<div class="userList" *ngIf="!isLoading">
    <ul class="box-right user-list" *ngFor="let user of contractUsers; let i = index">
      <li>
        <div class="row">
          <div class="col-2">
            <span class="border-b"></span>
            <i *ngIf="user.identityUserId !== null" class="ico-person"></i>
            <i *ngIf="user.identityUserId == null" class="ico-person-pending tooltip1" ngbTooltip="Pendente de criação"></i>
          </div>

          <div class="col-6">
            <div class="contractUsers" *ngIf="contractUsers" [ngClass]="user.identityUserId == null ? 'contractUsersPendent' : ''">
                <b [attr.data-e2e]="'username-' + i" id="UserFullName{{i}}">{{ user.fullName }}</b><br />
                <div class="contractUsersPersonResponsibleType">
                  <span [attr.data-e2e]="'role-' + i">{{ user.grupoDescription }}</span>
                  <i
                    *ngIf="user.grupoDescription !== null"
                    [attr.data-e2e]="'user-tooltip-msg-' + i"
                    class="ico ico-alert col-1"
                    placement="top"
                    ngbTooltip="{{user.grupoSubDescription}}"
                  ></i>
                </div>
                <span>
                  <span [ngClass]="'user-status user-status-'+switchUserStatusClass(user.statusUsuarioId, user.subPerfilBlocked)">{{switchUserStatusText(user.statusUsuarioId, user.subPerfilBlocked)}}</span>
                </span>
              </div>
          </div>

          <div class="col-2">
            <a [attr.data-e2e]="'open-edit-user-modal-btn-' + i" (click)="showUserEditModal(user, false)">
              <i ngbTooltip="Visualizar" placement="top" class="ico ico-white ico-search-zoom"></i>
            </a>
          </div>
          <div class="col-2" *accessControl="101219">
            <a [attr.data-e2e]="'open-edit-user-modal-btn-' + i" (click)="showUserEditModal(user, true)">
              <i ngbTooltip="Editar" placement="top" class="ico ico-white ico-edit-user"></i>
            </a>
          </div>
        </div>
      </li>
    </ul>
    <div class="col-12 btn-center">

    </div>
  </div>
<div class="col-12" *ngIf="isLoading">
  <div class="container-loading">
    <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
  </div>
</div>
