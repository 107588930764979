import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-tracking',
  templateUrl: './view-tracking.component.html',
  styleUrls: ['./view-tracking.component.scss'],
})
export class ViewTrackingComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {}
  @Input() order: any;
  viewTrackingForm: FormGroup;

  ngOnInit(): void {
    this.createFormReportTracking();
  }

  createFormReportTracking() {
    this.viewTrackingForm = this.formBuilder.group({
      trackingCode: [this.order?.trackingCode],
    });
  }
  openViewTracking() {
    var openInOtherTab = window.open(this.order.logisticPartnerLink, '_blank');
    openInOtherTab.focus();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
  copyCode(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
