<div class="FinancialBlockModalComponent">
    <div class="modal-content">
        <div class="modal-header">
            <div class="box-title">
              <span>Bloqueio financeiro</span>
            </div>
            <div class="blueContainer"></div>
            <div class="line"></div>
            <div class="contractInfo">
              <p class="name">{{contract.description}}</p>
              <p class="contract">
                <span class="document">{{contract?.documentNumberCompanyContract | mask: '00.000.000/0000-00'}}</span>
                | Contrato: <span class="id">{{ contract?.id }}</span>
              </p>
            </div>
            <button type="button" class="close" (click)="dismissModal()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body show row prepaid" *modalityType="contractModality.PREPAID">
            <div class="col"><img src="../../../assets/img/contract_block.svg" /></div>
            <div class="col col-right">
              <div class="title">Seu contrato está bloqueado por falta de saldo.</div>
              <div class="message">Realize uma nova recarga para continuar utilizando nossos serviços ou entre em contato com a Central de Atendimento através do chat ou Fale Conosco disponíveis na plataforma.</div>
            </div>
          </div>
          <div class="modal-body show row" *modalityType="contractModality.POSTPAID">
            <div class="col"><img src="../../../assets/img/contract_block.svg" /></div>
            <div class="col col-right">
              <div class="title">Seu contrato está bloqueado por falta de pagamento.</div>
              <div class="message">Regularize a situação para continuar usando nossos serviços ou entre em
                contato pela nossa central de atendimento através do Chat
                ou pela central de atendimento: 4020-8205.</div>
            </div>
          </div>
         <div class="modal-footer modal-footer-gray">
            <app-borderless-button [description]="'Fechar'"
                                   [id-html]="'btn-modal-block-close'"
                                   [data-e2e]="'btn-modal-block-close'"
                                   [buttonColor]="'secundary'"
                                   [isBtnWithBorder]="true"
                                   (emitterEvtClick)="dismissModal()">
            </app-borderless-button>
            <app-borderless-button *modalityType="contractModality.PREPAID"
                                   [description]="'Regularizar contrato'"
                                   [id-html]="'btn-modal-block-active-prepaid'"
                                   [data-e2e]="'btn-modal-block-active-prepaid'"
                                   [isBtnWithBorder]="true"
                                   (emitterEvtClick)="RedirectToPage(1)">
            </app-borderless-button>
            <app-borderless-button *modalityType="contractModality.POSTPAID"
                                   [description]="'Regularizar fatura'"
                                   [id-html]="'btn-modal-block-active-postpaid'"
                                   [data-e2e]="'btn-modal-block-active-postpaid'"
                                   [isBtnWithBorder]="true"
                                   (emitterEvtClick)="RedirectToPage(2)">
            </app-borderless-button>
         </div>
    </div>
</div>
