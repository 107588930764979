<div class="NotAuthorized Dashboard container-fluid">
  <div class="row">
    <div class="col-12 Dashboard_Overview">
      <h1 class="image-replacement" data-e2e="img-replace-title">Toll Management</h1>
    </div>
    <div class="container-fluid">
        <div class="row NotAuthorizedBlockImg">
            <div class="NotAuthorizedImg">
                <p>Usuário sem permissão de acesso à funcionalidade selecionada.</p>
            </div>
        </div>
    </div>
  </div>
</div>
