import { Component, Input } from "@angular/core";
import { ContractModality } from "@models/old/contractModality.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentService } from "@services/payment.service";
import { Dto } from "src/app/core/models/dto.model";

@Component({
  selector: "app-order-success",
  templateUrl: "./order-success.component.html",
  styleUrls: ["./order-success.component.scss"],
})
export class OrderSuccessComponent {
  @Input() response: Dto<any>;
  @Input() amount: number;
  @Input() totalAmountPayable: number;
  readonly modalityType = ContractModality;

  constructor(public modal: NgbModal, public paymentService: PaymentService) {}
}
