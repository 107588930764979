import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from '../feedback/feedback.component';
import { TransferVprComponent } from '../transfer-vpr/transfer-vpr.component';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventEmitterService } from '@services/event-emitter.service';
import {VehiclesDto} from "@models/backoffice/vehicles-backoffice.dto";
import {CancelTagDto} from "@models/backoffice/cancel-tag.dto";
import {MyvehiclesService} from "@modules/customer/myvehicles/services/myvehicles.service";
import {
  CancelTagBackOfficeComponent
} from "@modules/customer/myvehicles/components/cancel-tag-backoffice/cancel-tag-backoffice.component";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  @Input() cancelTagForm: FormControl;
  @Input() vehicle: VehiclesDto;
  @Input() vehicleToReceiveVPR: VehiclesDto;
  @Input() balance: number;
  @Output() event: EventEmitter<any> = new EventEmitter();

  cancelTag: CancelTagDto;
  isLoading: boolean;
  message: string;

  constructor(
    private _modalService: NgbModal,
    private _vehiclesService: MyvehiclesService,
    private _router: Router,
    public activeModal: NgbActiveModal,
  ) {
    this.cancelTag = {
      excluirVeiculoFrota: false,
      tagId: this.vehicle?.tagId,
      changeUserId: 1,
      contractVehicleId: 1,
      motivoCancelamento: 1,
      motivoDescricao: '',
      protocol: '',
      hasBalanceVehicle: false,
      isBackOffice: true,
    };
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  previous() {
    if (this.balance > 0) {
      let modal = this._modalService.open(TransferVprComponent);
      modal.componentInstance.vehicle = this.vehicle;
      modal.componentInstance.balance = this.balance;
      modal.componentInstance.selectedVehicle = this.vehicleToReceiveVPR;
      modal.componentInstance.cancelTagForm = this.cancelTagForm;
    } else {
      let modalForm = this._modalService.open(CancelTagBackOfficeComponent);
      modalForm.componentInstance.cancelTagForm = this.cancelTagForm;
      modalForm.componentInstance.vehicle = this.vehicle;
      modalForm.componentInstance.optionSelected = true;
    }

    this.dismissModal();
  }

  confirm() {
    this.isLoading = true;
    this.cancelTag.excluirVeiculoFrota =
      this.cancelTagForm.value.cancelTag == 2 ? true : false;
    this.cancelTag.tagId = this.vehicle?.tagId;
    this.cancelTag.contractVehicleId = this.vehicle?.contractVehicleId;
    this.cancelTag.motivoCancelamento =
      this.cancelTagForm.value.motivoCancelamento;
    this.cancelTag.motivoDescricao = this.cancelTagForm.value.motivoDescricao;
    this.cancelTag.protocol = this.cancelTagForm.value.protocol;
    this.cancelTag.hasBalanceVehicle = this.balance > 0 ? true : false;
    if (this.balance > 0) {
      this.cancelTag.contractVehicleTagIdDestination =
        this.vehicleToReceiveVPR.contractVehicleTagId;
    }

    this._vehiclesService
      .cancelTag(this.cancelTag, true) // true = isBackOffice
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (sucess: any) => {
          this.activeModal.dismiss();
          let modalConfirm = this._modalService.open(FeedbackComponent);
          modalConfirm.componentInstance.vehicle = this.vehicle;
          modalConfirm.componentInstance.vehicleToReceiveVPR =
            this.vehicleToReceiveVPR || null;
          modalConfirm.componentInstance.balance = this.balance;
          modalConfirm.componentInstance.isExclude =
            this.cancelTagForm.value.cancelTag == 2 ? true : false;

          EventEmitterService.get('RefreshVehiclesAfterCancelTag').emit();
        },
        (error) => {
          console.error(error);
          this.activeModal.dismiss();
          this._router.navigate(['/meus-veiculos']);
        },
      );
  }
}
