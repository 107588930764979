<form [formGroup]="cancelTagForm" class="cancelTagComponent" novalidate>
  <div class="modal-header">
    <div class="box-title">
      <span>Cancelamento de tag</span>
    </div>

    <button
      data-e2e="modal-top-close"
      (click)="dismissModal()"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="container-loading" *ngIf="isLoading">
    <app-loader
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    ></app-loader>
  </div>

  <div class="sub-header">
    <div class="box-infos">
      <div class="title-infos">
        <div>Veiculo</div>
        <div class="tag">Tag</div>
        <div>Saldo VPR</div>
      </div>
      <div class="sub-header-content">
        <div class="popover-board">
          <button
            type="button"
            class="btn-popover"
            [ngbPopover]="popContentDetails"
            placement="right"
            (click)="focus = !focus"
            autoClose="outside"
            [ngClass]="{ focus: focus, '': !focus }"
            (hidden)="focus = false"
            popoverClass="custom-popover"
          >
            <img src="assets/svg/icon-popover.svg" />
          </button>
          {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}

          <ng-template #popContentDetails>
            <div class="popover-filter">
              <div class="row my-1">
                <div
                  [attr.data-e2e]="'popover-plate-label'"
                  class="head-info"
                  translate
                >
                  Board
                </div>
                <div
                  [attr.data-e2e]="'popover-tag'"
                  *ngIf="vehicle?.serialTag"
                  class="content-info"
                >
                  {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}
                </div>
              </div>

              <div class="row my-1">
                <div
                  [attr.data-e2e]="'popover-nickname-label'"
                  class="head-info"
                  translate
                >
                  Nickname
                </div>
                <div [attr.data-e2e]="'popover-nickname'" class="content-info">
                  {{ vehicle?.nickName }}
                </div>
              </div>

              <div class="row my-1">
                <div
                  [attr.data-e2e]="'popover-tag-label'"
                  class="head-info"
                  translate
                >
                  Tag
                </div>
                <div
                  [attr.data-e2e]="'popover-tag'"
                  *ngIf="vehicle?.serialTag"
                  class="content-info"
                >
                  {{ vehicle?.serialTag }}
                </div>

                <div
                  *ngIf="!vehicle?.serialTag"
                  class="content-info"
                  [attr.data-e2e]="'popover-tag'"
                  translate
                >
                  No tag
                </div>
              </div>

              <div class="row my-1">
                <div
                  [attr.data-e2e]="'popover-type-label'"
                  class="head-info"
                  translate
                >
                  Type
                </div>
                <div [attr.data-e2e]="'popover-type'" class="content-info">
                  {{ vehicle?.descriptionVehicleTypePT }}
                </div>
              </div>

              <div class="row my-1">
                <div
                  [attr.data-e2e]="'popover-brand-label'"
                  class="head-info"
                  translate
                >
                  Brand
                </div>
                <div [attr.data-e2e]="'popover-brand'" class="content-info">
                  {{ vehicle?.descriptionVehicleBrandTypePt }}
                </div>
              </div>

              <div class="row my-1">
                <div
                  [attr.data-e2e]="'popover-model-label-'"
                  class="head-info"
                  translate
                >
                  Model
                </div>
                <div [attr.data-e2e]="'popover-model-'" class="content-info">
                  {{ vehicle?.model }}
                </div>
              </div>

              <div class="row my-1 mb-4">
                <div
                  [attr.data-e2e]="'popover-axel-label-'"
                  class="head-info"
                  translate
                >
                  Number of Axis
                </div>
                <div [attr.data-e2e]="'popover-axel-'" class="content-info">
                  {{ vehicle?.descriptionAxlePt }}
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="tag-text">
          {{ vehicle?.serialTag }}
        </div>
        <div *ngIf="balance != null; else balanceNull">
          {{ balance | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR' }}
        </div>
        <ng-template #balanceNull>
          {{ '0' | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR' }}
        </ng-template>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div class="modal-body-content col-lg-10 col-md-12">
      <h5 translate class="text-protocol">
        Informe o protocolo da solicitação
      </h5>
      <input
        type="text"
        class="input-protocol"
        required
        mask="999999999999999"
        placeholder="Informe o protocolo da solicitação"
        formControlName="protocol"
        maxlength="15"
        [class.is-invalid]="
          cancelTagForm.controls.protocol.invalid &&
          cancelTagForm.controls.protocol.touched
        "
      />
    </div>
    <div class="modal-body-content options">
      <p class="vehicle-has-vpr" *ngIf="balance > 0">
        O veículo selecionado possui saldo de vale-pedágio, deseja realizar a
        transferência do saldo para outro veículo?
      </p>
      <p class="vehicle-has-vpr" *ngIf="balance <= 0">
        Deseja manter o veículo na frota para futuras vinculações de tag?
      </p>

      <div class="custom-radio-check cancel-tag">
        <div *ngIf="balance > 0; else noVpr">
          <mat-radio-group
            formControlName="cancelTag"
            class="radio-container radio-group__label-black"
          >
            <mat-radio-button
              class="custom-radio"
              id="tranfer-manter-veiculo"
              value="1"
              (change)="optionSelected = true"
            >
              Transferir o saldo do veículo e mantê-lo na frota
            </mat-radio-button>
            <mat-radio-button
              class="custom-radio"
              id="tranf-excluir-veiculo"
              value="2"
              (change)="optionSelected = true"
            >
              Transferir o saldo do veículo e excluí-lo da frota
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <ng-template #noVpr>
          <mat-radio-group
            formControlName="cancelTag"
            class="radio-container radio-group__label-black"
          >
            <mat-radio-button
              class="custom-radio"
              id="cancel-manter-veiculo"
              value="1"
              (change)="optionSelected = true"
            >
              Cancelar tag e manter veículo na frota
            </mat-radio-button>
            <mat-radio-button
              class="custom-radio"
              id="cancel-excluir-veiculo"
              value="2"
              (change)="optionSelected = true"
            >
              Cancelar tag e excluir veículo da frota
            </mat-radio-button>
          </mat-radio-group>
        </ng-template>
      </div>
      <div class="custom-radio-check cancel-tag" *ngIf="optionSelected == true">
        <h5 class="title-reason">Informe o motivo do cancelamento</h5>
        <mat-radio-group
          class="box radio-container radio-group__label-black"
          formControlName="motivoCancelamento"
        >
          <mat-radio-button class="custom-radio" id="contrato" value="1"
            >Cancelamento do contrato</mat-radio-button
          >
          <mat-radio-button class="custom-radio" id="devolucao" value="2"
            >Devolução do veículo à locadora</mat-radio-button
          >
          <mat-radio-button class="custom-radio" id="sinistro" value="3"
            >Sinistro</mat-radio-button
          >
          <mat-radio-button class="custom-radio" id="filial" value="4"
            >Troca de filial</mat-radio-button
          >
          <mat-radio-button class="custom-radio" id="venda" value="5"
            >Venda do veículo</mat-radio-button
          >
          <mat-radio-button class="custom-radio" id="outro" value="6"
            >Outro</mat-radio-button
          >
        </mat-radio-group>
        <div
          class="motivo col-12"
          *ngIf="cancelTagForm.controls.motivoCancelamento.value == '6'"
        >
          <p class="title-reason">INFORME O MOTIVO</p>
          <input
            type="text"
            class="other-reason"
            formControlName="motivoDescricao"
            placeholder="Digite"
            [maxlength]="200"
            required
          />
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <app-vmessage
          *ngIf="
            cancelTagForm.controls.motivoCancelamento.invalid &&
            cancelTagForm.controls.motivoCancelamento.touched
          "
          errorMessage="{{ 'Reason is required' | translate }}"
        >
        </app-vmessage>
        <app-vmessage errorMessage="{{ errorMsg }}"></app-vmessage>
      </div>
    </div>
  </div>

  <div class="modal-footer mt-4">
    <div class="content-buttons d-flex justify-content-lg-between">

      <app-borderless-button
        [description]="'Cancelar'"
        [id-html]="'btn-cancel'"
        [data-e2e]="'btn-cancel'"
        [isBtnWithBorder]="true"
        [buttonColor]="'secundary'"
        (emitterEvtClick)="dismissModal()">
      </app-borderless-button>

      <app-borderless-button
        [description]="'Próximo'"
        [id-html]="'btn-next'"
        [data-e2e]="'btn-next'"
        [isBtnWithBorder]="true"
        [buttonColor]="'primary'"
        [isDisable]="cancelTagForm.invalid || isLoading"
        (emitterEvtClick)="next()"
      >
      </app-borderless-button>

    </div>
  </div>
</form>
