import { Component } from "@angular/core";

import { Observable } from "rxjs";

import { TagStockStats } from "@models/statistics.model";
import { TagService } from "@services/tag.service";

@Component({
  selector: "app-tag-stock",
  templateUrl: "./tag-stock.component.html",
  styleUrls: ["./tag-stock.component.scss"],
})
export class TagStockComponent {
  stock$: Observable<TagStockStats[]> = this._service.getTagStock();

  constructor(private _service: TagService) {}
}
