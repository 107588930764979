<div class="TagOrderConfirmationComponent">
    <div class="modal-content">
        <header class="modal-header">
          <div class="box-title">
            <span >{{ object.paymentTotalValue !== 0 && modalityTypeId == contractModality.PREPAID ? 'Pedido solicitado' : 'Pedido realizado'}}</span>
          </div>
      
          <button class="close" type="button" (click)="modal.dismissAll()">
            <span aria-hidden="true">×</span>
          </button>
      
          <div class="row-box-yellow">
            <span class="box-yellow">
              <b>P</b>edido de {{ object.paymentAmountOfTags }} tags em processamento. <span *ngIf="object.paymentTotalValue !== 0 && modalityTypeId == contractModality.PREPAID">Aguardando confirmação de pagamento</span>
            </span>
          </div>
        </header>
      
        <div class="modal-body" [ngClass]="object.paymentTotalValue !== 0 && modalityTypeId == contractModality.PREPAID ? '' : 'without-value'">
          <div class="contract-infos">
            <div class="companyName">
              {{ response.data.companyName }}
            </div>
            <div class="companyDocument">
              {{ response.data.documentNumber | mask : "00.000.000/0000-00" }}
            </div>
            <div class="row row-animation"></div>
            <div class="nickname">
              <div class="box-white-small">
                <p>Contrato:</p>
                <p>
                  <span id="contractId">{{ response.data.contractId }}</span
                  ><span *ngIf="response.data.contractName" id="contractName">
                    - {{ response.data.contractName }}</span
                  >
                </p>
              </div>
            </div>
          </div>
      
          <div class="row row-modal-collapse mt-7rem">
            <div class="w-100 d-flex justify-content-center">
              <div class="col-lg-4 left-infos">
                <img class="w-100" src="../../../assets/img/bg-place.svg" />
                <div class="box-gray" *ngIf="object.paymentTotalValue !== 0 && modalityTypeId == contractModality.PREPAID">
                  Você será notificado da confirmação dessa solicitação de pedido.<br />
                  Fique atento na área de notificações.
                </div>
                <div class="notification" *ngIf="object.paymentTotalValue !== 0 && modalityTypeId == contractModality.PREPAID">
                  <img src="/assets/img/red-arrow.svg" />
                  <div class="ico-notification">
                    <img src="/assets/img/notification_image.svg" />
                  </div>
                </div>
              </div>
              <div class="col-sm-auto tag-replace right-infos">
                <strong 
                  >Pedido Número:
                  <span id="tagOrderId">{{
                    response.data.tagRequestId
                  }}</span></strong
                >
                <div class="row orderConfirmedInfos" *ngIf="object.paymentTotalValue !== 0 && modalityTypeId == contractModality.PREPAID">
                  <div class="col">
                    <div class="col-12 textTitle">
                      Total debitado do cartão de crédito
                    </div>
                    <div class="col-12 text">
                      {{
                        object.paymentTotalValue
                          | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                      }}
                    </div>
                  </div>
                  <div class="col credit-card-details">
                    <b data-e2e="card-label">Cartão de crédito</b>
                    <app-credit-card></app-credit-card>
                  </div>
                </div>
                <div class="payment-Infos" *ngIf="object.paymentTotalValue == 0 || modalityTypeId == contractModality.POSTPAID">
                    <div class="payment-values">
                        <div data-e2e="payment-value-label" class="label payment-value-label">Valor</div>
                        <div data-e2e="payment-value" class="value payment-value">{{ object.paymentValue | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                        <div data-e2e="discount-value-label" class="label discount-value-label">Descontos</div>
                        <div data-e2e="discount-value" class="value discount-value">{{ object?.paymentDiscountValue | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                    </div>
                    <div class="payment-total">
                        <div data-e2e="payment-total-label" class="label total-label">Você irá pagar</div>
                        <div data-e2e="payment-total" class="value total">{{ object.paymentTotalValue | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                    </div>
                    <div class="info-postpaid" *ngIf="modalityTypeId == contractModality.POSTPAID && object.paymentTotalValue !== 0">O valor do pedido será cobrado em sua próxima fatura.</div>
                </div>
                <div class="row mt-3 user">
                  <div class="col-lg-12 text-center">
                    <span id="registrationDate">{{
                      response.data.registrationDate | date : "dd/MM/yyyy - HH:mm"
                    }}</span>
                    por
                    <span id="registrationUserFullName">{{
                      response.data.registrationUserName
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <footer class="modal-footer modal-footer-gray">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="modal.dismissAll()"
            id="btn-close"
          >
            Fechar
          </button>
        </footer>
      </div>
</div>

  