<main>
  <div class="wrap">
    <h1 data-e2e="page-not-found-msg">Desculpe! Página não foi encontrada!</h1>

    <ul class="d-none">
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showAddUserModal()"
        >
          <i class="ico ico-edit"></i>showAddUserModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showUsersBlockedModal()"
        >
          <i class="ico ico-edit"></i>showUsersBlockedModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showUserChangeDatailsModal()"
        >
          <i class="ico ico-edit"></i>showUserChangeDatailsModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showUserChangePasswordModal()"
        >
          <i class="ico ico-edit"></i>showUserChangePasswordModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showUserBusinessConditionsModal()"
        >
          <i class="ico ico-edit"></i>showUserBusinessConditionsModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showEditVehicleModal()"
        >
          <i class="ico ico-edit"></i>showEdreplacitVehicleModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showTagBlockModal()"
        >
          <i class="ico ico-edit"></i>showTagBlockModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showTagReplaceModalModal()"
        >
          <i class="ico ico-edit"></i>showTagReplaceModalModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showTagUnlockModal()"
        >
          <i class="ico ico-edit"></i>showTagUnlockModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showAddVehicleModal()"
        >
          <i class="ico ico-edit"></i>showAddVehicleModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showAlterAddressTagOrderModal()"
        >
          <i class="ico ico-edit"></i>showAlterAddressTagOrderModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showContractDetailsModal()"
        >
          <i class="ico ico-edit"></i>showContractDetailsModal
          <span class="btn-animation"></span>
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-repom right"
          (click)="showNewHierarchyModal()"
        >
          <i class="ico ico-edit"></i>showNewHierarchyModal
          <span class="btn-animation"></span>
        </button>
      </li>
    </ul>

    <div class="container">
      <div class="car-wrapper">
        <div class="car-wrapper_inner">
          <div class="car_outter">
            <div class="car">
              <div class="body">
                <div></div>
              </div>
              <div class="decos">
                <div class="line-bot"></div>
                <div class="door">
                  <div class="handle"></div>
                  <div class="bottom"></div>
                </div>
                <div class="window"></div>
                <div class="light"></div>
                <div class="light-front"></div>
                <div class="antenna"></div>
                <div class="repom">
                  <img
                    class="logo"
                    src="../../../assets/img/logo-brasao-clube-da-estrada-repom-branco.png"
                  />
                </div>
                <!--
                  <div class="ice-cream">
                    <div class="cone"></div>
                  </div>
                  -->
              </div>
              <div>
                <div class="wheel"></div>
                <div class="wheel"></div>
              </div>
              <div class="wind">
                <div class="p p1"></div>
                <div class="p p2"></div>
                <div class="p p3"></div>
                <div class="p p4"></div>
                <div class="p p5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="background-stuff">
        <div class="bg"></div>
        <div class="bg bg-2"></div>
        <div class="bg bg-3"></div>
        <div class="ground"></div>
      </div>
    </div>

    <br />
    <br />
    <p>A página que você solicitou foi movida ou não existe.</p>
    <p>O que você pode fazer:</p>
    <ul>
      <li>Voltar a <a href="#">home</a>.</li>
      <li>
        <a href="#">Contatar-nos</a>, se você acredita que aconteceu algum erro.
      </li>
    </ul>
  </div>
</main>

<script>
  window.addEventListener("DOMContentLoaded", game);

  function game() {
    var canvas = document.querySelector("canvas"),
      c = canvas.getContext("2d"),
      W = canvas.width,
      H = canvas.height,
      S = 2,
      assets = [
        "https://i.ibb.co/dDcTzrQ/nowhere.png",
        "https://i.ibb.co/S7zPTv5/tumbleweed.png",
      ],
      sprites = [],
      score = 0,
      world = {
        friction: 0.1,
        gravity: 0.1,
      },
      tumbleweed = {
        inPlay: false,
        x: -160,
        y: 200,
        r: 32,
        rotation: 0,
        xVel: 10,
        yVel: 0,
        mass: 2.5,
        restitution: 0.3,
      },
      loadSprite = (url) => {
        return new Promise((resolve, reject) => {
          let sprite = new Image();
          sprite.src = url;
          sprite.onload = () => {
            resolve(sprite);
          };
          sprite.onerror = () => {
            reject(url);
          };
        });
      },
      spritePromises = assets.map(loadSprite),
      applyForce = (e) => {
        let ex = e.clientX - canvas.offsetLeft,
          ey = e.clientY - (canvas.offsetTop - window.pageYOffset);

        ex = (ex / canvas.offsetWidth) * W;
        ey = (ey / canvas.offsetHeight) * H;

        let insideX = Math.abs(ex - tumbleweed.x) <= tumbleweed.r,
          insideY = Math.abs(ey - tumbleweed.y) <= tumbleweed.r;

        if (insideX && insideY) {
          let xForce = tumbleweed.x - ex,
            yForce = tumbleweed.y - ey,
            xAccel = xForce / tumbleweed.mass,
            yAccel = yForce / tumbleweed.mass;

          tumbleweed.xVel += xAccel;
          tumbleweed.yVel += yAccel;

          ++score;

          // when enabled, the tumbleweed will be allowed to touch the left side after rolling in
          if (!tumbleweed.inPlay) tumbleweed.inPlay = true;
        }
      },
      update = () => {
        // A. Background
        c.clearRect(0, 0, W, H);
        c.drawImage(sprites[0], 0, 0, W, H);

        // B. Tumbleweed
        tumbleweed.x += tumbleweed.xVel;

        // 1. Friction to the right
        if (tumbleweed.xVel > 0) {
          tumbleweed.xVel -= world.friction;
          if (tumbleweed.xVel < 0) tumbleweed.xVel = 0;

          // 2. Friction to the left
        } else if (tumbleweed.xVel < 0) {
          tumbleweed.xVel += world.friction;
          if (tumbleweed.xVel > 0) tumbleweed.xVel = 0;
        }

        // 3. Horizontal collision
        let hitLeftBound = tumbleweed.x <= tumbleweed.r && tumbleweed.inPlay,
          hitRightBound = tumbleweed.x >= W - tumbleweed.r;

        if (hitLeftBound) tumbleweed.x = tumbleweed.r;
        else if (hitRightBound) tumbleweed.x = W - tumbleweed.r;

        if (hitLeftBound || hitRightBound)
          tumbleweed.xVel *= -tumbleweed.restitution;

        // 4. Vertical collision
        tumbleweed.y += tumbleweed.yVel;
        tumbleweed.yVel += world.gravity;

        let hitTopBound = tumbleweed.y <= tumbleweed.r,
          hitBottomBound = tumbleweed.y >= H - tumbleweed.r;

        if (hitTopBound) {
          tumbleweed.y = tumbleweed.r;
        } else if (hitBottomBound) {
          tumbleweed.y = H - tumbleweed.r;
          score = 0;
        }
        if (hitTopBound || hitBottomBound)
          tumbleweed.yVel *= -tumbleweed.restitution;

        // 5. Rotation
        tumbleweed.rotation += tumbleweed.xVel;

        if (tumbleweed.rotation >= 360) tumbleweed.rotation -= 360;
        else if (tumbleweed.rotation < 0) tumbleweed.rotation += 360;

        // 6. Drawing
        c.save();
        c.translate(tumbleweed.x, tumbleweed.y);
        c.rotate((tumbleweed.rotation * Math.PI) / 180);
        c.drawImage(
          sprites[1],
          -tumbleweed.r,
          -tumbleweed.r,
          tumbleweed.r * 2,
          tumbleweed.r * 2
        );
        c.translate(-tumbleweed.x, -tumbleweed.y);
        c.restore();

        // C. Score
        if (score > 0) {
          c.fillStyle = "#7f7f7f";
          c.font = "48px Hind, sans-serif";
          c.textAlign = "center";
          c.fillText(score, W / 2, 48);
        }
      },
      render = () => {
        update();
        requestAnimationFrame(render);
      };

    // ensure proper resolution
    canvas.width = W * S;
    canvas.height = H * S;
    c.scale(S, S);

    // load sprites
    Promise.all(spritePromises)
      .then((loaded) => {
        for (let sprite of loaded) sprites.push(sprite);

        render();
        canvas.addEventListener("click", applyForce);
      })
      .catch((urls) => {
        console.log(urls + " couldn’t be loaded");
      });
  }
</script>
