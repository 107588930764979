import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-late-fee-edit-modal',
  templateUrl: './late-fee-edit-modal.component.html',
  styleUrl: './late-fee-edit-modal.component.scss'
})
export class LateFeeEditModalComponent implements OnInit {

  @Input() lateFee: number;
  @Output() eventSubmitForm = new EventEmitter<any>();
  lateFeeForm: FormGroup;

  constructor(
    private _modalService: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.lateFeeForm = this.fb.group({
      lateFee: [
        this.formatInitialValue(this.lateFee),
        [
          Validators.required,
          this.validateLateFeeRange
        ]
      ],
      protocol: ['', Validators.required]
    });
  }

  formatInitialValue(value: number): number {
    return Number(value != null ? value.toFixed(2) : '');
  }

  validateLateFeeRange(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    const numericValue = parseFloat(value);
    if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
      return { 'rangeError': true };
    }
    return null;
  }

  dismissModal() {
    this._modalService.dismissAll();
  }

  getErrorMessage(): string {
    const control = this.lateFeeForm.controls.lateFee;
    if (control.hasError('required')) {
      return 'Por favor, preencha a multa';
    } else if (control.hasError('rangeError')) {
      return 'O valor deve estar entre 0,00 e 100,00';
    }
    return '';
  }

  onSubmit() {
    if (this.lateFeeForm.valid) {
      this.eventSubmitForm.emit(this.lateFeeForm.value);
    }
  }

}
