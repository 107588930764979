<div class="billPassesChargebackComponent">
    <div class="row align-items-center px-0 my-3">

        <div class="btn-group pl-2" dropdown [isAnimated]="true" [autoClose]="false">
            <button dropdownToggle type="button" data-e2e="filter-dropdown-btn" id="button-animated"
                aria-controls="dropdown-animated" class="btn btn-filter dropdown-toggle"
                [class.no-shadow]="isShownHierarchy" [class.without-border-bottom-radius]="isOpenDropdown"
                [class.active]="isOpenDropdown" (click)="toggleDropdown()">
                <img src="assets/img/filter.svg" class="button-icon" />
                Filtros
            </button>

            <div class="filters">
                <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-filter pl-2"
                    [class.dropdown-expanded]="isShownHierarchy" [class.no-shadow]="isShownHierarchy" role="menu"
                    aria-labelledby="button-animated">
                    <li data-e2e="show-hierarchy-toggle-btn" role="menuitem" (click)="toggleShowHierarchy()">
                        <div class="simulate-select">
                            <img src="assets/img/hierarchy_select.svg" />
                            <span data-e2e="hierarhy-label" class="simulate-label">Hierarquia</span>
                            <input data-e2e="select-hierarchy-input" id="hierarchySelected" class="simulate-input"
                                placeholder="Selecione um grupo" [value]="hierarchySelected" />
                            <div class="simulate-button" [class.active]="isShownHierarchy">
                                <img src="assets/img/arrow-select.svg" />
                            </div>
                        </div>
                    </li>

                    <li role="menuitem">
                        <div class="content-button">
                            <app-borderless-button [description]="'Limpar filtros'"
                            [data-e2e]="'clear-filter-btn'"
                            [id-html]="'resetFiltersButton'"
                            [buttonColor]="'secundary'"
                            [isBtnWithBorder]="true"
                            (click)="resetFilters()"
                            [classIconLeft]="'ico-delete'"></app-borderless-button>
                        </div>
                    </li>
                </ul>
                <div class="box-area-hierarchy" [ngClass]="{ show: isShownHierarchy, hide: !isShownHierarchy }">
                    <div class="content-extend">
                        <div class="hierarchy" *ngIf="!getBreadcrumbResult?.data">
                            <span data-e2e="empty-hierarchy-msg" class="without-hierarchy">Não há hierarquias para esse
                                contrato.</span>
                        </div>
                        <div class="hierarchy" *ngIf="getBreadcrumbResult?.data">
                            <h4 data-e2e="hierarchy-title" class="text-center mb-2_25rem">Selecione o grupo</h4>
                            <div class="container-hierarchy">
                                <ul class="content-hierarchy">
                                    <li [attr.data-e2e]="'hierarchy-option-' + i" class="items-hierarchy"
                                        *ngFor="let hierarchy of getBreadcrumbResult?.data; let i = index"
                                        (click)="onApplyHierarchyFilter(hierarchy)">
                                        <div class="info-hierarchy">
                                            <span [attr.data-e2e]="'hierarchy-breadcrumb-' + i"
                                                id="breadCrumb{{ i }}">{{ hierarchy.breadCrumb }}</span> <br />
                                            <span [attr.data-e2e]="'hierarchy-description-' + i"
                                                id="description_PT{{ i }}">{{ hierarchy.description_PT }}</span>
                                        </div>

                                        <button [attr.data-e2e]="'hierarchy-action-btn-' + i" class="action-hierarchy">
                                            <img src="assets/img/ico-search.svg" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-auto">
            <div class="title d-flex align-items-center">
                <form novalidate>
                    <app-outlined-select
                    id="page-size-select"
                    dataE2e="page-size-select"
                    [title]="'Quantidade de linhas'"
                    [values]="perPages"
                    [beforeTextValue]="'exibir '"
                    [afterTextValue]="' linhas'"
                    (optionSelected)="onPageSizeChange($event)">
                    </app-outlined-select>
                </form>
            </div>
        </div>

        <div class="col-md-auto">
            <div class="title">
                <form novalidate>
                    <app-outlined-select
                    id="order-select"
                    dataE2e="order-select"
                    [title]="'Ordenação'"
                    [values]="orderSelect"
                    [initialValue]="passengersQuery.sortField"
                    [hasLabel]="true"
                    (valueChange)="onOrderByChangeSelect($event)">
                    </app-outlined-select>
                </form>
            </div>
        </div>

        <div class="col-md-auto search form-inline">
            <app-search-form-inline
                [placeholder]="'placa e apelido'"
                [searchValue]="passengersQuery.search"
                (keyUpEvent)="onFullNameKeyup($event)"
                >

            </app-search-form-inline>
        </div>


    </div>

    <div class="px-4">
        <div class="row mt-2">
            <div class="col-12">
                <div class="row">
                    <div class="col-custom col-custom-left percent-73 pr-1">
                        <div data-e2e="table-top-description" class="header-table">
                            Descrições
                        </div>
                    </div>

                    <div class="col-custom col-custom-right percent-26 pl-1">
                        <div data-e2e="table-top-amount" class="header-table">
                            Valores (R$)
                        </div>
                    </div>
                </div>

                <table class="table bill-passes-chargeback">
                    <thead class="thead">
                        <tr>
                            <th [ngClass]="bindOrderByClass('BillId')">
                                <button data-e2e="code-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('code')">Cód.</button>
                                <div class="round-order" (click)="onOrderByChange('code')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass('passengerDate')">
                                <button data-e2e="transaction-date-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('passengerDate')">Data da
                                    Transação</button>
                                <div class="round-order" (click)="onOrderByChange('passengerDate')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass('processingDate')">
                                <button data-e2e="processing-date-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('processingDate')">Data
                                    do Processamento</button>
                                <div class="round-order" (click)="onOrderByChange('processingDate')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass('billItemDescription')">
                                <button data-e2e="type-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('billItemDescription')">Tipo</button>
                                <div class="round-order" (click)="onOrderByChange('billItemDescription')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass('licensePlate')">
                                <button data-e2e="plate-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('licensePlate')">Placa
                                    Apelido</button>
                                <div class="round-order" (click)="onOrderByChange('licensePlate')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass('hierarchyDescriptionPt')">
                                <button data-e2e="hierarchy-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('hierarchyDescriptionPt')">Hierarquia</button>
                                <div class="round-order" (click)="onOrderByChange('hierarchyDescriptionPt')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>

                            <th [ngClass]="bindOrderByClass('axleDescriptionPt')">
                                <button data-e2e="registered-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('axleDescriptionPt')">Categoria Cadastrada e
                                    cobrada</button>
                                <div class="round-order" (click)="onOrderByChange('axleDescriptionPt')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>

                            <th [ngClass]="bindOrderByClass('merchantName')">
                                <button data-e2e="description-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('merchantName')">Descrição</button>
                                <div class="round-order" (click)="onOrderByChange('merchantName')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>

                            <th class="px-2" [ngClass]="bindOrderByClass('passenger')">
                                <button data-e2e="transaction-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('passenger')">Transação</button>
                                <div class="round-order" (click)="onOrderByChange('passenger')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>

                            <th class="px-2" [ngClass]="bindOrderByClass('vprUser')">
                                <button data-e2e="used-ticket-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('vprUser')">Utilizado de
                                    Vale-Pedágio</button>
                                <div class="round-order" (click)="onOrderByChange('vprUser')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>

                            <th class="px-2" [ngClass]="bindOrderByClass('amount')">
                                <button data-e2e="charged-order-btn" class="btn-order-table" translate
                                    (click)="onOrderByChange('amount')">Cobrado</button>
                                <div class="round-order" (click)="onOrderByChange('amount')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <div class="container-loading" *ngIf="isLoading">
                        <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
                    </div>

                    <tbody *ngIf="!isLoading">
                        <tr *ngFor="let passes of passesChargeBack.passengers; let count = index">
                            <td [attr.data-e2e]="'passenger-code-' + count" id="code{{count}}">{{ passes.code | slice:-7
                                }}</td>
                            <td id="passengerDate{{count}}" class="text-left">
                                <div [attr.data-e2e]="'passenger-date-' + count">
                                    {{ passes.passengerDate | date:'dd/MM/yyyy' }}
                                </div>

                                <div [attr.data-e2e]="'passenger-time-' + count">
                                    {{ passes.passengerDate | date:'HH:mm:ss' }}
                                </div>
                            </td>
                            <td [attr.data-e2e]="'passenger-processing-date-' + count" id="processingDate{{count}}"
                                class="text-left">
                                <div [attr.data-e2e]="'processing-date-' + count">
                                    {{ passes.processingDate | date:'dd/MM/yyyy' }}
                                </div>

                                <div [attr.data-e2e]="'processing-time-' + count">
                                    {{ passes.processingDate | date:'HH:mm:ss' }}
                                </div>
                            </td>
                            <td class="text-center img">
                                <div *ngIf="passes?.billItemTypeId == 2" placement="top"
                                    ngbTooltip="Estorno de pedágio">
                                    <img src="assets/svg/icon_estornopedagio.svg" />
                                </div>
                                <div *ngIf="passes?.billItemTypeId == 11" placement="top" ngbTooltip="Estacionamento">
                                    <img src="assets/svg/icon_estacionamento.svg" />
                                </div>
                                <div *ngIf="passes?.billItemTypeId == 12" placement="top"
                                    ngbTooltip="Estorno de estacionamento">
                                    <img src="assets/svg/icon_estornoestacionamento.svg" />
                                </div>
                                <div *ngIf="passes?.billItemTypeId == 1" placement="top" ngbTooltip="Pedágio">
                                    <img src="assets/svg/icon_pedagio.svg" />
                                </div>
                            </td>
                            <td class="text-left">
                                <span [attr.data-e2e]="'passenger-plate-' + count" id="licensePlate{{count}}">{{
                                    passes.licensePlate| mask: 'AAA-AAAA' }}</span>
                                <br>
                                <span [attr.data-e2e]="'passenger-nickname-' + count" id="nickName{{count}}">{{
                                    passes.nickName }}</span>
                            </td>
                            <td [attr.data-e2e]="'passenger-hierarchy-' + count" class="text-left"
                                id="hierarchyDescriptionPt{{count}}">{{ passes.hierarchyDescriptionPt }}</td>
                            <td class="text-left">
                                <span id="Category{{count}}"
                                    *ngIf="passes?.billItemTypeId != 11 && passes?.billItemTypeId != 12">
                                    <div [attr.data-e2e]="'registerd-label-' + count">Cadastrada</div>
                                    <span [attr.data-e2e]="'passenger-registered-category-' + count"
                                        id="registerCategory{{count}}">{{ passes?.registerCategory }}</span>
                                    <div [attr.data-e2e]="'charged-label-' + count" class="mt-2">Cobrada</div>
                                    <span [attr.data-e2e]="'passenger-charge-category-' + count"
                                        id="chargedCategory{{count}}">{{ passes?.chargedCategory }}</span>
                                </span>

                                <span id="Category{{count}}"
                                    *ngIf="passes?.billItemTypeId == 11 || passes?.billItemTypeId == 12">
                                </span>
                            </td>
                            <td class="text-left">
                                <span id="passageDescription{{count}}"
                                    *ngIf="passes?.billItemTypeId == 2 || passes?.billItemTypeId == 1">
                                    <span [attr.data-e2e]="'passenger-summary-' + count" id="summary{{count}}">{{
                                        passes?.summary }}</span><br>
                                    <span [attr.data-e2e]="'passenger-establishment-' + count"
                                        id="merchantName{{count}}">{{ passes?.merchantName }}</span>
                                    <span [attr.data-e2e]="'passenger-placename-' + count" id="placeName{{count}}"> {{
                                        passes?.placeName }}</span>
                                    <span [attr.data-e2e]="'passenger-address-' + count" id="address{{count}}">{{
                                        passes?.address }}</span>
                                </span>

                                <span id="passageDescription{{count}}"
                                    *ngIf="passes?.billItemTypeId == 11 || passes?.billItemTypeId == 12">
                                    <div [attr.data-e2e]="'passenger-establishment-' + count"
                                        id="merchantName{{count}}">{{passes?.merchantName}}</div>
                                    <div [attr.data-e2e]="'passenger-entering-label-' + count">Entrada:</div>
                                    <span [attr.data-e2e]="'passenger-entrance-date-' + count"
                                        id="entranceDateformat1_{{count}}">{{ passes?.entranceDate |
                                        date:'dd/MM/yyyy'}}</span> | <span
                                        [attr.data-e2e]="'passenger-entrance-time-' + count"
                                        id="entranceDateformat2_{{count}}">{{ passes?.entranceDate |
                                        date:'HH:mm:ss'}}</span>
                                    <div [attr.data-e2e]="'passenger-departure-label-' + count">Saida:</div>
                                    <span [attr.data-e2e]="'passenger-departure-date-' + count"
                                        id="departureDateformat1_{{count}}">{{ passes?.departureDate |
                                        date:'dd/MM/yyyy'}}</span> | <span
                                        [attr.data-e2e]="'passenger-departure-time-' + count"
                                        id="departureDateformat2_{{count}}">{{ passes?.departureDate |
                                        date:'HH:mm:ss'}}</span>
                                </span>
                            </td>
                            <td class="services-values-label-text">
                                <span [attr.data-e2e]="'passenger-value-sign-' + count"
                                    [ngClass]="{'': passes?.passenger > 0, 'negative-amount':passes?.passenger < 0 }">
                                    {{ passes?.passenger > 0 ? '+' : (passes?.passenger < 0 ? '-' : '' ) }} </span>
                                        <span [attr.data-e2e]="'passenger-value-' + count" id="passenger{{count}}"
                                          [ngClass]="{'': passes?.passenger > 0, 'negative-amount':passes?.passenger < 0 }"
                                        >
                                            {{ (passes?.passenger>0?passes?.passenger: passes?.passenger*-1)| currency:
                                            'BRL':'symbol':'0.2-2':'pt-BR' }}</span>
                            </td>
                            <td class="services-values-label-text">
                                <span *ngIf="passes?.billItemTypeId == 1 || passes?.billItemTypeId == 2">
                                    <span [attr.data-e2e]="'passenger-vpr-sign-' + count"
                                        [ngClass]="{'': passes?.vprUser > 0, 'negative-amount':passes?.vprUser < 0 }">
                                        {{ passes?.vprUser > 0 ? '+' : (passes?.vprUser < 0 ? '-' : '' ) }} </span>
                                            <span [attr.data-e2e]="'passenger-vpr-value-' + count"
                                                id="vprUser{{count}}"
                                                [ngClass]="{'': passes?.vprUser > 0, 'negative-amount':passes?.vprUser < 0 }"
                                            >
                                                {{ (passes?.vprUser > 0 ? passes?.vprUser : passes?.vprUser*-1) |
                                                currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}
                                            </span>
                                    </span>
                                    <span *ngIf="passes?.billItemTypeId == 11 || passes?.billItemTypeId == 12"></span>
                            </td>
                            <td class="services-values-label-text">
                                <span [attr.data-e2e]="'passenger-amount-sign-' + count"
                                    [ngClass]="{'': passes?.amount > 0, 'negative-amount':passes?.amount < 0 }">
                                    {{ passes?.amount > 0 ? '+' : (passes?.amount < 0 ? '-' : '' ) }} </span>
                                        <span [attr.data-e2e]="'passenger-amount-value-' + count"
                                            id="amount{{count}}"
                                            [ngClass]="{'': passes?.amount > 0, 'negative-amount':passes?.amount < 0 }"
                                        >{{ (passes?.amount > 0 ? passes?.amount :
                                            passes?.amount*-1)| currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}
                                        </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div *ngIf="!isLoading &&passesChargeBack.total==0">
                    <div data-e2e="empty-msg" colspan="12" class="text-center w-100 py-4" translate>Nenhum resultado
                        encontrado.</div>
                </div>

                <div class="pagination-custom" *ngIf="!isLoading && passesChargeBack.total > passengersQuery.pageSize">
                    <app-pagination [totalItems]="passesChargeBack.total" [itemsPerPage]="passengersQuery.pageSize"
                        [maxPages]="5" [currentPage]="passengersQuery.page"
                        (pageChangeEvent)="onPaginationChange($event)"></app-pagination>
                </div>
            </div>
        </div>
    </div>

</div>
