export class PaymentObject {
  paymentOriginId: number;
  paymentMethodSelected: number;
  paymentValue: number;
  paymentDiscountValue: number;
  paymentRechargeFeeValue: number;
  paymentRechargeFee: number;
  paymentTotalValue: number;
  paymentAmountOfTags?: number;
}

export interface PaymentType {
    id: number
    description_PT?: string
    description_EN?: string
}

export class PaymentMethod {
  paymentMethodId: number;
  paymentMethodname: string;
}


export class PixPayment {
    id: number
    contractId: number
    pixTransactionStatusId: number
    pixTransactionTypeId: number
    externalId: string
    transactionId: string
    amount: number
    feeAmount: number
    feePercent: number
    totalAmount: number
    totalDiscount: number
    codePix: string
    linkPix: string
    imagePix: string
    organization: string
    bankName: string
    documentNumber: string
    expirationDate: Date
    paymentDate: any
    registrationUserId: number
    registrationDate: Date
    changeDate: any
  }

  export class CreditCard {
    cardNumber?: string;
    companyName?: string;
    contractId?: number;
    creditCardBrandTypeId?: number;
    data?: string;
    documentNumber?: string;
    message?: string;
    nameContract?: string;
    registerDateTime?: string;
    registerUserName?: string;
    success?: boolean;
    cardFlagIcon?: string;
  }

  export enum CreditCardFlagUrl {
    "",
    "/assets/img/mastercard.svg",
    "/assets/img/hipercard.svg",
    "/assets/img/visa.svg",
    "/assets/img/amex.svg",
    "/assets/img/elo.svg",
    "/assets/img/dinersclub.svg",
  }

  export enum PaymentOrigin {
    LooseRecharge = 1,
    TagOrder = 2,
  }

  export enum PaymentMethodEnum {
    NoPaymentMethod = 0,
    CreditCard = 1,
    Pix = 2,
  }

  
  
  