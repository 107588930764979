import {Directive, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {UserProfileService} from "@services/user-profile.service";

@Directive({
  selector: "[controlAccessBackOffice]",
   standalone: true
})
export class ControlBackOfficeProfileAccessDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private manageUserProfile: UserProfileService
              ) {
  }

  ngOnInit(): void {
    const isBackOfficeProfile = this.manageUserProfile.isBackOffice();
    isBackOfficeProfile ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }


}
