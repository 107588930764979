import { HttpParams } from "@angular/common/http";
import { TableHeadersBatchTagBlockUnblockHistory  } from "./batch-management-consts";

export class TagBlockUnblockHistoryQuery {
  page: number;
  pageSize: number;
  sortField: string;
  desc: boolean;
  contractId: number;
  contractVehicleTagStatusTypeId?: number;

  constructor() {
    this.page = 1;
    this.pageSize = 10;
    this.sortField = TableHeadersBatchTagBlockUnblockHistory.REGISTRATION_DATE;
    this.desc = true;
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.sortField)
      .append('contractId', this.contractId.toString());

    if (this.contractVehicleTagStatusTypeId) {
      httpAppend = httpAppend.append('contractVehicleTagStatusTypeId', this.contractVehicleTagStatusTypeId.toString());
    }

    return httpAppend;
  }

}
