import { BehaviorSubject, Observable } from 'rxjs';
import { ChargebackReportQueryArguments } from '../dto/chargeback-report-query-arguments.dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { formatCurrency, formatDate } from '@angular/common';
import { ChargebackApproveReport } from '../dto/chargeback-approve.dto';
import { environment } from '@env';
import { Dto } from '@models/dto.model';
import {ChargebackReportModel} from "@models/backoffice/chargeback-report.model.dto";

@Injectable({
  providedIn: 'root',
})
export class ChargebackReportService {
  constructor(private http: HttpClient) {}

  public base = environment.apiTMSystemAccount;

  getChargebackReport(
    args: ChargebackReportQueryArguments,
  ): Observable<Dto<ChargebackReportModel[]>> {
    return this.http
      .get<Dto<ChargebackReportModel[]>>(
        this.base + `/api/PrePaidTransaction/GetChargebackReport`,
        { params: args.httpParams },
      )
      .pipe(
        map((response) => {
          let newItems: ChargebackReportModel[] = [];

          response.data.forEach((item) => {
            item.chargebackDate = item.chargebackDate.split('T')[0];
            item.validationDeadlineDate =
              item.validationDeadlineDate.split('T')[0];

            let newTransactionSale = {
              ...item,
              creditCardBrandType: item.creditCardBrandType.toUpperCase(),
              accountBalance: formatCurrency(
                Number(item.accountBalance),
                'pt-BR',
                'R$ ',
              ),
              transactionDate: formatDate(
                item.transactionDate,
                'dd/MM/yyyy HH:mm:ss',
                'en-US',
              ),
              chargebackDate: formatDate(
                item.chargebackDate,
                'dd/MM/yyyy',
                'en-US',
              ),
              validationDeadlineDate: formatDate(
                item.validationDeadlineDate,
                'dd/MM/yyyy',
                'en-US',
              ),
              totalAmount: formatCurrency(
                Number(item.totalAmount),
                'pt-BR',
                'R$ ',
              ),
            };

            if (newTransactionSale.chargebackDate === '01/01/1901') {
              newTransactionSale.chargebackDate = '-';
            }

            if (newTransactionSale.validationDeadlineDate === '01/01/1901') {
              newTransactionSale.validationDeadlineDate = '-';
            }
            newItems.push(newTransactionSale);
          });

          response.data = newItems;
          return response;
        }),
      );
  }

  getChargebackReportCsv(
    args: ChargebackReportQueryArguments,
  ): Observable<any> {
    return this.http
      .get(this.base + `/api/PrePaidTransaction/GetChargebackReportCsv`, {
        observe: 'response',
        responseType: 'blob',
        params: args.httpParams,
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),
            filename: response.headers.get('filename'),
          };
          return data;
        }),
      );
  }

  approveDisapproveChargeback(args: ChargebackApproveReport) {
    return this.http.post(
      this.base + `/api/PrePaidTransaction/ChargebackApprovedDisapproved`,
      args,
    );
  }
}
