import { Component, OnInit, Input, Pipe, PipeTransform  } from '@angular/core';
import { Subject } from 'rxjs';import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router'
import { finalize } from 'rxjs/operators';
import { BillVehiclesQueryArguments } from '../../dto/bill-vehicles-query.arguments';
import { VehiclesResultDto } from '../../dto/vehicles-result.dto';
import { VehiclesQueryArguments } from '../../dto/vehicles-query.arguments';
import { BillDetailHierarchy } from '../../services/bill-detail-hierarchy.service';

@Component({
  selector: 'app-bill-vehicle-modal',
  templateUrl: './bill-vehicle-modal.component.html',
  styleUrls: ['./bill-vehicle-modal.component.css']
})


export class BillVehicleModalComponent implements OnInit {

    @Input() Hierarchy;
    @Input() HierarchyFull;
    @Input() billId;
    @Input() licensePlate;
    @Input() nickname;
    BillVehiclesResultDto:any;
    BillVehiclesQueryArguments: BillVehiclesQueryArguments;
    VehiclesResultDto: VehiclesResultDto;
    VehiclesQueryArguments: VehiclesQueryArguments

    public BillVehicleList;
    public isLoading;
    public Vehicles;
    public descriptionVehicleBrandTypePt;
    public model;
    public vehicleId;
    public amountTotal;

    public nickName
    public descriptionAxlePt
    public serialTag
    public descriptionVehicleTypePT
    public total;



    constructor(private modalService: NgbModal,private billDetail: BillDetailHierarchy) {
     }

      ngOnInit() {
    this.BillVehiclesResultDto = {
      success: true,
      message: '',
      data: []
    };

        this.VehiclesQueryArguments = new VehiclesQueryArguments();

        this.BillVehiclesQueryArguments = new BillVehiclesQueryArguments();
        this.BillVehiclesQueryArguments.page = 1;
        this.BillVehiclesQueryArguments.pageSize = 10;
        this.BillVehiclesQueryArguments.id = this.billId;
        this.BillVehiclesQueryArguments.search = this.licensePlate
        this.BillVehiclesQueryArguments.desc = false;
        this.getBillDetailVehicles(this.BillVehiclesQueryArguments)

      }


      getBillDetailVehicles(args: BillVehiclesQueryArguments){
        this.isLoading = true
        this.billDetail.getBillVehicles(args)
        .pipe(
          finalize(() => {
            this.isLoading = false
          })
        )
        .subscribe(
          (success) => {
            this.VehicleList(success)
           // this.getBillVehiclesQueryResult = success;
          },
          (error) => {
            console.error(error);
            return;
          }
        );
      }

      onPaginationChange(page: number) {
        if(this.BillVehiclesQueryArguments.page !== page){
          this.BillVehiclesQueryArguments.page = page;
          this.getBillDetailVehicles(this.BillVehiclesQueryArguments)
        }
      }


      VehicleList(list){
          this.BillVehiclesResultDto = list
          this.amountTotal = list.data[0].amountTotal
          this.vehicleId = list.data[0].vehicleId
          this.total = list.data[0].total
          this.VehiclesQueryArguments._PageSize = 2147483647
          this.getVehicles(this.VehiclesQueryArguments)
          this.BillVehicleList =  list.data[0].items
      }

      getVehicles(args: VehiclesQueryArguments){
        this.isLoading = true
        this.billDetail.getVehicles(args)
        .pipe(
          finalize(() => {
            this.isLoading = false
          })
        )
        .subscribe(
          (success) => {
            this.getVehiclesInfos(success)
          },
          (error) => {
            console.error(error);
            return;
          }
        );
      }


      getVehiclesInfos(list){
        this.model = list.vehicles.filter(item => item.id === this.vehicleId).map(item => item.model).toString();
        this.descriptionVehicleBrandTypePt = list.vehicles.filter(item => item.id === this.vehicleId).map(item => item.descriptionVehicleBrandTypePt).toString();
        this.descriptionAxlePt = list.vehicles.filter(item => item.id === this.vehicleId).map(item => item.descriptionAxlePt).toString();
        this.serialTag = this.BillVehiclesResultDto.data[0].serial ? this.BillVehiclesResultDto.data[0].serial :  list.vehicles.filter(item => item.id === this.vehicleId).map(item => item.serialTag).toString();
        this.descriptionVehicleTypePT = list.vehicles.filter(item => item.id === this.vehicleId).map(item => item.descriptionVehicleTypePT).toString();
      }


      dismissModal() {
        this.modalService.dismissAll();
      }

      onOrderByChange(field: string) {
        if (this.BillVehiclesQueryArguments.sortField === field) {
          this.BillVehiclesQueryArguments.desc = !this.BillVehiclesQueryArguments.desc;
        } else {
          this.BillVehiclesQueryArguments.sortField = field;
          this.BillVehiclesQueryArguments.desc = true;
        }

         this.getBillDetailVehicles(this.BillVehiclesQueryArguments)

      }

      bindOrderByClass(field: string) {
        return {
          'order-by-asc': this.BillVehiclesQueryArguments.sortField == field && !this.BillVehiclesQueryArguments.desc,
          'order-by-desc': this.BillVehiclesQueryArguments.sortField == field && this.BillVehiclesQueryArguments.desc
        }
      }

      addClass(position){
        if(position % 2 === 0) {
          return "linetype1"
        }
        else{
          return "linetype2"
        }
     }

}
