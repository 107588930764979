import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogService } from './log.service';
import { ContractVehicleTag } from '@models/old/contractvehicletag/contractvehicletag.model';
import { AddContractVehicleTagResult } from '@models/old/contractvehicletag/contractvehicletagresult.model';
import { ContractVehicleTagReplacement } from '@models/old/contractvehicletag/contractvehicletagreplacement.model';
import { ContractVehicleTagReplacementResult } from '@models/old/contractvehicletag/contractvehicletagreplacementresult.model';
import { ContractVehicleTagAlterService } from '@models/old/contractvehicletag/contractvehicletagalterservice.model';
import { ContractVehicleTagAlterServiceResult } from '@models/old/contractvehicletag/contractvehicletagalterserviceresult.model';


@Injectable({
  providedIn: 'root'
})
export class ContractVehicleTagService {
  private base = environment.apiTMTag;
  private showErrors = environment.showErrors;

  constructor(protected http: HttpClient, protected logger: LogService) { }

  /**
   * Salvar Contrato Veiculo e Tag.
   * @param {ContractVehicleTag} tagrequest
   */
  public saveContractVehicleTag(entity: ContractVehicleTag): Observable<AddContractVehicleTagResult> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
      const apiUrl = `${this.base}/api/ContractVehicleTag/SaveContractVehicleTag`;
      const body = JSON.stringify(entity);
      
      const result = this.http.post<AddContractVehicleTagResult>(apiUrl, body, httpOptions);
      return result;
    } catch (e) {
      if (this.showErrors) {
        this.logger.showError(`Erro em 'ContractVehicleTag - Save ContractVehicleTag' ${e.status}: ${JSON.stringify(e.message)}`);
      }
    }
  }

   /**
  * Substituição de Tag.
  * @param {ContractVehicleTagReplacement} tagrequest
  */
  public saveContractVehicleTagReplacement(entity: ContractVehicleTagReplacement): Observable<ContractVehicleTagReplacementResult> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
      const apiUrl = `${this.base}/api/ContractVehicleTag/SaveContractVehicleTagReplacement`;
      const body = JSON.stringify(entity);
      const result = this.http.put<ContractVehicleTagReplacementResult>(apiUrl, body, httpOptions);
      return result;
    } catch (e) {
      if (this.showErrors) {
        this.logger.showError(`Erro em 'saveContractVehicleTagReplacement - Substituição de tag ' ${e.status}: ${JSON.stringify(e.message)}`);
      }
    }
  }

  public saveContractVehicleTagAlterService(entity: ContractVehicleTagAlterService, id:number): Observable<ContractVehicleTagAlterServiceResult> {
        try {
          const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          };
          const apiUrl = `${this.base}/api/ContractVehicleTag/${id}`;     
          const body = JSON.stringify(entity);
          const result = this.http.put<ContractVehicleTagAlterServiceResult>(apiUrl, body, httpOptions);
          return result;
        } catch (e) {
          if (this.showErrors) {
            this.logger.showError(`Erro em 'saveContractVehicleTagAlterService - Alteração de serviço de Tag ' ${e.status}: ${JSON.stringify(e.message)}`);
          }
        }
      } 

    }
