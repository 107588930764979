<div class="ConsultReasonComponent">
  <header class="modal-header">
    <h4 data-e2e="modal-title" class="modal-title" translate>Consult reason</h4>

    <a
      data-e2e="close-modal-top-btn"
      class="btn-close"
      (click)="dismissModal()"
    >
      <img src="assets/img/close.svg" />
    </a>
  </header>

  <div class="row-box-yellow">
    <span data-e2e="warning-box" class="box-yellow">
      <b>P</b>ara finalizar o pedido, deverá ser incluído um meio de pagamento
      válido
    </span>
  </div>

  <div class="modal-body">
    <div class="modal-content col-lg-12">
      <div class="contract-infos">
        <div data-e2e="company-name" class="companyName">
          {{ tagRequestPrepaid?.companyName }}
        </div>
        <div data-e2e="company-tax-id" class="companyDocument">
          {{ tagRequestPrepaid?.documentNumber | mask: '00.000.000/0000-00' }}
        </div>

        <div class="row row-animation"></div>
        <div class="nickname">
          <div class="box-white-small">
            <p data-e2e="company-contract-label">Contrato:</p>
            <p>
              <span data-e2e="company-contract-id" id="contractId">{{
                tagRequestPrepaid?.contractId
              }}</span>
              <span
                data-e2e="company-contract-name"
                id="contractName"
                *ngIf="tagRequestPrepaid?.contractName"
              >
                - {{ tagRequestPrepaid?.contractName }}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="row row-modal-collapse mt-7rem">
        <div class="w-100 d-flex justify-content-center">
          <section class="col">
            <img src="assets/img/ico-payment-refused2.svg" />
            <div class="leftInfo">
              <div data-e2e="refused-label" class="title">
                Pagamento recusado
              </div>
              <div data-e2e="refused-date" class="text">
                {{ orderStatus.registrationDate | date: 'dd/MM/yyyy HH:mm' }}
              </div>
            </div>
          </section>

          <section class="col-sm-auto">
            <div class="paymentCreditCard">
              <div data-e2e="payment-title" class="title">Pagamento</div>
              <div class="box-payment">
                <div class="infos-payment">
                  <div class="pt-3">
                    <b data-e2e="card-label">Cartão de crédito</b>
                  </div>

                  <div class="content-payment">
                    <div class="item-action-list"></div>
                    <div data-e2e="card-info" class="infos-card-payment">
                      <img [src]="tagRequestPrepaid.cardFlagIconPath" />
                      ●●●● ●●●● ●●●● {{ tagRequestPrepaid?.creditCard }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="col">
            <div class="rightInfo">
              <div data-e2e="refuse-reason-label" class="title">
                Atualize o meio de pagamento cadastrado ou entre em contato com
                o emissor do seu cartão de crédito
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="col-12 justify-content-center d-flex">
      <button
        data-dismiss="modal"
        data-e2e="close-modal-btn"
        aria-label="Close"
        (click)="dismissModal()"
        class="btn btn-secondary position-relative"
        type="button"
      >
        Fechar
      </button>
    </div>
  </div>
</div>
