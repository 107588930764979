import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, finalize, last } from 'rxjs/operators';
import { BillReversalPassengersQueryArguments } from '../../../../../../../bill/dto/bill-reversal-passengers-query.arguments';
import { BillReversalPassengersResultDto } from '../../../../../../../bill/dto/bill-reversal-passengers-result.dto';
import { BillHierarchyBreadcrumbResultDto } from '../../../../../../../bill/dto/bill-hierarchy-breadcrumb-result.dto';
import { BillDetailService } from '../../../../../../../bill/components/bill-detail/services/bill-detail.service';

@Component({
  selector: 'app-bill-passes-chargeback',
  templateUrl: './bill-passes-chargeback.component.html',
  styleUrls: ['./bill-passes-chargeback.component.scss']
})
export class BillPassesChargebackComponent implements OnInit {
  billId: number;
  perPages = [];
  passengersQuery:BillReversalPassengersQueryArguments;
  passesChargeBack: BillReversalPassengersResultDto;
  filterDebounce: Subject<BillReversalPassengersQueryArguments> = new Subject<BillReversalPassengersQueryArguments>();
  isLoading: boolean;
  description: string;
  isShownPeriod: boolean = false;
  isShownValue: boolean = false;
  isShownHierarchy: boolean = false;
  open: boolean;
  isOpenDropdown: boolean;
  hierarchySelected: any = 'Selecione o grupo';
  getBreadcrumbResult: BillHierarchyBreadcrumbResultDto;
  orderSelect = [{value: 'BillId', label:'Código'},{value: 'passengerDate', label: 'Data de transação'}, {value: 'processingDate', label: 'Data do processamento'}];



  constructor(private billDetail: BillDetailService, private actRoute: ActivatedRoute) {
    this.billId = this.actRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.passesChargeBack = {
      total: 0,
      passengers: [],
    };
    this.perPages = [5,10, 20, 50];
    this.passengersQuery = new BillReversalPassengersQueryArguments();
    this.passengersQuery.id = this.billId;
    this.passengersQuery.page = 1;
    this.passengersQuery.sortField = 'passengerDate';
    this.passengersQuery.pageSize = 5;
    this.passengersQuery.desc = true;
    this.passengersQuery.search = '';
    this.filterDebounce.pipe(debounceTime(300)).subscribe((filter) => this.getBillReversalAndPassengers(filter));
    this.getBillReversalAndPassengers(this.passengersQuery);
    this.getHierarchyBreadcrumb();
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.passengersQuery.sortField == field && !this.passengersQuery.desc,
      'order-by-desc': this.passengersQuery.sortField == field && this.passengersQuery.desc
    }
  }

  onOrderByChangeSelect(value: any) {
    this.onOrderByChange(value.value);
  }
  onOrderByChange(field: string) {
    if (this.passengersQuery.sortField === field) {
      this.passengersQuery.desc = !this.passengersQuery.desc;
    } else {
      this.passengersQuery.sortField = field;
      this.passengersQuery.desc = true;
    }
    this.passengersQuery.page = 1;
    this.getBillReversalAndPassengers(this.passengersQuery);

  }
  getBillReversalAndPassengers(args: BillReversalPassengersQueryArguments){
    this.isLoading = true;
    this.billDetail.getBillReversalAndPassengers(args)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success) => {
        this.passesChargeBack = success;
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }
  onPaginationChange(page: number) {
    if(this.passengersQuery.page !== page){
      this.passengersQuery.page = page;
      this.getBillReversalAndPassengers(this.passengersQuery);
    }
  }

  onPageSizeChange(value: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.passengersQuery.page = 1;
    this.passengersQuery.pageSize = value;
    this.getBillReversalAndPassengers(this.passengersQuery);
  }
  onSearchClick(event: any) {
    this.getBillReversalAndPassengers(this.passengersQuery);
  }
  onFullNameKeyup(value: any) {
    this.passengersQuery.page = 1;
    this.passengersQuery.search = value.replace("-","");
    this.filterDebounce.next(this.passengersQuery);
    this.getBillReversalAndPassengers(this.passengersQuery);

  }

  toggleShowHierarchy() {
    this.isShownHierarchy = !this.isShownHierarchy;
  }
  toggleDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
    this.isShownPeriod = false;
    this.open = false;
    this.isShownHierarchy = false;
    this.isShownValue = false;
  }
  getHierarchyBreadcrumb() {
    this.billDetail
      .getHierarchyBreadcrumb()
      .pipe(
        finalize(() => {

        })
      )
      .subscribe(
        (success) => {
          this.getBreadcrumbResult = success;
        },
        (error) => {
          return;
        }
      );
  }

  onApplyHierarchyFilter(hierarchy){
    this.description = hierarchy.description_PT;
    this.passengersQuery.hierarchyId = hierarchy.id;
    this.getBillReversalAndPassengers(this.passengersQuery);
    this.isShownHierarchy = false;
    this.hierarchySelected = this.description;
  }
  resetFilters(){
    this.hierarchySelected = null;
    this.passengersQuery.hierarchyId = null;
    this.passengersQuery.page = 1;
    this.passengersQuery.search = null;
    this.getBillReversalAndPassengers(this.passengersQuery);
  }


}
