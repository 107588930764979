<mat-toolbar class="menu-toolbar">
  <nav class="menu-toolbar__nav-left">
    @if (isMainMenuBackoffice) {
      <img
        class="menu-toolbar__logo"
        src="assets/img/toll-management.svg"
        alt="Logo Pedágio"
      />
    }
    @if (isBackofficeMenu) {
      <section class="dropdown menu-toolbar__menu-container">
        @if (menuActive && (menuActive.children.length > 0)) {
          <ng-container *ngFor="let item of menuActive.children">
            <button
              class="dropdown-toggle menu-toolbar__menu-links"
              [ngClass]="{ active: isMenuActivePath(item.path, true, item) }"
              id="menu-links-{{ generateClassName(item) }}"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [routerLink]="
            item.children.length > 0
              ? '/backoffice/' + item.children[0].path
              : '/backoffice/' + item.path
          "
              (mouseenter)="openMenuSubitems(item.title)"
            >
                        {{ item.title | translate }}
            </button>

            <div
              class="dropdown-menu menu-toolbar__submenu-container submenu-{{
            generateClassName(item)
          }}"
              *ngIf="menuSubitemsActive == item.title && item.children.length > 0"
              (mouseleave)="openMenuSubitems('')"
            >
              <span class="{{ handleClassByMenusIds(item) }}"></span>

              <button
                class="dropdown-item menu-toolbar__submenu-links"
                *ngFor="let subitem of item.children!"
                [routerLink]="'/backoffice/' + subitem.path"
              >
                            {{ subitem.title | translate }}
              </button>
            </div>
          </ng-container>
        }
      </section>
    }

    @if (isCustomerMenu) {
      <section
        *ngIf="isCustomerMenu"
        class="dropdown menu-toolbar__menu-container"
      >
        <ng-container *ngFor="let item of menuCustomer">
          <button
            class="dropdown-toggle menu-toolbar__menu-links menu-toolbar__menu-links--smaller-img-margin"
            [ngClass]="{ active: isMenuActivePath(item.path, false) }"
            id="menu-links-{{ generateClassName(item) }}"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [routerLink]="
            item.children.length > 0
              ? '/' + item.children[0].path
              : '/' + item.path
          "
            (mouseenter)="openMenuSubitems(item.title)"
          >
            <img [src]="item.iconPath" [alt]="item.icon"/>
                      <span>{{ item.title | translate }}</span>
          </button>

          <div
            class="dropdown-menu menu-toolbar__submenu-container submenu-{{
            generateClassName(item)
          }}"
            *ngIf="menuSubitemsActive == item.title && item.children.length > 0"
            (mouseleave)="openMenuSubitems('')"
          >
            <span class="{{ handleClassByMenusIds(item) }}"></span>

            <button
              class="dropdown-item menu-toolbar__submenu-links"
              *ngFor="let subitem of item.children!"
              [routerLink]="'/' + subitem.path"
            >
              {{ subitem.title }}
              <!--            {{ subitem.title | translate }}-->
            </button>
          </div>
        </ng-container>
      </section>
    }
  </nav>

<!--  <app-toll-balance *ngIf="isCustomerMenu"></app-toll-balance>-->

  <nav class="menu-toolbar__nav-right">
    @if (isBackofficeMenu) {
      <button
        class="menu-toolbar__menu-links"
        [routerLink]="'/' + mainMenuBackofficePath"
      >
        {{ "Main menu" | translate }}
      </button>
    }

    @if (hasMultipleProfile()) {
      <button class="menu-toolbar__menu-links"
        (click)="changeProfile()">
        {{ "Portal do Cliente" }}
      </button>
    }

    @if (isMainMenuBackoffice || isBackofficeMenu) {
      <button
        class="menu-toolbar__menu-links"
        (click)="logout()"
      >
        <img src="assets/icons/login-logout-icon.svg" alt="logout icon"/>
        {{ 'Exit' | translate }}
      </button>
    }
  </nav>
</mat-toolbar>
