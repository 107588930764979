export const ProfileType: any = {
  NO_PROFILE: 0,
  CUSTOMER: 1,
  RELATIONSHIP: 2,
  BACKOFFICE: 3
}

export const Profile: any = {
  BACKOFFICE: 100,
  CUSTOMER: 101,
}

export const PerfisCode: any = {
  BACKOFFICE: 'BO',
  CUSTOMER: 'CM',
  ALL_PROFILE: 'BC'
}
