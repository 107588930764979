import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, finalize } from 'rxjs';
import { FilterPageSizes, NoResultsMessages, StatusProcess, TableHeadersBatchTagActivate } from '@modules/customer/batch-management/dtos/batch-management-consts';
import { TagActivateHistoryQuery } from '@modules/customer/batch-management/dtos/tag-activate-history-query';
import { ITagActivateHistoryDto } from '@modules/customer/batch-management/dtos/tag-activate-history.dto';
import { BatchTagFileInconsistenciesModalComponent } from './components/batch-tag-file-inconsistencies-modal/batch-tag-file-inconsistencies-modal.component';
import { BatchManagementService } from '@modules/customer/batch-management/services/batch-management.service';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';

@Component({
  selector: 'app-batch-tag-activate',
  templateUrl: './batch-tag-activate.component.html',
  styleUrl: './batch-tag-activate.component.scss'
})
export class BatchTagActivateComponent extends AbstractConfigurationBase implements OnInit, OnChanges {
  readonly pageSizes = FilterPageSizes;
  readonly tableHeaders = TableHeadersBatchTagActivate;
  readonly noResultsMessages = NoResultsMessages;
  readonly statusProcess = StatusProcess;

  @Input() inReloadRequest: any;
  @Input() inHasTagsAndVehiclesAvaliable: boolean;

  filterDebounce: Subject<TagActivateHistoryQuery> = new Subject<TagActivateHistoryQuery>();
  isLoadingTableData: boolean;
  historyQuery: TagActivateHistoryQuery;
  historyResultList: ITagActivateHistoryDto[];
  totalItems: number;

  constructor(
    private _modalService: NgbModal,
    private _batchManagementService: BatchManagementService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.settingsInitClass();
    this.handleTagActivateHistory();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!changes.inReloadRequest.firstChange) {
      this.historyQuery = new TagActivateHistoryQuery();
      this.filterDebounce.next(this.historyQuery);
    }
  }

  private settingsInitClass() {
    this.historyQuery = new TagActivateHistoryQuery();
    this.historyResultList = [];
    this.inReloadRequest = 0;

    this.filterDebounce
      .pipe(debounceTime(800))
      .subscribe(() => this.handleTagActivateHistory());
  }

  private getContractId(): number {
    return Number.parseInt(this.authService.getContractId());
  }

  private handleTagActivateHistory() {
    this.isLoadingTableData = true;
    this.historyQuery.contractId = this.getContractId();

    this.requestTagActivateHistory();
  }

  private requestTagActivateHistory() {
    this._batchManagementService.getHistoryTagActivate(this.historyQuery)
      .pipe(
        finalize(() => {
          this.isLoadingTableData = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.totalItems = success.totalItems;
          this.historyResultList = success.items;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  onPageSizeChange(value: any) {
    this.historyQuery.page = 1;
    this.historyQuery.pageSize = value;

    this.handleTagActivateHistory();
  }

  onOrderByChange(field: string) {
    if (this.historyQuery.sortField === field) {
      this.historyQuery.desc = !this.historyQuery.desc;
    }
    else {
      this.historyQuery.sortField = field;
      this.historyQuery.desc = true;
    }

    this.historyQuery.page = 1;
    this.handleTagActivateHistory();
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.historyQuery.sortField == field && !this.historyQuery.desc,
      'order-by-desc':
        this.historyQuery.sortField == field && this.historyQuery.desc,
    };
  }

  onPaginationChange(page: number) {
    this.historyQuery.page = page;
    this.handleTagActivateHistory();
  }

  openBatchTagFileInconsistenciesModal(item: ITagActivateHistoryDto) {
    const modal = this._modalService.open(BatchTagFileInconsistenciesModalComponent);

    modal.componentInstance.inFileData = item;
    modal.componentInstance.inHasTagsAndVehiclesAvaliable = this.inHasTagsAndVehiclesAvaliable;

    modal.componentInstance.outEventReloadRequest.subscribe(() => {
      this.historyQuery = new TagActivateHistoryQuery();
      this.filterDebounce.next(this.historyQuery);
    });
  }

}
