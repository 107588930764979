<div class="detailOrderComponent">
  <div class="Dashboard container-fluid page-myaccount">
    <div class="row">
      <div class="col-12 Dashboard_Overview header-page">
        <div class="row">
          <div class="col-lg-3 div-header-title">
            <h1 data-e2e="page-title">
              / PEDIDO:
              <span data-e2e="order-id-title" id="orderId">{{
                orderDetail.orderId
                }}</span>
            </h1>
          </div>

          <div class="col-lg-3">
            <a data-e2e="order-link" [routerLink]="handleRouterByProfile()">
              <button id="returnToOrdersButton" class="btn btn-repom btn-repom-user float-right"
                data-target="#userIncludeModal" data-toggle="modal" type="button">
                <i class="ico ico-arrow-left"></i>
                voltar para os pedidos
                <span class="btn-animation"></span>
              </button>
            </a>
          </div>

          <div class="col-lg-4" *controlAccessCustomer>
            <div class="form-header-search">

              <app-search-form-inline
                [placeholder]="'número do pedido'"

              />
            </div>
          </div>

          <div class="col-lg-2" *controlAccessCustomer>
            <a>
              <button data-e2e="new-order-link" *accessControl="101170" [routerLink]="['/novo-pedido']" buttonBlock
                [ContractBlock]="[2,3,6]" id="RedirectToNewOrderButton" class="btn btn-repom btn-repom-user"
                data-target="#userIncludeModal" data-toggle="modal" type="button">
                <i class="ico ico-shopping-car"></i>
                novo pedido
                <span class="btn-animation"></span>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 Dashboard_col_1">
        <div class="container-repom timelineStatus" style="padding-bottom: 0">
          <div class="box-title">
            <span data-e2e="status-box-title">Status</span>
          </div>

          <!-- Pré-pago-->
          <div class="box-body" *modalityType="1">
            <div class="row">
              <div class="arrow-top" [ngClass]="getArrowClass(1)">
                <a><img src="/assets/img/bg-arrow-down.svg" /></a>
              </div>

              <div class="col-sm-auto timelinecolumn PrePaidTimeline">
                <div class="checkout-wrap">
                  <ul class="checkout-bar" [ngClass]="received == true ? 'received' : ''">
                    <li *ngFor="
                        let order of orderDetail.getTagRequestTrackingQueryResults;
                        let count = index
                      " [ngClass]="GetClassTimeline(count, order.isActive)">
                      <div class="bg-arrow-down"></div>

                      <!-- <div id="checkReason" *accessControl="101223">
                        <button
                        [attr.data-e2e]="'retry-btn-' + count"
                        (click)="tryAgain(order)"
                        *ngIf="order.id === 12"
                        [disabled]="!order.isActive"
                        class="btn btn-repom btn-repom-user"
                        type="button"
                      >
                        Tentar novamente
                        <span class="btn-animation"></span>
                      </button>
                      </div> -->


                      <i *ngIf="order.id !== 5" [ngClass]="GetClassTimelineImage(order.id)"></i>

                      <button [attr.data-e2e]="'track-btn-' + count" id="trackButton" *ngIf="order.id === 5"
                        (click)="TrackOrder()" [disabled]="buttonDisabled"
                        class="btn btn-repom btn-repom-user tracking-btn" data-target="#userIncludeModal"
                        data-toggle="modal" type="button">
                        <i class="ico ico-Tracking-progress"></i>
                        rastrear <span class="btn-animation"></span>
                      </button>

                      <b [attr.data-e2e]="'order-description-' + count">{{
                        order.description
                        }}</b>

                      <p *ngIf="order.registrationDate === null">
                        <br />
                      </p>

                      <p [attr.data-e2e]="'order-registration-date' + count" *ngIf="order.registrationDate !== null">
                        {{ order.registrationDate | date : "dd/MM/yyyy HH:mm" }}
                      </p>

                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--Pós-pago-->
          <div class="box-body" *modalityType="2">
            <div class="row">
              <div class="arrow-top" [ngClass]="getArrowClass(2)">
                <a><img src="/assets/img/bg-arrow-down.svg" /></a>
              </div>

              <div class="col-sm-auto timelinecolumn">
                <div class="checkout-wrap">
                  <ul class="checkout-bar" [ngClass]="received == true ? 'received' : ''">
                    <li *ngFor="
                        let order of orderDetail.getTagRequestTrackingQueryResults;
                        let count = index
                      " [ngClass]="GetClassTimeline(count, order.isActive)">
                      <div class="bg-arrow-down"></div>

                      <i *ngIf="order.id !== 5" [ngClass]="GetClassTimelineImage(order.id)"></i>

                      <button id="trackButton" *ngIf="order.id === 5" (click)="TrackOrder()" [disabled]="buttonDisabled"
                        class="btn btn-repom btn-repom-user tracking-btn" data-target="#userIncludeModal"
                        data-toggle="modal" type="button">
                        <i class="ico ico-Tracking-progress"></i>
                        rastrear
                        <span class="btn-animation"></span>
                      </button>

                      <b [attr.data-e2e]="'order-descriptin-' + count">{{
                        order.description
                        }}</b>

                      <p *ngIf="order.registrationDate === null">
                        <br />
                      </p>

                      <p [attr.data-e2e]="'order-registration-date-' + count" *ngIf="order.registrationDate !== null">
                        {{ order.registrationDate | date : "dd/MM/yyyy HH:mm" }}
                      </p>

                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 Dashboard_col_1">
          <div class="container-repom">
            <div class="box-title">
              <span data-e2e="order-summary-title">Resumo</span>
            </div>

            <div class="box-body">
              <div class="row conteiner-geral-resumo">
                <div class="col-lg-4">
                  <label data-e2e="recipient-title" class="big"><strong>TAGS SOLICITADAS</strong></label>
                  <div class="box-gray-detail">
                    <div>
                      <div class="div-geral-tag">
                        <p>Tag</p>
                        <p class="p-info"><strong>{{
                            orderDetail.amount
                            }} tags</strong></p>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-lg-4">
                  <div>
                    <label data-e2e="recipient-title" class="big"><strong>RESPONSÁVEL PELO RECEBIMENTO</strong></label>
                  </div>

                  <div class="form-icon no-margin">
                    <div>
                      <div class="div-geral-text-icon">
                        <img src="../../../../../assets/svg/user.svg" class="icons-geral">
                        <p class="p-info">{{ orderDetail.fullName }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="form-icon" style="margin-top: 0px">
                    <div>
                      <div class="div-geral-text-icon">
                        <img src="../../../../../assets/img/ico-phone-talk.svg" class="icons-geral">
                        <p class="p-info">{{ orderDetail.phoneNumber| phone }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div>
                    <label data-e2e="delivery-address-label" class="big"><strong>ENDEREÇO DE ENTREGA</strong></label>
                  </div>

                  <b data-e2e="regular-address-msg" *ngIf="orderDetail.addressTypeId == 1">
                    Endereço comum
                  </b>

                  <b data-e2e="mailbox-msg" *ngIf="orderDetail.addressTypeId == 2">
                    Caixa Postal
                  </b>

                  <p class="pbox-address" *ngIf="orderDetail.addressTypeId == 1">
                    <span data-e2e="address-data" id="streetAddress">{{
                      orderDetail.streetAddress
                      }}</span>,
                    <span data-e2e="address-number-data" id="number">{{ orderDetail.number }},</span>
                    <span data-e2e="address-complementary-data" id="streetAddressLine2">
                      {{ orderDetail.streetAddressLine2 }}</span>
                    <span id="space">{{
                      orderDetail.streetAddressLine2 === null ||
                      orderDetail.streetAddressLine2 === ""
                      ? ""
                      : ","
                      }}</span>
                    <span data-e2e="address-district-data" id="district">
                      {{ orderDetail.district }}</span>
                    -
                    <span data-e2e="city-data" id="city">{{
                      orderDetail.city
                      }}</span>
                    -
                    <span data-e2e="federal-unit-data" id="federatedUnit">{{
                      orderDetail.federatedUnit
                      }}</span>
                    - CEP:
                    <span data-e2e="zipcode-data" id="zipCode">{{
                      orderDetail.zipCode | cepFormat }}</span>
                    <br />

                    <span data-e2e="landmark-data" id="landmark">{{
                      orderDetail.landmark
                      }}</span>
                  </p>

                  <p data-e2e="mailbox-code-msg" class="pbox-address" *ngIf="orderDetail.addressTypeId == 2">
                    Código da caixa postal:
                    <span data-e2e="mailbox-data" id="mailBox">{{
                      orderDetail.mailBox
                      }}</span>
                    <br />
                    <span data-e2e="city-data" id="city">{{
                      orderDetail.city
                      }}</span>
                    -
                    <span data-e2e="federal-unit-data" id="federatedUnit">{{
                      orderDetail.federatedUnit
                      }}</span>
                    - CEP:
                    <span data-e2e="zipcode-data" id="zipCode">{{
                      orderDetail.zipCode
                      | cepFormat }}</span>
                  </p>
                </div>

              </div>

              <div class="row">
                <div class="col-lg-12 div-espacamento-laterais">
                  <div class="row line-gray blue-light div-geral-card">
                    <div data-e2e="tag-total-value-title" class="col-lg-9">
                      Valor das tags
                    </div>
                    <div data-e2e="tag-total-value-data" class="col-lg-1 price" id="totalTagValue">
                      <strong>R$ {{ orderDetail.totalTagValue | number : ".2" : "pt-br" }}</strong>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="row line-gray blue-light div-geral-card">
                      <div data-e2e="tag-total-value-title" class="col-lg-9">
                        Frete
                      </div>
                      <div data-e2e="tag-total-value-data" class="col-lg-1 price" id="totalTagValue">
                        <strong>R$ {{ orderDetail.shippingValue | number : ".2" : "pt-br"
                          }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row div-espacamento-laterais">
                <div class="col-lg-12 div-espacamento-laterais">
                  <h6 data-e2e="final-total-title">
                    <strong>DESCONTO</strong>
                  </h6>
                </div>
              </div>

              <div class="row">
                <div>
                  <div class="row">
                    <div class="col-lg-12 div-espacamento-laterais">
                      <div class="row line-gray blue-light div-geral-card">
                        <div data-e2e="tag-discount-title" class="col-lg-9">
                          Desconto de tags
                        </div>
                        <div data-e2e="tag-discount-data" class="col-lg-1 price" id="totalTagValueDiscount">
                          <strong>{{ orderDetail.totalTagValueDiscount !== 0 ? '- ' : '' }}R$ {{
                            orderDetail.totalTagValueDiscount | number : ".2" : "pt-br" }}</strong>
                        </div>

                      </div>

                      <div class="row line-gray blue-light div-geral-card">
                        <div data-e2e="freight-discount-title" class="col-lg-9">
                          Desconto de frete
                        </div>

                        <div data-e2e="freight-discount-data" class="col-lg-1 price" id="totalShippingDiscount">
                          <strong>{{ orderDetail.totalShippingDiscount !== 0 ? '- ' : '' }}R$ {{
                            orderDetail.totalShippingDiscount | number : ".2" : "pt-br" }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 div-espacamento-laterais">
                  <h6 data-e2e="final-total-title">
                    <strong>VALOR TOTAL</strong>
                  </h6>
                  <div class="row line-gray dark-blue div-geral-card">
                    <div data-e2e="total-due-amount-title" class="col-lg-10">
                      Total do pedido
                    </div>
                    <div data-e2e="total-due-amount-data" class="col-lg-1 price" id="totalAmountPayable">
                      <strong> R$ {{
                        orderDetail.totalAmountPayable | number : ".2" : "pt-br"
                        }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
