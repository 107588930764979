import { Component, Output, Input, EventEmitter } from '@angular/core';
import { FiltersService } from '../../filters.service';
import { FiltersComponent } from '../../filters.component';
import {EFiltersIds} from "@models/filters-backoffice.model";

@Component({
  selector: 'app-radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['../../filters.component.scss', './radio-filter.component.scss'],
})
export class RadioFilterComponent {
  @Input() labelTitle: string = 'Passo';
  @Input() stepsData: [];
  @Input() idFilter: EFiltersIds;
  @Input() textValue: string;

  @Output() getFilter = new EventEmitter<number>();
  @Output() selectedStep = new EventEmitter<number>();

  radioSelected: number;

  constructor(
    private _filtersService: FiltersService,
    public filtersComponent: FiltersComponent,
  ) {}

  ngOnInit(): void {
    this.textValue = this._filtersService.radioStep$.getValue();

    if (this.textValue !== 'Selecione') {
      this.radioSelected = this._filtersService.formatNameToId(
        this.textValue,
        this.stepsData,
      );
      this.onSelection(this.radioSelected);
    }
  }

  showFilterContent() {
    this._filtersService.activeFilter = this.idFilter;

    this.filtersComponent.closeOtherFilters(this.idFilter);
  }

  onSelection(radio: number) {
    this.radioSelected = radio;
    this.selectedStep.emit(this.radioSelected);
    this.textValue = this._filtersService.formatIdToName(radio, this.stepsData);

    this._filtersService.radioStep$.next(this.textValue);
    this.getFilter.emit(radio);
  }
}
