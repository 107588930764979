import { Component, Input } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormValidatorMessageService } from "@services/form-validators/form-validator-message.service";
import { finalize } from "rxjs";
import { HolidaysCalendarService } from "../../services/holidays-calendar.service";
import { IHoliday } from "../../models/holidays.dto";
import { Router } from "@angular/router";
import { RoutesPaths } from "src/app/core/utils/routes/routes-paths";

@Component({
  selector: "app-action-success-modal",
  templateUrl: "./action-success-modal.component.html",
  styleUrl: "./action-success-modal.component.scss",
})
export class ActionSuccessModalComponent {

  @Input() type: 'exclude' | 'include';
  @Input() holiday: IHoliday;
  @Input() success: any;
  @Input() many: boolean;

  constructor(private _modalService: NgbModal, private _router : Router) {}



  dismissModal() {
    this._modalService.dismissAll();
    this._router.navigate(['/backoffice/'+ RoutesPaths.HOLIDAYS_CALENDAR])
  }

}
