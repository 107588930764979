import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContractualAlterationRoutingModule } from './routes/contractual-alteration-routing.module';
import { ContractualAlterationComponent } from './contractual-alteration.component';
import { LimitChangeModalComponent } from './components/limit-change-modal/limit-change-modal.component';
import { NgxCurrencyDirective } from 'ngx-currency';

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    ContractualAlterationRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxCurrencyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [ContractualAlterationComponent, LimitChangeModalComponent],
  providers: [provideNgxMask()],
})
export class ContractualAlterationModule {}
