import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShellPagesRoutingModule } from './routes/shell-pages-routing.module';
import { ShellPagesComponent } from './shell-pages.component';
import {SharedModule} from "primeng/api";
import {CoreModule} from "../../../core/core.module";
import {FooterModule} from "@modules/commom/footer/footer.module";
import {HeaderModule} from "@modules/commom/header/header.module";

@NgModule({
  declarations: [ShellPagesComponent],
  imports: [
    CommonModule,
    // TranslateModule,
    RouterModule,
    ShellPagesRoutingModule,
    CoreModule,
    SharedModule,
    HeaderModule,
    FooterModule
  ]
})
export class ShellPagesModule { }
