import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { MyaccountComponent } from '../myaccount.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "minha-conta",
      component: MyaccountComponent,
      canActivate: [authGuardCustomer],
      data: {
        roles: "101134",
        backofficeRoles: "0",
        contractRequired: true
      },
    }
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class MyaccountRoutingModule {}
