import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from "@angular/core";
import { AuthService } from "src/app/core/auth/auth.service";

@Directive({
  selector: "[accessControl]",
})
export class AccessControlDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    protected authService: AuthService
  ){}

  @Input()
  set accessControl(val) {
    let permission = val.toString()
    this.checkAccess(permission);
  }

  ngOnInit() {}

  checkAccess(permission) {

   let authorize = this.authService.checkRoles(permission)

    if (authorize == true) {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else{
        this.viewContainer.clear();
    }

  }

}
