import { HttpParams } from "@angular/common/http";

export class AdvanceVprTransaction {
  licensePlate: string;
  nickname: string;
  processingDate: Date;
  totalAmount: 0;
  billId: 0;
  contractId: 0;
  documentNumber: string;
  fullName: string;
}

export class BillAdvanceDetailsDto {
  numberOfItems: number;
  getAnticipationReportQueryResult: AdvanceVprTransaction[];
}

export class BillAdvanceDetailsQueryArguments {
  public SortOrder: boolean;
  public sortField: string="";

  constructor(
    public billId: number,
    public search: string="",
    public page: number = 1,
    public pageSize: number = 10,
    public desc: boolean = false
  ) {}

  get httpParams() {
    let httpParams = new HttpParams()
      .set("billId", this.billId?.toString())
      .set("page", this.page?.toString())
      .set("pageSize", this.pageSize?.toString())
      .append("sortOrder", this.desc ? "1" : "0");

    if (this.sortField.length > 0) {
      httpParams = httpParams.append("sortField", this.sortField);
    }
    if (this.search) {
      httpParams = httpParams.set("search", this.search);
    }

    return httpParams;
  }
}
