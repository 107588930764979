import {Directive, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {UserProfileService} from "@services/user-profile.service";

@Directive({
  selector: "[controlAccessCustomer]",
   standalone: true
})
export class ControlCustomerProfileAccessDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private manageUserProfile: UserProfileService
              ) {
  }

  ngOnInit(): void {
      const isCustomer = this.manageUserProfile.isCustomerOrRelationshipProfile();

    isCustomer ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }


}
