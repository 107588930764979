<div class="add-hierarchy-vehicle">
    <div class="modal-header">
      <div class="box-title">
        <span data-e2e="page-title">Detalhamento dos veículos associados</span>
      </div>

      <button data-e2e="page-close-btn" type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- start step-1 -->
    <div class="d-flex align-items-center">
      <div class="w-100 text-center">
        <div class="box-breadcrumb">
          <div class="row justify-content-center align-items-center">
            <p data-e2e="hierarchy-vehicle-breadcrumb" class="col-sm-auto" id="HierarchyVehiclesBreadCrumb">{{ breadCrumb }}</p>
            <p data-e2e="hierarchy-vehicle-description" class="col-sm-auto" id="HierarchyVehiclesDescription_PT">{{ description_PT }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container col-md-12 mt-5">
      <div class="">
        <div class="card-body pt-0">
          <div class="title d-flex align-items-center">
            <form novalidate class="p-4">

              <app-outlined-select
              [title] = "'Quantidade de linhas'"
              [beforeTextValue]="'exibir '"
              [afterTextValue]="' linhas'"
              [values]="perPages"
              [disableRipple]="true"
              (valueChange)="onPageSizeChange($event)"
              >

              </app-outlined-select>
            </form>
          </div>
          <div class="px-4">

            <!-- // TODO: Verificar a possibilidade de separar essa table em um componente para ficar mais organizada -->
            <table class="table vehicles">
              <thead class="thead">
                <tr>
                  <th [ngClass]="bindOrderByClass('licensePlate')">
                    <button data-e2e="plate-order-btn" class="btn-order-table" translate (click)="onOrderByChange('licensePlate')">Veículo</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('nickName')">
                    <button data-e2e="nickname-order-btn" class="btn-order-table" translate (click)="onOrderByChange('nickName')">Apelido</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('descriptionVehicleTypePT')">
                    <button data-e2e="description-order-btn" class="btn-order-table" translate
                      (click)="onOrderByChange('descriptionVehicleTypePT')">Tipo</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('descriptionAxlePt')">
                    <button data-e2e="axel-order-btn" class="btn-order-table" translate
                      (click)="onOrderByChange('descriptionAxlePt')">Eixos</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('hierarchyName')">
                    <button data-e2e="hierarchy-order-btn" class="btn-order-table" translate
                      (click)="onOrderByChange('hierarchyName')">Hierarquia</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('aggregateDriver')">
                    <button data-e2e="aggragated-order-btn" class="btn-order-table" translate
                      (click)="onOrderByChange('aggregateDriver')">Agregado</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('descriptioncontractVehicleTagStatusTypeIdPt')">
                    <button data-e2e="status-order-btn" class="btn-order-table" translate
                      (click)="onOrderByChange('descriptioncontractVehicleTagStatusTypeIdPt')">Status</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>

                  <th [ngClass]="bindOrderByClass('serialTag')">
                    <button data-e2e="tag-order-btn" class="btn-order-table" translate (click)="onOrderByChange('serialTag')">Tag</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('startDate')">
                    <button data-e2e="start-date-order-btn" class="btn-order-table" translate
                      (click)="onOrderByChange('startDate')">Ativação</button>
                    <div class="round-order">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                </tr>
              </thead>

              <div class="container-loading" *ngIf="isLoading">
                <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
              </div>

              <tbody>
                <tr *ngFor="let vehicle of getVehiclesQueryResult.vehicles; let i = index">
                  <td [attr.data-e2e]="'vehicle-plate-' + i" id="HierarchyVehiclesLicensePlate{{i}}">
                    {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}
                  </td>
                  <td [attr.data-e2e]="'vehicle-nickname-' + i" id="HierarchyVehiclesNickName{{i}}">{{ vehicle?.nickName }}</td>
                  <td [attr.data-e2e]="'vehicle-description-' + i" id="HierarchyVehiclesDescriptionVehicleTypePT{{i}}">{{ vehicle?.descriptionVehicleTypePT }}</td>
                  <td [attr.data-e2e]="'vehicle-axel-' + i" id="HierarchyVehiclesDescriptionAxlePt{{i}}">{{ vehicle?.descriptionAxlePt }}</td>
                  <td [attr.data-e2e]="'vehicle-hierarchy-' + i" id="HierarchyVehiclesHierarchyName{{i}}">{{ vehicle?.hierarchyName }}</td>
                  <td id="HierarchyVehiclesAggregateDriver{{i}}">
                    <div class="d-flex align-items-center justify-content-center" *ngIf="vehicle.aggregateDriver">
                      <button [attr.data-e2e]="'vehicle-driver-popover-btn' + i" type="button" class="btn-popover" [popover]="cnpjCpfPopover" [outsideClick]="true" >
                        <img src="../../../assets/svg/icon-info.svg" alt="" />
                      </button>
                      <span [attr.data-e2e]="'vehicle-has-driver-msg-' + i" class="custom-text text-uppercase font-weight-bold color-default-font"
                        translate>Sim</span>
                    </div>

                    <ng-template #cnpjCpfPopover placement="right" [outsideClick]="true">
                      <span [attr.data-e2e]="'vehicle-driver-doc-msg-' + i" *ngIf="vehicle?.documentNumber?.length == 11">
                        CPF: <span [attr.data-e2e]="'vehicle-driver-doc-' + i" id="documentNumber{{i}}">{{ vehicle?.documentNumber | mask: '000.000.000-00' }}</span>
                      </span>
                      <span [attr.data-e2e]="'vehicle-driver-doc-msg-' + i" *ngIf="vehicle?.documentNumber?.length == 14">
                        CNPJ: <span [attr.data-e2e]="'vehicle-driver-doc-' + i" id="documentNumber{{i}}">{{ vehicle?.documentNumber | mask: '00.000.000/0000-00' }}</span>
                      </span>
                      <div [attr.data-e2e]="'vehicle-driver-rntrc-msg' + i" *ngIf="vehicle?.rntrc">
                        RNTRC: <span [attr.data-e2e]="'vehicle-driver-rntrc-' + i" id="rntrc{{i}}">{{ vehicle?.rntrc }}</span>
                      </div>
                    </ng-template>

                    <span [attr.data-e2e]="'vehicle-no-driver-msg-' + i" class="custom-text text-uppercase font-weight-bold color-default-font"
                      *ngIf="!vehicle?.aggregateDriver" translate>Não</span>
                  </td>
                  <td>
                    <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 0" ngbTooltip="Sem tag" placement="top">
                      <img src="assets/svg/link.svg" />
                    </div>
                    <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 1"
                      ngbTooltip="Tag ativa e liberada para utilização" placement="top">
                      <img src="assets/svg/ticked.svg" />

                    </div>
                    <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 2"
                      ngbTooltip="Tag bloqueada temporariamente" placement="top">
                      <img src="assets/img/padlock-vehicles.svg" />
                    </div>
                  </td>
                  <td>
                    <span [attr.data-e2e]="'vehicle-tag-' + i" *ngIf="vehicle?.hasTag" class="custom-text" id="HierarchyVehiclesSerialTag{{i}}">{{ vehicle?.serialTag }}</span>
                    <div *ngIf="!vehicle?.hasTag">
                      <!-- <button type="button" class="btn-activeTag" [routerLink]="['/ativar-tags',vehicle.id]" disabled>
                        <img class="w-25" src="../../../assets/svg/ticked.svg" alt="" />
                        ativar
                        <span class="btn-animation"></span>
                      </button> -->
                    </div>
                  </td>
                  <td>
                    <span [attr.data-e2e]="'vehicle-start-date-' + i" class="custom-text font-weight-bold" id="HierarchyVehiclesStartDate{{i}}">{{ vehicle?.startDate | date: 'dd/MM/yyyy' }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="getVehiclesQueryResult.total == 0">
              <div data-e2e="empty-vehicle-search-msg" colspan="12" class="text-center w-100 py-4" translate>Nenhum resultado encontrado.</div>
            </div>

            <div class="pagination-custom" *ngIf="getVehiclesQueryResult.total != 0">
              <app-pagination
                [totalItems]="getVehiclesQueryResult.total"
                [itemsPerPage]="getVehiclesQueryArguments.pageSize"
                [maxPages]="5"
                [currentPage]="getVehiclesQueryArguments.page"
                (pageChangeEvent)="onPaginationChange($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>










  </div>
  <!-- <pre> {{vehicleHierarchyForm.value | json}} </pre> -->
