import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  Input,
} from "@angular/core";
import {ContractService} from "@services/contract.service";

@Directive({
  selector: "[modalityType]",
})
export class ModalityTypeDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private contractService: ContractService,
  ) {}
  /**
   * Select the modality:
   * 1 - Prepago;
   * 2 - Póspago;
   */
  @Input()
  set modalityType(val) {
    this.display(val);
  }

  ngOnInit() {}

  display(modalityTypeId) {
    const display: boolean = this.contractService.getContractModalityTypeId() == modalityTypeId;
    display ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
