import { Routes, Route } from '@angular/router';
import { PageComponent } from '../page.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

/**
 * Provides helper methods to create routes.
 */
export class PageService {
/**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: PageComponent,
      children: routes,
      canActivate: [authGuardCustomer],
      data: { reuse: true },
    };
  }

}
