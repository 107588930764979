import { Component, EventEmitter, Input, Output } from '@angular/core';
import { formatDate } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';

import { Dto } from '@models/dto.model';
import {UpdateBlockTypeDto} from "@modules/backoffice/financial-blocking-unblocking/dtos/update-block-type.dto";
import {
  FeedbackFinancialBlockDto
} from "@modules/backoffice/financial-blocking-unblocking/dtos/feedback-financial-block.dto";
import {
  FinancialBlockingUnblockingService
} from "@modules/backoffice/financial-blocking-unblocking/services/financial-blocking-unblocking.service";
import {
  FiancialBlockResponseDto
} from "@modules/backoffice/financial-blocking-unblocking/dtos/financial-block-response.dto";
import {
  FeedbackFinancialBlockModalComponent
} from "@modules/backoffice/financial-blocking-unblocking/components/feedback-financial-block-modal/feedback-financial-block-modal.component";

const TITLE: string = 'CONFIGURAÇÃO DE BLOQUEIO FINANCEIRO';
const IMG_PATH: string = '/assets/img/feedback-success-modal.png';
const SUBTITLE_MANUAL: string = 'BLOQUEIO FINANCEIRO MANUAL ATIVO';
const SUBTITLE_AUTOMATIC: string = 'BLOQUEIO FINANCEIRO AUTOMÁTICO ATIVO';

@Component({
  selector: 'app-confirmation-block-type-modal',
  templateUrl: './confirmation-block-type-modal.component.html',
  styleUrls: ['./confirmation-block-type-modal.component.scss'],
})
export class ConfirmationBlockTypeModalComponent {
  @Input() isChangingToBlockManual: boolean;
  @Input() daysToBlock: number;
  @Input() updateBlockTypeDto: UpdateBlockTypeDto;
  @Output() eventReloadPage: EventEmitter<void> = new EventEmitter();

  feedbackFinancialBlockDto: FeedbackFinancialBlockDto;
  isLoading: boolean;

  constructor(
    private _modalService: NgbModal,
    private _financialBlockingUnblockingService: FinancialBlockingUnblockingService,
  ) {
    this.feedbackFinancialBlockDto = new FeedbackFinancialBlockDto();
  }

  dismissModal() {
    this._modalService.dismissAll();
  }

  updateContractBlockType() {
    this.isLoading = true;

    this._financialBlockingUnblockingService
      .updateContractBlockType(this.updateBlockTypeDto)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (success) => {
          this.assignResponseAttributes(success);

          this.dismissModal();

          this.assignModalAttributes();

          this.eventReloadPage.emit();
        },
        error: (error) => {
          console.error(error);
          return;
        },
      });
  }

  assignResponseAttributes(success: Dto<FiancialBlockResponseDto>) {
    this.feedbackFinancialBlockDto.changeDate = formatDate(
      success.data.changeDate,
      'dd/MM/yyyy HH:mm',
      'en-US',
    );

    this.feedbackFinancialBlockDto.changeUserName = success.data.changeUserName;
  }

  assignModalAttributes() {
    const modal = this._modalService.open(FeedbackFinancialBlockModalComponent);

    modal.componentInstance.title = TITLE;
    modal.componentInstance.imgPath = IMG_PATH;
    modal.componentInstance.textBold =
      'Protocolo: ' + this.updateBlockTypeDto.protocol;
    modal.componentInstance.textGray =
      this.feedbackFinancialBlockDto.changeDate +
      ' por ' +
      this.feedbackFinancialBlockDto.changeUserName;
    modal.componentInstance.subtitle = this.isChangingToBlockManual
      ? SUBTITLE_MANUAL
      : SUBTITLE_AUTOMATIC;
  }
}
