<div class="ChargebackApproveComponent">
  <div class="modal-header">
    <h4
      data-e2e="modal-title"
      class="modal-title"
      translate
      *ngIf="option == true"
    >
      Chargeback Aprovado
    </h4>
    <h4
      data-e2e="modal-title"
      class="modal-title"
      translate
      *ngIf="option == false"
    >
      Chargeback Reprovado
    </h4>
    <a
      data-e2e="close-modal-top-btn"
      class="btn-close"
      (click)="dismissModal()"
    >
      <img src="assets/svg/close.svg" />
    </a>
  </div>

  <div class="modal-body" *ngIf="option == true">
    <div class="modal-main">
      <img src="assets/img/chargebackApprove.svg" alt="" />
      <p class="text-reason" translate>
        O chargeback para a transação de
        <strong translate>{{ transaction?.transactionTypeDescription }}</strong>
        foi aprovado e o contrato está desbloqueado.
      </p>
    </div>
  </div>
  <!-- Reprovado -->
  <div class="modal-body" *ngIf="option == false">
    <div class="modal-main">
      <img src="assets/img/chargebackRepprove.svg" alt="" />

      <h4>O contrato permanece bloqueado.</h4>
      <h5>Motivo:</h5>
      <p class="text-reason-repprove" translate>
        {{ reason }}
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <div class="content-buttons w-100">
      <button
        data-e2e="cancel"
        class="btn btn-secondary custom"
        (click)="dismissModal()"
        translate
      >
        Fechar
      </button>
    </div>
  </div>
</div>
