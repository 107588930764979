import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() totalItems: number;
  @Input() itemsPerPage: number;
  @Input() maxPages: number;
  @Input() currentPage: number;

  @Output() pageChangeEvent: EventEmitter<number> = new EventEmitter();

  onPageChange(page: number) {
    this.currentPage = page;
    this.pageChangeEvent.emit(this.currentPage);
  }
}
