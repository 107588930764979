import { Component, OnInit, Input, Pipe, PipeTransform  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { GetFromVehiclesQueryResult } from '../../../tickets/dtos/getFromVehiclesQueryResult.dto';
import { GetTransactionsVehiclesQueryArguments } from '../../../tickets/dtos/getVehiclesQueryArguments.dto';
import { TicketsService } from '../../../tickets/services/tickets.service';


@Component({
  selector: 'app-ticket-vehicle-modal',
  templateUrl: './ticket-vehicle-modal.component.html',
  styleUrls: ['./ticket-vehicle-modal.component.css']
})


export class TicketVehicleModalComponent implements OnInit {

    @Input() startDate;
    @Input() endDate;
    @Input() licensePlate;
    @Input() contractVehicleId;
    @Input() hierarchyDescription;
    getTransactionsVehiclesQueryArguments: GetTransactionsVehiclesQueryArguments;
    getFromVehiclesQueryResult: GetFromVehiclesQueryResult;
    public isLoading;
    public oldDate;
    public axleWheelTypeDescription;
    public brand;
    public completeHierarchicalDescription;
    public nickName;
    public tag;
    public type;
    public vehicleModel;
    public totalVehicleValue;

    constructor(private modalService: NgbModal,private ticketsService: TicketsService) {
     }

      ngOnInit() {
        //inicializa parâmetros
        this.getTransactionsVehiclesQueryArguments = new GetTransactionsVehiclesQueryArguments();
        //se nenhuma data foi filtrada, filtra os últimos 30 dias
        if(typeof this.startDate === 'undefined' || typeof this.endDate === 'undefined'){
          this.oldDate = new Date();
          this.oldDate.setDate( this.oldDate.getDate() - 30);
          this.getTransactionsVehiclesQueryArguments.startDate = this.oldDate;
          this.getTransactionsVehiclesQueryArguments.endDate = new Date();
        }
        else{
          this.getTransactionsVehiclesQueryArguments.startDate = this.startDate;
          this.getTransactionsVehiclesQueryArguments.endDate = this.endDate;
        }

        this.getTransactionsVehiclesQueryArguments.page = 1;
        this.getTransactionsVehiclesQueryArguments.pageSize = 10;
        this.getTransactionsVehiclesQueryArguments.desc = false;
        this.getTransactionsVehiclesQueryArguments.orderBy = "passengerDate";
        this.getTransactionsVehiclesQueryArguments.filter = 1;
        this.getTransactionsVehiclesQueryArguments.search = "";

      // lista de transações
       this.getVehicleQuery(this.licensePlate,this.getTransactionsVehiclesQueryArguments)
      }

      getVehicleQuery(licensePlate: string, args: GetTransactionsVehiclesQueryArguments) {
        this.isLoading = true;
        this.ticketsService
          .getVehiclesQuery(licensePlate, args)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(
            (success) => {
              this.VehicleList(success);
            },
            (error) => {
              console.error(error)
            }
          );
      }

      VehicleList(list){
        this.axleWheelTypeDescription = list.axleWheelTypeDescription;
        this.brand = list.brand;
        this.completeHierarchicalDescription = list.completeHierarchicalDescription;
        this.licensePlate = list.licensePlate;
        this.nickName = list.nickName;
        this.tag = list.tag;
        this.type = list.type;
        this.vehicleModel = list.vehicleModel;
        this.totalVehicleValue = list.totalVehicleValue;
        this.getFromVehiclesQueryResult = list
      }

      dismissModal() {
        this.modalService.dismissAll();
      }

      onOrderByChange(field: string) {
        if (this.getTransactionsVehiclesQueryArguments.orderBy === field) {
          this.getTransactionsVehiclesQueryArguments.desc = !this.getTransactionsVehiclesQueryArguments.desc;
        } else {
          this.getTransactionsVehiclesQueryArguments.orderBy = field;
          this.getTransactionsVehiclesQueryArguments.desc = true;
        }

        this.getVehicleQuery(this.licensePlate, this.getTransactionsVehiclesQueryArguments);

      }

      bindOrderByClass(field: string) {
        return {
          'order-by-asc': this.getTransactionsVehiclesQueryArguments.orderBy == field && !this.getTransactionsVehiclesQueryArguments.desc,
          'order-by-desc': this.getTransactionsVehiclesQueryArguments.orderBy == field && this.getTransactionsVehiclesQueryArguments.desc
        }
      }

      addClass(position){
        if(position % 2 === 0) {
          return "linetype1"
        }
        else{
          return "linetype2"
        }
     }

     onPaginationChange(page: number) {
      if(this.getTransactionsVehiclesQueryArguments.page !== page){
        this.getTransactionsVehiclesQueryArguments.page = page;
        this.getVehicleQuery(this.licensePlate, this.getTransactionsVehiclesQueryArguments);
      }
    }

}
