import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";


const routes: Routes = [
  // { path: 'backoffice',
  //   redirectTo: 'backoffice/' + RoutesPaths.MAIN_MENU_BACKOFFICE,
  //   data: { roles: "", contractRequired: false },
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShellPagesRoutingModule { }
