import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserConsultantViewComponent } from '../user-consultant-view.component';
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";

const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.CONSULTANT_VIEW,
        component: UserConsultantViewComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Visualizar Consultor'),
          roles: '100993',
          contractRequired: false,
        },
      },
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserConsultantViewRoutingModule { }
