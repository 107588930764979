import { HttpParams } from '@angular/common/http';

export class BillServicesQueryArguments {
  id: number;
  page: number;
  pageSize: number;
  sortField: string;
  transactionId?: number;
  hierarchyId: number;
  desc: boolean;
  search: string;
  BillItemTypeIdArray:number[];

  get httpParams() {
    let httpParams = new HttpParams()
      .set('BillId', this.id?.toString())
      .set('Page', this.page?.toString())
      .set('PageSize', this.pageSize?.toString())
      .set('SortField', this.sortField)
      .append('SortOrder', this.desc ? '1' : '0');

    if (this.hierarchyId)
      httpParams = httpParams.set('hierarchyId', this.hierarchyId.toString());
    if (this.transactionId)
      httpParams = httpParams.set('transactionId', this.transactionId.toString());

    if (this.search) {
      httpParams = httpParams.set('search', this.search);
    }

    this.BillItemTypeIdArray.forEach((element) => httpParams = httpParams.append('BillItemTypeId', element.toString()));

    return httpParams;
  }
}
