import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './../reports.component';
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";


const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.REPORTS,
        component: ReportsComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Estoque Logística'),
          roles: '100991',
          contractRequired: false,
        },
      },
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ReportsRoutingModule {}
