import {
  Component,
  Input,
  ChangeDetectorRef,
  Renderer2,
  inject,
  AfterViewChecked,
  OnInit, AfterViewInit
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PaymentService } from "src/app/core/services/payment.service";
import { PaymentType } from "src/app/core/models/payment.model";
import { StoragedContractInfosService } from "@services/storaged-contract-infos.service";
import { finalize, Subscription} from "rxjs";
import { UpdateCreditCardModalComponent } from "../update-credit-card-modal/update-credit-card-modal.component";
import { RegisterCreditCardModalComponent } from "../register-credit-card-modal/register-credit-card-modal.component";
import { ExcludeCreditCardModalComponent } from "../exclude-credit-card-modal/exclude-credit-card-modal.component";
import { PrepaidConditions } from "@models/prepaid.model";
import {AbstractConfigurationBase} from "../../../abstracts/abstract-configuration-base";

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
})
export class PaymentMethodComponent extends AbstractConfigurationBase implements OnInit, AfterViewInit, AfterViewChecked {
    /**
   * A boolean the render the component with the payment type list reduced ou complete
   * @param {boolean} boolean
   */
  @Input() reducedList: boolean;
  /**
   * A boolean the render the component with or without radio to selection.
   * @param {boolean} boolean
   */
  @Input() selectorMode: boolean;

  paymentMethodform:FormGroup = this._formBuilder.group({
    paymentMethod: ['',[Validators.required]]
  })
  prepaidConditions: PrepaidConditions;
  paymentMethodList: Array<PaymentType> = [];
  isLoading: boolean;
  sub$: Subscription;
  hasCreditCard : boolean;

  constructor(
   private _changeDetectorRef: ChangeDetectorRef,
   private _modalService: NgbModal,
   private _formBuilder : FormBuilder,
   public _service: PaymentService,
   public _storagedContractInfosService: StoragedContractInfosService,
   private renderer: Renderer2)
  {
    super();
     this.updatePaymentMethods();
     this._service.getPrePaidConditions().subscribe(
      (success) => {
        this.prepaidConditions = success;
     })
  }

  updatePaymentMethods(){
    this.sub$ = this._storagedContractInfosService.hasCreditCard$
    .subscribe(() => {
      this.getPaymentMethods();
    });
  }

  ngOnInit(){
    this.getPaymentMethods();
    this._storagedContractInfosService.updateStoragedContractInfos();
    this.hasCreditCard = this._storagedContractInfosService.hasCreditCard$.value;
  }

  ngAfterViewInit() {
    this._changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.disableElementIfBackOfficeUser();
  }

  ngOnDestroy(){
    this.sub$.unsubscribe();
  }

  getPaymentMethods(){
  this.isLoading = true;
  this._service
    .getPaymentTypes()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success: Array<PaymentType>) => {
        if(this.reducedList){
          if(success.filter(data => data.id == 1).length > 0){
            this.paymentMethodList = [{id:1}];
          }
          else{
            this.paymentMethodList = [];
          }
        }
        else{
          this.paymentMethodList = success;
        }

        if(success.filter(data => data.id == 1).length == 0){
          this.paymentMethodList.unshift({id:0})
        }


        if(this.selectorMode){
          this.defaultSelection(this.paymentMethodList[0].id);
        }
      },
      (error) => {
        console.error("error: ", error);
      }
    );
}

  defaultSelection(value){
  this.paymentMethodform.controls.paymentMethod.setValue(value);
  this.onRadioChange(value);
}

  gridRowStyle(){
    return this.paymentMethodList.length == 0 ? `repeat(2, 1fr) 0px` : `repeat(${this.paymentMethodList.length+1}, 1fr) 0px`;
  }

  onRadioChange(paymentMethodTypeId){
   this._service.selectPaymentMethod(paymentMethodTypeId);
  }

  showUpdateCreditCardModal() {
    const modalRef = this._modalService.open(UpdateCreditCardModalComponent);
    modalRef.componentInstance.event.subscribe(() => {
      this.ngOnInit()
    })
  }

  showRegisterCreditCardModal() {
    const modalRef = this._modalService.open(RegisterCreditCardModalComponent);
    modalRef.componentInstance.event.subscribe(() => {
      this.ngOnInit()
    })
  }

  showExcludeCreditCardModal() {
    const modalRef = this._modalService.open(ExcludeCreditCardModalComponent)
    modalRef.componentInstance.prepaidConditions = this.prepaidConditions
    modalRef.componentInstance.event.subscribe(() => {
      this.ngOnInit()
    })
  }

  private disableElementIfBackOfficeUser(): any {
    if (this.userProfileService.isBackOffice() || !this.authService.checkRoles('101175')) {
      const radioInputs = document.querySelectorAll('input[type="radio"][id^="inputPaymentType"]');
      const buttonsModal = document.querySelectorAll('[id^="id-btn-modal"]');
      const checkmarkElement = document.querySelector('.radioContent .container .checkmark');


      radioInputs.forEach((input) => {
        this.renderer.setProperty(input, 'disabled', true);
      });

      buttonsModal.forEach((button) => {
        this.renderer.setStyle(button, 'display', 'none')
      });

      this.renderer.addClass(checkmarkElement, 'changed');

    }
  }
}
