import { Component, Input, OnInit } from '@angular/core';
import { PrePaidConditionsDto } from '@models/prepaid-conditions.dto';

@Component({
    selector: 'app-register-credit-card-confirmation',
    templateUrl: './register-credit-card-confirmation.component.html',
    styleUrls: ['./register-credit-card-confirmation.component.scss']
  })

export class RegisterCreditCardConfirmationComponent implements OnInit {

@Input() prePaidConditions: PrePaidConditionsDto;
@Input() success: any;

   ngOnInit(): void {}

  
}
