import {
  transition,
  animate,
  trigger,
  state,
  style,
} from "@angular/animations";

export const boxPositionAnimation = trigger("boxPosition", [
  state("0", style({ left: "2.8rem" })),
  state("1", style({ left: "21.3rem" })),
  state("2", style({ left: "39.89rem" })),
  transition(":increment", [
    style({ transform: "translateY(0)" }),
    animate(800, style({ transform: "translateY(3rem)", "z-index": 3 })),
    animate(800),
  ]),
  transition(":decrement", [
    style({ transform: "translateY(0)" }),
    animate(800, style({ transform: "translateY(-3rem)", "z-index": 2 })),
    animate(800),
  ]),
]);

export const tutorialBground = trigger("tutorialBground", [
  state(
    "*",
    style({
      "border-radius": "0 0 1rem 1rem",
      background: "#CEE8FF",
      position: "absolute",
      height: "100%",
      width: "100%",
      opacity: ".9",
      content: "",
      "z-index": "-1",
      left: "0",
      top: "0",
    })
  ),
  state(
    "step1",
    style({
      "border-radius": "0 0 1rem 1rem",
      background: " #CEE8FF",
      position: "absolute",
      height: "100%",
      width: "100%",
      opacity: ".6",
      content: "",
      "z-index": "-1",
      left: "0",
      top: "0",
    })
  ),
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [
    style({ opacity: 1 }),
    animate("500ms", style({ opacity: 0 })),
  ]),
  transition("* <=> step1", animate("1s ease-in-out")),
]);
