import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BillVehiclesQueryArguments } from './dto/bill-vehicles-query.arguments';
import { BillVehiclesResultDto } from './dto/bill-vehicles-result.dto';
import { BillDetailVehicles} from './services/bill-detail-vehicles.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Item } from './dto/bill-vehicles-result.dto';


@Component({
  selector: 'app-bill-detail-vehicles',
  templateUrl: './bill-detail-vehicles.component.html',
  styleUrls: ['./bill-detail-vehicles.component.scss']
})
export class BillDetailVehiclesComponent implements OnInit {

  billId:number;
  BillVehiclesQueryArguments: BillVehiclesQueryArguments;
  BillVehiclesResultDto: BillVehiclesResultDto;
  filterDebounce: Subject<BillVehiclesQueryArguments> = new Subject<BillVehiclesQueryArguments>();
  public search
  isLoading: boolean;
  perPages = []
  vehicleOpen: Array<boolean> = []
  Empty: boolean;


  constructor(private billDetail: BillDetailVehicles, private actRoute: ActivatedRoute) {
    this.billId = this.actRoute.snapshot.params.id;
  }


  ngOnInit() {
    this.open = false;
    this.BillVehiclesResultDto = {
      success: true,
      message: '',
      total:0,
      data: []
    };


    this.perPages = [10, 20, 50];
    this.BillVehiclesQueryArguments = new BillVehiclesQueryArguments();
    this.BillVehiclesQueryArguments.id = this.billId;
    this.BillVehiclesQueryArguments.pageVehicle = 1;
    this.BillVehiclesQueryArguments.pageVehicleSize = 7;
    this.BillVehiclesQueryArguments.page = 1;
    this.BillVehiclesQueryArguments.sortField = 'amountTotal';
    //this.BillVehiclesQueryArguments.search = '';
    this.BillVehiclesQueryArguments.pageSize = 2147483647;
    this.BillVehiclesQueryArguments.desc = false;
    this.filterDebounce.pipe(debounceTime(300)).subscribe((filter) => this.getBillDetailVehicles(filter));
    this.getBillDetailVehicles(this.BillVehiclesQueryArguments);


  }

  onPaginationChange(page: number) {
    this.BillVehiclesQueryArguments.pageVehicle = page;
    this.getBillDetailVehicles(this.BillVehiclesQueryArguments)
  }

  getBillDetailVehicles(args: BillVehiclesQueryArguments){
    this.billDetail.getBillVehicles(args)
    .pipe(
      finalize(() => {
      })
    )
    .subscribe(
      (success) => {
        this.VehiclesList(success)
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }


  VehiclesList(list) {
    this.BillVehiclesResultDto = list

    if(list.data.toString() == ''){
     this.Empty = true;
    }
    else{
      this.Empty = false;
    }


    for(var count=0;count<list.data.length;count++){
      this.vehicleOpen[count] = false
    }

  }


open: boolean;
isOpenDropdown: boolean;

toogle() {
this.isOpenDropdown = !this.isOpenDropdown;
this.open =  !this.open;
}

onFullNameKeyup(value: any) {
  this.BillVehiclesQueryArguments.page = 1;
  this.search = value.replace("-","");
  this.BillVehiclesQueryArguments.search = value.replace("-","");
  this.filterDebounce.next(this.BillVehiclesQueryArguments);

  this.getBillDetailVehicles(this.BillVehiclesQueryArguments);

}

onSearchClick(event: any) {
  this.BillVehiclesQueryArguments.page = 1;
  this.getBillDetailVehicles(this.BillVehiclesQueryArguments);
}

SelectVehicle(i){
  for(var count=0;count<this.BillVehiclesResultDto.data.length;count++){
    this.vehicleOpen[count] = false
  }
  this.vehicleOpen[i] = true;
}

ChangeClassSelect(i){
  if(this.vehicleOpen[i] == true){
    return 'selected'
  }
  else{
    return ''
  }
}



}
