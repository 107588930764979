import {Component, Input} from "@angular/core";
import { Tag } from "src/app/core/models/tag.model";

@Component({
  selector: "app-tags-card",
  templateUrl: "./tags-card.component.html",
  styleUrls: ["./tags-card.component.scss"],
})
export class TagsCardComponent {
  @Input() tag: Tag;

  constructor() {}

}
