import { Injectable } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {Filter, IFilterNameId, RequestType} from "@models/filters-backoffice.model";
import {DATE_FORMAT_US, FORMATED_PREVIEWS_THREE_MONTHS, FORMATED_TODAY} from "@variables/dates";

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private _filters = [];
  private _filters$ = new BehaviorSubject<Filter>(
    new Filter({
      startDate: FORMATED_PREVIEWS_THREE_MONTHS,
      endDate: FORMATED_TODAY,
    }),
  );

  setFiltersByRequest(request: RequestType): Observable<Filter> {
    return this._filters$.pipe(
      filter(
        (filter) => filter.request === request || filter.request === undefined,
      ),
    );
  }

  setDateFilter(dates: Date[], request: RequestType): void {
    if (dates.length > 1) {
      const startDate = formatDate(dates[0], DATE_FORMAT_US, 'pt-BR');
      const endDate = formatDate(dates[1], DATE_FORMAT_US, 'pt-BR');

      this._filters$.next(new Filter({ startDate, endDate }, request));
    } else if (dates.length == 1) {
      const startDate = formatDate(dates[0], DATE_FORMAT_US, 'pt-BR');

      this._filters$.next(new Filter({ startDate }, request));
    } else if (dates.length == 0) {
      const startDate = FORMATED_PREVIEWS_THREE_MONTHS;
      const endDate = FORMATED_TODAY;

      this._filters$.next(new Filter({ startDate, endDate }, request));
    }
  }

  activeFilter: number = 0;

  transactionDate$ = new BehaviorSubject<string>('Selecione');
  expectedClearingDate$ = new BehaviorSubject<string>('Selecione');
  creditDate$ = new BehaviorSubject<string>('Selecione');
  expirationDate$ = new BehaviorSubject<string>('Selecione');
  transactionType$ = new BehaviorSubject<number[]>([]);
  statusType$ = new BehaviorSubject<number[]>([]);
  rangeAmount$ = new BehaviorSubject<string>('Selecione');
  periodRadioTransaction$ = new BehaviorSubject<number>(7);
  periodRadioExpected$ = new BehaviorSubject<number>(null);
  radioStep$ = new BehaviorSubject<string>('Selecione');
  refusalReason$ = new BehaviorSubject<number[]>([]);
  customType$ = new BehaviorSubject<number[]>([]);

  constructor(private _dataPipe: DatePipe) {}

  resetFilters(): void {
    this._filters = [];

    this.expectedClearingDate$.next('Selecione');
    this.transactionDate$.next('Selecione');
    this.creditDate$.next('Selecione');
    this.expirationDate$.next('Selecione');
    this.periodRadioTransaction$.next(7);
    this.rangeAmount$.next('Selecione');
    this.transactionType$.next([]);
    this.statusType$.next([]);
    this.periodRadioExpected$.next(null);
    this.radioStep$.next('Selecione');
    this.refusalReason$.next([]);
  }

  formatRangeAmountToText(range: number[]): string {
    const text = range.map((number) => {
      if (!isNaN(number)) {
        return `R$ ${number.toFixed(2)}`;
      }
    });
    return text.join(' - ');
  }

  formatTextToRangeAmount(text: string): number[] {
    const numbers = text
      .split('-')
      .map((part) => parseFloat(part.trim().replace('R$', '')));
    return numbers;
  }

  formatDateToText(date1: Date, date2: Date): string {
    const text = `${this._dataPipe.transform(date1, 'dd/MM/yyyy')}
    - ${this._dataPipe.transform(date2, 'dd/MM/yyyy')}`;
    return text;
  }

  formatTextToDate(dateISOstring: string): Date[] {
    let splitedDates = dateISOstring.split('-');
    let dates = [];
    splitedDates.forEach((dateStringPart) => {
      const [day, month, year] = dateStringPart.split('/');
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      dates.push(date);
    });
    return dates;
  }

  formatIdToName(id: number, items: IFilterNameId[]): string {
    let itemMacth = items.find((item) => item.id == id);
    return itemMacth.name;
  }

  formatNameToId(name: string, items: IFilterNameId[]): number {
    let itemMacth = items.find((item) => item.name == name);
    return itemMacth.id;
  }
}
