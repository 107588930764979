export const COLOR = {
  WHITE: '#FFFFFF',
  BLUE: '#00859C',
  GREEN: '#00A184',
  RED: '#F72717',
  YELLOW: '#FAC83B',
  PURPLE: '#711CFF',

  BLUE_DARK: '#1E293B',
  RED_EDENRED: '#F72717'
}
