import { SearchFormInlineComponent } from '../../../shared/components/search-form-inline/search-form-inline.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportsRoutingModule } from './routes/reports-routing.module';
import { ReportsComponent } from './reports.component';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {SharedModule} from "../../../shared/shared.module";
import {CoreModule} from "../../../core/core.module";
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { OutlinedSelectComponent } from '../../../shared/select/outlined-select/outlined-select.component';

registerLocaleData(ptBr);

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    ReportsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BorderlessButtonComponent,
    OutlinedSelectComponent,
    SearchFormInlineComponent
  ],
  declarations: [ReportsComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    DatePipe,
    provideNgxMask(),
  ],
})
export class ReportsModule {}
