import { HttpParams } from '@angular/common/http';

export class GetVehiclesAvailableArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search:string;
  HierarchyId:number;
  association: number

  
  get httpParans() {
    let httpParams = new HttpParams()
    .append('page', this.page.toString())
    .append('pageSize', this.pageSize.toString())
    .append('sortField', this.orderBy)
    .append('SortOrder', this.desc ? '1' : '0')

    if (this.search) {
      httpParams = httpParams.set('search', this.search.toString());
    }

    if (this.association) {
      httpParams = httpParams.set('Association', this.association.toString());
    }

    if (this.HierarchyId) {
      httpParams = httpParams.set('HierarchyId', this.HierarchyId.toString());
    }
 
 
    return httpParams;

  };
}


