import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FiltersService } from '../../filters.service';
import { FiltersComponent } from '../../filters.component';
import { NotifyService } from '@services/notify.service';
import {EFiltersIds} from "@models/filters-backoffice.model";

@Component({
  selector: 'app-range-amount-filter',
  templateUrl: './range-amount-filter.component.html',
  styleUrls: [
    '../../filters.component.scss',
    './range-amount-filter.component.scss',
  ],
})
export class RangeAmountFilterComponent {
  @Input() labelTitle: string = 'Valor';
  @Input() idFilter: EFiltersIds;
  @Input() textValue: string;
  @Output() getFilter = new EventEmitter<number[]>();
  @ViewChild('MaxValue') Max: ElementRef;
  @ViewChild('MinValue') Min: ElementRef;

  rangeValues: number[] = [];

  constructor(
    private _filtersService: FiltersService,
    public filtersComponent: FiltersComponent,
    private _notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    let text = this._filtersService.rangeAmount$.getValue();

    if (text !== 'Selecione') {
      this.textValue = text;
      let values = this._filtersService.formatTextToRangeAmount(text);
      this.rangeValues[0] = values[0];
      this.rangeValues[1] = values[1];
    }
  }

  showFilterContent() {
    this._filtersService.activeFilter = this.idFilter;
    this.filtersComponent.closeOtherFilters(this.idFilter);
  }

  setValues(type: 'min' | 'max', event: Event) {
    const inputValue = Number((event.target as HTMLInputElement).value);
    let min = parseFloat(this.Min.nativeElement.value);
    let max = parseFloat(this.Max.nativeElement.value);

    if (inputValue < 0) {
      (event.target as HTMLInputElement).value = '0';

      if (type === 'min') {
        min = 0;
        this.persistValues(min, max);
      } else if (type === 'max') {
        max = 0;
        this.persistValues(min, max);
      }

      this._notifyService.showError(
        'Valor Inválido',
        'Valor não deve ser negativo.',
      );
    } else if (min > max && !isNaN(max)) {
      this.Min.nativeElement.value = 0;
      min = 0;

      this.persistValues(min, max);

      this._notifyService.showError(
        'Valor Inválido',
        'O valor Min. deve ser menor que o Máx.',
      );
    } else {
      this.persistValues(min, max);
    }
  }

  persistValues(min: number, max: number) {
    this.rangeValues[0] = min;
    this.rangeValues[1] = max;
    this.textValue = this._filtersService.formatRangeAmountToText([min, max]);
    this.getFilter.emit([min, max]);
  }
}
