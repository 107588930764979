// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --dev-itec` replaces `environment.ts` with `environment.dev-itec.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  showErrors: false,
  // Abaixo sao os endereços das diferentes API que serão usada em requisções.
  // colocar o endereço sem a ultima barra.

  apiTMCustomer: 'https://dev-apigestaodepedagio.edenred.com/tmcustomer-api', // Swagger de TMCustomer
  apiTMVehicle: 'https://dev-apigestaodepedagio.edenred.com/tmvehicle-rejected-api', // Swagger de TMVehicle
  apiTMContract: 'https://dev-apigestaodepedagio.edenred.com/tmcustomer-register-api/api/Contract',
  apiTMUniqueRegister: 'https://dev-apigestaodepedagio.edenred.com/repom-unique-register-customer-api', // Swagger de TMVehicle
  apiTMSystemAccount: 'https://dev-apigestaodepedagio.edenred.com/tmsystemaccount-rejected-api', // Swagger de TMUniqueRegister
  apiTMFinancial: 'https://dev-apigestaodepedagio.edenred.com/tmfinancial-billing-api', // Swagger de TMFinancial
  apiTMTag: 'https://dev-apigestaodepedagio.edenred.com/tmtag-rejected-api', // Swagger de TMTag
  apiPostalCode: 'https://dev-apigestaodepedagio.edenred.com/repom-postalcode-api', // Swagger de PostalCode
  apiTMIdentityServer: "https://dev-apigestaodepedagio.edenred.com/tmidentityserver-rejected-api/api/v1",
  identityProviderUrl: "https://dev-apigestaodepedagio.edenred.com/is", // Base Url RepomIdentityServer
  apiTMTagTransaction: 'https://dev-apigestaodepedagio.edenred.com/tmtag-transaction-rejected-api', // Swagger de TMTag
  apiTMReport: 'https://dev-apigestaodepedagio.edenred.com/tmreport-rejected-api',
  apiTMContractCSV: 'https://dev-apigestaodepedagio.edenred.com/tmreport-rejected-api/Contract/GetClientBlockingSettingsCsv',
  apiTMIntegrationVPR: 'https://dev-apigestaodepedagio.edenred.com/tmintegrationvpr-rejected-api',
  apiTMIntegrationSGA: 'https://dev-apigestaodepedagio.edenred.com/tm-integration-sga-rejected-api',
  filaChat: 'c3f3adfb-eeb4-485e-8f2e-162480e0272d',
  apiVindi: 'https://sandbox-app.vindi.com.br/api',
  apiVindiKey: 'uWmczr9Rj_6-aFfayfjrfiD8njFw8MZIHzlWAkc0PUk',
  cookiesSrc: 'https://cdn.cookielaw.org/consent/bdc63525-7709-42ad-8334-e7d7676e62bf-test/otSDKStub.js',
  cookiesScript: 'bdc63525-7709-42ad-8334-e7d7676e62bf-test',

  // ID TEST Google Tag Manager
  googleTagManagerId: 'GTM-5BTF5PG3',

  //Token da requisicao Get Contract Dual Tag Ativo
  tokenDualTag: '33a2aad3-3e15-497e-9b7b-88487142eac5',

  //Token de staging do userpilot
  userPilotId: 'STG-NX-51f6fa83'
};
