import { Component, OnInit, Input } from '@angular/core';
import { PaymentService } from '@services/payment.service';
import { ContractPixFee } from "@models/contractpixfee.model";
import { finalize } from "rxjs/operators";

@Component({
  selector: 'recharge-fee-table',
  templateUrl: './recharge-fee-table.component.html',
  styleUrls: ['./recharge-fee-table.component.scss']
})
export class RechargeFeeTableComponent implements OnInit {
  @Input() isLoading = false;
  @Input() rechargeFee: number;
  contractPixFee: ContractPixFee[];
  rechargeFeeCard: any;
  constructor(public _paymentService: PaymentService) { }

  ngOnInit(): void {
    this.rechargeFeeCard = this.rechargeFee;
    this.getContractPixFee();
  }

  getContractPixFee() {
    this.isLoading = true;
    this._paymentService
      .getContractPixFee()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: Array<ContractPixFee>) => {
          this.contractPixFee = success;
        },
        (error) => {
          console.error("error: ", error);
        }
      );
  }
}
