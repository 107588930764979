import {inject} from "@angular/core";
import {UserProfileService} from "@services/user-profile.service";
import {GTMMonitorService} from "@services/GTM/g-t-m-monitor.service";

/**
 * Send login event to Google Tag Manager only if the user is a customer or relationship profile
 */
export const selectProfileCanDeactivateGuard = (): void => {
  const userProfileService: UserProfileService = inject(UserProfileService);

  if (userProfileService.isCustomerOrRelationshipProfile()) {
    const gtmService = inject(GTMMonitorService);
    const userId = userProfileService.getUserId();

    gtmService.postLoginGoogleTagManager(String(userId));
  }
};
