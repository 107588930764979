import {inject} from '@angular/core';
import {UserProfileService} from "@services/user-profile.service";
import {AuthService} from "../../core/auth/auth.service";
import {ContractService} from "@services/contract.service";

export abstract class AbstractConfigurationBase {

  protected userProfileService: UserProfileService;
  protected authService: AuthService;
  protected contractService: ContractService

  protected constructor() {
    this.userProfileService = inject(UserProfileService);
    this.authService = inject(AuthService);
    this.contractService = inject(ContractService);
  }

}
