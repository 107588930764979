import { Position } from "@models/types.model";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {ChartOptions} from "@models/charts.model";
import { ChartType } from "chart.js";
import { ChartCustomData } from "@services/report.service";

@Component({
  selector: "charts",
  templateUrl: "./charts.component.html",
  styleUrls: ["./charts.component.scss"],
})
export class ChartsComponent implements OnChanges {
  @Input() type: ChartType = "line";

  @Input() options: ChartOptions = new ChartOptions();
  @Input() data: ChartCustomData;

  @Input() labelPosition: Position = 'bottom';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) this.data = changes['data'].currentValue;
  }
}
