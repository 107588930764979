import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractProcessLauncher } from 'src/app/shared/abstracts/abstract-process-launcher';

@Component({
  selector: 'app-batch-management',
  templateUrl: './batch-management.component.html',
  styleUrl: './batch-management.component.scss'
})
export class BatchManagementComponent extends AbstractProcessLauncher {

  constructor(
    protected router: Router,
  ) {
    super(router);
  }

}
