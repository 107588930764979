import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CreditAlertDto } from "./../dto/credit-alert.dto";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CreditAlertService {
  constructor(private http: HttpClient) {}

  getCreditAlerts() {
    return this.http.get<CreditAlertDto>(
      environment.apiTMCustomer +
        `/Contract/GetContractAccountBalanceAlertsByContractId`
    );
  }

  postDisableCreditAlertTutorial(arg?: boolean) {
    return this.http.post(
      environment.apiTMCustomer +
        `/Contract/DisableContractAccountBalanceAlertTutorial`,
      arg
    );
  }

  putCreditAlerts(alerts: CreditAlertDto) {
    return this.http.put(
      environment.apiTMCustomer +
        `/Contract/UpdateContractAccountBalanceAlerts`,
      alerts
    );
  }

  postAlertTutorialFirstAccess(args?: any) {
    return this.http.post(
      environment.apiTMCustomer +
        `/Contract/ContractAccountBalanceAlertTutorialFirstAccess`,
      args
    );
  }
}
