<div class="lateFeeEditComponent">
  <div class="modal-header">
    <h4 class="modal-title">EDITAR MULTA MORATÓRIA</h4>
    <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
      <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>


  <div class="modal-body">
    <form [formGroup]="lateFeeForm">
      <div class="input-box">
        <label class="input-box__label" for="input-lateFee">Multa Moratória <span>*</span></label>
        <input
          class="input-box__input"
          [class.is-invalid]="
            (lateFeeForm.controls.lateFee.invalid &&
            lateFeeForm.controls.lateFee.touched)"
          id="input-lateFee"
          data-e2e="input-lateFee"
          formControlName="lateFee"
          placeholder="0,00"
          [mask]="'separator.2'"
          decimalMarker=","
          [thousandSeparator]="''"
          type="text"
          required
        />
        <span class="input-box__img-after">% ao mês</span>
        @if(
        lateFeeForm.controls.lateFee.invalid &&
        lateFeeForm.controls.lateFee.touched){
          <app-vmessage
            id="msg-error-input-documentnumber"
            errorMessage="{{ getErrorMessage() }}">
          </app-vmessage>
        }
      </div>

      <div class="input-box">
        <label class="input-box__label" for="input-protocol">Informe o protocolo da solicitação <span>*</span></label>
        <input
          class="input-box__input"
          [class.is-invalid]="
            (lateFeeForm.controls.protocol.invalid &&
            lateFeeForm.controls.protocol.touched)"
          id="input-protocol"
          data-e2e="input-protocol"
          formControlName="protocol"
          placeholder="Digite aqui o protocolo"
          maxlength="10"
          type="text"
          required
        />

        @if(
          lateFeeForm.controls.protocol.invalid &&
          lateFeeForm.controls.protocol.touched){
          <app-vmessage
            id="msg-error-input-documentnumber"
            errorMessage="{{ 'Protocolo é obrigatório' }}">
          </app-vmessage>
          }
      </div>
    </form>

  </div>

  <div class="modal-footer">
    <button
      class="btn btn-tertiary"
      id="modal-btn-cancel"
      data-e2e="modal-btn-cancel"
      (click)="dismissModal()"
    >
      Cancelar
    </button>
    <button
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      [disabled]="lateFeeForm.invalid"
      (click)="onSubmit()"
    >
      Confirmar
    </button>
  </div>
</div>
