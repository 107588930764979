import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ChargebackReportService } from './services/chargeback-report.service';
import { ChargebackReportQueryArguments } from './dto/chargeback-report-query-arguments.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChargebackApproveComponent } from './components/chargeback-approve/chargeback-approve.component';
import { Dto } from '@models/dto.model';
import {ChargebackReportModel} from "@models/backoffice/chargeback-report.model.dto";
import {Filters, IFilterNameId} from "@models/filters-backoffice.model";


class chargebackModelApprove {
  contractId: number;
  chargebackStatus: number;
  reason: string;
  isApproved: boolean;
}

@Component({
  selector: 'app-chargeback-report',
  templateUrl: './chargeback-report.component.html',
  styleUrls: ['./chargeback-report.component.scss'],
})
export class ChargebackReportComponent implements OnInit {
  perPages = [10, 50, 100];

  popoverHeader = [
    'Customer',
    'Contract',
    'Card',
    'Card Holder',
    'Account Balance',
  ];

  tableHeader: [string, string][] = [
    ['', ''],
    ['documentNumber', ''],
    ['[Authorization]', 'Código da autorização da transação'],
    ['transactionTypeDescription', ''],
    ['transactionDate', 'Data e hora da captura da transação'],
    ['chargebackDate', 'Data que ocorreu o chargeback'],
    [
      'validationDeadlineDate',
      'O prazo para solicitação de disputa para chargebacks é de 7 dias após o recebimento da transação',
    ],
    ['totalAmount', 'Valor total da transação (valor líquido + taxa)'],
    ['chargebackStatus', ''],
    ['Action', ''],
  ];

  filterDebounce: Subject<ChargebackReportQueryArguments> =
    new Subject<ChargebackReportQueryArguments>();
  queryArguments: ChargebackReportQueryArguments;
  DtoTransactions: Dto<ChargebackReportModel[]>;
  message: string = 'Loading...';
  isLoading: boolean = false;
  noFiles: boolean;

  transactionTypesFilters: IFilterNameId[] = [
    { name: 'Mensalidade de tag ativa e em estoque', id: 3 }, // 3 | 6 | 7
    { name: 'Pedido de tags', id: 4 },
    { name: 'Recarga automática', id: 1 },
    { name: 'Recarga avulsa', id: 2 },
  ];

  constructor(
    private _chargebackReportService: ChargebackReportService,
    private _localeService: BsLocaleService,
    private modalService: NgbModal,
  ) {
    this.queryArguments = new ChargebackReportQueryArguments();
    this._localeService.use('pt-br');
    this.filterDebounce
      .pipe(debounceTime(300))
      .subscribe((filter) => this.getChargebackReports(filter));
  }

  ngOnInit(): void {
    this.getChargebackReports(this.queryArguments);
  }

  getChargebackReports(args: ChargebackReportQueryArguments) {
    this.isLoading = true;
    this.message = 'Loading...';
    this._chargebackReportService
      .getChargebackReport(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (data: Dto<ChargebackReportModel[]>) => {
          if (data.totalItems == 0) {
            this.noFiles = true;
          } else {
            this.noFiles = false;
          }
          this.DtoTransactions = data;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }

  onPageSizeChange(value: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.queryArguments.page = 1;
    this.queryArguments.pageSize = value;

    this.getChargebackReports(this.queryArguments);
  }

  onOrderByChange(field: string) {
    if (this.queryArguments.orderBy === field) {
      this.queryArguments.desc = !this.queryArguments.desc;
    } else {
      this.queryArguments.orderBy = field;
      this.queryArguments.desc = true;
    }
    this.getChargebackReports(this.queryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.queryArguments.orderBy == field && !this.queryArguments.desc,
      'order-by-desc':
        this.queryArguments.orderBy == field && this.queryArguments.desc,
    };
  }

  onPaginationChange(event: any) {
    this.queryArguments.page = event;
    this.getChargebackReports(this.queryArguments);
  }

  Download() {
    this.isLoading = true;
    let now = new Date();
    let date = moment(now).format('DDMMYYYY');
    this.message = 'Download in progress...';
    this._chargebackReportService
      .getChargebackReportCsv(this.queryArguments)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Chargebacks_' + date + '.csv';
        element.click();
      });
  }

  resetFilters() {
    this.queryArguments = new ChargebackReportQueryArguments();
    this.getChargebackReports(this.queryArguments);
  }

  handleFiltersParams(params: Filters) {
    this.queryArguments.fromDateChargeback = params.transactionDateFilter[0];
    this.queryArguments.toDateChargeback = params.transactionDateFilter[1];

    if (
      params.transactionTypeFilter != null ||
      params.transactionTypeFilter != undefined
    ) {
      //if includes 3, add 6 and 7 (same text meaning)
      if (params.transactionTypeFilter.includes(3)) {
        params.transactionTypeFilter.push(6, 7);
      }
      if (!params.transactionTypeFilter.includes(3)) {
        params.transactionTypeFilter = params.transactionTypeFilter.filter(
          (value) => value !== 6 && value !== 7,
        );
      }
      // remove duplicate values
      params.transactionTypeFilter = params.transactionTypeFilter.reduce(
        (result, value) => {
          if (!result.includes(value)) {
            result.push(value);
          }
          return result;
        },
        [],
      );
      this.queryArguments.transactionTypeDescriptionId =
        params.transactionTypeFilter;
    }

    if (
      params.rangeAmountFilter != null ||
      params.rangeAmountFilter != undefined
    ) {
      this.queryArguments.minimumAmount = params.rangeAmountFilter[0];
      this.queryArguments.maximumAmount = params.rangeAmountFilter[1];
    }

    this.queryArguments.search = '';
    this.getChargebackReports(this.queryArguments);
    this.queryArguments.page = 1;
  }

  // search filter
  onFullNameKeyup($event: any) {
    const maskedValue = $event;
    const numericValue = maskedValue.replace(/\D/g, '');
    if (numericValue !== '') {
      this.queryArguments.page = 1;
      this.queryArguments.search = numericValue;
      this.filterDebounce.next(this.queryArguments);
    } else {
      this.queryArguments.page = 1;
      this.queryArguments.search = '';
      this.filterDebounce.next(this.queryArguments);
    }
  }

  onSearchClick() {
    this.getChargebackReports(this.queryArguments);
  }

  approveChargeback(chargeback: ChargebackReportModel, option: boolean) {
    let modalRef = this.modalService.open(ChargebackApproveComponent);
    modalRef.componentInstance.transaction = chargeback;
    modalRef.componentInstance.option = option;

    modalRef.componentInstance.approved.subscribe((event) => {
      this.getChargebackReports(this.queryArguments);
    });
  }
}
