import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { environment } from "src/environments/environment";
import {
  BillAdvanceDetailsDto,
  BillAdvanceDetailsQueryArguments,
} from "../dto/advance-details-dto";

@Injectable({
  providedIn: "root",
})
export class AdvanceDetailsService {
  constructor(private http: HttpClient) {}

  getAdvanceDetails(args: BillAdvanceDetailsQueryArguments) {
    return this.http.get<BillAdvanceDetailsDto>(
      environment.apiTMReport + `/VprAntecipation/GetVprAntecipation`,
      { params: args.httpParams }
    );
  }

  getCvsAdvanceDetails(args: BillAdvanceDetailsQueryArguments) {
    return this.http
      .get(environment.apiTMReport + `/VprAntecipation/GetVprAntecipationCsv`, {
        params: args.httpParams,
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get("Content-Type"),
            }),
            filename: response.headers.get("filename"),
          };
          return data;
        })
      );
  }
}
