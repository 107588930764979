import { formatCurrency } from '@angular/common';
import { ContractInfosFinancialBlockingDto } from './dtos/contract-infos-financial-blocking.dto';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, finalize } from 'rxjs';

import { FinancialBlockingUnblockingService } from './services/financial-blocking-unblocking.service';
import { ContractBlockLogDto } from './dtos/contract-block-log.dto';
import { ContractBlockLogArguments } from './dtos/contract-block-log.arguments';
import { ContractBillDto } from './dtos/contract-bill.dto';
import { NotifyService } from '@services/notify.service';
import { ConfirmationBlockTypeModalComponent } from './components/confirmation-block-type-modal/confirmation-block-type-modal.component';
import { UpdateBlockTypeDto } from './dtos/update-block-type.dto';
import { ConfirmationBlockUnblockModalComponent } from './components/confirmation-block-unblock-modal/confirmation-block-unblock-modal.component';
import { BlockUnblockContractDto } from './dtos/block-unblock-contract.dto';
import { ContractBillArguments } from './dtos/contract-bill.arguments';
import {RoutesPaths} from "../../../core/utils/routes/routes-paths";
import { LateFeeEditModalComponent } from './components/late-fee-edit-modal/late-fee-edit-modal.component';
import { DefaultInterestEditModalComponent } from './components/default-interest-edit-modal/default-interest-edit-modal.component';
import { ContractFeeType } from '@models/contractFee.model';

@Component({
  selector: 'app-financial-blocking-unblocking',
  templateUrl: './financial-blocking-unblocking.component.html',
  styleUrls: ['./financial-blocking-unblocking.component.scss'],
})
export class FinancialBlockingUnblockingComponent implements OnInit {
  selectProfileFinancialBlockingPosPaidPath =
    'backoffice/' + RoutesPaths.SELECT_PROFILE_FINANCIAL_BLOCKING_POS_PAID;
  isLoading: boolean;

  tableHeadersBlockLog = {
    protocol: 'Protocol',
    registrationUserName: 'RegistrationUserName',
    registrationDate: 'RegistrationDate',
    contractBlockType: 'ContractBlockType',
    contractStatusTypeId: 'ContractStatusTypeId',
    reason: 'Reason',
  };

  tableHeadersOverdueBills = {
    billId: 'Id',
    dueDate: 'DueDate',
    amount: 'Amount',
  };

  contractInfos: ContractInfosFinancialBlockingDto;
  contractBlockLogArgs: ContractBlockLogArguments;
  contractBlockLogDto: ContractBlockLogDto[];
  contractBillArgs: ContractBillArguments;
  contractOverdueBillsDto: ContractBillDto[];
  updateBlockTypeDto: UpdateBlockTypeDto;
  blockUnblockContractDto: BlockUnblockContractDto;

  isBlockAutomatic: boolean;
  isBlock: boolean;
  hasChangedBlockType: boolean = false;

  financialBlockConfigForm = new FormGroup({
    blockType: new FormControl('', [Validators.required]),
    protocol: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  filterDebounce: Subject<ContractBlockLogArguments> =
    new Subject<ContractBlockLogArguments>();

  constructor(
    private _financialBlockingUnblockingService: FinancialBlockingUnblockingService,
    private _notifyService: NotifyService,
    private _modalService: NgbModal,

  ) {
    this.contractInfos = new ContractInfosFinancialBlockingDto();
    this.contractBlockLogArgs = new ContractBlockLogArguments();
    this.contractBlockLogDto = [];
    this.contractBillArgs = new ContractBillArguments();
    this.contractOverdueBillsDto = [];
    this.updateBlockTypeDto = new UpdateBlockTypeDto();
    this.blockUnblockContractDto = new BlockUnblockContractDto();

    this.isLoading = true;

    this.filterDebounce
      .pipe(debounceTime(300))
      .subscribe(() => this.getContractInfos());
  }

  ngOnInit(): void {
    this.getContractInfos();
  }

  getContractInfos() {
    this.isLoading = true;

    this._financialBlockingUnblockingService
      .getContractsById()
      .pipe(
        finalize(() => {
          this.getBlockInfos();
          this.getContractTags();
          this.getPostPaidContractBalance();
          this.getContractBlockLogs();
          this.getListOverdueBills();

          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (success: any) => {
          this.contractInfos.companyName =
            success.contract.customer.person.companyName;
          this.contractInfos.id = success.contract.id;
          this.contractInfos.billingCode = success.contract.billingCode;
          this.contractInfos.creditLimit = formatCurrency(
            success.contract.creditLimit,
            'pt-BR',
            'R$ ',
          );
          this.contractInfos.daysToBlock = success.contract.daysToBlock;
          this.contractInfos.contractStatusTypeId =
            success.contract.contractStatusTypeId;
          this.contractInfos.contractBlockTypeId =
            success.contract.contractBlockTypeId;
          this.contractInfos.contractFee = success.contract.contractFee;
        },
        error: (error) => {
          console.error(error);
          return;
        },
      });
  }

  getContractTags() {
    this.isLoading = true;

    this._financialBlockingUnblockingService
      .getTagsDashboard()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (success) => {
          this.contractInfos.tagsActiveQuantity = success.activeQuantity;
          this.contractInfos.tagsBlockedQuantity = success.blockedQuantity;
        },
        error: (error) => {
          console.error(error);
          return;
        },
      });
  }

  getPostPaidContractBalance() {
    this.isLoading = true;

    this._financialBlockingUnblockingService
      .getPostPaidContractBalance()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (success) => {
          this.contractInfos.accountBalance = formatCurrency(
            success.accountBalance,
            'pt-BR',
            'R$ ',
          );
        },
        error: (error) => {
          console.error(error);
          return;
        },
      });
  }

  getContractBlockLogs() {
    this.contractBlockLogArgs.contractId = this.contractInfos.id;
    this.isLoading = true;

    this._financialBlockingUnblockingService
      .getContractBlockLog(this.contractBlockLogArgs)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (success) => {
          this.contractBlockLogDto = success;
        },
        error: (error) => {
          console.error(error);
          return;
        },
      });
  }

  getListOverdueBills() {
    this.contractBillArgs.contractId = this.contractInfos.id;
    this.isLoading = true;

    this._financialBlockingUnblockingService
      .getListOverdueBillsByContractId(this.contractBillArgs)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (success) => {
          this.contractOverdueBillsDto = success;
        },
        error: (error) => {
          console.error(error);
          return;
        },
      });
  }

  getBlockInfos() {
    this.financialBlockConfigForm.controls.blockType.setValue(
      this.contractInfos.contractBlockTypeId.toString(),
    );

    this.isBlockAutomatic =
      this.contractInfos.contractBlockTypeId == 1 ? true : false; // 1 = Automatic Block

    if (this.contractInfos.contractStatusTypeId === 3) {
      // 3 = Financial Block
      this.isBlock = true;
    }
  }

  onOrderByChangeBlockLog(field: string) {
    if (this.contractBlockLogArgs.orderBy == field) {
      this.contractBlockLogArgs.desc = !this.contractBlockLogArgs.desc;
    } else {
      this.contractBlockLogArgs.orderBy = field;
      this.contractBlockLogArgs.desc = true;
    }
    this.getContractBlockLogs();
  }

  bindOrderByClassBlockLog(field: string) {
    return {
      'order-by-asc':
        this.contractBlockLogArgs.orderBy == field &&
        !this.contractBlockLogArgs.desc,
      'order-by-desc':
        this.contractBlockLogArgs.orderBy == field &&
        this.contractBlockLogArgs.desc,
    };
  }

  onOrderByChangeOverdueBills(field: string) {
    if (this.contractBillArgs.orderBy == field) {
      this.contractBillArgs.desc = !this.contractBillArgs.desc;
    } else {
      this.contractBillArgs.orderBy = field;
      this.contractBillArgs.desc = true;
    }
    this.getListOverdueBills();
  }

  bindOrderByClassOverdueBills(field: string) {
    return {
      'order-by-asc':
        this.contractBillArgs.orderBy == field && !this.contractBillArgs.desc,
      'order-by-desc':
        this.contractBillArgs.orderBy == field && this.contractBillArgs.desc,
    };
  }

  onSelectionRadio(radio: number) {
    if (
      radio.toString() !=
        this.financialBlockConfigForm.controls.blockType.value &&
      radio != this.contractInfos.contractBlockTypeId
    ) {
      this.hasChangedBlockType = true;
      this.financialBlockConfigForm.controls.blockType.setValue(
        radio.toString(),
      );
      this.financialBlockConfigForm.controls.protocol.enable();
    } else {
      this.financialBlockConfigForm.controls.blockType.setValue(
        radio.toString(),
      );
      this.resetFinancialBlockConfigForm();
    }
  }

  getInputMask(): string {
    return '9'.repeat(50);
  }

  formatSliceString(str: string): string {
    return (
      str.slice(0, 18) + '<br>' + str.slice(18, 36) + '<br>' + str.slice(36)
    );
  }

  confirmBlockTypeChange() {
    if (
      this.financialBlockConfigForm.invalid ||
      this.financialBlockConfigForm.controls.protocol.value == '0'
    ) {
      this._notifyService.showError(
        'Atenção!',
        'Informe um número de protocolo válido!',
      );
    } else {
      this.updateBlockTypeDto.contractId = this.contractInfos.id;
      this.updateBlockTypeDto.contractBlockTypeId = Number(
        this.financialBlockConfigForm.controls.blockType.value,
      );
      this.updateBlockTypeDto.protocol =
        this.financialBlockConfigForm.controls.protocol.value;

      const modal = this._modalService.open(
        ConfirmationBlockTypeModalComponent,
      );
      modal.componentInstance.updateBlockTypeDto = this.updateBlockTypeDto;

      if (this.financialBlockConfigForm.controls.blockType.value === '1') {
        // '1' Automatic Block
        modal.componentInstance.isChangingToBlockManual = false;
        modal.componentInstance.daysToBlock = this.contractInfos.daysToBlock;
      } else {
        modal.componentInstance.isChangingToBlockManual = true;
      }
      modal.componentInstance.eventReloadPage.subscribe((event) => {
        this.filterDebounce.pipe(debounceTime(300));
        this.getContractInfos();
        this.resetFinancialBlockConfigForm();
      });
    }
  }

  confirmBlockUnblockContract() {
    this.blockUnblockContractDto.contractId = this.contractInfos.id;

    if (this.isBlock) {
      this.blockUnblockContractDto.contractStatusTypeId = 1; // 1 = Active
    } else {
      this.blockUnblockContractDto.contractStatusTypeId = 3; // 3 = Financial Block
    }

    const modal = this._modalService.open(
      ConfirmationBlockUnblockModalComponent,
    );
    // if the contract is block (true), so it can be unblocking (true)
    modal.componentInstance.isUnblockingContract = this.isBlock;
    modal.componentInstance.blockUnblockContractDto =
      this.blockUnblockContractDto;

    modal.componentInstance.eventReloadPage.subscribe((event) => {
      this.filterDebounce.pipe(debounceTime(300));
      this.getContractInfos();
      this.isBlock = !this.isBlock;
      this.resetFinancialBlockConfigForm();
    });
  }

  resetFinancialBlockConfigForm() {
    this.hasChangedBlockType = false;
    this.financialBlockConfigForm.controls.protocol.disable();
    this.financialBlockConfigForm.controls.protocol.reset();
  }

  openLateFeeEditModal(lateFee: number) {
    const modal = this._modalService.open(LateFeeEditModalComponent);
    modal.componentInstance.contractId = this.contractInfos.id;
    modal.componentInstance.lateFee = lateFee;

    modal.componentInstance.eventSubmitForm.subscribe((formData) => {
      this.updateFeeByType(formData, ContractFeeType.LateFee);
    });
  }

  openDefaultInterestEditModal(defaultInterest: number) {

    const modal = this._modalService.open(DefaultInterestEditModalComponent);
    modal.componentInstance.contractId = this.contractInfos.id;
    modal.componentInstance.defaultInterest = defaultInterest;

    modal.componentInstance.eventSubmitForm.subscribe((formData) => {
      this.updateFeeByType(formData, ContractFeeType.DefaultInterest);
    });
  }


  updateFeeByType(formData: any, FeeType: ContractFeeType) {

    const newValue = FeeType == ContractFeeType.LateFee ? formData.lateFee : formData.defaultInterest;

    this._financialBlockingUnblockingService.updateContractFeeByType(this.contractInfos.id, FeeType, Number(newValue), formData.protocol)
      .subscribe({
        next: (success) => {

          FeeType == ContractFeeType.LateFee ? this._notifyService.showSuccess('Sucesso!', 'Percentual de multa alterado com sucesso!')
          : this._notifyService.showSuccess('Sucesso!', 'Percentual de juros alterado com sucesso!')

          this.getContractInfos();
          this._modalService.dismissAll();
        },
        error: (error) => {
          console.error(error);
        }
    });
  }
}
