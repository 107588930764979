export class Vehicle {
  id: number;
  nickname: string;
  tag?: string;
  type: string;
  brand: string;
  axle: string;
  contractId: number;
  licensePlate: string;
  vehicleType: VehicleType;
  contractVehicleId: number;
  registrationDate: Date;
  nickName: string;
  vehicleBrandTypeId: number;
  model: string;
  vehicleCategoryId: number;
  aggregateDriver: boolean;
  documentNumber: string;
  rntrc: string;
  hierarchyId: number;
  userId: number;
  customerId: number;
  startDate: Date;
  endDate: Date;
  tagId: number;
  hasTag: boolean;
  descriptionAxlePt: string;
  hierarchyName: string;
  contractVehicleTagStatusTypeId: number;
  contractVehicleTagServiceTypeId: number;
  tagActivationDate: Date;
  descriptioncontractVehicleTagStatusTypeIdPt: string;
  descriptionVehicleBrandTypePt: string;
  descriptionVehicleTypePT: string;
  serialTag: string;
  accountBalance: number;
  serial: any;
  vehicleId: number;

  constructor() {
    this.aggregateDriver = false
  }
}

export enum VehicleType {
  PASSENGER = 1,
  VAN = 2,
  TRUCK = 3,
  BUS = 4
}

export enum VehicleStatus {
  REGISTERED = 1,
  NO_REGISTERED_TAG = 2,
  ACTIVATED_TAG = 3,
  WITH_BLOCKED_TAG = 4,
  DELETED_VEHICLE = 5
}

export class VehicleStock {
  total: number;
  totalActives: number;
  totalNotActive: number;
}
