import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {
  AbstractProcessLauncher
} from "../../../shared/abstracts/abstract-process-launcher";

@Component({
  selector: "app-fleet-dashboard",
  templateUrl: "./fleet-dashboard.component.html",
  styleUrls: ["./fleet-dashboard.component.scss"],
})
export class FleetDashboardComponent extends AbstractProcessLauncher {

  constructor(
    protected router: Router
  ) {
    super(router);
  }
}
