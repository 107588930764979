<div class="InsertWarningModalComponent">
  <div class="modal-header">

      <h4 class="modal-title" id="modal-title">INCLUIR FERIADO</h4>
      <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
        <img src="assets/svg/close.svg" alt="Ícone de X"/>
      </a>

  </div>
  <div class="modal-body">
    <img src="assets/svg/warning-blue.svg" alt="warning">
    <span class="main-title">Feriado duplicado!</span>

    <p>Você está tentando cadastrar mais de um feriado na mesma data. Por favor, revise a data que você está cadastrando e faça as alterações necessárias.</p>


    <div class="bg-gray">
      <ul>
      @for(errors of holidayErrors ; track $index){

          <li>
              <div><span>● Feriado:</span> {{ errors.name }}</div>
              <div><span>Data:</span> {{errors.holidayDate | date: 'dd/MM/YYYY' }}</div>
          </li>

      }
      @for(errors of errorsAtForm ; track $index){

        <li>
            <div><span>● Feriado:</span> {{ errors.name }}</div>
            <div><span>Data:</span> {{errors.date | date: 'dd/MM/YYYY' }}</div>
        </li>

    }
    </ul>
    </div>


  </div>
  <div class="modal-footer">
    <app-borderless-button
      [description]="'Voltar'"
      [id-html]="'modal-btn-cancel'"
      [data-e2e]="'modal-btn-cancel'"
      [buttonColor]="'secondary'"
      (emitterEvtClick)="dismissModal()">
    </app-borderless-button>
  </div>
</div>
