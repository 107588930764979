import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";
import {AuthService} from "./auth.service";
import {UserProfileService} from "@services/user-profile.service";

const LOGIN_URL: string = '/login';
const NAO_AUTORIZADO: string = '/nao-autorizado';

function navigateWithRedirect(router: Router, url: string, state: RouterStateSnapshot): boolean {
  router.navigate([url], {
    queryParams: {redirect: state.url},
    replaceUrl: true
  }).catch(error => {
    console.error("Erro ao navegar:", error);
  });
  return false;
}

export const authBackOfficeGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  const roles: string = route.data.roles;
  const router: Router = inject(Router);
  const oauthService: OAuthService = inject(OAuthService);
  const authService: AuthService = inject(AuthService);
  const userProfile: UserProfileService = inject(UserProfileService);

  /** Not authenticated, redirecting and adding redirect url */
  if (!oauthService.hasValidAccessToken()) {
    return navigateWithRedirect(router, LOGIN_URL, state);
  }

  /** Profile not backoffice, redirecting and adding redirect url */
  if (!userProfile.isBackOffice()) {
    return navigateWithRedirect(router, NAO_AUTORIZADO, state);
  }

  /** `User does not have the roles ${expectedRole} */
  if (!authService.checkRoles(roles)) {
    return navigateWithRedirect(router, NAO_AUTORIZADO, state);
  }

  return true;
}
