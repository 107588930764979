 <div class="EditContractNameModalComponent">
    <div class="modal-content">
      <div class="modal-header">
        <div class="box-title">
          <span>Editar nome do contrato</span>
        </div>
        <div class="blueContainer" *ngIf="step==1"></div>
        <div class="line" *ngIf="step==1"></div>
        <div class="contractInfo" *ngIf="step==1">
          <p class="name">{{contract.description}}</p>
          <p class="contract">
            <span class="document">{{contract?.documentNumberCompanyContract | mask: '00.000.000/0000-00'}}</span>
            | Contrato: <span class="id">{{ contract?.id }}</span>
          </p>
        </div>
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body modal-body1 show" *ngIf="step==1">
        <form class="form" [formGroup]="editContractNameForm">
          <div class="formfield col">
               <label for="contractName">Informe o nome do contrato</label>
               <div class="formInput">
                  <input id="contractName" [(ngModel)]="contractName" formControlName="nameContract"/>
               </div>
            </div>
        </form>
      </div>
      <div class="modal-body modal-body2 show row" *ngIf="step==2">
        <div class="col-12 container-confirmation">
          <div class="col-4 image-content">
              <img src="/assets/img/bg-place.svg" />
          </div>
          <div class="col-8 confirmation-text">
              <h3>Nome de contrato alterado</h3>
              <div class="row">
                  <span class="col-4 previousNameLabel">
                     Nome anterior: 
                  </span>
                  <span class=" col previousName">
                    {{ contract?.nameContract }}
                  </span> 
              </div>
              <div class="row">
                <span class="col-4 actualNameLabel">
                   Nome Atual: 
                </span>
                <span class="col 
                actualName">
                {{ editContractNameForm.value.nameContract }}
                </span> 
            </div>      
          </div>
      </div>
      <div class="col-12 author mt-4">
        <p>
          <span id="dateRegister">{{
            dateRegister | date: 'dd/MM/yyyy HH:mm'
          }}</span>
          por <span id="nameUser">{{ nameUser }}</span>
        </p>
    </div>
      </div>
      <div class="modal-footer modal-footer-first-step modal-footer-gray"  *ngIf="step==1">
        <button type="button" class="btn btn-tertiary mr-4" (click)="close()">Cancelar</button>
        <button type="button" class="btn btn-secondary position-relative" (click)="submitContractName(editContractNameForm.value)"
         [disabled]="onSaving || contractName == contract.nameContract" ><span>Salvar edição</span>
          <img *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
        </button>
      </div>
      <div class="modal-footer modal-footer-last-step modal-footer-gray"  *ngIf="step==2">
        <button type="button" class="btn btn-secondary" (click)="close()">Fechar</button>
    </div>
    </div>
 </div>
