import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { ContractFeeType } from '@models/contractFee.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-default-interest-edit-modal',
  templateUrl: './default-interest-edit-modal.component.html',
  styleUrl: './default-interest-edit-modal.component.scss'
})
export class DefaultInterestEditModalComponent implements OnInit {

  @Input() defaultInterest: number;
  @Output() eventSubmitForm = new EventEmitter<any>();
  contractFeeType = ContractFeeType.DefaultInterest;
  defaultInterestForm: FormGroup;

  constructor(
    private _modalService: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.defaultInterestForm = this.fb.group({
      defaultInterest: [
        this.formatInitialValue(this.defaultInterest),
        [
          Validators.required,
          this.validateInterestRange
        ]
      ],
      protocol: ['', Validators.required]
    });
  }

  formatInitialValue(value: number): number {
    return Number(value != null ? value.toFixed(2) : '');
  }

  validateInterestRange(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    const numericValue = parseFloat(value);
    if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
      return { 'rangeError': true };
    }
    return null;
  }

  dismissModal() {
    this._modalService.dismissAll();
  }

  getErrorMessage(): string {
    const control = this.defaultInterestForm.controls.defaultInterest;
    if (control.hasError('required')) {
      return 'Por favor, preencha os juros';
    } else if (control.hasError('rangeError')) {
      return 'O valor deve estar entre 0,00 e 100,00';
    }
    return '';
  }

  onSubmit() {
    if (this.defaultInterestForm.valid) {
      this.eventSubmitForm.emit(this.defaultInterestForm.value);
    }
  }

}
