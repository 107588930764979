<header>
  <nav class="navbar navbar-expand-lg navbar-light p-0 float-left">
    <button
      class="navbar-toggler Header_BT_OpenMenu"
      type="button"
      data-toggle="collapse"
      data-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <img src="../../../../../../assets/svg/icon_menu.svg" class="Responsive_iconMenu" />
    </button>
    <div
      class="collapse navbar-collapse HeaderMenu_navbarCollapse"
      id="navbarText"
    >
      <!-- ------ Menu | Start ------ -->
      <ul class="navbar-nav mr-auto navbarResponsiveMenu">
        <li class="nav-item" routerLinkActive="is-active">
          <a
            id="redirectToDashboard"
            class="nav-link Header_BTN"
            routerLink="/pagina-inicial"
          >
            <img src="../../../../../../assets/img/ico-nav-1.svg" class="Header_BTN_Icon" />
            <img
              src="../../../../../../assets/img/ico-nav-1-active.svg"
              class="Header_BTN_Icon nav-active"
            />
            <p>Página Inicial</p>
          </a>
        </li>

        <li class="nav-item dropdown" routerLinkActive="is-active">
          <a
            id="redirectFleet"
            class="nav-link dropdown-toggle Header_BTN"
            data-toggle="dropdown"
            routerLink="/meus-veiculos"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="../../../../../../assets/img/ico-nav-2.svg" class="Header_BTN_Icon" />
            <img
              src="../../../../../../assets/img/ico-nav-2-active.svg"
              class="Header_BTN_Icon nav-active"
            />
            <p>Frota</p>
          </a>
          <div class="dropdown-menu dropdownMenu_Viagens">
            <a
              id="redirectToMyVehicles"
              class="dropdown-item"
              routerLink="/meus-veiculos"
              routerLinkActive="is-active"
              *accessControl="101143"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                </svg>
              </div>
              Meus veículos
            </a>
            <a
              id="redirectToBathManagement"
              class="dropdown-item"
              routerLink="/gestao-lote"
              routerLinkActive="is-active"
              *accessControl="103003"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                </svg>
              </div>
              Gestão em lote
            </a>
            <a
              id="redirectToActiveTags"
              class="dropdown-item"
              routerLink="/ativar-tags"
              routerLinkActive="is-active"
              *accessControl="101157"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                </svg>
              </div>
              Ativar tags
            </a>

            <a
              class="dropdown-item"
              id="redirectToHierarchy"
              routerLink="/hierarquia"
              routerLinkActive="is-active"
              *accessControl="101162"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                </svg>
              </div>
              Hierarquia
            </a>
            <a
              id="redirectToMyOrder"
              class="dropdown-item"
              routerLink="/meus-pedidos"
              routerLinkActive="is-active"
              *accessControl="101171"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                </svg>
              </div>
              Pedidos
            </a>
          </div>
        </li>

        <li class="nav-item dropdown" routerLinkActive="is-active">
          <a
            id="redirectFinancial"
            class="nav-link dropdown-toggle Header_BTN"
            data-toggle="dropdown"
            routerLink="/passagens"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="../../../../../../assets/img/ico-nav-3.svg" class="Header_BTN_Icon" />
            <img
              src="../../../../../../assets/img/ico-nav-3-active.svg"
              class="Header_BTN_Icon nav-active"
            />
            <p>Financeiro</p>
          </a>
          <div class="dropdown-menu dropdownMenu_Relatorios">
            <div *accessControl="101174">
              <a
              *modalityType="CLIENT_TYPE.PRE"
              id="redirectToRecharge"
              class="dropdown-item"
              routerLink="/recargas"
              routerLinkActive="is-active"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                </svg>
              </div>
              Recargas
            </a>
            </div>
            <a
              id="redirectToExtract"
              class="dropdown-item"
              routerLink="/extrato"
              routerLinkActive="is-active"
              *accessControl="101180"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                </svg>
              </div>
              Extrato
            </a>
            <a
              id="redirectToTickets"
              class="dropdown-item"
              routerLink="/passagens"
              routerLinkActive="is-active"
              *accessControl="101187"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >

                </svg>
              </div>
              Passagens
            </a>
            <a
              id="redirectToBills"
              class="dropdown-item"
              routerLink="/faturas"
              routerLinkActive="is-active"
              *accessControl="101193"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >

                </svg>
              </div>
              Faturas
            </a>
          </div>
        </li>

        <li class="nav-item dropdown" routerLinkActive="is-active">
          <a
            id="redirectReports"
            class="nav-link dropdown-toggle Header_BTN"
            data-toggle="dropdown"
            routerLink="/indicadores-frota"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="../../../../../../assets/img/ico-nav-4.svg" class="Header_BTN_Icon" />
            <img
              src="../../../../../../assets/img/ico-nav-4-active.svg"
              class="Header_BTN_Icon nav-active"
            />
            <p>Painel de Gestão</p>
          </a>
        </li>

        <li class="nav-item dropdown" routerLinkActive="is-active">
          <a
            id="redirectVPR"
            class="nav-link dropdown-toggle Header_BTN"
            data-toggle="dropdown"
            routerLink="/saldovpr-veiculos"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="../../../../../../assets/img/ico-nav-5.svg" class="Header_BTN_Icon" />
            <img
              src="../../../../../../assets/img/ico-nav-5-active.svg"
              class="Header_BTN_Icon nav-active"
            />
            <p>Vale Pedágio</p>
          </a>
          <div class="dropdown-menu dropdownMenu_Roteiros">
            <a
              id="redirectToVPRbalance"
              class="dropdown-item"
              [routerLink]="['/saldovpr-veiculos']"
              routerLinkActive="is-active"
              *accessControl="101212"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >

                </svg>
              </div>
              Saldo dos veículos
            </a>
            <a
              id="redirectToReceivedCredits"
              class="dropdown-item"
              [routerLink]="['/creditos-recebidos']"
              routerLinkActive="is-active"
              *accessControl="101214"
            >
              <div class="cta">
                <svg
                  width="66px"
                  height="43px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >

                </svg>
              </div>
              Créditos recebidos
            </a>
          </div>
        </li>
      </ul>
      <!-- ------ Menu | End ------ -->
    </div>
  </nav>

  <div class="tollBalanceComponent">
    <app-toll-balance (emitPostPaidContractBalance)="reciverPaidContractBalance($event)"
                      [companyName]="companyName"></app-toll-balance>
  </div>

  <!-- ------ User | Start ------ -->
  <div class="Header_UserMenu">
    <div class="dropdownElements row">
      <div class="notifications col-sm-auto" *ngIf="sizeScreen >= 1025">
        <div *controlAccessCustomer>
          <app-notification></app-notification>
        </div>
      </div>
      <div class="user dropdown col-sm-auto">
        <button
          (mouseover)="openDropdown('button')"
          (mouseout)="closeDropdown('button')"
          class="btn btn-user dropdown-toggle Header_UserMenu_BTN"
          type="button"
          id="dropdownMenuUser"
        >
        </button>
        <div
          (mouseover)="openDropdown('dropdownItem')"
          (mouseout)="closeDropdown('dropdownItem')"
          class="dropdown-menu Header_UserMenu_Dropdown"
          [ngbCollapse]="!isOpenAccount"
        >
          <div class="Header_UserMenu_Company">
            <div
              class="before"
            ></div>
            <p class="Header_UserMenu_Company_Name" id="HeaderCompanyName">
              {{ companyName.name }}
            </p>
            <p class="Header_UserMenu_Document_Number">
              {{ companyName.documentNumber | mask: "00.000.000/0000-00" }}
            </p>
            <div
              class="after"
            ></div>
            <div class="contractInfos">
              <p>Contrato:</p>
              <p>
                <span id="contractId">
                  {{ companyName.contractId }}
                </span>
                <span
                  id="contractName"
                  *ngIf="
                    companyName.contractName &&
                    companyName.contractName.length > 0
                  "
                >
                  - {{ companyName.contractName }}
                </span>
              </p>
            </div>
          </div>

          <div class="btn-group Header_UserMenu_BoxBTNs"
               [ngClass]="isUserProfileBackOffice ? 'Header_UserMenu_BoxBTNs_BackOffice' : ''">
            <div *controlAccessCustomer>
              <a
                *accessControl="101134"
                routerLink="/minha-conta"
                type="button"
                class="btn btn-secondary Header_UserMenu_BT_MinhaConta"
                [ngClass]="handleClassCustomized() ? 'Header_UserMenu_BT_MinhaConta_Only' : ''"
                >
                  Conta
                </a>
                </div>
            <div>
              <a
                *accessControl="101136"
                routerLink="/contrato"
                type="button"
                class="btn btn-secondary Header_UserMenu_BT_Usuarios"
                [ngClass]="isUserProfileBackOffice ? 'Header_UserMenu_BT_Usuarios_BackOffice' : ''"
              >
                <i class="ico-contract"></i>
                <span>Contrato</span>
            </a>
            </div>
          </div>
          @if (getHaveManyContracts() || isUserProfileBackOffice) {
            <div>
              <a
                type="button"
                class="btn btn-secondary btn-header-3"
                (click)="handleRouteByProfile()"
              >
                <i class="ico ico-employe"></i>
                <span class="employe-description">Trocar Contrato</span>
              </a>
          </div>
          }

          <a *ngIf="this.settingBtnMultipleProfile.hasMultipleProfile"
            type="button"
            class="btn btn-secondary btn-header-3 btn-header-4"
            (click)="updateProfile(this.settingBtnMultipleProfile.url)"
          >
            <i class="ico ico-client"></i>
            <span>{{this.settingBtnMultipleProfile.description}}</span>
          </a>

          <a
            (click)="logout()"
            class="btn btn-secondary no-shadow Header_UserMenu_Logout underline"
          >
            <span>Sair</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- ------ User | End ------ -->

  <!--  Header Responsive Start -->
  <div class="container header-responsive">
    <div class="row">
      <div class="col-6">
        <div class="contractInfos">
          <p>Contrato</p>
          <p>
            <span id="contractIdResponsive">
              {{ companyName.contractId }}
            </span>
            <span id="contractNameResponsive" >
                  {{ this.handleContractNameSize(companyName) }}
            </span>
          </p>
        </div>
        <div class="header-account-balance">
          <p *modalityType="CLIENT_TYPE.PRE">Saldo Disponível</p>
          <p *modalityType="CLIENT_TYPE.POS">Limite Restante</p>
          <p *modalityType="CLIENT_TYPE.PRE">
            <span *ngIf="postPaidContractBalance?.accountBalance; else zeroValue" id="headerAccountBalanceResponsive" [ngClass]="postPaidContractBalance?.accountBalance >= 0 ? 'numberPositive' : 'numberNegative'">
              R$ {{postPaidContractBalance?.accountBalance | number: ".2":"pt-br"}}
            </span>
            <ng-template #zeroValue><span class="numberPositive">R$ 0,00</span></ng-template>
          </p>
          <p *modalityType="CLIENT_TYPE.POS">
            <span *ngIf="postPaidContractBalance?.remainingLimit; else zeroValue" id="headerRemainingLimitResponsive" [ngClass]="postPaidContractBalance?.remainingLimit >= 0 ? 'numberPositive' : 'numberNegative'">
              R$ {{postPaidContractBalance?.remainingLimit | number: ".2":"pt-br"}}
            </span>
            <ng-template #zeroValue><span class="numberPositive">R$ 0,00</span></ng-template>
          </p>
        </div>
      </div>
      <div class="col notifications-resp" *ngIf="sizeScreen <= 1024">
        <app-notification></app-notification>
      </div>
      <div class="col-2 header-menu-resp">
        <button cdk-overlay-origin #trigger="cdkOverlayOrigin" (click)="isMenuOpen = !isMenuOpen">
          <img src="../../../../../../assets/svg/menu.svg" alt="" />
        </button>
      </div>
    </div>
  </div>

  <ng-template
    cdk-connected-overlay
    [cdkConnectedOverlayOrigin]="trigger"
    hasBackdrop
    [cdkConnectedOverlayOpen]="isMenuOpen"
    (backdropClick)="isMenuOpen=false"
  >
    <app-menu-responsive (dismiss)="isMenuOpen = !isMenuOpen"
                         [companyName]="companyName"
                         [haveManyContracts]="haveManyContracts"></app-menu-responsive>
  </ng-template>

  <!--  Header Responsive End -->
</header>
