<div class="finallimitComponent" >
  <div class="row b1">
    <div class="col-lg-1 t0"></div>
    <div data-e2e="final-limit-date-data" class="col-lg-3 t1 finalLimitdate" >{{ itemByDate.date | date:'dd/MM/yyyy' }}</div>
    <div
      data-e2e="balance-after-data"
      class="col-lg-4 t2 finalLimitbalanceAfterTransaction">
      {{ itemByDate.balanceAfterTransaction | currency:'BRL' : 'symbol':'0.2-2' : 'pt-BR' }}
    </div>
    <div data-e2e="day-final-balance-title" class="col-lg-4 t3">saldo final do dia</div>
</div>
<div class="row b2">
  <div class="col-lg-12">- - - -</div>
</div>
<div class="row b3">
  <div class="circ"></div>
  <div class="line" [style.height.px]="getHeight()"></div>
</div>
</div>


