import { Component, OnInit, ElementRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { Tag } from "src/app/core/models/tag.model";
import { VehicleStock } from "@models/vehicle.model";
import { Subscription } from "rxjs";
import { StoragedContractInfosService } from "@services/storaged-contract-infos.service";
import { PersonDetailsNpsDto } from "@models/person-details-nps.model";
import { Financial } from "@models/old/dashboard/financial.model";
import { DashboardService } from "./services/dashboard.service";
import { FinancialBlockModalComponent } from "../select-contracts/components/financial-block-modal/financial-block-modal.component";
import { MyContractService } from "@services/mycontract.service";
import { Router } from "@angular/router";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent extends AbstractProcessLauncher implements OnInit {
  tag: Tag;
  vehicle: VehicleStock;
  financial: Financial;
  prePaidConditions: any;
  received: boolean;
  contractBlock: number;
  contract: any;
  sub$: Subscription;

  private haveBillRole: boolean;
  private isCustomerProfile: boolean = false;

  constructor(
    protected _dashboardService: DashboardService,
    private _modal: NgbModal,
    private _elementRef: ElementRef,
    private _myContractService: MyContractService,
    private _storagedContractInfosService: StoragedContractInfosService,
    protected router: Router
  ) {
    super(router);
    this._getContractBlockLevelVariable();
  }

  _getContractBlockLevelVariable(){
    if (this.userProfileService.isCustomerOrRelationshipProfile()) {
      this.sub$ = this._storagedContractInfosService.contractBlockLevel$
        .subscribe((data) => {
          this.contractBlock = data;
        });
    } else {
      this.contractBlock = this.contractService.getContractBlockLevel();
    }
  }

  ngOnInit(): void {
    this.isCustomerProfile = this.userProfileService.isCustomer();
    this.haveBillRole = this.userProfileService.haveRole("101125");
    this.contract = [];
    this.getCompanyInfos();
    this.getTagDashboard();
    this.getDashboardVehicle();
    this.buildScriptElement();
    this.getPersonInfosForNPS();
  }

  //medallia - adicionando os scripts
  getPersonInfosForNPS() {
    //verifica se possui permissão ao medalia
    if (this.userProfileService.haveRole("101224")) {
      this._dashboardService.getPersonDetailsForNPS()
        .subscribe(
          (success: any) => {
            const personDetailsNpsDto: PersonDetailsNpsDto = success;
            var s1 = document.createElement("script");
            s1.type = "text/javascript";
            s1.src = "https://nebula-cdn.kampyle.com/eu/we/738718/onsite/embed.js";
            s1.async = true;
            this._elementRef.nativeElement.appendChild(s1);
            var s2 = document.createElement("script");
            s2.text = `
                  var medallia_username = "`+ personDetailsNpsDto.username + `";
                  var medallia_email = "`+ personDetailsNpsDto.email + `";
                  var medallia_full_name = "`+ personDetailsNpsDto.fullname + `";
                  var medallia_phone = "`+ personDetailsNpsDto.phone + `";
                  var medallia_company_code = "`+ personDetailsNpsDto.billingCode + `";
                  var medallia_user_profile = "admin";
                  var medallia_registered_number = "`+ personDetailsNpsDto.documentNumber + `";
                  var medallia_company_name = "`+ personDetailsNpsDto.companyName + `";
                  var medallia_customer_legal_nature = "PJ";
                  var medallia_contract_id = "`+ personDetailsNpsDto.contractId + `";
          `;
            this._elementRef.nativeElement.appendChild(s2);
          },
          (error) => {
            console.error(error);
            return;
          }
        )
    }
  }

  buildScriptElement(): void {
    const s1 = document.createElement("script");
    s1.setAttribute("data-domain-script", environment.cookiesScript);
    s1.src = environment.cookiesSrc;
    s1.type = "text/javascript";
    s1.charset = "UTF-8";

    const s2 = document.createElement("script");

    s2.type = "text/javascript";
    s2.text = "function OptanonWrapper() {}";

    this._elementRef.nativeElement.appendChild(s1);
    this._elementRef.nativeElement.appendChild(s2);
  }

  getCompanyInfos(): void {
    this._myContractService.getName().subscribe(
      (success: any) => {
        this.contract.documentNumberCompanyContract = success.documentNumber;
        this.contract.description = success.name;
        this.contract.id = success.contractId;
      },
      (error) => {
        console.error("getFromServerError", error);
        return;
      }
    );
  }

  showFinancialBlockModal(): void {
    const modalRef = this._modal.open(FinancialBlockModalComponent);
    modalRef.componentInstance.contract = this.contract;
  }

  getTagDashboard(): void {
    this._dashboardService.getDashboardTag().subscribe(
      (result) => {
        this.tag = result;
      },
      (errors) => {
        console.log("Dashboard Tag Erro: " + JSON.stringify(errors));
      }
    );
  }

  getDashboardVehicle(): void {
    this._dashboardService.getDashboardVehicle().subscribe(
      (result) => {
        this.vehicle = result;
      },
      (errors) => {
        console.log("Dashboard Vehicle Erro: " + JSON.stringify(errors));
      }
    );
  }

  isVisibleAppInvoices(): boolean {
    return !this.isCustomerProfile || (this.isCustomerProfile && this.haveBillRole);
  }
}
