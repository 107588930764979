<div
  class="dragdrop-file"
  id="dragdrop-file"
  data-e2e="dragdrop-file"
>
  @if (!uploaded) {
    <section
      class="dragdrop-file__interaction-area"
      id="dragdrop-file-interaction-area"
      data-e2e="dragdrop-file-interaction-area"
      appDnd
      (fileDropped)="onFileDropped($event)"
    >
      <input
        class="dragdrop-file__input"
        id="dragdrop-file-input"
        data-e2e="dragdrop-file-input"
        type="file"
        (change)="fileBrowseHandler($event.target.files)"
      />
      <label
        class="dragdrop-file__label"
        id="dragdrop-file-label"
        data-e2e="dragdrop-file-label"
        for="dragdrop-file-input"
      >
        <img class="dragdrop-file__icon" alt="Ícone adicionar documento">
        <p class="dragdrop-file__title">
          Arraste e solte ou clique aqui para escolher o arquivo
        </p>
        <p class="dragdrop-file__subtitle" id="dragdrop-file-subtitle">
          {{ inTextInfoAllowedExtensions }}
        </p>
      </label>
    </section>
  }
  @else {
    <section class="dragdrop-file__uploaded">
      <div class="dragdrop-file__uploaded-info">
        <img
          [ngClass]="getUploadedFileIconClass()"
          class="dragdrop-file__uploaded-icon"
          id="dragdrop-file-uploaded-icon"
          alt="Ícone documento xls"
        >
        <p class="dragdrop-file__uploaded-name" id="dragdrop-file-uploaded-name">
          {{files[0].name}}
          <br>
          <span class="dragdrop-file__uploaded-size" id="dragdrop-file-uploaded-size">
            {{ formatBytes(files[0].size) }}
          </span>
        </p>
      </div>

      <button
        id="dragdrop-file-uploaded-remove"
        data-e2e="dragdrop-file-uploaded-remove"
        class="btn-icon-not-bg"
        type="button"
        (click)="removeFile(0)"
      >
        <img class="btn-icon-not-bg__icon icon-trash-can" alt="Ícone de lixeira">
      </button>
    </section>
  }

  @if (messageError) {
    <div class="dragdrop-file__uploaded-error" id="dragdrop-file-uploaded-error">
      Formato do arquivo não permitido.
    </div>
  }
</div>


