<!-- <div class="container-loading" *ngIf="isLoading" >
  <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
</div>  -->
<div class="modal-content">
  <div class="modal-header">
    <div class="box-title"><span>DETALHES DE TRANSAÇÕES</span></div>
    <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body show modal-edit-vehicle">
    <div class="header1">
      <div class="row">
        <div class="col-md-auto headercolumn1">
          <a class="img-hierarchy"><img src="/assets/img/bill-detail-hierarchy@2x.png" /></a>
          <div class="d-flex flex-column mr-5">
            <!-- <span class="fs-12">Frota 2</span>
              <span class="gray-hierarchy">Sudeste / São Paulo / Sede 3 / Tags ativas</span> -->
            <span class="fs-12" id="hierarchyDescription">{{hierarchyDescription}}</span>
            <span class="gray-hierarchy" id="completeHierarchicalDescription">{{completeHierarchicalDescription}}</span>
          </div>
        </div>
        <div class="col-5 headercolumn2">
          <div class="d-flex flex-column">
            <span class="fs-12">Gastos até o momento:</span>
            <span>
              <div class="money">R$</div>
              <div class="blue-hierarchy" id="totalVehicleValue">{{totalVehicleValue}}</div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="header2">
      <table class="table">
        <thead class="thead">
          <tr class="head1">
            <td>
              <div class="row">
                <div class="col-sm-auto">PLACA:</div>
                <div class="col" id="licensePlate">{{licensePlate}}</div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-sm-auto">TIPO:</div>
                <div class="col" id="type">{{type}}</div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-sm-auto">Nº DE EIXOS:</div>
                <div class="col" id="axleWheelTypeDescription">{{axleWheelTypeDescription}}</div>
              </div>
            </td>
          </tr>
          <tr class="head2">
            <td>
              <div class="row">
                <div class="col-sm-auto">APELIDO:</div>
                <div class="col" id="nickName">{{nickName}}</div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-sm-auto">MARCA:</div>
                <div class="col" id="brand">{{brand}}</div>
              </div>
            </td>
            <td></td>
          </tr>
          <tr class="head3">
            <td>
              <div class="row">
                <div class="col-sm-auto">TAG:</div>
                <div class="col" id="tag">{{tag}}</div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-sm-auto">MODELO:</div>
                <div class="col" id="vehicleModel">{{vehicleModel}}</div>
              </div>
            </td>
            <td></td>
          </tr>
        </thead>
      </table>
    </div>


    <div class="desc-vehicles-col">
      <table class="table table-title">
        <thead class="thead">
          <tr class="head1">
            <td colspan="4">
              <div class="desc-vehicles">DETALHES</div>
            </td>
            <td colspan="1">
              <div class="values-vehicles">VALORES (R$)</div>
            </td>
          </tr>
          <tr class="head2">
            <th (click)="onOrderByChange('passengerDate')" id="transactionDate"
              [ngClass]="bindOrderByClass('passengerDate')">
              <button class="btn-order-table" translate>Data da Transação</button>
              <div class="round-order">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>
            <th (click)="onOrderByChange('processingDate')" [ngClass]="bindOrderByClass('processingDate')"
              id="transactionProcessDate">
              <button class="btn-order-table" translate>Data do Processamento</button>
              <div class="round-order">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('transactionType')" (click)="onOrderByChange('transactionType')"
              id="transactionType">
              <button class="btn-order-table" translate>Tipo de Transação</button>
              <div class="round-order">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>
            <th (click)="onOrderByChange('merchantName')" [ngClass]="bindOrderByClass('merchantName')"
              id="merchantName">
              <button class="btn-order-table" translate>Descrição</button>
              <div class="round-order">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>
            <th (click)="onOrderByChange('amount')" [ngClass]="bindOrderByClass('amount')" id="amount">
              <button class="btn-order-table" translate>Total</button>
              <div class="round-order">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>
          </tr>
        </thead>
        <div class="container-loading" *ngIf="isLoading">
          <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
        </div>
        <tbody *ngIf="!isLoading">
          <tr *ngFor="let vehicle of getFromVehiclesQueryResult?.transactions.transactionItems; let i = index"
            [ngClass]="addClass(i)">
            <td *ngIf="vehicle.transactionTypeId !== 3 && vehicle.transactionTypeId !== 4" id="transactionDateDesc">
              {{vehicle.transactionDate |
              date:'dd/MM/yyyy HH:mm:ss'}}</td>
            <td *ngIf="vehicle.transactionTypeId == 3 || vehicle.transactionTypeId == 4"></td>
            <td id="transactionProcessDateDesc">{{vehicle.transactionProcessDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
            <td id="transactionTypeDesc">{{vehicle.transactionType}}</td>
            <td id="merchantNameDesc">{{vehicle.merchantName}}</td>
            <td id="amountDesc">{{vehicle.amount | currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}</td>
          </tr>
        </tbody>
        <div class="tickets-vehicles-modal pagination-custom"
          *ngIf="getFromVehiclesQueryResult?.transactions.total > 10 && !isLoading">
          <app-pagination [totalItems]="getFromVehiclesQueryResult?.transactions.total"
            [itemsPerPage]="getTransactionsVehiclesQueryArguments.pageSize" [maxPages]="5"
            [currentPage]="getTransactionsVehiclesQueryArguments.page"
            (pageChangeEvent)="onPaginationChange($event)"></app-pagination>
        </div>
        <tfoot>
          <button type="button" class="btn btn-default" (click)="dismissModal()" id="btn-close">FECHAR</button>
        </tfoot>
      </table>

    </div>

  </div>
</div>