import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Vehicle } from '@models/vehicle.model';
import { MyvehiclesService } from '../../../myvehicles/services/myvehicles.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-delete-vehicle-modal',
  templateUrl: './delete-vehicle-modal.component.html',
  styleUrls: ['./delete-vehicle-modal.component.scss']
})
export class DeleteVehicleModalComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Output() event: EventEmitter<any> = new EventEmitter();
  formVehicle: FormGroup;
  onSaving: boolean;


  constructor(
      private modalService: NgbModal,
      protected myVehiclesService: MyvehiclesService,
      protected notifyService: NotifyService,
      private frmBuilder: FormBuilder
      ) { }

  ngOnInit(): void {
    this.createEditVehicleForm()
  }

  dismissModal() {
    this.modalService.dismissAll();
  }


  createEditVehicleForm() {
    this.formVehicle = this.frmBuilder.group({
      id: [this.vehicle?.id],
      licensePlate: [this.vehicle?.licensePlate],
      vehicleType: [this.vehicle?.vehicleType],
      descriptionVehicleTypePT: [this.vehicle?.descriptionVehicleTypePT],
      nickName: [this.vehicle?.nickName],
      vehicleBrandTypeId: [this.vehicle?.vehicleBrandTypeId],
      descriptionVehicleBrandTypePt: [this.vehicle?.descriptionVehicleBrandTypePt],
      model: [this.vehicle?.model],
      vehicleCategoryId: [this.vehicle?.vehicleCategoryId],
      descriptionAxlePt: [this.vehicle?.descriptionAxlePt],
      hierarchyId: [this.vehicle?.hierarchyId],
      rntrc: [this.vehicle?.rntrc],
      documentNumber: [this.vehicle?.documentNumber],
      aggregateDriver: [this.vehicle?.aggregateDriver]
    });
  }

  onDeleteVehicle() {
    this.onSaving = true;
    this.myVehiclesService
      .deleteVehicleId(this.vehicle?.id)
      .pipe(
        finalize(() => { this.onSaving = false; }))
        .subscribe(
        (success) => {
          this.notifyService.showSuccess('Atenção!', 'Veículo deletado com sucesso!');
          this.event.emit(this.vehicle);
          this.dismissModal();
        },
        (e) => {
          console.error(e)
        }
      );
  }




}
