<div class="RegisterCreditCardModalComponent">
    <div class="modal-content">
        <div class="modal-header">
          <div class="box-title" *ngIf="step == 1"><span>Solicitação de cadastro de cartão</span></div>
          <div class="box-title" *ngIf="step == 2"><span>Confirmação de cadastro de cartão</span></div>
          <button class="close" type="button" (click)="dismissModal()"><span aria-hidden="true">×</span></button>
        </div>
          <div class="container-loading" *ngIf="isLoading">
            <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
          </div>
          <div class="modal-body show modal-update-credit-card" *ngIf="!isLoading && step == 1" >
              <div class="title">Insira os dados do seu cartão de crédito para recargas</div>
              <div class="CreditCardform">
                <div class="safeEnvironmentTag"><img src="assets/img/lock_black.svg" /><span>Ambiente seguro</span></div>
                <form class="form row" [formGroup]="CreditCardForm">       
                    <div class="formfield col-7"> 
                      <label for="cardNumber">Número do cartão</label> 
                      <div class="formInput">
                           <input id="creditCardNumber" [mask]="'0000 0000 0000 0000'" type="text" formControlName="card_number"> 
                           <div class="alertErrorValidator" 
                           *ngIf="(CreditCardForm.controls['card_number']?.invalid && CreditCardForm.controls['card_number']?.touched) 
                           || (submitted && CreditCardForm.controls['card_number']?.invalid)">
                           {{ generateMessage(CreditCardForm.controls['card_number'].errors)}}
                          </div>
                       </div>
                    </div>
                    <div class="formfield col-5 form-group"> 
                        <label for="paymentCompanyCode">Bandeira</label> 
                        <div class="formInput">
                          <select class="form-control form-select" id="creditCardFlag" formControlName="payment_company_code">
                            <option *ngFor="let creditCardflag of creditCardFlags" value="{{creditCardflag.value}}">{{creditCardflag.name}}</option>        
                          </select>
                             <div class="alertErrorValidator" 
                             *ngIf="(CreditCardForm.controls['payment_company_code']?.invalid && CreditCardForm.controls['payment_company_code']?.touched) 
                             || (submitted && CreditCardForm.controls['payment_company_code']?.invalid)">
                             {{ generateMessage(CreditCardForm.controls['payment_company_code'].errors)}}
                            </div>
                         </div>
                      </div>
                      <div class="formfield col-12"> 
                        <label for="holderName">Nome impresso do cartão</label> 
                        <div class="formInput">
                             <input id="creditCardName" type="text" formControlName="holder_name"  maxlength="50"> 
                             <div class="alertErrorValidator" 
                             *ngIf="(CreditCardForm.controls['holder_name']?.invalid && CreditCardForm.controls['holder_name']?.touched) 
                             || (submitted && CreditCardForm.controls['holder_name']?.invalid)">
                             {{ generateMessage(CreditCardForm.controls['holder_name'].errors)}}
                            </div>
                         </div>
                      </div>
                      <div class="formfield col-7"> 
                        <label for="cardExpiration">Validade</label> 
                        <div class="formInput">
                             <input id="creditCardValidity" [mask]="'00/0000'" type="text" formControlName="card_expiration"> 
                             <div class="alertErrorValidator" 
                             *ngIf="(CreditCardForm.controls['card_expiration']?.invalid && CreditCardForm.controls['card_expiration']?.touched) 
                             || (submitted && CreditCardForm.controls['card_expiration']?.invalid)">
                             {{ generateMessage(CreditCardForm.controls['card_expiration'].errors)}}
                            </div>
                         </div>
                      </div>
                      <div class="formfield col-5"> 
                          <label for="cardCvv">CVV</label> 
                          <div class="formInput">
                               <input id="creditCardCvv" type="text" [mask]="'000'" formControlName="card_cvv"> 
                               <div class="alertErrorValidator" 
                               *ngIf="(CreditCardForm.controls['card_cvv']?.invalid && CreditCardForm.controls['card_cvv']?.touched) 
                               || (submitted && CreditCardForm.controls['card_cvv']?.invalid)">
                               {{ generateMessage(CreditCardForm.controls['card_cvv'].errors)}}
                              </div>
                           </div>
                        </div>
                 </form>
              </div>
              <div class="row infosRecharge">
                <div class="col-sm-auto">
                  <div class="col-12 textTitle">Valor da recarga automática:</div>
                  <div class="col-12 text">{{ prePaidConditions?.automaticRechargeValue | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</div>
                  <div class="col-12 textTitle">Taxa de recarga <span *ngIf="prePaidConditions?.rechargeFee !==0">({{ prePaidConditions?.rechargeFee }}%)</span></div>
                  <div class="col-12 text" *ngIf="prePaidConditions?.rechargeFee !== 0">{{ prePaidConditions?.rechargeFee / 100 * prePaidConditions?.automaticRechargeValue | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</div>
                  <div class="col-12 text" *ngIf="prePaidConditions?.rechargeFee == 0">Isento</div>                   
                  <div class="col-12 textTitle">Total debitado do cartão de crédito</div>
                  <div class="col-12 text">{{prePaidConditions?.automaticRechargeValue + (prePaidConditions?.rechargeFee / 100 * prePaidConditions?.automaticRechargeValue) | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</div>
              </div>
                <div class="col">
                  <div class="title">Ao cadastrar seu cartão de crédito você autoriza a cobrança da sua primeira recarga.</div>
                  <div class="text">
                    Toda vez que o saldo de sua conta atingir <b class="red minimumPercentageForAutomaticRecharge">{{ prePaidConditions?.minimumPercentageForAutomaticRecharge }}%</b> do valor da Recarga
                    Automática, no caso <b class="black minimumBalance">{{ prePaidConditions?.automaticRechargeValue * (prePaidConditions?.minimumPercentageForAutomaticRecharge/100) | currency: 'R$':'symbol':'0.2-2':'pt-BR' }} (saldo mínimo),</b> será débitado de seu cartão de
                    crédito uma nova recarga automática no valor escolhido de <b class="color-primary automaticRechargeValue">R$
                      {{ prePaidConditions?.automaticRechargeValue | currency: ' ':'symbol':'0.2-2':'pt-BR'}}.</b>
                  </div>
                </div>
              </div>

          </div>
          <div class="modal-body show modal-update-credit-card" *ngIf="!isLoading && step == 2" >
             <app-register-credit-card-confirmation [success]="success" [prePaidConditions]="prePaidConditions" ></app-register-credit-card-confirmation>
          </div>
          <div class="modal-footer modal-footer-gray justify-content-start">
            <div class="col-12 justify-content-center d-flex">
              <button *ngIf="step == 1" [disabled]="isLoading" type="button" class="btn btn-tertiary mr-4" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">Cancelar</button>
              <button *ngIf="step == 1" [disabled]="CreditCardForm.invalid || isLoading" (click)="createPaymentProfile()" class="btn btn-secondary position-relative" type="button">Confirmar</button>
              <button *ngIf="step == 2" [disabled]="isLoading" (click)="dismissModal()"class="btn btn-secondary position-relative" type="button">Fechar</button>
            </div>
          </div>
   </div>
</div>