import { ChartData } from "@models/charts.model";
import { Colors } from "../variables/colors";

export default function filterAndRemoveTypeFromChart(data: ChartData) {
  const FILTERED_DATA = data.datasets.filter(
    (item) =>
      item.type === "currency" && item.label != "Gasto médio por veículo"
  );

  let newChart = new ChartData(data.labels, FILTERED_DATA, data.hasData);
  let newDataset = [];

  newChart.datasets.forEach((item, index) => {
    const { type, ...newItem } = item;
    newItem.lineTension = 0;

    newDataset.push({
      ...newItem,
      borderColor: Colors[index],
      backgroundColors: Colors[index],
    });
  });

  newChart.datasets = newDataset;

  return newChart;
}
