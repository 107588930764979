<div class="feedbackFinancialBlockModalComponent">
  <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <a class="btn-close" (click)="dismissModal()">
      <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>

  <div class="yellow row-box-yellow" *ngIf="textBoxYellow">
    <span data-e2e="warning-box" class="box-yellow">
      <b>{{ getFirstChar(textBoxYellow) }}</b
      >{{ getRestOfText(textBoxYellow) }}
    </span>
  </div>

  <div class="modal-body">
    <img src="{{ imgPath }}" alt="Feedback image" />

    <section class="modal-text-container">
      <h5 class="modal-text-subtitle">{{ subtitle }}</h5>
      <p class="modal-text--bold">{{ textBold }}</p>
      <p *ngIf="text">{{ text }}</p>
      <p class="modal-text--gray">{{ textGray }}</p>
    </section>
  </div>

  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="dismissModal()">
      Fechar
    </button>
  </div>
</div>
