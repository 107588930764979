import { Validators, AbstractControl } from '@angular/forms';

export class ZipCodeValidator {
    constructor() {}
    
    static isValidZipCode() {
      return (control: AbstractControl): Validators => {

        if(control.value && control.value.length !== 8){
            return { zipcodeInvalid: true };
        } 

    return null;
   };
 }


}