<card-model>
  <ng-container *ngIf="stats$ | async as stats">
    <header class="card-model-header">
      <h1>Minha Frota</h1>
      <p>Acompanhe e controle os status de seus veículos.</p>
    </header>

    <section class="card-model-container">
      <div>
        <app-borderless-button [description]="'Download CSV'"
                               [classIconRight]="'ico-download'"
                               [id-html]="'id-download-csv'"
                               [data-e2e]="'id-download-csv'"
                               [service]="'myFleet'"
                               [filters]="filters"
                               [buttonColor]="'tertiary'"
                               [isBtnWithBorder]="true">
        </app-borderless-button>
      </div>

      <div class="stats-cards-container">
        <card-model *ngFor="let item of stats">
          <div class="stats-card" [class]="item.color">
            <span>{{ item.stats }}</span>
            <p>{{ item.title }}</p>
          </div>
        </card-model>
      </div>

      <div class="footer-action-container">
        <app-borderless-button [description]="'Ativar Tag'"
                               [classIconRight]="'ico-double-arrow'"
                               [routerLink]="'/ativar-tags'"
                               [id-html]="'id-activate-tag-link'"
                               [data-e2e]="'activate-tag-link'"
                               [buttonColor]="'secundary'">
        </app-borderless-button>

      </div>
    </section>
  </ng-container>
</card-model>
