<div class="page-wrap d-flex flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div data-e2e="wait-msg" *ngIf="!isLoading && !error" class="col-md-12 text-center">
        <span class="display-4 d-block">Aguarde...</span>
        <div id="message"class="mb-4 lead">{{message}}</div>
      </div>
      <div *ngIf="error" class="col-md-12 text-center">
        <span data-e2e="error-440" class="display-1 d-block">440</span>
        <div id="error" class="mb-4 lead">{{error}}</div>
        <a data-e2e="retry-btn" (click)="tryAgain($event)" class="btn btn-link">Tentar novamente</a>
      </div>
    </div>
  </div>
</div>
