<card-model>
  <ng-container *ngIf="ranking$ | async as ranking">
    <header class="card-model-header">
      <h1>Ranking de Gastos</h1>
      <p>Veja os veículos que mais consumiram em sua frota.</p>
    </header>

    <section class="card-model-container">
      <div class="action-center">
        <app-outlined-select [title]="'Período'" [values]="[30]"
                             [beforeTextValue]="'Últimos '" [afterTextValue]="' dias'" [customWidthSize]="'250px'"
                             [disableRipple]="true">
        </app-outlined-select>
      </div>

      <ng-container *ngIf="ranking?.length > 1; else empty">
        <table class="ranking-table">
          <thead>
            <tr>
              <th *ngFor="let header of tableHeader; let i = index">
                {{ header }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of ranking; let i = index" class="ranking-row">
              <td>
                <app-vehicle-details-popover
                  [vehicle]="item"
                  [hasActions]="false"
                  [attr.data-e2e]="'popover-btn-' + i"
                ></app-vehicle-details-popover>
              </td>

              <td class="ranking-data">
                <p class="ranking-first-line">{{ item.licensePlate }}</p>
                <p class="ranking-second-line">
                  {{
                    item.amount
                      | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                  }}
                </p>
              </td>

              <td class="ranking-data">
                <p class="ranking-first-line">
                  {{ item.nickname ? item.nickname : "-" }}
                </p>
                <p class="ranking-second-line">
                  {{ item.hierarchyName ? item.hierarchyName : "-" }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>

      <ng-template #empty>
        <p class="empty-msg">Nenhum veículo encontrado na data selecionada.</p>
      </ng-template>
    </section>
  </ng-container>
</card-model>
