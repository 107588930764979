import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { Order } from "src/app/core/models/order.model";

@Injectable({
  providedIn: "root",
})
export class OrderTimelineService {
  private _lastOrderUrl: string = `${environment.apiTMTag}/Tag/GetLastOrderDetails`;
  refreshOrder$ = new BehaviorSubject<boolean>(undefined);

  constructor(private readonly _http: HttpClient) {}

  lastOrder$ = this.refreshOrder$.pipe(switchMap(() => this.getOrder()));

  getOrder() {
    return this._http
      .get<Order>(this._lastOrderUrl, { observe: "response" })
      .pipe(
        switchMap((res) => {
          if (res.status == 200) {
            let newRes;
            const steps = res.body.getTagRequestTrackingQueryResults;
            const activeStep = steps.find((item) => item.isActive == true);
            let index: number = steps.indexOf(activeStep);
            let newArray = [];

            newRes = {
              ...res.body,
              activeStep: index,
            };

            steps.forEach((step, i) => {
              if (step.isActive) {
                newArray.push({ ...step, stepStatus: "active" });
              } else if (
                !step.isActive &&
                i < steps.length - 1 &&
                steps[i + 1].isActive
              ) {
                newArray.push({ ...step, stepStatus: "passed" });
              } else {
                newArray.push({ ...step, stepStatus: "inactive" });
              }
            });

            return of({
              ...newRes,
              getTagRequestTrackingQueryResults: newArray,
            });
          } else {
            return of([]);
          }
        })
      );
  }
}
