export class PersonAddress {
    id: number;
    personId?: number;
    zipCode: string;
    streetAddress: string;
    number: string;
    streetAddressLine2: string;
    landmark: string;
    district: string;
    city: string;
    state?: string;
    mailbox: string;
    federatedUnit: string;
    country: string;
    personAddressTypeId: number;
    addressTypeId: number;
    principalFlg: boolean;
    registrationUserId?: number;
    constructor() {}
}
