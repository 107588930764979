import { Component, Input, OnInit,ChangeDetectorRef,ChangeDetectionStrategy} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { HierarchyService } from '../../../hierarchy/services/hierarchy.service';
import { NotifyService } from '@services/notify.service';



@Component({
  selector: 'app-remove-hierarchy-modal',
  templateUrl: './remove-hierarchy-modal.component.html',
  styleUrls: ['./remove-hierarchy-modal.component.css']
})


export class RemoveHierarchyModalComponent implements OnInit {

    constructor(private bsModalRef: BsModalRef, private hierarchyService: HierarchyService, private notifyService: NotifyService){

    }

    @Input() hierarchyLevel;
    @Input() openHierarchy;
    public Step1Active:boolean;
    public Step2Active:boolean;
    public Step3Active:boolean;
    public step:number;
    array: any[];
    nodeItems: any[];
    Children : any[];
    ChildrenDisassociate: any[];
    public totalVehicles:number;
    public availableChildrens: any[];
    public ChangeHierarchy
    public breadCrumb;
    public TransferGroupHierarchy
    public withoutVehicle;
    public FirstLevel;
    public successDeleted;
    public id;
    isLoading: boolean;
    public FirstNode;
    public margin

    ngOnInit() {
      this.successDeleted = true;
      this.TransferGroupHierarchy = []
      this.ChangeHierarchy = []
      this.availableChildrens = []
      this.totalVehicles = 0
      this.array = []
      this.Children = []
      this.ChildrenDisassociate = []
      this.getHierarchyTree(this.hierarchyLevel);
      this.Step1Active = true;
      this.Step2Active = false;
      this.Step3Active = false;
      this.step = 1;
    }

    close() {
      this.bsModalRef.hide();
    }

    getDescClass(desc,vehicleCount){
      if(desc == this.FirstNode && vehicleCount == 0){
          return 'first-node'
      }
      else if(desc !== this.FirstNode && vehicleCount == 0){
          return 'second-node'
      }
       else if(desc !== this.FirstNode && vehicleCount !== 0){
        return 'second-node-without-vehicle'
      }
      else{
        return 'first-node-without-vehicle'
      }
    }


    nextStep(){

        this.step++;

      if(this.step == 2){
         this.Step1Active = false;
         this.Step2Active = true;
         this.Step3Active = false;
       }
      else{
         this.Step1Active = false;
         this.Step2Active = false;
         this.Step3Active = true;
       }

    }

    getHierarchyTree(hierarchySelect: number = null) {
      this.isLoading = true;
      this.hierarchyService
        .getGroupHierarchy(hierarchySelect)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (success: any) => {
            this.generateTree(success.data.tree);

          },
          (error) => {
            // TODO: Depois que for implementado alertService descomentar a linha de baixo para tratar os retornos das apis
            //this.alertService.danger(error.message);
            console.error("getFromServerError", error);
            return;
          }
        );
    }



    GetHierarchyList(description_PT){
      this.isLoading = true;
      this.hierarchyService
      .GetHierarchyBreadCrumbList()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: any) => {
          this.getChildren(success.data,description_PT);

        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }


  getChildren(list,description_PT){
   this.FirstNode = description_PT
   let breadCrumbs = list.map(item => item.breadCrumb)

   let belongsHierarchy
   let lengthFirstNode = this.FirstNode.length

   //gera o array de hierarquias filhas para desassociar
   for(var count:number = 0;count<list.length;count++){
     if(breadCrumbs[count].slice(-lengthFirstNode).toString() == this.FirstNode){
      belongsHierarchy = this.FirstNode
     }
     else{
      belongsHierarchy = this.FirstNode+' ';
     }

    if(breadCrumbs[count].indexOf(belongsHierarchy) != -1){
      if(list[count].vehicleCount !== 0){
        this.Children.push(list[count])
        this.ChildrenDisassociate.push(list[count])
      }
      else{
        this.Children.push(list[count])
      }
    }
   }

      //exbição do step 1 caso não há veículos para associar
      if(this.ChildrenDisassociate.length == 0){
        this.withoutVehicle = true
      }
      else{
        this.withoutVehicle = false
      }

      //exbição do step 1 caso seja o primeiro nivel de hierarquia
      if(this.hierarchyLevel == 1 || this.hierarchyLevel == null ){
        this.FirstLevel = true
      }
      else{
        this.FirstLevel = false
      }


     //pega primmeiro nivel quando hierarchicalLevel for nulo
     let hierarchyLevel;
     if(this.hierarchyLevel == null){
       hierarchyLevel = 1
     }
     else{
       hierarchyLevel = this.hierarchyLevel
     }

      //descrição hierarquia atual completa
      this.breadCrumb = list.filter(item => item.hierarchicalLevel === hierarchyLevel).map(item => item.breadCrumb).toString();


      //id da hierarquia
      this.id = +list.filter(item => item.hierarchicalLevel === hierarchyLevel).map(item => item.id);

      //pega total de veículos associados na hierarquia atual ou nas hierarquias filhas da atual
     for(var count:number = 0;count<this.ChildrenDisassociate.length;count++){
      this.totalVehicles = this.totalVehicles+this.ChildrenDisassociate[count].vehicleCount
     }

     //função que gera Array com hierarquias disponiveis para receberem veículos
      this.getDestinyChildrenHierarchy(list,this.Children)

    }

    getHierarchyNode(hierarchySelect: number = null,list) {
      this.hierarchyService
        .getGroupHierarchy(hierarchySelect)
        .pipe(
          finalize(() => {

          })
        )
        .subscribe(
          (success: any) => {
           if(success.data.tree.children.length == 0){
             this.getAvailableHierarchys(true,success.data.tree.hierarchicalLevel,list)
           }
           else{
             this.getAvailableHierarchys(false,success.data.tree.hierarchicalLevel,list)
           }
          },
          (error) => {
            // TODO: Depois que for implementado alertService descomentar a linha de baixo para tratar os retornos das apis
            //this.alertService.danger(error.message);
            console.error("getFromServerError", error);
            return;
          }
        );

    }

    getAvailableHierarchys(available,level,list){
      let availableChild = list.filter(item => item.hierarchicalLevel === level)
      if(available === true){
        this.availableChildrens.push(availableChild[0])
      }
    }


    getDestinyChildrenHierarchy(list,Children){
    let HierarchyLevelChildrens = Children.map(item => item.hierarchicalLevel)

    for(var count:number = 0;count<list.length;count++){
      if(HierarchyLevelChildrens.includes(list[count].hierarchicalLevel) == false){
        this.getHierarchyNode(list[count].hierarchicalLevel,list)
      }
    }
    }


    generateTree(tree){
      this.array.push(tree);
      this.nodeItems = this.array;
      this.GetHierarchyList(tree.description_PT);
    }

    generatebreadCrumb(breadCrumb,description_PT){
     return breadCrumb.replace(description_PT,'')
    }


    onItemChange(Source,Destiny,position,breadCrumb,description_PT){
      this.ChangeHierarchy[position] = {
        hierarchyIdSource: Source,
        hierarchyIdDestiny: Destiny,
        breadCrumb: breadCrumb,
        description_PT: description_PT
      }

      this.TransferGroupHierarchy[position] = {
        hierarchyIdSource: Source,
        hierarchyIdDestiny: Destiny,
      }
    }

    SaveChanges(){
      if(this.TransferGroupHierarchy.length > 0){
         this.transferVehicles()
      }
      this.deleteHierarchy();
    }

    generateDeleteModalTreeObject(){
      return {
        isDeleteTree: true,
        ChangeHierarchy: this.ChangeHierarchy
      }
    }

    transferVehicles(){
      this.hierarchyService
      .PostTransferVehicles(this.TransferGroupHierarchy)
      .subscribe(
        (success: any) => {
          this.notifyService.showSuccess('Atenção!', 'Veículos associados com sucesso!');
        },
       (error) => {
        console.error(error);
       });
  }

    deleteHierarchy(){
      this.hierarchyService
      .DeleteHierarchy(this.id)
      .subscribe(
        (success: any) => {
          this.successDeleted = true;
       },
       (error) => {
          this.successDeleted = false;
       });
    }

    }
