<div class="billVehicleModalComponent">
  <div class="container-loading" *ngIf="isLoading" >
    <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
  </div>
  <div class="modal-content"  *ngIf="!isLoading">
      <div class="modal-header">
        <div class="box-title"><span>DETALHES DE TRANSAÇÕES</span></div>
        <button class="close"  id="btn-close" type="button" (click)="dismissModal()"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body show modal-edit-vehicle">
     <div class="header1">
      <div class="row">
        <div class="col-md-auto headercolumn1">
            <a class="img-hierarchy"><img src="/assets/img/bill-detail-hierarchy@2x.png"/></a>
            <div class="d-flex flex-column mr-5">
                <!-- <span class="fs-12">Frota 2</span>
                <span class="gray-hierarchy">Sudeste / São Paulo / Sede 3 / Tags ativas</span> -->
                <span class="fs-12" id="hierarchy">{{Hierarchy}}</span>
                <span class="gray-hierarchy" id="hierarchyFull">{{HierarchyFull}}</span>
            </div>
        </div>
        <div class="col-4 headercolumn2">
            <div class="d-flex flex-column">
                <span class="fs-12">Valor total do veículo:</span>
                <span><div class="money">R$</div><div class="blue-hierarchy" id="amountTotal">{{amountTotal | currency: ' ':'symbol':'0.2-2':'pt-BR'}}</div></span>
            </div>
        </div>
    </div>
     </div>
     <div class="header2">
      <table class="table">
        <thead class="thead">
          <tr class="head1">
              <td ><div class="row"><div class="col-sm-auto">PLACA:</div><div class="col" id="licensePlate">{{licensePlate}}</div></div></td>
              <td ><div class="row"><div class="col-sm-auto">TIPO:</div><div class="col" id="descriptionVehicleType">{{descriptionVehicleTypePT}}</div></div></td>
              <td ><div class="row"><div class="col-sm-auto">Nº DE EIXOS:</div><div class="col" id="descriptionAxle">{{descriptionAxlePt}}</div></div></td>
          </tr>
          <tr class="head2">
            <td ><div class="row"><div class="col-sm-auto">APELIDO:</div><div class="col" id="nickname">{{nickname}}</div></div></td>
            <td><div class="row"><div class="col-sm-auto">MARCA:</div><div class="col" id="descriptionVehicleBrandType">{{descriptionVehicleBrandTypePt}}</div></div></td>
            <td ></td>
        </tr>
        <tr class="head3">
           <td><div class="row"><div class="col-sm-auto">TAG:</div><div class="col" id="serialTag">{{serialTag}}</div></div></td>
            <td><div class="row"><div class="col-sm-auto">MODELO:</div><div class="col" id="model">{{model}}</div></div></td>
             <td></td>
      </tr>
        </thead>
       </table>
     </div>


          <div class="desc-vehicles-col">
              <table class="table table-title">
                  <thead class="thead">
                      <tr class="head1">
                        <td colspan="4" ><div class="desc-vehicles">DETALHES</div></td>
                        <td colspan="1"><div  class="values-vehicles">VALORES (R$)</div></td>
                      </tr>
                    <tr class="head2">
                      <th (click)="onOrderByChange('passengerDate')" [ngClass]="bindOrderByClass('passengerDate')">
                        <button class="btn-order-table" id="passengerDate" translate>Data da Transação</button>
                        <div class="round-order">
                          <img src="../../../assets/svg/order-table.svg" alt="" />
                        </div>
                      </th>
                      <th (click)="onOrderByChange('processingDate')" [ngClass]="bindOrderByClass('processingDate')">
                        <button class="btn-order-table" id="processingDate" translate>Data do Processamento</button>
                        <div class="round-order">
                          <img src="../../../assets/svg/order-table.svg" alt="" />
                        </div>
                      </th>
                      <th [ngClass]="bindOrderByClass('billItemType')" (click)="onOrderByChange('billItemType')">
                        <button class="btn-order-table" id="billItemType" translate >Tipo de Transação</button>
                        <div class="round-order">
                          <img src="../../../assets/svg/order-table.svg" alt="" />
                        </div>
                      </th>
                      <th (click)="onOrderByChange('merchantName')" [ngClass]="bindOrderByClass('merchantName')">
                        <button class="btn-order-table" id="merchantName" translate >Descrição</button>
                        <div class="round-order">
                          <img src="../../../assets/svg/order-table.svg" alt="" />
                        </div>
                      </th>
                      <th  (click)="onOrderByChange('amount')" [ngClass]="bindOrderByClass('amount')">
                        <button class="btn-order-table" id="totalAmmount" translate>Total</button>
                        <div class="round-order">
                          <img src="../../../assets/svg/order-table.svg" alt="" />
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody >
                       <tr *ngFor="let vehicle of BillVehicleList; let i = index" [ngClass]="addClass(i)">
                         <td *ngIf="vehicle.billItemTypeId !== 3 && vehicle.billItemTypeId !== 4">{{vehicle.passengerDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                         <td *ngIf="vehicle.billItemTypeId == 3 || vehicle.billItemTypeId == 4"></td>
                         <td>{{vehicle.processingDate  | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                         <td>{{vehicle.billItemType}}</td>
                         <td>{{vehicle.merchantName}}</td>
                         <td>{{vehicle.amount | currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}</td>
                       </tr>
                       <div class="pagination-custom" *ngIf="total>BillVehiclesQueryArguments.pageSize">
                          <app-pagination
                            [totalItems]="total"
                            [itemsPerPage]="BillVehiclesQueryArguments.pageSize"
                            [maxPages]="5"
                            [currentPage]="BillVehiclesQueryArguments.page"
                            (pageChangeEvent)="onPaginationChange($event)"
                          ></app-pagination>
                      </div>
                  </tbody>

                  <tfoot>
                    <button type="button" class="btn btn-default" id="dismissModal" (click)="dismissModal()">FECHAR</button>
                  </tfoot>
                </table>

          </div>

      </div>
  </div>
</div>
