<div class="Dashboard container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="col">
        <app-page-title [title]="'/ Ativar Tags'"></app-page-title>
      </div>
      <div data-e2e="tag-activation-time-msg" class="page-subtitle">
        Fique atento, em alguns estabelecimentos, as novas Tags podem levar até 6 horas para serem ativadas.
      </div>

    </div>

    <div class="col-12">
      <app-first-access-ballon [page]="2"></app-first-access-ballon>
    </div>

    <div *accessControl="103003" class="container-link-batch-management">

      <app-borderless-button
        [description]="'Ativar TAGs em lote'"
        [id-html]="'link-page-batch-management'"
        [data-e2e]="'link-page-batch-management'"
        [classIconLeft]="'ico-two-chains'"
        [buttonColor]="'tertiary'"
        [routerLink]="'/gestao-lote'"
        [isBtnWithBorder]="true"
      >
      </app-borderless-button>

    </div>

    <div class="col-6 Dashboard_col_1">
      <app-tags-disponiveis
        [elementEmpty]="isEmpty"
        (changeTag)="onchangeTag($event)"
        [tagSelected]="tagselected">
      </app-tags-disponiveis>
    </div>
    <!-- /box left-->

    <div class="col-6 Dashboard_col_2">
      <app-veiculos-disponiveis
        (changeVehicle)="onchangeVehicle($event)"
        [contractvehicleselected]="contractvehicleselected">
      </app-veiculos-disponiveis>
      <!--Dashboard_ExtratoVPR-->
    </div>

  </div>

  <!--row-->
  <div class="row">
    <div class="text-center mt-4">
     <app-borderless-button
        [description]="'Confirmar'"
        [id-html]="'confirm'"
        [data-e2e]="'save-contract-tag-btn'"
        [isBtnWithBorder]="true"
        [buttonColor]="'primary'"
        (emitterEvtClick)="saveContractVehicleTag()"
        [verifyContractBlock]="true"
        [listVerifyContractBlock]="listVerifyContractStatus"
        [isDisable]="(tagselected==null || contractvehicleselected==null)"
      >
      </app-borderless-button>

    </div>
    <div class="col-lg-4 ">
    </div>
  </div>
</div>

