import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { FiltersService } from '../../filters.service';
import { FiltersComponent } from '../../filters.component';
import {EFiltersIds} from "@models/filters-backoffice.model";

export class TypesChecked {
  transactionCheck?: number[];
  statusCheck?: number[];
  refusalCheck?: number[];
}
@Component({
  selector: 'app-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: [
    '../../filters.component.scss',
    './checkbox-filter.component.scss',
  ],
})
export class CheckboxFilterComponent implements OnInit {
  @Input() labelTitle: string = 'Tipo';
  @Input() typesData: [];
  @Input() transactionTypeIcon: boolean;
  @Input() statusIcon: boolean;
  @Input() idFilter: EFiltersIds;
  @Output() getFilter = new EventEmitter<number[]>();
  @Input() textValue: string;

  typesChecked = [];

  test: TypesChecked = {
    transactionCheck: [],
    statusCheck: [],
    refusalCheck: [],
  };

  constructor(
    private _filtersService: FiltersService,
    public filtersComponent: FiltersComponent,
  ) {}
  ngOnInit(): void {
    this.getValuesToPersist();
  }

  showFilterContent() {
    this._filtersService.activeFilter = this.idFilter;
    this.filtersComponent.closeOtherFilters(this.idFilter);
  }

  isChecked(typeId: number) {
    let isChecked = this.typesChecked.includes(typeId);
    return isChecked;
  }

  onCheckboxChange(typeId: number) {
    let index = this.typesChecked.indexOf(typeId);

    let active = this._filtersService.activeFilter;

    if (index > -1) {
      this.typesChecked.splice(index, 1);
    } else {
      this.typesChecked.push(typeId);
    }

    switch (this.idFilter) {
      case 3:
        this.test.transactionCheck = this.typesChecked;
        this._filtersService.transactionType$.next(this.test.transactionCheck);
        break;
      case 4:
        this.test.statusCheck = this.typesChecked;
        this._filtersService.statusType$.next(this.test.statusCheck);
        break;
      case 7:
        this.test.refusalCheck = this.typesChecked;
        this._filtersService.refusalReason$.next(this.test.refusalCheck);
        break;
      default:
        this._filtersService.customType$.next(this.typesChecked);
    }

    this.getFilter.emit(this.typesChecked);
  }

  getValuesToPersist() {
    switch (this.idFilter) {
      case 3:
        this.typesChecked = this._filtersService.transactionType$.getValue();

        this.typesChecked.forEach((value) => {
          this.isChecked(value);
        });
        break;
      case 4:
        this.typesChecked = this._filtersService.statusType$.getValue();

        this.typesChecked.forEach((value) => {
          this.isChecked(value);
        });
        break;
      case 7:
        this.typesChecked = this._filtersService.refusalReason$.getValue();

        this.typesChecked.forEach((value) => {
          this.isChecked(value);
        });
        break;
      default:
        this.typesChecked = this._filtersService.customType$.getValue();

        this.typesChecked.forEach((value) => {
          this.isChecked(value);
        });
    }
  }
}
