export class Order {
  orderId?: number;
  deliverytTerm?: Date;
  statusOrder?: string;
  getTagRequestTrackingQueryResults?: OrderTracking[];
  trackingCode?: string;
  logisticPartnerLink?: string;
  logisticPartnerImage?: string;
  logisticPartnerName?: string;
  amount?: number;
  registrationDate?: string;
  shippingValue?: number;
  descriptionaddress?: string;
  fullName?: string;
  phoneNumber?: string;
  totalTagValue?: number;
  totalTagValueDiscount?: number;
  totalOrder?: number;
  totalOrderDiscount?: number;
  totalAmountPayable?: number;
  totalShippingDiscount?: number;
  subTotal?: number;
  protocol?: string;
  urlProtocolFile?: string;
  urlDeclarationFile?: string;
  name?: string;
  documentNumber?: string;
  areaCode?: string;
  telephoneNumber?: string;
  zipCode?: string;
  streetAddress?: string;
  number?: string;
  streetAddressLine2?: string;
  landmark?: string;
  district?: string;
  city?: string;
  mailBox?: string;
  federatedUnit?: string;
  country?: string;
  personAddressTypeId?: number;
  addressTypeId?: number;
  logisticPartnerId?: number;
  contractType?: number;
}

export class OrderTracking {
  registrationDate?: Date;
  description?: string;
  isActive?: Boolean;
  id?: number;
}

export enum OrderStatus {
  REQUEST = 1,
  CANCELED = 2,
  SEPARATING = 3,
  SHIPPED = 4,
  IN_TRANSIT = 5,
  DELIVERED = 6,
  RECEIVED = 7,
  AWAITING_PAYMENT = 8,
  REQUESTED = 9,
  ON_CARRIER = 10,
  PAYMENT_ACCEPTED = 11,
  PAYMENT_DECLINED = 12,
}
