import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GetVehicleQueryResultDto } from '../../../myvehicles/dtos/get-vehicle-query-result.dto';
import { MyvehiclesService } from '../../../myvehicles/services/myvehicles.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from '@services/logger.service';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs/operators';
import { ConfirmationTagUnlockModalComponent } from '../confirmation-tag-unlock-modal/confirmation-tag-unlock-modal.component';

const log = new Logger('TagUnlockModalComponent');

@Component({
  selector: 'app-tag-unlock-modal',
  templateUrl: './tag-unlock-modal.component.html',
  styleUrls: ['./tag-unlock-modal.component.css']
})
export class TagUnlockModalComponent implements OnInit {

  @Output() event: EventEmitter<any> = new EventEmitter();
  @Input() vehicle: GetVehicleQueryResultDto;
  unlock: any;
  onSaving: boolean;

  constructor(
    private modalService: NgbModal,
    private myVehiclesService: MyvehiclesService,
    private notifyService: NotifyService
  ) { }

  ngOnInit(): void {

    this.unlock = {
      contractVehicleId: this.vehicle.contractVehicleId,
      tagId: this.vehicle.tagId
    }

  }

  unlockTag() {
    this.onSaving = true;
    this.myVehiclesService
      .unlockTag(this.unlock)
      .pipe(finalize(() => { this.onSaving = false; }))
      .subscribe(
        (success) => {
          this.notifyService.showSuccess('Atenção!', 'Tag desbloqueada com sucesso!');
          this.modalService.dismissAll();
          this.redirectToConfirmation(success);
          this.event.emit(success);
        },
        (e) => {
         console.error(e);
        }
      );
  }

  redirectToConfirmation(success: any) {
    const modalRef = this.modalService.open(ConfirmationTagUnlockModalComponent);
    modalRef.componentInstance.receivedEntry = success;
  }

  close() {
    this.modalService.dismissAll();
  }

}
