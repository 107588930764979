import { HttpParams } from '@angular/common/http';

export class GetContractVehiclesQueryArguments {
  search?:string;
  startDate:Date;
  endDate:Date;
  page:number;
  pageSize:number;
  get httpParans() {

    let httpParams = new HttpParams()
    .set('Page', this.page?.toString())
    .set('PageSize', this.pageSize?.toString())

    if(this.search){
      httpParams = httpParams.append('search', this.search?.toString());
    }

    // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC. 
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.startDate){
      httpParams = httpParams.append('startDate', this.startDate.toISOString());
    }

    // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC.
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.endDate){
      httpParams = httpParams.append('endDate', this.endDate.toISOString());
    }
    
    return httpParams;

  };
}


