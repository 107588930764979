import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Component, Input } from "@angular/core";

import { Vehicle } from "@models/vehicle.model";

@Component({
  selector: "app-vehicle-details-popover",
  templateUrl: "./vehicle-details-popover.component.html",
  styleUrls: ["./vehicle-details-popover.component.scss"],
})
export class VehicleDetailsPopoverComponent {
  @Input() vehicle: Partial<Vehicle>;
  @Input() hasActions: boolean = false;

  constructor(private _modal: NgbModal) {}

  redirectToUnlockTag(_t5: any) {
    this._modal.open('');
  }

  showReplaceTagVehicleModal(_t5: any) {
    this._modal.open('');
  }

  redirectToBlockTag(_t5: any) {
    this._modal.open('');
  }

  showDeleteVehicleModal(_t5: any) {
    this._modal.open('');
  }

  redirectToCancelTag(_t5: any) {
    this._modal.open('');
  }

  showAlterServiceModal(_t5: any) {
    this._modal.open('');
  }

  showEditVehicleModal(_t5: any) {
    this._modal.open('');
  }

  redirectToReincludeVehicleModal(_t5: any) {
    this._modal.open('');
  }
}
