import {inject} from "@angular/core";
import {LocalstorageService} from "@services/localstorage.service";
import {AuthService} from "../../../../core/auth/auth.service";
import {Observable} from "rxjs/internal/Observable";

export const mainMenuBackofficeResolver: () => Observable<any> = () => {
  const rolesBackOffice: string[] = inject(LocalstorageService).getRolesBackOffice();

  if (rolesBackOffice.length <= 1) {
    return inject(AuthService).setRolesOnStorage(true);
  }

  return;
}
