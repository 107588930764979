<div class="content-page">
  <div class="container-fluid page-myaccount">
    <div class="row">
      <header class="col">
        <app-page-title [title]="'/ Detalhes da Fatura'"></app-page-title>
      </header>

      <div class="d-flex row header-content">
        <div class="col-md-auto" id="div-btn-invoice-link">

          <app-borderless-button
            [description]="'Voltar para as faturas'"
            [id-html]="'btn-invoice-link'"
            [data-e2e]="'btn-invoice-link'"
            [isBtnWithBorder]="true"
            [buttonColor]="'tertiary'"
            [classIconLeft]="'ico-arrow-left'"
            [routerLink]="'/faturas'"
          >
          </app-borderless-button>

        </div>
        <div class="container-loading" *ngIf="isLoading">
          <app-loader
            [isLoading]="isLoading"
            message="{{ 'Carregando...' }}"
          ></app-loader>
        </div>
        <div class="col" id="id-bill-card" *ngIf="!isLoading">
          <app-bill-card [billHeaderDto]="billHeader" [count]="0" [isLoading]="isLoading" [inContractModalityTypeId]="contractModalityTypeId"></app-bill-card>
        </div>
      </div>

      <div class="col-12 Dashboard_col_1">
        <div class="container-bill" *ngIf="!isLoading">

          <div class="row tabs-responsive">
            <div class="col-12 d-flex justify-content-center">

              <app-tab-select [tabs]="tabs" (filterChange)="selectedTab = $event">
              </app-tab-select>

            </div>
          </div>

          <div class="tab-content">
            <div *ngIf="selectedTab === tabs[0].filters" role="tabpanel" class="tab-pane active" id="one">
              <app-bill-detail-general [billSummary]="billSummary"></app-bill-detail-general>
            </div>

            <div *ngIf="selectedTab === tabs[1].filters" role="tabpanel" class="tab-pane active" id="two">
              <app-bill-detail-vehicles></app-bill-detail-vehicles>
            </div>

            <div *ngIf="selectedTab === tabs[2].filters" role="tabpanel" class="tab-pane active" id="three">
              <app-bill-detail-hierarchy></app-bill-detail-hierarchy>
            </div>
          </div>
        </div>

        <div class="container-loading" *ngIf="isLoading">
          <app-loader [isLoading]="isLoading" message="{{ 'Download em andamento...'}}"></app-loader>
        </div>
      </div>
    </div>
  </div>
</div>
