import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  BsDaterangepickerConfig,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

import { FiltersService } from '../../filters.service';
import { Position } from '@models/types.model';
import {Filter, RequestType} from "@models/filters-backoffice.model";

@Component({
  selector: 'toll-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss'],
})
export class RangeDatePickerComponent {
  @Input() title: string = 'Período';
  @Input() request: RequestType;
  @Input('placement') position: Position = null;
  @Output() filters = new EventEmitter<Filter>();

  maxDate: Date = new Date();
  dateValue: Date = this.maxDate;
  minDate: Date = new Date('05/07/2017');

  constructor(
    public service: FiltersService,
    public bsConfig: BsDaterangepickerConfig,
    private _localeService: BsLocaleService,
  ) {
    this._localeService.use('pt-br');
  }

  emitFilter(filters): void {
    this.service.setDateFilter(filters, this.request);
  }
}
