import { ActionSuccessModalComponent } from './../action-success-modal/action-success-modal.component';
import { IHoliday } from './../../models/holidays.dto';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { HolidaysCalendarService } from '../../services/holidays-calendar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { NotifyService } from '@services/notify.service';

@Component({
  selector: 'app-exclude-modal',
  templateUrl: './exclude-holiday-modal.component.html',
  styleUrl: './exclude-holiday-modal.component.scss'
})
export class ExcludeHolidayModalComponent implements OnInit {

  @Input() holiday : IHoliday
  @Output() excluded = new EventEmitter<void>()
  excludeForm: FormGroup;

  constructor(private _holidayService : HolidaysCalendarService,
              private _formValidatorMessageService: FormValidatorMessageService,
              private _modalService: NgbModal,
              private _formBuilder: FormBuilder,
              private _notify: NotifyService)
  { }
  ngOnInit(): void {
    this.excludeForm = this._formBuilder.group({
      protocol: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
    });
  }

  dismissModal() {
    this._modalService.dismissAll();
  }

  generateErrorMessageByFormControlName(controlName: string): string {
    return this._formValidatorMessageService.generateErrorMessageByFormControlName(controlName);
  }

  validateProtocol(): boolean {
    const protocolControl = this.excludeForm.get('protocol');

    return protocolControl?.invalid && protocolControl?.touched;
  }

  excludeHoliday(id: number) {
    this._holidayService.deleteHoliday(id, this.excludeForm.get('protocol')!.value)
      .pipe(
        finalize(() => {})
      )
      .subscribe({
        next: (success) => {
          this.excluded.emit()
          this._modalService.dismissAll()
          const modal = this._modalService.open(ActionSuccessModalComponent);
          modal.componentInstance.success = success
          modal.componentInstance.holiday = this.holiday
          modal.componentInstance.type = 'exclude'

        },
        error: (error) => {

        }
      });
  }
}
