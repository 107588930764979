import { ExtractService } from './services/extract.service';
import { GetExtractQueryArguments } from './dtos/getExtractQueryArguments.dto';
import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BsDaterangepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
defineLocale('pt-br', ptBrLocale);
import moment from 'moment';
import { debounceTime, finalize } from 'rxjs/operators';
import { GetTransactionExtractDto } from './dtos/getTransactionExtract.dto';
import { GetExtractQueryResultDto } from './dtos/getExtractQueryResult.dto';
import { GetExtractDownloadQueryArguments } from './dtos/getExtractDownloadQueryArguments.dto';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ContractModality } from '@models/old/contractModality.enum';
import { NotifyService } from '@services/notify.service';
import { Router } from "@angular/router";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";

@Component({
  selector: 'app-extract',
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.scss']
})
export class ExtractComponent extends AbstractProcessLauncher implements OnInit {
  contractModality = ContractModality;
  radioType: string;
  radioSignal: string;
  disabled = false;
  PostPaidTypes = [
    { name: 'Pedágio', id: 9 },
    { name: 'Estorno de pedágio', id: 6 },
    { name: 'Estacionamento', id: 15 },
    { name: 'Estorno de estacionamento', id: 16 },
    { name: 'Recomposição de limite', id: 13 }
  ];
  PrePaidTypes = [
    { name: 'Pedágio', id: 9 },
    { name: 'Estorno de pedágio', id: 6 },
    { name: 'Estacionamento', id: 15 },
    { name: 'Estorno de estacionamento', id: 16 },
    { name: 'Recarga automática', id: 11 },
    { name: 'Recarga avulsa', id: 12 },
    { name: 'Mensalidade de tag ativa com débito no saldo', id: 18 },
    { name: 'Mensalidade de tag em estoque com débito no saldo', id: 19 },
    { name: 'Cancelamento de recarga automática', id: 20 },
    { name: 'Cancelamento de recarga avulsa', id: 21 },
    { name: 'Pedido de tag com débito no saldo', id: 23 }
  ];
  signal = [
    { name: 'Crédito', id: 1 },
    { name: 'Débito', id: 2 }
  ];
  public formExtract = this.formBuilder.group({
    signal: null,
    radioType: null,
  });
  public formPreDate = this.formBuilder.group({
    date: '7'
  });
  public formPerPages = this.formBuilder.group({
    perPages: '5'
  });
  inlineDateTime: { chosenLabel: string; startDate: moment.Moment; endDate: moment.Moment };
  @ViewChild(BsDaterangepickerDirective, { static: true })
  pickerDirective: BsDaterangepickerDirective;
  getExtractQueryArguments: GetExtractQueryArguments;
  items: Array<GetTransactionExtractDto>;

  getExtractQueryResult: GetExtractQueryResultDto;
  getExtractDownloadQueryArguments: GetExtractDownloadQueryArguments;
  perPages = [];
  filterDebounce: Subject<GetExtractQueryArguments> = new Subject<GetExtractQueryArguments>();
  total: number;
  isOpenDropdown: boolean;
  isShownPeriod: boolean;
  isShowType: boolean;
  isShownValue: boolean;
  isOpen: boolean;
  open: boolean;
  date: any;
  selectedDate = new Date();
  dayCustom: Date[];
  isDatePickerOpen: boolean;
  minDate: Date;
  maxDate: Date;
  element: HTMLElement;
  isLoading: boolean;
  isDownloading: boolean;
  tiposelecionado = 'Selecione';
  sinalselecionado = 'Selecione';
  creditCardBrand = [
    "",
    "mastercard",
    "hipercard",
    "visa",
    "amex",
    "elo",
    "dinersclub",
  ]

  constructor(private extractService: ExtractService,
    private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private notifyService: NotifyService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    protected router: Router) {
    super(router);
  }

  private executeSettingWhenStartComponent(): void {
    this.items = [];
    this.perPages = [5, 10, 20, 50];
    this.getExtractQueryResult = {
      itemsByDate: [{
        balanceAfterTransaction: 0,
        date: null,
        items: []
      }],
      total: 0,
      items: []
    }

    this.localeService.use('pt-br');

    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 395);
    this.maxDate.setDate(this.maxDate.getDate() + 0);

    this.getExtractDownloadQueryArguments = new GetExtractDownloadQueryArguments();

    this.getExtractQueryArguments = new GetExtractQueryArguments();
    this.getExtractQueryArguments.page = 1;
    this.getExtractQueryArguments.pageSize = this.perPages[0];
    this.getExtractQueryArguments.orderBy = "";
    this.getExtractQueryArguments.desc = false;
    this.getExtractQueryArguments.filter = 1;
    this.getExtractQueryArguments.search = "";
    this.getExtractQueryArguments.MovementType = 0;
    this.getExtractQueryArguments.TransactionType = 0;
    this.getExtractQueryArguments.FromDate = new Date(new Date().setDate(new Date().getDate() - 7))
    this.getExtractQueryArguments.ToDate = new Date();
    this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getExtractQuery(filter));
  }

  ngOnInit(): void {
    this.executeSettingWhenStartComponent();
    this.getExtractQuery(this.getExtractQueryArguments);
  }

  ngModelChange(e): void {
    console.log(e);
  }
  onFilterDate(values: Array<Date>): void {
    this.toggleDateRangePicker();
    this.getExtractQueryArguments.page = 1;
    this.getExtractQueryArguments.FromDate = values[0];
    this.getExtractQueryArguments.ToDate = values[1];
    this.filterDebounce.next(this.getExtractQueryArguments)
  }
  toogleDropdown() {
    this.isOpenDropdown = !this.isOpenDropdown;
    this.isShownPeriod = false;
    this.open = false;
    this.isShowType = false;
    this.isShownValue = false;
  }
  setSevenDaysLast() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 7);
    this.dayCustom = [this.selectedDate, new Date()];
    return this.dayCustom;
  }
  setThirtyDaysLast() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 30);
    this.dayCustom = [this.selectedDate, new Date()];
    return this.dayCustom;
  }
  setFifteenDaysLast() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 15);
    this.dayCustom = [this.selectedDate, new Date()];
    return this.dayCustom;

  }
  toggleShowType() {
    this.isShowType = !this.isShowType;
    this.isShownPeriod = false;
    this.isShownValue = false;
    this.isOpen = false;
  }
  toggleShowPeriod() {
    this.isDatePickerOpen = !this.isDatePickerOpen;
  }
  toggleDateRangePicker() {
    this.isShownPeriod = !this.isShownPeriod;
    this.isOpen = !this.isOpen;
    this.isShowType = false;
    this.isShownValue = false;
    this.toggleShowPeriod();
  }
  toggleShowValue() {
    this.isShownValue = !this.isShownValue;
    this.isShownPeriod = false;
    this.isShowType = false;
    this.isOpen = false;
  }

  compareDay(aux1: Date, aux2: Date): boolean {
    return new Date(aux1).setHours(0, 0, 0, 0) == new Date(aux2).setHours(0, 0, 0, 0);
  }

  generateDescription(item) {
    // descrição de Pedágio e estorno de pedágio
    if (item.transactionTypeId == 6 || item.transactionTypeId == 9) {
      return `${this.getValueOrEmptyString(item.summary)}
          ${this.getValueOrEmptyString(item.merchantName)}
          ${this.getValueOrEmptyString(item.placeName)}
          ${this.getValueOrEmptyString(item.address)}`
    }

    // descrição de Estacionamento e estorno de estacionamento
    if (item.transactionTypeId == 15 || item.transactionTypeId == 16) {
      return `${this.getValueOrEmptyString(item.merchantName)}
        Entrada: ${this.getValueOrEmptyString(
        this.datePipe.transform(
          item.entranceDate, 'dd/MM/yyyy HH:mm:ss'))}
        Saida: ${this.getValueOrEmptyString(
            this.datePipe.transform(
              item.departureDate, 'dd/MM/yyyy HH:mm:ss'))}`
    }

    // descrição de recarga automática
    if (item.transactionTypeId == 11) {
      return `Recarga automática paga via cartão de crédito ${this.creditCardBrand[item.creditCardBrandTypeId]} - **** **** **** ${item.creditCard},
        com cobrança de taxa de ${this.currencyPipe.transform(item.rechargeFee, ' ', 'symbol', '0.2-2', 'pt-BR')}%, no valor de ${this.currencyPipe.transform(item.rechargeFeeAmount, 'BRL', 'symbol', '0.2-2', 'pt-BR')}`
    }

    // descrição de recarga avulsa
    if (item.transactionTypeId == 12) {
      let paymentType = item.creditCard !== null ? `cartão de crédito ${this.creditCardBrand[item.creditCardBrandTypeId]} - **** **** **** ${item.creditCard}` : `PIX`;
      return `Recarga avulsa paga via ${paymentType},
        com cobrança de taxa de ${this.currencyPipe.transform(item.rechargeFee, ' ', 'symbol', '0.2-2', 'pt-BR')}%, no valor de ${this.currencyPipe.transform(item.rechargeFeeAmount, 'BRL', 'symbol', '0.2-2', 'pt-BR')}`
    }

    // Recomposição de limite - pagamento de fatura
    if (item.transactionTypeId == 13) {
      return item.description;
    }

    // descrição de mensalidade de tag ativa - pré-pago
    if (item.transactionTypeId == 18) {
      return this.getValueOrEmptyString(item.summary);
    }

    // descrição de mensalidade de tag em estoque - pré-pago
    if (item.transactionTypeId == 19) {
      return this.getValueOrEmptyString(item.summary);
    }

    // cancelamento de recarga automática com pagamento via cartão de crédito - pré-pago
    if (item.transactionTypeId == 20) {
      return this.getValueOrEmptyString(item.summary);
    }

    // cancelamento de recarga avulsa com pagamento via cartão de crédito - pré-pago
    if (item.transactionTypeId == 21) {
      return this.getValueOrEmptyString(item.summary);
    }

    // Pedido de tag com débito no saldo
    if (item.transactionTypeId == 23) {
      return this.getValueOrEmptyString(item.summary);
    }
    // Alteração de limite de crédito e débito
    if (item.transactionTypeId == 26 || item.transactionTypeId == 17) {
      return this.getValueOrEmptyString(item.summary)
    }
    return '';
  }


  getExtractQuery(args: GetExtractQueryArguments) {
    this.isLoading = true;
    this.extractService
      .getExtractQuery(args)
      .pipe(
        finalize(() => { this.isLoading = false; })
      )
      .subscribe(
        (success) => {
          this.getExtractQueryResult = success;
        },
        (error) => {
          this.notifyService.showWarning('Atenção', error.error[0].message);
          console.log(error);
          return;
        }
      );
  }

  onPaginationChange(page: number) {
    this.getExtractQueryArguments.page = page;
    this.getExtractQuery(this.getExtractQueryArguments);
  }

  onFullNameKeyup(value: any) {
    this.getExtractQueryArguments.page = 1;
    this.getExtractQueryArguments.search = value.replace("-", "");
    this.filterDebounce.next(this.getExtractQueryArguments)
  }

  onPageSizeChange(value: any) {
    this.getExtractQueryArguments.page = 1;
    this.getExtractQueryArguments.pageSize = value;
    this.getExtractQuery(this.getExtractQueryArguments);
  }

  onFilterSinalClick(event: any) {
    this.sinalselecionado = event.name;
    this.getExtractQueryArguments.page = 1;
    this.getExtractQueryArguments.MovementType = event.id;
    this.filterDebounce.next(this.getExtractQueryArguments)
  }
  onFilterTypeClick(event: any) {
    this.tiposelecionado = event.name;
    this.getExtractQueryArguments.page = 1;
    this.getExtractQueryArguments.TransactionType = event.id;
    this.filterDebounce.next(this.getExtractQueryArguments)
  }
  resetFilters() {
    this.selectedDate.setDate(new Date().getDate() - 7);
    this.dayCustom = [this.selectedDate, new Date()];
    this.formPreDate.get('date').setValue('7');
    this.tiposelecionado = 'Selecione';
    this.sinalselecionado = 'Selecione';
    this.formExtract.get('signal').reset();
    this.formExtract.get('radioType').reset();
    this.formPerPages.get('perPages').setValue("5");
    this.getExtractQueryArguments.page = 1;
    this.getExtractQueryArguments.pageSize = this.perPages[0];
    this.getExtractQueryArguments.filter = 1;
    this.getExtractQueryArguments.TransactionType = 0;
    this.getExtractQueryArguments.MovementType = 0;
    this.getExtractQueryArguments.search = "";
    this.getExtractQueryArguments.FromDate = new Date(new Date().setDate(new Date().getDate() - 7))
    this.getExtractQueryArguments.ToDate = new Date();
    this.getExtractQuery(this.getExtractQueryArguments);
  }

  hideDate() {
    this.element = document.getElementById('hideDate') as HTMLElement;
    this.element.click();
  }

  Download() {
    this.getExtractDownloadQueryArguments.page = this.getExtractQueryArguments.page;
    this.getExtractDownloadQueryArguments.pageSize = this.getExtractQueryArguments.pageSize;
    this.getExtractDownloadQueryArguments.orderBy = this.getExtractQueryArguments.orderBy;
    this.getExtractDownloadQueryArguments.desc = this.getExtractQueryArguments.desc;
    this.getExtractDownloadQueryArguments.filter = this.getExtractQueryArguments.filter;
    this.getExtractDownloadQueryArguments.search = this.getExtractQueryArguments.search;
    this.getExtractDownloadQueryArguments.TransactionType = this.getExtractQueryArguments.TransactionType;
    this.getExtractDownloadQueryArguments.MovementType = this.getExtractQueryArguments.MovementType;
    this.getExtractDownloadQueryArguments.FromDate = moment(this.getExtractQueryArguments.FromDate).format(
      'MM/DD/YYYY'
    );
    this.getExtractDownloadQueryArguments.ToDate = moment(this.getExtractQueryArguments.ToDate).format('MM/DD/YYYY');
    this.DownloadCSV(this.getExtractDownloadQueryArguments);
  }

  public DownloadCSV(args: GetExtractDownloadQueryArguments) {
    let now = new Date();
    let date = moment(now).format('YYYY_MM_DD');
    this.isDownloading = true;
    this.extractService
      .GetCSVFile(args)
      .pipe(
        finalize(() => {
          this.isDownloading = false;
        })
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Extrato ' + date + '.csv';
        element.click();
      });
  }

  private getValueOrEmptyString(value: any) {
    if (value == '01/01/0001 00:00:00') {
      return '';
    } else {
      return value ? value : '';
    }
  }
}
