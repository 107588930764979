import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import {ContractDto} from "@models/old/contract.model";
import {CurrentUserDto} from "@models/backoffice/current-user.dto";
import {PasswordUserDto} from "@models/backoffice/password-user.dto";
import {ProfileGroupDto} from "@models/backoffice/profile-group.dto";
import {UsersDto} from "@models/backoffice/users.dto";

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _contractBillingCuttingCycle: string;

  constructor(private http: HttpClient) {}

  get contractBillingCuttingCycle(): string {
    return this._contractBillingCuttingCycle;
  }

  set contractBillingCuttingCycle(cuttingDay: string) {
    this._contractBillingCuttingCycle = cuttingDay;
  }

  // Get users (Account)
  getUnblockedUsers() {
    return this.http.get<UsersDto[]>(`CustomerService/User/Unblocked`);
  }

  getUsers() {
    return this.http.get<UsersDto[]>(`CustomerService/User`);
  }

  // Get current user (Account)
  getCurrentUser() {
    return this.http.get<CurrentUserDto>(`CustomerService/User/CurrentUser`);
  }

  getContractsById() {
    return this.http.get<ContractDto>(
      environment.apiTMCustomer + `/Contract/GetContractById`,
    );
  }

  // Put change data (My account)
  changeDataCurrentUser(currentUser: CurrentUserDto) {
    return this.http.put(
      `CustomerService/User/CurrentUser/AlterUser`,
      currentUser,
    );
  }

  // Put change password (My account)
  changePasswordUser(passwordUser: PasswordUserDto) {
    return this.http.put(
      `CustomerService/User/CurrentUser/AlterPassword`,
      passwordUser,
    );
  }

  // Get user group (User)
  getUserGroup() {
    return this.http.get<ProfileGroupDto[]>(`CustomerService/Group`);
  }

  // Post create user (User)
  postCreateUser(createUser: CurrentUserDto) {
    return this.http.post(`CustomerService/User/`, createUser);
  }

  // Post block user (User)
  postBlockUser(userLock: UsersDto) {
    return this.http.post(
      `CustomerService/User/Block/${userLock.id}`,
      userLock,
    );
  }

  getContractPrePaidSummaryById() {
    return this.http.get<any>(
      environment.apiTMCustomer + `/Contract/GetContractPrePaidSummaryById`,
    );
  }
}
