<div class="balloonFirstAccess" *ngIf="contractBlock == 6">
  <img class="woman" *ngIf="page == firstAccessBallonPage.Dashboard" src="../../../assets/img/woman1.svg"/>
  <img class="man_notebook" *ngIf="page == firstAccessBallonPage.TagActivation || page == firstAccessBallonPage.Myorder"
       src="../../../assets/img/man_notebook.svg"/>
  <div *ngIf="page == firstAccessBallonPage.Dashboard" data-e2e="welcome-msg" class="message">
    <strong>Bem-vindo,</strong>
    <p> Para começar é necessário fazer a sua primeira recarga</p></div>
  <div *ngIf="page == firstAccessBallonPage.TagActivation" data-e2e="welcome-msg" class="message">
    <strong>Bem-vindo,</strong>
    <p> Para ativar suas tags é necessário fazer sua primeira recarga</p></div>
  <div *ngIf="page == firstAccessBallonPage.Myorder" data-e2e="welcome-msg" class="message"><strong>Bem-vindo,</strong>
    <p> Antes de realizar um pedido é necessário fazer sua primeira recarga</p></div>
  <div *controlAccessCustomer>
    <button *accessControl="101174" data-e2e="add-btn" id="creditCardRegister" type="button" class="btn btn-repom"
            (click)="redirectToRecharge()">
      Realizar minha primeira recarga<span class="btn-animation"></span>
    </button>
  </div>
</div>
