<mat-form-field appearance="outline" class="outlined-select" [class]="disableRipple ? 'no-ripple' : '' " [style]="handleStyleByInput()">
  <mat-label>{{title}}</mat-label>
  @if(classIconLeft){
    <mat-icon matPrefix >
      <i class="icn ico {{classIconLeft}} custom-icon" ></i>
    </mat-icon>
  }
  <mat-select data-e2e="select-btn" [formControl]="selectControl" (selectionChange)="onValueChange($event)" [disableRipple]="disableRipple" [multiple]="multiple" >
    @if(multiple || hasLabel){
      @for( value of values ; let i = $index; track value){
        <mat-option [id]="'option'+i"  value="{{ value.value }}" [attr.data-e2e]="'option'+i">@if(beforeTextValue){ {{ beforeTextValue}}}{{ value.label }} @if(afterTextValue){ {{ afterTextValue}}}</mat-option>
        }
    } @else {
      @for( value of values; let i = $index; track value){
        <mat-option [id]="'option'+i"  value="{{ value }}" [attr.data-e2e]="'option'+i">@if(beforeTextValue){ {{ beforeTextValue}}}{{ value }} @if(afterTextValue){ {{ afterTextValue}}}</mat-option>
        }
    }

  </mat-select>
</mat-form-field>
