import {Injectable} from '@angular/core';
import {Bill} from "@models/old/financial/bill/bill-list/bill.dto";
import {BILL_STATUS} from "src/app/shared/constants/bill-status";
import {COLOR} from "src/app/shared/constants/color";
import {BORDER} from "src/app/shared/constants/border";
import {BillHeaderDto} from "../../../bill/dto/bill-header.dto";

@Injectable({
  providedIn: 'root'
})
export class BillCardService {

  constructor() {
  }

  public configureBillStatusAppearance(bill: Bill): void {
    switch (bill.idStatus) {
      case BILL_STATUS.ABERTA:
        this.setBillStyle(bill, COLOR.WHITE, COLOR.BLUE, COLOR.BLUE, BORDER.BLUE);
        break;
      case BILL_STATUS.A_PAGAR:
        this.setBillStyle(bill, COLOR.WHITE, COLOR.YELLOW, COLOR.YELLOW, BORDER.YELLOW);
        break;
      case BILL_STATUS.ATRASADA:
        this.setBillStyle(bill, COLOR.WHITE, COLOR.RED, COLOR.RED, BORDER.RED);
        break;
      case BILL_STATUS.EM_FECHAMENTO:
        this.setBillStyle(bill, COLOR.WHITE, COLOR.PURPLE, COLOR.PURPLE, BORDER.PURPLE);
        break;
      case BILL_STATUS.FECHADA:
        this.setBillStyle(bill, COLOR.WHITE, COLOR.GREEN, COLOR.GREEN, BORDER.GREEN);
        break;
      case BILL_STATUS.PAGA:
        this.setBillStyle(bill, COLOR.WHITE, COLOR.GREEN, COLOR.GREEN, BORDER.GREEN);
        break;
    }
  }

  private setBillStyle(bill: Bill, statusColor: string, backgroundColor: string, amountColor: string, border: string): void {
    bill.styleStatusColor = statusColor
    bill.styleAmountColor = amountColor
    bill.styleBackgroundColor = backgroundColor
    bill.styleBorder = border
  }

  public fromBillHeaderToBill(billHeaderDto: BillHeaderDto): Bill {
    const bill = new Bill();
    bill.id = billHeaderDto.id;
    bill.status = billHeaderDto.billStatusTypeDescription_PT; // Supondo que status seja a descrição em inglês
    bill.idStatus = billHeaderDto.billStatusTypeId;
    bill.openingDate = billHeaderDto.openingDate;
    bill.closingDate = billHeaderDto.closingDate;
    bill.dueDate = billHeaderDto.dueDate;
    bill.amount = billHeaderDto.billTotalValue;
    bill.billetLink = billHeaderDto.billetLink;
    bill.invoiceLink = billHeaderDto.invoiceLink;
    bill.pdfLink = billHeaderDto.pdfLink;
    bill.excelLink = billHeaderDto.excelLink;
    bill.flgDownloadPDF = billHeaderDto.flgDownloadPDF;
    bill.csvFileStatusID = billHeaderDto.csvFileStatusID;
    bill.csvFile = billHeaderDto.csvFile;
    bill.pdfFileStatusID = billHeaderDto.pdfFileStatusID;
    bill.pdfFile = billHeaderDto.pdfFile;
    bill.paymentDate = billHeaderDto.paymentDate;
    bill.permissionManualRecomposition = billHeaderDto.permissionManualRecomposition;

    this.configureBillStatusAppearance(bill);
    return bill;
  }
}
