import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectProfileBackOfficeComponent } from '../select-profile-back-office.component';
import { RoutesPaths } from "../../../../core/utils/routes/routes-paths";
import { RoutesService } from "@modules/backoffice/page-backoffice/services/routes.service";
import { authBackOfficeGuard } from "../../../../core/auth/auth-backoffice.guard";

const routes: Routes = [
  {
    path: 'backoffice/atendimento',
    redirectTo: '/backoffice/' + RoutesPaths.SELECT_PROFILE,
    pathMatch: 'full',
  },

  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.SELECT_PROFILE,
        component: SelectProfileBackOfficeComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Acessar Contrato'),
          roles: '100992',
          contractRequired: false
        },
      },
      {
        path: RoutesPaths.SELECT_PROFILE_FINANCIAL_BLOCKING_POS_PAID,
        canActivate: [authBackOfficeGuard],
        component: SelectProfileBackOfficeComponent,
        data: {
          title: 'CONSULTA DE CLIENTES',
          roles: '100992',
        },
      },
    ],
    true,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SelectProfileBackOfficeRoutingModule { }
