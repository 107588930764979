import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from '@services/event-emitter.service';
import { environment } from '@env';
import {UserProfileService} from "@services/user-profile.service";

declare function openChatGamora(fila): any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() disableButton: boolean = false;

  constructor(private router: Router, private userProfileService: UserProfileService) { }

  ngOnInit() {
    this.disableButton = this.userProfileService.isBackOffice();
  }

  openChat(){
    openChatGamora(environment.filaChat);
  }

  emitOrRedirectToContactUs(){
    if(this.router.url == '/fale-conosco'){
      EventEmitterService.get('alreadyInContactUsPage').emit();
    }
    else{
      this.router.navigate(['/fale-conosco']);
    }
  }

}
