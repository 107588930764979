export const NOTIFICATION_TYPE = {
  BATCH_VEHICLE_VALID: 1,
  BATCH_VEHICLE_INVALID: 2,
  EXTERN_LINK: 3,
  TAG_REQUEST: 4,
  FILE_IMPORT_CONCIL: 5,
  INVOICE: 6,
  FEEDBACK: 7,
  FEEDBACK_ERROR: 8,
  PIX_RECHARGE_MODAL: 9,
  PIX_TAG_REQUEST_MODAL: 10,
  PIX_EXPIRED: 11,
  MODAL_HOME_INFORMATIVE: 12,
  BULK_ACTIVATION_TAG: 14,
  BULK_BLOCKUNBLOCK: 15,
}
