import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, animateChild, query, style, transition, trigger} from "@angular/animations";
import {OAuthService} from "angular-oauth2-oidc";
import {SidebarService} from "@modules/commom/header/componentes/customer-header/services/sidebar.service";
import {Menu} from "@modules/commom/header/componentes/customer-header/models/menu.model";
import {LocalstorageService} from "@services/localstorage.service";

@Component({
  selector: 'app-menu-responsive',
  templateUrl: './menu-responsive.component.html',
  host: {
    '[@slide]': '',
  },
  styleUrls: ['./menu-responsive.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease', style({ opacity: 1 })),
        query('@*', [animateChild()], { optional: true }),
      ]),
      transition(':leave', [
        query('@*', [animateChild()], { optional: false }),
      ]),
    ]),

    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate(
          '800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate(
          '300ms ease',
          style({
            transform: 'translateX(-100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)',
          })
        ),
      ]),
    ]),
  ],
})
export class MenuResponsiveComponent {
  @Input() companyName: any;
  @Input() haveManyContracts!: any;
  @Output() dismiss: EventEmitter<any> = new EventEmitter<any>();

  menuSidebar: Menu[] = [];

  private isOpenMenu: boolean = false;

  constructor(private sidebarService: SidebarService,
              private oauthService: OAuthService,
              private localstorageService: LocalstorageService) {
    this.menuSidebar = this.sidebarService.menuSidebar;
  }

  /**
   Verificar se ocorreu click fora do menu e fechar todos os menus em aberto
   */
  handleClickOutside() {
    if (this.isOpenMenu) {
      this.dismiss.emit();
    } else {
      this.isOpenMenu = !this.isOpenMenu;
    }
  }

  /**
     Ao clicar no titulo, o item que contem submenu recebera a class showMenu
     para que seja aberto a lista de submenu
   */
  showSubmenu(itemEl: HTMLElement, item: any): void {
    itemEl.classList.toggle("showMenu");
    this.handleClassOpenClose(itemEl, item)
  }

  /**
      Verificar se o elemento selecionado contem a classe showMenu e add a classe correspondente
      para que seja aberto o icone correto
   */
  private handleClassOpenClose(itemEl: HTMLElement, itemMenu: any): void {
    if (itemEl.classList.contains("showMenu")) {
      const elementMenu = this.findElementForId(itemEl, itemMenu);
      if (elementMenu) {
        elementMenu.classList.remove("img-menu-close");
        elementMenu.classList.toggle("img-menu-open");
      }
    } else {
      const elementMenu = this.findElementForId(itemEl, itemMenu);
      if (elementMenu) {
        elementMenu.classList.remove("img-menu-open");
        elementMenu.classList.toggle("img-menu-close");
      }
    }
  }

  private findElementForId(itemEl: HTMLElement, itemMenu: any): Element | undefined {
    const idMenu = this.generateDynamicIDElement(itemMenu);
    return itemEl.querySelector("#"+idMenu);
  }

  generateDynamicIDElement(item): string {
    const prefix = "id-menu-";
    const suffix = item.link_name.replace(" ","").substring(0,3).toLocaleLowerCase();
    return prefix + suffix;
  }

  logout(): void {
    this.localstorageService.removeAllProfiles();
    this.oauthService.logOut();
  }

}
