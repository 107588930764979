<div class="formComponent"
  *ngIf="chosenSubCategoryId && chosenSubCategoryId !== contactTypeIdEnum.UpdateLicensePlateMercosur &&  chosenSubCategoryId !== contactTypeIdEnum.RegisterNewUser">
  <form [formGroup]="ContactUsForm" (ngSubmit)="onSubmit()">
    <div class="row">

      <!-- Contestação de Passagem - Campos -->
      <div class="formfield col-sm-auto subCategory4"
        *ngIf="chosenSubCategoryId == contactTypeIdEnum.ContestationPassage">
        <label for="licensePlate" data-e2e="plate-label">Placa</label>
        <div class="formInput">
          <input [ngClass]="(ContactUsForm.controls['licensePlate']?.invalid && ContactUsForm.controls['licensePlate']?.touched)
                  || (submitted && ContactUsForm.controls['licensePlate']?.invalid) ? 'invalidField' : ''"
            class="subCategory4Input" data-e2e="plate-input" id="licensePlate" type="text"
            formControlName="licensePlate" [mask]="'AAA-AAAA'">

          <div class="alertErrorValidator" data-e2e="plate-error-msg" *ngIf="(ContactUsForm.controls['licensePlate']?.invalid && ContactUsForm.controls['licensePlate']?.touched)
                  || (submitted && ContactUsForm.controls['licensePlate']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['licensePlate'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-sm-auto subCategory4 dateField"
        *ngIf="chosenSubCategoryId == contactTypeIdEnum.ContestationPassage">
        <label for="passageDate" data-e2e="passage-date-label">Data da passagem</label>
        <div class="formInput">
          <img id="ico-calendar" src="assets/img/ico-calendar.svg" />
          <input data-e2e="passage-date-input" id="passageDate" type="text"
            class="simulate-form-control subCategory4Input" #dp="bsDatepicker" bsDatepicker
            formControlName="passageDate" (bsValueChange)="onFilterDateGeneral(0)" [triggers]="isShowDatePicker[0]"
            [ngClass]="(ContactUsForm.controls['passageDate']?.invalid && ContactUsForm.controls['passageDate']?.touched)
                || (submitted && ContactUsForm.controls['passageDate']?.invalid) ? 'invalidField' : '' "
            rInputMask="99/99/9999" [outsideClick]="false">
          <a [ngClass]="isShowDatePicker[0] ? 'selected' : ''" data-e2e="passage-date-picker-btn"
            (click)="toggleDatePicker(dp,0)">
            <img src="assets/img/arrow-select.svg" />
          </a>

          <div class="alertErrorValidator" data-e2e="passage-date-error-msg" *ngIf="(ContactUsForm.controls['passageDate']?.invalid && ContactUsForm.controls['passageDate']?.touched)
                 || (submitted && ContactUsForm.controls['passageDate']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['passageDate']) }}
          </div>
        </div>
      </div>

      <div class="formfield col-sm-auto subCategory4"
        *ngIf="chosenSubCategoryId == contactTypeIdEnum.ContestationPassage">
        <label for="passageHour" data-e2e="passage-time-label">Hora da passagem</label>
        <div class="formInput">
          <input data-e2e="passage-time-input" [ngClass]="
                    (ContactUsForm.controls['passageHour']?.invalid &&
                    ContactUsForm.controls['passageHour']?.touched) ||
                    (submitted && ContactUsForm.controls['passageHour']?.invalid)
                    ? 'invalidField' : ''" class="subCategory4Input" id="passageHour" type="text"
            formControlName="passageHour" [mask]="'00:00'">

          <div class="alertErrorValidator" data-e2e="passage-time-error-msg" *ngIf="(ContactUsForm.controls['passageHour']?.invalid && ContactUsForm.controls['passageHour']?.touched)
                  || (submitted && ContactUsForm.controls['passageHour']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['passageHour'])}}
          </div>
        </div>
      </div>

      <!-- Credenciar estacionamento - Campos -->
      <div class="formfield col-7 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label for="parkingName" data-e2e="establishment-name-label">Nome do estabelecimento</label>

        <div class="formInput">
          <input data-e2e="establishment-name-input" [ngClass]="(ContactUsForm.controls['parkingName']?.invalid && ContactUsForm.controls['parkingName']?.touched)
                 || (submitted && ContactUsForm.controls['parkingName']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="parkingName" type="text" formControlName="parkingName" maxlength="100">

          <div data-e2e="establishment-name-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['parkingName']?.invalid && ContactUsForm.controls['parkingName']?.touched)
                  || (submitted && ContactUsForm.controls['parkingName']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['parkingName'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-5 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label data-e2e="zipcode-label" for="zipCode">CEP</label><span class="gray">(OPCIONAL)</span>

        <div class="formInput">
          <input data-e2e="zipcode-input" [ngClass]="(ContactUsForm.controls['zipCode']?.invalid && ContactUsForm.controls['zipCode']?.touched)
                 || (submitted && ContactUsForm.controls['zipCode']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="zipCode" type="text" formControlName="zipCode" [mask]="'00000-000'"
            (change)="getAddressInfos()">

          <div data-e2e="zipcode-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['zipCode']?.invalid && ContactUsForm.controls['zipCode']?.touched)
                  || (submitted && ContactUsForm.controls['zipCode']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['zipCode'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-12 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label data-e2e="address-label" for="address">Endereço</label>

        <div class="formInput">
          <input data-e2e="address-input" [ngModel]="postalCode?.streetAddress" [ngClass]="(ContactUsForm.controls['address']?.invalid && ContactUsForm.controls['address']?.touched)
                 || (submitted && ContactUsForm.controls['address']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="address" type="text" formControlName="address" maxlength="100">

          <div data-e2e="address-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['address']?.invalid && ContactUsForm.controls['address']?.touched)
                  || (submitted && ContactUsForm.controls['address']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['address'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-3 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label data-e2e="address-number-label" for="number">Número</label><span class="gray">(OPCIONAL)</span>

        <div class="formInput">
          <input data-e2e="address-number-input" [ngClass]="(ContactUsForm.controls['number']?.invalid && ContactUsForm.controls['number']?.touched)
                  || (submitted && ContactUsForm.controls['number']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="number" type="text" formControlName="number" maxlength="6">

          <div data-e2e="address-number-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['number']?.invalid && ContactUsForm.controls['number']?.touched)
                  || (submitted && ContactUsForm.controls['number']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['number'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-5 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label data-e2e="district-label" for="district">Bairro</label><span class="gray">(OPCIONAL)</span>
        <div class="formInput">
          <input data-e2e="district-input" [ngModel]="postalCode?.district" [ngClass]="(ContactUsForm.controls['district']?.invalid && ContactUsForm.controls['district']?.touched)
                  || (submitted && ContactUsForm.controls['district']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="district" type="text" formControlName="district" maxlength="100">

          <div data-e2e="district-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['district']?.invalid && ContactUsForm.controls['district']?.touched)
                  || (submitted && ContactUsForm.controls['district']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['district'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-4 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label data-e2e="complement-label" for="complement">Complemento</label><span class="gray">(OPCIONAL)</span>
        <div class="formInput">
          <input data-e2e="complement-input" [ngClass]="(ContactUsForm.controls['complement']?.invalid && ContactUsForm.controls['complement']?.touched)
                 || (submitted && ContactUsForm.controls['complement']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="complement" type="text" formControlName="complement" maxlength="50">

          <div data-e2e="compĺement-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['complement']?.invalid && ContactUsForm.controls['complement']?.touched)
                 || (submitted && ContactUsForm.controls['complement']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['complement'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-5 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label data-e2e="city-label" for="city">Cidade</label>

        <div class="formInput">
          <input data-e2e="city-input" [ngModel]="postalCode?.city" [ngClass]="(ContactUsForm.controls['city']?.invalid && ContactUsForm.controls['city']?.touched)
                  || (submitted && ContactUsForm.controls['city']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="city" type="text" formControlName="city" maxlength="100">

          <div data-e2e="city-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['city']?.invalid && ContactUsForm.controls['city']?.touched)
                  || (submitted && ContactUsForm.controls['city']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['city'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-3 subCategory5" *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
        <label data-e2e="state-label" for="state">Estado</label>

        <div class="formInput">
          <select data-e2e="state-select-input" [ngModel]="postalCode?.federatedUnit" [ngClass]="(ContactUsForm.controls['state']?.invalid && ContactUsForm.controls['state']?.touched)
               || (submitted && ContactUsForm.controls['state']?.invalid) ? 'invalidField' : '' "
            class="subCategory5Input" id="state" type="text" formControlName="state">
            <option [attr.data-e2e]="'state-option-' + i" *ngFor="let uf of UFs; let i = index" value="{{ uf }}">{{ uf
              }}</option>
          </select>

          <div data-e2e="state-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['state']?.invalid && ContactUsForm.controls['state']?.touched)
                  || (submitted && ContactUsForm.controls['state']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['state'])}}
          </div>
        </div>
      </div>

      <!-- Cancela não abre - Campos -->
      <div class="formfield col-12 subCategory7" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <div class="formInput row">
          <div class="parkingAndTollCol formRadio col-sm-auto">
            <input data-e2e="toll-checkbox-input" class="checkbox-input subCategory7Input" type="radio"
              formControlName="parkingAndToll" name="parkingAndToll" value="1"
              (click)="onRadioParkingAndTollChange($event)" />
            <span class="checkmark"></span>
            <div data-e2e="toll-checkbox-label" class="checkbox-label">Pedágio</div>
          </div>

          <div class="parkingAndTollCol formRadio col-sm-auto">
            <input data-e2e="parking-checkbox-input" class="checkbox-input subCategory7Input" type="radio"
              formControlName="parkingAndToll" name="parkingAndToll" value="2"
              (click)="onRadioParkingAndTollChange($event)" />
            <span class="checkmark"></span>
            <div data-e2e="parking-checkbox-label" class="checkbox-label">Estacionamento</div>
          </div>
        </div>
      </div>

      <div class="formfield col-3 subCategory7" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <label data-e2e="plate-label" for="subCategory7LicensePlate">Placa</label>

        <div class="formInput">
          <input data-e2e="plate-input" [ngClass]="(ContactUsForm.controls['subCategory7LicensePlate']?.invalid && ContactUsForm.controls['subCategory7LicensePlate']?.touched)
              || (submitted && ContactUsForm.controls['subCategory7LicensePlate']?.invalid) ? 'invalidField' : '' "
            class="subCategory7Input" id="subCategory7LicensePlate" type="text"
            formControlName="subCategory7LicensePlate" [mask]="'AAA-AAAA'">

          <div data-e2e="plate-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['subCategory7LicensePlate']?.invalid && ContactUsForm.controls['subCategory7LicensePlate']?.touched)
                || (submitted && ContactUsForm.controls['subCategory7LicensePlate']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['subCategory7LicensePlate'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-6 subCategory7" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <label data-e2e="establishment-question-label" for="parkingAndTollName"
          *ngIf="ContactUsForm.value.parkingAndToll == 1">Qual a praça de pedágio?</label>
        <label data-e2e="establishment-question-label" for="parkingAndTollName"
          *ngIf="ContactUsForm.value.parkingAndToll == 2">Qual estacionamento?</label>

        <div class="formInput">
          <input data-e2e="establishment-question-input" [ngClass]="(ContactUsForm.controls['parkingAndTollName']?.invalid && ContactUsForm.controls['parkingAndTollName']?.touched)
              || (submitted && ContactUsForm.controls['parkingAndTollName']?.invalid) ? 'invalidField' : '' "
            class="subCategory7Input" id="parkingAndTollName" type="text" formControlName="parkingAndTollName"
            maxlength="100">

          <div data-e2e="establishment-question-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['parkingAndTollName']?.invalid && ContactUsForm.controls['parkingAndTollName']?.touched)
                || (submitted && ContactUsForm.controls['parkingAndTollName']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['parkingAndTollName'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-5 subCategory7" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <label data-e2e="city-label" for="subCategory7city">Cidade</label>

        <div class="formInput">
          <input data-e2e="city-input" [ngClass]="(ContactUsForm.controls['subCategory7city']?.invalid && ContactUsForm.controls['subCategory7city']?.touched)
              || (submitted && ContactUsForm.controls['subCategory7city']?.invalid) ? 'invalidField' : '' "
            class="subCategory7Input" id="subCategory7city" type="text" formControlName="subCategory7city"
            maxlength="100">

          <div data-e2e="city-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['subCategory7city']?.invalid && ContactUsForm.controls['subCategory7city']?.touched)
                || (submitted && ContactUsForm.controls['subCategory7city']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['subCategory7city'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-3 subCategory7" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <label data-e2e="state-label" for="subCategory7state">Estado</label>

        <div class="formInput">
          <select data-e2e="state-select-input" [ngClass]="(ContactUsForm.controls['subCategory7state']?.invalid && ContactUsForm.controls['subCategory7state']?.touched)
            || (submitted && ContactUsForm.controls['subCategory7state']?.invalid) ? 'invalidField' : '' "
            class="subCategory7Input" id="subCategory7state" type="text" formControlName="subCategory7state">
            <option [attr.data-e2e]="'state-option-' + i" *ngFor="let uf of UFs; let i = index" value="{{ uf }}">{{ uf
              }}</option>
          </select>

          <div data-e2e="state-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['subCategory7state']?.invalid && ContactUsForm.controls['subCategory7state']?.touched)
              || (submitted && ContactUsForm.controls['subCategory7state']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['subCategory7state'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-5 subCategory7 dateField" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <label data-e2e="occurrence-date-label" for="occurrenceDate">Data da ocorrência</label>

        <div class="formInput">
          <img id="ico-calendar" src="assets/img/ico-calendar.svg" />
          <input data-e2e="occurrence-date-input" id="occurrenceDate" type="text"
            class="simulate-form-control subCategory7Input" #dp2="bsDatepicker" bsDatepicker
            formControlName="occurrenceDate" (bsValueChange)="onFilterDateGeneral(1)" [triggers]="isShowDatePicker[1]"
            [ngClass]="(ContactUsForm.controls['occurrenceDate']?.invalid && ContactUsForm.controls['occurrenceDate']?.touched)
            || (submitted && ContactUsForm.controls['occurrenceDate']?.invalid) ? 'invalidField' : '' "
            rInputMask="99/99/9999" [outsideClick]="false">
          <a data-e2e="occurrence-date-picker-btn" [ngClass]="isShowDatePicker[1] ? 'selected' : ''"
            (click)="toggleDatePicker(dp2,1)"><img src="assets/img/arrow-select.svg" /></a>

          <div data-e2e="occurrence-date-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['occurrenceDate']?.invalid && ContactUsForm.controls['occurrenceDate']?.touched)
              || (submitted && ContactUsForm.controls['occurrenceDate']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['occurrenceDate'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-6 subCategory7" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <label data-e2e="time-occurrance-label" for="occurrenceHour">Horário aproximado da ocorrência</label>

        <div class="formInput">
          <input data-e2e="time-occurrance-input" [ngClass]="(ContactUsForm.controls['occurrenceHour']?.invalid && ContactUsForm.controls['occurrenceHour']?.touched)
           || (submitted && ContactUsForm.controls['occurrenceHour']?.invalid) ? 'invalidField' : '' "
            class="subCategory7Input" id="occurrenceHour" type="text" formControlName="occurrenceHour" [mask]="'00:00'">

          <div data-e2e="time-occurrance-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['occurrenceHour']?.invalid && ContactUsForm.controls['occurrenceHour']?.touched)
             || (submitted && ContactUsForm.controls['occurrenceHour']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['occurrenceHour'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-12" *ngIf="chosenSubCategoryId == contactTypeIdEnum.TollNotOpen">
        <label data-e2e="payment-label" for="paymentInMoney">O pagamento ocorreu em dinheiro?</label>

        <div class="formInput row">
          <div class="paymentInMoneyCol formRadio col-sm-auto">
            <input data-e2e="payment-yes-radio" class="checkbox-input" type="radio" formControlName="paymentInMoney"
              name="paymentInMoney" value="1" (click)="onRadiPaymentInMoneyChange($event)" />
            <span class="checkmark"></span>
            <div data-e2e="payment-yes-label" class="checkbox-label">Sim</div>
          </div>

          <div class="paymentInMoneyCol formRadio col-sm-auto">
            <input data-e2e="payment-no-radio" class="checkbox-input" type="radio" formControlName="paymentInMoney"
              name="paymentInMoney" value="0" (click)="onRadiPaymentInMoneyChange($event)" />
            <span class="checkmark"></span>
            <div data-e2e="payment-no-label" class="checkbox-label">Não</div>
          </div>
        </div>
      </div>

      <!-- Nota Fiscal, Boleto ou Fatura - Campos -->
      <div class="formfield col-7 subCategory9" *ngIf="chosenSubCategoryId == contactTypeIdEnum.BillProblem">
        <label data-e2e="invoice-label" for="billId">Código da fatura</label>
        <div class="formInput">
          <input data-e2e="invoice-input" [ngClass]="(ContactUsForm.controls['billId']?.invalid && ContactUsForm.controls['billId']?.touched)
              || (submitted && ContactUsForm.controls['billId']?.invalid) ? 'invalidField' : '' "
            class="subCategory9Input" id="billId" formControlName="billId" maxlength="15">

          <div data-e2e="invoice-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['billId']?.invalid && ContactUsForm.controls['billId']?.touched)
                || (submitted && ContactUsForm.controls['billId']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['billId'])}}
          </div>
        </div>
      </div>

      <!-- Problema de Acesso - Campos -->
      <div class="formfield col-3 subCategory10" *ngIf="chosenSubCategoryId == contactTypeIdEnum.AccessProblem">
        <label data-e2e="doc-label" for="userDocument">CPF do usuário</label>

        <div class="formInput">
          <input data-e2e="doc-input" [ngClass]="(ContactUsForm.controls['userDocument']?.invalid && ContactUsForm.controls['userDocument']?.touched)
              || (submitted && ContactUsForm.controls['userDocument']?.invalid) ? 'invalidField' : '' "
            class="subCategory10Input" id="userDocument" type="text" formControlName="userDocument"
            [mask]="'000.000.000-00'">

          <div data-e2e="doc-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['userDocument']?.invalid && ContactUsForm.controls['userDocument']?.touched)
                || (submitted && ContactUsForm.controls['userDocument']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['userDocument'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-6 subCategory10" *ngIf="chosenSubCategoryId == contactTypeIdEnum.AccessProblem">
        <label data-e2e="email-label" for="userEmail">E-mail do usuário</label>

        <div class="formInput">
          <input data-e2e="email-input" id="userEmail" type="text" [ngClass]="(ContactUsForm.controls['userEmail']?.invalid && ContactUsForm.controls['userEmail']?.touched)
             || (submitted && ContactUsForm.controls['userEmail']?.invalid) ? 'invalidField' : '' "
            class="subCategory10Input" formControlName="userEmail" maxlength="100">

          <div data-e2e="email-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['userEmail']?.invalid && ContactUsForm.controls['userEmail']?.touched)
              || (submitted && ContactUsForm.controls['userEmail']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['userEmail'])}}
          </div>
        </div>
      </div>

      <!-- Padrão - Campos -->
      <div class="formfield col-12">
        <label data-e2e="description-label" for="description"
          *ngIf="chosenSubCategoryId !== contactTypeIdEnum.ContestationPassage && chosenSubCategoryId !== contactTypeIdEnum.Suggestion">
          Descrição
        </label>

        <label data-e2e="description-label" for="description"
          *ngIf="chosenSubCategoryId == contactTypeIdEnum.ContestationPassage">
          Motivo
        </label>

        <label data-e2e="description-label" for="description"
          *ngIf="chosenSubCategoryId == contactTypeIdEnum.Suggestion">
          Fique a vontade para mandar as suas sugestões
        </label>

        <div data-e2e="info-msg" class="infoTextSubCategory5"
          *ngIf="chosenSubCategoryId == contactTypeIdEnum.ParkingRegistration">
          Se possível, informar a quantidade de veículos e frequência de uso no estabelecimento informado (semanal,
          mensal ou diária).
        </div>

        <div class="formInput">
          <textarea data-e2e="description-textarea" [ngClass]="(ContactUsForm.controls['description']?.invalid && ContactUsForm.controls['description']?.touched)
                || (submitted && ContactUsForm.controls['description']?.invalid) ? 'invalidField' : '' "
            id="description" formControlName="description" maxlength="1000">
                </textarea>

          <div data-e2e="description-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['description']?.invalid && ContactUsForm.controls['description']?.touched)
                  || (submitted && ContactUsForm.controls['description']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['description'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-12" [ngClass]=" files.length > 0 ? 'hasFiles' : 'borderedFormField'">
        <label data-e2e="attachement-label" for="name">Anexar documentos</label><span class="gray">(OPCIONAL)</span>

        <div class="formInput row">
          <button data-e2e="attachement-dropdown" id="button-animated" aria-controls="dropdown-animated"
            class="btn btn-repom btn-import btn-repom-user col-sm-auto" type="button">
            <input data-e2e="attachement-input" type="file" id="file" (change)="fileBrowseHandler($event.target.files)"
              accept=".csv, .xlsx, .xls">
            <i class="ico ico-upload"></i>
            <div data-e2e="attachement-info">anexar arquivos</div>
            <span class="btn-animation"></span>
          </button>


          <div class="col infos">
            <div data-e2e="attachement-format-info" class="info1 info"><strong>.csv, .xls ou .xlsx.</strong></div>
            <div data-e2e="attachement-size-info" class="info2 info"><strong>Limite:</strong> 10mb por arquivo.</div>
          </div>
        </div>
      </div>

      <div class="fileList borderedFormField" *ngIf="files.length > 0">
        <div class="containerFile" *ngFor="let file of files; let i = index">
          <div class="file file{{i}}">
            <div class="icon" *ngIf="percent[i] == 80 && !error[i]"></div>

            <div class="loading" *ngIf="percent[i] < 80 && !error[i]">
              <img src="assets/img/loading_upload_file.svg" />
            </div>

            <div class="iconError" *ngIf="error[i]"></div>

            <div class="fileInfos"><span class="loadingText" *ngIf="percent[i] < 80">Carregando: </span><span
                class="filename">{{ file?.name }}</span></div>

            <div class="removeFile" (click)="deleteFile(i)" *ngIf="percent[i] == 80 && !error[i]">
              <img src="assets/img/ico-delete.svg" />
            </div>

            <div class="progressBarInicial"></div>

            <div class="progressBar" [style.background]="error[i] ? 'red' : '#00869d'" [style.width]="percent[i]+'%'">
            </div>
          </div>
        </div>
      </div>

      <div class="formfield col-12">
        <label data-e2e="return-type-label" for="returnType">Tipo de retorno</label>

        <div class="formInput row">
          <div class="returnType formRadio col-sm-auto" *ngIf="chosenSubCategoryId == contactTypeIdEnum.Suggestion">
            <input data-e2e="no-return-checkbox" class="checkbox-input" type="radio" formControlName="returnType"
              name="returnType" value="3" (click)="onRadioChange($event)" />
            <span class="checkmark"></span>
            <div data-e2e="checkbox-no-return-label" class="checkbox-label">Não retornar</div>
          </div>

          <div class="returnType formRadio col-sm-auto">
            <input data-e2e="phone-checkbox" class="checkbox-input" type="radio" formControlName="returnType"
              name="returnType" value="1" (click)="onRadioChange($event)" />
            <span class="checkmark"></span>
            <div data-e2e="phone-checkbox-label" class="checkbox-label">Telefone</div>
          </div>

          <div class="returnType formRadio col-sm-auto">
            <input data-e2e="email-checkbox" class="checkbox-input" type="radio" formControlName="returnType"
              name="returnType" value="2" (click)="onRadioChange($event)" />
            <span class="checkmark"></span>
            <div data-e2e="email-checkbox-label" class="checkbox-label">E-mail</div>
          </div>
        </div>
      </div>

      <div class="formfield col-12 borderedFormField" *ngIf="ContactUsForm.controls['returnType'].value == 1">
        <label data-e2e="phone-label" for="phone">Telefone</label>

        <div class="formInput">
          <input data-e2e="phone-input" id="phone" type="text" [ngModel]="currentTel" [ngClass]="(ContactUsForm.controls['phone']?.invalid && ContactUsForm.controls['phone']?.touched)
                || (submitted && ContactUsForm.controls['phone']?.invalid) ? 'invalidField' : '' "
            formControlName="phone" [mask]="getPhoneMask()">

          <div data-e2e="phone-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['phone']?.invalid && ContactUsForm.controls['phone']?.touched)
                  || (submitted && ContactUsForm.controls['phone']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['phone'])}}
          </div>
        </div>
      </div>

      <div class="formfield col-12 borderedFormField" *ngIf="ContactUsForm.controls['returnType'].value == 2">
        <label data-e2e="email-label" for="email">E-mail</label>

        <div class="formInput">
          <input data-e2e="email-input" id="email" type="text" [ngModel]="currentEmail" [ngClass]="(ContactUsForm.controls['email']?.invalid && ContactUsForm.controls['email']?.touched)
                || (submitted && ContactUsForm.controls['email']?.invalid) ? 'invalidField' : '' "
            formControlName="email">

          <div data-e2e="email-error-msg" class="alertErrorValidator" *ngIf="(ContactUsForm.controls['email']?.invalid && ContactUsForm.controls['email']?.touched)
                  || (submitted && ContactUsForm.controls['email']?.invalid)">
            {{ generateErrorMessage(ContactUsForm.controls['email'])}}
          </div>
        </div>
      </div>

      <div class="formFooter">
        <button data-e2e="confirm-btn" class="btn btn-secondary" [disabled]="ContactUsForm.invalid || isLoading">
          <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
          Confirmar</button>
      </div>
    </div>
  </form>
</div>

<!-- Atualizar Placa Mercosul -->
<div class="formComponent" *ngIf="chosenSubCategoryId == contactTypeIdEnum.UpdateLicensePlateMercosur">
  <div data-e2e="plate-renew-msg" class="subCategor2 messageBox">
    Prezado cliente, é possível atualizar a placa do veículo, acessando o menu:<br>
    <span class="underlined redirect" [routerLink]="['/meus-veiculos']" data-e2e="vehicles-link">Frota > Meus
      Veículos</span> e na tabela,
    acesse o botão da coluna <span class="underlined">"Veículo + ações"</span><br>
    e depois <span class="underlined redirect" [routerLink]="['/meus-veiculos']"
      data-e2e="vehicle-2-link">"Editar"</span>.

    <div class="img1 img">
      <img src="assets/img/down-arrow.png" />
      <div class="button">
        <img src="assets/svg/icon-popover.svg" />
      </div>
    </div>

    <div class="img2 img">
      <img src="assets/img/down-arrow.png" />
      <div class="button">
        <img src="assets/svg/edit.svg" />
      </div>
      <div class="imgLabel">Editar</div>

    </div>
    <br><br><br><br><br>
    Caso você tenha tentado alterar a placa de algum veículo e não tenha obtido sucesso,<br>
    acesse a opção "Relatar um problema" ou entre em contato com a nossa Central de<br>
    Atendimento via CHAT, disponível no Portal.
  </div>
</div>

<!--  Cadastrar novo usuário -->
<div class="formComponent" *ngIf="chosenSubCategoryId == contactTypeIdEnum.RegisterNewUser">
  <div data-e2e="new-user-msg" class="subCategory3 messageBox">
    Prezado cliente, é possível incluir um novo usuário, acessando o seu contrato<br>
    <span class="underlined redirect" [routerLink]="['/contrato']" data-e2e="account-link">Contrato > Incluir usuário
    </span><br><br><br>
    <img class="contactUsImg1" src="assets/img/contactus_arrow1.png" />
    <img class="add-user-icon" src="assets/img/add-user-icon-sample.png">
    Caso você tenha encontrado alguma dificuldade para incluir novos usuários,<br>
    acesse a opção "Relatar um problema" ou entre em contato com a nossa<br>
    central de atendimento pelo chat disponível no portal.
  </div>
</div>
