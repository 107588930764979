<button (click)="showFilterContent()" class="button-custom-filters"
  [class.active]="idFilter === filtersComponent.activeFilter">
  <span class="label">{{ labelTitle }}</span>

  <p class="button-text" [class.active]="selectedPeriod">{{ textValue }}</p>

  <span class="container-arrow">
    <img class="arrow" src="../../../../assets/img/ico-arrow-right-b.svg" />
  </span>
</button>

<div *ngIf="idFilter === filtersComponent.activeFilter" class="box-area-value">
  <div class="content-extend">
    <h4 class="text-center mb-3" data-e2e="period-filter-popover-title">
      Selecione o período:
    </h4>

    <mat-radio-group class="radio-group__label-black">
      <mat-radio-button *ngFor="let period of periodRange" [id]="period" (click)="onPeriodSelection(period)"
        [checked]="radioSelected === period" name="date" [value]="period">
        últimos {{ period }} dias
      </mat-radio-button>
    </mat-radio-group>

    <bs-daterangepicker-inline #dpGeneral="bsDaterangepicker" class="simulate-form-control" bsDaterangepicker
      placement="right" (bsValueChange)="onDateSelection($event)" [outsideClick]="false" [(bsValue)]="selectedPeriod">
    </bs-daterangepicker-inline>
  </div>
</div>