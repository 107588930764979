import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { NotifyService } from '@services/notify.service';
import { MyvehiclesService } from '../../../myvehicles/services/myvehicles.service';
import { GetVehicleQueryResultDto } from '../../../myvehicles/dtos/get-vehicle-query-result.dto';

@Component({
  selector: 'app-tag-block-modal',
  templateUrl: './tag-block-modal.component.html',
  styleUrls: ['./tag-block-modal.component.scss']
})

export class TagBlockModalComponent implements OnInit {

  @Output() event: EventEmitter<any> = new EventEmitter();
  @Input() vehicle: GetVehicleQueryResultDto;
  block: any;
  onSaving: boolean;
  step = 1;
  successBlockTag: any;

  constructor(
    private modalService: NgbModal,
    private myvehiclesService: MyvehiclesService,
    private notifyService: NotifyService
  ) { }

  ngOnInit(): void {

    this.block = {
      contractVehicleId: this.vehicle.contractVehicleId,
      tagId: this.vehicle.tagId
    }

  }

  blockTag() {
    this.onSaving = true;
    this.myvehiclesService
      .blockTag(this.block)
      .pipe(finalize(() => {
        this.onSaving = false;
      }))
      .subscribe(
        (success: any) => {
          this.event.emit(success);
          this.notifyService.showSuccess('Atenção!', 'Tag bloqueada com sucesso!');
          this.successBlockTag = success.blockTagRegister;
          this.step = 2;
        },
        (e) => {
          console.error(e)
        }
      );
  }
  close() {
    this.modalService.dismissAll();
  }

}
