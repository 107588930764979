import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MyvehiclesService } from '../../../myvehicles/services/myvehicles.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-cancel-tag',
  templateUrl: './cancel-tag.component.html',
  styleUrls: ['./cancel-tag.component.scss']
})
export class CancelTagComponent implements OnInit {
  @Input() vehicle: any;
  @Output() event: EventEmitter<any> = new EventEmitter();
  success: any;
  errorMsg: string;
  isLoading = false;
  step:number = 1;
  balance: number;
  cancelTagForm = new FormGroup({
    tagId: new FormControl(),
    contractVehicleId: new FormControl(),
    motivoCancelamento: new FormControl(),
    excluirVeiculoFrota: new FormControl<any>('',[Validators.required]),
    motivoDescricao: new FormControl(),
    shipper: new FormControl()
  });

  constructor(
    private modalService: NgbModal,
    private vehiclesService: MyvehiclesService,
    private notifyService: NotifyService,
    private frmBuilder: FormBuilder
  ) {
    this.getRequiredFields();
  }

  getRequiredFields() {
    this.cancelTagForm
      .get('excluirVeiculoFrota')
      .valueChanges.subscribe((value) => {
        if (value == 1) {
          this.cancelTagForm
            .get('motivoCancelamento')
            .setValidators(Validators.required);

          this.cancelTagForm
            .get('motivoCancelamento')
            .valueChanges.subscribe((value) => {
              if (value == 5) {
                this.cancelTagForm
                  .get('motivoDescricao')
                  .setValidators(Validators.required);
                this.cancelTagForm
                  .get('shipper')
                  .clearValidators();
              }
              else if(value == 7){
                this.cancelTagForm
                .get('shipper')
                .setValidators(Validators.required);
                this.cancelTagForm
                .get('motivoDescricao')
                .clearValidators();
              }
              else {
                if (value) {
                  this.cancelTagForm.get('motivoDescricao').clearValidators();
                  this.cancelTagForm.get('shipper').clearValidators();
                }
              }
              this.cancelTagForm
                .get('motivoDescricao')
                .updateValueAndValidity();

                this.cancelTagForm
                .get('shipper')
                .updateValueAndValidity();
            });
        }
      });
  }

  ngOnInit() {
    this.loadInfos();
  }

  loadInfos() {
    this.isLoading = true;
    this.vehiclesService
      .GetVehicleTagDetails(this.vehicle.contractVehicleId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.balance = success.accountBalance;
        },
        (e) => {
         console.error(e);
        }
      );
  }

  close() {
    this.modalService.dismissAll();
  }

  next() {
    if (+this.cancelTagForm.value.excluirVeiculoFrota == 2) {
      this.confirmCancellation();
    } else {
      this.step = 2;
    }
  }

  confirmCancellation() {

    this.cancelTagForm.value.excluirVeiculoFrota =
      this.cancelTagForm.value.excluirVeiculoFrota == 1 ? true : false;
    this.cancelTagForm.value.tagId = this.vehicle?.tagId;
    this.cancelTagForm.value.contractVehicleId = this.vehicle?.contractVehicleId;
    this.cancelTagForm.value.motivoCancelamento = +this.cancelTagForm.value
      .motivoCancelamento;

    this.errorMsg = '';
    if (!this.cancelTagForm.valid) {
      this.notifyService.showInfo(
        'Atenção',
        'Preencha todos os campos, por favor!'
      );
    } else {
      this.isLoading = true;
      this.vehiclesService
        .cancelTag(this.cancelTagForm.value, false)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (success: any) => {
            this.event.emit();
            this.success = success.contractVehicleTagRegister;
            this.step = 3;
          },
          (error) => {
            console.error(error);
            this.errorMsg = error.error[0].message;
          }
        );
    }
  }

}

