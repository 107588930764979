import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ChartOptions } from "@models/charts.model";

import Chart, { ChartConfiguration, ChartDataSets, ChartType } from "chart.js";

@Directive({
  selector: "canvas[baseChart]",
  exportAs: "base-chart",
})
export class ChartsDirective implements OnChanges {
  @Input() type: ChartConfiguration["type"] = "bar" as ChartType;
  @Input() legend?: boolean;
  @Input() data?: ChartConfiguration["data"];
  @Input() options: ChartConfiguration["options"] = new ChartOptions();

  @Input() labels?: string[];
  @Input() datasets?: ChartDataSets;

  @Output() chartClick: EventEmitter<{ event?: any; active?: {}[] }> =
    new EventEmitter();
  @Output() chartHover: EventEmitter<{ event: any; active: {}[] }> =
    new EventEmitter();

  ctx: string;
  chart?: Chart;

  constructor(private _element: ElementRef, private _zone: NgZone) {
    this.ctx = _element.nativeElement.getContext("2d");
  }

  ngOnChanges(changes: SimpleChanges): void {
    const requireRender = ["type"];
    const propertyNames = Object.getOwnPropertyNames(changes);

    if (
      propertyNames.some((key) => requireRender.includes(key)) ||
      propertyNames.every((key) => changes[key].isFirstChange())
    ) {
      this.render();
    } else {
      const config = this._getChartConfiguration();

      // Using assign to avoid changing the original object reference
      if (this.chart) {
        Object.assign(this.chart.config.data, config.data);
        if (this.chart.config.options) {
          Object.assign(this.chart.config.options, config.options);
        }
      }

      this.update();
    }
  }

  render(): Chart {
    if (this.chart) {
      this.chart.destroy();
    }

    return this._zone.runOutsideAngular(
      () => (this.chart = new Chart(this.ctx, this._getChartConfiguration()))
    );
  }

  update(): void {
    if (this.chart) {
      this._zone.runOutsideAngular(() => this.chart?.update());
    }
  }

  private _getChartOptions(): any {
    return this.options;
  }

  private _getChartConfiguration(): ChartConfiguration {
    return {
      type: this.type,
      data: this._getChartData(),
      options: this._getChartOptions(),
    };
  }

  private _getChartData(): Chart.ChartData {
    return this.data ? this.data :
      {
        labels: this.labels || [],
        datasets: Array.isArray(this.datasets) ? this.datasets : []
      };
  }
}
