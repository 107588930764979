<section class="modal-content edit-hierarchy">
  <div class="modal-header">
    <div class="box-title">
      <span>Relatório de Antecipação de Vale-Pedágio</span>
    </div>

    <button class="close" type="button" (click)="onClose()" id="btn-dismiss">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body show">
    <div class="container-loading" *ngIf="isLoading">
      <app-loader
        [isLoading]="isLoading"
        message="{{ 'Carregando...' }}"
      ></app-loader>
    </div>

    <section class="container col-md-12 mt-5" *ngIf="!isLoading">
      <div class="action-bar">
        <div class="form-group select-table">

          <app-outlined-select
            [beforeTextValue]="'exibir '"
            [afterTextValue]="' linhas'"
            [values]="perPages"
            [disableRipple]="true"
            [id]="'select-page-size'"
            [title]="'Exibir '"
            [customWidthSize]="'180px'"
            (valueChange)="onPageSizeChange($event)"
            name="pageSize"
          >
          </app-outlined-select>
        </div>

        <div class="search-bar">

            <app-search-form-inline
              [placeholder]="'Placa'"
              (keyUpEvent)="onFullNameKeyup($event)"
            ></app-search-form-inline>

        </div>

        <app-borderless-button
        [classIconRight]="'ico-export'"
        [data-e2e]="'btn-export'"
        [id-html]="'btn-export'"
        (emitterEvtClick)="onDownload()"
        [description]=" 'Exportar' "
        [isBtnWithBorder]="true"
        >

        </app-borderless-button>
      </div>

      <div class="px-4">
        <table class="table transactions">
          <thead class="thead">
            <tr>
              <th [ngClass]="bindOrderByClass('LicensePlate')">
                <button
                  [ngClass]="bindOrderByClass('LicensePlate')"
                  (click)="onOrderByChange('LicensePlate')"
                  class="btn-order-table"
                  translate
                >
                  Placa
                </button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>

              <th [ngClass]="bindOrderByClass('NickName')">
                <button
                  [ngClass]="bindOrderByClass('NickName')"
                  (click)="onOrderByChange('NickName')"
                  class="btn-order-table"
                  translate
                >
                  Apelido
                </button>
                <div
                  [ngClass]="bindOrderByClass('NickName')"
                  (click)="onOrderByChange('NickName')"
                  class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>

              <th [ngClass]="bindOrderByClass('ProcessingDate')">
                <button
                  [ngClass]="bindOrderByClass('ProcessingDate')"
                  (click)="onOrderByChange('ProcessingDate')"
                  class="btn-order-table"
                  translate
                >
                  Data de Processamento
                </button>
                <div
                  [ngClass]="bindOrderByClass('ProcessingDate')"
                  (click)="onOrderByChange('ProcessingDate')"
                  class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>

              <th [ngClass]="bindOrderByClass('TotalAmount')">
                <button
                  [ngClass]="bindOrderByClass('TotalAmount')"
                  (click)="onOrderByChange('TotalAmount')"
                  class="btn-order-table"
                  translate
                >
                  Valor Total
                </button>

                <div
                  [ngClass]="bindOrderByClass('TotalAmount')"
                  (click)="onOrderByChange('TotalAmount')"
                  class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
            </tr>
          </thead>

          <div class="container-loading" *ngIf="isLoadingPage">
            <app-loader [isLoading]="isLoadingPage"></app-loader>
          </div>

          <tbody>
            <tr *ngFor="let transaction of transactions; let i = index">
              <td>
                <span id="licensePlate{{ i }}">{{
                  transaction?.licensePlate | mask: "AAA-AAAA"
                }}</span>
              </td>
              <td id="nickName{{ i }}">{{ transaction?.nickName }}</td>
              <td id="processingDate{{ i }}">
                {{
                  transaction?.processingDate | date: "dd/MM/yyyy - HH:MM:SS"
                }}
              </td>
              <td id="totalAmount{{ i }}" class="advance-total">
                {{ transaction?.totalAmount > 0 ? "+" : "-" }}
                {{
                  transaction?.totalAmount
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="transactions.length == 0">
          <div colspan="12" class="text-center w-100 py-4" translate>
            Nenhum resultado encontrado.
          </div>
        </div>

        <div
          class="pagination-custom"
          *ngIf="total > transactionQuery.pageSize && !isLoading"
        >
          <app-pagination
            [totalItems]="total"
            [itemsPerPage]="transactionQuery.pageSize"
            [maxPages]="5"
            [currentPage]="transactionQuery.page"
            (pageChangeEvent)="onPaginationChange($event)"
          ></app-pagination>
        </div>
      </div>
    </section>

    <footer *ngIf="!isLoadingPage">

      <app-borderless-button
        [description]="'Fechar'"
        [id-html]="'modal-btn-close'"
        [data-e2e]="'modal-btn-close'"
        [buttonColor]="'secundary'"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="onClose()">
      </app-borderless-button>

    </footer>
  </div>
</section>
