import { Component, Input } from '@angular/core';
import { ReceivedEntryDto } from '../../../myvehicles/dtos/receivedEntry.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-tag-unlock-modal',
  templateUrl: './confirmation-tag-unlock-modal.component.html',
  styleUrls: ['./confirmation-tag-unlock-modal.component.scss']
})
export class ConfirmationTagUnlockModalComponent {
  @Input() receivedEntry: ReceivedEntryDto;

  constructor(public modalService: NgbModal) { }

}
