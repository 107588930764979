<div class="modal-header">
  <h4 data-e2e="modal-title" class="modal-title" translate>
    {{ titles.title }}
  </h4>
  <a data-e2e="close-modal-top-btn" class="btn-close" (click)="dismissModal()">
    <img src="assets/svg/close.svg" />
  </a>
</div>

<div class="modal-body">
  <div class="modal-content col-lg-12">
    <div class="row ordr-fll custom">
      <div class="col-3">
        <img class="w-100" src="assets/svg/ticket-confirm.svg" />
      </div>

      <div class="col-9">
        <h3 data-e2e="modal-subtitle">{{ titles.subtitle }}</h3>
        <b data-e2e="client-selected-count" class="text-uppercase">
          {{ order?.tagCount }} tags incluídas pelo cliente</b
        >
        <br />
        <b data-e2e="order-selected-count" class="text-uppercase">
          {{ order?.tagRequestSeparationCount }} tags incluídas no pedido</b
        >
        <p>
          <strong data-e2e="request-signature"
            >{{ order?.changeDate | date: 'dd/MM/yyyy - HH:mm' }} por
            {{ order?.changeResponsibilityName }}</strong
          >
        </p>

        <div class="w-100 d-flex justify-content-start mb-3">
          <input type="text" class="d-none" />
          <button
            class="btn btn-primary mr-4"
            data-e2e="print-declaration-btn"
            (click)="goToPrint(order.urlDeclarationFile)"
            [disabled]="isLoading || generatingFiles"
          >
            <div *ngIf="!isLoading">
              <img class="button-icon" src="assets/svg/print.svg" />
              Imprimir declaração
            </div>
            <div *ngIf="isLoading" translate>
              <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              Gerando declaração
            </div>
          </button>

          <button
            class="btn btn-primary btn-imp-prot"
            data-e2e="print-protocol-btn"
            (click)="goToPrint(order.urlProtocolFile)"
            [disabled]="isLoading || generatingFiles"
          >
            <div *ngIf="!isLoading">
              <img class="button-icon" src="assets/svg/print.svg" />
              Imprimir protocolo
            </div>
            <div *ngIf="isLoading" translate>
              <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              Gerando protocolo
            </div>
          </button>
        </div>

        <div class="w-50 d-flex justify-content-center">
          <app-vmessage
            data-e2e="error-msg"
            *ngIf="errorMsg"
            errorMessage="{{ 'Ocorreu um erro ao gerar o pdf' }}"
          >
          </app-vmessage>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="content-buttons w-100">
    <button
      data-e2e="close-modal-btn"
      class="btn btn-secondary"
      (click)="dismissModal()"
      translate
    >
      Close
    </button>
  </div>
</div>
