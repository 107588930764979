<div class="panel" [@childAnimation] clickOutside (isClickOutside)="handleClickOutside()">
  <div class="fechar">
    <img (click)="dismiss.emit()" src="../assets/svg/Group.svg" alt=""/>
  </div>
  <br/>
  <br/>
  <div class="d-flex flex-column body-info">
    <div class="info-contract contractInfos">
      <p>Contrato</p>
      <p id="contractNameResponsive">{{ companyName.contractId }} - {{ companyName?.name }}</p>
      <p id="contractDocumentNumber">{{ companyName?.documentNumber | mask: '00.000.000/0000-00' }}</p>
    </div>
    <div class="tollBalanceComponent">
      <app-toll-balance></app-toll-balance>
    </div>
    <div class="sidebar">
      <ul class="nav-links" id="nav-links">
        <li *ngFor="let item of menuSidebar" #itemEl routerLinkActive="active">
          <!--  menu sem submenu -->
          <div *ngIf="item.sub_menu.length == 0" class="dropdown-title">
            <div *ngIf="item?.accessControl; else menuWithoutAccessControl">
              <a class="menu-item" *accessControl="item?.accessControl" [routerLink]="[item.link]" [routerLinkActive]="[item?.linkActive]">
                <img *ngIf="item?.icon" src="{{item.icon}}" class="img-title"/>
                <span class="link_name">{{ item.link_name.toLocaleUpperCase() }}</span>
              </a>
            </div>
            <ng-template #menuWithoutAccessControl>
              <a class="menu-item"
                *ngIf="item.link_name.toLocaleUpperCase() !== 'SAIR' && item.link_name.toLocaleUpperCase() !== 'TROCAR CONTRATO'"
                [routerLink]="[item.link]" [routerLinkActive]="[item?.linkActive]">
                <img *ngIf="item?.icon" src="{{item.icon}}" class="img-title"/>
                <span class="link_name">{{ item.link_name.toLocaleUpperCase() }}</span>
              </a>
              <a class="menu-item"
                *ngIf="item.link_name.toLocaleUpperCase() === 'TROCAR CONTRATO' && this.haveManyContracts"
                 [routerLink]="[item.link]" [routerLinkActive]="[item?.linkActive]">
                <img *ngIf="item?.icon" src="{{item.icon}}" class="img-title"/>
                <span class="link_name">{{ item.link_name.toLocaleUpperCase() }}</span>
              </a>
              <a class="menu-item"
                *ngIf="item.link_name.toLocaleUpperCase() === 'SAIR'" (click)="logout()">
                <img *ngIf="item?.icon" src="{{item.icon}}" class="img-title"/>
                <span class="link_name">{{ item.link_name.toLocaleUpperCase() }}</span>
              </a>
            </ng-template>
          </div>
          <!--  menu com submenu -->
          <div *ngIf="item.sub_menu.length > 0" class="dropdown-title" (click)="showSubmenu(itemEl, item)">
            <a class="menu-item">
              <img src="{{item.icon}}" class="col-1 img-title"/>
              <span class="link_name">{{ item.link_name.toLocaleUpperCase() }}</span>
              <a id="{{generateDynamicIDElement(item)}}" class="img-menu-close"></a>
            </a>
          </div>
          <!--  submenu -->
          <ul class="sub-menu" [class.blank]="item.sub_menu.length == 0">
            <li><a class="menu-item link_name">{{ item.link_name.toLocaleUpperCase() }}</a></li>
            <li *ngFor="let item_sub of item.sub_menu" routerLinkActive="active">
              <div *ngIf="item_sub.modalityType != null">
                <div *modalityType="item_sub?.modalityType">
                  <a class="submenu-item" *accessControl="item_sub?.accessControl"
                     [routerLink]="[item_sub.link]">{{ item_sub.link_name }}</a>
                </div>
              </div>
              <div *ngIf="item_sub.modalityType == null">
                <a class="submenu-item" *accessControl="item_sub?.accessControl" [routerLink]="[item_sub.link]">{{ item_sub.link_name }}</a>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
