import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {ArrayDataSource} from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddVehicleHierarchyComponent } from '../add-vehicle-hierarchy/add-vehicle-hierarchy.component';
import { RemoveHierarchyModalComponent } from '../remove-hierarchy-modal/remove-hierarchy-modal.component';
import { ViewVehicleComponent } from '../view-vehicle/view-vehicle.component';
import { ManageVehiclesHierarchyComponent } from '../manage-vehicles-hierarchy/manage-vehicles-hierarchy.component';
import { NotifyService } from '@services/notify.service';
import { HierarchyService } from '../../../hierarchy/services/hierarchy.service';
import { finalize } from 'rxjs';
import { UserProfileService } from '@services/user-profile.service';

@Component({
  selector: 'app-cdk-tree',
  templateUrl: './cdk-tree.component.html',
  styleUrls: ['./cdk-tree.component.scss']
})

export class CdkTreeComponent implements OnInit {

  public dataSource;
  public treeControl
  bsModalRef: BsModalRef;
  disableSaveButton: boolean;
  public ArrayHierarchyNames: any[]
  @ViewChildren('name') name: QueryList<any>;
  onSaving: boolean;

  constructor(
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private hierarchyService: HierarchyService,
    private userProfileService: UserProfileService
    ){}

  @Input() deleteModal: any = null;
  @Input() viewMode: boolean = false;
  @Input() set tree(tree: any) {
      this.treeControl = new NestedTreeControl<any>(node => node.children);
      this.dataSource = new ArrayDataSource(tree);
      this.dataSource.data = tree;
      this.treeControl.dataNodes = tree;
      this.expandAllNodes(false)
  }


  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.ArrayHierarchyNames = [];
  }

  haveRole(permission){
    return this.userProfileService.haveRole(permission)
   }

  onOpenAdcVehicle(hierarchyId, description_PT) {
    const initialState = {
      parameter: 2020,
      hierarchyId: hierarchyId,
      modalTitle: description_PT,
      modal: "1",
    };
    this.bsModalRef = this.modalService.show(AddVehicleHierarchyComponent, {
      initialState,
    });
    this.bsModalRef.content.closeBtnName = "Close";
  }

  showVehicleList(model) {
    if (model.vehicleCount) {
      const initialState = { parameter: 2020, hierarchicaId: model.id };
      this.bsModalRef = this.modalService.show(ViewVehicleComponent, {
        initialState,
      });
      this.bsModalRef.setClass("modal-hierarchy-vehiclelist");
      // this.bsModalRef.content.hierarchicaId = model.id;
    }
  }

  showManageVehiclesHierarchyModal(node) {
     const initialState = { parameter: 2020, hierarchyId: node.id};
     this.bsModalRef = this.modalService.show(ManageVehiclesHierarchyComponent, { initialState });
     this.bsModalRef.content.closeBtnName = 'Close';
     this.bsModalRef.content.hierarchyLevel = node.hierarchyLevel;
  }

  expandAllNodes(isExpanded){
    if(isExpanded){
      this.treeControl.collapseAll();
    }
    else{
      this.treeControl.expandAll()
    }
  }

  expandNode(isExpanded, node){
    if(isExpanded){
      this.treeControl.collapse(node);
    }
    else{
      this.treeControl.expand(node);
    }
  }

  showRemoveHierarchyModal(hierarchyLevel) {
    const config: ModalOptions = {
      backdrop: "static",
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      initialState: {
        parameter: 2020,
        hierarchyLevel: hierarchyLevel,
      },
    };
    this.bsModalRef = this.modalService.show(RemoveHierarchyModalComponent,config);
    this.bsModalRef.content.closeBtnName = "Close";
  }

  onItemChange(node, value){
    let id = node.id;
    let field = {
      'id': id,
      'description_PT': value,
      'description_EN': value
    }

    if(this.ArrayHierarchyNames.length > 0){
      if(this.ArrayHierarchyNames.filter(item => item.id == id).length > 0){
        let index = this.ArrayHierarchyNames.map(x=>x.id).indexOf(id); //apanhar o indice com map e indexOf
        if (index !== -1){ //se existe remove
          this.ArrayHierarchyNames.splice(index,1);
        }
        this.ArrayHierarchyNames.push(field)
      }
      else{
        this.ArrayHierarchyNames.push(field)
      }
    }
    else{
      this.ArrayHierarchyNames.push(field)
    }
  }

  onKeyup(node, value){
    let FirstValueInput = node.description_PT;
    let CurrentValueInput = value;
    this.onItemChange(node, value);
    if(FirstValueInput == CurrentValueInput){
      this.disableSaveButton = true;
    }
    else{
      this.disableSaveButton = false;
    }
  }

  onSaveHierarchy(hierarchicalLevel){
    this.onSaving = true;
    let ArrayInputs = this.name.toArray()
    let Input = ArrayInputs.filter(item => item.nativeElement.value !== '')
    let array

 if(typeof Input[0] == 'undefined'){
   this.notifyService.showInfo('Atenção!', 'Digite o nome da nova hieraraquia!');
 }
 else{
  array = {
    "description_PT": Input[0].nativeElement.value,
    "description_EN": Input[0].nativeElement.value,
    "higherHierarchicalLevel": hierarchicalLevel
  }
  this.hierarchyService
    .addHiearchy(array)
    .pipe(
      finalize(() => {
        this.onSaving = false
      })
    )
    .subscribe(
      (success: any) => {
        this.notifyService.showSuccess('Atenção!', 'Hierarquia adicionada com sucesso!');
      },
      (error) => {
        console.error("getFromServerError", error);
        return;
      }
    );
    Input[0].nativeElement.value = ''
  }
 }


 SaveHierarchy(){
  this.onSaving = true;
   if(this.ArrayHierarchyNames.length == 0){
     this.notifyService.showInfo('Atenção!', 'Nenhuma alteração de hierarquia realizada!');
   }
   else{
   this.hierarchyService
     .PutSaveEditHierarchy(this.ArrayHierarchyNames)
     .pipe(
       finalize(() => {
        this.onSaving = false;
       })
     )
     .subscribe(
       (success: any) => {
         this.notifyService.showSuccess('Atenção!', 'Hierarquia Salva com sucesso!');
       },
       (error) => {
         console.error("getFromServerError", error);
         return;
       }
     );
   }
 }

 ShowBreadCrumb(id){
  let breadCrumbDestiny = this.deleteModal.ChangeHierarchy.filter(item => item.hierarchyIdSource == id).map(item => item.breadCrumb).toString()
  let description_PTDestiny = this.deleteModal.ChangeHierarchy.filter(item => item.hierarchyIdSource == id).map(item => item.description_PT).toString()
 if(breadCrumbDestiny !== ''){
   return  breadCrumbDestiny.replace(description_PTDestiny,'')
 }
 else{
   return 'Veículo(s) sem hierarquia associada'
 }

 }

 ShowDescription(id){
   let description_PTDestiny = this.deleteModal.ChangeHierarchy.filter(item => item.hierarchyIdSource == id).map(item => item.description_PT).toString()
   return description_PTDestiny
 }

}


