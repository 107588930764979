import { Injectable } from '@angular/core';
import { Routes, Route, Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import {ShellPagesComponent} from "@modules/backoffice/page-backoffice/shell-pages.component";

// import { AuthenticationGuard } from '@core/auth';
// import { ShellPagesComponent } from '@modules/shell-pages/shell-pages.component';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private _pathActive: string;
  private _urlChangeSource = new Subject<string>();

  urlChange$ = this._urlChangeSource.asObservable();

  constructor(
    private _router: Router
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._urlChangeSource.next(event.urlAfterRedirects)
      }
    });
  }

  static childRoutes(routes: Routes, reuse = true): Route {
    return {
      path: 'backoffice',
      component: ShellPagesComponent,
      children: routes,
      // canActivate: [AuthenticationGuard],
      data: { reuse: reuse },
    };
  }

  setMenuPathActive(pathActive: string) {
    this._pathActive = pathActive;
  }

  getMenuPathActive(): string {
    return this._pathActive;
  }
}
