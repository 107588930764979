import { HttpParams } from '@angular/common/http';

export class TagsQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search: string;
  filter: number;
  FromDate: Date | string;
  ToDate: Date | string;

  get httpParans() {
    let httpParams = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortField', this.orderBy)
      .append('SortOrder', this.desc ? '1' : '0')
      .append('search', this.search);


      if (this.FromDate != null) {
        if (typeof this.FromDate === 'string') {
          // Convert the string to a Date object
          this.FromDate = new Date(this.FromDate);

        }
        /*
          The fromDate arrives like: Fri Jun 29 2023 00:00:00
          toISOString() - add 3 hours: 2023-06-29T03:00:00.000Z
          .split('T')[0] - split the Date, storing only the Date: 2023-06-29
        */
        const fromDateISOString = this.FromDate
          .toISOString()
          .split('T')[0];

        httpParams = httpParams.append('FromDate', fromDateISOString);
      }

      if (this.ToDate != null) {
        if (typeof this.ToDate === 'string') {
          // Convert the string to a Date object
          this.ToDate = new Date(this.ToDate);

        }
        /*
          The toDate arrives like: Fri Jun 29 2023 23:59:59
          toISOString() - add 3 hours: 2023-06-30T02:59:59.000Z
          .split('T')[0] - split the Date, storing only the Date: 2023-06-30
        */

        // Diminuir um dia da data para não ultrapassar 30 dias
        this.ToDate.setDate(this.ToDate.getDate() - 1);


        const toDateISOString = this.ToDate
          .toISOString()
          .split('T')[0];

        httpParams = httpParams.append('ToDate', toDateISOString);
      }

    return httpParams;
  }
}
