<div class="confirmationComponent">
  <div class="modal-header">
    <div class="box-title">
      <span>Cancelamento de tag</span>
    </div>

    <button
      data-e2e="modal-top-close"
      (click)="dismissModal()"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div *ngIf="!isLoading">
    <!-- Casos sem VPR-->
    <div
      class="modal-body"
      *ngIf="balance <= 0 && cancelTagForm.value.cancelTag == 1"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="maintain"
        >
          mantido na frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o veículo permanecerá cadastrado
        na frota do contrato.</span
      >
    </div>

    <div
      class="modal-body"
      *ngIf="balance <= 0 && cancelTagForm.value.cancelTag == 2"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="exclude"
        >
          excluído da frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o veículo será excluído da frota
        do contrato.</span
      >
    </div>

    <!-- Casos com transferencia -->
    <div
      class="modal-body"
      *ngIf="balance > 0 && cancelTagForm.value.cancelTag == 1"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="maintain"
        >
          mantido na frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o saldo residual de
        <b>{{ balance | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR' }}</b>
        será transferido ao veículo
        <b>{{ vehicleToReceiveVPR.licensePlate | mask: 'SSS-0A00' }}</b
        >.</span
      >
    </div>

    <div
      class="modal-body"
      *ngIf="balance > 0 && cancelTagForm.value.cancelTag == 2"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="exclude"
        >
          excluído da frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o saldo residual de
        <b>{{ balance | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR' }}</b>
        será transferido ao veículo
        <b>{{ vehicleToReceiveVPR.licensePlate | mask: 'SSS-0A00' }}</b
        >.</span
      >
    </div>
  </div>

  <div class="modal-loader" *ngIf="isLoading">
    <app-loader
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    >
    </app-loader>
  </div>

  <div class="modal-footer mt-4">

    <app-borderless-button
      [description]="'Voltar'"
      [id-html]="'btn-previous'"
      [data-e2e]="'btn-previous'"
      [isBtnWithBorder]="true"
      [buttonColor]="'secundary'"
      [isDisable]="isLoading"
      (emitterEvtClick)="previous()">
    </app-borderless-button>

    <app-borderless-button
      [description]="'Confirmar'"
      [id-html]="'btn-confirm'"
      [data-e2e]="'btn-confirm'"
      [isBtnWithBorder]="true"
      [buttonColor]="'primary'"
      [isDisable]="isLoading"
      (emitterEvtClick)="confirm()"
    >
    </app-borderless-button>

  </div>
</div>
