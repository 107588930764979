import { Color, PointStyle, Align, TextDirection, Font } from "./types.model";
import Chart, {
  ChartColor, ChartPoint,
  ChartScales,
} from "chart.js";

export type Clip = number | object | false;
export type JoinStyle = "round" | "bevel" | "miter";
export type Fill = boolean | string;
export type Stepped = boolean | string;
export type SpanGaps = boolean | number;

export class ChartData {
  hasData?: boolean;
  labels?: any[] = [];
  datasets?: ChartDatasets[] = [];

  constructor(labels?, datasets?, hasData?) {
    if (labels) this.labels = labels;
    if (datasets) this.datasets = datasets;
    if (hasData) this.hasData = hasData;
  }
}

export class ChartOptions implements Chart.ChartOptions {
  scales?: ChartScales;
  legend?: ChartLegends = new ChartLegends();
  responsive?: boolean = true;
}

export class LineChartOptions implements ChartOptions {
  scales?: LineChartScales = new LineChartScales();
  legend?: ChartLegends = new ChartLegends();
  responsive?: boolean = true;
}

export class LineChartScales implements ChartScales {
  yAxes? = [
    {
      ticks: {
        callback: function (value) {
          if (parseInt(value) >= 1000) {
            return (
              "R$ " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
          } else {
            return "R$ " + value;
          }
        },
      },
    },
  ];
}

export class ChartDatasets {
  backgroundColor?: string[];
  borderWidth?: number[];
  borderColor?: ChartColor;
  borderCapStyle?: string;
  borderDash?: number[];
  borderDashOffset?: number;
  borderJoinStyle?: string;

  data?: number[] | ChartPoint[];
  fill?: boolean;
  label?: string;
  lineTension?: number;

  pointBorderColor?: ChartColor | ChartColor[];
  pointBackgroundColor?: ChartColor | ChartColor[];
  pointBorderWidth?: number | number[];
  pointRadius?: number | number[];
  pointHoverRadius?: number | number[];
  pointHitRadius?: number | number[];

  pointHoverBackgroundColor?: ChartColor | ChartColor[];
  pointHoverBorderColor?: ChartColor | ChartColor[];
  pointHoverBorderWidth?: number | number[];
  pointStyle?: string | string[] | HTMLImageElement | HTMLImageElement[];

  xAxisID?: string;
  yAxisID?: string;
  type?: "number" | "currency";
}

export class LineChartDataset {
  borderCapStyle?: string;
  clip?: Clip;
  drawActiveElementsOnTop?: boolean;
  hoverBackgroundColor?: Color;
  hoverBorderCapStyle?: string;
  hoverBorderColor?: Color;
  hoverBorderDash?: number[];
  hoverBorderDashOffset?: number;
  hoverBorderJoinStyle?: JoinStyle;
  hoverBorderWidth?: number;
  indexAxis?: string;
  order?: number;
  pointRotation?: number;
  segment?: object;
  showLine?: boolean;
  spanGaps?: SpanGaps;
  stack?: string;
  stepped?: Stepped;
  tension?: number = 0;
}

export class PieChartDataset extends ChartDatasets {
  hoverOffset: number;
}

export class ChartLegends implements Chart.ChartLegendOptions {
  display?: boolean = false;
  position?: Chart.PositionType = "bottom";
  align?: Align = "center";
  maxHeight?: number;
  maxWidth?: number;
  fullSize?: boolean = true;
  onClick?: any;
  onHover?: any;
  onLeave?: any;
  reverse?: boolean = false;
  labels?: Chart.ChartLegendLabelOptions;
  rtl?: boolean;
  textDirection?: TextDirection;
  title?: LegendTitleConfig;
}

export class LegendLabelConfig {
  boxWidth?: number = 40;
  boxHeight?: number;
  color?: Color;
  font?: Font;
  padding?: number = 10;
  generateLabels?: any;
  filter?: Function;
  sort?: Function;
  pointStyle?: PointStyle = "circle";
  textAlign?: Align = "center";
  usePointStyle?: boolean = false;
  pointStyleWidth?: number;
  useBorderRadius?: boolean = false;
  borderRadius?: number;
}

export class LegendTitleConfig {
  color: Color;
  display: boolean = false;
  font: Font;
  padding: number = 0;
  text: string;
}
