import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";

import { environment } from "@env";
import { NotifyService } from "@services/notify.service";

@Injectable()
export class ToastrInterceptor implements HttpInterceptor {
  constructor(private _notifyService: NotifyService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {

        if(request.headers.get('authorization').slice(0,6) == 'Bearer'){
          if (error.status === 401) {
            this._notifyService.showError("Atenção!", "Não Autorizado");
          }
          else{
              if(error.error && typeof error.error == 'string'){
                this._notifyService.showError("Atenção!", error.error);
              }
              else if(error.error[0]?.message && typeof error.error[0]?.message == 'string'){
                this._notifyService.showError("Atenção!", error.error[0].message);
              }
              else if(error.error?.message && typeof error.error?.message == 'string'){
                this._notifyService.showError("Atenção!", error.error.message);
              }
              else if (error.error.warning) {
                this._notifyService.showWarning('Atenção!', error.error.message);
              }
              else{
                this._notifyService.showError('Atenção!', 'Ocorreu um erro. Tente novamente. Se o erro persistir, entre em contato com os canais de atendimento.');
              }
          }
        }
            
        return throwError(error);
      })
    );
  }
}
