<ul class="nav nav-tabs" role="tablist">
  @for(tab of tabs; track $index){
    <li class="nav-item">
      <a
        [id]="'tab'+$index"
        class="nav-link"
        [ngClass]="tab.badges ? 'pending-tab-element' : ''"
        [class.active]="$index === activeTabIndex"
        (click)="selectTab($index)"
        role="tab"
        [attr.aria-controls]="'tab' + $index"
        [attr.aria-selected]="$index === activeTabIndex"
      >
        {{ tab.heading }}
        @if(tab.badges?.value > 0){
          <span
              id="pending-tab-notification"
              class="numberNotification"
            >{{ tab.badges.value > 9 ? '9+' : tab.badges.value  }}</span>
        }
      </a>
    </li>
  }
</ul>
