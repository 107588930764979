import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogService } from '@services/log.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { GetVehiclesAvailableArguments } from '../dtos/getVehiclesAvailableArguments.dto';
import { GetVehiclesAvailableQueryResult } from '../dtos/getVehiclesAvailableQueryResult.dto';


const httpHeaders = new HttpHeaders({
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Headers': 'Content-Type',
});

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {

  private base = environment.apiTMVehicle;

  constructor(protected http: HttpClient, protected logger: LogService) { }



   /**
   * A Behavior Subject that updates the Hierarchy tree data. It emits an event to require a updated tree.
   *
   * @class boolean BehaviorSubject.
   * */
   refreshTree$ = new BehaviorSubject<boolean>(undefined);



  addHiearchy(Hierarchy: any) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    const apiUrl = `${this.base}/GroupHierarchy/SaveHierarchy`;
    const body = JSON.stringify(Hierarchy); // -> JSON para Salvar.
    return this.http.post<any>(apiUrl, body, httpOptions)
    .pipe(
      map((res) => {
        this.refreshTree$.next(undefined);
        return res;
      })
    );
  }

  getGroupHierarchy(hierarchySelect: number = null) {
    if(hierarchySelect != null){
      return this.http.get<any[]>(`${this.base}/GroupHierarchy/GetHierarchy?hierarchicalLevel=${hierarchySelect}`)
    }
    return this.http.get<any[]>(`${this.base}/GroupHierarchy/GetHierarchy`)

  }
  GetHierarchyList() {
    return this.http.get<any[]>(`${this.base}/GroupHierarchy/GetHierarchyList`)
    .pipe(
      map((res:any) => {
        return res.data;
      })
    );
  }

  GetHierarchyBreadCrumbList() {
    return this.http.get<any[]>(`${this.base}/GroupHierarchy/GetHierarchyBreadCrumbList`);
  }

  PostTransferVehicles(TransferGroupHierarchy: any) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    const apiUrl = `${this.base}/GroupHierarchy/TransferGroupHierarchy`;
    const body = JSON.stringify(TransferGroupHierarchy); // -> JSON para Salvar.
    return this.http.post<any>(apiUrl, body, httpOptions)
    .pipe(
      map((res) => {
        this.refreshTree$.next(undefined);
        return res;
      })
    );
  }

  DeleteHierarchy(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: {}
    };

    const options = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      body: {'id':id}
  }

  const apiUrl = `${this.base}/GroupHierarchy/Remove`;
  return this.http.delete<any>(apiUrl, options)
  .pipe(
    map((res) => {
      this.refreshTree$.next(undefined);
      return res;
    })
  );

  }

  PutSaveEditHierarchy(hierarchy: any){
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    const apiUrl = `${this.base}/GroupHierarchy/UpdateHierarchyList`;
    const body = JSON.stringify(hierarchy); // -> JSON para Salvar.
    return this.http.put<any>(apiUrl, body, httpOptions)
    .pipe(
      map((res) => {
        this.refreshTree$.next(undefined);
        return res;
      })
    );
  }

  getListVehicleAvailable(args:GetVehiclesAvailableArguments){
    return this.http.get<GetVehiclesAvailableQueryResult>(`${this.base}/GroupHierarchy/GetVehiclesAvailable`, { params: args.httpParans });
  }
  saveVehicleByLicensePlate(addVehicle: any) {
    return this.http.post(`${this.base}/GroupHierarchy/SaveVehicleByLicensePlate`, addVehicle)
    .pipe(
      map((res) => {
        this.refreshTree$.next(undefined);
        return res;
      })
    );
  }

}
