export const BILL_ITEM_TYPE: any = {
  PEDAGIO: 1,
  ESTORNO_PEDAGIO: 2,
  MENSALIDADE_TAG_ATIVA: 3,
  MENSALIDADE_TAG_ESTOQUE: 4,
  CREDITO_ANTECIPACAO_VPR: 5,
  SERVICO_ANTECIPACAO_VPR: 6,
  RECARGA_AUTOMATICA: 7,
  AQUISICAO_TAG: 8,
  FRETE: 9,
  TRIBUTOS: 10,
  ESTACIONAMENTO: 11,
  ESTORNO_ESTACIONAMENTO: 12,
  RECARGA_AVULSA: 13,
  TAXA_RECARGA: 14,
  TAXA_SOBRE_VOLUME: 15,
  MENSALIDADE_TAG_ATIVA_PRE_PAGO: 16,
  MENSALIDADE_TAG_ESTOQUE_PRE_PAGO: 17,
  ESTORNO_RECARGA_AUTOMATICA: 18,
  ESTORNO_RECARGA_AVULSA: 19,
  ESTORNO_TAXA_RECARGA: 20,
  SERVICO_INTEGRACAO_DADOS: 21
}
