import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from "@modules/commom/header/header.component";
import {SharedModule} from "../../../shared/shared.module";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {OverlayModule} from "@angular/cdk/overlay";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from "@angular/platform-browser";
import {MatExpansionModule} from "@angular/material/expansion";
import {CustomerHeaderComponent} from "@modules/commom/header/componentes/customer-header/customer-header.component";
import {
  NotificationComponent
} from "@modules/commom/header/componentes/customer-header/components/notification/notification.component";
import {
  TollBalanceComponent
} from "@modules/commom/header/componentes/customer-header/components/toll-balance/toll-balance.component";
import {
  AlertCreditModalComponent
} from "@modules/commom/header/componentes/customer-header/components/alert-credit-modal/alert-credit-modal.component";
import {
  MenuResponsiveComponent
} from "@modules/commom/header/componentes/customer-header/components/menu-responsive/menu-responsive.component";
import {MenuNavComponent} from "@modules/commom/header/componentes/backoffice-header/menu-nav.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {TranslateModule} from "@ngx-translate/core";
import {UserProfileService} from "@services/user-profile.service";

@NgModule({
  declarations: [
    HeaderComponent,
    CustomerHeaderComponent,
    NotificationComponent,
    TollBalanceComponent,
    AlertCreditModalComponent,
    MenuResponsiveComponent,
    MenuNavComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    OverlayModule,
    OverlayPanelModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatExpansionModule,
    MatToolbarModule,
    TranslateModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    MenuNavComponent
  ],
  providers: [provideNgxMask(), UserProfileService]
})
export class HeaderModule { }
