import { Component, OnInit } from "@angular/core";
import { MyAccountService } from "./services/myaccount.service";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { CurrentUser } from "@models/old/currentuser.model";
import { LocalstorageService } from "@services/localstorage.service";
import { EditContractPhoneModalComponent } from "./components/edit-contract-phone-modal/edit-contract-phone-modal.component";
import { EditContractEmailModalComponent } from "./components/edit-contract-email-modal/edit-contract-email-modal.component";
import { ChangePasswordModalComponent } from "./components/change-password-modal/change-password-modal.component";
import { StoragedContractInfosService } from "@services/storaged-contract-infos.service";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";

@Component({
  selector: "app-myaccount",
  templateUrl: "./myaccount.component.html",
  styleUrls: ["./myaccount.component.scss"],
})
export class MyaccountComponent extends AbstractProcessLauncher implements OnInit {

  currentUser$: Observable<CurrentUser>;
  public currentUser: CurrentUser;
  isLoading: boolean;
  public userContractList: any;

  constructor(
    private myAccountService: MyAccountService,
    private ngbModalService: NgbModal,
    private localstorageService: LocalstorageService,
    protected router: Router,
    private _storagedContractInfosService: StoragedContractInfosService
  ) {
    super(router);

    this.currentUser$ = myAccountService.updateCurrentUser$;
  }

  ngOnInit() {
    this.showCurrentUser();
    this.showContractsCurrentUser();
  }

  changePassword() {
    this.ngbModalService.open(ChangePasswordModalComponent);
  }

  setLocalStorageContractInfos(contract: any) {
    this.localstorageService.setContractId(contract.id);
    this.localstorageService.setUser(contract);
    this.localstorageService.setContractModalityTypeId(
      contract.contractModalityTypeId
    );
    this.localstorageService.removeCreditcard();
    this.localstorageService.setContractBlockLevel(contract.contractBlockLevel);
    if (Number(contract.contractModalityTypeId) == 1) {
      this.localstorageService.setCrediCardCheck(contract.hasCreditCard);
    }
  }

  selectContract(contract) {
    this.isLoading = true;
    this.setLocalStorageContractInfos(contract);

    this.authService
      .setRolesOnStorage()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this._storagedContractInfosService.storagedContractInfos$.next(undefined)
          this.router.navigate(["/pagina-inicial"]);
        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

  showEditPhoneModal(currentUser) {
    const modalRef = this.ngbModalService.open(EditContractPhoneModalComponent);
    modalRef.componentInstance.currentUser = currentUser;
  }

  showEditEmailModal(currentUser) {
    const modalRef = this.ngbModalService.open(EditContractEmailModalComponent);
    modalRef.componentInstance.currentEmailAddress = currentUser.emailAddress;
    modalRef.componentInstance.personEmailAddressId = currentUser.personEmailAddressId;
  }

  showCurrentUser() {
    this.isLoading = true;
    this.myAccountService.getCurrentUser()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.currentUser = success;
        },
        (error) => {
          console.log("Erro: " + error);
        }
      );
  }


  showContractsCurrentUser() {
    this.isLoading = true;
    this.myAccountService.getContracts()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (sucess) => {
          this.userContractList = sucess
        },
        (error) => {
          console.log("Erro: " + error);
        }
      );
  }
}
