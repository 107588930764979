 <!-- Modal userIncludeModal -->
<div class="modal fade userIncludeModal" id="userIncludeModal" tabindex="-1" role="dialog" aria-labelledby="userIncludeModal" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">

         <div class="modal-header">
            <div class="box-title">
               <span>Incluir Usuário</span>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>

      <form class="modal-body include-user" [formGroup]="formUser" *ngIf="step==1">

        <div class="row titleRow">
          <p>Dados pessoais</p>
          <span>Nome completo e CPF</span>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group-user">
              <label for="fullName">Nome</label>
              <input type="text" class="form-control-user" id="fullName" formControlName="fullName" placeholder="Nome completo">
            </div>
            <div class="alertErrorValidator" *ngIf="(formUser.controls['fullName']?.invalid && formUser.controls['fullName']?.touched) || (submitted && formUser.controls['fullName']?.invalid)">
              {{ generateErrorMessage(formUser.controls['fullName'])}}
            </div>
          </div>
          <div class="col-6">
            <div class="form-group-user">
              <label for="documentNumber">CPF</label>
              <input mask="000.000.000-00" type="text" min="11" class="form-control-user" id="documentNumber" formControlName="documentNumber" placeholder="000.000.000-00">
            </div>
            <div class="alertErrorValidator" *ngIf="(formUser.controls['documentNumber']?.invalid && formUser.controls['documentNumber']?.touched) || (submitted && formUser.controls['documentNumber']?.invalid)">
              {{ generateErrorMessage(formUser.controls['documentNumber'])}}
            </div>
          </div>
        </div>

        <div class="row titleRow mt-2">
          <span>Data de nascimento</span>
        </div>

        <div class="row">
          <div class="col-4">

            <div class="form-group-user">
              <label for="documentNumber">Dia</label>
              <select  formControlName="dateOfBirthDay" class="form-control-user" id="dateOfBirthDay">
                <option value="">Dia</option>
                <option *ngFor="let day of days" [value]="day.value" id="day{{day.desc}}">
                  {{day.desc}}
                </option>
              </select>

              <div class="alertErrorValidator" *ngIf="(formUser.controls['dateOfBirthDay']?.invalid && formUser.controls['dateOfBirthDay']?.touched) || (submitted && formUser.controls['dateOfBirthDay']?.invalid)">
                {{ generateErrorMessage(formUser.controls['dateOfBirthDay'])}}
              </div>
            </div>

          </div>
          <div class="col-4">
            <div class="form-group-user">
              <label for="documentNumber">Mês</label>

              <select  formControlName="dateOfBirthMonth"  class="form-control-user" id="dateOfBirthMonth">
                <option value="">Mês</option>
                <option *ngFor="let month of months" [value]="month.value" id="month{{month.desc}}">
                  {{month.desc}}
                </option>
              </select>

              <div class="alertErrorValidator" *ngIf="(formUser.controls['dateOfBirthMonth']?.invalid && formUser.controls['dateOfBirthMonth']?.touched) || (submitted && formUser.controls['dateOfBirthMonth']?.invalid)">
                {{ generateErrorMessage(formUser.controls['dateOfBirthMonth'])}}
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group-user">
              <label for="documentNumber">Ano</label>
              <select  formControlName="dateOfBirthYear" class="form-control-user" id="dateOfBirthYear">
                <option value="">Ano</option>
                <option *ngFor="let year of years" [value]="year.value" id="year{{year.value}}">
                  {{year.value}}
                </option>
              </select>
              <div class="alertErrorValidator" *ngIf="(formUser.controls['dateOfBirthYear']?.invalid && formUser.controls['dateOfBirthYear']?.touched) || (submitted && formUser.controls['dateOfBirthYear']?.invalid)">
                {{ generateErrorMessage(formUser.controls['dateOfBirthYear'])}}
              </div>
          </div>
        </div>
        </div>

        <div class="row titleRow">
          <p>Contato</p>
          <span>E-mail e telefone de contato</span>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group-user">
              <label for="emailAddress">E-mail</label>
              <input type="text" class="form-control-user" id="emailAddress" formControlName="emailAddress" placeholder="Digite o e-mail">

             <div class="alertErrorValidator">
              <span *ngIf="(((formUser.controls['emailAddress']?.invalid && formUser.controls['emailAddress']?.touched)
              || (submitted && formUser.controls['emailAddress']?.invalid)) && !this.emailExists)">
                <span class="fas fa-exclamation-circle"></span> O e-mail informado não é válido. Por favor informe um e-mail válido.
              </span>
              <span *ngIf="emailExists">
                <span class="fas fa-exclamation-circle"></span> O e-mail informado já está sendo utilizado por outro usuário.
              </span>
            </div>


            </div>
          </div>
          <div class="col-6">
            <div class="form-group-user">
              <label for="telephoneNumber">Telefone</label>
              <input type="text" [mask]="getPhoneMask()"  class="form-control-user" id="telephoneNumber" formControlName="telephoneNumber">
              <div class="alertErrorValidator" *ngIf="(formUser.controls['telephoneNumber']?.invalid && formUser.controls['telephoneNumber']?.touched) || (submitted && formUser.controls['telephoneNumber']?.invalid)">
                {{ generateErrorMessage(formUser.controls['telephoneNumber'])}}
              </div>
            </div>
          </div>
        </div>

        <div class="row titleRow">
          <p>Informação de acesso</p>
          <span>Escolha o perfil de acesso do usuário</span>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group-user">
              <label>Perfil de acesso</label>

              <mat-form-field appearance="outline">
                <mat-select required  formControlName="profile" id="profile" placeholder="Perfil de acesso" panelClass="mat-select-panel">
                  <mat-option *ngFor="let profile of profileList" [value]="profile.id"
                   matTooltip="{{profile.subTitle}}" [matTooltipPosition]="'right'" [matTooltipClass]="'tooltip'">
                    {{ profile.description }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formUser.controls['profile'].hasError('required') && formUser.controls['profile'].pristine">
                  Required Message
                </mat-error>
              </mat-form-field>
            </div>
            <div class="alertErrorValidator">
              <span *ngIf="profileNotSelect">
                <span class="fas fa-exclamation-circle"></span> Selecione um perfil de acesso do usuário
              </span>
            </div>

          </div>
        </div>
        @if(isBackOfficeProfile()){
          <div class="row mt-2">
            <div class="col-12 form-row">
              <p>Informe o protocolo de solicitação <span>*</span></p>
              <input id="protocol" formControlName="protocol" required mask="9999999999999999999"
                placeholder="Digite aqui o protocolo" maxlength="20" />

              <div class="alertErrorValidator">
                @if(validateFormProtocol()){
                  <span>
                    <span class="fas fa-exclamation-circle"></span> Informe um
                    número de protocolo válido.
                  </span>
                }
              </div>
            </div>
            </div>
        }
  </form>

  <div class="modal-footer modal-footer-gray justify-content-start" *ngIf="step==1">
    <div class="col-12 justify-content-between d-flex">
      <app-borderless-button
      [buttonColor]="'tertiary'"
      [isBtnWithBorder]="true"
      [description]="'Cancelar'"
      [id-html]="'cancelBtnId'"
      [data-e2e]="'cancel-modal-btn'"
      (emitterEvtClick)="close()"/>

      <app-borderless-button
      [buttonColor]="'primary'"
      [isBtnWithBorder]="true"
      [description]="'Incluir Usuário'"
      [id-html]="'AddUserButton'"
      [data-e2e]="'open-add-user-modal-btn'"
      [classIconLeft]="'ico-user-add'"
      (emitterEvtClick)="confirmEmail()"
      [isDisable]="formUser.invalid"/>
    </div>
 </div>

  <div class="modal-body confirm-include-user" *ngIf="step==2">
   <div class="row row-header">
      <div class="col-lg-12 confirm-data">
         <p>Confira se os dados estão corretos e confirme:</p>
      </div>
   </div>    <div class="row user-data">
      <div class="col-6">
        <p>Nome:</p>
        <p>Perfil:</p>
        <p>CPF:</p>
        <p>Data de nascimento:</p>
        <p>Telefone:</p>
        <p>E-mail:</p>
      </div>
      <div class="col-6">
        <p>{{formUser.value.fullName}}</p>
        <p><span id="profileDesc">{{selectedProfile?.description}}</span>
         <i
            class="ico ico-alert"
            placement="right"
            ngbTooltip="{{this.selectedProfile.subTitle}}"
          ></i>
        </p>
        <p>{{formUser.value.documentNumber |  mask: '000.000.000-00'}}</p>
        <p>{{formUser.value.dateOfBirthDay}}/{{formUser.value.dateOfBirthMonth}}/{{formUser.value.dateOfBirthYear}}</p>
        <p>{{formUser.value.telephoneNumber | mask: getPhoneMask() }}</p>
        <p>{{formUser.value.emailAddress}}</p>
      </div>
   </div>
  </div>
   <div class="modal-footer modal-footer-gray row" *ngIf="step==2">
      <button type="button" [disabled]="isLoading" class="btn btn-tertiary mr-4" id="returnBtnId" aria-label="Close" (click)="returnStep()">Voltar</button>
      <button type="button" [disabled]="isLoading" id="ConfirmButton" class="btn btn-secondary position-relative" (click)="onSubmit(true)">
         <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
         Confirmar
      </button>
  </div>


      <div class="modal-body included-user" *ngIf="step==3">
         <div class="row">
            <div class="row-box-yellow">
              <span class="box-yellow">
               <b>O</b> usuário criado receberá um e-mail de boas-vindas em até 2 horas.
              </span>
            </div>
           </div>
         <div class="row row-header">
            <div class="col-lg-12 confirm-data">
               <p>Usuário cadastrado com sucesso</p>
            </div>
         </div>
          <div class="row user-data">
            <div class="col-6">
              <p>Nome:</p>
              <p>Perfil:</p>
              <p>CPF:</p>
              <p>Data de nascimento:</p>
              <p>Telefone:</p>
              <p>E-mail:</p>
            </div>
            <div class="col-6">
              <p>{{form.fullName}}</p>
              <p><span id="profileDesc">{{selectedProfile?.description}}</span>
               <i
               [attr.data-e2e]="'profile-option-'+selectedProfile?.name+'-tooltip-msg'"
               class="ico ico-alert col-1"
               placement="bottom"
               ngbTooltip="{{selectedProfile?.subTitle}}"
             ></i>
              </p>
              <p>{{form.documentNumber |  mask: '000.000.000-00'}}</p>
              <p>{{form.dateOfBirthDay}}/{{form.dateOfBirthMonth}}/{{form.dateOfBirthYear}}</p>
              <p>{{form.telephoneNumber | mask: getPhoneMask()}}</p>
              <p>{{form.emailAddress}}</p>
            </div>
         </div>
         <div class="userResponsibleAnddate">
             {{ success?.registrationDate | date:'dd/MM/yyyy HH:mm:ss'}} por {{ success?.userResponsible }}
            @if(isBackOfficeProfile()){
              <p><b>Protocolo </b> {{form.protocol}}</p>
            }
         </div>
        </div>
         <div class="modal-footer modal-footer-gray row confirm" *ngIf="step==3">
            <button type="button" id="CloseButton" class="btn btn-secondary position-relative" (click)="close()">Fechar
            </button>
        </div>



   </div>
 </div>
 </div>
 <!-- /Modal userIncludeModal-->

