import { HttpParams } from '@angular/common/http';

export type RequestType =
  | 'expediture'
  | 'canceledTags'
  | 'costsRanking'
  | 'totalCosts';

export class Filter {
  constructor(
    public params?: FiltersParams,
    public request?: RequestType,
  ) {}

  toParams() {
    return new HttpParams({ fromObject: this.request as any });
  }
}

export class FiltersParams {
  startDate?: Date | string;
  endDate?: Date | string;
  myFleet?: boolean = false;
  IsOnlyGreaterThanZero?: boolean;
  desc?: boolean;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  search?: string;
}

export enum EFiltersIds {
  transactionDate = 1,
  expectedClearingDate = 2,
  transactionType = 3,
  statusType = 4,
  rangeAmount = 5,
  radioStep = 6,
  refusalReason = 7,
  creditDate = 8,
  expirationDate = 9
}

export type FilterName =
  | 'transactionDateFilter'
  | 'expectedClearingDateFilter'
  | 'creditDateFilter'
  | 'expirationDateFilter'
  | 'transactionTypeFilter'
  | 'statusTypeFilter'
  | 'rangeAmountFilter'
  | 'canceledTags'
  | 'radioStepFilter'
  | 'refusalReasonFilter';

export type Filtertype = 'date' | 'checkbox' | 'range-amount' | 'value';

export class Filters {
  transactionDateFilter: Date[];
  expectedClearingDateFilter: Date[];
  creditDateFilter: Date[];
  expirationDateFilter: Date[];
  transactionTypeFilter: number[];
  statusTypeFilter: number[];
  rangeAmountFilter: number[];
  radioStepFilter: number;
  refusalReasonFilter: number[];
}

export class FilterTextValues {
  transactionDateText: string;
  expectedClearingDateText: string;
  creditDateText: string;
  expirationDateText: string;
  transactionTypeText: string;
  statusTypeText: string;
  rangeAmountText: string;
  radioStepText: string;
  refusalReasonText: string;
}

export interface IFilterNameId {
  name: string;
  id: number;
}
