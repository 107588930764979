import { Component, ViewChild, Input, Output, EventEmitter, input, OnChanges, Inject, SimpleChanges, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, FormControl, FormControlName, FormGroup, FormGroupDirective } from "@angular/forms";
import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService } from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrl: './input-date-picker.component.scss'
})
export class InputDatePickerComponent implements  OnInit, OnChanges{
  @ViewChild('dp') datepicker: BsDatepickerDirective;
  @Input() invalid: boolean = false
  @Input() message: String = 'Por favor informe a data'
  @Input() id: number;
  @Input() set date(value:  string) {
     this.hasDateValue(value)
  }

  formattedDate: string | null = null;
  private _date: Date | null = null;
  formGroup: FormGroupDirective;

  @Output() blur = new EventEmitter<void>();
  @Output() formControlChange = new EventEmitter<any>();

  get date(): string | Date {
    return this._date;
  }

  constructor(private localeService: BsLocaleService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.date){
      this.dateChanged(changes)
    }
  }

  ngOnInit() {
    this.localeService.use('pt-br');
  }

  dateChanged(changes: any){
    if(changes.date.currentValue != null && changes.date.currentValue != '' && changes.date.currentValue != undefined){
      const [ano, mes, dia] = changes.date.currentValue.split('-');
       this.formattedDate = `${dia}/${mes}/${ano}`
     }
  }
  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    dateInputFormat: 'DD/MM/YYYY',
  };


  onDateSelection(date: Date) {
    console.log(date)
    this.formControlChange.emit('')
    if( date != undefined){
      this.formControlChange.emit(date.toISOString().split('T')[0])
    }
  }

  hasDateValue(value){
        this.formattedDate = ''
    if (value && value != '') {
      const [ano, mes, dia] = value.split('-');
      this.formattedDate = `${dia}/${mes}/${ano}`
    }
  }

  onBlur(){
    this.blur.emit()
  }

  formatarData(data: Date): string {
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

}
