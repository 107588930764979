import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-validation-password',
  templateUrl: './validation-password.component.html',
  styleUrls: ['./validation-password.component.scss']
})
export class ValidationPasswordComponent implements OnChanges {

  isSizeMinValid: boolean | undefined = undefined;
  isLowerCharValid: boolean | undefined = undefined;
  isUpperCharValid: boolean | undefined = undefined;
  isNumberValid: boolean | undefined = undefined;
  isSpecialCharValid: boolean | undefined = undefined;

  @Input() password: string;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.password?.currentValue) {
      this.validateSizeMin(changes?.password?.currentValue);
      this.validateLowerChar(changes?.password?.currentValue);
      this.validateUpperChar(changes?.password?.currentValue);
      this.validateNumber(changes?.password?.currentValue);
      this.validateSpecialChar(changes?.password?.currentValue);
    } else {
      this.isSizeMinValid = this.isLowerCharValid = this.isUpperCharValid = this.isNumberValid = this.isSpecialCharValid = undefined;
    }
  }

  private validateSizeMin(password: string): void {
    this.isSizeMinValid = password.length >= 8;
  }

  private validateLowerChar(password: string): void {
    this.isLowerCharValid = /[a-z]/.test(password);
  }

  private validateUpperChar(password: string): void {
    this.isUpperCharValid = /[A-Z]/.test(password);
  }

  private validateNumber(password: string): void {
    this.isNumberValid = /\d/.test(password);
  }

  private validateSpecialChar(password: string): void {
    this.isSpecialCharValid = /[\W_]/.test(password)
  }

  loadImageFile(isEventValid: boolean): string {
    let img: string = 'assets/svg/radio_button_unchecked.svg';
    if (isEventValid) {
      img = '/assets/svg/done.svg';
    } else if (isEventValid === false) {
      img = '/assets/svg/close-circle-outline.svg'
    }
    return img;
  }

  loadClassConfiguration(isValido: boolean): string {
    let _class: string = 'ico-unchecked-svg';
    if (isValido) {
      _class = 'ico-valid-svg';
    } else if (isValido === false) {
      _class = 'ico-invalid-svg';
    }
    return _class;
  }

}
