import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";
import {LocalstorageService} from "@services/localstorage.service";
import {AuthService} from "./auth.service";
import {UserProfileService} from "@services/user-profile.service";

const LOGIN_URL: string = '/login';
const SELECT_PROFILE_URL: string = '/selecionar-perfil';
const SELECT_CONTRACT_URL: string = '/selecionar-contrato';
const NAO_AUTORIZADO_URL: string = '/nao-autorizado';

function navigateWithRedirect(router: Router, url: string, state: RouterStateSnapshot): boolean {
  router.navigate([url], {
    queryParams: {redirect: state.url},
    replaceUrl: true
  }).catch(error => {
    console.error("Erro ao navegar:", error);
  });
  return false;
}

export const authGuardCustomer: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  const customerRoles: string = route.data.roles;
  const backofficeRoles: string = route.data.backofficeRoles;
  const contractRequired: boolean = route.data.contractRequired;
  const prepaidPage: boolean = route.data.prepaidPage;

  const router: Router = inject(Router);
  const oauthService: OAuthService = inject(OAuthService);
  const localstorageService: LocalstorageService = inject(LocalstorageService);
  const manageProfileService: UserProfileService = inject(UserProfileService);
  const authService: AuthService = inject(AuthService);

  /** Not authenticated, redirecting and adding redirect url */
  if (!oauthService.hasValidAccessToken()) {
    return navigateWithRedirect(router, LOGIN_URL, state);
  }


  if (manageProfileService.isCustomerOrRelationshipProfile()) {

    /** `User does not have the roles ${customerRoles} */
    if (!authService.checkRoles(customerRoles)) {
      return navigateWithRedirect(router, NAO_AUTORIZADO_URL, state);
    }

    /** User does not have the contract selected  */
    if (contractRequired && (localstorageService.getContractId() == null
      || localstorageService.getContractId().length == 0
      || localstorageService.getContractId() == "0")) {
      return navigateWithRedirect(router, SELECT_CONTRACT_URL, state);
    }

    if (prepaidPage && Number(localstorageService.getContractModalityTypeId()) !== 1) {
      return navigateWithRedirect(router, NAO_AUTORIZADO_URL, state);
    }
  }

  /** `User does not have the roles ${backofficeRoles} */
  if (manageProfileService.isBackOffice()) {
    if (!authService.checkRoles(backofficeRoles)) {
      return navigateWithRedirect(router, NAO_AUTORIZADO_URL, state);
    }

    /** User does not have the contract selected  */
    if (contractRequired && (localstorageService.getSessionStorageContractId() == null
      || localstorageService.getSessionStorageContractId().length == 0
      || localstorageService.getSessionStorageContractId() == "0")) {
      return navigateWithRedirect(router, SELECT_PROFILE_URL, state);
    }

    if (prepaidPage && Number(authService.getContractModalityTypeId()) !== 1) {
      return navigateWithRedirect(router, NAO_AUTORIZADO_URL, state);
    }
  }

  return true;
}
