<div class="passwordRequirementsComponent">
    <div class="title">Requisitos de senha:</div>
    <div class="validation" [ngClass]="minLengthValidation ? 'valid' : ( minLengthValidation == false ? 'invalid' : '' )"><span>Mínimo 8 caracteres</span></div>
    <div class="example"></div>
    <div class="validation" [ngClass]="lowercaseValidation ? 'valid' : ( lowercaseValidation == false ? 'invalid' : '' )"><span>Mínimo 1 letra minúscula</span></div>
    <div class="example">Ex: a b c d e f g h</div>
    <div class="validation" [ngClass]="uppercaseValidation ? 'valid' : ( uppercaseValidation == false ? 'invalid' : '' )"><span>Mínimo 1 letra maiúscula</span></div>
    <div class="example">Ex: A B C D E F G H</div>
    <div class="validation" [ngClass]="numberValidation ? 'valid' : ( numberValidation == false ? 'invalid' : '' )"><span>Mínimo 1 número</span></div>
    <div class="example">Ex: 1 2 3 4 5 6 7 8</div>
    <div class="validation" [ngClass]="especialCaracterValidation ? 'valid' : ( especialCaracterValidation == false ? 'invalid' : '' )"><span>Mínimo 1 caractere especial</span></div>
    <div class="example">Ex: ! &#64; # $ % & * ( ) - = +</div>
</div>

