import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetVprCreditReceivedQueryResult } from '../dtos/get-vpr-credit-received-query-result.dto';
import { GetVprCreditReceivedQueryArguments } from '../dtos/get-vpr-credit-received-query-arguments.dto';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})

export class VprCreditReceivedService {

  private base = environment.apiTMIntegrationVPR;

  constructor(protected http: HttpClient) { }

  getTollVoucherRecharge(args: GetVprCreditReceivedQueryArguments) {
    return this.http.get<GetVprCreditReceivedQueryResult>(this.base + `/api/TollVoucherRecharge`, {
      params: args.httpParans,
    });
  }


  //Download CSV	  
  public getTollVoucherRechargeCSVFile(args: GetVprCreditReceivedQueryArguments) {
    let uri = this.base + `/api/TollVoucherRecharge/Csv`;
    return this.http.get(uri, { observe: 'response', responseType: 'blob', params: args.httpParans })
      .pipe(map((response) => {
        let data = {
          file: new Blob([response.body], { type: response.headers.get('Content-Type') }),
          filename: response.headers.get('filename'), // pegar nome do arquivo caso content-disposition esteja acessível. 
        }
        return data;
      }
      ));
  }

} 
