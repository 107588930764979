import {CardConfiguration} from "@models/old/financial/bill/bill-card/card-configuration.abstract";

export class Bill extends CardConfiguration {
  id: number;
  status: string;
  referenceYear: string;
  changeDate: string;
  dueDate: string | Date;
  openingDate: string | Date;
  closingDate: string | Date;
  amount: number;
  contractType: number;
  billetLink: string;
  invoiceLink: string;
  paymentDate: string | Date;
  pdfLink: string;
  excelLink: string;
  idStatus: number;
  csvFile: any;
  flgDownloadPDF: any;
  pdfFile: any;
  csvFileStatusID: number;
  pdfFileStatusID: number;
  permissionManualRecomposition?: any;
  csvDate: string | Date;

  constructor() {
    super();
  }
}
