import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(private toastr: ToastrService) { }

  showSuccess(title: string, message: string) {
    this.toastr.success(message, title);
  }

  showSuccessDefineTimeOut(title: string, message: string, timeout:number) {
    this.toastr.success(message, title, {
      timeOut: timeout,
    });
  }

  showInfo(title: string, message: string) {
    this.toastr.info(message, title);
  }

  showWarning(title: string, message: string) {
    this.toastr.warning(message, title);
  }

  showWarningDefineTimeOut(title: string, message: string, timeout:number) {
    this.toastr.warning(message, title, {
      timeOut: timeout,
    });
  }

  showError(title: string, message: string) {
    this.toastr.error(message, title);
  }
  clear() {
    this.toastr.clear();
  }

}
