<div class="ExcludeHolidayModalComponent">
  <div class="modal-header">

      <h4 class="modal-title" id="modal-title">EXCLUIR FERIADO</h4>
      <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
        <img src="assets/svg/close.svg" alt="Ícone de X"/>
      </a>

  </div>
  <div class="modal-body">
    <p>Tem certeza de que deseja excluir o feriado <span>{{holiday.name}}</span> em <span> {{holiday.holidayDate | date: "dd/MM/yyyy"}}</span>?</p>
    <form [formGroup]="excludeForm">
    <div class="toll-input-box">
      <label class="toll-input-box__label" for="input-protocol">Informe o protocolo de solicitação <span>*</span></label>
      <input
        class="toll-input-box__input"
        [class.is-invalid]="validateProtocol()"
        formControlName="protocol"
        id="input-protocol"
        data-e2e="input-protocol"
        (click)="excludeForm.markAsTouched()"
        type="text"
        placeholder="Digite aqui o protocolo"
        maxlength="40"

      />
      @if(validateProtocol()){
        <app-vmessage
        id="msg-error-input-protocol"
        errorMessage="{{ generateErrorMessageByFormControlName('protocol') }}"
      >
      </app-vmessage>
      }
    </div>
    </form>
  </div>
  <div class="modal-footer">

    <app-borderless-button
      [description]="'Cancelar'"
      [id-html]="'modal-btn-cancel'"
      [data-e2e]="'modal-btn-cancel'"
      [buttonColor]="'secundary'"
      [isBtnWithBorder]="true"
      (emitterEvtClick)="dismissModal()">
    </app-borderless-button>

    <app-borderless-button
      [description]="'Confirmar exclusão'"
      [classIconLeft]="'ico-trash-white'"
      [id-html]="'modal-btn-confirm'"
      type="submit"
      [data-e2e]="'modal-btn-confirm'"
      [buttonColor]="'primary'"
      [isBtnWithBorder]="true"
      (emitterEvtClick)="excludeHoliday(holiday.id)"
      [isDisable]="validateProtocol() || !excludeForm.get('protocol').value">
    </app-borderless-button>
  </div>
</div>
