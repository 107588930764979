import { HttpParams } from "@angular/common/http";

export class ValidateUserByDocumentAndGroupDto {
  groupId: number;
  documentNumber: string;

  get httpParams() {
    let params = new HttpParams()
      .append('GroupId', this.groupId.toString())
      .append('DocumentNumber', this.documentNumber);

    return params;
  }
}
