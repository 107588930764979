import { PaymentMethodComponent } from 'src/app/shared/components/payment/payment-method/payment-method.component';
import { AfterViewChecked, Component, inject, OnInit, Renderer2 } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { PaymentService } from "src/app/core/services/payment.service";
import { PrepaidConditions } from "src/app/core/models/prepaid.model";
import { CurrencyPipe } from "@angular/common";
import { StoragedContractInfosService } from "@services/storaged-contract-infos.service";
import { PaymentMethodEnum, PaymentObject, PaymentOrigin } from "@models/payment.model";
import { ChangeAutoRechargeModalComponent } from "./components/change-auto-recharge-modal/change-auto-recharge-modal.component";
import { PurchaseConfirmationModalComponent } from "src/app/shared/components/payment/purchase-confirmation-modal/purchase-confirmation-modal.component";
import { PaymentTransactionConfirmationComponent } from "src/app/shared/components/payment/payment-transaction-confirmation/payment-transaction-confirmation.component";
import { PixPaymentModalComponent } from "src/app/shared/components/payment/pix-payment-modal/pix-payment-modal.component";
import { RegisterCreditCardModalComponent } from "src/app/shared/components/payment/register-credit-card-modal/register-credit-card-modal.component";
import { UpdateCreditCardModalComponent } from "src/app/shared/components/payment/update-credit-card-modal/update-credit-card-modal.component";
import { RechargeFeeModalComponent } from "./components/recharge-fee-modal/recharge-fee-modal.component";
import { ContractPixFee } from "@models/contractpixfee.model";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";
import { Router } from "@angular/router";

@Component({
  selector: "app-recharge",
  templateUrl: "./recharge.component.html",
  styleUrls: ["./recharge.component.scss"],
})
export class RechargeComponent extends AbstractProcessLauncher implements OnInit, AfterViewChecked {
  prePaidConditions$: Observable<PrepaidConditions>;
  prePaidConditions: PrepaidConditions;
  contractPixFee: ContractPixFee[];
  hasCreditCard: boolean = null;
  looseRechargeForm: FormGroup;
  submitted: boolean = false;
  isLoading: boolean;
  rangeList: any;
  sub$: Subscription;
  formObject: PaymentObject = null;

  //Injections
  private renderer: Renderer2;

  constructor(
    public modal: NgbModal,
    public service: PaymentService,
    private _storagedContractInfosService: StoragedContractInfosService,
    private currencyPipe: CurrencyPipe,
    public paymentMethodComponent: PaymentMethodComponent,
    protected router: Router
  ) {
    super(router);
    this.prePaidConditions$ = service.updatePrepaidConditions$;
    this._getHasCreditCardVariable();
    this.renderer = inject(Renderer2);
  }

  _getHasCreditCardVariable() {
    this.sub$ = this._storagedContractInfosService.hasCreditCard$
      .subscribe((data) => {
        this._updateCreditCardInfo(data);
      });
  }

  private _updateCreditCardInfo(hasCreditCard: boolean) {
    this.hasCreditCard = hasCreditCard;
    this.getPrePaidConditions();
  }

  ngOnInit() {
    this.getPrePaidConditions();
    this.getAutoRechargeValues();
    this.getContractPixFee();
  }

  ngAfterViewChecked(): void {
    this.disableElementIfBackOfficeUser();
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  isInteger() {
    return (control: AbstractControl): Validators => {
      let rechargeValue = this.looseRechargeForm
        ? this.looseRechargeForm.controls["amount"].value
        : 0;

      return rechargeValue % 1 != 0 && !isNaN(rechargeValue % 1)
        ? { notInteger: true }
        : null;
    };
  }

  getAutoRechargeValues() {
    this.isLoading = true;
    this.service
      .getAutomaticRechargeLimitValues()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: any) => {
          this.rangeList = success;
        },
        (error) => {
          console.log("error: ", error);
          return;
        }
      );
  }

  getContractPixFee() {
    this.isLoading = true;
    this.service
      .getContractPixFee()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: Array<ContractPixFee>) => {
          this.contractPixFee = success;
        },
        (error) => {
          console.error("error: ", error);
        }
      );
  }

  getPrePaidConditions() {
    this.isLoading = true;
    this.service
      .getPrePaidConditions()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: PrepaidConditions) => {
          this.prePaidConditions = success;
          this._initForm();
        },
        (error) => {
          console.error("error: ", error);
        }
      );
  }

  private _initForm() {
    this.looseRechargeForm = new FormGroup({
      amount: new FormControl(this.formObject ? this.formObject.paymentValue : 0, [
        Validators.required,
        Validators.min(this.prePaidConditions?.minSingleRechargeValue),
        this.isInteger()
      ]),
      isPix: new FormControl()
    });
  }

  generateMessage(errors) {
    if (typeof errors !== "undefined") {
      if (errors.required) {
        return "Campo é requirido!";
      }
      if (errors.min) {
        return `Valor mínimo permitido: ${this.currencyPipe.transform(errors.min.min, 'BRL', 'symbol', '0.2-2', 'pt-BR')}!`;
      }
      if (errors.notInteger) {
        return 'O valor informado deve ser um número inteiro'
      }
    }
  }

  purchaseConfirm(object) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
    };
    const modalRef = this.modal.open(PurchaseConfirmationModalComponent, ngbModalOptions);
    modalRef.componentInstance.object = object;
    modalRef.componentInstance.event.subscribe((paymentMethodSelected) => {
      if (paymentMethodSelected == PaymentMethodEnum.Pix) {
        this.looseRechargeForm.controls.isPix.setValue(true);
      }
      else {
        this.looseRechargeForm.controls.isPix.setValue(false);
      }

      this.postSingleRecharge(paymentMethodSelected);
    })
  }

  postSingleRecharge(paymentMethodSelected) {
    this.service
      .postSingleRecharge(this.looseRechargeForm.value)
      .pipe(
        finalize(() => {
          this.service.processingPaymentTransaction$.next(false)
        })
      )
      .subscribe(
        (success) => {
          if (paymentMethodSelected == PaymentMethodEnum.Pix) {
            this.showPaymentPixModal(success.pixTransactionId)
          }
          else {
            this.singleRechargeConfirmation(success)
          }
        },
        (error) => {
          this.paymentMethodComponent.ngOnInit();
          console.error(error)
        });
  }

  validPaymentMethod() {
    if (this.service.paymentMethodSelected == null ||
      typeof this.service.paymentMethodSelected == 'undefined') {
      return true
    } else if (this.service.paymentMethodSelected == 0 || (this.service.paymentMethodSelected == 1 && !this.hasCreditCard)) {
      return true
    }
    else {
      return false
    }
  }

  singleRechargeConfirmation(response) {
    const object = this.service.generatePaymentObject(PaymentOrigin.LooseRecharge, this.prePaidConditions, this.looseRechargeForm.controls["amount"].value, this.contractPixFee)
    const modalRef = this.modal.open(PaymentTransactionConfirmationComponent);
    modalRef.componentInstance.response = response;
    modalRef.componentInstance.object = object;
  }

  showPaymentPixModal(idDestination) {
    const modalRef = this.modal.open(PixPaymentModalComponent);
    modalRef.componentInstance.paymentOriginId = PaymentOrigin.LooseRecharge;
    modalRef.componentInstance.idDestination = idDestination;
  }

  looseRecharge() {
    const object = this.service.generatePaymentObject(PaymentOrigin.LooseRecharge, this.prePaidConditions, this.looseRechargeForm.controls["amount"].value, this.contractPixFee)
    this.submitted = true;
    if (this.looseRechargeForm.invalid || typeof this.service.paymentMethodSelected == 'undefined') {
      return;
    }
    else {
      if (object.paymentMethodSelected == PaymentMethodEnum.NoPaymentMethod) {
        this.formObject = object
        this.showRegisterCreditCardModal(true);
      }
      else {
        this.purchaseConfirm(object);
      }
    }
  }

  changeAutoRecharge(conditions: PrepaidConditions) {
    const modalRef = this.modal.open(ChangeAutoRechargeModalComponent);
    modalRef.componentInstance.prePaidConditions = conditions;
    modalRef.componentInstance.rangeList = this.rangeList;
    modalRef.componentInstance.cardIconPath = this.service.flagCardIcon;
    modalRef.componentInstance.event.subscribe((receivedEntry) => {
      this.prePaidConditions.automaticRechargeValue =
        receivedEntry.automaticRechargeValue;
    });
  }

  showUpdateCreditCardModal() {
    const modalRef = this.modal.open(UpdateCreditCardModalComponent);
    modalRef.componentInstance.event.subscribe((receivedEntry) => {
      this.prePaidConditions.creditCard = receivedEntry.cardNumber;
      this.prePaidConditions.creditCardBrandTypeId =
        receivedEntry.creditCardBrandTypeId;
    });
  }

  showRegisterCreditCardModal(isPaymentTransaction = null) {
    const modalRef = this.modal.open(RegisterCreditCardModalComponent);
    modalRef.componentInstance.isPaymentTransaction = isPaymentTransaction;
    modalRef.componentInstance.event.subscribe(() => {
      this.service.selectPaymentMethod(PaymentMethodEnum.CreditCard)
      const object = this.service.generatePaymentObject(PaymentOrigin.LooseRecharge, this.prePaidConditions, this.looseRechargeForm.controls["amount"].value, this.contractPixFee)

      this.purchaseConfirm(object);
    });
  }

  showRechargeFeeModal() {
    const modalRef = this.modal.open(RechargeFeeModalComponent);
    modalRef.componentInstance.rechargeFee = this.prePaidConditions.rechargeFee;
  }

  private disableElementIfBackOfficeUser(): any {
    if (this.userProfileService.isBackOffice() || !this.authService.checkRoles('101175')) {
      const eBtAutoRecharge = document.getElementById('idButtonChangeAutoRecharge') as HTMLElement;
      const eInputAmount = document.getElementById('idInputAmount') as HTMLElement;

      this.renderer.setProperty(eBtAutoRecharge, 'disabled', true);
      this.renderer.setProperty(eInputAmount, 'disabled', true);
      this.renderer.setProperty(eInputAmount, 'content', '');
    }
  }

}
