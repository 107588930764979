import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registered-password',
  templateUrl: './registered-password.component.html',
  styleUrls: ['../../login.component.scss']
})
export class RegisteredPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
