import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from '@env';
import { UserConsultantQueryArgs } from '../models/user-consultant-query.arguments';
import { Dto } from '@models/dto.model';
import { FeedbackDeleteUserDto } from '../models/feedback-delete-user.dto';
import {UserDetailsViewDto} from "@modules/backoffice/user-consultant-view/dtos/user-details-view.dto";

@Injectable({
  providedIn: 'root'
})
export class UserConsultantViewService {

  constructor(private _http: HttpClient) { }

  getRelationshipConsultantsList(args: UserConsultantQueryArgs) {
    return this._http.get<Dto<UserDetailsViewDto[]>>(
      `${environment.apiTMCustomer}/Person/GetRelationshipConsultantsList`,
      { params: args.httpParams }
    );
  }

  getRelationshipConsultantsListCSV(args: UserConsultantQueryArgs) {
    return this._http
    .get(
       `${environment.apiTMCustomer}/Person/GetRelationshipConsultantsListCSV`,
      {
        observe: 'response',
        responseType: 'blob',
        params: args.httpParams,
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),
            filename: response.headers.get('filename'),
          };

          return data;
        }),
      );
  }

  deleteUser(userId: number, protocol: string ) {
    return this._http.delete<Dto<FeedbackDeleteUserDto>>(
      `${environment.apiTMCustomer}/Person/RelationshipConsultant?id=${userId}&protocol=${protocol}`,
    );
  }
}
