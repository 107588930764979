import { Validators, AbstractControl } from '@angular/forms';

export class LicensePlateValidator {
    constructor() {}
    
    static isValidLicensePlate() {
      return (control: AbstractControl): Validators => {

        if(control.value && control.value.length !== 7){
            return { licensePlateInvalid: true };
        } 
        else if(control.value && control.value.length == 7){
            if(
                !isNaN(control.value[0]) ||
                !isNaN(control.value[1]) ||
                !isNaN(control.value[2]) ||
                isNaN(control.value[3]) ||   
                isNaN(control.value[5]) ||  
                isNaN(control.value[6]) 
            ){
                return { licensePlateInvalid: true };
            }
        }

    return null;
   };
 }


}