import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from '@env';
import { IHoliday, IIncludeHolidays } from "../models/holidays.dto";
import { BehaviorSubject } from "rxjs";
import { HolidaysCalendarQueryArguments } from "../models/holidays-calendar-query-arguments";


@Injectable({
  providedIn: 'root'
})
export class HolidaysCalendarService {

  constructor(private _http: HttpClient) {}



  getHolidays(year:  number, args: HolidaysCalendarQueryArguments) {
    return this._http.get<IHoliday[]>(
      environment.apiTMFinancial + `/GetAllByYear?Year=${year}`,
      { params: args.httpParams }
    );
  }

  addHolidayList(object: IIncludeHolidays[]) {
    return this._http.post<IIncludeHolidays[]>(
      environment.apiTMFinancial + `/AddHoliday`,
      object
    );
  }

  validateHolidaysList(object: IIncludeHolidays[]) {
    return this._http.post<IHoliday[]>(
      environment.apiTMFinancial + `/ValidateHoliday`,
      object
    );
  }

  deleteHoliday(id: number, protocol: string) {
    return this._http.put<IHoliday>(
      environment.apiTMFinancial + `/UpdateHoliday`,
      {
        id: id,
        protocol: protocol
      }
    );
  }

}
