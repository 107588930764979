<div class="FileInconsistenciesComponent">
<div class="modal-content">
    <div class="modal-header">
      <div class="box-title"><span>Arquivo com inconsistências</span></div>
      <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss"><span aria-hidden="true">×</span></button>
      <div class="row-box-yellow"  *ngIf="!isLoading" >
        <span class="box-yellow" id="fileName">
          <b>A</b>rquivo "{{ fileName }}"
        </span>
      </div>
    </div>
    <div class="container-loading" *ngIf="isLoading">
      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
    </div>
    <div class="modal-body show modal-edit-vehicle" *ngIf="!isLoading" >
      <div class="bodyTitle" *ngIf="inconsistenciesNumber > 1" id="inconsistenciesNumber">
        Foram encontradas {{ inconsistenciesNumber }} inconsistências nesse upload.<br>
        Você pode fazer o download do arquivo com as inconsistências, ajustar e fazer o upload novamente. 
      </div>
      <div class="bodyTitle" *ngIf="inconsistenciesNumber == 1" id="inconsistenciesNumber">
        Foi encontrada {{ inconsistenciesNumber }} inconsistência nesse upload.<br>
        Você pode fazer o download do arquivo com a inconsistência, ajustar e fazer o upload novamente. 
      </div>
      <div class="InconsistenciesList">
          <div class="InconsistenciesListHead">
            <div class="title" >{{ inconsistenciesNumber }} inconsistência(s) encontrada(s)</div>
            <div class="body">Os veículos que estiverem nessas linhas e colunas não podem ser cadastrados</div>
          </div>
          <div class="InconsistenciesListBody box-collapse">
            <div>
              <button id="collapseFor" class="btn btn-collapse" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">      
                <span>Clique para visualizar</span>
              </button>
            </div>
            <div class="collapse" id="collapse">
             <div class="row collapseRow">              
                  <div class="col-lg-12 d-flex"
                    *ngFor="let item of getInconsistenciesQueryResultDto?.vehicleBatchItemInvalid; let i = index;">
                     Linha {{i+2}} - {{item.description}} 
                  </div>        
             </div>
            </div>
          </div>
          <button class="InconsistenciesListfooter ico-upload-white" (click)="DownloadFileInconsistencies()">
            <p>Download</p> com inconsistências
          </button>
      </div>
    </div>
     <div class="modal-footer modal-footer-gray">
        <button type="button" (click)="dismissModal()" class="btn btn-tertiary mr-4" id="btn-cancel" >Cancelar</button>
        <button  id="button-animated" id="btn-upload" class="btn btn-repom btn-import btn-repom-user" (click)="showImportVehiclesModal()" type="button" >
          <i class="ico ico-upload"></i>
          <div>Fazer novo upload</div>
          <span class="btn-animation"></span>
        </button>  
</div>
</div>