import { ITransactionConciliaton } from './../dtos/transaction-conciliation.dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TransactionConciliationQueryArguments } from '../dtos/transaction-conciliation-query-arguments';
import { Dto } from '@models/dto.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionConciliationService {
  constructor(
    private http: HttpClient,
    private _datePipe: DatePipe,
  ) {}

  public base = environment.apiTMTagTransaction;

  getTransactionConciliation(
    args: TransactionConciliationQueryArguments,
  ): Observable<Dto<ITransactionConciliaton[]>> {
    return this.http
      .get<Dto<ITransactionConciliaton[]>>(
        this.base + `/GetConciliationTransaction`,
        {
          params: args.httpParams,
        },
      )
      .pipe(
        map((response) => {
          let newItems: ITransactionConciliaton[] = [];

          response.data.forEach((item) => {
            let newTransactionConciliaton = {
              ...item,

              registrationDate: this._datePipe.transform(
                item.registrationDate,
                'dd/MM/yyyy HH:mm:ss',
              ),
            };
            newItems.push(newTransactionConciliaton);
          });

          response.data = newItems;
          return response;
        }),
      );
  }

  getTransactionConciliationCsv(args: TransactionConciliationQueryArguments) {
    return this.http
      .get(this.base + `/GetConciliationTransactionCsv`, {
        observe: 'response',
        responseType: 'blob',
        params: args.httpParams,
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),

            filename: response.headers.get('filename'),
          };
          return data;
        }),
      );
  }
}
