<button
  class="button-custom-filters"
  (click)="showFilterContent()"
  [class.active]="idFilter === filtersComponent.activeFilter"
  [class.transaction]="transactionTypeIcon"
  [class.status]="statusIcon"
>
  <span class="label">{{ labelTitle }}</span>

  <p class="button-text">Selecione</p>

  <span class="container-arrow">
    <img class="arrow" src="../../../../assets/img/ico-arrow-right-b.svg" />
  </span>
</button>

<div *ngIf="idFilter === filtersComponent.activeFilter" class="box-area-value">
  <div class="content-extend">
    <h4 class="mb-3">Selecione o(s) tipos(s):</h4>

    <form class="form-group">
      <label class="checkbox-custom" *ngFor="let type of typesData">
        {{ type.name }}

        <input
          type="checkbox"
          (change)="onCheckboxChange(type.id)"
          [checked]="isChecked(type.id)"
        />

        <span class="checkmark"></span>
      </label>
    </form>
  </div>
</div>
