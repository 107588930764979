import { Injectable } from '@angular/core';
import { LocalstorageService } from "@services/localstorage.service";
import { AuthService } from "../auth/auth.service";
import { UserProfileService } from "@services/user-profile.service";

@Injectable({
  providedIn: 'root',
})
export class ContractService {

  constructor(private _localStorage: LocalstorageService,
    private _authService: AuthService,
    private userProfileService: UserProfileService) { }

  setContract(contract: any) {
    if (this.userProfileService.isCustomerOrRelationshipProfile()) {
      this._localStorage.setContractId(contract.id);
      this._localStorage.setContractModalityTypeId(contract.contractModalityTypeId);
      this._localStorage.setContractBlockLevel(contract.contractBlockLevel);
      this._localStorage.setCrediCardCheck(contract.hasCreditCard);
      this._localStorage.setUser(contract);
    }

    this._authService.setContractId(contract.id);
    this._authService.setContractModalityTypeId(contract.contractModalityTypeId);
    this._authService.setContractBlockLevel(contract.contractBlockLevel);
  }

  getContractId(): number {
    const contractId: string = this.userProfileService.isCustomerOrRelationshipProfile() ?
      this._localStorage.getContractId() : this._authService.getContractId();

    return Number(contractId);
  }

  getContractModalityTypeId(): number {
    const contractModalityTypeId: string = this.userProfileService.isCustomerOrRelationshipProfile() ?
      this._localStorage.getContractModalityTypeId() : this._authService.getContractModalityTypeId();

    return Number(contractModalityTypeId);
  }


  removeContract() {
    this._localStorage.removeContractId();
    this._localStorage.removeContractModalityTypeId();
    this._localStorage.removeContractBlockLevel();
    this._localStorage.removeCreditcard();

    this._authService.removeContractId();
    this._authService.removeContractModalityTypeId();
    this._authService.removeContractBlockLevel();
  }

  getContractBlockLevel(): number {
    const contractBlockLevel: string = this.userProfileService.isCustomerOrRelationshipProfile() ?
      this._localStorage.getContractBlockLevel() : this._authService.getContractBlockLevel();

    return Number(contractBlockLevel);
  }

  setContractBlockLevel(contractBlockLevel: any): void {
    this._localStorage.setContractBlockLevel(contractBlockLevel);
  }



  getCrediCardCheck(): boolean | undefined {
    const hasCreditCard: string = this._localStorage.getHasCreditCard();
    return hasCreditCard ? Boolean(hasCreditCard) : undefined;
  }

  setCrediCardCheck(hasCreditCard: any): void {
    this._localStorage.setCrediCardCheck(hasCreditCard);
  }

  setDualTag(isDualTag: boolean): void {
    this._localStorage.setDualTag(isDualTag);
  }
  isDualTag(): boolean {
    return this._localStorage.getDualTag();
  }
}
