import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@models/old/user.model';

@Injectable({
    providedIn: 'root'
})
export class FirstAccessService {

    private base = environment.apiTMCustomer

    private httpOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*'
          })
      };

      constructor(protected http: HttpClient) { }

      public async getUser(documentNumber: string): Promise<Object> {
        try {
          let apiUrl = `${this.base}/Person/GetPersonByDocumentNumber?documentNumber=${documentNumber}`;
            var result = await this.http.get<User>(apiUrl).toPromise();
            return result;
          } catch (e) {
            if(e.status == "404")
              throw("Documento não cadastrado");

            if(e.error === undefined)
              throw("Ocorreu um erro ao salvar o veículo");
            else
              throw(e);

            return null;
          }

    }
    public firstAccess(documentNumber: number){
        return this.http.get(`${this.base}/FirstAccess/IsPersonFirstAccess?DocumentNumber=${documentNumber}`);
    }


}
