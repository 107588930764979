<!-- **************** MYCOMPANY | Start **************** -->
<div class="Dashboard container-fluid">
  <div class="row">
    <div class="col-12">
      <app-page-title [title]="'/ Contrato'"></app-page-title>
    </div>

    <div class="col-12" *ngIf="isLoading">
      <div class="container-loading">
        <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
      </div>
    </div>

    <div class="col-7 margintop22" *ngIf="!isLoading">
      <div class="row blockTitle">
        <div class="col-sm-auto"><img src="assets/img/company.svg"></div>
        <div class="contractPageTitle col">
          <p data-e2e="company-name" class="title" id="companyName">{{ legalPerson?.companyName }}</p>
          <p data-e2e="company-doc" class="subtitle" id="documentNumber"
            *ngIf="legalPerson?.documentNumber.length == 11">
            {{ legalPerson?.documentNumber | mask: '000.000.000-00' }}
          </p>
          <p data-e2e="company-doc" class="subtitle" id="documentNumber"
            *ngIf="legalPerson?.documentNumber.length == 14">
            {{ legalPerson?.documentNumber | mask: '00.000.000/0000-00' }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-5 margintop22 actionButtons" *ngIf="!isLoading">

      <app-borderless-button
        [description]="'Endereços para entrega'"
        [id-html]="'AddressButton'"
        [data-e2e]="'open-delivery-addresses-modal-btn'"
        [classIconLeft]="'ico-address'"
        [buttonColor]="'secundary'"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="showDeliveryAddressesModal()"
      >
      </app-borderless-button>

      <app-borderless-button
        *accessControl="101219"
        [description]="'Incluir usuário'"
        [id-html]="'AddUserButton'"
        [data-e2e]="'open-add-user-modal-btn'"
        [classIconLeft]="'ico-user-add'"
        [buttonColor]="'primary'"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="showAddUserModal()"
      >
      </app-borderless-button>

    </div>

    <div class="grid">

      <div class="Dashboard_col_1" *ngIf="!isLoading">
        <div class="container-repom">
          <div class="box-title">
            <span data-e2e="register-title">Cadastro</span>
          </div>
          <div class="registrationTitle" data-e2e="company-subtitle">Dados da empresa</div>
          <div class="row">
            <div class="col-6 company-description">
              <p data-e2e="company-fantasy-label">Nome fantasia:</p>
              <p data-e2e="company-municipal-subscription-label">Inscrição Municipal:</p>
              <p data-e2e="company-state-subscription-label">Inscrição Estadual:</p>
              <p data-e2e="company-rntrc-label">RNTRC:</p>
            </div>
            <div class="col-6">
              <p data-e2e="company-fantasy-data" id="tradingName">{{ legalPerson?.tradingName }}</p>
              <p data-e2e="company-municipal-subscription-data" id="stateRegister">{{ legalPerson?.stateRegister }}</p>
              <p data-e2e="company-state-subscription-data" id="corporateTaxRegister">{{
                legalPerson?.corporateTaxRegister }}</p>
              <p data-e2e="company-rntrc-data" id="rntrc">{{ legalPerson?.rntrc }}</p>
            </div>
          </div>
          <hr />
          <div>
            <app-contract-summary [commercialCondition]="commercialCondition"
              [principalPaymentMethodType]="principalPaymentMethodType"></app-contract-summary>
          </div>
        </div>
      </div>

      <div class="Dashboard_col_2" *ngIf="!isLoading">
        <app-manager-contract [contractUsers]="contractUsers"></app-manager-contract>
      </div>

    </div>

  </div>
</div>
