import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@services/notify.service';
import { UserConsultantDeleteConst } from '../../constants/const-user-consultant-delete';
import { finalize } from 'rxjs';
import { Dto } from '@models/dto.model';
import {UserDetailsViewDto} from "@modules/backoffice/user-consultant-view/dtos/user-details-view.dto";
import {FeedbackDeleteUserDto} from "@modules/backoffice/user-consultant-view/models/feedback-delete-user.dto";
import {
  UserConsultantViewService
} from "@modules/backoffice/user-consultant-view/services/user-consultant-view.service";
import {MasksService} from "@services/masks.service";
import {EStepModal} from "../../../../../shared/enums/enum-step-modal";

@Component({
  selector: 'app-user-consultant-delete-modal',
  templateUrl: './user-consultant-delete-modal.component.html',
  styleUrls: ['./user-consultant-delete-modal.component.scss']
})
export class UserConsultantDeleteModalComponent implements OnInit{
  @Input() userDetails: UserDetailsViewDto;

  @Output() eventHandlePage: EventEmitter<void>;

  protected eStepModal = EStepModal;
  protected userDeleteForm: FormGroup;
  private isValidatatorsApplyed: boolean = false;

  step: EStepModal;
  isLoading: boolean;
  feedbackDto: FeedbackDeleteUserDto;
  TITLE: string;
  SUBTITLE_CONFIRM: string;
  SUBTITLE_FEEDBACK: string;
  TEXT_CONFIRM: string;
  TEXT_FEEDBACK: string;
  IMG_PATH: string;
  isDisabledBtnConfirm: boolean = true;

  constructor(
    private _modalService: NgbModal,
    private _masksService: MasksService,
    private _userConsultantViewService: UserConsultantViewService,
    private _notifyService : NotifyService,
  ) {
    this.settingsInitClass();
  }

  ngOnInit(): void {
    this.observeReactiveForm();
  }

  private settingsInitClass(): void {
    this.step = EStepModal.CONFIRM;

    this.TITLE = UserConsultantDeleteConst.TITLE;
    this.SUBTITLE_CONFIRM = UserConsultantDeleteConst.SUBTITLE_CONFIRM;
    this.SUBTITLE_FEEDBACK = UserConsultantDeleteConst.SUBTITLE_FEEDBACK;
    this.TEXT_CONFIRM = UserConsultantDeleteConst.TEXT_CONFIRM;
    this.TEXT_FEEDBACK = UserConsultantDeleteConst.TEXT_FEEDBACK;
    this.IMG_PATH = UserConsultantDeleteConst.IMG_PATH;

    this.feedbackDto = new FeedbackDeleteUserDto();
    this.eventHandlePage = new EventEmitter();

    this.userDeleteForm = new FormGroup({
      protocol: new FormControl('', [])
    });
  }

  private observeReactiveForm(): void {
    this.userDeleteForm.valueChanges.subscribe(() => {
      if (!this.isValidatatorsApplyed) {
        this.isDisabledBtnConfirm = false;
        this.isValidatatorsApplyed = !this.isValidatatorsApplyed;
        this.setValidatorsFormReactive();
      }
    });
  }

  setValidatorsFormReactive(): void {
    this.userDeleteForm.controls.protocol.setValidators([Validators.required, Validators.pattern('^\\S*$')]);
    this.userDeleteForm.controls.protocol.updateValueAndValidity();
  }

  getPhoneMask(phone: string): string {
    return this._masksService.getPhoneMask(phone);
  }

  dismissModal(): void {
    this._modalService.dismissAll();
  }

  confirmDeleteUser(): void {
    this.isLoading = true;

    if (this.userDeleteForm.valid) {
      this.deleteUser();
    }
    else {
      this._notifyService.showError('Atenção', 'Dados não válidos!');
    }
  }

  private deleteUser(): void {
    this._userConsultantViewService
    .deleteUser(this.userDetails.userId, this.userDeleteForm.controls.protocol.value)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe({
      next: (success) => {
        if (success.success) {
          this.assignResponseAttributes(success);
          this.step = EStepModal.FEEDBACK;
          this.eventHandlePage.emit();
        }
      },
      error: (error) => {
        console.error(error);
        this.dismissModal();
        return;
      }
    });
  }

  assignResponseAttributes(success: Dto<FeedbackDeleteUserDto>): void {
    this.feedbackDto.protocol = success.data.protocol;
    this.feedbackDto.registrationDate = success.data.registrationDate;
    this.feedbackDto.registrationUsername = success.data.registrationUsername;
  }

}
