<div class="BillRecompositionModalComponent">
  <div class="modal-header">
    <h4 data-e2e="modal-title" class="modal-title" translate>
      Manual Payment Recomposition
    </h4>
    <a
      data-e2e="close-modal-top-btn"
      class="btn-close"
      (click)="dismissModal()"
    >
      <img src="assets/svg/close.svg" />
    </a>
  </div>

  <div class="modal-body mt-4">
    <div class="container-loading" *ngIf="isLoading">
      <app-loader
        [isLoading]="isLoading"
        message="{{ 'Loading...' | translate }}"
      ></app-loader>
    </div>

    <div class="recomposition-body" *ngIf="!isLoading" [hidden]="isSuccess">
      <div class="row mt-4">
        <div class="col-lg-12">
          <h3 class="modal-subtitle">
            Você tem certeza de que deseja realizar a baixa de pagamento manual
            desta fatura?
          </h3>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="container">
            <table class="table-recomposition">
              <thead class="table-recomposition-head">
                <tr>
                  <td>Fatura</td>
                  <td>Valor total da fatura</td>
                  <td>Data de fechamento</td>
                  <td>Data de vencimento</td>
                  <td>Período de apurado</td>
                </tr>
              </thead>
              <tbody class="table-recomposition-body">
                <tr>
                  <td>{{ billHeader?.id }}</td>
                  <td>
                    {{
                      billHeader?.billTotalValue
                        | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
                    }}
                  </td>
                  <td>{{ billHeader?.closingDate | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ billHeader?.dueDate | date: 'dd/MM/yyyy' }}</td>
                  <td>
                    Início: {{ billHeader?.openingDate | date: 'dd/MM/yyyy' }}
                    <br />
                    Fim: {{ billHeader?.closingDate | date: 'dd/MM/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-12">
          <form [formGroup]="recompositionForm" novalidate>
            <div class="form-group select-table">
              <label for="protocolInput" class="input-label"
                >Informe o protocolo relacionado à solicitação
                <span>*</span></label
              >
              <input
                id="protocolInput"
                class="input-recomposition"
                formControlName="protocolInput"
                type="text"
                [class.is-invalid]="
                  recompositionForm.controls.protocolInput.invalid &&
                  recompositionForm.controls.protocolInput.touched
                "
              />
            </div>
            <app-vmessage
              data-e2e="protocol-error"
              *ngIf="
                recompositionForm.controls.protocolInput.invalid &&
                recompositionForm.controls.protocolInput.touched
              "
              errorMessage="{{
                'Protocol is required and numeric' | translate
              }}"
            >
            </app-vmessage>
            <div class="form-group select-table mt-4">
              <label for="reasonInput" class="input-label"
                >Informe o motivo da baixa de pagamento manual
                <span>*</span></label
              >
              <input
                id="reasonInput"
                class="input-recomposition"
                formControlName="reasonInput"
                type="text"
                [class.is-invalid]="
                  recompositionForm.controls.reasonInput.invalid &&
                  recompositionForm.controls.reasonInput.touched
                "
              />
            </div>
            <app-vmessage
              data-e2e="reason-error"
              *ngIf="
                recompositionForm.controls.reasonInput.invalid &&
                recompositionForm.controls.reasonInput.touched
              "
              errorMessage="{{ 'Reason is required' | translate }}"
            >
            </app-vmessage>
          </form>
        </div>
      </div>
    </div>

    <div class="recomposition-success" *ngIf="isSuccess">
      <img src="assets/svg/success.svg" class="img-success m-4" />
      <p class="title-success">
        A baixa manual da fatura
        <span class="mark-success">{{ billHeader?.id }}</span> foi realizada com
        sucesso!
      </p>
    </div>
  </div>
  <div class="modal-footer modal-footer-gray">
    <div class="footer-recomposition" [hidden]="isSuccess">
      <button
        data-e2e="return-btn"
        type="button"
        class="btn btn-tertiary"
        (click)="dismissModal()"
        id="btn-return"
      >
        CANCELAR
      </button>

      <button
        (click)="finalizeRecomposition(billHeader?.id)"
        data-e2e="confirm-btn"
        type="button"
        class="btn btn-secondary"
        id="confirmBtn"
        [disabled]="isLoading || this.recompositionForm.invalid"
      >
        CONFIRMAR
      </button>
    </div>

    <div class="footer-recomposition-success" *ngIf="isSuccess">
      <button
        data-e2e="close-btn"
        type="button"
        class="btn btn-secondary"
        (click)="dismissModal()"
        id="btn-close"
      >
        FECHAR
      </button>
    </div>
  </div>
</div>
