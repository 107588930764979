<main class="CardInsertHolidayComponent">
  <div class="div-link">
    <app-borderless-button
    [description]="'Voltar para Calendário de feriados'"
    [classIconLeft]="'ico-arrow-backward'"
    [id-html]="'link-page-holidays-calendar'"
    [data-e2e]="'link-page-holidays-calendar'"
    [buttonColor]="'primary'"
    (emitterEvtClick)="backToCalendar()">
  </app-borderless-button>
  </div>

   <section class="table-card">

    <app-header-form-insert-holiday>
    </app-header-form-insert-holiday>

    <app-form-insert-holiday>
    </app-form-insert-holiday>
  </section>
</main>

