import { Injectable } from '@angular/core';
import { Userpilot } from 'userpilot';
import { UserInfo } from '../../models/userInfo.dto';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserPilotService {

  constructor(private datePipe : DatePipe) { }

  postData(user : UserInfo, userId : Number) {
    const date = new Date();
    Userpilot.identify(userId.toString(), {name: user.fullName, email: user.email, action_date: date.toISOString()});
  }
}
