import {Injectable, Pipe, PipeTransform} from '@angular/core';
import { Bill } from '@models/old/financial/bill/bill-list/bill.dto';

@Pipe({
  name: 'billStatusFilter'
})
@Injectable()
export class BillFilterPipe implements PipeTransform {
  transform(bills: Bill[], args: any[]): any {
    return bills.filter((bills, idx) => args.includes(bills.status));
    // return bills.filter(bills => bills.Status.toLowerCase().indexOf(args.toLowerCase()) !== -1);
  }
}