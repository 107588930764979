import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormSuccessHandleService {
  constructor() {}

  smoothSuccess() {
    setTimeout(() => {
      var element = document.body;
      element.scrollIntoView();
    }, 300);
  }
}
