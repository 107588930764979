import { HttpParams } from '@angular/common/http';
import { ModalityType } from './contract.model';

export class GetContractsBySearchFieldParams {
  page: number;
  pageSize: number;
  sortOrderDesc: boolean;
  sortField: string;
  searchType: number;
  searchField: string;
  contractModalityTypeId?: ModalityType;

  constructor() {
    this.page = 1;
    this.pageSize = 10;
    this.sortOrderDesc = false;
    this.sortField = 'Description';
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.sortOrderDesc ? '1' : '0')
      .append('sortField', this.sortField)
      .append('searchType', this.searchType ? this.searchType.toString() : '')
      .append('searchField', this.searchField);

    if (!!this.contractModalityTypeId) {
      httpAppend = httpAppend.append('contractModalityTypeId', this.contractModalityTypeId.toString());
    }

    return httpAppend;
  }

}
