import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetVehiclesAvailableArguments } from '../../../hierarchy/dtos/getVehiclesAvailableArguments.dto';
import { GetVehiclesAvailableQueryResult } from '../../../hierarchy/dtos/getVehiclesAvailableQueryResult.dto';
import { NotifyService } from '@services/notify.service';
import { HierarchyService } from '../../../hierarchy/services/hierarchy.service';
import { EventEmitterService } from '@services/event-emitter.service';

@Component({
  selector: 'app-manage-vehicles-hierarchy',
  templateUrl: './manage-vehicles-hierarchy.component.html',
  styleUrls: ['./manage-vehicles-hierarchy.component.scss']
})

export class ManageVehiclesHierarchyComponent implements OnInit {
  @Input() contractvehicleselected: any;
  @Input() closeBtnName: any;
  @Input() hierarchyId: any;
  @Input() openHierarchy: any = null;
  @Input() hierarchyLevel: any;


  public vehiclesearch: string;
  filterDebounce: Subject<GetVehiclesAvailableArguments> = new Subject<GetVehiclesAvailableArguments>();
  listVehicles: GetVehiclesAvailableQueryResult;
  getVehiclesAvailableArguments: GetVehiclesAvailableArguments;
 // public listAllVehicleDisfellowshipped:GetVehiclesAvailableQueryResult;
  public breadCrumb: string;
  public description_PT: string;
  public vehicleHierarchyForm = [];
  public success;
  public DisableButton;
  public teste = 1;

  isLoading: boolean;

  constructor(
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private notifyService: NotifyService,
    private formBuilder: FormBuilder,
    private hierarchyService: HierarchyService
    ) {

      this.getVehiclesAvailableArguments = new GetVehiclesAvailableArguments();
      this.getVehiclesAvailableArguments.page = 1;
      this.getVehiclesAvailableArguments.pageSize = 20;
      this.getVehiclesAvailableArguments.desc = false;
      this.getVehiclesAvailableArguments.association = 1;
      this.getVehiclesAvailableArguments.HierarchyId = this.hierarchyId;
      this.getVehiclesAvailableArguments.orderBy = 'LicensePlate'
      this.getVehiclesAvailableArguments.search = '';
      this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getListVehicle(filter));
    }

  ngOnInit(): void {
    this.GetHierarchyList();
    this.resetForm(this.getVehiclesAvailableArguments.association);
  }

  getListVehicle(args: GetVehiclesAvailableArguments) {
    this.isLoading = true;
    this.hierarchyService
      .getListVehicleAvailable(args)
      .pipe(
        finalize(() => { this.isLoading = false; })
      )
      .subscribe(
        (success) => {
           this.listVehicles = success;
        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }

  onPaginationChange(page: number) {
    this.getVehiclesAvailableArguments.page = page;
    this.getListVehicle(this.getVehiclesAvailableArguments);
  }

  onBuscarClick(event: any) {
    this.getListVehicle(this.getVehiclesAvailableArguments);
  }

  onFullNameKeyup($event: any) {
    this.getVehiclesAvailableArguments.page = 1;
    this.getVehiclesAvailableArguments.search = $event.target.value;
    this.filterDebounce.next(this.getVehiclesAvailableArguments);
  }

  VerifyAssociate(associated){
    if(associated == true){
      return true
    }
    else{
      return false
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  resetForm(association){
    this.getVehiclesAvailableArguments.search = '';
    this.getVehiclesAvailableArguments.page = 1;
    this.getVehiclesAvailableArguments.pageSize = 20;
    if(association == 1){
      this.getVehiclesAvailableArguments.association = 1;
      this.getVehiclesAvailableArguments.HierarchyId = this.hierarchyId;
    }
    else if(association == 0){
      this.getVehiclesAvailableArguments.association = 0;
      this.getVehiclesAvailableArguments.HierarchyId = null;
    }
    else{
      this.getVehiclesAvailableArguments.association = 2;
      this.getVehiclesAvailableArguments.HierarchyId = this.hierarchyId;
    }

    this.DisableButton = true
    this.vehicleHierarchyForm[0] = this.formBuilder.group({
      licensePlate: new FormArray([]),
      hierarchyId: this.hierarchyId,
    })

    this.vehicleHierarchyForm[1] = this.formBuilder.group({
      licensePlate: new FormArray([]),
      hierarchyId: 0,
    })

    this.listVehicles = {
      total: 0,
      vehiclesAvailable: []
    }

   this.getListVehicle(this.getVehiclesAvailableArguments);
  }

  GetHierarchyList(){
    this.isLoading = true;
    this.hierarchyService
    .GetHierarchyBreadCrumbList()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success: any) => {
        this.description_PT = success.data.filter(item => item.id == this.hierarchyId).map(item => item.description_PT).toString()
        let breadCrumb = success.data.filter(item => item.id == this.hierarchyId).map(item => item.breadCrumb).toString()
        this.breadCrumb =  breadCrumb.replace(this.description_PT,'')
      },
      (error) => {
        console.error("getFromServerError", error);
        return;
      }
    );
}

  SaveVehicle(){
    let arrayVehiclelength: number  = 0;
    if(this.vehicleHierarchyForm[0].value.licensePlate.length > 0){
      arrayVehiclelength++;
    }

    if(this.vehicleHierarchyForm[1].value.licensePlate.length > 0){
      arrayVehiclelength++;
    }

    if(arrayVehiclelength == 0){
      this.notifyService.showInfo('Atenção!', 'Nenhuma alteração realizada!');
    }
    else{
      for(var count:number = 0;count<2;count++){
        if(this.vehicleHierarchyForm[count].value.licensePlate.length > 0){
          this.saveVehicleByLicensePlate(count,arrayVehiclelength)
        }
      }
    }
  }

  saveVehicleByLicensePlate(count,arrayVehiclelength){
    this.isLoading = true;
    this.hierarchyService
      .saveVehicleByLicensePlate(this.vehicleHierarchyForm[count].value)
      .pipe(
        finalize(() => { this.isLoading = false; })
      )
      .subscribe(
        (success) => {
          if(arrayVehiclelength-1 <= count){
            this.notifyService.showSuccess('Atenção!', 'Alteração de associação de veículos realizada com sucesso!');
            this.resetForm(this.getVehiclesAvailableArguments.association);
          }
        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }

  onCheckChange(event,associated) {
    const formArraytoAssociate: FormArray = this.vehicleHierarchyForm[0].get('licensePlate') as FormArray;
    const formArraytoDisassociate: FormArray = this.vehicleHierarchyForm[1].get('licensePlate') as FormArray;
    //associar veículo
    if(!associated && event.target.checked){
      formArraytoAssociate.push(new FormControl(event.target.value));
      }
    // desassociar veículo
    else if(associated && !event.target.checked){
      formArraytoDisassociate.push(new FormControl(event.target.value));
      }
     //retirar do array para associação
    else if(!associated && !event.target.checked){
        let i: number = 0;
        formArraytoAssociate.controls.forEach((ctrl: FormControl) => {
          if(ctrl.value == event.target.value) {
            formArraytoAssociate.removeAt(i);
            return;
          }
          i++;
        });
      }
     //retirar do array para desassociação
      else if(associated && event.target.checked){
           let i: number = 0;
           formArraytoDisassociate.controls.forEach((ctrl: FormControl) => {
             if(ctrl.value == event.target.value) {
               formArraytoDisassociate.removeAt(i);
               return;
             }
             i++;
           });
      }
   // desabilita botão de salvar se não houver mudança.
   if(this.vehicleHierarchyForm[0].value.licensePlate.length > 0 || this.vehicleHierarchyForm[1].value.licensePlate.length > 0 ){
      this.DisableButton = false;
   }
   else{
      this.DisableButton = true;
   }
 }

}
