import { NgModule } from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {SelectProfileRoutingModule} from "@modules/commom/select-profile/routes/select-profile-routing.module";
import {SelectProfileComponent} from "@modules/commom/select-profile/select-profile.component";
import {SharedModule} from "../../../shared/shared.module";
import {FooterModule} from "@modules/commom/footer/footer.module";

@NgModule({
    imports: [
        SelectProfileRoutingModule,
        MatCardModule,
        MatButtonModule,
        BrowserModule,
        CommonModule,
        SharedModule,
        FooterModule
    ],
  declarations: [
    SelectProfileComponent
  ]
})

export class SelectProfileModule {}
