
<div class="row row-title">
  <div class="col-lg-4">
  </div>
  <div class="col-lg-4 desc">
    <p class="title">Condições financeiras</p>
  </div>
  <div class="col-lg-4">
  </div>
</div>
<div class="row">
  <div class="col-lg-6" *ngIf="commercialCondition.creditLimit">
    <div class="box-gray min-height-135">
      <i class="ico ico-alert" placement="right"
        ngbTooltip="Limite de crédito aprovado para a utilização do serviço."></i>
      <div class="row">
        <div class="col-lg-12 p-0">
          <h4 class="align-left">LIMITE DE CRÉDITO</h4>
          <p class="price align-left">
            <b id="creditLimit">{{commercialCondition.creditLimit | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}</b></p>
            <p>
              &nbsp;
            </p>
          </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="box-gray min-height-135">
      <i class="ico ico-alert" placement="top"
        ngbTooltip="Parâmetros definidos para fechamento e apuração de cobrança e disponibilização do demonstrativo da fatura."></i>
      <div class="row">
        <div class="col-lg-12 align-left">
          <h4 data-e2e="due-date-title">CORTE DE FATURAMENTO</h4>


          <div [ngSwitch]="commercialCondition.contractBillingCuttingTypeId">
            <p *ngSwitchCase="1" data-e2e="weekly-desc">Semanal</p>
            <p *ngSwitchCase="2" data-e2e="biweekly-desc">Quinzenal</p>
            <p *ngSwitchCase="3" data-e2e="monthly-desc">Mensal - {{ contractBillingCuttingCycle === 'Último dia do mês' ? contractBillingCuttingCycle : 'Todo dia ' + contractBillingCuttingCycle }}</p>
            <p *ngSwitchCase="4" data-e2e="individual-desc">Diferenciado</p>
            <p *ngSwitchCase="5" data-e2e="custom-desc">Intervalo Padronizado</p>
          </div>

          <!-- Semanal = 1,
               Quinzenal = 2,
               Mensal = 3,
               Diferenciado = 4 -->

      <div *ngIf="commercialCondition.contractBillingCuttingTypeId == cuttingTypeId.WEEKLY"
            [ngSwitch]="commercialCondition.contractBillingCuttingWeekDayTypeId">
            <p *ngSwitchCase="1">Domingo</p>
            <p *ngSwitchCase="2">Segunda-feira</p>
            <p *ngSwitchCase="3">Terça-feira</p>
            <p *ngSwitchCase="4">Quarta-feira</p>
            <p *ngSwitchCase="5">Quinta-feira</p>
            <p *ngSwitchCase="6">Sexta-feira</p>
            <p *ngSwitchCase="7">Sabado</p>
          </div>

          <div *ngIf="commercialCondition.contractBillingCuttingTypeId == cuttingTypeId.BIWEEKLY">
            <p>De 1 a 15 </p>
            <p>De 16 até o último dia do mês</p>
          </div>

          <div *ngIf="commercialCondition.contractBillingCuttingTypeId == cuttingTypeId.INDIVIDUAL">
            <p>A cada {{commercialCondition.billingCuttingDays}} dias</p>
          </div>

          <div *ngIf="commercialCondition.contractBillingCuttingTypeId == cuttingTypeId.CUSTOM"
            [ngSwitch]="commercialCondition.contractBillingCuttingWeekDayTypeId">
            <p *ngSwitchCase="1">Domingo</p>
            <p *ngSwitchCase="2">Segunda-feira</p>
            <p *ngSwitchCase="3">Terça-feira</p>
            <p *ngSwitchCase="4">Quarta-feira</p>
            <p *ngSwitchCase="5">Quinta-feira</p>
            <p *ngSwitchCase="6">Sexta-feira</p>
            <p *ngSwitchCase="7">Sabado</p>

            <p>De 1 a 7</p>
            <p>De 8 a 14</p>
            <p>De 15 a 21</p>
            <p>De 22 até o final do mês</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="box-gray min-height-135 h-165">
      <i class="ico ico-alert" placement="top"
        ngbTooltip="Quantidade de dias desde a apuração até o vencimento da fatura."></i>
      <div class="row">
        <div class="col-lg-12 p-0 align-left">
          <h4>PRAZO DE PAGAMENTO</h4>
          <p id="paymentTerm">
            {{commercialCondition.paymentTerm}} dia{{commercialCondition.paymentTerm > 1 ? 's úteis': ' útil'}}
          </p>
        </div>
      </div>
      <hr class="hr"/>
      <div class="row pt-2 justify-content-between">
        <div class="col p-0">
          <p class="d-flex align-items-center justify-content-start w-100">Juros moratórios <i class="ico ico-alert test" placement="top"
            ngbTooltip="Em caso de atraso no pagamento, juros mensais serão adicionados
                  ao valor devido, aplicados desde a data da mora até o dia em que o pagamento for realizado."></i>
          </p>
           <p class="text-left"><b> {{commercialCondition.contractFee.DefaultInterest.value | currency: ' ':'symbol':'0.2-2':'pt-BR'}}% ao mês</b></p>
        </div>
         <div class="col p-0">
            <p class="d-flex align-items-center justify-content-end w-100">Multa moratória <i class="ico ico-alert test" placement="top"
              ngbTooltip="Em caso de atraso no pagamento, uma multa mensal será aplicada ao
              valor devido, contada a partir da data da mora até o dia em que o pagamento for realizado."></i>
            </p>
            <p class="text-right" ><b> {{commercialCondition.contractFee.LateFee.value | currency: ' ':'symbol':'0.2-2':'pt-BR'}}% ao mês</b></p>
         </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6" *ngIf="commercialCondition.tollVoucherAnticipation">
    <div class="box-gray min-height-135 h-165">
      <i class="ico ico-alert" placement="top"
        ngbTooltip="Serviço cobrado sobre o valor de antecipação de vale-pedágio"></i>
      <div class="row">
        <!-- <div class="col-lg-1"></div> -->
        <div class="col-lg-12 p-0 align-left">
          <h4>ANTECIPAÇÃO DE VALE-PEDÁGIO</h4>
          <p id="tollVoucherAntecipationService">
            Serviço de antecipação:
             <span *ngIf="commercialCondition?.tollVoucherAntecipationService > 0">{{commercialCondition?.tollVoucherAntecipationService | currency: ' ':'symbol':'0.2-2':'pt-BR'}}%</span>
             <span *ngIf="!commercialCondition?.tollVoucherAntecipationService || commercialCondition?.tollVoucherAntecipationService == 0">Bonificado</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
<div class="col-lg-12" *ngIf="commercialCondition.marketingPlan">
  <div class="box-gray">


    <div class="col-lg-12 p-0 align-left">
      <i class="ico ico-alert" placement="left"
        ngbTooltip="Meios de pagamento cadastrados. Eles serão utilizados para cobrança de valores referentes aos serviços e utilização da tag."></i>
      <h3>FORMA DE PAGAMENTO</h3>
    </div>
    <ng-template ngFor let-paymentMethod [ngForOf]="commercialCondition.customer.person.paymentMethodCollection"
      let-i="index">
      <!-- AccountDebit = 1,
    BankSlip = 2,
    CreditCard = 3 -->
      <div class="col-lg-12" *ngIf="paymentMethod.paymentMethodTypeId == 1">
        <div class="align-rl">
          <div class="row">
            <div class="col-lg-12 p-0 align-left">
              <div class="box-white">
                <div class="featured" *ngIf="paymentMethod.principalFlg">
                  <i class="ico ico-star"> Principal</i>
                </div>
                <div class="row">
                  <div class="col-lg-12 box-w-title">
                    Débito em conta
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 align-right">Banco</div>
                  <div class="col-lg-6 align-left" id="bankNumber">{{paymentMethod.bankNumber}}</div>
                </div>
                <div class="row">
                  <div class="col-lg-6 align-right">Agência</div>
                  <div class="col-lg-6 align-left" id="agencyNumber">{{paymentMethod.agencyNumber}}</div>
                </div>
                <div class="row">
                  <div class="col-lg-6 align-right">Conta</div>
                  <div class="col-lg-6 align-left" id="accountNumber">{{paymentMethod.accountNumber}}</div>
                </div>
                <div class="row">
                  <div class="col-lg-6 align-right">Dígito da Conta</div>
                  <div class="col-lg-6 align-left" id="accountDigit">{{paymentMethod.accountDigit}}</div>
                </div>
                <div class="row">
                  <div class="col-lg-6 align-right">CPF ou CNPJ do titular da conta</div>
                  <div class="col-lg-6 align-left" id="documentNumber">{{commercialCondition?.customer?.person?.documentNumber}}</div>
                </div>
                <div class="row">
                  <div class="col-lg-6 align-right">Nome do titular da conta</div>
                  <div class="col-lg-6 align-left" id="fullName">{{commercialCondition?.customer?.person?.fullName}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12" *ngIf="paymentMethod.paymentMethodTypeId == 2">
        <div>
          <div class="box-white">
            <div class="row">
              <div class="col-lg-12 p-0 align-left">
                <p><b>Boleto </b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
</div>
