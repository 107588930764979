import { HttpParams } from '@angular/common/http';


export class BillVehiclesTotalQueryArguments {
  id: number;
  page: number;
  pageSize: number;
  sortField: string;
  desc: boolean;
  HierarchyId: number;
  search: string;

  get httpParams() {
    let httpParams = new HttpParams()
      .set('BillId', this.id?.toString())
      .set('Page', this.page?.toString())
      .append('SortOrder', this.desc ? '1' : '0');

      if (this.pageSize)
      httpParams = httpParams.set('PageSize', this.pageSize?.toString());

      if (this.HierarchyId)
      httpParams = httpParams.set('HierarchyId', this.HierarchyId?.toString());

      if (this.search) {
        httpParams = httpParams.set('search', this.search);
      }

    if (this.sortField) {
      httpParams = httpParams.set('SortField', this.sortField);
    }

    return httpParams;
  }
}


