import { Component, OnInit } from '@angular/core';
import { ChangePasswordModalValidator } from '../../services/change-password-modal-validator.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss']
})
export class PasswordRequirementsComponent implements OnInit {

  uppercaseValidation:boolean;
  lowercaseValidation:boolean;
  numberValidation:boolean;
  especialCaracterValidation:boolean;
  minLengthValidation:boolean;
  $subs: Array<Subscription> = [];

  constructor() { 
    this.getCaractersValidators()
  }

  getCaractersValidators(){
    this.$subs[0] = ChangePasswordModalValidator.uppercaseValidation$
      .subscribe((data) => 
         this.uppercaseValidation = data
      )

    this.$subs[1] = ChangePasswordModalValidator.lowercaseValidation$
      .subscribe((data) => 
         this.lowercaseValidation = data
      )

    this.$subs[2] = ChangePasswordModalValidator.numberValidation$
      .subscribe((data) => 
         this.numberValidation = data
      )

    this.$subs[3] = ChangePasswordModalValidator.especialCaracterValidation$
      .subscribe((data) => 
         this.especialCaracterValidation = data
      )

    this.$subs[4] = ChangePasswordModalValidator.minLengthValidation$
      .subscribe((data) => 
         this.minLengthValidation = data
      )
  }

  ngOnDestroy() {
    this.$subs.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnInit(): void {
  }

}
