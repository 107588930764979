import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IHoliday } from '../../models/holidays.dto';
import { HolidaysCalendarQueryArguments } from '../../models/holidays-calendar-query-arguments';
import { finalize } from 'rxjs';
import { HolidaysCalendarService } from '../../services/holidays-calendar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcludeHolidayModalComponent } from '../exclude-holiday-modal/exclude-holiday-modal.component';


@Component({
  selector: 'app-table-holidays',
  templateUrl: './table-holidays.component.html',
  styleUrl: './table-holidays.component.scss'
})
export class TableComponent implements OnChanges, OnInit  {
  @Input() year! : number
  holidays : IHoliday[]
  message: string = 'Loading...';
  isLoading: boolean = false;
  isOpen: boolean;
  noFiles: boolean = false
  today: Date = new Date()
  tableHeader: [string,string][] = [
    ['name', 'Nome do feriado'],
    ['holidayDate', 'Data'],
    ['fullName', 'Criado por'],
    ['repeatAnnually', 'Repetir Anualmente'],
    ['', 'Ações']
  ];
  queryArguments: HolidaysCalendarQueryArguments;
  messageLoader: string;

  ngOnChanges(changes: SimpleChanges) {
    if(changes.year && !changes.year.isFirstChange()) {
      this.getTableData();
    }
  }

  constructor(
    private _holidayService: HolidaysCalendarService,
    private _modalService: NgbModal
  ) { }


  ngOnInit(): void {
    this.settings();
    this.getTableData();
  }

  settings(): void {
    this.isLoading = true;
    this.messageLoader = 'Carregando...';
    this.queryArguments = new HolidaysCalendarQueryArguments();
  }

  onOrderByChange(field: string) {
    if (this.queryArguments.sortField === field) {
      this.queryArguments.desc = !this.queryArguments.desc;
    } else {
      this.queryArguments.sortField = field;
      this.queryArguments.desc = true;
    }

    this.getTableData()
  }

  getTableData() {
    this.isLoading = true;
    this._holidayService
      .getHolidays(this.year, this.queryArguments)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.noFiles = success.length > 0 ? false :  true
          if (success) {
           this.holidays = success
          }
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.queryArguments.sortField == field && !this.queryArguments.desc,
      'order-by-desc':
        this.queryArguments.sortField == field && this.queryArguments.desc,
    };
  }

  openExclude(holiday: IHoliday){
    const modal = this._modalService.open(
      ExcludeHolidayModalComponent,
    );
    modal.componentInstance.holiday = holiday
    this._modalService.activeInstances.subscribe()
    modal.componentInstance.excluded.subscribe(() => {
      this.getTableData()
    });
  }

  canBeExcluded(date){
    let dateObj = new Date(date)
    if (dateObj > this.today)
      return true
    return false
  }
}
