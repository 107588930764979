import { Directive, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import generateCVS, { prepareDownloadCVS } from '@functions/generateCVS';
import { Filters } from '@models/filter.model';
import { environment as env } from '@env';

const download = [
  { title: 'myFleet', path: `${env.apiTMVehicle}/Vehicle/GetVehiclesCsv`, fileName: 'Veiculos_cadastrados_' },
  { title: 'vehicles', path: `${env.apiTMVehicle}/Vehicle/GetVehiclesCsv`, fileName: 'Veiculos_' },
  { title: 'expediture', path: `${env.apiTMReport}/Bill/GetFleetConsuptionEvolutionCsv`, fileName: 'Evolutivo_consumo_' },
  { title: 'canceledTags', path: `${env}`, fileName: '' },
  { title: 'costsRanking', path: `${env}`, fileName: '' },
  { title: 'totalCosts', path: `${env.apiTMReport}/Bill/TotalFleetSpendingCsv`, fileName: 'Gasto_total_' },
  { title: 'vprCredit', path: `${env.apiTMIntegrationVPR}/api/TollVoucherRecharge/Csv`, fileName: 'Créditos Recebidos_' },
  { title: 'vehicleBalance', path: `${env.apiTMSystemAccount}/api/VehicleAccount/GetCsv?`, fileName: 'Saldo dos veículos_' },
]

type Service = 'myFleet' | 'vehicles' | "canceledTags" | "costsRanking" | "totalCosts" | 'vprCredit' | 'vehicleBalance';
@Directive({
  selector: 'button[btn-export]'
})
export class BtnExportDirective implements OnDestroy {
  // private _classes: Array<string> = ['btn btn-primary btn-download'];
  private _sub$: Subscription;

  @Input() filters: Filters;
  @Input() service: Service;
  @Input() fileNameCompliment: string;

  // @HostBinding('innerHTML') name = 'Download CSV';
  // @HostBinding('class') get classes() {
  //   return this._classes.toString().split(',').join();
  // }
  @HostListener('click') onClick() {
    this._downloadCSV();
  }

  constructor(private _http: HttpClient) { }

  ngOnDestroy(): void {
    if (this._sub$) this._sub$.unsubscribe();
  }

  private _downloadCSV() {
    const params = new HttpParams({ fromObject: this.filters as any });

    const selected = download.find(item => item.title === this.service);
    this._sub$ = this._http.get(selected.path,
      {
        observe: "response",
        responseType: "blob",
        params
      })
      .pipe(
        map(data => generateCVS(data))
      )
      .subscribe(res => prepareDownloadCVS(res, selected.fileName, this.fileNameCompliment));
  }
}
