import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITabs } from './models/tabs-models';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  @Input() inTabs: ITabs[];
  @Input() inTabSelected: number;
  @Output() outChangeTabSelected = new EventEmitter<number>();

  onSelectTab(tabIndex: number) {
    this.inTabSelected = tabIndex;
    this.outChangeTabSelected.emit(tabIndex);
  }

  onStyle(styles: string): any  {
    return styles;
  }
}
