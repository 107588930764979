import { Component, Input, OnInit } from '@angular/core';
import { PersonAddress } from '@models/old/personAddress.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '@services/address.service';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-delete-delivery-address',
  templateUrl: './delete-delivery-address.component.html',
  styleUrls: ['./delete-delivery-address.component.scss']
})
export class DeleteDeliveryAddressComponent implements OnInit {

  @Input() AddressObject: PersonAddress;
  onSaving: Boolean;

  constructor(private modalService: NgbActiveModal, private allModalServices: NgbModal, private addressService: AddressService, protected notifyService: NotifyService) {}

  ngOnInit(): void {
  }

  close() {
    this.modalService.close();
  }

  confirmDelete(){
    this.onSaving = true;
    this.addressService
      .deletePersonAddress(this.AddressObject?.id)
      .pipe(
        finalize(() => { this.onSaving = false; }))
        .subscribe(
        (success) => {
          this.notifyService.showSuccess('Atenção!', 'Endereço excluído com sucesso!');
          this.allModalServices.dismissAll();
        },
        (e) => {
           console.error(e)
        }
      );
  }

}
