<mat-list>
  <mat-list-item>
    <img class="{{loadClassConfiguration(isSizeMinValid)}}" src="{{loadImageFile(isSizeMinValid)}}" alt=""/>
    <span [ngClass]="{'information-valid': isSizeMinValid, 'information-invalid': isSizeMinValid === false}"> Mínimo 8 caracteres</span>
  </mat-list-item>
  <mat-list-item>
    <img class="{{loadClassConfiguration(isLowerCharValid)}}" src="{{loadImageFile(isLowerCharValid)}}" alt=""/>
    <span
      [ngClass]="{'information-valid': isLowerCharValid, 'information-invalid': isLowerCharValid === false}"> Mínimo 1 letra minúscula</span>
    <div class="model-info-password">
      <span>Ex: a b c d e f g h</span>
    </div>
  </mat-list-item>
  <mat-list-item>
    <img class="{{loadClassConfiguration(isUpperCharValid)}}" src="{{loadImageFile(isUpperCharValid)}}" alt=""/>
    <span
      [ngClass]="{'information-valid': isUpperCharValid, 'information-invalid': isUpperCharValid === false}"> Mínimo 1 letra maiúscula</span>
    <div class="model-info-password">
      <span>Ex: A B C D E F G H</span>
    </div>
  </mat-list-item>
  <mat-list-item>
    <img class="{{loadClassConfiguration(isNumberValid)}}" src="{{loadImageFile(isNumberValid)}}" alt=""/>
    <span [ngClass]="{'information-valid': isNumberValid, 'information-invalid': isNumberValid === false}"> Mínimo 1 número</span>
    <div class="model-info-password">
      <span>Ex: 1 2 3 4 5 6 7 8</span>
    </div>
  </mat-list-item>
  <mat-list-item>
    <img class="{{loadClassConfiguration(isSpecialCharValid)}}" src="{{loadImageFile(isSpecialCharValid)}}" alt=""/>
    <span [ngClass]="{'information-valid': isSpecialCharValid, 'information-invalid': isSpecialCharValid === false}"> Mínimo 1 caractere especial</span>
    <div class="model-info-password">
      <span>Ex: ! &#64; # $ %  & * ( ) - = +</span>
    </div>
  </mat-list-item>
</mat-list>
