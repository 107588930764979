import { Component, OnInit } from '@angular/core';
import { TransactionConciliationQueryArguments } from './dtos/transaction-conciliation-query-arguments';
import { ITransactionConciliaton } from './dtos/transaction-conciliation.dto';
import { Subject } from 'rxjs';
import { TransactionConciliationService } from './services/transaction-conciliation.service';
import { debounceTime, finalize } from 'rxjs/operators';
import moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Dto } from '@models/dto.model';
import { NotifyService } from '@services/notify.service';
import {Filters, IFilterNameId} from "@models/filters-backoffice.model";

@Component({
  selector: 'app-transaction-conciliation',
  templateUrl: './transaction-conciliation.component.html',
  styleUrls: ['./transaction-conciliation.component.scss'],
})
export class TransactionConciliationComponent implements OnInit {
  perPages = [10, 20, 50];
  tableHeader: string[] = [
    'registrationDate',
    'guidTransactionId',
    'stepTransaction',
    'transactionNotificationErrorTypeId',
  ];
  filterDebounce: Subject<TransactionConciliationQueryArguments> =
    new Subject<TransactionConciliationQueryArguments>();
  queryArguments: TransactionConciliationQueryArguments;
  DtoTransactions: Dto<ITransactionConciliaton[]>;
  message: string = 'Loading...';
  isLoading: boolean = false;
  noFiles: boolean;

  conciliationStepsFilters: IFilterNameId[] = [
    { name: 'Conciliação não iniciada', id: 0 },
    { name: 'Validações Iniciais', id: 1 },
    { name: 'Em processamento', id: 2 },
    { name: 'Sistema de contas', id: 3 },
    { name: 'Financeiro', id: 4 },
    { name: 'Relatórios', id: 5 },
    { name: 'Extrato', id: 6 },
    { name: 'Integração Oracle', id: 7 },
  ];

  refusalTypesFilters: IFilterNameId[] = [
    // { name: 'Sem erros', id: 1 },
    { name: 'O campo ChargedCategoryCode deve ser diferente de 504.', id: 2 },
    { name: 'O campo Metadata deve ser obrigatório.', id: 3 },
    { name: 'O valor da transação deverá ser diferente de zero.', id: 4 },
    {
      name: 'Transação fora do período de SLA de 12 horas após cancelamento de tag por substituição.',
      id: 5,
    },
  ];

  constructor(
    private _transactionConciliation: TransactionConciliationService,
    private _notifyService: NotifyService,
    private _localeService: BsLocaleService,
  ) {
    this.queryArguments = new TransactionConciliationQueryArguments();
    this._localeService.use('pt-br');
    this.filterDebounce
      .pipe(debounceTime(300))
      .subscribe((filter) => this.getTransactionConciliation(filter));
  }

  ngOnInit(): void {
    this.getTransactionConciliation(this.queryArguments);
  }

  getTransactionConciliation(args: TransactionConciliationQueryArguments) {
    this.isLoading = true;
    this.message = 'Loading...';
    this._transactionConciliation
      .getTransactionConciliation(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (data: Dto<ITransactionConciliaton[]>) => {
          if (data.totalItems == 0) {
            this.noFiles = true;
          } else {
            this.noFiles = false;
          }
          this.DtoTransactions = data;
        },
        (error) => {
          console.error(error);
          this._notifyService.clear();
          this._notifyService.showError('Ocorreu um erro', error);
          return;
        },
      );
  }

  onPageSizeChange(value: any) {
    this.queryArguments.page = 1;
    this.queryArguments.pageSize = value;
    this.getTransactionConciliation(this.queryArguments);
  }

  onOrderByChange(field: string) {
    if (this.queryArguments.orderBy === field) {
      this.queryArguments.desc = !this.queryArguments.desc;
    } else {
      this.queryArguments.orderBy = field;
      this.queryArguments.desc = true;
    }
    this.getTransactionConciliation(this.queryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.queryArguments.orderBy == field && !this.queryArguments.desc,
      'order-by-desc':
        this.queryArguments.orderBy == field && this.queryArguments.desc,
    };
  }

  onPaginationChange(event: any) {
    this.queryArguments.page = event;
    this.getTransactionConciliation(this.queryArguments);
  }

  Download() {
    this.isLoading = true;
    let now = new Date();
    let date = moment(now).format('DDMMYYYY');
    this.message = 'Download in progress...';
    this._transactionConciliation

      .getTransactionConciliationCsv(this.queryArguments)

      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )

      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Conciliacoes_' + date + '.csv';
        element.click();
      });
  }

  resetFilters() {
    this.queryArguments = new TransactionConciliationQueryArguments();

    this.getTransactionConciliation(this.queryArguments);
  }

  handleFiltersParams(params: Filters) {
    this.queryArguments.fromDate = params.transactionDateFilter[0];
    this.queryArguments.toDate = params.transactionDateFilter[1];
    this.queryArguments.stepTransaction = params.radioStepFilter;

    if (params.radioStepFilter != 1) {
      this.queryArguments.refusalReason = null;
    } else {
      this.queryArguments.refusalReason = params.refusalReasonFilter;
    }

    this.getTransactionConciliation(this.queryArguments);
    this.queryArguments.page = 1;
  }
}
