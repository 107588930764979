export const TOOLTIPS = {
    dualTagVehiclesChangeService: 'Para tags do tipo Dual Tag a alteração de serviços deve acontecer pela Plataforma de Abastecimento',
    dualTagVehiclesEdit: 'Em contratos com Dual Tag Ativo,os veículos podem ser editados somente na página de Abastecimento',
    dualTagVehiclesTagReplacement: 'Para realizar uma substituição de TAG precisamos que o veículo já possua uma tag vinculada',
    dualTagActivateTag: 'Inclusão de veículos deve ser feita pela plataforma de Abastecimento',
    dualTagUnlock: 'Tags do tipo Dual Tag somente podem ser substituídas pela plataforma de Abastecimento',
    dualTagBlock: 'Tags do tipo Dual Tag somente podem ser bloqueadas/desbloqueadas pela plataforma de Abastecimento',
    dualTagChangeService: 'Para tags do tipo Dual Tag a alteração de serviços deve acontecer pela Plataforma de Abastecimento',
    dualTagGeneralStatus: 'Só é possível cancelar tags bloqueadas manualmente',
    dualTagCancel: 'Cancelar tags do tipo Dual Tag somente é possível via Plataforma de Abastecimento'
};