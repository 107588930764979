<div class="modal-content UserEditModal">
  <div class="modal-header">
    <div class="box-title">
      <span data-e2e="modal-title">{{titleModal}}</span>
    </div>
    <button type="button" class="close" (click)="close()" id="btn-dismiss">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  @if(isLoading){
    <div class="container-loading">
      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
    </div>
  }

  @if(!isLoading){
    <div class="modal-body show">

      @if(emailChanged){
        <div class="email-changed">
          <span class="message"><span class="highlight">O</span> usuário irá receber as instruções de acesso no e-mail
            cadastrado em até 2 horas.</span>
        </div>
      }


      @if(step == 1){
        <div>
          <form [formGroup]="editUserForm">

            <div class="row titleRow">
              <p>Dados pessoais</p>
              <span>Nome completo e CPF</span>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group-user">
                  <label>Nome</label>
                  <input class="form-control-user" formControlName="name"
                  value="{{UserInfos.usersCollection.fullName}}" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group-user">
                  <label>CPF</label>
                  <input class="form-control-user" disabled
                    value="{{ UserInfos.usersCollection.documentNumber | mask: '000.000.000-00' }}" />
                </div>
              </div>
            </div>

            <div class="row titleRow">
              <p>Contato</p>
              <span>E-mail e telefone de contato</span>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group-user">
                  <label>E-mail</label>
                  <input #emailAddressInput class="form-control-user" id="emailAddress" formControlName="emailAddress"
                    value="{{UserInfos.usersCollection.emailAddress}}" (keyup)="changeEmailAddress(emailAddressInput)" />
                </div>

                <div class="alertErrorValidator">
                  @if(validateFormEmail()){
                  <span>
                    <span class="fas fa-exclamation-circle"></span> O e-mail informado não é válido. Por favor informe um
                    e-mail válido.
                  </span>
                  }

                  @if(emailExists){
                    <span>
                      <span class="fas fa-exclamation-circle"></span> O e-mail informado já está sendo utilizado por outro
                      usuário.
                    </span>
                  }
                </div>
              </div>

              <div class="col-6">
                <div class="form-group-user">
                  <label>Telefone</label>
                  <input class="form-control-user"  formControlName="telephoneNumber" mask="(00) 00000-0000"
                    value="{{UserInfos.usersCollection.prefix + UserInfos.usersCollection.telephoneNumber }}" />
                </div>
                <div class="alertErrorValidator">
                @if(validateFormPhoneNumber()){
                  <span>
                    <span class="fas fa-exclamation-circle"></span>  Por favor informe um telefone válido.
                  </span>
                  }
                </div>
              </div>
            </div>

            <div class="row titleRow">
              <p>Informações gerais</p>
              <span>Veja quando a conta foi criada, o último acesso e o status do usuário</span>
            </div>

            <div class="row">
              <div [ngClass]="isBackOfficeProfile() ? 'col-4' : 'col-6'">
                <div class="form-group-user">
                  <label>Data de criação do usuário</label>
                  <input class="form-control-user" disabled
                    value="{{ UserData.dataCriacao | date:'dd/MM/yyyy - HH:mm' }}" />
                </div>
              </div>
              <div [ngClass]="isBackOfficeProfile() ? 'col-4' : 'col-6'">
                <div class="form-group-user">
                  <label>Data do último acesso</label>
                  <input class="form-control-user" disabled
                    value="{{ UserData.dataUltimoAcesso | date:'dd/MM/yyyy - HH:mm' }}" />
                </div>
              </div>

              @if(isBackOfficeProfile()) {
                <div class="col-4">
                  <div class="form-group-user">
                    <label>Data do desbloqueio</label>
                    <input class="form-control-user" disabled
                      value="{{ UserData.dataDesbloqueio | date:'dd/MM/yyyy - HH:mm' }}" />
                  </div>
                </div>
              }

            </div>
            <div class="row">
              @if(isBackOfficeProfile()) {
                <div class="col-6">
                  <div class="form-group-user">
                    <label>Data da última alteração de senha</label>
                    <input class="form-control-user" disabled value="{{ UserData.dataSenha | date:'dd/MM/yyyy - HH:mm' }}" />
                  </div>
                </div>
              }
              <div [ngClass]="isBackOfficeProfile() ? 'col-6' : 'col-12' ">
                <div class="form-group-user">
                  <label>Status</label>
                    <mat-form-field appearance="outline" [matTooltip]="tooltipStatus(UserData.statusUsuarioId)" [matTooltipPosition]="'right'" [matTooltipClass]="'tooltip'">
                      <mat-select formControlName="subPerfilBlocked"  [disabled]="UserData.statusUsuarioId == 2" id="subPerfilBlocked" [placeholder]="bindStatusDescription(UserData.statusUsuarioId, UserData.subPerfilBlocked)"
                        [ngClass]="UserData.statusUsuarioId == 2 ? 'disabled': ''"
                        panelClass="mat-select-panel">
                        @for(option of statusOptions; track option) {
                          @if(UserData.statusUsuarioId !== 2) {
                            <mat-option [value]="option.subPerfilBlocked">
                              {{ option.viewValue }}
                            </mat-option>
                          }
                        }
                      </mat-select>
                    </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row titleRow">
              <p>Informação de acesso</p>
              <span *ngIf="!edit">Veja o perfil de acesso do usuário</span>
              <span *ngIf="edit">Edite o perfil de acesso do usuário</span>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group-user profile-select">
                  <label>Perfil de acesso</label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="profile" id="profile" placeholder="Perfil de acesso"
                      panelClass="mat-select-panel">
                      <mat-option *ngFor="let profile of profileList" [value]="profile.id" matTooltip="{{profile.subTitle}}"
                        [matTooltipPosition]="'right'" [matTooltipClass]="'tooltip'">
                        {{ profile.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            @if (isBackOfficeProfile()) {
              @if(edit) {
                <div class="row mt-2">
                  <div class="col-12 form-row">
                    <p>Informe o protocolo de solicitação <span>*</span></p>
                    <input id="protocol" formControlName="protocol" required mask="999999999999999"
                      placeholder="Informe o protocolo da solicitação" maxlength="15" />

                    <div class="alertErrorValidator">
                      @if(validateFormProtocol()){
                        <span>
                          <span class="fas fa-exclamation-circle"></span> Informe um
                          número de protocolo válido.
                        </span>
                      }
                    </div>
                  </div>
                  </div>
              }

              @if (edit) {
              <div class="row">
                <div class="col-12 form-row">
                  <p>Motivo <span>*</span></p>
                  <input id="reason" formControlName="reason" required placeholder="Digite o motivo" maxlength="70" />
                  @if(!validateFormReason()){
                    <p class="count-reason" >
                      {{ editUserForm.get('reason')?.value?.length || 0 }}/70
                    </p>
                  }

                  <div class="alertErrorValidator">
                    @if(validateFormReason()){
                      <span>
                      <span class="fas fa-exclamation-circle"></span> Informe um
                      motivo.
                    </span>
                    }
                  </div>
                </div>
              </div>
              }

            }

            @if (!edit && (checkRoles("101219") || isBackOfficeProfile())) {
            <div class="row titleRow mt-3">
              <span>Você pode editar a conta do usuário</span>

              <app-borderless-button
              [buttonColor]="'primary'"
              [classIconLeft]=" 'ico-edit-user'"
              [description]="'Editar dados da conta'"
              [id-html]="'EditUserButton'"
              [data-e2e]="'open-add-user-modal-btn'"
              (emitterEvtClick)="editUser()"
              [isBtnWithBorder]="true"
              >
              </app-borderless-button>
            </div>
            }

          </form>
        </div>
      }


      @if(step == 2) {
        <div class="confirm-data">

          <app-user-edit-modal-confirm [subTitleModal]="this.subTitleModal" [userEdit]="getDataUserEdit()"
            [resultEdit]="this.resultEdit" [isBackoffice]="isBackOfficeProfile()">
          </app-user-edit-modal-confirm>

        </div>
      }

    </div>
  }


  <div class="modal-footer modal-footer-gray justify-content-start">
    @if( !edit ){
      <div class="col-12 justify-content-center d-flex">
        <button (click)="close()" class="btn btn-secondary position-relative" type="button">
          Fechar
        </button>
      </div>
    } @else if ( edit ) {
      <div  class="col-12 justify-content-between d-flex">
        <button (click)="close()" class="btn btn-tertiary position-relative" type="button">
          Cancelar
        </button>
        @if(step == 1){
          <button (click)="confirmEmail()" [disabled]="disableButton()"
          class="btn btn-secondary position-relative" type="button">
          Salvar
        </button>
        }
        @if(step == 2){
          <button (click)="saveUser()"
          class="btn btn-secondary position-relative" type="button">
            Confirmar
          </button>
        }
      </div>
    }
  </div>
</div>
