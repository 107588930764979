import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalstorageService } from '@services/localstorage.service';
import { CacheService } from '@services/cache.service';
import {UserProfileService} from "@services/user-profile.service";
import {ProfileType} from "@variables/profileType";

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {

    constructor( protected localstorageService: LocalstorageService,
                 protected cache: CacheService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if(req.url == `${environment.apiVindi}/v1/public/payment_profiles`){
            //Basic auth - Vindi req
            const dupReq = req.clone({
                setHeaders: {
                    Authorization: 'Basic ' + btoa(environment.apiVindiKey+':')
                }
            });
            return next.handle(dupReq);
        }
        else{
            //Bearer auth - all reqs
            const dupReq = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token')
                },
                headers: req.headers.set('contractId', this.getContractId()),
            });
            let contractId = +dupReq.headers.get('contractId');
            let cacheExpiry = dupReq.headers.get('cache-expiry') ? +dupReq.headers.get('cache-expiry') : 300000;

            //solução de cache
            if(this.isCachable(dupReq)){
                let cachedResponse=this.cache.get(dupReq.url,cacheExpiry,contractId);
                return cachedResponse ?of(cachedResponse):this.sendNewRequest(dupReq,next,contractId)
            }
            else{
                if(this.cache && (this.cache.get(dupReq.url,cacheExpiry,contractId))){
                    this.cache.delete(dupReq.url)
                }
                return next.handle(dupReq);
            }
        }
}

//verifica se a requisição utiliza/utilizará cache ou não
isCachable(req:HttpRequest<any>){
    return req.method==='GET' && req.headers.get('cache-response') ? true : false;
}

//segue com a requisição e salva seu retorno em cache
sendNewRequest(req:HttpRequest<any>,next:HttpHandler,contractId:number){
   return next.handle(req).pipe(
   tap(event=>this.cache.set(req.url,event,new Date(),contractId)))
}

  private getContractId(): string {
      let contractId: string = this.localstorageService.getSessionStorageContractId()
      const profile: any = this.localstorageService.getProfile();

      if (profile === ProfileType.CUSTOMER || profile === ProfileType.RELATIONSHIP) {
        const x = this.localstorageService.getContractId();
        contractId = x ?? "0";
      }

      return contractId;
  }
}
