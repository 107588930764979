<div class="neworderComponent">
    <div class="Dashboard container-fluid">
      <div class="row mt-2">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <app-page-title [title]="'/ Novo Pedido'"></app-page-title>

            <app-borderless-button
            [description]=" 'Voltar para os pedidos' "
            [buttonColor]="'tertiary'"
            [classIconLeft]="'ico-arrow-left'"
            [data-e2e]=" 'order-link' "
            [id-html]=" 'order-link' "
            [isBtnWithBorder]="true"
            [routerLink]=" '/meus-pedidos' "
            />

        </div>

        <div class="container-loading" *ngIf="isLoading">
          <app-loader
            [isLoading]="isLoading"
            message="{{ 'Carregando...' }}"
          ></app-loader>
        </div>

        <div class="container-content">
           <div class="content" *ngIf="!isLoading">
              <div class="box-body">
                <form class="form" [formGroup]="orderForm" (ngSubmit)="onSubmit()">
                  <div class="form-content" *ngIf="step == 1">
                    <div class="form-field form-field-qtd-tags">
                        <label for="amount" data-e2e="qtd-tags-label">Quantidade de Tags
                          <i
                          class="ico ico-alert"
                          placement="top"
                          data-e2e="qtd-tags-tooltip"
                          ngbTooltip="Quantidade de tags que serão solicitadas no pedido"
                        ></i>
                        </label>
                        <div class="form-input">
                            <input
                            [ngClass]="(orderForm.controls['amount']?.invalid && orderForm.controls['amount']?.touched)
                            || (submitted && orderForm.controls['amount']?.invalid) ? 'invalidField' : ''"
                            class="qtdTagsInput inputneworder"
                            data-e2e="qtd-tags-input"
                            id="qtdTags"
                            type="number"
                            formControlName="amount">
                            <div class="alert-error-validator" data-e2e="qtd-tags-error-msg"
                            *ngIf="(orderForm.controls['amount']?.invalid && orderForm.controls['amount']?.touched)
                            || (submitted && orderForm.controls['amount']?.invalid)">
                            {{ generateErrorMessage(orderForm.controls['amount'])}}
                            </div>
                        </div>
                    </div>
                    <div class="order-contract-infos">
                      <div class="info-container">
                        <div class="title" data-e2e="tag-block-title">Valor por tag</div>
                        <div class="infos">
                          <div class="label strong" data-e2e="tag-value-label">Valor unitário</div>
                          <div class="value strong" data-e2e="tag-value"> {{ contract.tagValue | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"}}</div>
                          <div class="label" data-e2e="exemption-end-date-label"><span>Fim da isenção</span>
                            <i
                              class="ico ico-alert"
                              placement="top"
                              data-e2e="exemption-end-date-tooltip"
                              ngbTooltip="Período de isenção da tarifa de valor unitário de tag"
                            ></i>
                          </div>
                          <div class="value" data-e2e="exemption-end-date">{{ contract.validityDiscountTag ? (contract.marketingPlan.exemptionEndDate | date : "dd/MM/yyyy") : '-' }}</div>
                          <div class="label" data-e2e="tag-discount-label">Desconto total</div>
                          <div class="value" data-e2e="tag-discount">{{ contract.validityDiscountTag ? (contract.marketingPlan.tagDiscount | number : "1.2-2" : "pt-BR") : '-' }}<span *ngIf="contract.validityDiscountTag">%</span></div>
                          <div class="label" data-e2e="amount-label">Quantidade de tags</div>
                          <div class="value" data-e2e="amount">{{ orderForm.controls['amount']?.value > 0 ? orderForm.controls['amount']?.value : 0 }}</div>
                        </div>
                      </div>
                      <div class="info-container">
                        <div class="title">Dados de entrega</div>
                        <div class="delivery-infos">
                          <label>Endereço de entrega</label>
                          <div class="delivery-adress">
                            <div
                            class="AddressLoading container-loading col-12"
                            *ngIf="isLoadingAddress"
                          >
                            <img src="../../../assets/img/loader.gif">
                          </div>
                            <div class="selected-address" *ngIf="!isLoadingAddress">
                              <p
                              class="pbox-address"
                              *ngIf="
                                contract.personAddress.addressTypeId == 1

                              "
                            >
                              <span data-e2e="address-street" id="streetAddress">{{
                                contract.personAddress.streetAddress
                              }}</span
                              >,
                              <span data-e2e="address-number" id="number">{{
                                contract.personAddress.number
                              }}</span
                              >,
                              <span
                                data-e2e="address-complementary"
                                id="streetAddressLine2"
                                >{{ contract.personAddress.streetAddressLine2 }}</span
                              >
                              <span id="space"
                                >{{
                                  contract.personAddress.streetAddressLine2 === null ||
                                  contract.personAddress.streetAddressLine2 === ""
                                    ? ""
                                    : ","
                                }}
                              </span>
                              <span data-e2e="address-district" id="district">{{
                                contract.personAddress.district
                              }}</span>
                              -
                              <span data-e2e="address-city" id="city">{{
                                contract.personAddress.city
                              }}</span>
                              -
                              <span data-e2e="address-state" id="federatedUnit">{{
                                contract.personAddress.federatedUnit
                              }}</span>
                              - CEP:
                              <span data-e2e="address-zipcode" id="zipCode">{{
                                contract.personAddress.zipCode
                              }}</span>
                            </p>

                            <p
                              class="pbox-address"
                              *ngIf="
                                contract.personAddress.addressTypeId == 2
                              "
                            >
                              Código da caixa postal:
                              <span data-e2e="address-mailbox" id="mailBox">{{
                                contract.personAddress.mailbox
                              }}</span>
                              <br />
                              <span data-e2e="address-city" id="city">{{
                                contract.personAddress.city
                              }}</span>
                              -
                              <span data-e2e="address-state" id="federatedUnit">{{
                                contract.personAddress.federatedUnit
                              }}</span>
                              - CEP:
                              <span data-e2e="address-zipcode" id="zipCode">{{
                                contract.personAddress.zipCode
                              }}</span>
                            </p>
                            </div>
                            <div data-e2e="delivery-adress-edit" class="edit-delivery-address" (click)="showDeliveryAddressesModal()">
                              Editar
                          </div>
                        </div>

                        </div>
                      </div>
                      <div class="info-container">
                        <div class="title" data-e2e="shipping-block-title">Frete</div>
                        <div class="infos">
                          <div class="label strong" data-e2e="shipping-value-label">Valor</div>
                          <div class="value strong" data-e2e="shipping-value"> {{ regionShippingChargeSelected.shippingValue | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"}}</div>
                          <div class="label" data-e2e="shipping-exemption-end-date-label"><span>Fim da isenção</span>
                            <i
                              class="ico ico-alert"
                              placement="top"
                              ngbTooltip="Período de isenção da tarifa de frete"
                              data-e2e="shipping-exemption-end-date-tooltip"
                            ></i>
                          </div>
                          <div class="value" data-e2e="shipping-exemption-end-date">{{ contract.shippingExemptionEndDate ? (contract.shippingExemptionEndDate | date : "dd/MM/yyyy") : '-' }}</div>
                          <div class="label" data-e2e="shipping-discount-label">Desconto total</div>
                          <div class="value" data-e2e="shipping-discount">{{ contract.validityDiscountShipping ? (contract.shippingDiscount | number : "1.2-2" : "pt-BR") : '-' }}<span *ngIf="contract.validityDiscountShipping">%</span></div>
                          <div class="label" data-e2e="shipping-delivery-term-label">Prazo de entrega</div>
                          <div class="value" data-e2e="shipping-delivery-term">{{ contract?.regionDeliveryTerm?.deliveryTerm }} {{contract?.regionDeliveryTerm?.deliveryTerm == 1 ? 'dia útil' : 'dias úteis' }}</div>
                        </div>
                      </div>
                      <div class="info-container">
                        <div class="title">Responsável pelo recebimento</div>
                        <div class="responsible-infos">

                          <div class="form-field">
                            <label for="fullName" data-e2e="full-name-label">Nome</label>
                            <div class="form-input">
                                <input
                                placeholder="João da Silva"
                                [ngClass]="(orderForm.controls['fullName']?.invalid && orderForm.controls['fullName']?.touched)
                                || (submitted && orderForm.controls['fullName']?.invalid) ? 'invalidField' : ''"
                                class="fullNameInput ico-user"
                                data-e2e="full-name-input"
                                id="fullName"
                                type="string"
                                formControlName="fullName">
                                <div class="alert-error-validator" data-e2e="full-name-error-msg"
                                *ngIf="(orderForm.controls['fullName']?.invalid && orderForm.controls['fullName']?.touched)
                                || (submitted && orderForm.controls['fullName']?.invalid)">
                                {{ generateErrorMessage(orderForm.controls['fullName'])}}
                                </div>
                            </div>
                        </div>

                        <div class="form-field">
                          <label for="telephoneNumber" data-e2e="telephone-number-label">Telefone</label>
                          <div class="form-input">
                              <input
                              [mask]="getPhoneMask()"
                              placeholder="(00) 00000-0000"
                              [ngClass]="(orderForm.controls['telephoneNumber']?.invalid && orderForm.controls['telephoneNumber']?.touched)
                              || (submitted && orderForm.controls['telephoneNumber']?.invalid) ? 'invalidField' : ''"
                              class="telephoneNumberInput ico-phone"
                              data-e2e="telephone-number-input"
                              id="telephoneNumber"
                              type="string"
                              formControlName="telephoneNumber">
                              <div class="alert-error-validator phone-message" data-e2e="telephone-number-error-msg"
                              *ngIf="(orderForm.controls['telephoneNumber']?.invalid && orderForm.controls['telephoneNumber']?.touched)
                              || (submitted && orderForm.controls['telephoneNumber']?.invalid)">
                              {{ generateErrorMessage(orderForm.controls['telephoneNumber'])}}
                              </div>
                              <div class="tel-exemple">(DDD) fixo/celular</div>
                          </div>
                      </div>

                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      class="btn btn-secondary"
                      id="next-btn"
                      data-e2e="next-btn"
                      [disabled]="orderForm.invalid">
                     Próximo
                    </button>

                  </div>

                  <div class="box-payment" *ngIf="step == 2">
                    <div>
                      <app-payment-method
                        [reducedList]="false"
                        [selectorMode]="true"
                      ></app-payment-method>
                    </div>

                      <button
                      type="button"
                      (click)="step = 1"
                      class="btn btn-secondary"
                      id="return-btn"
                      data-e2e="return-btn"
                      [disabled]="onSaving">
                    Voltar
                    </button>

                    <button
                    type="submit"
                    id="submit-btn"
                    data-e2e="order-confirmation-btn"
                    [ngClass]="getClassAnimateButton()"
                    [disabled]="onSaving || orderForm.invalid || validPaymentMethod()"
                      >
                        <span class="default" id="defaultMessage">{{ message }}</span>
                        <span class="error" id="errorMessage">{{ message }}</span>

                        <div class="box"></div>

                        <div class="truck">
                          <div class="back"></div>
                          <div class="front">
                            <div class="window"></div>
                          </div>
                          <div class="light top"></div>
                          <div class="light bottom"></div>
                        </div>
                        <div class="lines"></div>
                  </button>
            </div>

                </form>
              </div>
            </div>
           <div class="content" *ngIf="!isLoading">
              <div class="order-summary order-infos">
                <div class="title" data-e2e="order-summary-title">Resumo do pedido</div>
                <div class="info">
                  <div class="label" data-e2e="order-summary-tag-value-label">TAGS ({{ orderForm.controls['amount']?.value > 0 ? orderForm.controls['amount']?.value : 0 }})</div>
                  <div class="value" data-e2e="order-summary-tag-value">{{ tagrequest.totalTagValue | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                </div>
                <div class="info">
                  <div class="label" data-e2e="order-summary-shipping-value-label">Valor do frete</div>
                  <div class="value" data-e2e="order-summary-shipping-value">{{ regionShippingChargeSelected.shippingValue | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                </div>
                <div class="info">
                  <div class="label" data-e2e="order-summary-total-order-value-label">Valor total do pedido</div>
                  <div class="value" data-e2e="order-summary-total-order-value">{{ tagrequest.totalOrder | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                </div>
             </div>
             <div class="order-discount order-infos">
                  <div class="title" data-e2e="order-discount-title">Descontos</div>
                  <div class="info">
                    <div class="label" data-e2e="order-discount-tag-label">Desconto de tag</div>
                    <div class="value" data-e2e="order-discount-tag">{{ contract.validityDiscountTag ? (contract.tagValueDiscount * tagrequest.amount | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR") : '-' }}</div>
                  </div>
                  <div class="info">
                    <div class="label"  data-e2e="order-discount-shipping-label">Desconto de frete</div>
                    <div class="value"  data-e2e="order-discount-shipping">{{ contract.validityDiscountShipping ? (regionShippingChargeSelected.shippingValueDiscount | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR") : '-' }}</div>
                  </div>
                  <div class="info">
                    <div class="label" data-e2e="order-discount-total-label">Desconto total do pedido</div>
                    <div class="value" data-e2e="order-discount-total">{{ contract.validityDiscountTag || contract.validityDiscountShipping ? (tagrequest.totalOrderDiscount | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR") : '-' }}</div>
                  </div>
             </div>
             <div class="order-total order-infos">
                <div class="info">
                  <div class="label" data-e2e="order-total-amount-payable-label">Valor total a pagar</div>
                  <div class="value" data-e2e="order-total-amount-payable">{{ tagrequest.totalAmountPayable | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                </div>
            </div>
          </div>
    </div>
   </div>
  </div>
</div>
