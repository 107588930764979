import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormsModule } from '@angular/forms';
import { PageComponent } from './page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {MatIconModule} from "@angular/material/icon";
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {MatExpansionModule} from "@angular/material/expansion";
import {FooterModule} from "@modules/commom/footer/footer.module";
import {HeaderModule} from "@modules/commom/header/header.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    OverlayModule,
    OverlayPanelModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatExpansionModule,
    HeaderModule,
    FooterModule,
  ],
  declarations: [
    PageComponent,
    // HeaderComponent,
    // NotificationComponent,
    // TollBalanceComponent,
    // AlertCreditModalComponent,
    // MenuResponsiveComponent
  ],
  providers: [provideNgxMask()]
})

export class PageModule {}
