import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr);
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from 'ngx-toastr';
import { OrderPipe } from "ngx-order-pipe";
import { HttpClientModule } from '@angular/common/http';
import { Interceptor } from './core/interceptors/interceptor.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from "./core/core.module";
import { DashboardModule } from "@modules/customer/dashboard/dashboard.module";
import { LoginModule } from "@modules/public/login/login.module";
import { PageModule } from "@modules/customer/page/page.module";
import { SelectContractsModule } from "@modules/customer/select-contracts/select-contracts.module";
import { TagactivationModule } from "@modules/customer/tagactivation/tagactivation.module";
import { TicketsModule } from "@modules/customer/tickets/tickets.module";
import { MyvehiclesModule } from "@modules/customer/myvehicles/myvehicles.module";
import { MyorderModule } from "@modules/customer/myorder/myorder.module";
import { BillModule } from "@modules/customer/bill/bill.module";
import { ExtractModule } from "@modules/customer/extract/extract.module";
import { RechargeModule } from "@modules/customer/recharge/recharge.module";
import { SaldovprVeiculosModule } from "@modules/customer/saldovpr-veiculos/saldovpr-veiculos.module";
import { VprCreditReceivedModule } from "@modules/customer/vpr-credit-received/vpr-credit-received.module";
import { MyaccountModule } from "@modules/customer/myaccount/myaccount.module";
import { MycontractModule } from "@modules/customer/mycontract/mycontract.module";
import { HierarchyModule } from "@modules/customer/hierarchy/hierarchy.module";
import { FleetDashboardModule } from "@modules/customer/fleet-dashboard/fleet-dashboard.module";
import { ContactUsModule } from "@modules/customer/contact-us/contact-us.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { SelectProfileModule } from "@modules/commom/select-profile/select-profile.module";
import { MainMenuBackofficeModule } from "@modules/backoffice/main-menu-backoffice/main-menu-backoffice.module";
import { ShellPagesModule } from "@modules/backoffice/page-backoffice/shell-pages.module";
import { TranslateModule } from "@ngx-translate/core";
import { SelectProfileBackOfficeModule } from "@modules/backoffice/select-profile/select-profile-back-office.module";
import { ContractualAlterationModule } from "@modules/backoffice/contractual-alteration/contractual-alteration.module";
import { ReportsModule } from "@modules/backoffice/reports/reports.module";
import { RequestModule } from "@modules/backoffice/request/request.module";
import {
  TransactionConciliationModule
} from "@modules/backoffice/transaction-conciliation/transaction-conciliation.module";
import { ChargebackReportModule } from "@modules/backoffice/chargeback-report/chargeback-report.module";
import { FinancialSalesReportModule } from "@modules/backoffice/financial-sales-report/financial-sales-report.module";
import {
  FinancialBlockingUnblockingModule
} from "@modules/backoffice/financial-blocking-unblocking/financial-blocking-unblocking.module";
import {
  TransactionPartnersConciliationModule
} from "@modules/backoffice/transaction-partners-conciliation/transaction-partners-conciliation.module";
import {
  UserConsultantCreateUpdateModule
} from "@modules/backoffice/user-consultant-create-update/user-consultant-create-update.module";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { UserConsultantViewModule } from "@modules/backoffice/user-consultant-view/user-consultant-view.module";
import { MatDialogModule } from '@angular/material/dialog';
import { BatchManagementModule } from '@modules/customer/batch-management/batch-management.module';
import { environment } from '@env';
import { HolidaysCalendarModule } from '@modules/backoffice/holidays-calendar/holidays-calendar.module';
import { TripExpiratedModule } from '@modules/backoffice/trip-expirated/trip-expirated.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    DashboardModule,
    LoginModule,
    PageModule,
    SelectContractsModule,
    TagactivationModule,
    MyvehiclesModule,
    TicketsModule,
    MyorderModule,
    BillModule,
    ExtractModule,
    RechargeModule,
    SaldovprVeiculosModule,
    VprCreditReceivedModule,
    MyaccountModule,
    MycontractModule,
    ContactUsModule,
    FleetDashboardModule,
    HierarchyModule,
    BatchManagementModule,
    Interceptor,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: false,
      },
    }),
    SelectProfileModule,
    //BackOffice
    ShellPagesModule,
    MainMenuBackofficeModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    SelectProfileBackOfficeModule,
    ContractualAlterationModule,
    ReportsModule,
    RequestModule,
    TransactionConciliationModule,
    ChargebackReportModule,
    FinancialSalesReportModule,
    FinancialBlockingUnblockingModule,
    TransactionPartnersConciliationModule,
    UserConsultantCreateUpdateModule,
    UserConsultantViewModule,
    MatDialogModule,
    HolidaysCalendarModule,
    TripExpiratedModule
  ],
  providers: [
    OrderPipe,
    { provide: NgModule, useValue: "pt-PT" },
    { provide: OAuthStorage, useValue: localStorage },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    GoogleTagManagerModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
