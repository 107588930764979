<div class="EditContractNameModalComponent">
  <div class="modal-header">
    <h4 data-e2e="modal-title" class="modal-title" translate>
      Edit contract name
    </h4>
    <a data-e2e="close-modal-top-btn" class="btn-close" (click)="close()">
      <img src="assets/img/close.svg" />
    </a>
  </div>

  <div class="blueContainer" *ngIf="step == 1"></div>
  <div class="line" *ngIf="step == 1"></div>

  <div class="contractInfo" *ngIf="step == 1">
    <p data-e2e="contract-name-title" class="name">
      {{ contract.description }}
    </p>
    <p class="contract">
      <span data-e2e="contract-tax-id" class="document">
        {{ contract?.documentNumber | mask: '00.000.000/0000-00' }}
      </span>
      | Contrato:
      <span data-e2e="contract-id" class="id">{{ contract?.id }}</span>
    </p>
  </div>

  <div class="modal-body modal-body1 show" *ngIf="step == 1">
    <form class="form" [formGroup]="editContractNameForm">
      <div class="formfield col">
        <label data-e2e="contract-nickname-label" for="nameContract"
          >Informe o nome do contrato</label
        >

        <div class="formInput">
          <input
            id="contractName"
            data-e2e="contract-nickname-input"
            [(ngModel)]="contractName"
            formControlName="nameContract"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="modal-body modal-body2 show row" *ngIf="step == 2">
    <div class="col-12 container-confirmation">
      <div class="col-4 image-content">
        <img src="assets/img/bg-place.svg" />
      </div>

      <div class="col-8 confirmation-text">
        <h3 data-e2e="confirmation-title">Nome de contrato alterado</h3>

        <div class="row">
          <span data-e2e="previews-label" class="col previousNameLabel">
            Nome anterior:
          </span>

          <span data-e2e="previews" class="col previousName">
            {{ contract?.nameContract }}
          </span>
        </div>

        <div class="row">
          <span data-e2e="current-label" class="col actualNameLabel">
            Nome Atual:
          </span>

          <span data-e2e="current" class="col actualName">
            {{ editContractNameForm.value.nameContract }}
          </span>
        </div>

        <div class="author mt-4">
          <p>
            <span data-e2e="confirmation-signature" id="dateRegister">{{
              dateRegister | date: 'dd/MM/yyyy HH:mm'
            }}</span>
            por <span id="nameUser">{{ nameUser }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal-footer modal-footer-first-step modal-footer-gray"
    *ngIf="step == 1"
  >
    <button
      data-e2e="cancel-btn"
      type="button"
      class="btn btn-tertiary mr-4"
      (click)="close()"
    >
      Cancelar
    </button>

    <button
      type="button"
      data-e2e="submit-btn"
      class="btn btn-secondary position-relative"
      (click)="submitContractName(editContractNameForm.value)"
      [disabled]="onSaving || contractName == contract.nameContract"
    >
      Salvar edição
      <img
        class="loading-btn"
        *ngIf="onSaving"
        src="assets/img/loader.gif"
        alt="Loading"
      />
    </button>
  </div>

  <div
    class="modal-footer modal-footer-last-step modal-footer-gray"
    *ngIf="step == 2"
  >
    <button
      data-e2e="close-modal-btn"
      type="button"
      class="btn btn-secondary"
      (click)="close()"
    >
      Fechar
    </button>
  </div>
</div>
