import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BrandType } from "@models/old/vehicle/brandType.model";
import { VehicleCategoryAxleWheelType } from "@models/old/vehicle/vehicleCategoryAxleWheelType.model";
import { VehicleType } from "@models/old/vehicle/vehicleType.model";
import { Vehicle } from "@models/vehicle.model";
import { GetHierarchyWithoutChildrensResultDto } from "../../../myvehicles/dtos/get-hierarchy-without-childrens-result.dto";
import { MyvehiclesService } from "../../../myvehicles/services/myvehicles.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CpfCnpjValidator } from "@services/form-validators/cpf-cnpj-validator.service";
import { Logger } from "@services/logger.service";
import { NotifyService } from "@services/notify.service";
import {
  finalize,
  switchMap,
  filter,
  distinctUntilChanged,
} from "rxjs/operators";
import { AbstractProcessLauncher } from "../../../../../shared/abstracts/abstract-process-launcher";
import { Router } from "@angular/router";


const log = new Logger("EditVehicleModalComponent");

@Component({
  selector: "app-add-vehicle",
  templateUrl: "./add-vehicle.component.html",
  styleUrls: ["./add-vehicle.component.scss"],
})
export class AddVehicleComponent extends AbstractProcessLauncher implements OnInit {
  @Output() event: EventEmitter<any> = new EventEmitter();
  @Input() vehicle: Vehicle;

  form: FormGroup;
  hierarchyType: GetHierarchyWithoutChildrensResultDto[] = [];

  isLoading: boolean = false;
  isSaving: boolean = false;
  editMode: boolean = false;
  hasTagCondition: boolean = false;
  mask: string = "000.000.000-009";
  vehicleCategoryAxleWheelTypes: Array<VehicleCategoryAxleWheelType>;
  ListVehicleType: VehicleType;
  ListBrandTypes: BrandType;
  editVehicle: boolean;

  constructor(
    protected myVehiclesService: MyvehiclesService,
    protected notifyService: NotifyService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    protected router: Router
  ) {
    super(router);
  }

  ngOnInit() {
    this.hierarchyType = [];
    this.isLoading = true;
    this.editMode = !!this.vehicle;
    this.hasTagCondition = !!this.vehicle?.hasTag

    this.getBrandTypes();
    this.getVehicleType();
    this.getHierarchyType();

    if (this.vehicle != null)
      this.getVehicleCategoryAxleWheelType(this.vehicle.vehicleType);

    this.initForm();
    this.setAggregateDriverValidator();

    this.form
      .get("licensePlate")
      .valueChanges.pipe(
        distinctUntilChanged(),
        filter((val) => val.length === 7),
        switchMap((plate) => {
          return this.myVehiclesService.getVehicleBoard(plate);
        })
      )
      .subscribe(
        (success) => {
          if (success != null) {
            this.onChangeVehicleType(success.vehicleType);
            this.form.patchValue(success);
            this.editVehicle = true;
          }
        },
        () => {
          this.editVehicle = false;
          this.vehicleCategoryAxleWheelTypes = [];
          this.form.setValue(this.vehicle);
        }
      );

    this.applyMask()
  }

  private getHierarchyType() {
    this.myVehiclesService
      .getHierarchyWithoutChildrens()
      .pipe(finalize(() => { }))
      .subscribe(
        (success) => {
          this.hierarchyType = success;
        },
        (error) => {
          this.notifyService.showWarning("Atenção", error);
          console.error(error);
          return;
        }
      );
  }

  private setAggregateDriverValidator() {
    if (this.form.value.documentNumber) {
      this.form.value.documentNumber.replace(/\D/g, "");
      const document = this.form.controls["documentNumber"];
      const rntrc = this.form.controls["rntrc"];
      const driver = this.form.controls["aggregateDriver"];
      const docValidators = [
        Validators.required,
        CpfCnpjValidator.isValidDocument(),
      ];

      if (driver.value === true) {
        document.setValidators(docValidators);
        rntrc.setValidators(Validators.required);
      }

      this.form.controls["aggregateDriver"].valueChanges.subscribe((value) => {
        if (value === true) {
          document.setValidators(docValidators);
          rntrc.setValidators(Validators.required);
        } else {
          document.reset("");
          rntrc.reset("");
          document.clearValidators();
          rntrc.clearValidators();
        }

        document.updateValueAndValidity();
        rntrc.updateValueAndValidity();
      });
    }
  }

  onSaveVehicle() {
    this.isSaving = true;

    if (this.editMode && this.form.valid) {
      this.myVehiclesService
        .onEditVehicle(this.form.getRawValue())
        .pipe(
          finalize(() => {
            this.isSaving = false;
          })
        )
        .subscribe(
          (success) => {
            this.event.emit();
            this.modalService.dismissAll();
            this.notifyService.showSuccess(
              "Atenção!",
              "Veículo editado com sucesso!"
            );
          },
          (e) => {
            console.error(e);
          }
        );
    } else {
      this.form.removeControl("id");

      this.myVehiclesService
        .onAddVehicle(this.form.value)
        .pipe(
          finalize(() => {
            this.isSaving = false;
          })
        )
        .subscribe(
          (success) => {
            this.notifyService.showSuccess(
              "Atenção!",
              "Veículo salvo com sucesso!"
            );
            this.event.emit(success);
            this.activeModal.close(success);
            this.dismissModal();
          },
          (e) => {
            console.error(e);
          }
        );
    }
  }

  onChangeVehicleType(vehicleType) {
    this.form.patchValue({ vehicleCategoryId: "" });
    this.getVehicleCategoryAxleWheelType(vehicleType);
  }

  private getVehicleCategoryAxleWheelType(idVehicleType) {
    this.myVehiclesService.getVehicleCategoryAxleWheelType(idVehicleType).then(
      (result) => {
        this.vehicleCategoryAxleWheelTypes = result;
      },
      (errors) => {
        log.error(errors);
      }
    );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getBrandTypes() {
    this.myVehiclesService
      .getBrandTypes()
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (success: any) => {
          this.ListBrandTypes = success;
        },
        (error) => {
          console.log("error:", error);
          return;
        }
      );
  }

  private getVehicleType() {
    this.myVehiclesService
      .getVehicleType()
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (success: any) => {
          this.ListVehicleType = success;
        },
        (error) => {
          console.log("error:", error);
          return;
        }
      );
  }

  private initForm() {
    let customForm = new Vehicle();

    if (this.editMode) {
      if (this.vehicle) customForm = this.vehicle;
    }

    this.form = new FormGroup({
      id: new FormControl(customForm.id),
      hierarchyId: new FormControl(
        customForm.hierarchyId ? customForm.hierarchyId : 0
      ),
      licensePlate: new FormControl(customForm.licensePlate, [
        Validators.required,
        Validators.maxLength(7),
      ]),
      model: new FormControl(customForm.model),
      nickName: new FormControl(customForm.nickName),
      vehicleBrandTypeId: new FormControl(customForm.vehicleBrandTypeId, [
        Validators.required,
        Validators.minLength(1),
      ]),
      vehicleCategoryId: new FormControl(customForm.vehicleCategoryId, [
        Validators.required,
        Validators.minLength(1),
      ]),
      vehicleType: new FormControl(customForm.vehicleType, [
        Validators.required,
        Validators.minLength(1),
      ]),
      aggregateDriver: new FormControl(customForm.aggregateDriver),
      rntrc: new FormControl(customForm.rntrc),
      documentNumber: new FormControl(customForm.documentNumber),
    });

    this.isLoading = false;
  }

  applyMask() {
    const documentNumberControl = this.form.get('documentNumber');
    const value = documentNumberControl.value;

    this.mask = "000.000.000-009";
    if (value.length > 11) {
      this.mask = "00.000.000/0000-00";
    }

    documentNumberControl.updateValueAndValidity();
  }

}
