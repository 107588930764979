import { HttpParams } from "@angular/common/http";

export class GetVehiclesQueryArguments {
  constructor(
    public orderBy: string = '',
    public search: string = '',
    public filter: number = undefined,
    public myFleetPanel: boolean = false,
    public page: number = 1,
    public pageSize: number = 10,
    public desc: boolean = false,
  ) { }

  get getHttpParams() {
    let httpParams = new HttpParams()
      .append("myFleetPanel", `${this.myFleetPanel}`)
      .append("page", `${this.page}`)
      .append("pageSize", `${this.pageSize}`)
      .append("SortOrder", this.desc ? "1" : "0")
      .append("sortField", `${this.orderBy}`)
      .append("search", `${this.search}`)
      .append("filter", `${this.filter}`);

    // if (!this.filter) return httpParams.delete("filter")

    return httpParams;
  }
}
