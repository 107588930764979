<button
  class="button-custom-filters"
  (click)="showFilterContent()"
  [class.active]="idFilter === filtersComponent.activeFilter"
>
  <span class="label">{{ labelTitle }}</span>

  <p class="button-text">{{ textValue }}</p>

  <span class="container-arrow">
    <img class="arrow" src="../../../../assets/img/ico-arrow-right-b.svg" />
  </span>
</button>

<div *ngIf="idFilter === filtersComponent.activeFilter" class="box-area-value">
  <div class="content-extend">
    <h4 class="mb-3">Selecione uma das opções:</h4>

    <mat-radio-group
      *ngFor="let step of stepsData"
      class="radio-group__label-black"
    >
      <mat-radio-button
        [id]="step.id"
        (click)="onSelection(step.id)"
        [checked]="radioSelected === step.id"
        [value]="step.id"
        name="radio"
      >
        {{ step.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
