<div>
  <h1 class="image-replacement Dashboard_Overview" [attr.data-e2e]="dataE2E" translate >
    {{ title }}
  </h1>

  @if (contractBlock == 3) {
<!--    <div class="financialBlock" (click)="showFinancialBlockModal()">-->
<!--      <img src="../../../assets/svg/white_alert.svg" alt="" />-->
<!--      <p id="block_title" data-e2e="financial-block-msg">-->
<!--        bloqueio financeiro-->
<!--      </p>-->
<!--    </div>-->
  }
</div>
