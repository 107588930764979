import { MyordersService } from "./services/myorder.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { GetAllCanceledReceivedOrdersQueryArguments } from "./dtos/get-all-canceled-received-orders-query.arguments";
import { finalize } from "rxjs/operators";
import { GetAllActiveOrdersQueryArguments } from "./dtos/get-all-active-orders-query.arguments";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AllActiveOrders } from "@models/old/allactiverders.model";
import { DeliveryAddressesModalComponent } from "src/app/shared/components/address/delivery-addresses-modal/delivery-addresses-modal.component";
import { TagReceiptModalComponent } from "src/app/shared/components/tag/tag-receipt-modal/tag-receipt-modal.component";

@Component({
  selector: "app-myorder",
  templateUrl: "./myorder.component.html",
  styleUrls: ["./myorder.component.scss"],
})
export class MyorderComponent implements OnInit {
  allActiveOrders: AllActiveOrders[] = [];
  allCanceledReceivedOrders: AllActiveOrders[] = [];
  isActiveOrdersLoading: boolean;
  isCanceledReceivedOrdersLoading: boolean;
  isShownPeriod: boolean;
  maxDate: Date;
  minDate: Date;
  argsActiveOrders: GetAllActiveOrdersQueryArguments;
  argsCanceledReceivedOrders: GetAllCanceledReceivedOrdersQueryArguments;
  allActiveOrdersFiltered: AllActiveOrders[] = [];
  dateOpen: boolean = false;
  dayCustom: Date[] = [];
  @ViewChild('status') status: ElementRef;

 modalityOptions2 = [
  { value: '0', label: 'Todos' },
  { value: '1', label: 'Pedido realizado' },
  { value: '3', label: 'Em separação' },
  { value: '4', label: 'Enviado para transportadora' },
  { value: '5', label: 'Em transporte' }
];

 modalityOptions1 = [
  { value: '0', label: 'Todos' },
  { value: '9', label: 'Pedido solicitado' },
  { value: '8', label: 'Aguardando pagamento' },
  { value: '12', label: 'Pagamento recusado' },
  { value: '11', label: 'Pagamento aprovado' },
  { value: '1', label: 'Pedido realizado' },
  { value: '3', label: 'Em separação' },
  { value: '4', label: 'Enviado para transportadora' },
  { value: '5', label: 'Em transporte' }
];


  constructor(
    protected myordersService: MyordersService,
    public datepipe: DatePipe,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public bsConfig: BsDaterangepickerConfig,
    private modal: NgbModal,
  ) {
    this.argsActiveOrders = new GetAllActiveOrdersQueryArguments();
    this.argsActiveOrders.tagRequestStatusTypeId = 0;
    this.argsCanceledReceivedOrders =
    new GetAllCanceledReceivedOrdersQueryArguments();
  }

  showDeliveryAddressesModal() {
    const modalRef = this.modal.open(DeliveryAddressesModalComponent);
    modalRef.componentInstance.fromOrder = false;
  }

  ngOnInit() {
    this.callApis();
    this.getAllCanceledReceivedOrders(this.argsCanceledReceivedOrders);
  }

  getAllActiveOrders(args: GetAllActiveOrdersQueryArguments) {
    this.argsActiveOrders.tagRequestId = "";
    this.argsActiveOrders.tagRequestStatusTypeId = 0;
    this.isActiveOrdersLoading = true;
    this.myordersService
      .getAllActiveOrders(args)
      .pipe(
        finalize(() => {
          this.isActiveOrdersLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.allActiveOrders = success;
          this.filterOrders();
        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

  getAllCanceledReceivedOrders(
    args: GetAllCanceledReceivedOrdersQueryArguments
  ) {
    this.isCanceledReceivedOrdersLoading = true;
    this.myordersService
      .getAllCanceledReceivedOrders(args)
      .pipe(
        finalize(() => {
          this.isCanceledReceivedOrdersLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.allCanceledReceivedOrders = success;
        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

  confirmReceiptTagRequest(allActiveOrder: AllActiveOrders) {
    this.bsModalRef = this.modalService.show(TagReceiptModalComponent);
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.tagRequestId = allActiveOrder.id;
    this.bsModalRef.content.amount = allActiveOrder.amount;
    this.bsModalRef.content.event.subscribe((result) => {
      if (result) {
        allActiveOrder.tagRequestStatusTypeId = 7;
        allActiveOrder.tagRequestStatusType = "Recebido";
        this.allCanceledReceivedOrders.push(allActiveOrder);
        this.allActiveOrders.splice(
          this.allActiveOrders.indexOf(allActiveOrder),
          1
        );
      }
    });
  }
  onFilterDate(values: Date) {
    this.dateOpen = false;
    if (values) {
      this.argsActiveOrders.startDate = values[0]?.toJSON();
      this.argsActiveOrders.endDate = values[1]?.toJSON();
    }
    this.callApis();
  }
  callApis() {
    this.getAllActiveOrders(this.argsActiveOrders);
    // this.getAllCanceledReceivedOrders(this.argsCanceledReceivedOrders);
  }
  changeFilter(value) {
    this.argsActiveOrders.tagRequestId = value;
    this.filterOrders();
  }
  changeStatus($event) {
    this.argsActiveOrders.tagRequestStatusTypeId = $event.value;
    this.filterOrders();
  }

  filterOrders() {
    let allActiveOrdersFiltered = this.allActiveOrders;
    if (this.argsActiveOrders?.tagRequestId) {
      allActiveOrdersFiltered = allActiveOrdersFiltered.filter((element) =>
        element?.id.toString().includes(this.argsActiveOrders?.tagRequestId)
      );
    }
    if (
      this.argsActiveOrders?.tagRequestStatusTypeId &&
      this.argsActiveOrders?.tagRequestStatusTypeId != 0
    ) {
      allActiveOrdersFiltered = allActiveOrdersFiltered.filter(
        (element) =>
          element?.tagRequestStatusTypeId ==
          this.argsActiveOrders?.tagRequestStatusTypeId
      );
    }
    this.allActiveOrdersFiltered = allActiveOrdersFiltered;
  }

  toggleDateCard() {
    this.dateOpen = !this.dateOpen;
  }
  resetFilters() {
    this.dateOpen = false;
    this.dayCustom = [];
    // this.callApis();
  }
}
