export enum ModalityType {
  PREPAID = 1,
  POSTPAID = 2,
}

export interface ContractDualTagDto {
  id: number;
  contractId: number;
  externalId: number;
  dualTagIntegrationTypeId: number;
  dualTagStatus: boolean;
  dualTagMonthlyFee: number;
  dualTagAcquisition: number;
  registrationDate: Date;
  registrationUserId: number;
  changeDate: Date;
  changeUserId: number;
  dualTagMonthlyExemptionStartDate: Date;
  dualTagMonthlyExemptionEndDate: Date;
}
