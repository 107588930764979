import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";
import { TripDocuments, VaucherRecharge } from "../../../vpr-credit-received/dtos/get-vpr-credit-received-query-result.dto";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "app-show-documents-modal",
  templateUrl: "./show-documents-modal.component.html",
  styleUrls: ["./show-documents-modal.component.scss"],
})
export class ShowDocumentsModalComponent implements OnInit{

  @Input() tripCode: string;
  @Input() tripDocuments: TripDocuments[];
  constructor(
    public modalRef: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
  }

}
