import { Component, OnInit, Input } from '@angular/core';
import { Contract, ContractBillingCuttingType } from '@models/old/contract.model';
import { MyContractService } from '@services/mycontract.service';

@Component({
  selector: 'app-pospago',
  templateUrl: './pospago.component.html',
  styleUrls: ['./pospago.component.css']
})
export class PospagoComponent implements OnInit {
  readonly cuttingTypeId = ContractBillingCuttingType;
  @Input() commercialCondition: Contract;
  contractBillingCuttingCycle: string;

  constructor(private myAccServ: MyContractService) { }

  ngOnInit() {
    this.contractBillingCuttingCycle = this.myAccServ.contractBillingCuttingCycle;
  }
}
