<div class="step-2">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase" translate>Transfer vehicle</h4>
    <a class="btn-close">
      <img src="assets/img/close.svg" />
    </a>
  </div>
  <div class="modal-body">
    <div class="modal-content col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-12"></div>
      </div>
      <div class="row row-modal-collapse">
        <div class="col-lg-4">
          <img src="../../../assets/img/bg-place.svg" />
        </div>
        <div class="col-lg-8">
          <h4 translate class="text-uppercase">Vehicle transferred</h4>
          <div class="sub-title">
            <span *ngIf="success.getVehicleTagResult.eVehicleTransferType == 1">
              TAG do veículo atual mantida
            </span>
            <span *ngIf="success.getVehicleTagResult.eVehicleTransferType == 2">
              TAG do veículo atual cancelada
            </span>
            <span *ngIf="success.getVehicleTagResult.eVehicleTransferType == 3">
              TAG do veículo atual substituída
            </span>
          </div>
          <!-- <div class="row-box-yellow gray" *ngIf="success.getVehicleTagResult.eVehicleTransferType == 1">
                    <span class="box-yellow gray">
                        <b>T</b>odas as tags vinculadas tem um prazo de até 12h para <br />
                        serem ativadas em todos os estabelecimentos do Brasil.
                    </span>
                    </div> -->
          <div
            class="row-box-yellow gray"
            *ngIf="success.getVehicleTagResult.eVehicleTransferType == 3"
          >
            <span class="box-yellow gray">
              <b>A</b> TAG atual será inutilizada após a substituição, não<br />
              podendo ser vinculada novamente e tem um prazo de até 12h <br />
              para serem ativadas em todas as praças de pedágio do Brásil.
            </span>
          </div>
          <div class="SuccessinfosContainer">
            <div class="row Successinfos">
              <div class="col-lg-5 text-right">PLACA:</div>
              <div class="col-lg-7 text-left">
                {{
                  success.getVehicleTagResult.licensePlate | mask: 'AAA-AAAA'
                }}
              </div>
            </div>
            <div class="row Successinfos">
              <div class="col-lg-5 text-right">TAG SUBSTITUIDA:</div>
              <div class="col-lg-7 text-left">
                {{ success.getVehicleTagResult.oldSerialTag }}
              </div>
            </div>
            <div class="row Successinfos">
              <div class="col-lg-5 text-right">TAG ATUAL:</div>
              <div class="col-lg-7 text-left">
                {{ success.getVehicleTagResult.newSerialTag }}
              </div>
            </div>
            <div class="row Successinfos">
              <div class="col-lg-5 text-right">SERVIÇO:</div>
              <div
                class="col-lg-7 text-left serviceType"
                *ngIf="
                  success.getVehicleTagResult.contractVehicleTagServiceTypeId ==
                  1
                "
              >
                Estacionamento + Pedágio
              </div>
              <div
                class="col-lg-7 text-left serviceType"
                *ngIf="
                  success.getVehicleTagResult.contractVehicleTagServiceTypeId ==
                  2
                "
              >
                Pedágio
              </div>
            </div>
            <div class="row line"></div>
            <div class="row Successinfos">
              <div class="col-lg-5 text-right">CNPJ ANTERIOR:</div>
              <div class="col-lg-7 text-left serviceType">
                {{
                  success.getVehicleTagResult.oldDocument
                    | mask: '00.000.000/0000-00'
                }}
                - Contrato
                {{ success.getVehicleTagResult.oldContractId }}
              </div>
            </div>
            <div class="row Successinfos">
              <div class="col-lg-5 text-right">CNPJ ATUAL:</div>
              <div class="col-lg-7 text-left serviceType">
                {{
                  success.getVehicleTagResult.newDocument
                    | mask: '00.000.000/0000-00'
                }}
                - Contrato
                {{ success.getVehicleTagResult.newContractId }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center content-bottom">
          {{
            success.getVehicleTagResult.registrationDate | date: 'dd/MM/yyyy'
          }}
          -
          {{ success.getVehicleTagResult.registrationDate | date: 'HH:mm' }} por
          {{ success.getVehicleTagResult.registrationUserFullName }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-gray">
    <button type="button" class="btn btn-secondary" (click)="dismissModal()">
      Fechar
    </button>
  </div>
</div>
