import moment from "moment";
import { TODAY } from "../variables/dates";

export default function generateCVS(response: any) {
  let data = {
    file: new Blob([response.body], {
      type: response.headers.get("Content-Type"),
    }),
  };
  return data;
}

export function prepareDownloadCVS(data: any, fileName: string, nameComplement?: string) {
  const _date = moment(TODAY).format("DDMMYYYY");

  const element = document.createElement("a");
  var newBlob = new Blob([data.file], { type: "text/csv" });

  element.href = URL.createObjectURL(newBlob);
element.download =  `${fileName}_${nameComplement ? nameComplement + '_' : ''}${_date}.csv`;
  element.click();
}
