import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import { TollBalanceService } from "../../services/toll-balance.service";
import { PostPaidContractBalance } from "@models/old/postpaidcontractbalance.model";
import { VPRBalance } from "@models/old/VPRBalance.model";
import { EventEmitterService } from "@services/event-emitter.service";
import { AlertCreditModalComponent } from "../alert-credit-modal/alert-credit-modal.component";
import {
  progressBarAnimation,
  slideUpDownAnimation
} from "@modules/commom/header/componentes/customer-header/components/toll-balance/animation/toll-balance-animation";

@Component({
  selector: "app-toll-balance",
  templateUrl: "./toll-balance.component.html",
  styleUrls: ["./toll-balance.component.scss"],
  animations: [
    progressBarAnimation(),
    slideUpDownAnimation()
  ],
})
export class TollBalanceComponent implements OnInit, OnChanges {
  widthProgressBar: string;
  isOpenProgressBar: boolean;
  isOpenBox: boolean;
  public postPaidContractBalance: PostPaidContractBalance;
  public VPRBalance: VPRBalance;
  contractId: string;
  @Output() emitPostPaidContractBalance: EventEmitter<PostPaidContractBalance> = new EventEmitter<PostPaidContractBalance>;
  @Input() companyName!: any;

  constructor(
    protected tollBalanceService: TollBalanceService,
    private modalService: NgbModal
  ) {
    EventEmitterService.get("UpdateBalanceAfterChangeRoute").subscribe((data) =>
      this.refresh()
    );
    EventEmitterService.get("clearCacheAfterEditContractName").subscribe(() =>
      this.clearVPRcache()
    );
   }

  clearVPRcache() {
    this.tollBalanceService.deleteVPRCacheAndRefresh();
  }

  refresh() {
    this.getPostPaidContractBalance();
    this.getVPRBalance();
    this.isOpenProgressBar = false;
    this.isOpenBox = false;
    this.VPRBalance = {
      contractId: 0,
      totalVPRBalance: 0,
      contractName: "",
    };
  }

  ngOnInit() {
    this.getPostPaidContractBalance();
    this.getVPRBalance();
    this.isOpenProgressBar = false;
    this.isOpenBox = false;
    this.VPRBalance = {
      contractId: 0,
      totalVPRBalance: 0,
      contractName: "",
    };
  }

  ngOnChanges(changes: SimpleChanges) {
      this.contractId = changes?.companyName?.currentValue?.contractId;
  }

  openBoxInfo() {
    this.isOpenBox = !this.isOpenBox;
  }

  getPostPaidContractBalance() {
    this.tollBalanceService.getPostPaidContractBalance().then(
      (result) => {
        if (result) {
          this.postPaidContractBalance = result;
          this.isOpenProgressBar = true;
          this.widthProgressBar = result.balanceUsedPercent + "%";
          this.emitPostPaidContractBalance.emit(this.postPaidContractBalance);
        }
      }
    );
  }

  getVPRBalance() {
    this.tollBalanceService.getVPRBalance().then(
      (result) => {
        this.VPRBalance = result;
      }
    );
  }

  showCreditAlertModal() {
    this.modalService.open(AlertCreditModalComponent);
  }
}
