
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LogService } from '@services/log.service';


@Injectable({
  providedIn: 'root'
})
export class AdduserModalService {

  private base = environment.apiTMCustomer;

  constructor(protected http: HttpClient, protected logger: LogService) { }

   public saveUser(form) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
      };
      const apiUrl = `${this.base}/Person/CreatePersonGuestUser`;
      const body = JSON.stringify(form); // -> JSON para Salvar.
      const result = this.http.post<any>(apiUrl, body, httpOptions);
      return result;
  }

}
