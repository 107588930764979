import {
  Component,
  Input,
  OnInit
} from "@angular/core";
import { PaymentObject } from "@models/payment.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-loose-recharge-confirmation',
  templateUrl: './loose-recharge-confirmation.component.html',
  styleUrls: ['./loose-recharge-confirmation.component.scss']
})

export class LooseRechargeConfirmationComponent implements OnInit {

  @Input() object: PaymentObject;
  @Input() response: any;

  constructor(
    public modal: NgbModal
  ) {}

  ngOnInit() {
  }

}
