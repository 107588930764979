import { Validators, AbstractControl } from '@angular/forms';

export class CpfCnpjValidator {
    constructor() {}
 
    static cpfLength = 11;
    static cnpjLength = 14;
  

    /**
     * Verifica se é um CPF válido
    */
     static isValidCpf(){
      return (control: AbstractControl): Validators => {
       let error = this.verifyInvalidCpf(control) ? { cpfInvalid: true } : null;
       return error;
    } 
  }

    static verifyInvalidCpf(control) {
        const cpf =  control.value ? control.value.replace(/\D/g, '') : '';
        var Soma = 0;

        // Verifica o tamanho da string.
        if (cpf.length !== CpfCnpjValidator.cpfLength) {
          return true;     
        }

        // Verifica se todos os dígitos são iguais.
        if (/^([0-9])\1*$/.test(cpf)) {
          return true     
        }

    // Os seis blocos seguintes de funções vão realizar a validação do CPF propriamente dito, conferindo se o DV bate. Caso alguma das funções não consiga verificar
    // o DV corretamente, retornará true, indicando que é um CPF inválido

    //Multiplica cada digito por numeros de 1 a 9, soma-os e multiplica-os por 10. Depois, divide o resultado encontrado por 11 para encontrar o resto
    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    var Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(cpf.substring(9, 10))) {
      return true;     
    }

    Soma = 0;
    for (let k = 1; k <= 10; k++) {
      Soma = Soma + parseInt(cpf.substring(k - 1, k)) * (12 - k)
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(cpf.substring(10, 11))) {
      return true;    
    }

    return null;
 }

   /**
     * Verifica se é um CNPJ válido
    */

  static isValidCnpj(){
    return (control: AbstractControl): Validators => {
     let error = this.verifyInvalidCnpj(control) ? { cnpjInvalid: true } : null;
     return error;
  } 
}

 static verifyInvalidCnpj(control) {
        const Cnpj =  control.value ? control.value.replace(/\D/g, '') : '';

        // Verifica o tamanho da string.
        if (Cnpj.length !== CpfCnpjValidator.cnpjLength) {
          return true;
        }
    
        // Verifica se todos os dígitos são iguais.
        if (/^([0-9])\1*$/.test(Cnpj)) {
          return true;
        }
    
        // A seguir é realizado o cálculo verificador.    
        var tamanho = Cnpj.length - 2;
        var numeros = Cnpj.substring(0, tamanho);
        var digitos = Cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
    
        // Os quatro blocos seguintes de funções irá reaizar a validação do CNPJ propriamente dito, conferindo se o DV bate. Caso alguma das funções não consiga verificar
        // o DV corretamente, retornará true, indicando que é um CNPJ inválido. 
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) {
            pos = 9;
          }
        }
    
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) {
          return true;
        }
    
        tamanho = tamanho + 1;
        numeros = Cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let k = tamanho; k >= 1; k--) {
          soma += numeros.charAt(tamanho - k) * pos--;
          if (pos < 2) {
            pos = 9;
          }
        }
    
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)) {
          return true;
        }
    
        return null;
 }


    /**
     * Verifica se é um CNPJ ou CPF válido
    */
    static isValidDocument(){
        return (control: AbstractControl): Validators => {
            if(this.verifyInvalidCpf(control) && this.verifyInvalidCnpj(control)){
               return { documentInvalid: true };
            } 
            
        return null;
      };
    }

}