<div class="transferVprComponent">
  <div class="modal-header">
    <div class="box-title">
      <span>Transferir Saldo</span>
    </div>

    <button
      data-e2e="modal-top-close"
      (click)="activeModal.close()"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="baloon">
      <span>O</span> saldo de vale-pedágio poderá ser transferido para outro
      veículo com tag ativa que faça parte do mesmo contrato
    </div>

    <div class="main-grid">
      <div class="vehicle-details">
        <h4>Dados do vínculo atual</h4>
        <div class="details">
          <div class="values">
            <span>Veículo</span>
            <span>{{ vehicle.licensePlate | mask: 'SSS-0A00' }}</span>
          </div>
          <hr />
          <div class="values">
            <span>Tag</span>
            <span>{{ vehicle.serialTag }}</span>
          </div>
          <hr />
          <div class="values">
            <span>Saldo vale-pedágio</span>
            <span>{{
              balance | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
            }}</span>
          </div>
        </div>
      </div>
      <div class="select-vehicle">
        <h4>Escolha um veículo cadastrado para transferir o saldo</h4>
        <app-vehicles-by-filter
          (changeVehicle)="getSelectedVehicle($event)"
          [filterType]="EFilterTypes.vehiclesWithActiveTag"
        ></app-vehicles-by-filter>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-4">

    <app-borderless-button
      [description]="'Voltar'"
      [id-html]="'btn-previous'"
      [data-e2e]="'btn-previous'"
      [isBtnWithBorder]="true"
      [buttonColor]="'secundary'"
      (emitterEvtClick)="previous()">
    </app-borderless-button>

    <app-borderless-button
      [description]="'Transferir saldo e cancelar tag'"
      [id-html]="'btn-next'"
      [data-e2e]="'btn-next'"
      [isBtnWithBorder]="true"
      [buttonColor]="'primary'"
      [isDisable]="!selectedVehicle"
      (emitterEvtClick)="next()"
    >
    </app-borderless-button>
  </div>
</div>
