export interface ContractFee {
  VolumeFee?: ContractFeeValues;
  InativeTag?: ContractFeeValues;
  DualTagMonthlyFee?: ContractFeeValues;
  DualTagAcquisition?: ContractFeeValues;
  DefaultInterest?: ContractFeeValues;
  LateFee?: ContractFeeValues;
}

export interface ContractFeeValues {
  id: number;
  contractId: number;
  contractFeeTypeId: ContractFeeType;
  value: number;
  percentage: any
  contractFeeName: string;
  contractFeeDescriptionPT: string;
  exemptionStartDate: Date | string;
  exemptionEndDate: Date | string;
  mustChargeFee: any;
}

export enum ContractFeeType {
  VolumeFee = 1,
  InativeTag = 2,
  DualTagMonthlyFee = 3,
  DualTagAcquisition = 4,
  DefaultInterest = 5, //Juros Moratórios
  LateFee = 6, //Multa moratória
}
