
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChangePasswordModalHttpService {
  
  constructor(private http: HttpClient) {}

  putAlterPassword(Passwords) {
    return this.http.put(
      environment.apiTMIdentityServer +
        `/Customer/User/CurrentUser/AlterPassword`,
        Passwords
    );
  }


}