import { HttpParams } from '@angular/common/http';

export class GetVehiclesBalanceVPRQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search:string;
  IsOnlyGreaterThanZero:boolean;

  get httpParans() {
    let httpParams = new HttpParams()
    .append('page', this.page.toString())
    .append('pageSize', this.pageSize.toString())
    .append('sortField', this.orderBy)
    .append('IsOnlyGreaterThanZero', this.IsOnlyGreaterThanZero.toString())
    .append('SortOrder', this.desc ? '1' : '0')
    .append('search', this.search);
    return httpParams;

  };
}
