import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { environment } from "@env";
import { VehicleStock } from "@models/vehicle.model";
import { Order } from "@models/order.model";
import { Tag } from "@models/tag.model";
import { Financial } from "@models/old/dashboard/financial.model";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private _baseVehicle = environment.apiTMVehicle;
  private _baseTag = environment.apiTMTag;
  private _baseFinancial = environment.apiTMFinancial;

  notExistsItems: boolean = false; // exibe a mensagem se array for 0 item.

  constructor(
    protected _http: HttpClient,
  ) {}

  getDashboardVehicle(): Observable<VehicleStock> {
    return this._http.get<VehicleStock>(
      `${this._baseVehicle}/Vehicle/GetDashboardVehicle`
    );
  }

  getDashboardTag(): Observable<Tag> {
    return this._http.get<Tag>(`${this._baseTag}/Tag/GetDashboardTag`);
  }

  getDashboardTagOrder(): Observable<Order> {
    return this._http.get<Order>(`${this._baseTag}/Tag/GetLastOrderDetails`);
  }

  getInvoicesDashboard(): Observable<Financial> {
    return this._http.get<Financial>(
      environment.apiTMFinancial + `/api/Bill/Last`
    );
  }

  getTotalDashboardFinancial(billId: number): Observable<number> {
    return this._http.get<number>(
      `${this._baseFinancial}/api/BillItem/TotalBill/` + billId
    );
  }

  getPersonDetailsForNPS(){
    let url = environment.apiTMCustomer+`/Person/GetPersonDetailsForNPS`;
    return this._http.get<any[]>(url);
  }

}
