import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LogService } from './log.service';
import { PersonAddress } from '@models/old/personAddress.model';
import { LegalPersonAddress } from '@models/old/legalpersonaddresses.model';
import { GetDeliveryAddressesQueryArguments } from '@models/get-delivery-addresses-query-arguments.model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private base = environment.apiTMCustomer;
  private showErrors = environment.showErrors;

  constructor(protected http: HttpClient, protected logger: LogService) { }

  /**
   * Salva novo endereço.
   * @param {PersonAddress} PersonAddress
   */
  public registerPersonAddress(entity: PersonAddress): Observable<any> {
    try {
        const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        const apiUrl = `${this.base}/PersonAddress/`;
        const body = JSON.stringify(entity); // -> JSON para Salvar.
        const result = this.http.post<any>(apiUrl, body, httpOptions);
        return result;
    } catch (e) {
      if (this.showErrors) {
        this.logger.showError(`Erro em 'TaqRequestService - registerPersonAddress' ${e.status}: ${JSON.stringify(e.message)}`);
      }
    }
  }

  public alterPersonAddress(entity: PersonAddress): Observable<any> {
    try {
        const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        const apiUrl = `${this.base}/PersonAddress/`;
        const body = JSON.stringify(entity); // -> JSON para Salvar.
        const result = this.http.put<any>(apiUrl, body, httpOptions);
        return result;
    } catch (e) {
      if (this.showErrors) {
        this.logger.showError(`Erro em 'TaqRequestService - registerPersonAddress' ${e.status}: ${JSON.stringify(e.message)}`);
      }
    }
  }

  public deletePersonAddress(id: number) {
    return this.http.delete<PersonAddress>(`${this.base}/PersonAddress/${id}`);
  }

  getPersonAddressByContractId(args: GetDeliveryAddressesQueryArguments) {
    return this.http.get<LegalPersonAddress>(`${this.base}/personAddress/getPersonAddressByContractId`, { params: args.httpParans });
  }

  
}
