import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { MycontractComponent } from '../mycontract.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "contrato",
      component: MycontractComponent,
      canActivate: [authGuardCustomer],
      data: { roles: "101136", contractRequired: true },
    }
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class MycontractRoutingModule {}
