<div class="succesComponent">
  <div class="modal-header">
    <div class="box-title">
      <span>Tag Cancelada</span>
    </div>

    <button
      data-e2e="modal-top-close"
      (click)="dismissModal()"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <img class="img-success" src="assets/svg/feedback-success.svg" alt="" />

    <div class="text-body">
      <h4>Cancelamento de tag realizado com sucesso!</h4>
      <h5>Veículo {{ isExclude ? 'excluído' : 'mantido' }} da frota</h5>
      <img src="assets/svg/icon-truck.svg" /><span>
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}
      </span>

      <h5>Tag Cancelada</h5>
      <img src="assets/svg/icon-tag.svg" /><span>
        {{ vehicle.serialTag }}
      </span>

      <p class="text-gray">
        As praças de pedágio poderão levar até 6 horas para processar o
        cancelamento da TAG.
      </p>
    </div>

    <div *ngIf="balance > 0" class="complement-body">
      <h5>Transferência de saldo realizada</h5>
      <p class="text-gray">Saldo residual</p>
      <img src="assets/svg/icon-money.svg" /><span>
        {{ balance | currency: 'BRL' : 'symbol' : '1.2-2' }}
      </span>

      <p class="text-gray">
        <span class="left">De:</span><span class="span-right">Para:</span>
      </p>

      <img src="assets/svg/icon-truck.svg" /><span>
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}
      </span>
      <img class="icon-arrow" src="assets/svg/icon-arrow-navigate.svg" />
      <img src="assets/svg/icon-truck.svg" /><span>
        {{ vehicleToReceiveVPR.licensePlate | mask: 'SSS-0A00' }}
      </span>
    </div>
  </div>

  <div class="modal-footer">
    <div class="content-buttons">

      <app-borderless-button
        [description]="'Fechar'"
        [id-html]="'cancel'"
        [data-e2e]="'cancel'"
        [isBtnWithBorder]="true"
        [buttonColor]="'secundary'"
        (emitterEvtClick)="dismissModal()">
      </app-borderless-button>
    </div>
  </div>
</div>
