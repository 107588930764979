import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserProfileService} from "@services/user-profile.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  private isCustomerMenu: boolean = false;
  private isBackOfficeMenu: boolean = false;
  private urlActive: string;

  constructor(private router: Router,
              private manageProfile: UserProfileService) {
  }

  ngOnInit() {
    this.isCustomerMenu = this.manageProfile.isCustomerOrRelationshipProfile();
    this.isBackOfficeMenu = this.manageProfile.isBackOffice();
    this.urlActive = this.router.routerState.snapshot.url;
  }

  private isURLBackoffice(): boolean {
    return this.urlActive && (this.urlActive.split('/').includes('backoffice'));
  }

  handleCustomerHeader() {
    return this.isCustomerMenu || (this.isBackOfficeMenu && !this.isURLBackoffice())
  }

  handleBackOfficeHeader() {
    return this.isBackOfficeMenu && this.isURLBackoffice();
  }

}
