import { formatCurrency, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReportQueryArguments } from '../dtos/report-query-arguments.dto';
import { environment } from 'src/environments/environment';
import { Dto } from '@models/dto.model';
import {TransactionSale} from "@models/backoffice/transaction.model";

@Injectable({
  providedIn: 'root',
})
export class FinancialSalesReportService {
  constructor(private http: HttpClient) {}

  public base = environment.apiTMSystemAccount;

  getTransactionSales(
    args: ReportQueryArguments,
  ): Observable<Dto<TransactionSale[]>> {
    return this.http
      .get<Dto<TransactionSale[]>>(
        this.base + `/api/PrePaidTransaction/GetSales`,
        { params: args.httpParams },
      )
      .pipe(
        map((response) => {
          let newItems: TransactionSale[] = [];
          response.data.forEach((item) => {
            item.expectedClearingDate = item.expectedClearingDate.split('T')[0];
            let newTransactionSale = {
              ...item,
              accountBalance: formatCurrency(
                Number(item.accountBalance),
                'pt-BR',
                'R$ ',
              ),
              transactionDate: formatDate(
                item.transactionDate,
                'dd/MM/yyyy HH:mm:ss',
                'pt-BR',
              ),
              expectedClearingDate: formatDate(
                item.expectedClearingDate,
                'dd/MM/yyyy',
                'pt-BR',
              ),
              totalAmount: formatCurrency(
                Number(item.totalAmount),
                'pt-BR',
                'R$ ',
              ),
              rechargeFee: item.rechargeFee + '%',
              transactionStatusChangeDate: formatDate(
                item.transactionStatusChangeDate,
                'dd/MM/yyyy HH:mm:ss',
                'pt-BR',
              ),
              creditCardBrandType: item.creditCardBrandType.toUpperCase(),
            };
            if (newTransactionSale.administrationFee !== null) {
              newTransactionSale.administrationFee =
                newTransactionSale.administrationFee.replace('-', '') + '%';
            }

            if (newTransactionSale.expectedClearingDate === '01/01/0001') {
              newTransactionSale.expectedClearingDate = '-';
            }

            if (
              newTransactionSale.transactionStatusChangeDate ===
              '01/01/0001 00:00:00'
            ) {
              newTransactionSale.transactionStatusChangeDate = '-';
            }
            newItems.push(newTransactionSale);
          });

          response.data = newItems;
          return response;
        }),
      );
  }

  getTransactionSalesCsv(args: ReportQueryArguments) {
    return this.http
      .get(this.base + `/api/PrePaidTransaction/GetSalesCsv`, {
        observe: 'response',
        responseType: 'blob',
        params: args.httpParams,
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),
            filename: response.headers.get('filename'), // pegar nome do arquivo caso content-disposition esteja acessível.
          };
          return data;
        }),
      );
  }
}
