import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { FormControl } from '@angular/forms';
import { CancelTagBackOfficeComponent } from '../../cancel-tag-backoffice.component';
import {VehiclesDto} from "@models/backoffice/vehicles-backoffice.dto";
import {filtersVehicles} from "@models/backoffice/filterVehicles.dto";

@Component({
  selector: 'app-transfer-vpr',
  templateUrl: './transfer-vpr.component.html',
  styleUrls: ['./transfer-vpr.component.scss'],
})
export class TransferVprComponent {
  @Input() vehicle: VehiclesDto;
  @Input() balance: number;
  @Input() cancelTagForm: FormControl;
  @Input() selectedVehicle: VehiclesDto;
  EFilterTypes = filtersVehicles;

  constructor(
    private _modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {}

  next() {
    let modalConfirm = this._modalService.open(ConfirmationComponent);
    modalConfirm.componentInstance.vehicle = this.vehicle;
    modalConfirm.componentInstance.vehicleToReceiveVPR = this.selectedVehicle;
    modalConfirm.componentInstance.cancelTagForm = this.cancelTagForm;
    modalConfirm.componentInstance.balance = this.balance;
    this.activeModal.close();
  }

  previous() {
    let modalForm = this._modalService.open(CancelTagBackOfficeComponent);
    modalForm.componentInstance.cancelTagForm = this.cancelTagForm;
    modalForm.componentInstance.vehicle = this.vehicle;
    modalForm.componentInstance.optionSelected = true;
    this.activeModal.close();
  }

  getSelectedVehicle(event: VehiclesDto) {
    this.selectedVehicle = event;
  }
}
