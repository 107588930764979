<form #chargebackForm="ngForm">
  <div class="ChargebackApproveComponent">
    <div class="modal-header">
      <h4
        data-e2e="modal-title"
        class="modal-title"
        translate
        *ngIf="option == true"
      >
        Aprovar Chargeback?
      </h4>
      <h4
        data-e2e="modal-title"
        class="modal-title"
        translate
        *ngIf="option == false"
      >
        Reprovar Chargeback?
      </h4>
      <a
        data-e2e="close-modal-top-btn"
        class="btn-close"
        (click)="dismissModal()"
      >
        <img src="assets/svg/close.svg" />
      </a>
    </div>

    <div class="modal-body" *ngIf="!isLoading">
      <div class="modal-main">
        <div class="chargeback-details">
          <p>
            {{ transaction?.companyName }}
            {{
              transaction?.contractName !== null
                ? ' - ' + transaction?.contractName
                : ''
            }}
          </p>
          <p>
            CNPJ:
            {{ transaction?.documentNumber | mask: '00.000.000/0000-00' }}
          </p>
          <p>Contrato: {{ transaction?.contractId }}</p>
        </div>

        <p>
          Tipo de transação:
          <strong translate>{{
            transaction?.transactionTypeDescription
          }}</strong>
        </p>
        <p style="padding-bottom: 1rem">
          Valor: <strong>{{ transaction?.totalAmount }}</strong>
        </p>

        <div class="label-select">
          <label for="select-input" *ngIf="option == true"
            >Por favor, informe o motivo de aprovação do chargeback</label
          >
          <label for="select-input" *ngIf="option == false"
            >Por favor, informe o motivo de reprovação do chargeback</label
          >
          <input
            type="text"
            id="reason-input"
            name="reason"
            data-e2e="refusalReason"
            [(ngModel)]="reason"
            #reasonInput="ngModel"
            [maxLength]="200"
            required
          />
        </div>

        <p class="text-reason" *ngIf="option == true">
          Ao tomar a decisão de aprovar o chargeback, é importante saber que o
          contrato associado a essa transação será desbloqueado de forma
          automática.
        </p>
        <p class="text-reason" *ngIf="option == false">
          Ao tomar a decisão de reprovar o chargeback, é importante saber que o
          contrato associado a essa transação irá permanecer bloqueado.
        </p>
      </div>
    </div>

    <div class="modal-loader" *ngIf="isLoading">
      <app-loader
        [isLoading]="isLoading"
        message="{{ 'Loading...' | translate }}"
      ></app-loader>
    </div>

    <div class="modal-footer">
      <div class="content-buttons w-100">
        <button
          data-e2e="cancel"
          class="btn btn-secondary custom"
          (click)="dismissModal()"
          translate
        >
          Cancelar
        </button>
        <button
          type="submit"
          data-e2e="confirm-chargeback"
          class="btn btn-secondary"
          (click)="approveOrDisapprove()"
          [disabled]="!chargebackForm.valid"
          translate
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</form>
