import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionPartnersConciliationRoutingModule } from './routes/transaction-partners-conciliation-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionPartnersConciliationComponent } from './transaction-partners-conciliation.component';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ImportConciliationDocumentModalComponent } from './components/import-conciliation-document-modal/import-conciliation-document-modal.component';
import { ConciliationFeedbackModalComponent } from './components/conciliation-feedback-modal/conciliation-feedback-modal.component';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { OutlinedSelectComponent } from '../../../shared/select/outlined-select/outlined-select.component';



@NgModule({

    imports: [
        CommonModule,
        TransactionPartnersConciliationRoutingModule,
        TranslateModule,
        SharedModule,
        CoreModule,
        NgbModule,
        NgbDropdownModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        BorderlessButtonComponent,
        OutlinedSelectComponent,
    ],
    declarations: [TransactionPartnersConciliationComponent, ImportConciliationDocumentModalComponent, ConciliationFeedbackModalComponent],
})
export class TransactionPartnersConciliationModule { }
