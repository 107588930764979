import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from "../../../../../core/auth/auth.service";
import {AccountService} from "@modules/backoffice/request/services/account.service";
import {RoutesPaths} from "../../../../../core/utils/routes/routes-paths";


@Component({
  selector: 'app-request-details-popover',
  templateUrl: './request-details-popover.component.html',
  styleUrls: ['./request-details-popover.component.scss'],
})
export class RequestDetailsPopoverComponent {
  @Input() order: any;
  @Input() id: string;

  constructor(
    private authService: AuthService,
    private accService: AccountService,
    private router: Router,
  ) {}

  redirectToRequestDetail(order): void {
    this.authService.setContractId(order.contractId);

    this.accService.getContractsById().subscribe((resp) => {
      this.authService.setContractModalityTypeId(
        resp.contract.contractModalityTypeId.toString()
      );
      this.authService.removeContractId();

      this.router.navigate(['/backoffice/logistica/pedidos-tag/detalhe-pedido/', order.id]);
    });
  }
}
