import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {RoleDto} from './dtos/role.dto';
import {map, catchError} from 'rxjs/operators';
import {LocalstorageService} from '@services/localstorage.service';
import {UserProfileService} from "@services/user-profile.service";

// TODO: COlocar a Url correta
const API_CUSTOMER_ROLES = `${environment.apiTMIdentityServer}/CustomerPortal/User/CurrentUser/Permissions`
const API_BACKOFFICE_ROLES = `${environment.apiTMIdentityServer}/CustomerService/User/CurrentUser/Permissions`

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  data: Object;
  lastContractIdChecked: string;

  constructor(protected http: HttpClient,
              private localstorageService: LocalstorageService,
              private manageProfileService: UserProfileService) {
    this.lastContractIdChecked = "";
  }

  public checkRoles(r: string) {

    if (!r) {
      return true;
    }

    const roles = this.getUserRoles();

    let rolesToCheck = r.split(",");
    for (let i = 0; i < roles.length; i++) {
      const dataRole = roles[i];

      for (let j = 0; j < rolesToCheck.length; j++) {
        const role = rolesToCheck[j];

        if (role.trim() == dataRole) {
          return true;
        }
      }
    }
  }

  private getUserRoles(): string[] {
    const localStorage = this.localstorageService;
    const manageProfile = this.manageProfileService;


    switch (true) {
      case manageProfile.isCustomer():
        return localStorage.getRoles();

      case manageProfile.isRelationship():
        return localStorage.getRoles();

      case manageProfile.isBackOffice():
        return localStorage.getRolesBackOffice();

      default:
        return [];
    }
  }

  public setRolesOnStorage(isRolesBackOffice?: boolean) {
    const API = isRolesBackOffice ? API_BACKOFFICE_ROLES : API_CUSTOMER_ROLES;
    return this.http.get<Array<RoleDto>>(API)
      .pipe(map(dataRoles => {
        let roles = [];

        for (let i = 0; i < dataRoles.length; i++) {
          const dataRole = dataRoles[i];
          roles.push(dataRole.id.toString())
        }

        this.saveRolesToLocalStorage(isRolesBackOffice, roles);

        return roles;

      }), catchError(() => {
        this.localstorageService.setRoles([]);
        this.localstorageService.setRolesBackOffice([]);
        return [];
      }));
  }

  private saveRolesToLocalStorage(isRolesBackOffice: boolean, roles: any): void {
    if (isRolesBackOffice) {
      this.localstorageService.setRolesBackOffice(roles)
    } else {
      this.localstorageService.setRoles(roles);
    }
  }

  public removeContractId() {
    return sessionStorage.removeItem('contractId');
  }

  public removeContractModalityTypeId() {
    return sessionStorage.removeItem('contractModalityTypeId');
  }

  public removeContractBlockLevel() {
    return sessionStorage.removeItem('contractBlockLevel');
  }

  public getAttendanceDocument() {
    return sessionStorage.getItem('AttendanceDocument');
  }

  public setAttendanceDocument(document: string) {
    sessionStorage.setItem('AttendanceDocument', document);
  }

  public setContractId(contractId: string | number) {
    sessionStorage.setItem('contractId', String(contractId ?? 0));
  }

  public getContractId(): string {
    const contract = sessionStorage.getItem('contractId');
    return contract ?? '0';
  }

  getContractModalityTypeId(): string {
    const contractModalityTypeId: string = sessionStorage.getItem('contractModalityTypeId');
    return contractModalityTypeId ?? '0';
  }

  setContractModalityTypeId(contractModalityTypeId: string | number) {
    if (contractModalityTypeId == null) {
      sessionStorage.removeItem('contractModalityTypeId');
      return;
    }

    sessionStorage.setItem('contractModalityTypeId', String(contractModalityTypeId));
  }

  getContractBlockLevel(): string {
    const contractBlockLevel: string = sessionStorage.getItem('contractBlockLevel');
    return contractBlockLevel ?? '0';
  }

  setContractBlockLevel(contractBlockLevel: string | number) {
    if (contractBlockLevel == null) {
      sessionStorage.removeItem('contractBlockLevel');
      return;
    }

    sessionStorage.setItem('contractBlockLevel', String(contractBlockLevel));
  }
}
