import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { map } from 'rxjs';
import {ContractAvailablesDto} from "@models/contractAvailables.dto";
import {Dto} from "@models/dto.model";
import {GetContractsBySearchFieldParams} from "@models/get-contracts-by-searchfield-params.model";

@Injectable({
  providedIn: 'root',
})
export class SelectProfileService {
  constructor(private http: HttpClient) {}

  getContractsAvailables(document: string) {
    return this.http.get<ContractAvailablesDto[]>(
      environment.apiTMCustomer + `/Contract/GetContractsByCpfCnpj/` + document,
    );
  }

  getContractsAttendance(params: GetContractsBySearchFieldParams) {
    return this.http.get<Dto<ContractAvailablesDto[]>>(
      environment.apiTMCustomer + `/Contract/GetContractsBySearchField`,
      {
        params: params.httpParams
      },
    );
  }

  getContractsPosPaid(params: GetContractsBySearchFieldParams) {
    return this.http
      .get<Dto<ContractAvailablesDto[]>>(
        environment.apiTMCustomer + `/Contract/GetContractsBySearchField`,
        {
          params: params.httpParams
        },
      );
  }

  putContractName(form) {
    return this.http.put(
      environment.apiTMCustomer + `/Contract/NameContract`,
      form,
    );
  }
}
