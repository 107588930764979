export class GroupUser {
  id: number;
  perfilID: number;
  description: string;
  grupoTipoID: number;
  subDescription: string;
}

export class GroupsCurrentUser {
  listGroups: GroupUser[];
  userID: number;
}
