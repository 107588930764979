import { Component } from '@angular/core';
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";
import { Router } from "@angular/router";
import { TicketsService } from './services/tickets.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent extends AbstractProcessLauncher {
  selectedTab: number = 1;
  constructor(protected router: Router, private ticketsService: TicketsService) {
    super(router);
  }

  tabs = [
    { heading: 'Geral', filters: 1 },
    { heading: 'Veículos',  filters: 2 },
    { heading: 'Hierarquia',  filters: 3}
  ]

  selectTabFilterValue(index: number) {
    this.selectedTab = index;
    this.ticketsService.setFilterObservable(index);
  }

}
