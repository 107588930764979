<div class="page-container">
  <div class="header-container">
    <div class="row">
      <app-page-title [title]="'/ Indicadores da Frota'"></app-page-title>
    </div>
  </div>

  <main class="fleet-container">
    <app-fleet-stats />
    <app-tag-stock />

    <app-fleet-expediture-evolution />

    <app-total-costs />
    <app-tag-canceled />

    <app-costs-ranking />

  </main>

</div>
