<p class="p-header">Cadastre abaixo os novos feriados.</p>
<div class="header-gray">
<table>
  <thead>
    @for(column of tableHeader; track column){
      <th>
        <div
        class="btn-order-table"
        [attr.data-e2e]="'sort-' + column[0] + '-table'"
        translate
        >
        {{ column }}
        </div>
      </th>
      }
    </thead>
  </table>
</div>
