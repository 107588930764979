import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { FinancialSalesReportService } from './services/financial-sales-report.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subject } from 'rxjs';
import { ReportQueryArguments } from './dtos/report-query-arguments.dto';
import { debounceTime, finalize } from 'rxjs/operators';
import moment from 'moment';
import { Dto } from '@models/dto.model';
import {TransactionSale} from "@models/backoffice/transaction.model";
import {Filters} from "@models/filters-backoffice.model";


defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-financial-sales-report',
  templateUrl: './financial-sales-report.component.html',
  styleUrls: ['./financial-sales-report.component.scss'],
})
export class FinancialSalesReportComponent implements OnInit {
  tableHeader: [string, string][] = [
    ['', ''],
    [
      'transactionDate',
      'Data e hora em que a transação foi enviada ao gateway de pagamentos',
    ],
    ['expectedClearingDate', 'Data prevista para pagamento'],
    ['transactionTypeDescription', ''],
    ['[Authorization]', 'Código de identificação de autorização'],
    ['totalAmount', 'Valor total da transação'],
    ['rechargeFee', 'Taxa cobrada do cliente na transação'],
    ['administrationFee', 'Taxa cobrada pelo adquirente'],
    ['transactionStatus', ''],
    ['transactionStatusChangeDate', ''],
    [
      'statusNd',
      'Status de integração da nota de débito com a Plataforma Financeira',
    ],
    ['', ''],
  ];

  popoverHeader = [
    'Company name',
    'Document Number',
    'Contract',
    'Account Balance',
    'ID Vindi',
    'CreditCardBrand',
    'Card',
  ];

  filterDebounce: Subject<ReportQueryArguments> =
    new Subject<ReportQueryArguments>();
  queryArguments: ReportQueryArguments;
  DtoTransactionSales: Dto<TransactionSale[]>;
  message: string = 'Loading...';
  isLoading: boolean = false;
  noFiles: boolean;
  // Filters
  perPages = [10, 50, 100];
  transactionTypesFilters = [
    { name: 'Mensalidade de tag ativa e em estoque', id: 3 }, // 3 | 6 | 7
    { name: 'Pedido de tags', id: 4 },
    { name: 'Recarga automática', id: 1 },
    { name: 'Recarga avulsa', id: 2 },
  ];

  // status concil
  statusTypesFilters = [
    { name: 'Capturada', id: 1 },
    { name: 'Não confirmada', id: 2 },
    { name: 'Confirmada', id: 3 },
    { name: 'Cancelada', id: 4 },
    { name: 'Chargeback', id: 5 },
  ];

  constructor(
    private _localeService: BsLocaleService,
    private _financialSalesReportService: FinancialSalesReportService,
    private _cdr: ChangeDetectorRef,
  ) {
    this._localeService.use('pt-br');
    this.queryArguments = new ReportQueryArguments();
    this.filterDebounce
      .pipe(debounceTime(300))
      .subscribe((filter) => this.getTransactionSales(filter));
  }

  ngOnInit(): void {
    this.getTransactionSales(this.queryArguments);
  }

  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }

  getTransactionSales(args: ReportQueryArguments) {
    this.isLoading = true;
    this.message = 'Loading...';
    this._financialSalesReportService
      .getTransactionSales(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (data: Dto<TransactionSale[]>) => {
          if (data.totalItems == 0) {
            this.noFiles = true;
          } else {
            this.noFiles = false;
          }
          this.DtoTransactionSales = data;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }

  handleFiltersParams(params: Filters) {
    if (
      params.transactionDateFilter != null ||
      params.transactionDateFilter != undefined
    ) {
      this.queryArguments.fromDateTransaction = params.transactionDateFilter[0];
      this.queryArguments.toDateTransaction = params.transactionDateFilter[1];
    }

    if (
      params.expectedClearingDateFilter != null ||
      params.expectedClearingDateFilter != undefined
    ) {
      this.queryArguments.fromDateExpectedClearing =
        params.expectedClearingDateFilter[0];
      this.queryArguments.toDateExpectedClearing =
        params.expectedClearingDateFilter[1];
    }

    if (
      params.transactionTypeFilter != null ||
      params.transactionTypeFilter != undefined
    ) {
      // if includes 3, add 6 and 7 (same text meaning)
      if (params.transactionTypeFilter.includes(3)) {
        params.transactionTypeFilter.push(6, 7);
      }
      if (!params.transactionTypeFilter.includes(3)) {
        params.transactionTypeFilter = params.transactionTypeFilter.filter(
          (value) => value !== 6 && value !== 7,
        );
      }
      // remove duplicate values
      params.transactionTypeFilter = params.transactionTypeFilter.reduce(
        (result, value) => {
          if (!result.includes(value)) {
            result.push(value);
          }
          return result;
        },
        [],
      );
      this.queryArguments.transactionTypeDescriptionId =
        params.transactionTypeFilter;
    }
    if (
      params.statusTypeFilter != null ||
      params.statusTypeFilter != undefined
    ) {
      this.queryArguments.transactionStatusId = params.statusTypeFilter;
    }

    if (
      params.rangeAmountFilter != null ||
      params.rangeAmountFilter != undefined
    ) {
      this.queryArguments.minimumAmount = params.rangeAmountFilter[0];
      this.queryArguments.maximumAmount = params.rangeAmountFilter[1];
    }

    this.queryArguments.search = '';
    this.getTransactionSales(this.queryArguments);
    this.queryArguments.page = 1;
  }

  resetFilters() {
    this.queryArguments = new ReportQueryArguments();
    this.getTransactionSales(this.queryArguments);
  }

  onOrderByChange(field: string) {
    if (this.queryArguments.orderBy === field) {
      this.queryArguments.desc = !this.queryArguments.desc;
    } else {
      this.queryArguments.orderBy = field;
      this.queryArguments.desc = true;
    }
    this.getTransactionSales(this.queryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.queryArguments.orderBy == field && !this.queryArguments.desc,
      'order-by-desc':
        this.queryArguments.orderBy == field && this.queryArguments.desc,
    };
  }

  onPaginationChange(event: any) {
    this.queryArguments.page = event;
    this.getTransactionSales(this.queryArguments);
  }

  // search filter
  onFullNameKeyup($event: any) {
    this.queryArguments.page = 1;
    this.queryArguments.search = $event;
    this.filterDebounce.next(this.queryArguments);
  }

  onSearchClick() {
    this.getTransactionSales(this.queryArguments);
  }

  onPageSizeChange(value: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.queryArguments.page = 1;
    this.queryArguments.pageSize = value;
    this.getTransactionSales(this.queryArguments);
  }

  Download() {
    this.isLoading = true;
    let now = new Date();
    let date = moment(now).format('DDMMYYYY');
    this.message = 'Download in progress...';
    this._financialSalesReportService
      .getTransactionSalesCsv(this.queryArguments)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Transações cartão de crédito_' + date + '.csv';
        element.click();
      });
  }

  isTransactionFileTypeChecked(id) {
    let isChecked =
      this.queryArguments.transactionTypeDescriptionId.indexOf(id) !== -1
        ? true
        : false;
    return isChecked;
  }

  onCheckboxTransactionFileTypeChange($event) {
    let index = this.queryArguments.transactionTypeDescriptionId.indexOf(
      Number($event.target.value),
    );
    if ($event.target.checked) {
      this.queryArguments.transactionTypeDescriptionId.push(
        Number($event.target.value),
      );
    } else {
      this.queryArguments.transactionTypeDescriptionId.splice(index, 1);
    }
    this.getTransactionSales(this.queryArguments);
  }
}
