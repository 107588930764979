import {Component, Input, OnInit} from '@angular/core';
import {ContractUsers} from "@models/old/contractusers.model";
import {UserProfileService} from "@services/user-profile.service";

@Component({
  selector: 'app-manager-contract',
  standalone: false,
  templateUrl: './manager-contract.component.html',
  styleUrl: './manager-contract.component.scss'
})
export class ManagerContractComponent implements OnInit {
  @Input() contractUsers: Array<ContractUsers> = [];

  selectedVision: number;

  constructor(private userProfileService: UserProfileService) {
  }

  ngOnInit(): void {
    this.selectedVision = this.userProfileService.isBackOffice() ? 1 : 2;
  }

  handlePermission(): boolean{
    return this.userProfileService.isBackOffice();
  }

}
