import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectProfileBackOfficeRoutingModule } from './routes/select-profile-back-office-routing.module';
import { SelectProfileBackOfficeComponent } from './select-profile-back-office.component';
import { EditContractNameModalComponent } from './components/edit-contract-name-modal/edit-contract-name-modal.component';
import { FinancialBlockModalComponent } from './components/financial-block-modal/financial-block-modal.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { CoreModule } from "../../../core/core.module";
import { SharedModule } from "../../../shared/shared.module";
import { CardSelectContractsComponent } from '@modules/commom/card-select-contracts/card-select-contracts.component';
import { FooterComponent } from '@modules/commom/footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    CoreModule,
    SharedModule,
    SelectProfileBackOfficeRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatRadioModule,
    SharedModule,
    CardSelectContractsComponent,

  ],
  declarations: [
    SelectProfileBackOfficeComponent,
    EditContractNameModalComponent,
    FinancialBlockModalComponent,
  ],
  providers: [DatePipe, provideNgxMask()],
})
export class SelectProfileBackOfficeModule {
}
