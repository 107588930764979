import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
// import { LinearChartData } from 'chart.js';
import { Observable } from 'rxjs';

import filterAndRemoveTypeFromChart from '@functions/filterAndRemoveTypeFromChart';
import { CostsRanking, TotalSpending } from '@models/statistics.model';

import { Dto } from '@models/dto.model';
import { formatDate } from '@angular/common';
import { environment } from '@env';
import generateCVS from "@functions/generateCVS";
import {TagsQueryArguments} from "@modules/backoffice/reports/dtos/tags-query-arguments.dto";
import {GetTagsQueryDto} from "@modules/backoffice/reports/dtos/getTags.dto";
import {
  ExpeditureData
} from "@modules/customer/fleet-dashboard/components/fleet-expediture-evolution/fleet-expediture-evolution.component";
import {ChartCustomData} from "@services/report.service";
import {DATE_FORMAT_US, FORMATED_PREVIEWS_THREE_MONTHS, TODAY} from "@variables/dates";
import {Filter} from "@models/filters-backoffice.model";

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private _baseUrl = environment.apiTMReport;

  constructor(private _http: HttpClient) {}

  getTagsOSA(args: TagsQueryArguments) {
    return this._http.get<GetTagsQueryDto>(
      environment.apiTMTag + `/api/LogicalTag`,
      {
        params: args.httpParans,
      },
    );
  }

  getFleetConsuptionEvolutionCsv(filters?: Filter) {
    return this._http
      .get(`${this._baseUrl}/Bill/GetFleetConsuptionEvolutionCsv`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          return generateCVS(response);
        }),
      );
  }

  getExpetureEvolution(params?: HttpParams): Observable<ExpeditureData> {
    return this._http
      .get<any>(
        `${this._baseUrl}/Bill/GetFleetConsuptionEvolution`,
        {
          params,
        },
      )
      .pipe(
        map((res) => {
          let labels = res.datasets.filter(
            (item) => item.label != 'Vale pedágio recebido',
          );
          let filteredData = { ...res, datasets: labels };
          let newRes: ExpeditureData = {
            table: res,
            chart: filterAndRemoveTypeFromChart(filteredData),
          };

          return newRes;
        }),
      );
  }

  getCostsRanking(params?: HttpParams) {
    return this._http
      .get<Dto<CostsRanking[]>>(`${this._baseUrl}/Bill/GetExpensesRanking`, {
        params,
      })
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  getTotalFleetCosts(params: HttpParams): Observable<ChartCustomData> {
    return this._http
      .get<TotalSpending[]>(`${this._baseUrl}/Bill/TotalFleetSpending`, {
        params,
      })
      .pipe(
        map((res) => {
          let data = new ChartCustomData();

          const dataset = {
            backgroundColor: ['#711CFF', '#81C105', '#0D8AFF'],
            data: [],
          };

          res.forEach((item) => {
            dataset.data.push(item.percentage);
            data.labels.push(item.type);
          });
          data.customLabels = res;
          data.datasets.push(dataset);

          return data;
        }),
      );
  }

  getTotalCostsCsv(params?: HttpParams) {
    params = new HttpParams()
      .append('startDate', FORMATED_PREVIEWS_THREE_MONTHS)
      .append('endDate', formatDate(TODAY, DATE_FORMAT_US, 'en-US'));

    return this._http
      .get(`${this._baseUrl}/Bill/TotalFleetSpendingCsv`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          return generateCVS(response);
        }),
      );
  }
}
