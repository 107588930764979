<div class="container-repom container-min-height">
  <div class="box-title">
    <span data-e2e="available-vehicle-title">Veículos disponíveis</span>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <b data-e2e="available-vehicle-subtitle" class="subtitle">
        Escolha um veículo cadastrado para vincular.
      </b>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <label data-e2e="available-vehicle-label" class="box-collapse-label" for="collapseTagsFor2">
        Veículos disponíveis
      </label>
    </div>
    <div class="col-lg-12">
      <div class="box-collapse">
        <!-- Collapse buttons -->
        <div>
          <button data-e2e="availbale-vehicle-dropdown-btn" id="collapseTagsFor2" class="btn btn-collapse" type="button"
            data-bs-toggle="collapse" data-bs-target="#collapseTags2" aria-expanded="false"
            aria-controls="collapseTags2">
            <b *ngIf="contractvehicleselected != null">Veículo selecionado:
              <span data-e2e="selected-vehicle" id="selectedVehicle">
                {{ contractvehicleselected.licensePlate | mask: 'AAA-AAAA' }}
              </span>
            </b>
            <span data-e2e="selected-vehicle" id="selectedVehicle" *ngIf="contractvehicleselected == null">
              Escolher
            </span>
          </button>
        </div>
        <!-- / Collapse buttons -->

        <!-- Collapsible element -->
        <div class="collapse" id="collapseTags2" *ngIf="!listVehicleAvailable?.length">
          <b data-e2e="empty-search-msg" class="subtitle">Não há veículos disponíveis.</b>
        </div>

        <div class="collapse" id="collapseTags2" *ngIf="listVehicleAvailable?.length">
          <div class="collapse" id="collapseTags2">
            <div class="mt-3 vehicle-disp">
              <mat-tab-group animationDuration="0">
                <mat-tab>
                  <ng-template data-e2e="vehicle-all-tab" mat-tab-label>
                    <div class="activate-tag-option">
                      Todos
                    </div>
                  </ng-template>

                  <mat-radio-group aria-label="Select an option" [(ngModel)]="contractvehicleselected"
                    *ngIf="listVehicleAvailable" (change)="onchangeVehicle()">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="row">
                          <div class="col-lg-12 d-flex"
                            *ngFor="let item of listVehicleAvailable | slice: 0 : listVehicleAvailable?.length / 2 + 1; let i = index">
                            <mat-radio-button id="licensePlate{{item.licensePlate}}"
                              [attr.data-e2e]="'vehicle-option-' + i" [value]="item" data-bs-toggle="collapse"
                              data-bs-target="#collapseTags2" aria-expanded="false" aria-controls="collapseTags2">
                              {{ item.licensePlate | mask: 'AAA-AAAA' }}
                            </mat-radio-button>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="row">
                          <div class="col-lg-12 d-flex"
                            *ngFor="let item of listVehicleAvailable | slice: listVehicleAvailable?.length / 2 + 1; let i = index">
                            <mat-radio-button id="licensePlate{{item.licensePlate}}"
                              [attr.data-e2e]="'vehicle-option-' + i" [value]="item" data-bs-toggle="collapse"
                              data-bs-target="#collapseTags2" aria-expanded="false" aria-controls="collapseTags2">
                              {{ item.licensePlate | mask: 'AAA-AAAA' }}
                            </mat-radio-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-radio-group>
                </mat-tab>

                <mat-tab>
                  <ng-template data-e2e="vehicle-search-tab" mat-tab-label>
                    <div class="activate-tag-option">
                      Pesquisar
                    </div>
                  </ng-template>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-header-search form-header-search-dropdown">
                        <input id="searchVehicle" data-e2e="vehicle-search-input" type="text" value="90207 - 80173"
                          placeholder="Placa do veiculo" class="form-control btn-search-order"
                          [(ngModel)]="vehiclesearch" />
                        <button id="searchVehicleButton" data-e2e="vehicle-search-btn" type="button"
                          class="btn btn-primary">
                          <i class="ico ico-search-white"></i>
                          buscar
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="serach-result">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="contractvehicleselected"
                          *ngIf="listVehicleAvailable" (change)="onchangeVehicle()">
                          <div class="col-lg-12"
                            *ngFor="let item of listVehicleAvailable | vehiclefilter: vehiclesearch; let i = index">
                            <mat-radio-button id="licensePlate{{item.licensePlate}}"
                              [attr.data-e2e]="'vehicle-option' + i" [value]="item" data-bs-toggle="collapse"
                              data-bs-target="#collapseTags2" aria-expanded="false" aria-controls="collapseTags2">
                              {{ item.licensePlate | mask: 'AAA-AAAA' }}
                            </mat-radio-button>
                          </div>
                        </mat-radio-group>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <!-- / Collapsible element -->
        </div>
      </div>
    </div>
    <span class="hr">
      <p>Ou</p>
      <hr />
    </span>
  </div>
  <div class="row">
    <div data-e2e="add-new-vehicle-msg" class="col-lg-7">
      <p>Cadastrar um novo veículo que ainda não foi cadastrado</p>
    </div>

    <div class="col-lg-5 custom-button">
      <div [ngbTooltip]="verificationPermissionByContractType ? tooltips.dualTagActivateTag : null">
        <app-borderless-button
          [description]="'novo veículo'"
          [classIconLeft]="'ico-add'"
          [id-html]="'insertNewVehicleButton'"
          [data-e2e]="'add-new-vehicle-btn'"
          [isBtnWithBorder]="true"
          [buttonColor]="'secundary'"
          (emitterEvtClick)="showAddVehicleModal()"
          [verifyContractBlock]="true"
          [verifyDualTag]="verificationPermissionByContractType"
        >
        </app-borderless-button>
      </div>
    </div>
  </div>
</div>
