<card-model>
  <ng-container *ngIf="stock$ | async as stock">
    <header class="card-model-header">
      <h1>Estoque de Tags</h1>
      <p>Acompanhe e controle seu estoque de tags disponíveis.</p>

      <i class="ico ico-info-gray" [ngbTooltip]="tooltip" placement="bottom-right" tooltipClass="custom-tooltip"></i>
    </header>

    <section class="card-model-container">
      <div class="stock-card-content" *ngFor="let item of stock">
        <span>{{ item.amount }}</span>
        <p>{{ item.title }}</p>
      </div>
    </section>
  </ng-container>
</card-model>

<ng-template #tooltip>
  <strong>Tags disponíveis em estoque:</strong> Quantidade de tags disponíveis
  em estoque, que nunca foram vinculadas a nenhum veículo.

  <br>

  <strong>Tags bonificadas:</strong> Quantidade de tags isentas da cobrança de
  mensalidade de tag em estoque.
</ng-template>

