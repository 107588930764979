import { HttpParams } from '@angular/common/http';

export class TripExpiratedQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  creditFromDate?: Date;
  creditToDate?: Date;
  expirationFromDate?: Date;
  expirationToDate?: Date;
  search: string;

  constructor() {
    this.page = 1;
    this.pageSize = 10;
    this.orderBy = 'TripExpirationDate';
    this.desc = true;
    this.creditFromDate = null;
    this.creditToDate = null;
    this.expirationFromDate = null;
    this.expirationToDate = null;
    this.search = '';
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.orderBy);

    if (this.creditFromDate != null) {
      const fromDateISOString = this.creditFromDate.toISOString().split('T')[0];
      httpAppend = httpAppend.append('creditFromDate', fromDateISOString);
    }

    if (this.creditToDate != null) {
      const toDateISOString = this.creditToDate.toISOString().split('T')[0];
      httpAppend = httpAppend.append('creditToDate', toDateISOString);
    }

    if (this.expirationFromDate != null) {
      const fromDateISOString = this.expirationFromDate.toISOString().split('T')[0];
      httpAppend = httpAppend.append('expirationFromDate', fromDateISOString);
    }

    if (this.expirationToDate != null) {
      const toDateISOString = this.expirationToDate.toISOString().split('T')[0];
      httpAppend = httpAppend.append('expirationToDate', toDateISOString);
    }

    if (this.search != '') {
      httpAppend = httpAppend.append('search', this.search);
    }

    return httpAppend;
  }
}
