import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { SharedFirstAccessService } from '../../services/sharedFirstAccess.service';
import { RegisterPasswordService } from './register-password.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '@models/old/user.model';
import { ChangePasswordFirstAccess } from '@models/old/login/changePasswordFirstAccess.model';
import { Token } from '@models/old/login/token.model';
import { NotifyService } from '@services/notify.service';


@Component({
  selector: 'app-login',
  templateUrl: './register-password.component.html',
  styleUrls: ['../../login.component.scss']
})
export class RegisterPasswordComponent implements OnInit {

  public formRegisterPassword = this.frmBuilder.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
    code: ['', [Validators.required]],
    // id: ['', [Validators.required]],
    // email: ['', [Validators.required]]
  });

  res;
  public user : User
  public tab : any = 'tab1';
  documentNumber: any;
  passwordInputed: string;

  constructor(protected router: Router, private sharedFirstAccessService: SharedFirstAccessService, private registerPasswordService: RegisterPasswordService, private frmBuilder: FormBuilder, protected notifyService: NotifyService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.documentNumber = this.route.snapshot.params.documentNumber;

    this.sharedFirstAccessService.data$
      .subscribe(res => {
        // this.res = <User>res;
        // this.user = <User>this.res.user;
      });

  }

  onSubmit() {
    try{

      if(this.formRegisterPassword.get('code').value == '')
      {
         this.notifyService.showWarning('Atenção', 'o campo código deve ser preenchido');
      }
      else
      {
        var changePasswordFirstAccess = new ChangePasswordFirstAccess();
        changePasswordFirstAccess.Token = this.formRegisterPassword.get('code').value;
        changePasswordFirstAccess.Password = this.formRegisterPassword.get('password').value;
        changePasswordFirstAccess.documentNumber = this.documentNumber;

        try{
          this.registerPasswordService.changeFirstPasswordUser(changePasswordFirstAccess).then((result) => {
            this.notifyService.showSuccess('Atenção!', 'Senha cadastrada com sucesso. Utilize seu CPF ou CNPJ / E-mail e sua senha para acessar a plataforma !');
            this.router.navigate(['/']);
          }, (e) => {

            if(e.error === undefined)
              this.notifyService.showWarning('Atenção', e);

            if(e.error.errors === undefined)
              this.notifyService.showError("Erro", "Ocorreu um erro ao Alterar senha");
            else
            {
              if(e.error.errors.errors.Token !== undefined)
              {
                e.error.errors.errors.Token.forEach(element => {
                  this.notifyService.showWarning('Atenção', element);
                });
              }
              if(e.error.errors.errors.SenhaMuitoCurta !== undefined)
              {
                e.error.errors.errors.SenhaMuitoCurta.forEach(element => {
                  this.notifyService.showWarning('Atenção', element);
                  this.tab = 'tab1';
                });
              }
              if(e.error.errors.errors.SenhaRequerCaracterEspecial !== undefined)
              {
                e.error.errors.errors.SenhaRequerCaracterEspecial.forEach(element => {
                  this.notifyService.showWarning('Atenção', element);
                  this.tab = 'tab1';
                })
              }
              if(e.error.errors.errors.SenhaRequerLetraMaiuscula !== undefined)
              {
                e.error.errors.errors.SenhaRequerLetraMaiuscula.forEach(element => {
                  this.notifyService.showWarning('Atenção', element);
                  this.tab = 'tab1';
                })
              }
              if(e.error.errors.errors.SenhaRequerLetraMinuscula !== undefined)
              {
                e.error.errors.errors.SenhaRequerLetraMinuscula.forEach(element => {
                  this.notifyService.showWarning('Atenção', element);
                  this.tab = 'tab1';
                })
              }
              if(e.error.errors.errors.ErroUsuarioNaoPodeTerASenhaAlteradaPorqueJaEfetuouUmLogin !== undefined)
              {
                e.error.errors.errors.ErroUsuarioNaoPodeTerASenhaAlteradaPorqueJaEfetuouUmLogin.forEach(element => {
                  this.notifyService.showWarning('Atenção', element);
                })
              }
            }

            if (e.error === undefined)
              this.notifyService.showError('Erro', e);

            e.error.forEach(element => {
              this.notifyService.showWarning('Atenção', element.message);
            });
          });
        }
        catch (e) {
          console.log(e.message);
          this.notifyService.showError('erro', e.message);
        }
      }
    }
    catch(e)
    {
      this.notifyService.showError('erro', e.message);
      console.log(e.Message);
    }
  }

  onClickMe() {
    var password = this.formRegisterPassword.get('password').value;
    var confirmPassword = this.formRegisterPassword.get('confirmPassword').value;
    var regex = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[0-9]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;

    this.passwordInputed = this.formRegisterPassword.get('password').value;

    if(this.formRegisterPassword.get('password').value == '')
    {
      this.notifyService.showWarning('Atenção', 'o campo senha precisa ser informado');
    }
    if(this.formRegisterPassword.get('confirmPassword').value == '')
    {
      this.notifyService.showWarning('Atenção', 'o campo confirmação de senha precisa ser informado');
    }
    if(this.formRegisterPassword.get('password').value !== this.formRegisterPassword.get('confirmPassword').value)
    {
      this.notifyService.showWarning('Atenção', 'A confirmação de senha deve ser igual a senha informada. Favor preencher novamente!');
    }
    if(!regex.exec(password || confirmPassword))
    {
      this.notifyService.showWarning('Atenção', 'A senha deve conter no mínimo 1 caracter em maiúsculo, 1 número e 1 caractere especial!');
    }
    else
    {
      var token = new Token();
      token.documentNumber = this.documentNumber;
      // token.userId = this.user.identityUserId;

      try{
        this.registerPasswordService.generateToken(token).then((result) => {
          this.notifyService.showSuccess('Atenção!', 'Token enviado com sucesso !');
          this.tab = 'tab2';
        }, (e) => {

          if(e.error === undefined)
            this.notifyService.showWarning('Atenção', e);

        if(e.error.length == 0 || e.error.length == undefined || e.error == null)
           this.notifyService.showError('Erro', 'Ocorreu um erro ao Gerar o Token');

        e.error.forEach(element => {
          this.notifyService.showWarning('Atenção', element.message);
        });

          if (e.error === undefined)
            this.notifyService.showError('Erro', e);

          e.error.forEach(element => {
            this.notifyService.showWarning('Atenção', element.message);
          });
        });
      }
      catch (e) {
        console.log(e.message);
        this.notifyService.showError('erro', e.message);
      }
    }
  }
}
