import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalityType } from '@models/contract.model';
import {RoutesPaths} from "../../../../../core/utils/routes/routes-paths";
// import { RoutesPaths } from '@shared/routes/routes-paths';

@Component({
  selector: 'app-financial-block-modal',
  templateUrl: './financial-block-modal.component.html',
  styleUrls: ['./financial-block-modal.component.scss'],
})
export class FinancialBlockModalComponent {
  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) {}

  @Input() contract;
  modalityType = ModalityType;

  close() {
    this.modalService.dismissAll();
  }

  RedirectToPage() {
    this.router.navigate(['/' + RoutesPaths.DASHBOARD]);
    this.close();
  }
}
