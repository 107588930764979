import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetVehicleBalanceVPRQueryResultDto } from '../dtos/get-vehiclebalancevpr-query-result.dto';
import { GetVehiclesBalanceVPRQueryArguments } from '../dtos/get-vehiclesbalancevpr-query-arguments.dto';

@Injectable({
  providedIn: 'root',
})
export class VehicleBalanceVPRService {
  constructor(private http: HttpClient) {}

  getVehiclesBalanceVPRQuery(args: GetVehiclesBalanceVPRQueryArguments) {
    return this.http.get<GetVehicleBalanceVPRQueryResultDto>(environment.apiTMSystemAccount + `/api/VehicleAccount`, {
      params: args.httpParans,
    });
  }

}
