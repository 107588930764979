import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";

import filterAndRemoveTypeFromChart from "@functions/filterAndRemoveTypeFromChart";
import {
  FORMATED_PREVIEWS_THREE_MONTHS,
  FORMATED_TODAY,
} from "@variables/dates";
import { ChartData } from "@models/charts.model";
import { Dto } from "@models/dto.model";
import { environment } from "@env";
import { CostsRanking } from "@modules/customer/fleet-dashboard/components/costs-ranking/costs-ranking.component";
import { ExpeditureData } from "@modules/customer/fleet-dashboard/components/fleet-expediture-evolution/fleet-expediture-evolution.component";

export class ChartCustomData extends ChartData {
  public customLabels?: any[] = [];
  constructor() { super() }
}

class TotalSpendingDTO {
  type: string;
  amount: number;
  percentage: number;
}

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private _baseUrl = environment.apiTMReport;

  constructor(
    private _http: HttpClient,
  ) {}

  getExpetureEvolution(
    params?: HttpParams
  ): Observable<ExpeditureData> {
    return this._http
      .get<ChartData>(`${this._baseUrl}/Bill/GetFleetConsuptionEvolution`, {
        params,
      })
      .pipe(
        map((res) => {
          let labels = res.datasets.filter(
            (item) => item.label != "Vale pedágio recebido"
          );
          let filteredData = { ...res, datasets: labels };
          let newRes: ExpeditureData = {
            table: res,
            chart: filterAndRemoveTypeFromChart(filteredData),
          };

          return newRes;
        })
      );
  }

  getCostsRanking(params?: HttpParams) {
    return this._http
      .get<Dto<CostsRanking[]>>(`${this._baseUrl}/Bill/GetExpensesRanking`, {
        params,
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  getTotalFleetCosts(params: HttpParams): Observable<ChartCustomData> {
    if (!params.has("startDate") && !params.has("endDate")) {
      params = new HttpParams()
        .append("startDate", FORMATED_PREVIEWS_THREE_MONTHS)
        .append("endDate", FORMATED_TODAY);
    }

    return this._http
      .get<TotalSpendingDTO[]>(`${this._baseUrl}/Bill/TotalFleetSpending`, {
        params,
      })
      .pipe(
        map((res) => {
          let data = new ChartCustomData();

          const dataset = {
            backgroundColor: ["#FA7D74", "#AA77FF", "#6EB9FF"],
            data: [],
          };

          res.forEach((item) => {
            dataset.data.push(item.percentage);
            data.labels.push(item.type);
          });
          data.customLabels = res;
          data.datasets.push(dataset);

          return data;
        })
      );
  }
}
