<div mat-dialog-content class="div-mat-dialog-content">
    <mat-icon mat-icon-button mat-dialog-close class="close-button" (click)="fecharDialog()">close</mat-icon>
    <div class="div-conteudo">
        @if (hasVerificaImg){
        <div class="div-fundo-img">
            <img [src]="data.imageUrl" alt="Imagem" class="img">
        </div>
        }
        <div class="div-conteudo-texto">
            @if (hasVerificaTitle){
            <h1 class="h1" mat-dialog-title>{{data.title}}</h1>
            }
            @if(hasVerificaMessage) {
            <p class="p">{{data.message}}</p>
            }
            @if(hasVerificaButton){
            <div mat-dialog-actions class="div-btn">
                <button mat-button (click)="onButtonClick()" class="button-acao">{{data.button}}</button>
            </div>
            }
        </div>
    </div>
</div>