<div class="modal-content confirmation-unlock">
  <div class="modal-header">
    <div class="box-title">
      <span>Desbloquear tag</span>
    </div>
    <button
      type="button"
      class="close"
      id="btn-close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body row">
    <div class="col-9 container-confirmation">
      <div class="col-5 image-content">
        <img src="/assets/img/bg-place.svg" />
      </div>
      <div class="col-7 confirmation-text">
        <h3>Tag desbloqueada</h3>
        <span class="col-12 row-information">
          O desbloqueio da TAG tem um prazo de até 6 horas para ser<br> efetivado em todos os estabelecimentos do Brasil.
        </span>
        <div class="row mt-4">
          <div class="col-6 vehicle">
            <span class="simulate-button">
              <img src="/assets/svg/icon-popover.svg" />
            </span>
            <strong id="licensePlate"
              >{{
                receivedEntry?.unblockTagRegister.licensePlate
                  | mask : "AAA-AAAA"
              }}
            </strong>
          </div>
          <div class="col-6 tag">
            <strong id="tagSerial">{{
              receivedEntry?.unblockTagRegister.tagSerial
            }}</strong>
          </div>
          <div class="author mt-4">
            <p id="changeDate">
              {{
                receivedEntry?.unblockTagRegister.changeDate
                  | date : "dd/MM/yyyy HH:mm"
              }}
              por {{ receivedEntry?.unblockTagRegister.userFullName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-gray">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modalService.dismissAll()"
      id="btn-dimiss"
    >
      Fechar
    </button>
  </div>
</div>
