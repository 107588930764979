import { HttpParams } from '@angular/common/http';

export class ContractBillArguments {
  orderBy: string;
  desc: boolean;
  contractId: number;

  constructor() {
    this.orderBy = 'Id';
    this.desc = true;
  }

  get httpParams() {
    let params = new HttpParams()
      .append('SortField', this.orderBy)
      .append('SortOrder', this.desc ? '1' : '0')
      .append('ContractId', this.contractId.toString());

    return params;
  }
}
