import { HttpParams } from '@angular/common/http';

export class GetTransactionsHierarchiesQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search:string;
  filter:number;
  startDate:Date;
  endDate:Date;
  hierarchyDescription:string;

  get httpParans() {

    let httpParams = new HttpParams()
    .append('page', this.page.toString())
    .append('pageSize', this.pageSize.toString())
    .append('SortOrder', this.desc ? '1' : '0')  
    .append('search', this.search)
    .append('filter', this.filter?.toString())
    .append('hierarchyDescription', this.hierarchyDescription)

    
    if(this.orderBy){
      httpParams = httpParams.append('sortField', this.orderBy.toString());
    }


    // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC. 
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.startDate){
      httpParams = httpParams.append('startDate', this.startDate.toISOString());
    }

    // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC.
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.endDate){
      httpParams = httpParams.append('endDate', this.endDate.toISOString());
    }

    return httpParams;

  };
}


