import { HttpParams } from '@angular/common/http';


export class BillVehiclesQueryArguments {
  id: number;
  page: number;
  pageSize: number;
  pageVehicle: number;
  pageVehicleSize: number;
  sortField: string;
  desc: boolean;
  hierarchyDescription: string;
  search: string;

  get httpParams() {
    let httpParams = new HttpParams()
      .set('BillId', this.id?.toString())
      .set('Page', this.page?.toString())
      .set('PageSize', this.pageSize?.toString())
      .set('SortField', this.sortField)
      .append('SortOrder', this.desc ? '1' : '0');

      
     if (this.pageVehicleSize)
      httpParams = httpParams.set('PageVehicleSize', this.pageVehicleSize?.toString());

      if (this.pageVehicle)
      httpParams = httpParams.set('PageVehicle', this.pageVehicle?.toString());

     if (this.hierarchyDescription)
      httpParams = httpParams.set('hierarchyDescription', this.hierarchyDescription);
      
      if (this.search) {
        httpParams = httpParams.set('search', this.search);
      }
   

    return httpParams;
  }
}


