import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChargebackReportComponent } from '../chargeback-report.component';
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";


const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.CHARGEBACK_REPORT,
        component: ChargebackReportComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Chargeback'),
          roles: '100993',
          contractRequired: false,
        },
      },
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChargebackReportRoutingModule {}
