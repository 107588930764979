<div class="RechargeFeeModalComponent">
  <div class="modal-content">
    <div class="modal-header">
      <div class="box-title"><span>Taxas de Recarga</span></div>
      <button class="close" type="button" (click)="modal.dismissAll()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="container-loading" *ngIf="isLoading">
      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
    </div>
    <div class="modal-body" *ngIf="!isLoading">

      <recharge-fee-table [rechargeFee]="this.rechargeFee"></recharge-fee-table>
    
    </div>
 
    <div class="modal-footer modal-footer-gray justify-content-start">
      <div class="col-12 justify-content-center d-flex">
             <button
          (click)="modal.dismissAll()"
          class="btn btn-secondary position-relative"
          type="button"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
