<div data-e2e="choose-vehicle-msg" class="container notSelected" *ngIf="licensePlate == '' && !isLoading">
  Selecione um veículo ao lado ou faça uma busca.
</div>

<div class="container-loading" *ngIf="isLoading">
  <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
</div>

<div class="container gridVehiclesContainer" *ngIf="licensePlate !== '' && !isLoading">

  <div class="row header align-items-center">
    <div class="col-md-auto d-flex flex-column columnHead1">
      <span data-e2e="vehicle-plate-data" class="title-blue" id="gridLicensePlate">{{ licensePlate }}</span>
      <span data-e2e="vehicle-nickname-data" class="title-description">Apelido: <span id="gridNickname">{{ nickname
          }}</span></span>
      <span data-e2e="vehicle-type-data" class="title-description">Tipo: <span id="gridVehicleTypeDescription">{{
          vehicleTypeDescription }}</span></span>
    </div>

    <div class="col-md-auto d-flex flex-column columnHead2">
      <span data-e2e="tag-txt" class="title-description">Tag: <span data-e2e="tag-data" id="gridSerial">{{ serial
          }}</span></span>
      <span data-e2e="axel-txt" class="title-description">N. de eixos: <span data-e2e="axel-data"
          id="gridAxleDescriptionPt">{{ axleDescriptionPt}}</span></span>
    </div>
  </div>

  <div class="row second-level">
    <div class="tickets col-sm-auto">
      <div class="row">
        <div class="col-20-percent col">
          <a><img src="assets/img/icon_pedagio.svg"></a>
          <p data-e2e="toll-label">Pedágio</p>
          <p data-e2e="toll-currency-sign">(R$)</p>
          <span data-e2e="toll-passenger-amount" id="gridAmountPassenger" [ngClass]="InfoVehicleHeadAmonts.amountPassenger < 0 ? 'negative-amount' : '' ">
            {{ InfoVehicleHeadAmonts.amountPassenger |
            currency: ' ':'symbol':'0.2-2':'pt-BR' }}</span>
        </div>

        <div class="col-20-percent col">
          <a><img src="assets/svg/icon_estacionamento.svg"></a>
          <p data-e2e="parking-label">Estacionamento</p>
          <p data-e2e="parking-currency-sign">(R$)</p>
          <span data-e2e="parking-amount" id="gridAmountParking" [ngClass]="InfoVehicleHeadAmonts.amountParking < 0 ? 'negative-amount' : '' ">
            {{ InfoVehicleHeadAmonts.amountParking | currency: '
            ':'symbol':'0.2-2':'pt-BR' }}</span>
        </div>

        <div class="col-20-percent col">
          <a><img src="assets/img/payday.svg"></a>
          <p data-e2e="monthly-fee-label">Mensalidade</p>
          <p data-e2e="monthly-fee-currency-sign">(R$)</p>
          <span data-e2e="monthly-fee-amount" id="gridAmountServices" [ngClass]="InfoVehicleHeadAmonts.amountServices < 0 ? 'negative-amount' : '' ">
            {{ InfoVehicleHeadAmonts.amountServices |
            currency: ' ':'symbol':'0.2-2':'pt-BR' }}</span>
        </div>
      </div>
    </div>

    <div class="reversal col-sm-auto">
      <div class="row">
        <div class="col-20-percent reversalLabel col">
          <div class="row">
            <p data-e2e="reverse-label" class="col-sm-auto">ESTORNOS</p>
            <a class="col-sm-auto "><img src="assets/img/Forward_arrow.svg"></a>
          </div>
        </div>

        <div class="col-20-percent col">
          <a><img src="assets/img/icon_estornopedagio.svg"></a>
          <p data-e2e="reverse-toll-label">Pedágio</p>
          <p data-e2e="reverse-toll-currency-sign">(R$)</p>
          <span data-e2e="reverse-toll-amount" id="gridAmountReversal">{{ InfoVehicleHeadAmonts.amountReversal |
            currency: ' ':'symbol':'0.2-2':'pt-BR' }}</span>
        </div>

        <div class="col-20-percent col-sm-auto">
          <a><img src="assets/img/icon_estornoestacionamento.svg"></a>
          <p data-e2e="reverse-parking-label">Estacionamento</p>
          <p data-e2e="reverse-parking-currency-sign">(R$)</p>
          <span data-e2e="reverse-parking-amount" id="gridAmountParkingReversal">{{
            InfoVehicleHeadAmonts.amountParkingReversal | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</span>
        </div>
      </div>
    </div>

    <div class="col-20-percent vp col">
      <a><img src="assets/img/icon_valepedagio.svg"></a>
      <p data-e2e="vpr-label">Vale Pedágio</p>
      <p data-e2e="vpr-currency-sign">(R$)</p>
      <span data-e2e="vpr-amount" id="gridAmountVPR" [ngClass]="InfoVehicleHeadAmonts.amountVPR < 0 ? 'negative-amount' : '' ">
        {{ InfoVehicleHeadAmonts.amountVPR | currency: '
        ':'symbol':'0.2-2':'pt-BR' }}</span>
    </div>
    <div class="col-20-percent total col-sm-auto">
      <p data-e2e="vehicle-total-amount-label">Valor total do veículo</p>
      <div class="totalValues">
        <span data-e2e="vehicle-total-amount" id="gridAmountTotal" [ngClass]="InfoVehicleHeadAmonts.amountTotal < 0 ? 'negative-amount' : '' ">
          R$ {{ InfoVehicleHeadAmonts.amountTotal | currency: ' ':'symbol':'0.2-2':'pt-BR' }}
        </span>
      </div>
    </div>
  </div>

  <div class="row  mt-4">
    <div class="col-md-auto">
      <form novalidate>
        <div class="d-flex ">
          <app-outlined-select
            [title]=" 'Quantidade de linhas'"
            [values]="perPages"
            [initialValue]="BillVehiclesQueryArguments.pageSize ? BillVehiclesQueryArguments.pageSize : 10"
            (valueChange)="onPageSizeChange($event)"
            data-e2e="line-filter-title"
            [beforeTextValue]="'exibir '"
            [afterTextValue]=" 'linhas'"
          />
        </div>
      </form>
    </div>
  </div>


  <div class="container">
    <div class="row mt-2">
      <div class="col-12 desc-vehicles-col">
        <table class="table table-title">
          <thead class="thead">
            <tr >
              <td data-e2e="table-top-description" colspan="5" class="custom-col desc-vehicles header-table">
                <div class="header-table" >DETALHES</div>
              </td>
              <td data-e2e="table-top-amount" colspan="3" class="custom-col values-vehicles">
                <div class="header-table" >VALORES (R$)</div>
              </td>
            </tr>
            <tr>
              <th [ngClass]="bindOrderByClass('code')">
                <button data-e2e="code-order-btn" class="btn-order-table" translate
                  (click)="onOrderByChange('code')">COD</button>
                <div class="round-order" (click)="onOrderByChange('code')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th data-e2e="bill-order-btn" [ngClass]="bindOrderByClass('billItemType')">
                <button class="btn-order-table" translate (click)="onOrderByChange('billItemType')">Tipo de
                  Transação</button>
                <div class="round-order" (click)="onOrderByChange('billItemType')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('passengerDate')">
                <button data-e2e="transaction-date-order-btn" class="btn-order-table" translate
                  (click)="onOrderByChange('passengerDate')">Data da Transação</button>
                <div class="round-order" (click)="onOrderByChange('passengerDate')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('processingDate')">
                <button data-e2e="processing-date-order-btn" class="btn-order-table" translate
                  (click)="onOrderByChange('processingDate')">Data do Processamento</button>
                <div class="round-order" (click)="onOrderByChange('processingDate')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('merchantName')">
                <button data-e2e="description-order-btn" class="btn-order-table" translate
                  (click)="onOrderByChange('merchantName')">Descrição</button>
                <div class="round-order" (click)="onOrderByChange('merchantName')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('passenger')">
                <button data-e2e="transaction-order-btn" class="btn-order-table" translate
                  (click)="onOrderByChange('passenger')">Transação</button>
                <div class="round-order" (click)="onOrderByChange('passenger')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('vprAmount')">
                <button data-e2e="used-vpr-order-btn" class="btn-order-table" translate
                  (click)="onOrderByChange('vprAmount')">Utilizado de vale pedágio</button>
                <div class="round-order" (click)="onOrderByChange('vprAmount')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('amount')">
                <button data-e2e="amount-order-btn" class="btn-order-table" translate
                  (click)="onOrderByChange('amount')">Cobrado</button>
                <div class="round-order" (click)="onOrderByChange('amount')">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
            </tr>
          </thead>


          <tbody>
            <tr *ngFor="let vehicle of BillVehicleList; let i = index" [ngClass]="addClass(i)">
              <td data-e2e="code-data" id="code{{i}}">{{ ShortCode(vehicle.code) }}</td>
              <td data-e2e="bill-data" id="billItemType{{i}}">{{ vehicle.billItemType }}</td>
              <td data-e2e="passenger-data" id="passengerDate{{i}}">
                {{ vehicle.passengerDate | date:'dd/MM/yyyy HH:mm:ss' }}
              </td>
              <td data-e2e="processing-date-data" id="processingDate{{i}}">
                {{ vehicle.processingDate | date:'dd/MM/yyyy HH:mm:ss' }}
              </td>
              <td data-e2e="desription-data" id="merchantName{{i}}">{{ vehicle.merchantName }}</td>
              <td data-e2e="passenger-data" id="passenger{{i}}" [ngClass]="vehicle.passenger < 0 ? 'negative-amount' : '' ">
                {{ vehicle.passenger | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}
              </td>
              <td data-e2e="vpr-amount-data" id="vprAmount{{i}}" [ngClass]="vehicle.vprAmount < 0 ? 'negative-amount' : '' ">
                {{ vehicle.vprAmount | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}
              </td>
              <td data-e2e="amount-data" id="amount{{i}}" [ngClass]="vehicle.amount < 0 ? 'negative-amount' : '' ">
                {{ vehicle.amount | currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="container-loading loadingPage" *ngIf="isLoadingPage">
          <app-loader [isLoading]="isLoadingPage" message="{{ 'Carregando...'}}"></app-loader>
        </div>

        <div class="pagination-custom" *ngIf="BillVehiclesResultDto.data[0].total>BillVehiclesQueryArguments.pageSize">
          <app-pagination [totalItems]="BillVehiclesResultDto.data[0].total"
            [itemsPerPage]="BillVehiclesQueryArguments.pageSize" [maxPages]="5"
            [currentPage]="BillVehiclesQueryArguments.page"
            (pageChangeEvent)="onPaginationChange($event)"></app-pagination>
        </div>

      </div>
    </div>
  </div>
</div>
