@if (isLoading) {
  <div class="container-loading">

    <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>

  </div>
}
@else {
  <mat-card class="batch-management-card">

    <div class="batch-management-card__header">

      <section class="batch-management-card__tabs">

        <app-tabs [inTabs]="tabs" [inTabSelected]="tabSelected" (outChangeTabSelected)="handleTabsSelection($event)"></app-tabs>

      </section>

      <section>
        @switch (tabSelected) {
          @case (tabType.TagActivate) {
            <app-borderless-button
              [description]="'Importar novo documento'"
              [id-html]="'btn-tag-activate-doc-upload'"
              [data-e2e]="'btn-tag-activate-doc-upload'"
              [buttonColor]="'primary'"
              [classIconLeft]="'ico-document-upload'"
              [isBtnWithBorder]="true"
              [isDisable]="!hasTagsAndVehiclesAvaliable"
              (emitterEvtClick)="openTagActivateUploadModal()"
            >
            </app-borderless-button>
          }
          @case (tabType.TagBlock) {
            <app-borderless-button
              [description]="'Bloquear tags'"
              [id-html]="'btn-tag-block'"
              [data-e2e]="'btn-tag-block'"
              [buttonColor]="'primary'"
              [classIconLeft]="'ico-padlock-closed'"
              [isBtnWithBorder]="true"
              [isDisable]="tagsBlockUnblockSelected.length == 0"
              (emitterEvtClick)="openTagBlockUnblockModal()"
            >
            </app-borderless-button>
          }
          @case (tabType.TagUnblock) {
            <app-borderless-button
              [description]="'Desbloquear tags'"
              [id-html]="'btn-tag-unblock'"
              [data-e2e]="'btn-tag-unblock'"
              [buttonColor]="'primary'"
              [classIconLeft]="'ico-padlock-opened'"
              [isBtnWithBorder]="true"
              [isDisable]="tagsBlockUnblockSelected.length == 0"
              (emitterEvtClick)="openTagBlockUnblockModal()"
            >
            </app-borderless-button>
          }
        }
      </section>
    </div>

    <div>
      @switch (tabSelected) {
        @case (tabType.TagActivate) {

          <app-batch-tag-activate
            [inReloadRequest]="controlReloadRequestComponent"
            [inHasTagsAndVehiclesAvaliable]="hasTagsAndVehiclesAvaliable"
          ></app-batch-tag-activate>

        }
        @case (tabType.TagBlock) {

          <app-batch-tag-block-unblock
            [inTabTypeActive]="tabType.TagBlock"
            (outTagsSelected)="handleTagsSelected($event)"
          >
          </app-batch-tag-block-unblock>

        }
        @case (tabType.TagUnblock) {

          <app-batch-tag-block-unblock
            [inTabTypeActive]="tabType.TagUnblock"
            (outTagsSelected)="handleTagsSelected($event)"
          >
          </app-batch-tag-block-unblock>

        }
        @case (tabType.TagBlockUnblockHistory) {

          <app-batch-tag-block-unblock-history></app-batch-tag-block-unblock-history>
        }
      }
    </div>
  </mat-card>
}

