import { Directive, OnInit, ElementRef, Renderer2, Input, OnChanges } from "@angular/core";
import { ButtonBlockService } from './button-block.service';
import { LocalstorageService } from "@services/localstorage.service";
import { UserProfileService } from "@services/user-profile.service";
import { AuthService } from "../../../core/auth/auth.service";
import { ContractService } from "@services/contract.service";

@Directive({
  selector: "[buttonBlock]",
})

export class ButtonBlockDirective implements OnInit, OnChanges {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private localstorageService: LocalstorageService,
    private buttonBlockService: ButtonBlockService,
    private userProfileService: UserProfileService,
    private authService: AuthService,
    private contractService: ContractService
  ) { }

  @Input() ConditionToBlock: boolean = null;
  @Input() ContractBlock: any = null;
  @Input() ContractDualTagBlock: boolean = false;
  @Input() idVehicles: number;


  getContractBlock() {
    if (this.userProfileService.isCustomerOrRelationshipProfile()) {
      this.buttonBlockService
        .getContracts()
        .subscribe(
          (success: any) => {
            this.setContractBlock(success);
            this.block();
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.buttonBlockService
        .getContractByDocument(Number(this.authService.getAttendanceDocument()))
        .subscribe(
          (success: any) => {
            this.setContractBlock(success);
            this.block();
          },
          (error) => {
            console.error(error);
          },
        );
    }
  }

  setContractBlock(contracts) {
    if (contracts) {
      const contractId: number = this.contractService.getContractId();
      const contract = contracts.filter(data => data.id == contractId);
      this.localstorageService.setContractBlockLevel(contract[0].contractBlockLevel);
    }
  }

  ngOnInit() {
    this.getContractBlock();

  }

  ngOnChanges() {
    this.block();
  }

  verifyBlockIfContractDualTag() {
    return this.contractService.isDualTag();
  }

  verifyButtonConditionToBlock() {
    //bloquear se a condição para o bloqueio do botão for atendida
    if (this.ConditionToBlock) {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
    }
    else {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
    }
  }

  // verifica se o botão deve ser bloqueado pelo bloqueio de contrato atual
  verifyButtonContractBlock() {
    let contractBlock = Number(this.localstorageService.getContractBlockLevel())
    let contractStatusToBlock = this.ContractBlock ? this.ContractBlock : [];

    return contractStatusToBlock.includes(contractBlock);
  }

  block() {
    const ContractBlockLevel = this.verifyButtonContractBlock()

    // bloquear se existir um bloqueio de contrato
    //criar um metodo que vai receber o id do veiculo e deixar true ou false
    if (this.verifyVehiclesBlock() || ContractBlockLevel || this.ContractDualTagBlock) {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
    }
    else {
      this.verifyButtonConditionToBlock();
    }
  }

  private verifyVehiclesBlock() {
    if (this.idVehicles != null) {
      const dualTagVehicles = this.localstorageService.getListVehiclesDualTag();
      if (dualTagVehicles && (dualTagVehicles.length > 0)) {
        return dualTagVehicles.includes(this.idVehicles)
      }
    }
    return false;
  }

}
