<div class="card mt-6rem overflow-initial tabGeneralComponent">
  <div class="card-body pt-0">

    <div class="d-flex">
      <div class="btn-group d-flex align-items-center" dropdown [isAnimated]="true" [autoClose]="false">
        <button dropdownToggle type="button" data-e2e="filter-toggle-btn" id="button-animated"
          aria-controls="dropdown-animated" class="btn btn-filter dropdown-toggle " [class.no-shadow]="isDatePickerOpen"
          [class.without-border-bottom-radius]="isOpenDropdown" [class.active]="isOpenDropdown"
          (click)="toggleDropdown()">
          <img src="assets/img/filter.svg" class="button-icon" />
          Filtros
        </button>

        <div class="filters">
          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-filter"
            [class.no-shadow]="isDatePickerOpen" role="menu" aria-labelledby="button-animated">
            <li role="menuitem" (click)="toggleDateRangePickerGeneral()">
              <div class="simulate-select">
                <img src="assets/img/ico-calendar.svg" />
                <span data-e2e="general-date-filter-label" class="simulate-label">Período</span>
                <input id="filterDateGeneral" type="text" data-e2e="general-date-filter-input" placeholder="Selecione"
                  class="simulate-form-control" #dpGeneral="bsDaterangepicker" bsDaterangepicker placement="right"
                  [bsValue]="dayCustomGeneral" [maxDate]="maxDate" (bsValueChange)="onFilterDateGeneral($event)"
                  [minDate]="minDate" [outsideClick]="false">

                <div class="simulate-button" [class.active]="isShownPeriod">
                  <img src="assets/img/arrow-select.svg" />
                </div>
              </div>
            </li>

            <li role="menuitem" (click)="toggleShowType(); dpGeneral.hide()">
              <div class="simulate-select">
                <img src="assets/img/hierarchy_select.svg" />
                <span data-e2e="transaction-type-filter-label" class="simulate-label">Tipo de transação</span>
                <p data-e2e="transaction-type-filter-selected" class="simulate-input" id="selectedType">{{
                  tiposelecionado }}</p>
                <div class="simulate-button" [class.active]="isShowType">
                  <img src="assets/img/arrow-select.svg" />
                </div>
              </div>
            </li>

            <li role="menuitem" (click)="toggleShowHierarchy(); dpGeneral.hide()">
              <div class="simulate-select">
                <img src="assets/img/hierarchy_select.svg" />
                <span data-e2e="hierarchy-filter-label" class="simulate-label">Hierarquia</span>
                <input data-e2e="hierarchy-filter-input" class="simulate-input" id="selectedHierarchy"
                  placeholder="Selecione um grupo" [value]="hierarchySelected" />

                <!-- <p class="simulate-input">Escolha um grupo</p> -->
                <div class="simulate-button" [class.active]="isShownHierarchy">
                  <img src="assets/img/arrow-select.svg" />
                </div>
              </div>
            </li>

            <li role="menuitem" (click)="toggleShowValue(); dpGeneral.hide()">
              <div class="simulate-select">
                <img src="assets/img/dollar_select.svg" />
                <span data-e2e="value-range-filter-label" class="simulate-label">Faixa de valor</span>
                <input data-e2e="value-range-filter" class="simulate-input" id="SelectedvaluesCustom" type="text"
                  placeholder="Selecione" [value]="valuesCustom" />
                <div class="simulate-button" [class.active]="isShownValue">
                  <img src="assets/img/arrow-select.svg" />
                </div>
              </div>
            </li>

            <li role="menuitem">
              <div class="content-button">

                <app-borderless-button [description]="'Limpar filtros'"
                  [classIconRight]="'ico-delete'"
                  [id-html]="'resetFiltersButton'"
                  [data-e2e]="'resetFiltersButton'"
                  [buttonColor]="'secundary'"
                  [isBtnWithBorder]="true"
                  (emitterEvtClick)="resetFilters()"/>


                <button data-e2e="hide-general-date-btn" id="hideGeneralDate" #myDiv hidden class="btn btn-success"
                  (click)="dpGeneral.hide()" type="button">
                </button>

                <button data-e2e="show-general-date-btn" id="showGeneralDate" #myDiv2 hidden class="btn btn-success"
                  (click)="dpGeneral.show()" type="button">
                </button>
              </div>
            </li>
          </ul>

          <div class="box-area-datepicker" [hidden]="date != null && !isOpen"
            [ngClass]="{ show: isShownPeriod, hide: !isShownPeriod }">
            <div class="content-extend">
              <form [formGroup]="formDateGeneral">
                <div class="datepicker">
                  <h4 data-e2e="select-period-label" class="text-center mb-2 mt-0">Selecione o período</h4>

                  <div class="d-flex justify-content-around align-items-center">
                    <mat-radio-group class="custom-control custom-radio">
                      @for(date of datePickerDates; track date;) {
                       <mat-radio-button  class="custom-control-input" id="defaultGroupExample4"
                       formControlName="dateVehicles" [value]="date" (click)="setDaysLast(date)"
                       name="dateVehicles" />
                     <label class="custom-control-label" for="defaultGroupExample4">últimos {{date}} dias</label>
                      }
                     </mat-radio-group>

                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="box-area-hierarchy" [ngClass]="{ show: isShownHierarchy, hide: !isShownHierarchy }">
            <div class="content-extend">
              @if (!getBreadcrumbResult?.data) {
              <div class="hierarchy">
                <span data-e2e="empty-hierarchy-msg" class="without-hierarchy">Não há hierarquias para esse
                  contrato.</span>
              </div>
              }

              @if (getBreadcrumbResult?.data) {
              <div class="hierarchy">
                <h4 data-e2e="select-hierarchy-group-title" class="text-center mb-2_25rem">Selecione o grupo</h4>

                <div class="container-hierarchy">
                  <ul class="content-hierarchy">
                    <li class="items-hierarchy" *ngFor="let hierarchy of getBreadcrumbResult?.data; let i = index"
                      (click)="onApplyHierarchyFilter(hierarchy)">

                      <div class="info-hierarchy">
                        <span [attr.data-e2e]="'hierarchy-breadcryumb-' + i" id="hierarchyBreadCrumb">{{
                          hierarchy.breadCrumb }}</span> <br />
                        <span [attr.data-e2e]="'hierarchy-description-' + i" id="hierarchyDescription_PT">{{
                          hierarchy.description_PT }}</span>
                      </div>

                      <button [attr.data-e2e]="'hierarchy-action-btn' + i" class="action-hierarchy">
                        <img src="assets/img/ico-search.svg" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              }
            </div>
          </div>

          <form [formGroup]="formExtract">
            <div class="box-area-hierarchy" [ngClass]="{ show: isShowType, hide: !isShowType }">
              <div class="content-extend">
                <div class="hierarchy">
                  <h4 data-e2e="select-hierarchy-type-filter-label" class="text-center mb-2">Selecione o tipo</h4>

                  <div class="col-lg-12">
                    <mat-radio-group [(ngModel)]="radioType" class="d-flex flex-column" [disabled]="disabled" formControlName="radioType">
                      <mat-radio-button (click)="onFilterTypeClick(value)" *ngFor="let value of types; let i = index"
                        [value]="value.id">
                        <b [attr.data-e2e]="'hierarchy-type-filter-' + i" id="NameType{{i}}">{{ value.name }}</b>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="box-area-value" [ngClass]="{ show: isShownValue, hide: !isShownValue }">
            <form [formGroup]="formValues">
              <div class="content-extend">
                <div class="value">
                  <h4 data-e2e="value-filter-title" class="text-center mb-2_25rem">Selecione os valores</h4>

                  <div class="form-group">
                    <label data-e2e="min-value-filter-label" for="LabelMinimo">Mínimo</label>
                    <input data-e2e="min-value-filter" type="number" id="minValue" class="form-control"
                      formControlName="minValue">
                  </div>

                  <div class="form-group">
                    <label data-e2e="max-value-filter-label" for="LabelMaximo">Máximo</label>
                    <input data-e2e="max-value-filter" type="number" id="maxValue" class="form-control"
                      formControlName="maxValue">
                  </div>

                  <div class="content-button">
                    <button type="button" data-e2e="value-filter-btn" id="applyFilterButton" class="btn btn-primary"
                      (click)="onApplyFilter(formValues.value)">
                      <img src="assets/img/ticked.svg" class="button-icon" />
                      Aplicar filtro
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="py-4 px-2">
        <form novalidate>
          <app-outlined-select [title]="'Quantidade de linhas'" [values]="perPages"
            [beforeTextValue]="'exibir '" [afterTextValue]="' linhas'" [customWidthSize]="'250px'"
            [disableRipple]="true"
            (valueChange)="onPageSizeChange($event)">
          </app-outlined-select>
        </form>
      </div>

      <div class="py-4 px-2">
        <form novalidate>
          <app-outlined-select [title]="'Ordenação'" [values]="orderObjectToOutlinedSelect"
          [hasLabel]="true" [customWidthSize]="'250px'"[disableRipple]="true"
          (valueChange)="onOrderBySelect($event)">
        </app-outlined-select>
        </form>
      </div>

      <div class="py-4 px-2">
        <form class="input-search">
          <app-search-form-inline
            id="search-form"
            data-e2e="search-form"
            [placeholder]="'Placa, veículo, apelido...'"
            [searchValue]="getTicketsQueryArguments.search"
            (keyUpEvent)="onFullNameKeyup($event)"
          >
          </app-search-form-inline>
        </form>
      </div>

      <div class="py-4 px-2">
        <app-borderless-button [description]="'Exportar'"
        [classIconRight]="'ico-export'"
        [id-html]="'exportButton'"
        [data-e2e]="'exportButton'"
        [buttonColor]="'primary'"
        [isDisable]="noTransactions"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="Download()">
        </app-borderless-button>
      </div>
    </div>

    <div class="px-4">
      <div class="row">
        <div class="col-custom percent-74 pr-1">
          <div data-e2e="table-top-description" class="header-table">
            Descrições
          </div>
        </div>

        <div class="col-custom percent-26 pl-1">
          <div data-e2e="table-top-value" class="header-table">
            Valores (R$)
          </div>
        </div>
      </div>

      <table class="table tickets-general">
        <thead class="thead">
          <tr>
            <th [ngClass]="bindOrderByClass('Code')">
              <button data-e2e="sort-id-btn" class="btn-order-table" (click)="onOrderByChange('Code')">Cód.</button>
              <div class="round-order" (click)="onOrderByChange('Code')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('PassengerDate')">
              <button data-e2e="sort-transaction-date-btn" class="btn-order-table"
                (click)="onOrderByChange('PassengerDate')">Data da <br />
                transação</button>
              <div class="round-order" (click)="onOrderByChange('PassengerDate')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('ProcessingDate')">
              <button data-e2e="sort-processing-date-btn" class="btn-order-table"
                (click)="onOrderByChange('ProcessingDate')">Data do
                <br /> processamento</button>
              <div class="round-order" (click)="onOrderByChange('ProcessingDate')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('TagModelReferenceId')">
              <button data-e2e="sort-processing-date-btn" class="btn-order-table"
                (click)="onOrderByChange('TagModelReferenceId')">Tipo de Tag</button>
              <div class="round-order" (click)="onOrderByChange('TagModelReferenceId')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('transactionType')">
              <button data-e2e="sort-transaction-type-btn" class="btn-order-table"
                (click)="onOrderByChange('transactionType')">Tipo de <br />
                transação</button>
              <div class="round-order" (click)="onOrderByChange('transactionType')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('LicensePlate')">
              <button data-e2e="sort-plate-btn" class="btn-order-table"
                (click)="onOrderByChange('LicensePlate')">Placa<br /> apelido</button>
              <div class="round-order" (click)="onOrderByChange('LicensePlate')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('HierarchyDescription')">
              <button data-e2e="sort-hierarchy-btn" class="btn-order-table"
                (click)="onOrderByChange('HierarchyDescription')">Hierarquia</button>
              <div class="round-order" (click)="onOrderByChange('HierarchyDescription')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('RegisterCategory')">
              <button data-e2e="sort-category-btn" class="btn-order-table"
                (click)="onOrderByChange('RegisterCategory')">Categoria<br /> Cadastrada e
                cobrada</button>
              <div class="round-order" (click)="onOrderByChange('RegisterCategory')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('merchantName')">
              <button data-e2e="sort-establishment-btn" class="btn-order-table"
                (click)="onOrderByChange('merchantName')">Descrição</button>
              <div class="round-order" (click)="onOrderByChange('merchantName')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('transactionProcessingAmount')">
              <button data-e2e="sort-transaction-value-btn" class="btn-order-table"
                (click)="onOrderByChange('transactionProcessingAmount')">Transação</button>
              <div class="round-order" (click)="onOrderByChange('transactionProcessingAmount')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('transactionProcessingVPR')">
              <button data-e2e="sort-vpr-btn" class="btn-order-table"
                (click)="onOrderByChange('transactionProcessingVPR')">Utilizado de<br />
                Vale-pedágio</button>
              <div class="round-order" (click)="onOrderByChange('transactionProcessingVPR')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>

            <th [ngClass]="bindOrderByClass('transactionChargedAmount')">
              <button data-e2e="sort-charged-amount-btn" class="btn-order-table"
                (click)="onOrderByChange('transactionChargedAmount')">Cobrado</button>
              <div class="round-order" (click)="onOrderByChange('transactionChargedAmount')">
                <img src="../../../assets/svg/order-table.svg" alt="" />
              </div>
            </th>
          </tr>
        </thead>

        <div class="container-loading" *ngIf="isLoading">
          <app-loader [isLoading]="isLoading" message="{{ message }}"></app-loader>
        </div>

        @if (!isLoading) {
        <tbody>
          <tr *ngFor="let item of getTicketsQueryResult?.items; let i = index">
            <td [attr.data-e2e]="'transaction-id-' + i" id="transactionChainId{{i}}">{{ item?.transactionChainId |
              slice:-7 }}</td>
            <td [attr.data-e2e]="'transaction-date-' + i" id="transactionDate{{i}}">{{ item?.transactionDate |
              date:'dd/MM/yyyy HH:mm:ss'}}</td>
            <td [attr.data-e2e]="'transaction-procesing-date-' + i" id="transactionProcessDate{{i}}">{{
              item?.transactionProcessDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
            <td class="tag-container" [attr.data-e2e]="'transaction-tagModelReferenceId' + i"
              id="tagModelReferenceId{{i}}"> {{
              getTagModelReference(item?.tagModelReferenceId) }}
            </td>
            <td [attr.data-e2e]="'transaction-type-' + i" id="transactionType{{i}}" class="text-center">
              <div *ngIf="item?.transactionTypeId == 6" placement="top" ngbTooltip="Estorno de pedágio">
                <img src="assets/svg/icon_estornopedagio.svg" />
              </div>
              <div *ngIf="item?.transactionTypeId == 15" placement="top" ngbTooltip="Estacionamento">
                <img src="assets/svg/icon_estacionamento.svg" />
              </div>
              <div *ngIf="item?.transactionTypeId == 16" placement="top" ngbTooltip="Estorno de estacionamento">
                <img src="assets/svg/icon_estornoestacionamento.svg" />
              </div>
              <div *ngIf="item?.transactionTypeId == 9" placement="top" ngbTooltip="Pedágio">
                <img src="assets/svg/icon_pedagio.svg" />
              </div>
            </td>
            <td [attr.data-e2e]="'transaction-plate-' + i" id="licensePlateNickName{{i}}">{{
              item?.licensePlateNickName}}</td>
            <td [attr.data-e2e]="'transaction-description-' + i" id="hierarchyDescription{{i}}">{{
              item?.hierarchyDescription }}</td>
            <td id="category" *ngIf="item?.transactionTypeId == 6 || item?.transactionTypeId == 9">
              <div [attr.data-e2e]="'transaction-registered-' + i">Cadastrada</div>
              <span [attr.data-e2e]="'transaction-registered-category-' + i" id="registeredCategory{{i}}">{{
                item?.registeredCategory }}</span>
              <div [attr.data-e2e]="'transaction-charged-' + i" class="mt-2">Cobrada</div>
              <span [attr.data-e2e]="'transaction-charged-category-' + i" id="chargedCategory{{i}}">{{
                item?.chargedCategory }}</span>
            </td>
            <td id="category" *ngIf="item?.transactionTypeId == 15 || item?.transactionTypeId == 16"></td>

            <!-- TRANSAÇÕES TIPO PEDÁGIO -->
            <td id="description" *ngIf="item?.transactionTypeId == 6 || item?.transactionTypeId == 9">
              <span [attr.data-e2e]="'toll-description-' + i" id="summary{{i}}">{{ item?.summary }} </span>
              <span [attr.data-e2e]="'toll-establishment-name-' + i" id="merchantName{{i}}">{{ item?.merchantName }}
              </span>
              <br>
              <span [attr.data-e2e]="'toll-place-name-' + i" id="placeName{{i}}">{{ item?.placeName }} </span>
              <span [attr.data-e2e]="'toll-address-' + i" id="address{{i}}">{{ item?.address }}</span>
            </td>

            <!-- TRANSAÇÕES TIPO ESTACIONAMENTO -->
            <td [attr.data-e2e]="'parking-descrition-info' + i" id="description"
              *ngIf="item?.transactionTypeId == 15 || item?.transactionTypeId == 16">
              <div [attr.data-e2e]="'parking-establishment-name-' + i">{{ item?.merchantName }}</div>

              <div>Entrada:</div>

              {{ item?.entranceDate | date:'dd/MM/yyyy'}} | {{ item?.entranceDate | date:'HH:mm:ss'}}

              <div>Saida:</div>

              {{ item?.departureDate | date:'dd/MM/yyyy'}} | {{ item?.departureDate | date:'HH:mm:ss'}}
            </td>

            <td [attr.data-e2e]="'transaction-processing-value-' + i" class="values red"
              id="transactionProcessingAmount{{i}}">
              {{ item?.transactionProcessingAmount | currency:'BRL' : 'symbol':'0.2-2':'pt-BR'}}
            </td>

            <td [attr.data-e2e]="'transaction-vpr-value-' + i"  class="values" id="transactionProcessingVPR{{i}}">
              {{ item?.transactionProcessingVPR | currency:'BRL' :
              'symbol':'0.2-2':'pt-BR'}}
            </td>

            <td [attr.data-e2e]="'transaction-charged-value-' + i" class="values red"
              id="transactionChargedAmount{{i}}">
              {{ item?.transactionChargedAmount | currency:'BRL' : 'symbol':'0.2-2':'pt-BR'}}
            </td>
          </tr>
        </tbody>
        }
      </table>

      <div *ngIf="getTicketsQueryResult.total == 0 && !isLoading">
        <!-- <div colspan="12" class="text-center w-100 py-4" translate>Nenhum resultado encontrado.</div> -->
        <div colspan="12" data-e2e="empty-transaction-msg" class="text-center w-100 py-4" translate>
          Nenhuma transação encontrada do dia
          <span data-e2e="transaction-starting-date" id="startDate">
            {{ getTicketsQueryArguments.startDate | date:'dd/MM/yyyy' }}
          </span> até

          <span data-e2e="transaction-ending-date" id="endDate">
            {{ getTicketsQueryArguments.endDate | date:'dd/MM/yyyy' }}
          </span>
        </div>
      </div>

      <div class="pagination-custom" *ngIf="getTicketsQueryResult.total != 0 && !isLoading">
        <app-pagination [totalItems]="getTicketsQueryResult.total" [itemsPerPage]="getTicketsQueryArguments.pageSize"
          [maxPages]="5" [currentPage]="getTicketsQueryArguments.page"
          (pageChangeEvent)="onPaginationChange($event)"></app-pagination>
      </div>
    </div>

  </div>
</div>
