import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { PopUpComponent } from './pop-up.component';
import { environment } from '@env';
import { filter, switchMap, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {
  private base = environment.apiTMCustomer;
  private dialogRef: MatDialogRef<PopUpComponent> | null = null;
  private readNotifications: Set<string> = new Set();
  private initialized = false; // Para evitar múltiplas inicializações

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private http: HttpClient
  ) { }

  initialize(): void {
    if (this.initialized) {
      return; // Previne múltiplas inicializações
    }
    this.initialized = true;

    this.getGetNotificationByTypes().subscribe(notifications => {
      // Verificar notificações ao inicializar
      this.checkAndOpenPopUp(notifications, this.router.url);
    });

    // Verificar notificações em cada navegação
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => ({
        notifications: this.getGetNotificationByTypes(),
        currentPath: this.router.url
      }))
    ).subscribe(({ notifications, currentPath }) => {
      notifications.subscribe(notificationData => {
        this.checkAndOpenPopUp(notificationData, currentPath);
      });
    });

  }


  getGetNotificationByTypes() {
    // GET das notificações
    const notificationTypes = 12;
    const notificationStatus = 1;
    const url = `${this.base}/Notification/GetNotificationByTypes?notificationTypes=${notificationTypes}&notificationStatus=${notificationStatus}`;
    return this.http.get<any[]>(url);
  }

  private checkAndOpenPopUp(notifications: any[], currentPath: string): void {
    // Abrir a PopUp se houver notificações
    if (notifications?.length === 0) {
      return; // Se não há notificações, não faça nada
    }

    const notification = notifications?.find(n => n.pageDestination === currentPath && !this.readNotifications.has(n.id));
    // Verifica se já não há um popup aberto
    if (notification && !this.dialogRef) {
      this.openPopUp(notification);
    }
  }

  private openPopUp(notification: any): void {
    // Abrir a notificação com os valores de cada rota
    if (this.dialogRef) {
      this.dialogRef.close();
    }

    this.dialogRef = this.dialog.open(PopUpComponent, {
      width: '900px',
      data: {
        title: notification.title,
        message: notification.description,
        imageUrl: notification.imageUrl,
        button: notification.destinationTitle
      }
    });

    this.dialogRef.componentInstance.buttonClicked.subscribe(() => {
      this.onPopUpButtonClick(notification);
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
      this.readNotification(notification.id);
    });
  }

  private onPopUpButtonClick(notification: any): void {
    // Botão do popUp
    this.dialogRef?.close();
    this.readNotification(notification.id);
    this.router.navigate([notification.urlDestination]);
  }

  private readNotification(id: string): void {
    // Função para marcar a notificação como lida
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const apiUrl = `${this.base}/Notification/Read/${id}`;
    this.http.put(apiUrl, {}, httpOptions).pipe(
      tap(() => this.readNotifications.add(id))
    ).subscribe();
  }
}
