<div class="ExcludeCreditCardComponent">
  <div class="modal-content">
    <div class="modal-header">
      <div class="box-title"><span>Excluir Cartão de crédito</span></div>
      <button class="close" type="button" (click)="modal.close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="!isLoading">
      <div class="title">
        Deseja mesmo excluir o cartão de crédito?
      </div>
      <app-credit-card></app-credit-card>
      <p
        class="text"
        *ngIf="this.prepaidConditions.marketingPlanTypeId == 1 || this.prepaidConditions.marketingPlanTypeId == 6; else marketingPlanTypeMonthly"
      >
        Após a exclusão do seu cartão de crédito a recarga automática será desabilitada.
      </p>
      <ng-template #marketingPlanTypeMonthly class="text">
        Após a exclusão do seu cartão de crédito a recarga automática será desabilitada e o débito da mensalidade ocorrerá no saldo disponível do contrato.
      </ng-template>

    </div>
    <div class="modal-body"*ngIf="isLoading">
      <app-loader
        [isLoading]="isLoading"
        message="{{ 'Carregando...' }}"
      ></app-loader>
    </div>
    <div class="modal-footer modal-footer-gray">
        <button
          [disabled]="isLoading"
          type="button"
          class="btn btn-tertiary mr-4"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modal.close()"
        >
          Cancelar
        </button>
        <button
          [disabled]="isLoading"
          (click)="excludeCreditCard()"
          class="btn btn-secondary position-relative"
          type="button"
        >
          Excluir
        </button>
    </div>
  </div>
</div>
