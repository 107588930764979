<div class="confirmationBlockTypeModalComponent">
  <div class="modal-header">
    <h4 class="modal-title">CONFIGURAÇÃO DE BLOQUEIO FINANCEIRO</h4>
    <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
      <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>

  <div class="container-loading" *ngIf="isLoading">
    <app-loader
      id="loader-modal"
      data-e2e="loader-modal"
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    ></app-loader>
  </div>

  <div class="modal-body" *ngIf="!isLoading">
    <h6 id="modal-body-subtitle" data-e2e="modal-body-subtitle">TEM CERTEZA DE QUE DESEJA ATIVAR O BLOQUEIO FINANCEIRO {{ isChangingToBlockManual ? 'MANUAL' : 'AUTOMÁTICO' }}?</h6>

    <p id="modal-body-text-manual" data-e2e="modal-body-text-manual" *ngIf="isChangingToBlockManual">Ao alterar para bloqueio financeiro manual o contrato só poderá ser bloqueado manualmente. Você também poderá desbloquear o contrato manualmente.</p>
    <p id="modal-body-text-automatic" data-e2e="modal-body-text-automatic" *ngIf="!isChangingToBlockManual">Ao ativar o bloqueio financeiro automático, o contrato será automaticamente bloqueado caso o pagamento não seja efetuado até {{ daysToBlock }} dias após o vencimento da fatura. Após o pagamento, o desbloqueio do contrato ocorrerá automaticamente.</p>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-tertiary"
      id="modal-btn-cancel"
      data-e2e="modal-btn-cancel"
      (click)="dismissModal()"
    >
      CANCELAR
    </button>
    <button
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      (click)="updateContractBlockType()"
    >
      CONFIRMAR
    </button>
  </div>
</div>
