<div class="container-repom manageContainer">
  <div class="box-title">
    <span data-e2e="management-title">Gerenciamento</span>
  </div>
  <div>
    <div class="subMenu row">

      @if (handlePermission()) {
        <div class="col users-rep"
             (click)="selectedVision = 1"
             [ngClass]="selectedVision == 1 ? '' : 'disable'">
          <div class="col iconUsers">
            <img src="assets/svg/icon-representantes.svg">
          </div>
          <div class="col usersSubmenu">
            <div data-e2e="contract-users-title">Representantes e procuradores</div>
          </div>
          <div class="whitebar" [ngClass]="selectedVision == 1 ? '' : 'disable'"></div>
        </div>
      }

      <div class="col users-contract"
           (click)="selectedVision = 2"
           [ngClass]="selectedVision === 2 ? '' : 'disable'">
        <div class="col iconUsers">
          <img src="assets/img/icon-users.svg" alt="">
        </div>
        <div class="col usersSubmenu">
          <div data-e2e="contract-users-title">Usuários do contrato</div>
        </div>
        <div class="whitebar" [ngClass]="selectedVision == 2 ? '' : 'disable'"></div>
      </div>
    </div>

    @if (handlePermission()) {
      @if (selectedVision === 1) {
        <div class="col-12 users">
          <app-contract-representative-attorney></app-contract-representative-attorney>
        </div>
      }
    }

    @if (selectedVision === 2) {
      <div class="col-12 users">
        <app-contract-users [contractUsers]="contractUsers"></app-contract-users>
      </div>
    }

  </div>
</div>
