<div class="manage-hierarchy-vehicles">
  <div class="modal-header">
    <div class="box-title">
      <span>GERENCIAR VEÍCULOS ASSOCIADOS</span>
    </div>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="row-box-yellow">
    <span class="box-yellow">
      <b>C</b>aso procure um veículo que não esteja na relação abaixo é porque o
      mesmo já possui uma hierarquia. Altere sua hierarquia na tela de veículos ou
      por meio da edição de hierarquia
    </span>
  </div>
  <!-- start step-1 -->
  <div class="mt-5 d-flex align-items-center">
    <div class="mt-5 w-100 text-center">
      <div class="box-breadcrumb">
        <h4 class="mb-0">Veículos associados a hierarquia de grupos:</h4>
          <div class="row justify-content-center align-items-center">
             <p class="col-sm-auto" id="breadCrumb">{{breadCrumb}}</p><p class="col-sm-auto" id="description_PT">{{description_PT}}</p>
          </div>
      </div>
      <div>
        <div class="box-tabs-hierarquiadd-vehicle">
          <div class="col-lg-12">
            <label class="label-collapse text-left mb-3">VEÍCULOS DISPONÍVEIS</label>
          </div>
          <div class="tab-header">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
              <li ngbNavItem>
                <a ngbNavLink (click)="resetForm(1)">Somente associados</a>
                <ng-template ngbNavContent>
                  <div class="box-licenseplates">
                    <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'"  *ngIf="!isLoading && listVehicles.total == 0" class="text-center">
                      <b class="subtitle">Não há veículos disponíveis.</b>
                    </div>
                    <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'"  class="container-loading" *ngIf="isLoading">
                        <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
                    </div>
                  <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'"  *ngIf="!isLoading && listVehicles.total !== 0" >
                    <div class="custom-control custom-checkbox"  *ngFor="let item of listVehicles?.vehiclesAvailable; let i=index">
                      <div class="d-flex justify-content-around align-items-center">
                        <input type="checkbox" id="inputLicensePlate" class="custom-control-input" id="{{item.contractVehicleId}}"
                          name="{{item.licensePlate}}" [checked]="VerifyAssociate(item.isAssociated)" [value]="item.licensePlate" (change)="onCheckChange($event,item.isAssociated)">
                        <label class="custom-control-label font-weight-bold"
                          for="{{item.contractVehicleId}}">{{item.licensePlate | mask: 'AAA-AAAA' }}</label>
                        <div class="icon-actions" [popover]="actionPopover1" [outsideClick]="true" >
                          <i class="ico ico-white ico-menu-open"></i>
                        </div>
                      </div>

                      <ng-template #actionPopover1 placement="right" [outsideClick]="true">
                        <div class="row my-1">
                          <div class="head-info" translate>Placa:</div>
                          <div class="content-info" id="licensePlate">{{ item?.licensePlate | mask: 'AAA-AAAA' }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Apelido:</div>
                          <div class="content-info" id="nickName">{{ item?.nickname }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tag:</div>
                          <div class="content-info" id="serialTag">{{ item?.serial }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tipo:</div>
                          <div class="content-info" id="vehicleType">{{ item?.vehicleType }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Marca:</div>
                          <div class="content-info" id="descriptionVehicleBrandType">{{ item?.vehicleBrand }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Modelo:</div>
                          <div class="content-info" id="model">{{ item?.vehicleModel }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Nº de eixos:</div>
                          <div class="content-info" id="descriptionAxle">{{ item?.axleWheelType }}</div>
                        </div>
                      </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink (click)="resetForm(0)">Somente desassociados</a>
                <ng-template ngbNavContent>
                  <div class="box-licenseplates">
                    <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'"  *ngIf="!isLoading && listVehicles.total == 0" class="text-center">
                      <b class="subtitle">Não há veículos disponíveis.</b>
                    </div>
                    <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'" class="container-loading" *ngIf="isLoading">
                      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
                    </div>
                  <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'"  *ngIf="!isLoading && listVehicles.total !== 0">
                    <div class="custom-control custom-checkbox"  *ngFor="let item of listVehicles?.vehiclesAvailable">
                      <div class="d-flex justify-content-around align-items-center">
                        <input type="checkbox" class="custom-control-input" id="{{item.contractVehicleId}}"
                          name="{{item.licensePlate}}" [value]="item.licensePlate" (change)="onCheckChange($event,item.isAssociated)">
                        <label class="custom-control-label font-weight-bold"
                          for="{{item.contractVehicleId}}">{{item.licensePlate | mask: 'AAA-AAAA' }}</label>
                        <div class="icon-actions" [popover]="actionPopover2" [outsideClick]="true">
                          <i class="ico ico-white ico-menu-open"></i>
                        </div>
                      </div>
                      <ng-template #actionPopover2 placement="right" [outsideClick]="true">
                        <div class="row my-1">
                          <div class="head-info" translate>Placa:</div>
                          <div class="content-info">{{ item?.licensePlate | mask: 'AAA-AAAA' }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Apelido:</div>
                          <div class="content-info">{{ item?.nickname }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tag:</div>
                          <div class="content-info">{{ item?.serial }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tipo:</div>
                          <div class="content-info">{{ item?.vehicleType }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Marca:</div>
                          <div class="content-info">{{ item?.vehicleBrand }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Modelo:</div>
                          <div class="content-info">33{{ item?.vehicleModel }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Nº de eixos:</div>
                          <div class="content-info">{{ item?.axleWheelType }}</div>
                        </div>
                      </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink (click)="resetForm(2)">Pesquisar</a>
                <ng-template ngbNavContent>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-header-search">
                        <input type="text" value="90207 - 80173" placeholder="Placa do veiculo" (keyup)="onFullNameKeyup($event)"
                          class="form-control btn-search-order" [value]="getVehiclesAvailableArguments.search" />
                        <button type="button" class="btn btn-primary"  (click)="onBuscarClick($event)" ><i class="ico ico-search-white"></i>
                          buscar</button>
                      </div>
                    </div>
                    <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'" *ngIf="!isLoading && listVehicles.total == 0" class="text-center">
                      <b class="subtitle">Não há veículos disponíveis.</b>
                    </div>
                    <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'"  *ngIf="isLoading">
                      <div class="container-loading">
                        <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
                      </div>
                    </div>
                  <div [ngClass]="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize ? 'col-lg-12' : 'searchContent col-lg-12'" *ngIf="!isLoading && listVehicles.total !== 0">
                    <div class="custom-control custom-checkbox"
                    *ngFor="let item of listVehicles?.vehiclesAvailable">
                      <div class="d-flex justify-content-around align-items-center">
                        <input type="checkbox" [checked]="VerifyAssociate(item.isAssociated)" class="custom-control-input" id="{{item.contractVehicleId}}"
                          name="{{item.licensePlate}}" [value]="item.licensePlate" (change)="onCheckChange($event,item.isAssociated)">
                        <label class="custom-control-label font-weight-bold"
                          for="{{item.contractVehicleId}}">{{item.licensePlate | mask: 'AAA-AAAA' }}</label>
                        <div class="icon-actions" [popover]="actionPopover3" [outsideClick]="true" >
                          <i class="ico ico-white ico-menu-open"></i>
                        </div>
                      </div>
                      <ng-template #actionPopover3 placement="right" [outsideClick]="true" >
                        <div class="row my-1">
                          <div class="head-info" translate>Placa:</div>
                          <div class="content-info">{{ item?.licensePlate | mask: 'AAA-AAAA' }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Apelido:</div>
                          <div class="content-info">{{ item?.nickname }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tag:</div>
                          <div class="content-info">{{ item?.serial }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tipo:</div>
                          <div class="content-info">{{ item?.vehicleType }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Marca:</div>
                          <div class="content-info">{{ item?.vehicleBrand }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Modelo:</div>
                          <div class="content-info">{{ item?.vehicleModel }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Nº de eixos:</div>
                          <div class="content-info">{{ item?.axleWheelType }}</div>
                        </div>
                      </ng-template>
                     </div>
                   </div>
                  </div>
                </ng-template>
              </li>
            </ul>
          </div>
          <div [ngbNavOutlet]="nav"></div>
        </div>
        <div class="pagination-custom" *ngIf="listVehicles?.total != 0 && listVehicles?.total > getVehiclesAvailableArguments.pageSize">
          <app-pagination
            [totalItems]="listVehicles.total"
            [itemsPerPage]="getVehiclesAvailableArguments.pageSize"
            [maxPages]="5"
            [currentPage]="getVehiclesAvailableArguments.page"
            (pageChangeEvent)="onPaginationChange($event)"
          ></app-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-gray justify-content-start">
    <div class="col-8 justify-content-between d-flex">
      <button type="button" class="btn btn-default btn-primary  mr-4" data-dismiss="modal" aria-label="Close" (click)="close()" id="btn-close">Fechar</button>
      <button [disabled]="DisableButton || isLoading" (click)="SaveVehicle()" class="btn btn-repom btn-repom-user" type="button"><i class="ico ico-save" id="btn-confirm"></i>Salvar alterações<span class="btn-animation"></span></button>
    </div>
  </div>
</div>
<!-- <pre>associar: {{vehicleHierarchyForm[0].value | json}} </pre>
<pre>desassociar: {{vehicleHierarchyForm[1].value | json}} </pre> -->
