<div class="DeleteDeliveryAddressComponent">
    <div class="modal-content">
        <div class="modal-header">
          <div class="box-title">
            <span  data-e2e="modal-title">EXCLUIR ENDEREÇO DE ENTREGA</span>
          </div>
          <button data-e2e="close-top-btn" type="button" class="close" (click)="close()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body confirm-data show">
          <div class="row">
            <div class="col-lg-12">
              <h3 data-e2e="modal-subtitle" >Você tem certeza de que deseja excluir o endereço abaixo?</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 border-gray formDeleteAddress">
                 <app-address-confirm-data [PersonAddressToConfirm]="AddressObject"></app-address-confirm-data>
            </div>
          </div>
        </div>
        <div class="modal-footer modal-footer-gray">
          <button data-e2e="return-btn" [disabled]="onSaving" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-return">Voltar</button>
          <button data-e2e="confirm-btn" [disabled]="onSaving" type="button" class="btn btn-primary" id="confirmBtn" (click)="confirmDelete()">
            <img class="button-icon" *ngIf="!onSaving" src="../../../../assets/img/ico-delete.svg" alt="Replace" />
            <img class="button-icon" *ngIf="onSaving" src="../../../../assets/img/loader.gif" alt="Loading" />
            CONFIRMAR EXCLUSÃO
        </button>
      </div>
      </div>
</div>

  