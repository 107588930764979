export class ChargebackApproveReport {
  authorization: string;
  nsu: string;
  chargebackStatus: number;
  reason: string;
  isApproved: boolean;

  constructor() {
    this.authorization = '1';
    this.chargebackStatus = 1;
    this.nsu = '1';
    this.reason = 'reason';
    this.isApproved = false;
  }
}
