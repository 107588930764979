<div class="paymentPrepaid">
            <div class="row row-title">
                <div class="col-lg-4">
                </div>
                <div class="col-lg-4 desc">
                    <p class="title">Condições financeiras</p>
                </div>
                <div class="col-lg-4">
                </div>
            </div>
          
            <div class="row">
                <div class="col-lg-6">
                    <div class="box-gray financialConditionBox">
                        <i class="ico ico-alert"  placement="left" ngbTooltip="Saldo mínimo estabelecido para o plano escolhido."></i>
                        <div class="row">
                            <div class="col-lg-12 p-0 align-left">
                                <h4 class="h4title">Saldo mínimo para recarga automática</h4>
                                <p class="price"><b>{{commercialCondition.minimumBalanceForAutomaticRecharge| currency:'BRL' : 'symbol':'0.2-2':'pt-BR'}}</b></p>
                                <p class="percent">{{ commercialCondition.minimumPercentageForAutomaticRecharge | currency: ' ':'symbol':'0.2-2':'pt-BR' }}% do valor da recarga automática</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="box-gray financialConditionBox">
                        <i class="ico ico-alert"  placement="left"
                        ngbTooltip="Toda vez que o saldo de sua conta atingir o percentual de saldo mínimo, será debitado um novo valor de recarga automática."></i>
                        <div class="row commercialConditionsBox2">
                            <div class="col-lg-12 p-0 align-left">
                                <h4 class="h4title">Valor de recarga automática</h4>
                                <p class="price"><b>{{  commercialCondition.automaticRechargeValue | currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="accordion" id="accordionFeeRecharge">
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFee" aria-expanded="true" aria-controls="collapseFee">
                              Taxas de Recarga
                            </button>
                          </h2>
                          <div id="collapseFee" class="accordion-collapse collapse" data-bs-parent="#accordionFeeRecharge">
                            <div class="accordion-body">
                                <recharge-fee-table [rechargeFee]="commercialCondition.rechargeFee"></recharge-fee-table>
                            </div>
                          </div>
                        </div>     
                    </div>             
                </div>
            </div>
           
        <div class="row">
            <div class="col-lg-12">     
                <div class="box-gray paymentTypes">
                    <i class="ico ico-alert"  placement="left"
                    ngbTooltip="Meios de pagamento cadastrados. Eles serão utilizados para cobrança de valores referentes a serviços e utilização das tags."></i>
                <h3>FORMA DE PAGAMENTO</h3>

                    <div class="row">
                        
                        <div class="col-6">
                            <div class="box-white">
                            <div class="row PaymentRow" *ngIf="commercialCondition.creditCardNumber">
                                <div
                                class="col p-0 creditCardFlag"
                                [ngSwitch]="commercialCondition.creditCardBrandTypeId"
                                >
                                <img *ngSwitchCase="1" src="../../../assets/img/mastercard.svg" />
                                <img *ngSwitchCase="2" src="../../../assets/img/hipercard.svg" />
                                <img *ngSwitchCase="3" src="../../../assets/img/visa.svg" />
                                <img *ngSwitchCase="4" src="../../../assets/img/amex.svg" />
                                <img *ngSwitchCase="5" src="../../../assets/img/elo.svg" />
                                <img *ngSwitchCase="6" src="../../../assets/img/dinersclub.svg" />
                                </div>
                                <div class="col p-0 creditCardNumber">
                                **** **** **** {{ commercialCondition.creditCardNumber }}
                                </div>
                            </div>
                            <div class="row PaymentRow" *ngIf="!commercialCondition.creditCardNumber">
                                <span class="noCreditCard">Sem cartão de crédito cadastrado</span> 
                            </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="box-white align-left">
                                <img src="/assets/img/icon-pix.svg" />
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
</div>