  import { Injectable } from '@angular/core';
  import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
  import { environment } from 'src/environments/environment'
import { GetInconsistenciesQueryResultDto } from 'src/app/shared/dtos/getInconsistenciesQueryResult.dto';
 
  @Injectable({
    providedIn: 'root'
  })
  
  export class FileInconsistenciesModalService {
    private base = environment.apiTMVehicle;
    constructor(protected http: HttpClient) { }
  
    getBatchVehicle(idDestination:number) {
        let url = this.base + `/Vehicle/GetBatchVehicle/`+idDestination;         
        return this.http.get<GetInconsistenciesQueryResultDto>(url);
    }
  
  }
