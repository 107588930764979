import { Injectable } from "@angular/core";
import { UserInfo } from "../models/userInfo.dto";

enum LocalstorageKeys {
  ContractId = "contractId",
  Contract = "contract",
  Roles = "Roles",
  RolesBackOffice = "RolesBackOffice",
  HaveManyContracts = "HaveManyContracts",
  InfoUser = "InfoUser",
  ContractModalityTypeId = "contractModalityTypeId",
  hasCreditCard = "hasCreditCard",
  hasMultipleProfile = "hasMultipleProfile",
  ContractBlockLevel = "contractBlockLevel",
  ExpiresAt = "expires_at",
  Profile = "Profile",
  currentIdentity = "currentIdentity",
  ContractIsDualTag = "ContractIsDualTag",
  VehiclesDualTag = 'vehiclesDualTag'

}
@Injectable({
  providedIn: "root",
})
export class LocalstorageService {
  get hasCreditCard(): boolean {
    return JSON.parse(localStorage.getItem(LocalstorageKeys.hasCreditCard));
  }

  clearAllItens(): boolean {
    try {
      localStorage.clear();
      return true;
    } catch (e) {
      return false;
    }
  }

  removeItem(keyName: string): boolean {
    try {
      localStorage.removeItem(keyName);
      return true;
    } catch (e) {
      return false;
    }
  }

  addItemGeneric<T>(keyName: string, keyValue: T): boolean {
    try {
      localStorage.setItem(keyName, JSON.stringify(keyValue));
      return true;
    } catch (e) {
      return false;
    }
  }

  /*
  checksLocalStorageSupport(): boolean {
    if (window.localStorage) {
      return true;
    } else {
      return false;
    }
  }
  */

  getContractId() {
    const contractId = localStorage.getItem(LocalstorageKeys.ContractId);
    return contractId ?? "0";
  }

  setContractId(contractId: string | number) {
    if (contractId == null) {
      localStorage.removeItem(LocalstorageKeys.ContractId);
      return;
    }
    localStorage.setItem(LocalstorageKeys.ContractId, String(contractId));
  }

  setUser(user: any) {
    if (user == null) {
      localStorage.removeItem(LocalstorageKeys.InfoUser);
      return;
    }
    localStorage.setItem("InfoUser", JSON.stringify(user));
  }

  getUserInfo() : UserInfo {
    const userData = localStorage.getItem("InfoUser")
    return JSON.parse(userData);
  }

  getRoles() {
    return (localStorage.getItem(LocalstorageKeys.Roles) ?? "").split(",");
  }

  getRolesBackOffice() {
    return (localStorage.getItem(LocalstorageKeys.RolesBackOffice) ?? "").split(",");
  }

  setRoles(value: Array<string>) {
    if (value == null) {
      localStorage.removeItem(LocalstorageKeys.Roles);
      return;
    }
    localStorage.setItem(LocalstorageKeys.Roles, value.map((x) => x).join(","));
  }

  setRolesBackOffice(value: Array<string>) {
    if (value == null) {
      localStorage.removeItem(LocalstorageKeys.RolesBackOffice);
      return;
    }
    localStorage.setItem(LocalstorageKeys.RolesBackOffice, value.map((x) => x).join(","));
  }

  setHaveManyContracts(value: boolean) {
    localStorage.setItem(LocalstorageKeys.HaveManyContracts, value.toString());
  }

  getHaveManyContracts(): boolean {
    return localStorage.getItem(LocalstorageKeys.HaveManyContracts) == true.toString();
  }

  getContractModalityTypeId(): string {
    const contractModalityTypeId: string = localStorage.getItem(LocalstorageKeys.ContractModalityTypeId);
    return contractModalityTypeId ?? '0';
  }

  getHasCreditCard() {
    return localStorage.getItem(LocalstorageKeys.hasCreditCard);
  }

  /**
   *sets a boolean value if the contract has or not a credit card. It stores the value on the LocalStorage as "hasCreditCard".
   * @param {boolean} status boolean value if the contract has or not a credit card.
   *
   */
  setCrediCardCheck(status: any): void {
    localStorage.setItem(
      LocalstorageKeys.hasCreditCard,
      JSON.stringify(status)
    );
  }

  removeCreditcard(): void {
    localStorage.removeItem(LocalstorageKeys.hasCreditCard);
  }

  setContractModalityTypeId(contractModalityTypeId: string | number) {
    if (contractModalityTypeId == null) {
      localStorage.removeItem(LocalstorageKeys.ContractModalityTypeId);
      return;
    }

    localStorage.setItem(LocalstorageKeys.ContractModalityTypeId, String(contractModalityTypeId));
  }

  getContractBlockLevel() {
    return localStorage.getItem(LocalstorageKeys.ContractBlockLevel) ?? "0";
  }

  setContractBlockLevel(ContractBlockLevel) {
    if (ContractBlockLevel == null) {
      localStorage.removeItem(LocalstorageKeys.ContractBlockLevel);
      return;
    }

    localStorage.setItem(
      LocalstorageKeys.ContractBlockLevel,
      ContractBlockLevel
    );
  }

  getExpiresSession() {
    return parseInt(localStorage.getItem(LocalstorageKeys.ExpiresAt), 10);
  }

  setProfile(profile: any) {
    if (profile == null) {
      return;
    }

    localStorage.setItem(LocalstorageKeys.Profile, String(profile));
  }

  getProfile(): number {
    const profile: any = localStorage.getItem(LocalstorageKeys.Profile);

    if (!profile) {
      return 0;
    }

    return Number(profile);
  }

  getSessionStorageContractId(): string {
    const contractId = sessionStorage.getItem('contractId');
    return contractId ? contractId : "0";
  }

  setHasMultipleProfile(hasMultipleProfile: boolean) {
    if (hasMultipleProfile == null) {
      localStorage.setItem(LocalstorageKeys.hasMultipleProfile, JSON.stringify(false));
      return;
    }

    localStorage.setItem(LocalstorageKeys.hasMultipleProfile, JSON.stringify(hasMultipleProfile));
  }

  hasMultipleProfile() {
    return JSON.parse(localStorage.getItem(LocalstorageKeys.hasMultipleProfile));
  }

  //Remove items from the LocalStorage
  public removeAllProfiles(): void {
    localStorage.removeItem(LocalstorageKeys.Profile);
  }

  public removeContractId() {
    return localStorage.removeItem('contractId');
  }

  public removeContractModalityTypeId() {
    return localStorage.removeItem('contractModalityTypeId');
  }

  public removeContractBlockLevel() {
    return localStorage.removeItem('contractBlockLevel');
  }

  getCurrentIdentity(): number {
    const userId = localStorage.getItem(LocalstorageKeys.currentIdentity);
    return Number(userId);
  }

  setCurrentIdentity(userId: number) {
    localStorage.setItem(LocalstorageKeys.currentIdentity, String(userId));
  }

  setDualTag(isDualTag: boolean) {
    localStorage.setItem(LocalstorageKeys.ContractIsDualTag, JSON.stringify(isDualTag));
  }

  getDualTag(): boolean {
    return localStorage.getItem(LocalstorageKeys.ContractIsDualTag) != null ? JSON.parse(localStorage.getItem(LocalstorageKeys.ContractIsDualTag)) : false;
  }

  getListVehiclesDualTag(): any {
    return localStorage.getItem(LocalstorageKeys.VehiclesDualTag) != null ? JSON.parse(localStorage.getItem(LocalstorageKeys.VehiclesDualTag)) : [];
  }

}
