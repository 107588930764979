<div class="modal-header">
  <h4 data-e2e="modal-title" class="modal-title text-uppercase" translate>
    Track order
  </h4>
  <a data-e2e="close-modal-top-btn" class="btn-close" (click)="dismissModal()">
    <img src="assets/svg/close.svg" />
  </a>
</div>

<div class="modal-body">
  <div class="modal-content col-lg-8">
    <form [formGroup]="viewTrackingForm">
      <div class="form-group d-flex justify-content-center mb-4">
        <div class="col-12">
          <div class="text-center">
            <div class="col-12 d-flex justify-content-center mb-4">
              <img
                [src]="this.order.logisticPartnerImage"
                alt=""
                class="w-50"
              />
            </div>

            <label data-e2e="tracking-code-label" translate class="tracking-code-label"
              >Enter your order tracking code</label
            >
            <div class="search-tracking-box">
              <input
                type="text"
                data-e2e="tracking-code-input"
                formControlName="trackingCode"
                class="form-control form-icon-insert text-uppercase"
                maxlength="30"
              />
              <button
                data-e2e="search-btn"
                class="btn btn-search"
                translate
                (click)="openViewTracking()"
              >
                <img src="assets/img/icon-search.svg" class="button-icon" />
                <span translate>Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <div class="d-flex col-12 justify-content-between align-items-center">
    <div>
      <button
        data-e2e="close-modal-btn"
        class="btn btn-secondary"
        (click)="dismissModal()"
      >
        <div translate>Close</div>
      </button>
    </div>

    <div class="box-copyTrackingCode shadow">
      <button
        data-e2e="copy-tracking-code-btn"
        class="btn btn-primary shadow-sm mr-4"
        translate
        (click)="copyCode(this.order.trackingCode)"
      >
        <img src="assets/img/copy.svg" class="button-icon" />
        Copy tracking code
      </button>
      <span data-e2e="tracking-code">
        {{ this.order.trackingCode }}
      </span>
    </div>
  </div>
</div>
