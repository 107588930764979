<div class="EditDeliveryAddressComponent">
    <div class="modal-content" #template>
        <div class="modal-header">
          <div class="box-title">
            <span data-e2e="modal-title">ALTERAR ENDEREÇO DE ENTREGA</span>
          </div>
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body show" *ngIf="step == 1">
          <div class="row">
            <div class="col-lg-12 border-gray formEditAddress">
                 <app-address-form (event)="receiveEvent($event)" [PreviousAddressObject]="PreviousAddressObject" [AddressObject]="AddressObject" [personId]="personId"></app-address-form>
            </div>
          </div>
        </div>
        <div class="modal-body confirm-data show" *ngIf="step == 2">
          <div class="row">
            <div class="col-lg-12">
              <h3 data-e2e="modal-subtitle-confirmation">Confira se os dados estão corretos e confirme:</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 border-gray formAddAddress">
                 <app-address-confirm-data [PersonAddressToConfirm]="AddressObject"></app-address-confirm-data>
            </div>
          </div>
        </div>
        <div class="modal-footer modal-footer-gray">
          <button *ngIf="step == 1" data-e2e="cancel-btn" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-close">Cancelar</button>
          <button *ngIf="step == 1" data-e2e="save-address-btn" [disabled]="isDisable" id="btn-saveadress"  class="btn btn-secondary position-relative" (click)="changeStep(2)">
            Salvar <span *ngIf="fromOrder">e escolher</span> endereço    
          </button>  
          <button *ngIf="step == 2" data-e2e="return-btn" [disabled]="onSaving" type="button" class="btn btn-tertiary mr-4" (click)="changeStep(1)" id="btn-return">Voltar</button>
          <button *ngIf="step == 2" data-e2e="confirm-btn" [disabled]="onSaving" id="btn-confirm"  class="btn btn-secondary confirm-btn" (click)="confirmEdit()">
              Confirmar    
              <img class="loading-btn" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />          
          </button>  
      </div>
      </div>
</div>

  