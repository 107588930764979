<div class="commercialConditionPostPaid" *modalityType="2">
  <div data-e2e="section-title" class="registrationTitle">Condições comerciais</div>
  <div class="row">
    <div class="company-description" [ngClass]="commercialCondition?.tollVoucherAnticipation ? 'col-7' : 'col-6'">
      <p data-e2e="payment-type-label">Tipo de faturamento:</p>
      <p data-e2e="payment-cycle-label">Ciclo de faturamento:</p>
      <p data-e2e="payment-method-label">Método de pagamento:</p>
      <p data-e2e="payment-deadline-label">Prazo de pagamento:</p>
      <p data-e2e="vpr-advance-label" *ngIf="commercialCondition?.tollVoucherAnticipation">Serviço de Antecipação de
        Vale-Pedágio:</p>
    </div>

    <div [ngClass]="commercialCondition?.tollVoucherAnticipation ? 'col-5' : 'col-6'" *ngIf="commercialCondition">
      <p data-e2e="payment-type" id="contractModalityType">Pós-pago</p>
      <div class="modality" [ngSwitch]="commercialCondition.contractBillingCuttingTypeId">
        <p data-e2e="payment-cycle" id="contractBillingCuttingType" *ngSwitchCase="0"></p>
        <p data-e2e="payment-cycle" id="contractBillingCuttingType" *ngSwitchCase="1">Semanal</p>
        <p data-e2e="payment-cycle" id="contractBillingCuttingType" *ngSwitchCase="2">Quinzenal</p>
        <p data-e2e="payment-cycle" id="contractBillingCuttingType" *ngSwitchCase="3">Mensal - {{
          contractBillingCuttingCycle === 'Último dia do mês' ? contractBillingCuttingCycle : 'todo dia ' +
          contractBillingCuttingCycle }}</p>
        <p data-e2e="payment-cycle" id="contractBillingCuttingType" *ngSwitchCase="4">Diferenciado</p>
        <p data-e2e="payment-cycle" id="contractBillingCuttingType" *ngSwitchCase="5">Intervalo Padronizado</p>
      </div>

      <div [ngSwitch]="principalPaymentMethodType">
        <p data-e2e="payment-method" id="principalPaymentMethodType" *ngSwitchCase="0"></p>
        <p data-e2e="payment-method" id="principalPaymentMethodType" *ngSwitchCase="1">Débito da conta</p>
        <p data-e2e="payment-method" id="principalPaymentMethodType" *ngSwitchCase="2">Boleto bancário</p>
        <p data-e2e="payment-method" id="principalPaymentMethodType" *ngSwitchCase="3">CreditCard</p>
      </div>

      <p data-e2e="payment-deadline-msg" id="paymentTerm">
        <span data-e2e="payment-deadline" id="paymentTermNumber">{{ commercialCondition.paymentTerm }}</span> dia{{
        commercialCondition.paymentTerm > 1 ? 's': '' }}
      </p>

      <p id="tollVoucherAnticipation" *ngIf="commercialCondition?.tollVoucherAnticipation">
        <span data-e2e="vpr-advance" id="tollVoucherAnticipationValue"
          *ngIf="commercialCondition?.tollVoucherAntecipationService > 0">{{
          commercialCondition?.tollVoucherAntecipationService | currency: ' ':'symbol':'0.2-2':'pt-BR' }}%
        </span>

        <span data-e2e="bonus-msg" id="tollVoucherAnticipationValue"
          *ngIf="!commercialCondition?.tollVoucherAntecipationService || commercialCondition?.tollVoucherAntecipationService == 0">Bonificado
        </span>
      </p>

    </div>

    <div class="col-12 btn-center">

      <app-borderless-button [description]="' Consultar detalhes'" [verifyContractBlock]="true"
        [classIconLeft]="'ico ico-add'" [id-html]="'consultCommercialConditionsDetailsButton'"
        [data-e2e]="'view-details-btn'" [buttonColor]="'primary'" [isBtnWithBorder]="true"
        (emitterEvtClick)="goToDetails()">
      </app-borderless-button>

    </div>
  </div>
</div>


<div class="commercialConditionPostPaid" *modalityType="1">
  <div data-e2e="section-title" class="registrationTitle">Condições comerciais</div>
  <div class="row">
    <div class="col-6 company-description">
      <p data-e2e="condition-payment-type-label">Tipo de faturamento:</p>
      <p data-e2e="condition-plan-label">Plano:</p>
      <p data-e2e="condition-auto-recharge-label">Valor da recarga automática:</p>
      <p data-e2e="condition-minimun-balance-label">Saldo mínimo ({{
        commercialCondition.minimumPercentageForAutomaticRecharge }}%):</p>

      <br>
      <p data-e2e="condition-closing-day-label">Dia do fechamento da fatura:</p>
      <p data-e2e="condition-payment-method-label">Método de pagamento:</p>
      <p data-e2e="condition-monthly-debt-day-label">Dia de débito de mensalidade:</p>

    </div>

    <div class="col-6">
      <p data-e2e="condition-payment-type" id="contractModalityType"> {{ commercialCondition.contractModality }}</p>
      <p data-e2e="condition-plan">{{ commercialCondition.contractedPlan }}</p>
      <p data-e2e="condition-auto-recharge"> {{
        commercialCondition.automaticRechargeValue
        | currency: 'BRL':'symbol':'0.2-2':'pt-BR'
        }}</p>
      <p data-e2e="condition-minimun-balance"> {{
        commercialCondition.minimumAmountContract
        | currency: 'BRL':'symbol':'0.2-2':'pt-BR'
        }}</p>

      <br>

      <p data-e2e="condition-closing-day">{{ commercialCondition.closingBillDay }}</p>
      <p data-e2e="condition-payment-method">{{ commercialCondition.paymentMethod }}</p>
      <p data-e2e="condition-monthly-debt-day">{{ commercialCondition.monthlyDebitDay }}</p>
    </div>

    <div class="col-12 btn-center">
      <app-borderless-button [description]="' Consultar detalhes'" [verifyContractBlock]="true"
        [classIconLeft]="'ico ico-add'" [id-html]="'consultCommercialConditionsDetailsButton'"
        [data-e2e]="'view-details-btn'" [buttonColor]="'primary'" [isBtnWithBorder]="true"
        (emitterEvtClick)="goToDetails()">
      </app-borderless-button>
    </div>
  </div>
</div>