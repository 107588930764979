import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { RechargeComponent } from '../recharge.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "recargas",
      component: RechargeComponent,
      canActivate: [authGuardCustomer],
      data: {
        roles: "101174",
        backofficeRoles: '100994,100995,100996',
        contractRequired: true,
        prepaidPage: true
      },
    }
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class RechargeRoutingModule {}
