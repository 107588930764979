import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ButtonBlockService {
  private base = environment.apiTMCustomer

  constructor(protected http: HttpClient) { }


  getContracts() {
    let cacheExpiry = 300000;
    let headers = new HttpHeaders({
         'cache-response': 'true'
        ,'cache-expiry': cacheExpiry.toString()
    });
    let apiUrl = `${this.base}/Contract/GetContractsByCurrentUser`;
      return this.http.get<any[]>(apiUrl,{ headers });
    }

  getContractByDocument(document: any) {
    let cacheExpiry = 300000;
    let headers = new HttpHeaders({
      'cache-response': 'true',
      'cache-expiry': cacheExpiry.toString(),
    });

    let apiUrl = `${this.base}/Contract/GetContractsByCpfCnpj/` + document;
    return this.http.get<any[]>(apiUrl, { headers });
  }
}


