import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { TabType } from '@modules/customer/batch-management/dtos/batch-management-consts';
import { TagActivateUploadModalComponent } from './components/tag-activate-upload-modal/tag-activate-upload-modal.component';
import { ITabs } from 'src/app/shared/components/tabs/models/tabs-models';
import { VehicleService } from '@services/vehicle.service';
import { TagService } from '@services/tag.service';
import { ITagBlockUnblockDto } from '@modules/customer/batch-management/dtos/tag-block-unblock.dto';
import { TagBlockUnblockModalComponent } from './components/tag-block-unblock-modal/tag-block-unblock-modal.component';
import { TabsSubscriptionService } from 'src/app/shared/services/tabs-subscription.service';

@Component({
  selector: 'app-batch-management-card',
  templateUrl: './batch-management-card.component.html',
  styleUrl: './batch-management-card.component.scss'
})
export class BatchManagementCardComponent implements OnInit {
  readonly tabType = TabType;

  tabs: ITabs[];
  tabSelected: TabType;
  isLoading: boolean;
  tagsBlockUnblockSelected: ITagBlockUnblockDto[];
  hasTagsAvaliable: boolean
  hasVehiclesAvaliable: boolean
  hasTagsAndVehiclesAvaliable: boolean;
  controlReloadRequestComponent: number;

  constructor(
    private modalService: NgbModal,
    private vehicleService: VehicleService,
    private tagService: TagService,
    private tabsSubscriptionService: TabsSubscriptionService
  ) { }

  ngOnInit(): void {
    this.settingsInitClass();
    this.handleStartTabActive();
    this.validateTagsAndVehiclesAvaliable();
  }

  private settingsInitClass() {
    this.tabs = [
      {index: 1, name: 'Ativar tags', styles:'width: 18%;'},
      {index: 2, name: 'Bloquear tags', styles:'width: 20%;'},
      {index: 3, name: 'Desbloquear tags', styles:'width: 22%;'},
      {index: 4, name: 'Histórico de bloqueio e desbloqueio', styles:'width: 40%;'},
    ];

    this.tagsBlockUnblockSelected = [];
    this.controlReloadRequestComponent = 0;
  }

  handleTabsSelection(event: number) {
    this.tabSelected = event;
    this.tagsBlockUnblockSelected = [];
  }

  private validateTagsAndVehiclesAvaliable() {
    this.requestTagsAvailable();
  }

  private requestTagsAvailable() {
    this.isLoading = true;

    this.tagService.getTagAvailableByContractId()
      .pipe(
        finalize(() => {
          this.requestVehiclesAvaliable();
        })
      )
      .subscribe({
        next: (success) => {
          this.hasTagsAvaliable = success.length > 0;
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  private requestVehiclesAvaliable() {
    this.vehicleService.getVehicleAvailableByContractId()
      .pipe(
        finalize(() => {
          this.hasTagsAndVehiclesAvaliable =  this.hasTagsAvaliable && this.hasVehiclesAvaliable;
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.hasVehiclesAvaliable = success.length > 0;
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  openTagActivateUploadModal() {
    const modal = this.modalService.open(TagActivateUploadModalComponent);

    modal.componentInstance.outEventReloadRequest.subscribe(() => {
      this.controlReloadRequestComponent++;
    });
  }

  handleTagsSelected(tagsSelected: ITagBlockUnblockDto[]) {
    this.tagsBlockUnblockSelected = tagsSelected;
  }

  openTagBlockUnblockModal() {
    const modal = this.modalService.open(TagBlockUnblockModalComponent);
    modal.componentInstance.inTabTypeActive = this.tabSelected;
    modal.componentInstance.inTagsSelected = this.tagsBlockUnblockSelected;

    modal.componentInstance.outEventChangeTab.subscribe((tab) => {
      this.handleTabsSelection(tab);
    });
  }

  private handleStartTabActive() {
    this.tabsSubscriptionService.tabActive$.subscribe((tab) => {
      this.tabSelected = tab;
    });
  }
}
