import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
import { PaymentService } from "@services/payment.service";
import { PaymentMethodEnum, PaymentOrigin } from "@models/payment.model";
import { TagRequestQueryResult } from "@models/old/tagrequestqueryresult/tagrequestqueryresult.model";
import { TagRequest } from "@models/old/tagrequest/tagrequest.model";
import { RegionShippingCharge } from "@models/old/tagrequestqueryresult/regionshippingcharge.model";
import { NotifyService } from "@services/notify.service";
import { TelephoneValidator } from "@services/form-validators/telephone-validator.service";
import { TagRequestReceivingResponsible } from "@models/old/tagrequest/tagrequestreceivingresponsible.model";
import { ContractModality } from "@models/old/contractModality.enum";
import { DeliveryAddressesModalComponent } from "src/app/shared/components/address/delivery-addresses-modal/delivery-addresses-modal.component";
import { PurchaseConfirmationModalComponent } from "src/app/shared/components/payment/purchase-confirmation-modal/purchase-confirmation-modal.component";
import { PixPaymentModalComponent } from "src/app/shared/components/payment/pix-payment-modal/pix-payment-modal.component";
import { RegisterCreditCardModalComponent } from "src/app/shared/components/payment/register-credit-card-modal/register-credit-card-modal.component";
import { PaymentTransactionConfirmationComponent } from "src/app/shared/components/payment/payment-transaction-confirmation/payment-transaction-confirmation.component";
import { StoragedContractInfosService } from "@services/storaged-contract-infos.service";
import {AbstractProcessLauncher} from "../../../../../shared/abstracts/abstract-process-launcher";
import {MyordersService} from "@modules/customer/myorder/services/myorder.service";
import {PaymentMethodComponent} from "../../../../../shared/components/payment/payment-method/payment-method.component";

@Component({
  selector: "app-neworder",
  templateUrl: "./neworder.component.html",
  styleUrls: ["./neworder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NeworderComponent extends AbstractProcessLauncher implements OnInit {
  @Output() event: EventEmitter<any> = new EventEmitter();

  tagRequestQueryResult: TagRequestQueryResult;
  contract: any;
  tagrequest: TagRequest = new TagRequest();
  regionShippingChargeSelected = new RegionShippingCharge();
  model: any = {};
  onSaving = false;
  clickedButton: boolean = false;
  message: string = "Próximo";
  stateButton: number = 1;
  step: number = 1;
  isLoading: boolean;
  isLoadingAddress: boolean;
  submitted: boolean
  modalityTypeId: number;
  orderForm = new FormGroup({
    amount: new FormControl(this.tagrequest.amount, [
      Validators.required,
      Validators.min(1),
      Validators.max(500)
    ]),
    fullName: new FormControl(this.tagrequest.tagRequestReceivingResponsible.fullName, [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(100),
    ]),
    telephoneNumber: new FormControl(this.tagrequest.tagRequestReceivingResponsible.telephone, [
      Validators.required,
      TelephoneValidator.isValidTelephone(),
    ])
  });

  constructor(
    private _orderService: MyordersService,
    public _paymentService: PaymentService,
    protected _notifyService: NotifyService,
    private _modalService: NgbModal,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private _storagedContractInfo: StoragedContractInfosService,
    public paymentMethodComponent: PaymentMethodComponent,
    protected router: Router
  ) {
    super(router)
  }

  getValueChange() {
    this.orderForm.get('amount').valueChanges.subscribe(data => {
      this.calcularTagRequest(data);
    });
  }



  generateErrorMessage(formControl) {
    if (formControl.errors.required) {
      return 'Campo obrigatório'
    }
    else if (formControl.errors.min) {
      return `A quantidade de tags por pedido deve ser igual ou maior que ${formControl.errors.min.min}.`;
    }
    else if (formControl.errors.max) {
      return `Por questões de segurança, restringimos a quantidade de tags por pedido em ${formControl.errors.max.max} unidades.`
    }
    else if (formControl.errors.minlength) {
      return 'mínimo ' + formControl.errors.minlength.requiredLength + ' caracter(es).'
    }
    else if (formControl.errors.maxlength) {
      return 'máximo ' + formControl.errors.maxlength.requiredLength + ' caracter(es).'
    }
    else if (formControl.errors.telephoneInvalid) {
      return 'Número de telefone inválido.'
    }
  }

  isCellPhone(phone): boolean {
    if (phone) {
      return phone.length >= 11;
    }
    else {
      return false
    }
  }

  getPhoneMask() {
    let filter = this.orderForm.controls['telephoneNumber'].value;
    return this.isCellPhone(filter)
      ? '(00) 00000-0000'
      : '(00) 0000-00009'
  }

  defaultObj() {
    this.tagrequest.tagRequestReceivingResponsible =
      new TagRequestReceivingResponsible();
    this.tagrequest.tagRequestReceivingResponsible.telephone = null;
    this.tagrequest.amount = null;
    this.tagrequest.totalTagValue = 0;
    this.tagrequest.totalTagValue = 0;
    this.tagrequest.totalOrder = 0;
    this.tagrequest.totalOrderDiscount = 0;
    this.tagrequest.totalAmountPayable = 0;
    this.regionShippingChargeSelected = new RegionShippingCharge();
  }

  ngOnInit() {
    this.defaultObj();
    setInterval(() => {
      this.cdr.detectChanges()
    }, 10);

    this.modalityTypeId = this.contractService.getContractModalityTypeId();
    if (!this._paymentService.cardNumber) {
      this._paymentService.getPrePaidConditions().subscribe(() => { });
    }

    this.getValueChange();
    this.getTagRequestParametros();
  }

  showDeliveryAddressesModal() {
    const modalRef = this._modalService.open(DeliveryAddressesModalComponent);
    modalRef.componentInstance.fromOrder = true;
    modalRef.componentInstance.event.subscribe((result) => {
      this.isLoadingAddress = true;
      if (result.id > 0) {
        this.getTagRequestParametrosByPersonAddressId(result.id);
      } else {
        this.getTagRequestParametrosByZipeCode(result.zipCode);
      }
    });
  }

  getTagRequestParametros(): void {
    this.isLoading = true;
    this._orderService.getContractsById()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (result) => {
          this.tagRequestQueryResult = result;
          this.contract = result.contract;
          this.tratarRequest();
        },
        (errors) => {
          this.isLoadingAddress = false;
          console.error(errors);
        }
      );
  }

  getTagRequestParametrosByZipeCode(zipeCode: string) {
    this._orderService.getContractsByIdByZipCode(zipeCode).then(
      (result) => {
        this.tagRequestQueryResult = result;
        this.contract = result.contract;
        this.tratarRequest();
        this.calcularTagRequest(this.orderForm.value.amount);
        this.isLoadingAddress = false;
      },
      (errors) => {
        console.error(errors);
        this.isLoadingAddress = false;
      }
    );
  }

  getTagRequestParametrosByPersonAddressId(personAddressId: number) {
    this._orderService.getContractsByIdByPersonAddressId(personAddressId).then(
      (result) => {
        this.tagRequestQueryResult = result;
        this.contract = result.contract;
        this.tratarRequest();
        this.calcularTagRequest(this.orderForm.value.amount);
        this.isLoadingAddress = false;
      },
      (errors) => {
        console.error(errors);
        this.isLoadingAddress = false;
      }
    );
  }

  tratarRequest() {
    this.tagrequest.addressTypeId = this.contract.personAddress.addressTypeId;
    this.orderForm.get('telephoneNumber').updateValueAndValidity();
    this.tagrequest.contractId = this.contract.id;
    this.tagrequest.marketingPlanId = this.contract.marketingPlan.id;
    this.tagrequest.personAddressId = this.contract.personAddress.id;
    this.tagrequest.customerId = this.contract.customerId;
    this.tagrequest.regionDeliveryTermId = this.contract.regionDeliveryTerm.id;
    this.tagrequest.totalTagValue =
      this.contract.tagValue * this.tagrequest.amount;
  }

  calcularTagRequest(amount) {
    this.tagrequest.amount = amount;
    if (this.tagrequest.amount > 500) {
      return;
    }
    if (this.tagrequest.amount === undefined || this.tagrequest.amount <= 0) {
      this.regionShippingChargeSelected = new RegionShippingCharge();
    } else {
      this.regionShippingChargeSelected =
        this.contract.regionShippingCharge.find(
          (i) =>
            this.tagrequest.amount >= i.startAmount &&
            this.tagrequest.amount <= i.endAmount
        );
    }
    this.tagrequest.regionShippingChargeId =
      this.regionShippingChargeSelected.id;
    this.tagrequest.totalTagValue =
      this.contract.tagValue * this.tagrequest.amount;
    this.tagrequest.totalOrder =
      this.contract.tagValue * this.tagrequest.amount +
      this.regionShippingChargeSelected.shippingValue;
    this.tagrequest.totalOrderDiscount =
      this.contract.tagValueDiscount * this.tagrequest.amount +
      this.regionShippingChargeSelected.shippingValueDiscount;
    this.tagrequest.totalAmountPayable =
      this.tagrequest.totalOrder - this.tagrequest.totalOrderDiscount;
    this.tagrequest.regionShippingChargeId =
      this.regionShippingChargeSelected.id;
  }

  postTagRequest(paymentMethodSelected) {
    this.onSaving = true;
    this.generateOrderData(paymentMethodSelected);
    this._orderService
      .postTagRequest(this.tagrequest)
      .pipe(
        finalize(() => {
          this.onSaving = false;
          this.clickedButton = true;
          this._paymentService.processingPaymentTransaction$.next(false)
        })
      )
      .subscribe(
        (success: any) => {
          this.AnimationTruckOrder("success", "Salvo com sucesso");
          if (paymentMethodSelected == PaymentMethodEnum.Pix) {
            this.showPaymentPixModal(success.data.pixTransactionId)
          }
          else {
            this.showSuccessModal(success);
            this.event.emit(success);
          }
        },
        (e) => {
          this.paymentMethodComponent.ngOnInit();
          console.error(e)
          this.AnimationTruckOrder("error", "Erro ao salvar");
        }
      );
  }

  purchaseConfirm(object) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
    };
    const modalRef = this._modalService.open(PurchaseConfirmationModalComponent, ngbModalOptions);
    modalRef.componentInstance.object = object;
    modalRef.componentInstance.event.subscribe((paymentMethodSelected) => {
      this.postTagRequest(paymentMethodSelected);
    })
  }

  validPaymentMethod() {
    if (this._paymentService.paymentMethodSelected == null ||
      typeof this._paymentService.paymentMethodSelected == 'undefined') {
      return true
    } else return this._paymentService.paymentMethodSelected == 0 || (this._paymentService.paymentMethodSelected == 1 && !this._storagedContractInfo.hasCreditCard$);
  }

  showPaymentPixModal(idDestination) {
    const modalRef = this._modalService.open(PixPaymentModalComponent);
    modalRef.componentInstance.paymentOriginId = PaymentOrigin.TagOrder;
    modalRef.componentInstance.idDestination = idDestination;
  }

  onSubmit() {
    if (this.modalityTypeId == ContractModality.PREPAID &&
      this.step == 1 &&
      this.tagrequest.totalAmountPayable > 0) {
      this.step = 2;
    }
    else {
      const object = this._paymentService.generatePaymentObject(PaymentOrigin.TagOrder, this.tagrequest)
      if (object.paymentMethodSelected == PaymentMethodEnum.NoPaymentMethod &&
        this.modalityTypeId == ContractModality.PREPAID &&
        this.tagrequest.totalAmountPayable > 0) {
        this.showRegisterCreditCardModal();
      }
      else {
        this.purchaseConfirm(object);
      }
    }
  }

  showRegisterCreditCardModal() {
    const modalRef = this._modalService.open(RegisterCreditCardModalComponent);
    modalRef.componentInstance.isPaymentTransaction = true;
    modalRef.componentInstance.event.subscribe(() => {
      this._paymentService.selectPaymentMethod(PaymentMethodEnum.CreditCard)
      const object = this._paymentService.generatePaymentObject(PaymentOrigin.TagOrder, this.tagrequest)
      this.purchaseConfirm(object);
    });
  }

  generateOrderData(paymentMethodSelected) {

    const phonestr = this.orderForm.value.telephoneNumber;
    const phoneparsed = phonestr.replace(/[^0-9]/g, "");
    this.tagrequest.tagRequestReceivingResponsible.telephone =
      this.orderForm.value.telephoneNumber;
    this.tagrequest.tagRequestReceivingResponsible.areaCode =
      phoneparsed.substring(0, 2);
    this.tagrequest.tagRequestReceivingResponsible.telephoneNumber =
      phoneparsed.substring(2);

    this.tagrequest.tagRequestReceivingResponsible.fullName = this.orderForm.value.fullName;
    this.tagrequest.isPix = paymentMethodSelected == PaymentMethodEnum.Pix;
  }

  getClassAnimateButton() {
    if (this.clickedButton) {
      if (this.stateButton == 1) {
        this.message = "";
        return "animate inAnimation order order-truck btn btn-secondary";
      } else if (this.stateButton == 2) {
        return "animate success order order-truck btn btn-secondary";
      } else {
        return "animate error order order-truck btn btn-secondary";
      }
    } else {
      return "order defaultButton order-truck btn btn-secondary";
    }
  }

  AnimationTruckOrder(type: string, msg: string) {
    setTimeout(() => {
      if (type == "success") {
        this.stateButton = 2;
        this.message = msg;
        this._notifyService.showSuccess(
          "Atenção!",
          "Em alguns minutos seu pedido será incluso na lista de pedidos"
        );
        setTimeout(() => {
          this._router.navigate(["/meus-pedidos"]);
        }, 2000);
      } else {
        this.message = msg;
        this.stateButton = 3;
      }
    }, 6900);
    setTimeout(() => {
      this.clickedButton = false;
      this.message =
        this.stateButton == 2 ? "Pedido enviado!" : "Reenviar Pedido";
    }, 11000);
  }

  showSuccessModal(sucess) {
    const object = this._paymentService.generatePaymentObject(PaymentOrigin.TagOrder, this.tagrequest)
    const modalRef = this._modalService.open(PaymentTransactionConfirmationComponent);
    modalRef.componentInstance.response = sucess;
    modalRef.componentInstance.object = object;
  }


}
