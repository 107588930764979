import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';

import { BehaviorSubject, Observable, switchMap } from 'rxjs';

import { FORMATED_PREVIEWS_MONTH, FORMATED_TODAY } from '@variables/dates';
import { TagStockStats } from '@models/statistics.model';
import { TagService } from '@services/tag.service';
import { Filters } from '@models/filter.model';

@Component({
  selector: 'app-tag-canceled',
  templateUrl: './tag-canceled.component.html',
  styleUrls: ['./tag-canceled.component.scss']
})
export class TagCanceledComponent {
  filter$ = new BehaviorSubject<Filters>({
    startDate: FORMATED_PREVIEWS_MONTH,
    endDate: FORMATED_TODAY
  });
  canceledCards$: Observable<TagStockStats> = this.filter$.pipe(
    switchMap(filter => this._service.getCanceledTagsByDate(new HttpParams({ fromObject: filter as any })))
  );

  constructor(private _service: TagService) { }
}
