<!-- Pos pago-->
<div class="commercialConditionsModal" id="commercialConditionsPosModal">
  <div *modalityType="2" class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="box-title">
          <span data-e2e="modal-title">Termos Contratuais</span>
        </div>

        <div class="col-12 box-white-small">
          <p class="contract-label" data-e2e="contract-label">Contrato</p>
          <p class="contract-name-label">
            <span id="contractId" data-e2e="contract-id">
              {{ commercialCondition?.id }}
            </span>

            <span data-e2e="contract-name" *ngIf="
              commercialCondition?.nameContract && commercialCondition?.nameContract.length > 0
              " id="contractName">
              - {{ commercialCondition?.nameContract }}
            </span>
          </p>
        </div>

        <button class="close" data-e2e="close-modal-btn" type="button" (click)="close()">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row row-header">
          <div class="col-lg-4 tag-left">
            <img src="../../../assets/img/ico-outline.svg" class="p">
          </div>

          <div class="col-lg-4 desc">
            <p class="title" id="contractModalityTypeId">
              Faturamento Pós-pago
              <i class="ico ico-alert" placement="bottom"
                ngbTooltip="Modalidade do contrato em que o pagamento é realizado pós utilização do serviço">
              </i>
            </p>

            <p class="title-plan" data-e2e="marketing-plan-title">Plano de comercialização
              <i class="ico ico-alert" placement="right"
                ngbTooltip="Condições contratadas para aquisição de novas tags. A cobrança do valor unitário e frete para envio das tags solicitadas tem como parâmetro as condições contratadas."></i>
            </p>

            <p class="subtitle" data-e2e="marketing-plan-descripton">
              {{getDescricaoMarketingPlan()}}
            </p>

            <p class="subtitle" id="validityDate">
              <span id="validityStartDate" data-e2e="validity-start-date">
                {{ commercialCondition.validityStartDate | date: 'dd/MM/yyyy' }}
              </span> até

              <span id="validityEndDate" data-e2e="validity-end-date">
                {{ commercialCondition?.validityEndDate | date: 'dd/MM/yyyy' }}
              </span>

              <i class="ico ico-alert" placement="bottom" ngbTooltip="Período de vigência do contrato">
              </i>
            </p>
          </div>

          <div class="col-lg-4 tag-right">
            <div class="row" [ngClass]="commercialCondition.automaticRenovation ? 'show' : ' hide'">
              <div class="col-lg-3">
                <i class="ico "
                  [ngClass]="commercialCondition.automaticRenovation ? ' ico-check-circle' : ' ico-check-circle'"
                  id="automaticRenovation">
                  {{ commercialCondition.automaticRenovation ? "Sim" : "Não"}}
                </i>
              </div>

              <div class="col-lg-9">
                <p>Renovação automática <i class="ico ico-alert" placement="bottom"
                    ngbTooltip="Renovação automática do contrato após o vencimento do prazo de vigência.">
                  </i></p>
                <span>

                  <div *ngIf="commercialCondition.automaticRenovation"
                    [ngSwitch]="commercialCondition.contractIndexReadjustmentTypeId">
                    <p data-e2e="contractIndexReadjustmentType" *ngSwitchCase="1">INPC</p>
                    <p data-e2e="contractIndexReadjustmentType" *ngSwitchCase="2">IGPDI</p>
                    <p data-e2e="contractIndexReadjustmentType" *ngSwitchCase="3">IGPM</p>
                    <p data-e2e="contractIndexReadjustmentType" *ngSwitchCase="4">IPC</p>
                    <p data-e2e="contractIndexReadjustmentType" *ngSwitchCase="5">IPCA</p>
                  </div>
                </span>

              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="box-gray">
              <i class="ico ico-alert" placement="right"
                ngbTooltip="A cobrança de mensalidade de tag é realizada a partir do momento em que uma tag é vinculada a um veículo, mesmo que esteja bloqueada temporariamente pelo cliente, de acordo com o valor unitário contratado."></i>
              <h3 data-e2e="tag-monthly-title">MENSALIDADE DE TAG</h3>
              <div>
                <div class="col p-0 align-left div-geral-tag-dual-tag">
                  <div>
                    <p>Tag</p>
                    <p *ngIf="commercialCondition.tagMonthlyFee">
                      <b data-e2e="tag-monthly-fee-Value" class="price">{{
                        commercialCondition.tagMonthlyFeeUnitValue
                        | currency: 'BRL':'symbol':'0.2-2':'pt-BR'
                        }}</b>
                      Unitário
                    </p>
                    <p *ngIf="!commercialCondition.tagMonthlyFee">
                      <b class="price" data-e2e="tag-monthly-fee-Value">Isento</b>
                      Unitário
                    </p>
                  </div>
                  <div class="col p-0 align-right" *ngIf="
                    commercialCondition.tagMonthlyFee &&
                    commercialCondition.tagMonthlyFeeExemptionStartDate &&
                    commercialCondition.tagMonthlyFeeExemptionEndDate
                  ">
                    <p>
                      Período de isenção<br />
                      <b><span data-e2e="tag-monthly-start-date">{{
                          commercialCondition.tagMonthlyFeeExemptionStartDate
                          | date: 'dd/MM/yyyy'
                          }}</span>
                        -
                        <span data-e2e="tag-monthly-end-date">{{
                          commercialCondition.tagMonthlyFeeExemptionEndDate
                          | date: 'dd/MM/yyyy'
                          }}</span> </b>
                    </p>
                  </div>
                </div>

                <hr class="hr" *ngIf="retornoDualTag?.length > 0">
                <div class="col p-0 align-left div-geral-tag-dual-tag" *ngIf="retornoDualTag?.length > 0">
                  <div>
                    <p>
                      Dual Tag
                    </p>
                    <p *ngIf="dualTagMensalidadeTags?.endDate !== null">
                      <strong>Desativado</strong>
                    </p>
                    <p *ngIf="dualTagMensalidadeTags?.endDate == null">
                      <b class="price" *ngIf="dualTagMensalidadeTags?.value > 0" id="marketingPlan"
                        data-e2e="marketing-plan-value">
                        {{ dualTagMensalidadeTags.value | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}
                      </b>
                      <b class="price" *ngIf="dualTagMensalidadeTags?.value == 0" data-e2e="marketing-plan-value">
                        Isento
                      </b>
                      <b class="b-valor-unitario" *ngIf="dualTagMensalidadeTags?.value > 0">
                        Unitário </b>
                    </p>
                  </div>
                  <p *ngIf="isPeriodoIsencao()">
                    Período de isenção<br />
                    <b>
                      <span data-e2e="marketing-plan-start-date">
                        {{ dualTagMensalidadeTags?.exemptionStartDate | date: 'dd/MM/yyyy':'UTC' }}
                      </span>
                      -
                      <span data-e2e="marketing-plan-end-date">
                        {{
                        dualTagMensalidadeTags?.exemptionEndDate
                        | date: 'dd/MM/yyyy':'UTC'
                        }}</span> </b><br />

                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- InativeTagCharge -->
          <div class="col-lg-6">
            <div class="box-gray box-div-mensalidade-tags">
              <i class="ico ico-alert" placement="left"
                ngbTooltip="Cobrança de mensalidade de tags em estoque, para tags com mais de 90 dias sem ativação, a partir da data de confirmação do pedido.">
              </i>

              <h3 id="stockTagMonthlyFee" data-e2e="stock-tag-title">
                MENSALIDADE DE TAGS EM ESTOQUE
              </h3>
              <div class="row">
                <div class="col-lg-12 p-0">
                  <div class="stock-tag-container">
                    <div *ngIf="commercialCondition?.contractFee" class="stock-tag-price-section div-tag-dual-tag">
                      <div class="div-text-dual-tag">
                        <p>
                          Tag
                        </p>
                        <p *ngIf="retornoDualTag?.length > 0 && dualTagAquisicaoNovasTags?.endDate === null">
                          e Dual Tag
                        </p>
                      </div>
                      <p>
                        <b class="price">{{ commercialCondition?.contractFee?.InativeTag?.value | currency : 'BRL' :
                          'symbol' : '0.2-2' : 'pt-BR'}}</b>
                        Unitário
                      </p>
                    </div>

                    <div class="bonus-tag-amount-section" *ngIf="commercialCondition?.bonificationTagsExemptionValue">
                      <span class="bonus-tag-label">Tags Bonificadas</span>
                      <i class="ico ico-alert"
                        ngbTooltip="Quantidade de tags com isenção de mensalidade de tag em estoque">
                      </i>
                      <span class="bonus-tag-amount">{{
                        commercialCondition?.bonificationTagsExemptionValue
                        }}</span>
                    </div>
                  </div>

                  <div class="row" *ngIf="!commercialCondition.contractFee?.InativeTag?.mustChargeFee">
                    <div class="col-lg-4">
                      <p>
                        <b class="price" data-e2e="inative-tag-value">Isento</b> <br />
                        Unitário
                      </p>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div
            [ngClass]="commercialCondition.shippingDiscount > 0 || commercialCondition.contractFee?.VolumeFee?.mustChargeFee ? 'col-lg-6' : 'col-lg-12'"
            *ngIf="commercialCondition.marketingPlan">

            <div class="box-gray align-rl">
              <h3 data-e2e="marketing-plan-title">
                AQUISIÇÃO DE NOVAS TAGS</h3>
              <i class="ico ico-alert" placement="left"
                ngbTooltip="Cobrança do valor unitário para envio de novas Tags."></i>
              <div class="row">
                <div class="col p-0 align-left div-geral-tag-dual-tag">
                  <div>
                    <p>
                      Tag
                    </p>
                    <p>
                      <b class="price" *ngIf="commercialCondition.marketingPlan.value > 0" id="marketingPlan"
                        data-e2e="marketing-plan-value">
                        {{ commercialCondition.marketingPlan.value | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}
                      </b>
                      <b class="price" *ngIf="commercialCondition.marketingPlan.value == 0"
                        data-e2e="marketing-plan-value">
                        Isento
                      </b>
                      <b *ngIf="commercialCondition.marketingPlan.value > 0">
                        valor unitário
                      </b>
                    </p>
                  </div>
                  <p *ngIf="
                    commercialCondition?.marketingPlan.exemptionStartDate ||
                    commercialCondition?.marketingPlan.exemptionEndDate">
                    Período de isenção<br />
                    <b>
                      <span data-e2e="marketing-plan-start-date">
                        {{ commercialCondition?.marketingPlan.exemptionStartDate | date: 'dd/MM/yyyy' }}
                      </span>
                      -
                      <span data-e2e="marketing-plan-end-date">
                        {{
                        commercialCondition?.marketingPlan.exemptionEndDate
                        | date: 'dd/MM/yyyy'
                        }}</span> </b>
                  </p>
                </div>
                <hr class="hr" *ngIf="retornoDualTag?.length > 0">
                <div class="col p-0 align-left div-geral-tag-dual-tag" *ngIf="retornoDualTag?.length > 0">
                  <div>
                    <p>
                      Dual Tag
                    </p>
                    <p *ngIf="dualTagAquisicaoNovasTags?.endDate !== null">
                      <strong>Desativado</strong>
                    </p>
                    <p *ngIf="dualTagAquisicaoNovasTags?.endDate == null">
                      <b class="price" *ngIf="dualTagAquisicaoNovasTags?.value> 0" id="marketingPlan"
                        data-e2e="marketing-plan-value">
                        {{ dualTagAquisicaoNovasTags.value | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}
                      </b>
                      <b class="price" *ngIf="dualTagAquisicaoNovasTags?.value == 0" data-e2e="marketing-plan-value">
                        Isento
                      </b>
                      <b class="b-valor-unitario" *ngIf="dualTagAquisicaoNovasTags?.value > 0">
                        Unitário </b>
                    </p>
                  </div>
                  <p *ngIf="isPeriodoIsencao()">
                    Período de isenção<br />
                    <b>
                      <span data-e2e="marketing-plan-start-date">
                        {{ dualTagAquisicaoNovasTags?.exemptionStartDate | date: 'dd/MM/yyyy':'UTC' }}
                      </span>
                      -
                      <span data-e2e="marketing-plan-end-date">
                        {{
                        dualTagAquisicaoNovasTags?.exemptionEndDate
                        | date: 'dd/MM/yyyy':'UTC'
                        }}</span> </b><br />

                  </p>
                </div>
              </div>

            </div>
          </div>

          <div class="col-lg-5 d-none" *ngIf="commercialCondition.shippingDiscount > 0">
            <div class="box-gray min-height-160">
              <h3 data-e2e="shipping-title">FRETE</h3>
              <div class="row">
                <div class="col-lg-12 p-0 align-left">
                  <p>
                    <span data-e2e="shipping-value"
                      *ngIf="commercialCondition.shippingDiscount === 100">Isento<br></span>
                    <span data-e2e="shipping-value"
                      *ngIf="commercialCondition.shippingDiscount < 100">{{commercialCondition.shippingDiscount}}%
                      de desconto</span><br>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="commercialCondition?.contractFee?.VolumeFee?.mustChargeFee">
            <div class="box-gray min-height-160 box-div-mensalidade-tags">
              <i class="ico ico-alert" placement="left"
                ngbTooltip="Taxa percentual cobrada sobre volume de transações de pedágio e estacionamento."></i>
              <h3 data-e2e="volume-fee-title">TAXA SOBRE VOLUME</h3>
              <div class="row">
                <div class="col-lg-4 p-0 align-left">
                  <p>
                    <span
                      data-e2e="volume-fee-value">{{commercialCondition?.contractFee?.VolumeFee?.percentage}}%</span><br>
                  </p>
                </div>
                <div class="col-lg-8 p-0 align-right" *ngIf="
                commercialCondition?.contractFee?.VolumeFee?.exemptionStartDate ||
                commercialCondition?.contractFee?.VolumeFee?.exemptionEndDate
                ">
                  <p>
                    <b><span data-e2e="volume-fee-start-date">{{
                        commercialCondition?.contractFee?.VolumeFee?.exemptionStartDate
                        | date: 'dd/MM/yyyy'
                        }}</span>
                      -
                      <span data-e2e="volume-fee-end-date">{{
                        commercialCondition?.contractFee?.VolumeFee?.exemptionEndDate
                        | date: 'dd/MM/yyyy'
                        }}</span> </b><br />
                    Período de isenção
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="fee-integration" *ngIf="contractIntegrationFee !== null">
            <div class="row">
              <div class="col-lg-12">
                <div class="accordion" id="accordionFeeIntegration">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFee" aria-expanded="true" aria-controls="collapseFee">
                        <div class="row justify-content-between">
                          <div class="col-6 accordionFee-title">
                            Serviço de integração de dados
                          </div>
                          <div class="col-6 accordionFee-period"
                            *ngIf="contractIntegrationFee?.exemptionStartDate !== null">
                            Período de isenção: <strong>{{contractIntegrationFee.exemptionStartDate |
                              date:'dd/MM/yyyy'}} - {{contractIntegrationFee.exemptionEndDate |
                              date:'dd/MM/yyyy'}}</strong>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div id="collapseFee" class="accordion-collapse collapse" data-bs-parent="#accordionFeeIntegration">
                      <div class="accordion-body">

                        <integration-fee-table
                          [integrationFeeList]="this.contractIntegrationFee?.integrationFees"></integration-fee-table>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div class="row" *ngIf="contractIntegrationFee !== null && haveIntegrationRole">
            <div class="col-lg-12">
              <div class="box-gray">
                <div class="row">
                  <div class="col-6 p-0 align-left">
                    <span class="title-credentials">Credenciais de Integração</span>
                  </div>
                  <div class="col-6 p-0 align-right">
                    <a class="btn-credentials" (click)="openCredentials()">Ver credenciais</a>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <app-pospago [commercialCondition]="commercialCondition"></app-pospago>

        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="close()" id="btn-dismiss">Fechar</button>
      </div>
    </div>
  </div>
</div>


<!-- Pre pago-->
<div *modalityType="1" class="commercialConditionsModal" id="commercialConditionsPreModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="box-title">
          <span data-e2e="modal-title">Termos Contratuais</span>
        </div>

        <div class="col-12 box-white-small">
          <p class="contract-label" data-e2e="contract-label">Contrato</p>
          <p class="contract-name-label">
            <span id="contractId" data-e2e="contract-id">{{ commercialCondition?.contractId
              }}</span><span data-e2e="contract-name" *ngIf="
              commercialCondition?.contractName && commercialCondition?.contractName.length > 0
              " id="contractName">
              - {{ commercialCondition?.contractName }}</span>
          </p>
        </div>

        <button class="close" type="button" (click)="close()">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row row-header">
          <div class="col-lg-4 tag-left">
            <img src="../../../assets/img/ico-outline.svg" class="p">
          </div>
          <div class="col-lg-4 desc">
            <p class="title" id="contractModalityTypeId">Faturamento Pré-pago</p>
            <p class="title-plan" data-e2e="marketing-plan-title">Plano de comercialização
              <i class="ico ico-alert" placement="right"
                ngbTooltip="Condições contratadas para aquisição de novas tags. A cobrança do valor unitário e frete para envio das tags solicitadas tem como parâmetro as condições contratadas."></i>
            </p>
            <p class="subtitle" data-e2e="marketing-plan-descripton">{{ commercialCondition.contractedPlan }}</p>
            <p class="subtitle" id="validityDate">
              <span id="validityStartDate" data-e2e="validity-start-date">{{ commercialCondition.validityStartDate |
                date: 'dd/MM/yyyy' }}</span> até
              <span id="validityEndDate" data-e2e="validity-end-date">{{ commercialCondition?.validityEndDate | date:
                'dd/MM/yyyy' }}</span>
              <i class="ico ico-alert" placement="bottom" ngbTooltip="Período de vigência do contrato"></i>
            </p>
          </div>

        </div>
        <div class="row">

          <div class="col-lg-6">
            <div class="box-gray align-left">

              <i class="ico ico-alert" placement="right"
                ngbTooltip="A cobrança de mensalidade de tag é realizada a partir do momento em que uma tag é vinculada a um veículo, mesmo que esteja bloqueada temporariamente pelo cliente, de acordo com o valor unitário contratado."></i>
              <h3 data-e2e="tag-monthly-title">MENSALIDADE DE TAG</h3>
              <div>
                <div class="col p-0 align-left div-geral-tag-dual-tag">
                  <div>
                    <p>
                      Tag
                    </p>
                    <p
                      *ngIf="commercialCondition.tagMonthlyFeeUnitValue && commercialCondition.tagMonthlyFeeUnitValue !== 0">
                      <b data-e2e="tag-monthly-fee-Value" class="price" id="tagMonthlyFeeUnitValue">{{
                        commercialCondition.tagMonthlyFeeUnitValue | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</b>
                      Unitário
                    </p>
                    <p class="free"
                      *ngIf="!commercialCondition.tagMonthlyFeeUnitValue || commercialCondition.tagMonthlyFeeUnitValue == 0">
                      <b class="price" data-e2e="tag-monthly-fee-Value">Isento</b>
                      <br />
                    </p>
                  </div>
                  <div class="col p-0 align-right"
                    *ngIf="commercialCondition.tagMonthlyFeeExemptionStartDate && commercialCondition.tagMonthlyFeeExemptionEndDate">
                    Período de isenção
                    <p>
                      <b><span data-e2e="tag-monthly-start-date">{{ commercialCondition.tagMonthlyFeeExemptionStartDate
                          | date: 'dd/MM/yyyy' }}</span>
                        -
                        <span data-e2e="tag-monthly-end-date">{{ commercialCondition.tagMonthlyFeeExemptionEndDate |
                          date: 'dd/MM/yyyy' }}</span> </b>
                    </p>
                  </div>
                </div>
                <hr class="hr" *ngIf="retornoDualTag?.length > 0">
                <div class="col p-0 align-left div-geral-tag-dual-tag" *ngIf="retornoDualTag?.length > 0">
                  <div>
                    <p>
                      Dual Tag
                    </p>
                    <p *ngIf="dualTagMensalidadeTags?.endDate !== null">
                      <strong>Desativado</strong>
                    </p>
                    <p *ngIf="dualTagMensalidadeTags?.endDate == null">
                      <b class="price" *ngIf="dualTagMensalidadeTags?.value > 0" id="marketingPlan"
                        data-e2e="marketing-plan-value">
                        {{ dualTagMensalidadeTags.value | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}
                      </b>
                      <b class="price" *ngIf="dualTagMensalidadeTags?.value == 0" data-e2e="marketing-plan-value">
                        Isento
                      </b>
                      <b class="b-valor-unitario" *ngIf="dualTagMensalidadeTags?.value > 0">
                        Unitário </b>
                    </p>
                  </div>
                  <p *ngIf="isPeriodoIsencao()">
                    Período de isenção<br />
                    <b>
                      <span data-e2e="marketing-plan-start-date">
                        {{ dualTagMensalidadeTags?.exemptionStartDate | date: 'dd/MM/yyyy' :'UTC' }}
                      </span>
                      -
                      <span data-e2e="marketing-plan-end-date">
                        {{
                        dualTagMensalidadeTags?.exemptionEndDate
                        | date: 'dd/MM/yyyy' :'UTC'
                        }}</span> </b><br />

                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- InativeTagCharge -->
          <div class="col-lg-6">
            <div class="box-gray align-left">
              <i class="ico ico-alert" placement="left"
                ngbTooltip="Cobrança de mensalidade de tags em estoque, para tags com mais de 90 dias sem ativação, a partir da data de confirmação do pedido."></i>
              <h3 id="inativeTagBlock" data-e2e="inative-tag-title">Mensalidade de tags em estoque</h3>

              <div class="stock-tag-container div-tag-dual-tag">
                <div class="div-text-dual-tag">
                  <p>
                    Tag
                  </p>
                  <p *ngIf="retornoDualTag?.length > 0 && dualTagAquisicaoNovasTags?.endDate === null">
                    e Dual Tag
                  </p>
                </div>
                <p class="stock-tag-price-section">
                  <b class="price">{{ commercialCondition?.inativeTagUnitValue | currency : 'BRL' : 'symbol' : '0.2-2' :
                    'pt-BR'}}</b>
                  Unitário
                </p>

                <div class="bonus-tag-amount-section" *ngIf="commercialCondition?.bonificationTagsExemptionValue">
                  <span class="bonus-tag-label">Tags Bonificadas</span>
                  <i class="ico ico-alert"
                    ngbTooltip="Quantidade de tags com isenção de mensalidade de tag em estoque"></i>
                  <span class="bonus-tag-amount">{{ commercialCondition?.bonificationTagsExemptionValue }}</span>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="row">

          <div class="col-lg-12">
            <div class="box-gray align-rl min-height-135">
              <i class="ico ico-alert" placement="left"
                ngbTooltip="Cobrança do valor unitário para envio de novas Tags."></i>
              <h3 data-e2e="marketing-plan-title">AQUISIÇÃO DE NOVAS TAGS</h3>
              <div>
                <div class="col p-0 align-left div-geral-tag-dual-tag">
                  <div class="box">
                    <p>
                      Tag
                    </p>
                    <p>
                      <b class="price" data-e2e="marketing-plan-value" *ngIf="
                          commercialCondition.acquisitionNewTags &&
                          commercialCondition.acquisitionNewTags !== 0
                        " id="marketingPlan">{{
                        commercialCondition.acquisitionNewTags
                        | currency: 'BRL':'symbol':'0.2-2':'pt-BR'
                        }}</b>
                      <b class="price" data-e2e="marketing-plan-value" *ngIf="
                          !commercialCondition.acquisitionNewTags ||
                          commercialCondition.acquisitionNewTags == 0
                        ">Isento
                      </b>
                      valor unitário
                    </p>
                  </div>
                  <div class="col p-0 align-right" *ngIf="
                    commercialCondition?.acquisionStartExemptionNewTags &&
                    commercialCondition?.acquisionEndExemptionNewTags
                  ">
                    Período de isenção<br />
                    <p>
                      <b><span id="acquisionStartExemptionNewTags" data-e2e="marketing-plan-start-date">{{
                          commercialCondition?.acquisionStartExemptionNewTags
                          | date: 'dd/MM/yyyy'
                          }}</span>
                        -
                        <span id="acquisionEndExemptionNewTags" data-e2e="marketing-plan-end-date">{{
                          commercialCondition?.acquisionEndExemptionNewTags
                          | date: 'dd/MM/yyyy'
                          }}</span></b>
                    </p>
                  </div>
                </div>
                <hr class="hr" *ngIf="retornoDualTag?.length > 0">
                <div class="col p-0 align-left div-geral-tag-dual-tag" *ngIf="retornoDualTag?.length > 0">
                  <div>
                    <p>
                      Dual Tag
                    </p>
                    <p *ngIf="dualTagAquisicaoNovasTags?.endDate !== null">
                      <strong>Desativado</strong>
                    </p>
                    <p *ngIf="dualTagAquisicaoNovasTags?.endDate == null">
                      <b class="price" *ngIf="dualTagAquisicaoNovasTags?.value > 0" id="marketingPlan"
                        data-e2e="marketing-plan-value">
                        {{ dualTagAquisicaoNovasTags?.value | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}
                      </b>
                      <b class="price" *ngIf="dualTagAquisicaoNovasTags?.value == 0" data-e2e="marketing-plan-value">
                        Isento
                      </b>
                      <b class="b-valor-unitario" *ngIf="dualTagAquisicaoNovasTags?.value > 0">
                        Unitário </b>
                    </p>
                  </div>
                  <p *ngIf="isPeriodoIsencao()">
                    Período de isenção<br />
                    <b>
                      <span data-e2e="marketing-plan-start-date">
                        {{ dualTagAquisicaoNovasTags?.exemptionEndDate | date: 'dd/MM/yyyy':'UTC' }}
                      </span>
                      -
                      <span data-e2e="marketing-plan-end-date">
                        {{
                        dualTagAquisicaoNovasTags?.exemptionEndDate
                        | date: 'dd/MM/yyyy':'UTC'
                        }}</span> </b><br />

                  </p>
                </div>
              </div>

            </div>
          </div>
          <!-- </div> -->

          <app-prepago [commercialCondition]="commercialCondition"></app-prepago>


        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="close()" id="btn-dismiss">Fechar</button>
      </div>
    </div>
  </div>
</div>
