import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import {
  FormGroup,
  FormBuilder,
  Validators,


} from '@angular/forms';
import {BillHeaderDto} from "@modules/customer/bill/dto/bill-header.dto";
import {BillRecompositionManualDto} from "@modules/customer/bill/dto/bill-recomposition-manual-dto";
import {BillService} from "@modules/customer/bill/service/bill.service";

@Component({
  selector: 'app-bill-recomposition-modal.component',
  templateUrl: './bill-recomposition-modal.component.html',
  styleUrls: ['./bill-recomposition-modal.component.scss'],
})
export class BillRecompositionModalComponent implements OnInit {
  billHeader: BillHeaderDto;
  billId: number;
  isLoading: boolean;
  isSuccess: boolean;
  recompositionForm: FormGroup;
  billRecompositionDto: BillRecompositionManualDto;
  public ProtocolValidator;
  public ReasonValidator;
  constructor(
    private modalService: NgbModal,
    private billService: BillService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.ProtocolValidator = [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ];
    this.ReasonValidator = [Validators.required];
    this.isLoading = true;
    this.isSuccess = false;
    this.loadBillHeader(this.billId);
    this.createForm();
  }

  createForm() {
    this.recompositionForm = this.formBuilder.group({
      protocolInput: ['', this.ProtocolValidator],
      reasonInput: ['', this.ReasonValidator],
    });
  }

  loadBillHeader(id: number) {
    this.billService
      .getBillHeader(id)
      .pipe(finalize(() => {}))
      .subscribe(
        (success) => {
          this.billHeader = success;
          this.isLoading = false;
        },
        (error: any) => {
          console.error(error);
          return;
        },
      );
  }

  finalizeRecomposition(id: number) {
    if (this.recompositionForm.valid) {
      this.billRecompositionDto = {
        billId: id,
        protocol: this.recompositionForm.controls['protocolInput'].value,
        reason: this.recompositionForm.controls['reasonInput'].value,
      };

      this.isLoading = true;
      this.billService
        .finalizeRecompositionBill(this.billRecompositionDto)
        .subscribe(
          (success) => {
            this.isLoading = false;
            this.isSuccess = true;
          },
          (e) => {
            console.error(e);
          },
        );
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
