import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MycontractComponent } from './mycontract.component';
import { AdduserModalComponent } from './components/add-user-modal/adduser-modal.component';
import { ContractSummaryComponent } from './components/contract-summary/contract-summary.component';
import { ContractUsersComponent } from './components/contract-users/contract-users.component';
import { UserEditModalComponent } from './components/user-edit-modal/user-edit-modal.component';
import { ContractDetailsModalComponent } from './components/contract-details-modal/contract-details-modal.component';
import { PrepagoComponent } from './components/contract-details-modal/components/prepago/prepago.component';
import { PospagoComponent } from './components/contract-details-modal/components/pospago/pospago.component';
import { MycontractRoutingModule } from './routes/mycontract-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CredentialsModalComponent } from './components/credentials-modal/credentials-modal.component';
import {
  ManagerContractComponent
} from "@modules/customer/mycontract/components/manager-contract/manager-contract.component";
import {MatTabsModule} from "@angular/material/tabs";
import {
  ContractRepresentativeAttorneyComponent
} from "@modules/customer/mycontract/components/contract-representative-attorney/contract-representative-attorney.component";
import {
  RepresentativesAttorneysModalComponent
} from "@modules/customer/mycontract/components/representatives-attorneys-modal/representatives-attorneys-modal.component";
import {
  UserEditModalConfirmComponent
} from "@modules/customer/mycontract/components/user-edit-modal/components/user-edit-modal-confirm/user-edit-modal-confirm.component";
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        MycontractRoutingModule,
        MatSelectModule,
        MatTooltipModule,
        MatTabsModule,
        BorderlessButtonComponent
    ],
  declarations: [
    MycontractComponent,
    AdduserModalComponent,
    ContractSummaryComponent,
    ContractUsersComponent,
    UserEditModalComponent,
    ContractDetailsModalComponent,
    PrepagoComponent,
    PospagoComponent,
    CredentialsModalComponent,
    ManagerContractComponent,
    ContractRepresentativeAttorneyComponent,
    RepresentativesAttorneysModalComponent,
    UserEditModalConfirmComponent
  ],
  providers: [DatePipe, provideNgxMask()],
})

export class MycontractModule {}
