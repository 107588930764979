import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RequestRoutingModule } from './routes/request-routing.module';
import { RequestComponent } from './request.component';
import { SeparateRequestComponent } from './components/separate-request/separate-request.component';
import { ConfirmationSeparateRequestComponent } from './components/confirmation-separate-request/confirmation-separate-request.component';
import { ReportTrackingComponent } from './components/report-tracking/report-tracking.component';
import { ViewTrackingComponent } from './components/view-tracking/view-tracking.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RequestDetailComponent } from './components/request-detail/request-detail.component';
import { ConsultReasonModalComponent } from './components/consult-reason-modal/consult-reason-modal.component';
import { RequestDetailsPopoverComponent } from './components/request-details-popover/request-details-popover.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { OutlinedSelectComponent } from '../../../shared/select/outlined-select/outlined-select.component';
import { SearchFormInlineComponent } from "../../../shared/components/search-form-inline/search-form-inline.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    RequestRoutingModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    FormsModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BorderlessButtonComponent,
    OutlinedSelectComponent,
    SearchFormInlineComponent
],
  declarations: [
    RequestComponent,
    SeparateRequestComponent,
    ConfirmationSeparateRequestComponent,
    ReportTrackingComponent,
    ViewTrackingComponent,
    RequestDetailComponent,
    ConsultReasonModalComponent,
    RequestDetailsPopoverComponent,
  ],
  providers: [provideNgxMask()],
})
export class RequestModule {}
