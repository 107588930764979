import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTagActivateUpload } from '@modules/customer/batch-management/dtos/batch-management-consts';
import { FILE_EXTENSION_TYPES } from 'src/app/shared/constants/file-extension-types';
import { STEP_TYPES } from 'src/app/shared/constants/step-types';
import { BatchManagementService } from '@modules/customer/batch-management/services/batch-management.service';
import { finalize } from 'rxjs';
import { TagActivateUploadResponseDto } from '@modules/customer/batch-management/dtos/tag-activate-upload-response.dto';
import { TagActivateUploadDto } from '@modules/customer/batch-management/dtos/tag-activate-upload.dto';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';

@Component({
  selector: 'app-batch-tag-upload-modal',
  templateUrl: './tag-activate-upload-modal.component.html',
  styleUrl: './tag-activate-upload-modal.component.scss'
})
export class TagActivateUploadModalComponent extends AbstractConfigurationBase implements OnInit {
  @Input() inFileId: number;
  @Output() outEventReloadRequest: EventEmitter<number> = new EventEmitter();

  stepTypes = STEP_TYPES;
  step: STEP_TYPES;
  title: string;
  isLoading: boolean;
  loaderMsg: string;
  batchTagUploadForm: FormGroup;
  allowedFileType: string;
  textInfoAllowedExtensions: string;
  fileValid: any;
  tagActivateUploadDto: TagActivateUploadDto;
  feedbackDate: string | Date;
  feedbackUsername: string;
  feedbackProtocol: string;

  constructor(
    private modalService: NgbModal,
    private batchManagementService: BatchManagementService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.settingsInitClass();
  }

  private settingsInitClass() {
    this.loaderMsg = 'Carregando...';
    this.step = this.stepTypes.CONFIRMATION;
    this.title = ModalTagActivateUpload.STEP_CONFIRMATION_TITLE;
    this.allowedFileType = FILE_EXTENSION_TYPES.XLS;
    this.textInfoAllowedExtensions = '(Somente arquivos com extensão .xls e .xlsx)';

    this.tagActivateUploadDto = new TagActivateUploadDto();

    this.batchTagUploadForm = new FormGroup({
      protocol: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]*$")])
    });
  }

  isUserProfileBackOffice() {
    return this.userProfileService.isBackOffice();
  }

  dismissModal() {
    this.modalService.dismissAll()
  }

  confirmUpload() {
    const formData = new FormData();
    formData.append('file', this.fileValid);

    this.tagActivateUploadDto.file = formData;

    // Only send protocol if the user profile is BackOffice
    this.tagActivateUploadDto.protocol = this.isUserProfileBackOffice() ?
      this.batchTagUploadForm.controls.protocol.value :
      null;

    if (this.inFileId) {
      this.tagActivateUploadDto.idFile = this.inFileId;
      this.requestUpdateUploadFileTagActivate();
      return;
    }

    this.requestUploadFileTagActivate();
  }

  private sendEventToReloadRequestTagActivate() {
    this.outEventReloadRequest.emit(1);
  }

  private requestUploadFileTagActivate() {
    this.isLoading = true;

    const contractId = this.getContractId();

    this.batchManagementService.postFileTagActivate(this.tagActivateUploadDto, contractId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.sendEventToReloadRequestTagActivate();
          this.setStepFeedbackSettings(success.data);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  private requestUpdateUploadFileTagActivate() {
    this.isLoading = true;

    this.batchManagementService.putFileTagActivate(this.tagActivateUploadDto)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.sendEventToReloadRequestTagActivate();
          this.setStepFeedbackSettings(success.data);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  private setStepFeedbackSettings(response: TagActivateUploadResponseDto) {
    this.step = this.stepTypes.FEEDBACK;
    this.title = ModalTagActivateUpload.STEP_FEEDBACK_TITLE;
    this.feedbackDate = response.registrationDate;
    this.feedbackUsername = response.userName;
    this.feedbackProtocol = response.protocolNumber;
  }

  handleFileUploaded(event: object) {
    this.fileValid = event;
  }

  isInvalidToConfirm(): boolean {
    return this.isUserProfileBackOffice() ?
      !this.fileValid || this.batchTagUploadForm.controls.protocol.invalid :
      !this.fileValid;
  }

  private getContractId(): number {
    return Number.parseInt(this.authService.getContractId());
  }

  downloadVehiclesAndTagsAvailables() {
    this.loaderMsg = 'Download...';
    this.isLoading = true;

    const contractId = this.getContractId();

    this.batchManagementService
      .downloadVehiclesAndTagsAvailables(contractId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderMsg = 'Carregando...';
        })
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        element.href = URL.createObjectURL(newBlob);
        element.download = `Veículos e tags disponíveis.xlsx`;
        element.click();
      });
  }

  downloadModelFile() {
    this.batchManagementService.downloadTagActivateModelFile();
  }


}
