import { Component, Input, OnInit } from '@angular/core';
import { PaymentObject, PaymentOrigin } from '@models/payment.model';

@Component({
  selector: 'app-payment-transaction-confirmation',
  templateUrl: './payment-transaction-confirmation.component.html',
  styleUrls: ['./payment-transaction-confirmation.component.scss']
})
export class PaymentTransactionConfirmationComponent implements OnInit {

  constructor() { }

  @Input() response: any;
  @Input() object: PaymentObject;
  paymentOrigin = PaymentOrigin;

  ngOnInit(): void {
  }

}
