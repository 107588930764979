<div class="tag-file-inconsist">
  <section class="modal-header">

    <div class="box-title"><span class="tag-file-inconsist__title">Arquivo com inconsistências</span></div>

    <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss">
      <span aria-hidden="true">×</span>
    </button>
  </section>

  <section class="modal-body">

    @if (isLoading) {

      <div>

        <app-loader [isLoading]="isLoading" message="Download..."></app-loader>

      </div>
    }
    @else {
      <div class="tag-file-inconsist__body">
        <h6 class="tag-file-inconsist__body-title">Arquivo com inconsistências encontradas!</h6>

        @if (inFileData.tagActivated > 0) {
          <p class="tag-file-inconsist__body-data" id="data-number-success" data-e2e="data-number-success">
            <b>{{ inFileData.tagActivated }}</b> tags foram ativadas com sucesso!
          </p>
        }

        <p class="tag-file-inconsist__body-data" id="data-number-errors" data-e2e="data-number-errors"><b>{{ inFileData.fileIncosistences }}</b> inconsistências foram encontradas no arquivo.</p>

        <p class="tag-file-inconsist__body-action-text">Por favor, baixe e revise o arquivo com as inconsistências e importe novamente:</p>

        <div class="tag-file-inconsist__body-action-buttons">

          <app-borderless-button
            [description]="'Baixar arquivo com inconsistências'"
            [id-html]="'btn-download-inconsistencies'"
            [data-e2e]="'btn-download-inconsistencies'"
            [buttonColor]="'secundary'"
            [classIconLeft]="'ico-document-download'"
            [isBtnWithBorder]="true"
            (emitterEvtClick)="downloadFileInconsistencies()"
          >
          </app-borderless-button>

          <app-borderless-button
            [description]="'Importar novo documento'"
            [id-html]="'btn-redirect-upload'"
            [data-e2e]="'btn-redirect-upload'"
            [buttonColor]="'primary'"
            [classIconLeft]="'ico-document-upload'"
            [isBtnWithBorder]="true"
            (emitterEvtClick)="redirectUploadModal()"
          >
          </app-borderless-button>

        </div>
      </div>
    }
  </section>

  <section class="modal-footer modal-footer-gray">

    <app-borderless-button
      [description]="'Fechar'"
      [id-html]="'modal-btn-close-below'"
      [data-e2e]="'modal-btn-close-below'"
      [buttonColor]="'secundary'"
      [isBtnWithBorder]="true"
      (emitterEvtClick)="dismissModal()"
    >
    </app-borderless-button>

  </section>
</div>
