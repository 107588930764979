<div class="tag-activate-upload-modal">
  <section class="modal-header">

    <div class="box-title"><span class="tag-activate-upload-modal__title">{{ title }}</span></div>

    <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss">
      <span aria-hidden="true">×</span>
    </button>
  </section>

  <section class="modal-body tag-activate-upload-modal__body">
    @if (isLoading) {
    <div class="tag-activate-upload-modal__loader">

      <app-loader [isLoading]="isLoading" message="{{ loaderMsg }}"></app-loader>

    </div>
    }
    @else if (step == stepTypes.CONFIRMATION) {

    <div class="box-information box-information--blue">
      <img class="box-information__icon" alt="Ícone exclamação com círculo">

      <section class="box-information__text-container">
        <h6 class="box-information__title">Ativação de tags</h6>
        <p class="box-information__text">Para ativar suas tags, baixe o arquivo para visualizar os veículos e tags
          disponíveis. Depois, envie o arquivo com os detalhes da placa, serial da TAG e tipo de serviço preenchidos.
        </p>
      </section>
    </div>

    <div class="tag-activate-upload-modal__dragdrop">

      <app-dragdrop-file [inAllowedFileType]="allowedFileType" [inTextInfoAllowedExtensions]="textInfoAllowedExtensions"
        (onFileValid)="handleFileUploaded($event)"></app-dragdrop-file>

    </div>

    <p class="tag-activate-upload-modal__info-download">
      Baixe aqui a planilha com seus veículos e tags disponíveis e a planilha modelo para importar
    </p>

    <div class="tag-activate-upload-modal__buttons-download">

      <app-borderless-button
        [description]="'Baixar veículos e tags disponível'"
        [id-html]="'btn-download-vehicles-tags'"
        [data-e2e]="'btn-download-vehicles-tags'"
        [classIconLeft]="'ico-document-download'"
        [buttonColor]="'secundary'"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="downloadVehiclesAndTagsAvailables()"
      >
      </app-borderless-button>

      <app-borderless-button
        [description]="'Baixar planilha modelo'"
        [id-html]="'btn-download-doc-model'"
        [data-e2e]="'btn-download-doc-model'"
        [classIconLeft]="'ico-document-download'"
        [buttonColor]="'primary'"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="downloadModelFile()"
      >
      </app-borderless-button>
    </div>

    @if (isUserProfileBackOffice()) {
    <form [formGroup]="batchTagUploadForm">

      <div class="input-box tag-activate-upload-modal__input-box">
        <label class="tag-activate-upload-modal__label" for="input-protocol">Informe o protocolo de solicitação
          <span>*</span></label>
        <input class="input-box__input tag-activate-upload-modal__input" [class.is-invalid]="
                batchTagUploadForm.controls.protocol.invalid &&
                batchTagUploadForm.controls.protocol.touched
              " formControlName="protocol" id="input-protocol" data-e2e="input-protocol" type="text"
          placeholder="Digite aqui o protocolo" maxlength="40" required />
      </div>

      @if (batchTagUploadForm.controls.protocol.invalid
      && batchTagUploadForm.controls.protocol.touched) {
      <span class="tag-activate-upload-modal__input-error-msg">Digite um protocolo válido.</span>
      }
    </form>
    }
    }
    @else {

    <div class="tag-activate-upload-modal__feedback">
      <section class="box-information box-information--yellow">
        <img class="box-information__icon" alt="Ícone exclamação com triângulo">

        <div class="box-information__text-container">
          <p class="box-information__text">As praças de pedágio poderão levar até 6 horas para processar a ativação das
            tags</p>
        </div>
      </section>

      <section class="tag-activate-upload-modal__feedback-body">
        <i class="tag-activate-upload-modal__feedback-icon ico-positive"></i>
        <h5 class="tag-activate-upload-modal__feedback-subtitle">Ativação em andamento!</h5>
        <p class="tag-activate-upload-modal__feedback-text">
          O arquivo está sendo processado.<br>
          Acompanhe o status pela área de notificações ou pelo painel de Gestão em Lote.
        </p>
        <span class="tag-activate-upload-modal__feedback-data" id="feedback-date-username"
          data-e2e="feedback-date-username">{{ feedbackDate | date: 'dd/MM/yyyy - HH:mm:ss' }} por {{ feedbackUsername
          }}</span>

        @if (isUserProfileBackOffice()) {
        <p class="tag-activate-upload-modal__feedback-protocol" id="feedback-protocol" data-e2e="feedback-protocol">
          <b>Protocolo </b>{{ feedbackProtocol }}
        </p>
        }
      </section>
    </div>
    }
  </section>

  <section class="modal-footer modal-footer-gray tag-activate-upload-modal__footer">
    @if (step == stepTypes.CONFIRMATION) {
      <app-borderless-button
        [description]="'Cancelar'"
        [id-html]="'modal-btn-cancel'"
        [data-e2e]="'modal-btn-cancel'"
        [buttonColor]="'secundary'"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="dismissModal()"
      >
      </app-borderless-button>

      <app-borderless-button
        [description]="'Enviar'"
        [id-html]="'modal-btn-confirm'"
        [data-e2e]="'modal-btn-confirm'"
        [buttonColor]="'primary'"
        [isBtnWithBorder]="true"
        [isDisable]="isInvalidToConfirm()"
        (emitterEvtClick)="confirmUpload()"
      >
      </app-borderless-button>
    }
    @else {
      <app-borderless-button
        [description]="'Fechar'"
        [id-html]="'modal-btn-close-below'"
        [data-e2e]="'modal-btn-close-below'"
        [buttonColor]="'secundary'"
        [isBtnWithBorder]="true"
        (emitterEvtClick)="dismissModal()"
      >
      </app-borderless-button>
    }
  </section>
</div>
