import { Subscription } from 'rxjs';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FiltersService } from './filters.service';
import {EFiltersIds, FilterName, Filters, FilterTextValues, IFilterNameId} from "@models/filters-backoffice.model";
import { BorderlessButtonComponent } from '../button/button-without-border/borderless-button.component';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  @Input() hasTransctionDateFilter: boolean;
  @Input() hasExpectedClearingDateFilter: boolean;
  @Input() hasCreditDateFilter: boolean;
  @Input() hasExpirationDateFilter: boolean;
  @Input() hasTransctionTypeFilter: boolean;
  @Input() hasStatusTypeFilter: boolean;
  @Input() hasRangeAmountFilter: boolean;
  @Input() hasRadioFilter: boolean;
  @Input() hasRefusalReasonFilter: boolean;
  @Input() hasFlowStepAndRefusalFilter: boolean;
  @Input() transactionTypes: [] | IFilterNameId[];
  @Input() statusTypes: [];
  @Input() conciliationSteps: IFilterNameId[];
  @Input() refusalTypes: IFilterNameId[];
  @Output() filtersParams = new EventEmitter<Filters>();
  @Output() resetParams = new EventEmitter<void>();

  eFilterId = EFiltersIds;
  showFilters: boolean = false;
  showRefusalReason: boolean = false;
  activeFilter: number;
  filters: Filters = new Filters();
  sub$: Subscription[] = [];

  textValues: FilterTextValues = {
    transactionDateText: 'Selecione',
    expectedClearingDateText: 'Selecione',
    creditDateText: 'Selecione',
    expirationDateText: 'Selecione',
    transactionTypeText: 'Selecione',
    statusTypeText: 'Selecione',
    rangeAmountText: 'Selecione',
    radioStepText: 'Selecione',
    refusalReasonText: 'Selecione',
  };

  constructor(
    private _filtersService: FiltersService,
    private _datePipe: DatePipe,
  ) {
    this.filtersSubscribes();
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  closeOtherFilters(idFilter: EFiltersIds) {
    this._filtersService.activeFilter = idFilter;
    this.activeFilter = idFilter;
  }

  handleDataFilters(filterType: FilterName, data: any) {
    this.filters[filterType] = data;
  }

  applyFilters() {
    // format and storage the filters at service
    if (this.filters.transactionDateFilter != null) {
      let transactionDate = this.filters.transactionDateFilter?.map((date) => {
        return this._datePipe.transform(date, 'dd/MM/yyyy');
      });

      this._filtersService.transactionDate$.next(
        `${transactionDate[0]} - ${transactionDate[1]}`,
      );
    }

    if (this.filters.expectedClearingDateFilter != null) {
      let expectingClearingDate = this.filters.expectedClearingDateFilter?.map(
        (date) => {
          return this._datePipe.transform(date, 'dd/MM/yyyy');
        },
      );

      this._filtersService.expectedClearingDate$.next(
        `${expectingClearingDate[0]} - ${expectingClearingDate[1]}`,
      );
    }

    if (this.filters.creditDateFilter != null) {
      let creditDate = this.filters.creditDateFilter?.map(
        (date) => {
          return this._datePipe.transform(date, 'dd/MM/yyyy');
        },
      );

      this._filtersService.creditDate$.next(
        `${creditDate[0]} - ${creditDate[1]}`,
      );
    }

    if (this.filters.expirationDateFilter != null) {
      let expirationDate = this.filters.expirationDateFilter?.map(
        (date) => {
          return this._datePipe.transform(date, 'dd/MM/yyyy');
        },
      );

      this._filtersService.expirationDate$.next(
        `${expirationDate[0]} - ${expirationDate[1]}`,
      );
    }

    // range amount filter
    if (this.filters.rangeAmountFilter != null) {
      let text = this._filtersService.formatRangeAmountToText(
        this.filters.rangeAmountFilter,
      );
      this._filtersService.rangeAmount$.next(text);
    }

    if (this.filters.radioStepFilter != null) {
      let text = this._filtersService.formatIdToName(
        this.filters.radioStepFilter,
        this.conciliationSteps,
      );
      this._filtersService.radioStep$.next(text);
    }

    this.filtersParams.emit(this.filters);
    this.toggleFilters();
  }

  resetFilters() {
    this._filtersService.resetFilters();
    this.filters = new Filters();
    this.showRefusalReason = false;
    this.resetParams.emit();
    this.toggleFilters();
  }

  ngOnDestroy() {
    this.sub$.forEach((subs) => subs.unsubscribe());
    this._filtersService.resetFilters();
  }

  filtersSubscribes() {
    this.sub$[0] = this._filtersService.transactionDate$?.subscribe((data) => {
      this.textValues.transactionDateText = data;
    });

    this.sub$[1] = this._filtersService.expectedClearingDate$?.subscribe(
      (data) => {
        this.textValues.expectedClearingDateText = data;
      },
    );

    this.sub$[4] = this._filtersService.rangeAmount$?.subscribe((data) => {
      this.textValues.rangeAmountText = data;
    });

    this.sub$[5] = this._filtersService.radioStep$.subscribe((data) => {
      this.textValues.radioStepText = data;
    });

    this.sub$[8] = this._filtersService.creditDate$?.subscribe(
      (data) => {
        this.textValues.creditDateText = data;
      },
    );

    this.sub$[9] = this._filtersService.expirationDate$?.subscribe(
      (data) => {
        this.textValues.expirationDateText = data;
      },
    );


  }

  handleStep(event: number) {
    if (event == this.conciliationSteps[1].id) {
      this.showRefusalReason = true;
    } else {
      this.showRefusalReason = false;
      this._filtersService.refusalReason$.next([]);
    }
  }
}
