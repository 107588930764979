import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OAuthService } from "angular-oauth2-oidc";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
import { SelectContractsService } from "./services/select-contracts.service";
import { StoragedContractInfosService } from "@services/storaged-contract-infos.service";
import { Logger } from "@services/logger.service";
import { LocalstorageService } from "@services/localstorage.service";
import { EditContractNameModal } from "./components/edit-contract-name-modal/edit-contract-name-modal.component";
import { FinancialBlockModalComponent } from "./components/financial-block-modal/financial-block-modal.component";
import { GroupUser, GroupsCurrentUser } from "@models/group-user.model";
import { ContractInfosDto } from "@models/contract-infos.dto";
import { GetContractsBySearchFieldParams } from "@models/get-contracts-by-searchfield-params.model";
import { SEARCH_TYPES } from "../../../shared/constants/search-types";
import { AbstractConfigurationBase } from "src/app/shared/abstracts/abstract-configuration-base";
import { CurrentUser } from "@models/old/currentuser.model";
import { Dto } from "@models/dto.model";

const log = new Logger("SelectContractSelectContractsComponent");

const GROUP_ID_RELATIONSHIP_CONSULTANT: number = 10200;

@Component({
    selector: "app-select-contracts",
    templateUrl: "./select-contracts.component.html",
    styleUrls: ["./select-contracts.component.scss"],
})
export class SelectContractsComponent extends AbstractConfigurationBase implements OnInit {
    groupsCurrentUser: GroupUser[] = [];
    contractList: Array<ContractInfosDto>;
    searchFieldParams: GetContractsBySearchFieldParams;
    hasPagination: boolean;
    totalContractsList: number;
    personInfo: CurrentUser;
    isAtendimentoRoute: boolean = false;

    protected isMessageBySearchFilter!: boolean;

    ErrorApi: boolean;
    isLoadingPage: boolean;
    isLoadingContracts: boolean;
    isSearchingContracts: boolean;
    isRelationshipConsultantUser: boolean;
    haveManyContracts: boolean;

    messageLoader = 'Carregando...';
    messageResults = 'Últimos contratos acessados';
    messageNoResults = 'Você ainda não acessou nenhum contrato. Faça uma busca para acessar';
    noResults: boolean;
    noResultsClass: string = '';
    noResultsIconPath: string = '';
    teste: boolean = false;
    selectedSearchType: number;

    searchTypes: any = SEARCH_TYPES; //RETIRAR
    searchTypeForm: FormGroup = new FormGroup({ //RETIRAR
        searchType: new FormControl('8', [Validators.required]),
        searchField: new FormControl('', [Validators.required]),
    });

    groupsresponse: GroupsCurrentUser = new GroupsCurrentUser;
    response: Dto<ContractInfosDto[]>;

    constructor(
        private _selectContractsService: SelectContractsService,
        private _router: Router,
        private _localstorageService: LocalstorageService,
        private _oauthService: OAuthService,
        private _modalService: NgbModal,
        private _storagedContractInfosService: StoragedContractInfosService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.isMessageBySearchFilter = false;
        this.setPageDefaultConfig();
        this.getGroupsByCurrentUser();
        this.getPersonInfo();

    }

    // No init, faz sentido para config de inicialização
    // No clear não faz sentido no clear
    setPageDefaultConfig() {
        this.contractList = new Array<ContractInfosDto>(); // zerar a lista - Pq este cara precisa ser zerado? E pq instancia um novo objeto na memoria?
        this.searchFieldParams = new GetContractsBySearchFieldParams(); // zera os parametros - Pq zera os parametros, se quando pesquisa inicializa um novo objeto?

        // Search field settings
        this.selectedSearchType = parseInt(this.searchTypeForm.controls.searchType.value);
    }

    getGroupsByCurrentUser() {
        this.isLoadingPage = true;

        this._selectContractsService
            .getGroupsByCurrentUser()
            .pipe(
                finalize(() => {
                    this.validateCurrentUser();
                })
            )
            .subscribe({
                next: (success) => {
                    if (success.succeeded) {
                        this.groupsCurrentUser = success.data.listGroups;
                    }
                },
                error: (error) => {
                    log.error(error);
                    return;
                }
            });
    }

    validateCurrentUser() {
        this.handleGroupsUser();

        this.getContracts();

    }

    handleGroupsUser() {
        const groupRelationshipConsultantUser = this.groupsCurrentUser.filter(
            (group) => group.id == GROUP_ID_RELATIONSHIP_CONSULTANT
        );
        if (groupRelationshipConsultantUser.length > 0) {
            this.isRelationshipConsultantUser = true;
        }
    }

    /*
     Regras de execução
     1- Se o usuário for um consultor de relacionamento, ele deve ver os últimos contratos acessados
     2- Se o usuário não for um consultor de relacionamento e tiver mais de um contrato, ele deve ver os últimos contratos acessados
     3- Quando for executado o clear
     */

    getLastAccessContracts() {
        this.isLoadingPage = true;
        this.haveManyContracts = true;
        this.isSearchingContracts = true;
        this.hasPagination = false;

        const groupId = this.isRelationshipConsultantUser ? GROUP_ID_RELATIONSHIP_CONSULTANT : 0;

        this._selectContractsService.getLastAccessContracts(groupId, 10)
            .pipe(
                finalize(() => {
                    this.isSearchingContracts = false;
                })
            )
            .subscribe({
                next: (success) => {
                    this.contractList = success;
                    this.totalContractsList = this.contractList.length;

                    this.handleMessageResult(this.hasPagination);
                },
                error: (error) => {
                    log.error(error);
                    return;
                }
            });
    }

    getContracts() {

        this.isLoadingPage = true;
        this._selectContractsService
            .getContracts()
            .pipe(
                finalize(() => {
                    this.isLoadingPage = false;
                })
            )
            .subscribe({
                next: (success) => {
                    const contracts = success;
                    this.haveManyContracts = contracts.length > 1;

                    this._localstorageService.setHaveManyContracts(
                        this.haveManyContracts
                    );

                    // If only have one contract redirect to the dashboard
                    if (contracts.length == 1 && !this.isRelationshipConsultantUser) {
                        const contract = contracts[0];
                        this.setContract(contract, this.haveManyContracts);
                    }
                    else if (this.haveManyContracts && this.isRelationshipConsultantUser) {
                        this.getLastAccessContracts();
                    } else {
                        this.getUserContractsBySearchField(this.searchTypes = 8, this.searchFieldParams.searchField = '');
                    }

                    if (contracts.length == 0) {
                        this.contractList = [];
                        this.totalContractsList = 0;
                    }
                },
                error: (error) => {
                    log.error(error);
                    this.ErrorApi = true;
                    return;
                }
            });
    }


    private ContractIsDualTag(contractId) {
        this._selectContractsService.getContractDualTag(contractId).subscribe((retorno: any) => {
            this.contractService.setDualTag(retorno);
        })
    }

    /**
     * Método que recebe o contrato selecionado do componente filho (CardContract)
     * @param contract
     */
    handleSelectedContract(contract: ContractInfosDto) {
        if (contract) {
            this.setContract(contract, true, false);
        }
        
    }

    setContract(contract: ContractInfosDto, hasManyContracts: boolean = false, isEventClick: boolean = false) {
        this.messageLoader = 'Selecionando contrato...'
        this.isLoadingContracts = true;
        let isNextCalled: boolean = false;

        if (isEventClick) {
            this.userContractAccessAndBond(contract, hasManyContracts);
        }

        this.setContractInfos(contract);

        this.ContractIsDualTag(contract.id);

        this.authService.setRolesOnStorage()
            .pipe(
                finalize(() => {
                    this.isLoadingContracts = false;
                    this.messageLoader = 'Carregando...'
                })
            )
            .subscribe({
                next: () => {
                    if (contract.contractBlockLevel == 3 && hasManyContracts) {
                        this.showFinancialBlockModal(contract);

                    } else {
                        this._router.navigate(["/pagina-inicial"]);
                    }

                },
                error: (error) => {
                    log.error(error);
                    this.ErrorApi = true;
                    return;
                }
            }
            );
    }

    setContractInfos(contract: ContractInfosDto) {
        //get the email to include in the localStorage userinfo
        contract.email = this.personInfo.emailAddress

        this.contractService.removeContract();
        this.contractService.setContract(contract);

        this._storagedContractInfosService.updateContractBlockLevelVariable(contract.contractBlockLevel);

        if (Number(contract.contractModalityTypeId) === 1) {
            this._storagedContractInfosService.updateHasCreditCardVariable(contract.hasCreditCard);
        }
    }
    showEditContractNameModal(contract) {
        this.contractService.setContract(contract);
        const modalRef = this._modalService.open(EditContractNameModal);
        modalRef.componentInstance.contract = contract;

        modalRef.componentInstance.event.subscribe((receivedEntry: any) => {
            if (receivedEntry == 1) {
                this.ngOnInit();
            }
            this.contractService.removeContract();
        });
    }

    showFinancialBlockModal(contract) {
        const modalRef = this._modalService.open(FinancialBlockModalComponent);
        modalRef.componentInstance.contract = contract;
    }

    getContractClass(contract) {
        if (contract.contractBlockLevel == 3 || contract.lastBillStatusType == 6) {
            return "contract-card-status";
        }
        else {
            return "";
        }
    }



    handleMessageResult(hasPagination: boolean) {
        if (this.totalContractsList == 0) {
            this.noResults = true;
            this.setMessageNoResult(hasPagination);
        }
        else if (this.totalContractsList > 0) {
            this.noResults = false;
            this.setMessageResult(hasPagination);
        }
    }

    private setMessageResult(hasPagination: boolean) {
        if (hasPagination) {
            this.messageResults = this.totalContractsList + ' contrato(s) encontrado(s)';
        }
        else {
            this.messageResults = 'contrato(s) encontrado(s)';
        }
    }

    private setMessageNoResult(hasPagination: boolean) {
        if (hasPagination) {
            this.messageNoResults = 'Não encontramos nenhum contrato com o dado informado. Por favor, verifique se digitou corretamente ou tente novamente com outra informação válida.';
            this.noResultsClass = 'no-results-text--red';
            this.noResultsIconPath = '/assets/svg/icon-search-no-results-r.svg';
        }
        else {
            this.messageNoResults = 'Você ainda não acessou nenhum contrato. Faça uma busca para acessar';
            this.noResultsClass = 'no-results-text--gray';
            this.noResultsIconPath = '/assets/svg/icon-search-no-results-access-g.svg';
        }
    }

    getMask(): string {
        let mask: string;
        this.selectedSearchType = parseInt(this.searchTypeForm.controls.searchType.value);

        this.searchTypes.filter((type) => {
            if (type.id == this.selectedSearchType) {
                mask = type.mask;
            }
        });
        return mask;
    }

    getPlaceholder(): string {
        let placeholder: string;
        this.selectedSearchType = parseInt(this.searchTypeForm.controls.searchType.value);

        this.searchTypes.filter((type) => {
            if (type.id == this.selectedSearchType) {
                placeholder = type.placeholder;
            }
        });
        return placeholder;
    }

    resetFormValues() {
        this.searchTypeForm.reset({
            searchType: '8',
            searchField: ''
        });
    }

    onChangeSearchType() {
        this.searchTypeForm.controls.searchField.reset();
    }

    clearSearch(event) {
        if (event) {
            this.isMessageBySearchFilter = false;
            this.getLastAccessContracts();
        }
    }

    getContractsBySearchField(searchType?: any, searchField?: any, page?: number) {
        this.isLoadingPage = true;
        this.isSearchingContracts = true;
        this.hasPagination = true;
        this.isLoadingContracts = true;

        this.searchFieldParams = new GetContractsBySearchFieldParams();
        this.searchFieldParams.searchType = searchType ?? undefined;
        this.searchFieldParams.searchField = searchField ?? undefined;
        this.searchFieldParams.page = page ?? 1; // Defina a página padrão como 1 se `page` for undefined

        this._selectContractsService
            .getContractsBySearchField(this.searchFieldParams)
            .pipe(
                finalize(() => {
                    this.isSearchingContracts = false;
                    this.isLoadingContracts = false;
                    this.isLoadingPage = false;

                })
            )
            .subscribe({
                next: (success) => {
                    this.contractList = success.data;
                    this.totalContractsList = success.totalItems;
                    this.handleMessageResult(this.hasPagination);
                },
                error: (error) => {
                    log.error(error);
                    return;
                }
            });
    }

    getUserContractsBySearchField(searchType?: any, searchField?: any, page?: number) {
        this.isLoadingPage = true;
        this.isSearchingContracts = true;
        this.hasPagination = true;
        this.isLoadingContracts = true;

        this.searchFieldParams = new GetContractsBySearchFieldParams();
        this.searchFieldParams.searchType = searchType ?? undefined;
        this.searchFieldParams.searchField = searchField ?? undefined;
        this.searchFieldParams.page = page ?? 1; // Defina a página padrão como 1 se `page` for undefined

        this._selectContractsService
            .getUserContractsBySearchField(this.searchFieldParams)
            .pipe(
                finalize(() => {
                    this.isSearchingContracts = false;
                    this.isLoadingContracts = false;
                    this.isLoadingPage = false;
                })
            )
            .subscribe({
                next: (success) => {
                    this.contractList = success.data;
                    this.totalContractsList = success.totalItems;

                    this.response = success;

                    this.handleMessageResult(this.hasPagination);
                },
                error: (error) => {
                    log.error(error);
                    return;
                }
            });
    }




    userContractAccessAndBond(contract: ContractInfosDto, hasManyContracts: boolean = false) {
        this.messageLoader = 'Selecionando contrato...'
        this.isLoadingContracts = true;

        this._selectContractsService.userContractAccessAndBond(contract.id)
            .subscribe({
                next: () => {
                    this.setContract(contract, hasManyContracts);
                },
                error: (error) => {
                    log.error(error);
                    return;
                }
            });
    }

    /**
     * Método para realizar o logout do usuário
     */
    logout(): void {
        this._oauthService.logOut();
    }

    /**
     * Método para realizar a busca de contratos
     * Recebe o evento do componente de busca
     * @param event
     */
    // Pendente ativar as mensagens
    onSearch(searchParams: any) {
        this.searchFieldParams = searchParams;
        this.searchFieldParams.page = 1;  // Reinicie para a primeira página ao buscar

        this.isMessageBySearchFilter = true;

        if (this.isRelationshipConsultantUser) {
            this.getContractsBySearchField(this.searchFieldParams.searchType, this.searchFieldParams.searchField, this.searchFieldParams.page);
        } else {
            this.getUserContractsBySearchField(this.searchFieldParams.searchType, this.searchFieldParams.searchField, this.searchFieldParams.page);
        }
    }



    getPersonInfo() {
        this._selectContractsService.getCurrentUser()
            .pipe(
                finalize(() => { })
            ).subscribe({
                next: (success) => {
                    this.personInfo = success
                },
                error: (err) => {
                    console.log(err)
                }
            })
    }


    onPaginationChange(pageSelected: any) {
        if (this.searchFieldParams.page !== pageSelected) {
            this.searchFieldParams.page = pageSelected;
            this.isMessageBySearchFilter = true;

            if (this.isRelationshipConsultantUser) {
                this.getContractsBySearchField(this.searchFieldParams.searchType, this.searchFieldParams.searchField, pageSelected);
            } else {
                this.getUserContractsBySearchField(this.searchFieldParams.searchType, this.searchFieldParams.searchField, pageSelected);
            }
        }
    }

}
