import { Component, OnInit, Input } from '@angular/core';
import { FirstAccessBallonPage } from './model/first-access-ballon-page.model';
import { Router } from '@angular/router';
import { StoragedContractInfosService } from '@services/storaged-contract-infos.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-first-access-ballon',
  templateUrl: './first-access-ballon.component.html',
  styleUrls: ['./first-access-ballon.component.scss']
})
export class FirstAccessBallonComponent implements OnInit {

  constructor(private _router: Router, private _storagedContractInfosService: StoragedContractInfosService) { 
    this._getContractBlockLevelVariable();
  }

  contractBlock: number;
  @Input() page:number;
  firstAccessBallonPage = FirstAccessBallonPage;
  sub$: Subscription;

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.sub$.unsubscribe();
  }

  _getContractBlockLevelVariable(){
    this.sub$ = this._storagedContractInfosService.contractBlockLevel$
    .subscribe((data) => {
      this.contractBlock = data;
    });
  }

  redirectToRecharge(){
    this._router.navigate(["/recargas"]); 
  }

}
