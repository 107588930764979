import {Injectable} from "@angular/core";
import {ProfileType} from "@variables/profileType";
import {LocalstorageService} from "@services/localstorage.service";
import { UserInfo } from "../models/userInfo.dto";

const ActionProfile: any = {
    CUSTOMER: {redirect: true, loadRolesBackOffice: false, url: '/selecionar-contrato'},
    RELATIONSHIP: {redirect: true, loadRolesBackOffice: false, url: '/selecionar-contrato'},
    BACKOFFICE: {redirect: true, loadRolesBackOffice: true, url: '/backoffice/menu-inicial'},
  }
;

@Injectable({
  providedIn: "root",
})
export class UserProfileService {

  constructor(private localStorageService: LocalstorageService) {
  }

  setUserProfile(idProfile: number) {
    if (idProfile) {
      this.localStorageService.setProfile(idProfile);
    }
  }

  updateUserProfile(idProfile: number) {
    if (idProfile) {
      this.localStorageService.setProfile(idProfile);
    }
  }

  getUserProfile(): number {
    return this.localStorageService.getProfile();
  }

  getActionForProfile(): any {
    const profileActionMap = {
      [ProfileType.CUSTOMER]: ActionProfile.CUSTOMER,
      [ProfileType.RELATIONSHIP]: ActionProfile.RELATIONSHIP,
      [ProfileType.BACKOFFICE]: ActionProfile.BACKOFFICE
    };

    const profile = this.localStorageService.getProfile();
    return profileActionMap[profile] || null;
  }

  isCustomer(): boolean {
    return ProfileType.CUSTOMER === this.localStorageService.getProfile();
  }

  isBackOffice(): boolean {
    return ProfileType.BACKOFFICE === this.localStorageService.getProfile();
  }

  isRelationship(): boolean {
    return ProfileType.RELATIONSHIP === this.localStorageService.getProfile();
  }

  isCustomerOrRelationshipProfile(): boolean {
    return this.isCustomer() || this.isRelationship();
  }

  hasMultipleProfile(): boolean {
    return this.localStorageService.hasMultipleProfile();
  }

  setHasMultipleProfile(hasMultipleProfile: boolean) {
    this.localStorageService.setHasMultipleProfile(hasMultipleProfile);
  }

  getUserId(): number {
    return this.localStorageService.getCurrentIdentity();
  }

  getUserInfo() : UserInfo {
    return this.localStorageService.getUserInfo();
  }

  setUserId(userId: number) {
    if (userId) {
      this.localStorageService.setCurrentIdentity(userId);
    }
  }

  haveRole(role){
    let roles;
    if (this.isBackOffice()) {
       roles = this.localStorageService.getRolesBackOffice();
    } else {
       roles = this.localStorageService.getRoles();
    }


    return !!roles.find(element => element == role);
  }
}
