import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { BillRoutingModule } from './routes/bill-routing.module';
import { BillDetailComponent } from './components/bill-detail/bill-detail.component';
import { BillListComponent } from './components/bill-list/bill-list.component';
import { BillDetailGeneralComponent } from './components/bill-detail/components/bill-detail-general/bill-detail-general.component';
import { BillDetailHierarchyComponent } from './components/bill-detail/components/bill-detail-hierarchy/bill-detail-hierarchy.component';
import { BillDetailVehiclesComponent } from './components/bill-detail/components/bill-detail-vehicles/bill-detail-vehicles.component';
import { AdvanceDetailsModalComponent } from './components/bill-detail/components/bill-detail-general/components/advance-details-modal/advance-details-modal.component';
import { BillPassesChargebackComponent } from './components/bill-detail/components/bill-detail-general/components/bill-passes-chargeback/bill-passes-chargeback.component';
import { BillServicesComponent } from './components/bill-detail/components/bill-detail-general/components/bill-services/bill-services.component';
import { BillVehicleModalComponent } from './components/bill-detail/components/bill-detail-hierarchy/components/bill-vehicle-modal/bill-vehicle-modal.component';
import { GridHierarchyComponent } from './components/bill-detail/components/bill-detail-hierarchy/components/grid-hierarchy/grid-hierarchy.component';
import { GridVehiclesComponent } from './components/bill-detail/components/bill-detail-vehicles/components/grid-vehicles/grid-vehicles.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from 'src/app/shared/shared.module';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import { BillCardComponent } from './components/bill-card/bill-card.component';
import {
  BillRecompositionModalComponent
} from "@modules/customer/bill/components/bill-recomposition-modal/bill-recomposition-modal.component";
import {TranslateModule} from "@ngx-translate/core";
import { TabSelectComponent } from 'src/app/shared/components/tab-select/tab-select.component';
import { OutlinedSelectComponent } from 'src/app/shared/select/outlined-select/outlined-select.component';
import { SearchFormInlineComponent } from 'src/app/shared/components/search-form-inline/search-form-inline.component';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BillRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    MatTabsModule,
    TabsModule,
    PopoverModule.forRoot(),
    MatCardModule,
    MatButtonModule,
    NgOptimizedImage,
    TranslateModule,
    TabSelectComponent,
    OutlinedSelectComponent,
    SearchFormInlineComponent,
    MatRadioModule,
    BorderlessButtonComponent,
    MatCheckboxModule,
  ],
  declarations: [
     BillDetailComponent,
     BillListComponent,
     BillDetailGeneralComponent,
     BillDetailHierarchyComponent,
     BillDetailVehiclesComponent,
     AdvanceDetailsModalComponent,
     BillPassesChargebackComponent,
     BillServicesComponent,
     BillVehicleModalComponent,
     GridHierarchyComponent,
     GridVehiclesComponent,
     BillCardComponent,

    //BackOffice
    BillRecompositionModalComponent
  ],
  providers: [DatePipe, provideNgxMask()],
})

export class BillModule {}
