import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ExtractRoutingModule } from './routes/extract-routing.module';
import { ExtractComponent } from './extract.component';
import { FinalLimitComponent } from './components/final-limit/final-limit.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from 'src/app/shared/shared.module';
import { RadioButtonModule } from 'src/app/shared/components/radiobutton/radiobutton.module';
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { SearchFormInlineComponent } from 'src/app/shared/components/search-form-inline/search-form-inline.component';
import { OutlinedSelectComponent } from 'src/app/shared/select/outlined-select/outlined-select.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ExtractRoutingModule,
    NgbModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    BsDropdownModule,
    BorderlessButtonComponent,
    OutlinedSelectComponent,
    SearchFormInlineComponent
  ],
  declarations: [
     ExtractComponent,
     FinalLimitComponent
  ],
  providers: [DatePipe, provideNgxMask(),CurrencyPipe],
})

export class ExtractModule {}
