import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BorderlessButtonComponent } from '../button/button-without-border/borderless-button.component';
import { FormsModule } from '@angular/forms';
import { NgClass, NgStyle } from '@angular/common';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-search-form-inline',
  standalone: true,
  imports: [BorderlessButtonComponent, FormsModule, NgClass, NgStyle, MatInputModule, MatIconModule, NgxMaskDirective],
  providers: [provideNgxMask()],
  templateUrl: './search-form-inline.component.html',
  styleUrl: './search-form-inline.component.scss'
})
export class SearchFormInlineComponent implements OnInit{
  @Input() placeholder: string = 'Digite para buscar';
  @Input() buttonDescription: string = 'Buscar';
  @Input() buttonIconClass: string = 'ico-search-white';
  @Input() buttonColor: string = 'primary';
  @Input() hasButton: boolean = false;
  @Input() isDisable: boolean = false;
  @Input() hasIcon: boolean = true;
  @Input() customWidth!: any;
  @Input() customIcon: string = 'ico-search-zoom'
  @Input() label: string;
  @Input() mask: string;

  searchClass: string = '';

  protected classCustom: string = '';

  @Output() buttonClickEvent = new EventEmitter<void | string>();
  @Output() keyUpEvent = new EventEmitter<string>();

  @Input() searchValue: string = '';



  ngOnInit(): void {
    this.handleDivStyle();
    this.handleInputStyle();
  }


   onKeyUp(): void {
    this.keyUpEvent.emit(this.searchValue);
  }

  onButtonClick(): void {
    this.buttonClickEvent.emit(this.searchValue);
  }

  handleDivStyle(): any {
    let customStyle: any = '';

    if (this.customWidth) {
      customStyle = { 'width': this.customWidth };
    }

    return customStyle;
  }



  handleInputStyle(): any {
    if(!this.hasButton){
      this.classCustom = this.classCustom + ' no-button';
    }
    return this.classCustom;
  }

}
