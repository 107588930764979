<div class="box-payment-method" [style.grid-template-rows]="gridRowStyle()" [formGroup]="paymentMethodform" *ngIf="!isLoading">
  <header class="title" data-e2e="page-title">Meio de pagamento</header>
      <div class="box-payment" *ngFor="let method of paymentMethodList">

          <div class="payment-method-selector-radio radioContent">
            <label class="container" *ngIf="selectorMode">
              <input type="radio" id="inputPaymentType{{method.id}}" [value]="method.id" formControlName="paymentMethod"  name="paymentMethod" (click)="onRadioChange(method.id)">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="payment-method">
            <div class="noPaymentMethod" *ngIf="(!reducedList && method.id == 1 && !hasCreditCard) || (method.id == 0)">Novo cartão de crédito</div>
            <img src="/assets/img/icon-pix.svg" *ngIf="method.id == 2"/>
            <div class="credit-card"  *ngIf="method.id == 1 && hasCreditCard">
              <b data-e2e="card-label">Cartão de crédito</b>
              <app-credit-card></app-credit-card>
            </div>
          </div>
          <div class="buttons-round" *ngIf="method.id == 1 && hasCreditCard">
                <button
                id="id-btn-modal-update"
                *accessControl="101177"
                data-e2e="open-card-update-modal-btn"
                (click)="showUpdateCreditCardModal()"
                type="button"
                class="btn-edit-round"
                placement="top"
                ngbTooltip="Atualizar"
            ></button>
                <button
                id="id-btn-modal-exclude"
                *accessControl="101225"
                data-e2e="open-card-update-modal-btn"
                (click)="showExcludeCreditCardModal()"
                type="button"
                class="btn-exclude-round"
                placement="top"
                ngbTooltip="Excluir"
            ></button>
          </div>
          <div class="buttons-round" *ngIf="(method.id == 1 && !hasCreditCard) || (method.id == 0)">
                <button
                id="id-btn-modal-register"
                *accessControl="101177"
                data-e2e="open-card-update-modal-btn"
                (click)="showRegisterCreditCardModal()"
                type="button"
                class="btn-add-round"
                placement="top"
                ngbTooltip="Adicionar"
            ></button>
          </div>

      </div>
  <footer></footer>
</div>

<div class="box-payment-method" [style.grid-template-rows]="gridRowStyle()"  *ngIf="isLoading">
  <header class="title" data-e2e="page-title">Meio de pagamento</header>
  <div class="container-loading">
    <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
  </div>
  <footer></footer>
</div>


