import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';

import { MasksService } from '@services/masks.service';
import { NotifyService } from '@services/notify.service';
import { Dto } from '@models/dto.model';
import {UserModel} from "@models/backoffice/user.model";
import {
  FeedbackCreateUpdateUserDto
} from "@modules/backoffice/user-consultant-create-update/dtos/feedback-create-update-user.dto";
import {
  UserConsultantCreateUpdateService
} from "@modules/backoffice/user-consultant-create-update/services/user-consultant-create-update.service";
import {EPageType} from "@modules/backoffice/user-consultant-create-update/enums/enum-page-type";
import {EStepModal} from "../../../../../shared/enums/enum-step-modal";
import {
  UserConsultantCreateUpdateConst
} from "@modules/backoffice/user-consultant-create-update/constants/const-user-consultant-create-update";

@Component({
  selector: 'app-user-consultant-modal',
  templateUrl: './user-consultant-modal.component.html',
  styleUrls: ['./user-consultant-modal.component.scss']
})
export class UserConsultantModalComponent implements OnInit {
  @Input() title: string;
  @Input() subtitleConfirm: string;
  @Input() subtitleFeedback: string;
  @Input() userDto: UserModel;
  @Input() activePage: EPageType;

  @Output() eventHandlePage: EventEmitter<void> = new EventEmitter();

  imgPath: string;
  eStepModal = EStepModal;
  step: EStepModal;
  isLoading: boolean;
  feedbackDto: FeedbackCreateUpdateUserDto;

  constructor(
    private _modalService: NgbModal,
    private _masksService: MasksService,
    private _userConsultantCreateUpdateService: UserConsultantCreateUpdateService,
    private _notifyService : NotifyService,
  ){
  }

  ngOnInit(): void {
    this.settingsInitClass();
  }

  settingsInitClass(): void {
    this.step = EStepModal.CONFIRM;
    this.feedbackDto = new FeedbackCreateUpdateUserDto();
    this.imgPath = UserConsultantCreateUpdateConst.IMG_PATH;
  }

  dismissModal(): void {
    this._modalService.dismissAll();
  }

  getPhoneMask(phone: string): string {
    return this._masksService.getPhoneMask(phone);
  }

  assignResponseAttributes(success: Dto<FeedbackCreateUpdateUserDto>): void {
    this.feedbackDto.fullName = success.data.fullName;
    this.feedbackDto.documentNumber = success.data.documentNumber;
    this.feedbackDto.dateOfBirth = success.data.dateOfBirth;
    this.feedbackDto.telephoneNumber = success.data.telephoneNumber;
    this.feedbackDto.email = success.data.email;
    this.feedbackDto.protocol = success.data.protocol;
    this.feedbackDto.registrationUsername = success.data.registrationUsername;
    this.feedbackDto.registrationDate = success.data.registrationDate;
  }

  confirmCreateUpdateUser(): void {
    this.isLoading = true;

    this.activePage === EPageType.CREATE ? this.createUser() : this.updateUser();
  }

  private createUser(): void {
    this._userConsultantCreateUpdateService.createUser(this.userDto)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          if(success.success) {
            this.assignResponseAttributes(success);
            this.step = EStepModal.FEEDBACK;
            this._notifyService.showSuccess('Usuário', success.message);
            this.eventHandlePage.emit();
          }
        },
        error: (error) => {
          console.error(error);
          this.dismissModal();
          return;
        }
      });
  }

  private updateUser(): void {
    this._userConsultantCreateUpdateService.updateUser(this.userDto)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({next: (success) => {
          if(success.success) {
            this.assignResponseAttributes(success);
            this.step = EStepModal.FEEDBACK;
            this.eventHandlePage.emit();
          }
        },
        error: (error) => {
          console.error(error);
          this.dismissModal();
          return;
        }
      });
  }

}
