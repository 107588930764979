<div class="ConciliationFeedbackModal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Conciliação de repasse de parceiro</h4>
    <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
      <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>

  <div class="modal-body">
    <section class="modal-data-container" *ngIf="!hasErrors">
      <img class="feedback-img" src="assets/img/feedback-success-modal.png" alt="feedback success">
      <h3>Arquivo enviado com sucesso!</h3>
      <div>
        <p>O arquivo está sendo conciliado.</p>
        <p>Acompanhe o status pelo Histórico de Conciliações.</p>
      </div>
    </section>

    <section class="modal-data-container" *ngIf="hasErrors && totalErrors > 0">
      <h5 class="error-title">Não foi possível realizar o upload do arquivo!</h5>
      <p class="error-message">Encontramos {{Errors.length}}
        <span *ngIf="totalErrors > 1">inconsistências</span>
        <span *ngIf="totalErrors == 1">inconsistência</span> no arquivo.</p>
        <div class="errors-box">
          <p class="error-line" *ngFor="let error of Errors"> {{error}}</p>
        </div>
      <div class="buttons">
        <p>Por favor, baixe e revise o arquivo com as inconsistências e importe um novo documento</p>
      <div class="buttons-line">
        <button class="btn btn-secondary" (click)="DownloadItemWithErrors(this.itemId)">
          Baixar arquivo com inconsistências
        </button>
        <button class="btn btn-primary" (click)="newImport()">
          <img class="button-icon" src="../../assets/svg/icon-import.svg"/>
          importar novo documento
        </button>
      </div>
      </div>
    </section>

    <section class="modal-data-container error-already-imported" *ngIf="hasErrors && AlreadyExistsImportedFile">
       <h5 class="error-title">Não foi possível realizar a conciliação,<br/>
        pois já existe um arquivo importado para o parceiro e período informados!</h5>
       <p class="error-message">Por favor, selecione outro parceiro ou período para importação.</p>

       <button class="btn btn-primary" (click)="newImport()"><img class="button-icon" src="../../assets/svg/icon-import.svg"/>importar novo documento</button>
    </section>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      (click)="dismissModal()"
    >
      FECHAR
    </button>
  </div>
</div>
