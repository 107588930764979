<div class="login">
    <div class="BGLogin">
      <img src="assets/svg/BG-Login.png" />
    </div>
    <div class="Login_BoxForm">
      <div class="BoxForm_Content">
        <div class="BoxForm_Title">
          <img src="assets/svg/icon_login.svg">
          <p>Olá, bem-vindo</p>
        </div>
        <div class="BoxForm_Form">
            <div class="choose">
              <div class="label">Escolha uma opção: </div>
                <div style="margin-top:2rem; margin-bottom:2rem;">
                  <app-borderless-button style="margin-left:1.5rem;"
                                         [description]="'Esse é meu primeiro acesso'"
                                         [isBtnWithBorder]="true"
                                         [classIconLeft]="'ico-first-access'"
                                         [data-e2e]="'btn-redirect-first-access'"
                                         [id-html]="'btn-redirect-first-access'"
                                         [customWidthSize]="'18rem'"
                                         (emitterEvtClick)="firstAccess()">
                  </app-borderless-button>
                </div>
                <div>
                  <app-borderless-button style="margin-left:1.5rem"
                    [description]="'Já sou cadastrado'"
                    [isBtnWithBorder]="true"
                    [classIconLeft]="'ico-login'"
                    [data-e2e]="'btn-redirect-login'"
                    [id-html]="'btn-redirect-login'"
                    [customWidthSize]="'18rem'"
                    (emitterEvtClick)="login()">
                  </app-borderless-button>
                </div>
            </div>
        </div>
        <!--BoxForm_Form-->
      </div>
      <div class="row">
        <div class="edenredMobile"> <img src="assets/svg/logo-edenred.svg" /><img src="assets/svg/logo-ticket-log.svg" /></div>
      </div>
      <!--BoxForm_Content-->
    </div>
    <!--Login_BoxForm-->
    <!-- **************** Login | End **************** -->
  </div>

