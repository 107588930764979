import { ContractDetailsModalComponent } from './../contract-details-modal/contract-details-modal.component';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '@services/payment.service';
import { MyContractService } from '@services/mycontract.service';
import { UserPassword } from '@models/user-password.model';
import { finalize } from 'rxjs';
import { ContractIntegration } from '@models/contractintegrationfee.model';
import {AbstractConfigurationBase} from "../../../../../shared/abstracts/abstract-configuration-base";

@Component({
  selector: 'app-credentials-modal',
  templateUrl: './credentials-modal.component.html',
  styleUrls: ['./credentials-modal.component.scss']

})

export class CredentialsModalComponent extends AbstractConfigurationBase implements OnInit {
  @Input() commercialCondition: any;
  @Input() principalPaymentMethodType: number;
  isLoading: Boolean = false;
  step: number = 1;
  hidepassword : boolean = true;
  passwordInvalid: boolean = false;
  validPasswordForm: any;
  userPassword: UserPassword;
  copied: boolean = false;
  copiedSecret: boolean = false;
  public clientId: string;
  public clientSecret: string;
  public form;
  constructor(
    private modalService: NgbModal,
    public paymentService: PaymentService,
    public myContractService: MyContractService
  ) {
      super();
    }

  ngOnInit() {
    this.generateForm();
  }

  close() {
    this.modalService.dismissAll();
  }

  generateForm() {
    this.validPasswordForm = new FormGroup({
      password: new FormControl("",[Validators.required])
    });
  }

  backContract() {
    this.close();
    const modalRef = this.modalService.open(ContractDetailsModalComponent);
    modalRef.componentInstance.commercialCondition = this.commercialCondition;
    modalRef.componentInstance.principalPaymentMethodType = this.principalPaymentMethodType;
  }

  justifyStyle(){
    return this.step == 1 ? `space-between`: `center`
  }

  viewpass() {
    this.hidepassword = !this.hidepassword;
  }

  copyToClipboard(inputElement, secret){
    if (secret) {
      const textarea = document.createElement('textarea');
      textarea.value = inputElement;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.copiedSecret = true;
      this.copied = false;
    }
    else {
      inputElement.select();
      document.execCommand('copy');
      inputElement.setSelectionRange(0, 0);
      this.copied = true;
      this.copiedSecret = false;
    }
  }

  viewCredentials()
  {
    this.isLoading = true;
    this.form = this.validPasswordForm.value;
    this.userPassword = {
      password: this.form.password
    };

    this.myContractService
    .validPassword(this.userPassword)
    .subscribe
    (
      (data: any) =>
      {
        if (!data.success)
        {
          this.passwordInvalid = true;
          this.isLoading = false;
        }
        else
        {
          this.step = 2;

          let endpoint: string = 'Integration';
          if (!this.userProfileService.isCustomer()){
            endpoint = 'Integrationservice';
          }

          this.paymentService
          .getContractIntegration(endpoint)
          .pipe(finalize(() => { this.isLoading = false; }))
          .subscribe(
            (success: ContractIntegration) => {
              this.clientId = success.clientId;
              this.clientSecret = success.clientSecret;
            },
            (error) => {
              console.error("error: ", error);
            }
          );
        }
      },
      (error) =>
      {
        console.error("getFromServerError", error);
        return;
      }
    );
  }



}
