import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../dashboard.component';
import { PageService } from '../../page/services/page.service';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "",
      redirectTo: "/selecionar-perfil",
      pathMatch: "full",
    },
    {
      path: "pagina-inicial",
      component: DashboardComponent,
      canActivate: [authGuardCustomer],
      data: { roles: "101124", contractRequired: true },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class DashboardRoutingModule {}
