import { Component, OnInit, EventEmitter,Input,Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectContractsService } from '../../../select-contracts/services/select-contracts.service';
import { NotifyService } from '@services/notify.service';
import { EventEmitterService } from '@services/event-emitter.service';

@Component({
  selector: 'app-edit-contract-name-modal',
  templateUrl: './edit-contract-name-modal.component.html',
  styleUrls: ['./edit-contract-name-modal.component.scss']
})
export class EditContractNameModal implements OnInit {

  constructor(
    private modalService: NgbModal,
    private notifyService: NotifyService,
    private formBuilder: FormBuilder,
    private selectContractsService: SelectContractsService
    ) {
    }

    @Output() event: EventEmitter<any> = new EventEmitter();
    @Input() contract;
    editContractNameForm: FormGroup;
    onSaving: boolean;
    step: number = 1;
    contractName: string;
    nameUser: string;
    dateRegister: Date;

  ngOnInit() {
    this.contractName = this.contract.nameContract;
    this.createForm()
  }

  createForm() {
    this.editContractNameForm = this.formBuilder.group({
      nameContract: ['', ],
    });
  }


  submitContractName(form) {
    this.onSaving = true;
    this.selectContractsService
      .putContractName(form)
      .pipe(
        finalize(() => {
          this.onSaving = false;
        })
      )
      .subscribe(
        (success: any) => {
          this.event.emit(1);
          EventEmitterService.get('clearCacheAfterEditContractName').emit();
          this.nameUser = success.nameUser;
          this.dateRegister = success.dateRegister;
          this.step = 2;
        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

  close() {
    this.event.emit(2);
    this.modalService.dismissAll();
  }

}
