import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { FleetDashboardComponent } from '../fleet-dashboard.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "indicadores-frota",
      component: FleetDashboardComponent,
      canActivate: [authGuardCustomer],
      data: { roles: "101222", contractRequired: true },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class FleetDashboardRoutingModule {}
