import { Component, OnInit,Input  } from '@angular/core';
import { FormGroup,FormBuilder, Validators} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdduserModalService } from '../../services/adduser-modal.service';
import { UserDTO } from '../../dtos/user.dto';
import { finalize } from "rxjs/operators";
import { NotifyService } from '@services/notify.service';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { CpfCnpjValidator } from '@services/form-validators/cpf-cnpj-validator.service';
import { TelephoneValidator } from '@services/form-validators/telephone-validator.service';
import { EventEmitterService } from '@services/event-emitter.service';
import { MyContractService } from '@services/mycontract.service';
import {lastValueFrom} from "rxjs";
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';

@Component({
  selector: 'app-adduser-modal',
  templateUrl: './adduser-modal.component.html',
  styleUrls: ['./adduser-modal.component.scss']
})

export class AdduserModalComponent extends AbstractConfigurationBase implements OnInit {

  public formUser: FormGroup;
  public days = [];
  public months = [];
  public years = [];
  public day = 31;
  public month = 12;
  public success;
  @Input() profileList;
  public selectedProfile;
  submitted:boolean=false;
  step:number = 1;
  date:Date = new Date();
  isLoading:Boolean;
  userResponsible:string = 'Usuário teste'
  public form;
  protected emailExists: boolean = false;
  protected profileNotSelect: boolean = false;

  constructor(
    protected notifyService: NotifyService,
    private formBuilder : FormBuilder,
    private adduserModalService: AdduserModalService,
    private bsModalRef: BsModalRef,
    private formValidatorMessageService: FormValidatorMessageService,
    private myContractService: MyContractService,
    ) {
    super();
  }

   createForm() {
    this.formUser = this.formBuilder.group({
      fullName: ['',[Validators.required]],
      documentNumber: ['',[Validators.required, CpfCnpjValidator.isValidDocument()]],
      dateOfBirthDay: ['',[Validators.required]],
      dateOfBirthMonth: ['',[Validators.required]],
      dateOfBirthYear: ['',[Validators.required]],
      telephoneNumber:['55',[Validators.required, TelephoneValidator.isValidTelephone(true)]],
      emailAddress: ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$")]],
      profile: ['',[Validators.required]],
      protocol: ['']
    })

    if(this.userProfileService.isBackOffice()){
      this.formUser.get('protocol').setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.formUser.get('protocol').updateValueAndValidity();

    }
  }

  ngOnInit() {
    this.createForm();
    this.selectedProfile = this.profileList.filter(data => data.id == 10199)[0]
    this.formUser.controls.profile.setValue('10199');

  // gera combobox de dia de aniversário
  this.days = [
    {"desc": '1',"value": '01'},
    {"desc": '2',"value": '02'},
    {"desc": '3',"value": '03'},
    {"desc": '4',"value": '04'},
    {"desc": '5',"value": '05'},
    {"desc": '6',"value": '06'},
    {"desc": '7',"value": '07'},
    {"desc": '8',"value": '08'},
    {"desc": '9',"value": '09'},
    {"desc": '10',"value": '10'},
    {"desc": '11',"value": '11'},
    {"desc": '12',"value": '12'},
    {"desc": '13',"value": '13'},
    {"desc": '14',"value": '14'},
    {"desc": '15',"value": '15'},
    {"desc": '16',"value": '16'},
    {"desc": '17',"value": '17'},
    {"desc": '18',"value": '18'},
    {"desc": '19',"value": '19'},
    {"desc": '20',"value": '20'},
    {"desc": '21',"value": '21'},
    {"desc": '22',"value": '22'},
    {"desc": '23',"value": '23'},
    {"desc": '24',"value": '24'},
    {"desc": '25',"value": '25'},
    {"desc": '26',"value": '26'},
    {"desc": '27',"value": '27'},
    {"desc": '28',"value": '28'},
    {"desc": '29',"value": '29'},
    {"desc": '30',"value": '30'},
    {"desc": '31',"value": '31'},
]

// gera combobox de mês de aniversário
  this.months = [
    {"desc": 'Janeiro',"value": '01'},
    {"desc": 'Fevereiro',"value": '02'},
    {"desc": 'Março',"value": '03'},
    {"desc": 'Abril',"value": '04'},
    {"desc": 'Maio',"value": '05'},
    {"desc": 'Junho',"value": '06'},
    {"desc": 'Julho',"value": '07'},
    {"desc": 'Agosto',"value": '08'},
    {"desc": 'Setembro',"value": '09'},
    {"desc": 'Outubro',"value": '10'},
    {"desc": 'Novembro',"value": '11'},
    {"desc": 'Dezembro',"value": '12'},
]

    // gera combobox de ano de aniversário
    let initYear = 1950;
    for(let count:number = 0;count<=60;count++){
      this.years.push({"value": initYear });
      initYear++;
    }

}

  close() {
    if(this.step == 3){
       EventEmitterService.get('updateList').emit();
    }
    this.bsModalRef.hide();
   }

   returnStep(){
    this.createForm();
    this.fillFields();
    this.step=1
   }

   fillFields(){
    this.formUser.controls.fullName.setValue(this.form.fullName);
    this.formUser.controls.documentNumber.setValue(this.form.documentNumber);
    this.formUser.controls.dateOfBirthDay.setValue(this.form.dateOfBirthDay);
    this.formUser.controls.dateOfBirthMonth.setValue(this.form.dateOfBirthMonth);
    this.formUser.controls.dateOfBirthYear.setValue(this.form.dateOfBirthYear);
    this.formUser.controls.telephoneNumber.setValue(this.form.telephoneNumber);
    this.formUser.controls.emailAddress.setValue(this.form.emailAddress);
    this.formUser.controls.profile.setValue(this.form.profile);
    if (this.isBackOfficeProfile()){
      this.formUser.controls.protocol.setValue(this.form.protocol);
    }
   }

  generateErrorMessage(formControl){
    return this.formValidatorMessageService.returnMessage(formControl);
  }

  private async verifyExistEmail(email: string, documentNumber: string): Promise<boolean> | null {
    return await lastValueFrom(this.myContractService.verifyEmail(email, documentNumber));
  }

  private async firstStepValidation(email: string, documentNumber: string): Promise<boolean> | null {
    return await this.verifyExistEmail(email, documentNumber);
  }

  public async confirmEmail()
  {
    if (this.formUser.value.profile == null)
    {
      this.profileNotSelect = true;
      return;
    }

    if (this.formUser.value.emailAddress != null)
    {
      this.emailExists = await this.firstStepValidation(this.formUser.value.emailAddress, this.formUser.value.documentNumber);
    }
    this.onSubmit(!this.emailExists);
  }

  onSubmit(emailIsValid: boolean) {

    if (!emailIsValid){
      return;
    }

    this.form = this.formUser.value
    if(this.step == 1){
      this.submitted = true;
      if(this.formUser.invalid){
        return;
      }
      else{
        this.step = 2;
        this.selectedProfile = this.profileList.find(x => x.id == this.form.profile);
      }
    }
    else if(this.step == 2){
      this.createUser(this.formUser.value);
    }

  }

  onRadioChange(profile){
    this.selectedProfile = profile;
  }

  createUser(entity){

    let user: UserDTO = {
      documentNumber: entity.documentNumber,
      fullName: entity.fullName,
      personDocumentTypeId: 0,
      contractId: 0,
      dateofBirth:  entity.dateOfBirthYear+'-'+entity.dateOfBirthMonth+'-'+entity.dateOfBirthDay+'T03:00:00.000Z',
      personEmailAddressCollection: [{
        emailAddress:  entity.emailAddress,
        principalFlg: true
      }],
      personPhoneCollection: [{
        telephoneNumber: entity.telephoneNumber,
        prefix: entity.telephoneNumber.slice(2,4),
        areaCode: entity.telephoneNumber.slice(2,4),
        countryCode: entity.telephoneNumber.slice(0,2),
        personPhoneTypeId: entity.telephoneNumber.length == 12 ? 1 : 2,
        principalFlg: true
      }],
      groupId: entity.profile.toString(),
    }

    entity.protocol ? user.protocol = entity.protocol : null;


    this.SaveUser(user);
  }

  SaveUser(user){
    this.isLoading = true;
    this.adduserModalService.saveUser(user)
    .pipe(finalize(() => {
      this.isLoading = false;
      }))
     .subscribe(
        (success) => {
          this.success = success;
          this.step = 3;
        },
        (error) =>{
        console.error(error);
         this.step = 1;
        }
      );
  }


  clearFields()
  {
    this.submitted = false;
    this.formUser.reset();
  }

  isCellPhone(phone): boolean {
    if(phone){
      return phone.length < 13 ? false : true;
    }
    else{
      return false
    }
  }

  getPhoneMask(){
    let filter = this.formUser.controls['telephoneNumber'].value;
    return this.isCellPhone(filter)
    ? '+00 (00) 0 0000-0000'
    : '+00 (00) 0000-00009'
  }

  protected isBackOfficeProfile(): boolean {
    return this.userProfileService.isBackOffice();
  }

  validateFormProtocol() : boolean {
    return ((this.formUser.controls['protocol']?.invalid &&
         this.formUser.controls['protocol']?.touched) ||
         (this.submitted && this.formUser.controls['protocol']?.invalid))
  }
}

