import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { Financial } from '@models/old/dashboard/financial.model';
import { DashboardService } from '../../../dashboard/services/dashboard.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})

export class InvoicesComponent implements OnInit {
  isLoading: boolean;
  isError: boolean;
  invoiceDashboard: Financial;
  totalInvoicesDashboard: number;
  openingMonth: string;
  today: string;
  lastBillDueDate: string;
  configurationCardContent: {dataE2e: string, _class: string, msgMain: string, msgSecondary: string} = null;
  @Input() contractBlock;

  constructor(private dashboardService: DashboardService,public datepipe: DatePipe) {
    this.today = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
  }

  ngOnInit(): void {
    this.loadInvoiceDashboard();
  }

  loadInvoiceDashboard() {
    this.isLoading = true;
    this.dashboardService
      .getInvoicesDashboard()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.handleConfigurationCardContent();
        })
      )
      .subscribe(
        (success) => {
          this.invoiceDashboard = success;
          this.lastBillDueDate = this.datepipe.transform(this.invoiceDashboard.dueDate, 'dd-MM-yyyy');
          if(success)
            this.loadInvoiceItemDashboard(success.id);
        },
        (error) => {
          this.isError = true;
          console.error(error);
          return;
        }
      );
  }

  loadInvoiceItemDashboard(billItem: number) {
    this.dashboardService
      .getTotalDashboardFinancial(billItem)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.totalInvoicesDashboard = success;
          this.setMonth(this.invoiceDashboard.openingDate);
        },
        (error) => {
          this.isError = true;
          console.error(error);
          return;
        }
      );
  }
  setMonth(date: Date) {
    let month = new Date(date).getMonth();
    switch (month) {
      case 0:
        this.openingMonth = 'Janeiro';
        break;
      case 1:
        this.openingMonth = 'Fevereiro';
        break;
      case 2:
        this.openingMonth = 'Março';
        break;
      case 3:
        this.openingMonth = 'Abril';
        break;
      case 4:
        this.openingMonth = 'Maio';
        break;
      case 5:
        this.openingMonth = 'Junho';
        break;
      case 6:
        this.openingMonth = 'Julho';
        break;
      case 7:
        this.openingMonth = 'Agosto';
        break;
      case 8:
        this.openingMonth = 'Setembro';
        break;
      case 9:
        this.openingMonth = 'Outubro';
        break;
      case 10:
        this.openingMonth = 'Novembro';
        break;
      case 11:
        this.openingMonth = 'Dezembro';
        break;

    }
  }

  getContractClass(billStatusTypeID){
    if(this.contractBlock == 3){
      return 'contractBlock'
    }
    else if(billStatusTypeID == 5 && this.lastBillDueDate == this.today && this.contractBlock !== 3){
      return 'billToDue'
    }
    else  if(billStatusTypeID == 6 && this.contractBlock !== 3){
      return 'overdueBill'
    }
    else{
      return '';
    }
  }

  private messageInformation: any = [
    {billStatusTypeID: 1, dataE2e: 'open-invoice-msg', _class: 'w-100 d-inline-block', msgMain: 'Sua fatura está aberta.', msgSecondary: ''},
    {billStatusTypeID: 2, dataE2e: 'closed-invoice-msg', _class: 'w-100 d-inline-block', msgMain: 'Sua fatura está em fechamento.', msgSecondary: ''},
    {billStatusTypeID: 3, dataE2e: 'waiting-payment-msg', _class: 'w-100 d-inline-block', msgMain: 'Sua fatura está fechada e aguardando pagamento.', msgSecondary: ''},
    {billStatusTypeID: 4, dataE2e: 'paid-invoice-msg', _class: 'w-100 d-inline-block', msgMain: 'Sua fatura está paga.', msgSecondary: ''},
    {billStatusTypeID: 5, dataE2e: 'to-be-paid-invoice-msg', _class: 'w-100 d-inline-block', msgMain: 'Sua fatura está a pagar.', msgSecondary: ''},
    {billStatusTypeID: 5, dataE2e: 'due-bill-msg', _class: 'billToDueMessage', msgMain: 'Realize o pagamento e evite multas por atraso. ', msgSecondary: 'Se já foi pago, desconsidere.'},
    {billStatusTypeID: 6, dataE2e: 'overdue-bill-msg', _class: 'overdueBillMessage', msgMain: 'Realize o pagamento hoje para evitar o bloqueio de seus serviços. ', msgSecondary: 'Se já foi pago, desconsidere.'},
    {billStatusTypeID: null, dataE2e: 'financial-block-msg', _class: 'financialBlockMessage', msgMain: 'Regularize a situação para continuar usando nossos serviços', msgSecondary: ''},
  ]
  handleConfigurationCardContent(): boolean {
    const objMessage: any | null = this.findCardContentByInvoiceAndConstract();

    if (objMessage) {
      this.configurationCardContent = {
        dataE2e: objMessage.dataE2e,
        msgMain: objMessage.msgMain,
        msgSecondary: objMessage.msgSecondary.length > 0 ? objMessage.msgSecondary : '',
        _class: objMessage._class
      }
    }

    return true;
  }

  private findCardContentByInvoiceAndConstract(): any | null {
    let objMessage: any = {dataE2e: '', _class: '', msgMain: '', msgSecondary: ''};

    if (this.invoiceDashboard?.billStatusTypeID != 5 && this.contractBlock !== 3) {
      objMessage = this.messageInformation.find(msg => msg.billStatusTypeID == this.invoiceDashboard.billStatusTypeID);
    } if (this.invoiceDashboard?.billStatusTypeID == 5 && this.contractBlock !== 3) {
      if (this.lastBillDueDate !== this.today) {
        objMessage = this.messageInformation.find(msg => msg.billStatusTypeID == 5 && msg.msgSecondary.length === 0);
      } else if (this.lastBillDueDate === this.today) {
        objMessage = this.messageInformation.find(msg => msg.billStatusTypeID == 5 && msg.msgSecondary.length > 0);
      }
    } else if (this.contractBlock == 3) {
      objMessage = this.messageInformation.find(msg => msg.billStatusTypeID === null);
    }

    return objMessage;
  }
}
