import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Token } from '@models/old/login/token.model';
import { ChangePasswordFirstAccess } from '@models/old/login/changePasswordFirstAccess.model';

@Injectable({
    providedIn: 'root'
})

export class RegisterPasswordService {

    private base = environment.apiTMIdentityServer;

    private httpOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*'
          })
      };
    
      constructor(protected http: HttpClient) { }

      public async generateToken(entity: Token): Promise<Object> {
        
        try {
          let apiUrl = `${this.base}/Tokens`;         
          var result = await this.http.post(apiUrl, entity, this.httpOptions).toPromise();          
          return result;
        } catch (e) {
          console.error(e.message);
          
          if(e.error === undefined)
            throw("Ocorreu um erro ao Gerar o Token");
          else
            throw(e);
          
          return null;
        }
      }

      public async changeFirstPasswordUser(entity: ChangePasswordFirstAccess) : Promise<Object> {
        try {
          
          let apiUrl = `${this.base}/CustomerPortal/User/ChangeFirstPasswordUser`
          var result = await this.http.put(apiUrl, entity, this.httpOptions).toPromise();          
          return result;
        } catch (e) {
          console.error(e.message);
          
          if(e.error === undefined)
            throw("Ocorreu um erro ao alterar a senha");
          else  
            throw(e);
          
          return null;
        }
      }
}