import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { ChargebackReportComponent } from './chargeback-report.component';
import { ChargebackReportRoutingModule } from './routes/chargeback-report-routing.module';
import { ChargebackApproveComponent } from './components/chargeback-approve/chargeback-approve.component';
import { ChargebackApprovedComponent } from './components/chargeback-approved/chargeback-approved.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {SharedModule} from "../../../shared/shared.module";
import {CoreModule} from "../../../core/core.module";
import {RadioButtonModule} from "../../../shared/components/radiobutton/radiobutton.module";
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { SearchFormInlineComponent } from 'src/app/shared/components/search-form-inline/search-form-inline.component';
import { OutlinedSelectComponent } from '../../../shared/select/outlined-select/outlined-select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TranslateModule.forRoot(),
    CoreModule,
    SharedModule,
    ChargebackReportRoutingModule,
    NgbModule,
    BsDropdownModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    ToastrModule,
    NgxCurrencyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    BorderlessButtonComponent,
    SearchFormInlineComponent,
    OutlinedSelectComponent,
  ],
  declarations: [
    ChargebackReportComponent,
    ChargebackApproveComponent,
    ChargebackApprovedComponent,
  ],
  providers: [provideNgxMask()],
})
export class ChargebackReportModule {}
