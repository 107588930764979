import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotifyService } from '@services/notify.service';
import { FeedbackFinancialBlockModalComponent } from '../feedback-financial-block-modal/feedback-financial-block-modal.component';
import { finalize } from 'rxjs';
import { formatDate } from '@angular/common';
import { Dto } from '@models/dto.model';
import {
  BlockUnblockContractDto
} from "@modules/backoffice/financial-blocking-unblocking/dtos/block-unblock-contract.dto";
import {
  FeedbackFinancialBlockDto
} from "@modules/backoffice/financial-blocking-unblocking/dtos/feedback-financial-block.dto";
import {
  FiancialBlockResponseDto
} from "@modules/backoffice/financial-blocking-unblocking/dtos/financial-block-response.dto";
import {
  FinancialBlockingUnblockingService
} from "@modules/backoffice/financial-blocking-unblocking/services/financial-blocking-unblocking.service";

const TITLE_BLOCK: string = 'BLOQUEIO FINANCEIRO MANUAL';
const TITLE_UNBLOCK: string = 'DESBLOQUEIO FINANCEIRO MANUAL';
const IMG_PATH: string = '/assets/img/feedback-success-modal.png';
const SUBTITLE_BLOCK: string = 'CONTRATO BLOQUEADO COM SUCESSO!';
const SUBTITLE_UNBLOCK: string = 'CONTRATO DESBLOQUEADO COM SUCESSO!';
const TEXT_BOXYELLOW_BLOCK: string =
  'O bloqueio das tags tem um prazo de até 6 horas para ser efetivado em todos os estabelecimentos do Brasil.';
const TEXT_BOXYELLOW_UNBLOCK: string =
  'O desbloqueio das tags tem um prazo de até 6 horas para ser efetivado em todos os estabelecimentos do Brasil.';

@Component({
  selector: 'app-confirmation-block-unblock-modal',
  templateUrl: './confirmation-block-unblock-modal.component.html',
  styleUrls: ['./confirmation-block-unblock-modal.component.scss'],
})
export class ConfirmationBlockUnblockModalComponent {
  @Input() isUnblockingContract: boolean;
  @Input() blockUnblockContractDto: BlockUnblockContractDto;
  @Output() eventReloadPage: EventEmitter<void> = new EventEmitter();

  feedbackFinancialBlockDto: FeedbackFinancialBlockDto;
  isLoading: boolean;

  blockUnblockForm = new FormGroup({
    protocol: new FormControl('', [Validators.required]),
    reason: new FormControl(''),
  });

  constructor(
    private _modalService: NgbModal,
    private _notifyService: NotifyService,
    private _financialBlockingUnblockingService: FinancialBlockingUnblockingService,
  ) {
    this.feedbackFinancialBlockDto = new FeedbackFinancialBlockDto();
  }

  dismissModal() {
    this._modalService.dismissAll();
  }

  getInputMask(): string {
    return '9'.repeat(50);
  }

  confirmBlockUnblockContract() {
    if (
      this.blockUnblockForm.invalid ||
      this.blockUnblockForm.controls.protocol.value == '0'
    ) {
      this._notifyService.showError(
        'Atenção!',
        'Informe um número de protocolo válido!',
      );
    } else {
      this.isLoading = true;

      this.blockUnblockContractDto.protocol =
        this.blockUnblockForm.controls.protocol.value;
      this.blockUnblockContractDto.reason =
        this.blockUnblockForm.controls.reason.value;

      this._financialBlockingUnblockingService
        .blockUnblockManualContract(this.blockUnblockContractDto)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (success) => {
            this.assignResponseAttributes(success);

            this.dismissModal();

            this.assignModalAttributes();

            this.eventReloadPage.emit();
          },
          error: (error) => {
            console.error(error);
            return;
          },
        });
    }
  }

  assignResponseAttributes(success: Dto<FiancialBlockResponseDto>) {
    this.feedbackFinancialBlockDto.changeDate = formatDate(
      success.data.changeDate,
      'dd/MM/yyyy HH:mm',
      'en-US',
    );

    this.feedbackFinancialBlockDto.changeUserName = success.data.changeUserName;
  }

  assignModalAttributes() {
    const modal = this._modalService.open(FeedbackFinancialBlockModalComponent);

    modal.componentInstance.title = this.isUnblockingContract
      ? TITLE_UNBLOCK
      : TITLE_BLOCK;
    modal.componentInstance.imgPath = IMG_PATH;
    modal.componentInstance.subtitle = this.isUnblockingContract
      ? SUBTITLE_UNBLOCK
      : SUBTITLE_BLOCK;
    modal.componentInstance.textBoxYellow = this.isUnblockingContract
      ? TEXT_BOXYELLOW_UNBLOCK
      : TEXT_BOXYELLOW_BLOCK;
    modal.componentInstance.textBold =
      'Protocolo: ' + this.blockUnblockContractDto.protocol;
    modal.componentInstance.text =
      'Motivo: ' + this.blockUnblockContractDto.reason;
    modal.componentInstance.textGray =
      this.feedbackFinancialBlockDto.changeDate +
      ' por ' +
      this.feedbackFinancialBlockDto.changeUserName;
  }
}
