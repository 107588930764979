<main class="under-constuction">
  <div class="Dashboard_Overview" data-e2e="dashboard-overview">
    <h1 class="image-replacement">Toll Management</h1>
  </div>

  <section class="under-construction-content" data-e2e="construction-content">
    <h3 class="under-construction-title">Em breve.</h3>
    <img src="../../../assets/svg/undraw_building_websites_i78t.svg" alt="" />

    <div class="under-construction-box">
      <p data-e2e="under-construction-text">
        Enquanto isso, você pode acessar outros relatórios pelo menu
        <span>financeiro</span>.
      </p>
    </div>
  </section>
</main>
