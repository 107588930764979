<div class="Alert">
    <button *ngIf="options.closeButton" class="close-btn" (click)="close()"><span>×</span></button>
    <div class="AlertBody">
        <p class="title">{{ title }}</p>
        <div class="message">
            <img src="/assets/img/notification_exemple_icon.svg" />
            <p>{{ message }}</p>
        </div>
    </div>
</div>


