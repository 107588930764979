import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetNotificationQueryResultDto } from '../models/getNotificationQueryResult.dto';
import { map } from "rxjs/operators";
import { PaymentOrigin } from '@models/payment.model';
import { CacheService } from './cache.service';
import { LocalstorageService } from './localstorage.service';
import { FileInconsistenciesModalComponent } from 'src/app/shared/components/file-inconsistencies-modal/file-inconsistencies-modal.component';
import { PixPaymentModalComponent } from 'src/app/shared/components/payment/pix-payment-modal/pix-payment-modal.component';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  private base = environment.apiTMCustomer;
  /**
   * A Array with notification types that open modals.
   *
   * @class Array<any>.
   * */
  private notificationModals: Array<any> = [
    {
      notificationTypeId: 2,
      component: FileInconsistenciesModalComponent,
      paymentOriginId: null
    },
    {
      notificationTypeId: 9,
      component: PixPaymentModalComponent,
      paymentOriginId: PaymentOrigin.LooseRecharge
    },
    {
      notificationTypeId: 10,
      component: PixPaymentModalComponent,
      paymentOriginId: PaymentOrigin.TagOrder
    }
  ]
  constructor(protected http: HttpClient, private cache: CacheService, private localStorageService: LocalstorageService) { }

  getNotificationsByContractId(cacheble: boolean) {
    let cacheExpiry = 180000;
    let headers = cacheble ? new HttpHeaders({
      'cache-response': 'true'
      , 'cache-expiry': cacheExpiry.toString()
    }) : new HttpHeaders();

    return this.http.get<Array<GetNotificationQueryResultDto>>
      (`${this.base}/Notification/GetNotificationList`, { headers })
      .pipe(
        map((res) => {
          let objectResponse: Array<GetNotificationQueryResultDto> = [];
          res?.forEach(
            (element) => {
              let modal = this.notificationModals.filter(data => data.notificationTypeId == element.notificationTypeId)[0] ?
                this.notificationModals.filter(data => data.notificationTypeId == element.notificationTypeId)[0] : [];
              let modalDestination = modal.length == 0 ? false : true;
              let modalComponent = modal.length == 0 ? null : modal.component;
              let paymentOriginId = modal.paymentOriginId;
              objectResponse.push({
                ...element,
                modalDestination: modalDestination,
                modalComponent: modalComponent,
                paymentOriginId: paymentOriginId
              });
            });
          return objectResponse;
        })
      );

  }

  readNotification(id) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const apiUrl = `${this.base}/Notification/Read/${id}`;
    const result = this.http.put(apiUrl, httpOptions);
    return result;
  }

  getContractAlertFeedback(cacheble: boolean) {
    let url = `${this.base}/Notification/GetNotificationFeedback`
    let cacheExpiry = 180000;
    const cachedReq = this.cache.get(url, cacheExpiry, Number(this.localStorageService.getContractId()))

    /* exclui o cache do Alerta (Feedback) caso ele possua valor no body, para que alertas
     repetidos não sejam exibidos, e exclui o cache da notificação para que seja 
     atualizado junto com o Alerta (Feedback) */
    if (cachedReq?.body.length > 0) {
      this.cache.delete(url)
      this.cache.delete(`${this.base}/Notification/GetNotificationList`)
    }

    let headers = new HttpHeaders({
      'cache-response': 'true'
      , 'cache-expiry': cacheExpiry.toString()
    });

    if (cacheble) {
      return this.http.get<any[]>(url, { headers });
    }
    else {
      return this.http.get<any[]>(url);
    }
  }

}