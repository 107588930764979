import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { ContactUsComponent } from '../contact-us.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "fale-conosco",
      component: ContactUsComponent,
      canActivate: [authGuardCustomer],
      data: { roles: "101217", contractRequired: true },
    }
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ContactUsRoutingModule {}
