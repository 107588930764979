import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BillSummary } from "@models/old/invoices/bill-summary.model";
import { BillHeaderDto } from "../../../../bill/dto/bill-header.dto";
import { BillHierarchyBreadcrumbResultDto } from "../../../../bill/dto/bill-hierarchy-breadcrumb-result.dto";
import { BillReversalPassengersQueryArguments } from "../../../../bill/dto/bill-reversal-passengers-query.arguments";
import { BillReversalPassengersResultDto } from "../../../../bill/dto/bill-reversal-passengers-result.dto";
import { BillServicesQueryArguments } from "../../../../bill/dto/bill-services-query.arguments";
import { BillServicesResultDto } from "../../../../bill/dto/bill-services-result.dto";
import { environment } from "src/environments/environment";
import {map} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BillDetailService {
  constructor(protected http: HttpClient) {}

  getBillSummary(id: number) {
    return this.http.get<BillSummary>(
      environment.apiTMFinancial + `/api/Bill/GetBillSummary/` + id
    );
  }
  getBillHeader(id: number) {
    return this.http.get<BillHeaderDto>(
      environment.apiTMReport + `/Bill/GetBillHeader/` + id
    );
  }
  getBillReversalAndPassengers(args: BillReversalPassengersQueryArguments) {
    return this.http.get<BillReversalPassengersResultDto>(
      environment.apiTMReport + `/Bill/GetBillReversalAndPassengers`,
      {
        params: args.httpParams,
      }
    );
  }
  getBillServices(args: BillServicesQueryArguments) {
    return this.http.get<BillServicesResultDto>(
      environment.apiTMReport + `/Bill/GetBillServices`,
      {
        params: args.httpParams,
      }
    );
  }

  getHierarchyBreadcrumb() {
    return this.http.get<BillHierarchyBreadcrumbResultDto>(
      environment.apiTMVehicle + `/GroupHierarchy/GetHierarchyBreadCrumbList`
    );
  }

  getDataIntegrationServiceCsv(billId: number, contractId: number) {
    let httpParams = new HttpParams()
      .append('billId', billId)
      .append('contractId', contractId);

    return this.http
      .get(
        environment.apiTMReport + `/DataIntegrationService/GetDataIntegrationServiceCsv`,
        {
          params: httpParams,
          observe: "response",
          responseType: "blob",
        })
      .pipe(
        map((response) => {
          return this.returnsFormattedResponseData(response);
        })
      );
  }

  getTagInStockMonthlyCsv(billId: number) {
    return this.http
      .get(
        environment.apiTMFinancial + `/api/BillItem/DetailsMonthlyFeeTagInStock/${billId}`,
        {
          observe: "response",
          responseType: "blob",
        })
      .pipe(
        map((response) => {
          return this.returnsFormattedResponseData(response);
        })
      );
    }

    private returnsFormattedResponseData(response: HttpResponse<Blob>): any {
      return {
        file: new Blob([response.body], {
          type: response.headers.get("Content-Type"),
        }),
        filename: response.headers.get("filename"),
      };
  }
}
