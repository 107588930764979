<div class="login">
  <div class="BGLogin">
    <img src="assets/svg/BG-Login.png" />
  </div>
  <div class="Login_BoxForm">
    <div class="BoxForm_Content">

      <div class="BoxForm_Title">
        <img src="assets/svg/icon_login.svg">
        <p data-e2e="page-title">Primeiro acesso</p>
      </div>
      <div class="BoxForm_Form">
        <form>
          <div class="wizard wizard-3 active">
            <label data-e2e="sucesso-msg">
              <b>Senha cadastrada com sucesso.</b>
              <br />
              Para acessar a plataforma, Utilize seu
              CPF / E-mail e sua senha para acessar a
              plataforma.
            </label>

            <button data-e2e="login-btn" type="button" routerLink="/login" class="btn btn-secundary FormLogin_btn">ENTRAR</button>
          </div>
        </form>


        <div class="BoxForm_LogoRepomResponsive">
          <img src="assets/svg/footer_logo_repom.svg">
        </div>


      </div>
      <!--BoxForm_Form-->


    </div>
    <!--BoxForm_Content-->
  </div>
  <!--Login_BoxForm-->
  <!-- **************** Login | End **************** -->
</div>
