import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrentUser } from '@models/old/currentuser.model';
import { MyAccountService } from '../../../myaccount/services/myaccount.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { TelephoneValidator } from '@services/form-validators/telephone-validator.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UpdateContractRegistration, UpdateType } from 'src/app/shared/components/verification-code/models/update-token.model';
import { VerificationCodeService } from 'src/app/shared/components/verification-code/verification-code.service';

@Component({
  selector: 'app-edit-contract-phone-modal',
  templateUrl: './edit-contract-phone-modal.component.html',
  styleUrls: ['./edit-contract-phone-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditContractPhoneModalComponent implements OnInit {

  @Input() currentUser: CurrentUser;
  submitted: boolean;
  isLoading: boolean;
  step: number = 1;
  updateType = UpdateType;
  code: string;
  sub$: Subscription;

  phoneForm = new FormGroup({
    newPhone: new FormControl('55',[
      Validators.required,
      TelephoneValidator.isValidTelephone(true),
      this.samePhone()
    ]),
    personEmailAddressId: new FormControl(),
    personPhoneId: new FormControl(),
    PersonPhoneTypeId: new FormControl(),
    telephoneNumber:new FormControl(),
    countryCode: new FormControl(),
    areaCode: new FormControl(),
  });

  constructor(
    private modalService: NgbModal,
    private formValidatorMessageService: FormValidatorMessageService,
    private verificationCodeService: VerificationCodeService,
    private cdr: ChangeDetectorRef,
    private myAccountService: MyAccountService
    ) {
      this.getCode();
      setInterval(() => {
        this.cdr.detectChanges()
      }, 10);
     }

  ngOnInit(): void {
    this.phoneForm.get('personPhoneId').setValue(this.currentUser.personPhoneId)
    this.phoneForm.get('personEmailAddressId').setValue(this.currentUser.personEmailAddressId)
  }

  getCode(){
    this.sub$ = this.verificationCodeService.verificationCode$
    .subscribe((code) => {
      this.code = code;
    });
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  generateErrorMessage(formControl){
    return this.formValidatorMessageService.returnMessage(formControl);
  }

  isCellPhone(phone): boolean {
    if(phone){
      return phone.length < 13 ? false : true;
    }
    else{
      return false
    }
  }

  getPhoneMask(){
    let filter = this.phoneForm.controls['newPhone'].value;
    return this.isCellPhone(filter)
    ? '+00 (00) 00000-0000'
    : '+00 (00) 0000-00009'
  }

  samePhone() {
    return (control: AbstractControl): Validators => {

      return this.currentUser ? (
        this.samePhoneNumber()
        ? { sameTelephone: true }
        : null ) : null
    };
  }

  close() {
    this.modalService.dismissAll();
   }

   sendCode(){
    this.submitted = true;
    this.isLoading = true;
    this.phoneForm.get('telephoneNumber').setValue(this.phoneForm.value.newPhone.slice(4,this.phoneForm.value.newPhone.length))
    this.phoneForm.get('countryCode').setValue(this.phoneForm.value.newPhone.slice(0,2))
    this.phoneForm.get('areaCode').setValue(this.phoneForm.value.newPhone.slice(2,4))
    this.phoneForm.get('PersonPhoneTypeId').setValue(this.phoneForm.value.newPhone.length == 12 ? 1 : 2)
    this.verificationCodeService.postPhoneUpdateToken(this.phoneForm.value)
    .pipe(finalize(() => { this.isLoading = false; }))
    .subscribe(
      (success) => {
        this.verificationCodeService.dataToVerificationCode$.next(this.phoneForm.value)
        this.step = 2;
      },
      (error) => {
        console.error(error)
      });
   }

   confirm(){
    if(!this.code || this.code.length < 6){
      this.verificationCodeService.isValidCode$.next(false)
      return;
   }
   else{
    let updatedData: UpdateContractRegistration = {
      code:this.code,
      personPhoneTypeId:this.phoneForm.controls["PersonPhoneTypeId"].value,
      telephoneNumber:this.phoneForm.controls["telephoneNumber"].value,
      countryCode:this.phoneForm.controls["countryCode"].value,
      areaCode:this.phoneForm.controls["areaCode"].value,
      id: this.currentUser.personPhoneId
    };
    this.isLoading = true;
    this.myAccountService.putUpdatePersonPhone(updatedData)
    .pipe(finalize(() => { this.isLoading = false; }))
    .subscribe(
      (success) => {
        this.myAccountService.refreshCurrentUser$.next(undefined);
        this.step = 3;
      },
      (error) => {
        error.error.forEach(element => {
          if(element.message == "Código inválido"){
            this.verificationCodeService.isValidCode$.next(false)
           }
        });

        console.error(error)
      });
   }
   }

   samePhoneNumber(){
    let currentTelephoneNumber = this.currentUser.countryCode+this.currentUser.areaCode+this.currentUser.telephoneNumber;
    currentTelephoneNumber = currentTelephoneNumber.replace(/ /g,'')
    return this.phoneForm?.controls["newPhone"].value == currentTelephoneNumber ? true : false;
   }

}
