import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    //this.showNewHierarchyModal();
  }


  showAddUserModal() {
  /*   const initialState = { parameter: 2019 };
    this.bsModalRef = this.modalService.show(AdduserModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showUsersBlockedModal() {
   /*  const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(UsersBlockedComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showUserChangeDatailsModal() {
   /*  const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(UserChangeDetailsModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showUserChangePasswordModal() {
    /* const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(UserChangePasswordModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showUserBusinessConditionsModal() {
   /*  const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(UserBusinessConditionsModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showEditVehicleModal() {
/*     const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(AddVehicleComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showTagBlockModal() {
    /* const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(TagBlockModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showTagUnlockModal() {
   /*  const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(TagUnlockModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }


  showAddVehicleModal() {
    /* const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(AddVehicleComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showAlterAddressTagOrderModal() {
    /* const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(AlterAddressTagOrderModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }

  showContractDetailsModal() {
    /* const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(ContractDetailsModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }
  showNewHierarchyModal() {
    /* const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(NewHierarchyModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }
  showTagReplaceModalModal() {
   /*  const initialState = { parameter: 2020 };
    this.bsModalRef = this.modalService.show(TagReplaceModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close'; */
  }




}
