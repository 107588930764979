<div class="login">

  <div class="BGLogin">
    <img src="assets/svg/BG-Login.png" />
  </div>

  <div class="Login_BoxForm">
    <div class="BoxForm_Content">
      <div class="BoxForm_Title">
        <img src="assets/svg/icon_login.svg">
        <p data-e2e="title-page">Primeiro acesso</p>
      </div>
      <div class="BoxForm_Form">
        <!-- <form action="senha-cadastrada" formGroup="formRegisterPassword" > -->
        <form class="form-register-password" [formGroup]="formRegisterPassword" (ngSubmit)="onSubmit()">
          <div class="wizard wizard-1 " [ngClass]="{'active': tab==='tab1'}">
            <!-- <label>Olá {{user.fullName}}, crie uma senha de acesso.
              Recomendamos que inclua letras, números e/ou
              símbolos.</label> -->
            <div class="input-group mb-3">
              <p data-e2e="password-label" class="Form_TitleInput">SENHA</p>
              <div class="input-group-prepend Form_Prepend">
                <span class="input-group-text"><img src="assets/svg/icon_login_user.svg" class="IconInput"></span>
              </div>
              <input data-e2e="password-input" formControlName="password" type="password" id="password" class="form-control FormInput_Usuario" placeholder="" aria-label="Username"
                aria-describedby="basic-addon1">
            </div>
            <div class="input-group mb-3">
              <p data-e2e="password-confirmation-label" class="Form_TitleInput">CONFIRME SUA SENHA</p>
              <div class="input-group-prepend Form_Prepend">
                <span class="input-group-text"><img src="assets/svg/icon_login_user.svg" class="IconInput"></span>
              </div>
              <!-- <input type="hidden" formControlName="id" value="{{user.id}}"/> -->
              <input
                data-e2e="confirmation-input"
                formControlName="confirmPassword"
                id="confirmPassword"
                type="password"
                class="form-control FormInput_Usuario"
                placeholder=""
                aria-label="Username"
                aria-describedby="basic-addon1">
            </div>

            <div class="password-validated">
              <app-validation-password [password]="passwordInputed"></app-validation-password>
            </div>

           <app-borderless-button
              [description]="'CADASTRAR SENHA'"
              [isBtnWithBorder]="true"
              (emitterEvtClick)="onClickMe()"
              [data-e2e]="'register-btn'"
              [id-html]="'registerPassword'"
              [customWidthSize]="'20rem'">
            </app-borderless-button>
          </div>

          <div class="wizard wizard-2 " [ngClass]="{'active': tab==='tab2'}">
            <label data-e2e="token-label">Para efetivar o cadastro da senha, confirme o token de segurança.</label>
            <div class="row">
              <div class="col-lg-12">
                <i class="ico ico-check-w2"></i>
                <!-- <p>E-mail <span>{{user.emailAddressMasked}}</span></p> -->
              </div>
              <div class="col-lg-6">
                <p class="text" data-e2e="code-sent-label">Código enviado.</p>
                <a [routerLink]="" (click)="onClickMe()" data-e2e="send-again-btn">Enviar novamente</a>
                <!-- <a href="" (click)="onClickMe()">Enviar novamente</a> -->
              </div>
              <div class="col-lg-6">
                <div class="form-group form-icon">
                  <label for="formNumberOrderInput" data-e2e="insert-label">INSIRA</label>
                  <input
                    id="formNumberOrderInput"
                    data-e2e="code-input"
                    formControlName="code"
                    type="text"
                    class="form-control ico-insert-text">
                </div>
              </div>
              <div class="col-lg-12">
                <button
                  id="confirmCode"
                  data-e2e="code-confirmation-btn"
                  type="submit"
                  class="btn btn-secundary FormLogin_btn">
                  CONFIRMAR CÓDIGO
                </button>
              </div>
            </div>
          </div>
        </form>

        <div class="FormLogin_Divider">
          <p>OU</p>
        </div>

        <div class="Login_ForgotPassword">
          <a data-e2e="back-to-login-btn" href="login" class="Login_btn_ForgotPassword">
            Voltar para o Login
          </a>
        </div>
        <div class="BoxForm_LogoRepomResponsive">
          <img src="assets/svg/footer_logo_repom.svg">
        </div>
      </div>
      <!--BoxForm_Form-->
    </div>
    <!--BoxForm_Content-->
  </div>
  <!--Login_BoxForm-->
  <!-- **************** Login | End **************** -->
</div>
