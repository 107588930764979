import { HttpParams } from '@angular/common/http';

export class GetContractHierarchiesQueryArguments {
  search?:string;
  startDate:Date;
  endDate:Date;
  hierarchyDescription:string;

  get httpParans() {

    let httpParams = new HttpParams()

    if(this.search){
      httpParams = httpParams.append('search', this.search?.toString());
    }

    if(this.hierarchyDescription){
      httpParams = httpParams.append('hierarchyDescription', this.hierarchyDescription?.toString());
    }

    // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC.
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.startDate){
      httpParams = httpParams.append('startDate', this.startDate?.toISOString());
    }

    // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC.
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.endDate){
      httpParams = httpParams.append('endDate', this.endDate?.toISOString());
    }


    return httpParams;

  };
}


