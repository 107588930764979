<!-- Modal userBlockModal -->
<div class="modal-content ">

  <div class="step-1" id="confirmacao" [hidden]="!stepconfirmacao">
    <div class="modal-header">
      <div class="box-title">
        <span>Substituir TAG</span>

      </div>
      <button class="close" type="button" (click)="dismissModal()"><span aria-hidden="true">×</span></button>

      <div class="row-box-yellow">
        <span class="box-yellow mt-6">
          <b>A</b> TAG atual será inutilizada após a substituição, não podendo ser utilizada novamente.
          A ativação da nova TAG, em alguns estabelecimentos, podem levar até 6 horas para ser efetivada.
        </span>
      </div>
    </div>
    <div class="modal-body">
      <div class="row row-modal-collapse">
        <div class="col-lg-5 col-vehicle-tag">
          <div class="row">
            <div class="col-lg-12">
              <b class="subtitle">Dados do vínculo atual</b>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <p>Veículo</p>
              <span></span>
              <b class="font-size-75rem"><i class="ico ico-white ico-menu-open tertiary"></i>{{vehicle.licensePlate}}</b>
            </div>
            <div class="col-lg-6">
              <p>TAG</p>
              <span></span>
              <b class="font-size-75rem">{{vehicle.serialTag}}</b>

            </div>
          </div>
        </div>
        <div class="col-lg-7 col-box-collapse font10 height-replace">
          <app-tags-disponiveis [elementEmpty]="isEmpty" (changeTag)="onchangeTag($event)"></app-tags-disponiveis>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray">

      <app-borderless-button
        [description]="'Cancelar'"
        [id-html]="'btn-cancel'"
        [data-e2e]="'btn-cancel'"
        [isBtnWithBorder]="true"
        [buttonColor]="'secundary'"
        [isDisable]="onSaving"
        (emitterEvtClick)="dismissModal()">
      </app-borderless-button>

      <app-borderless-button
        [description]="'Substituir Tag'"
        [id-html]="'btn-replace'"
        [data-e2e]="'btn-replace'"
        [isBtnWithBorder]="true"
        [buttonColor]="'primary'"
        [classIconLeft]="'ico-replace'"
        [isDisable]="onSaving || tagselected == null"
        (emitterEvtClick)="saveContractVehicleTagReplacement()"
      >
      </app-borderless-button>

    </div>
  </div>

  <div class="step-2" [hidden]="stepconfirmacao" *ngIf="replacementTagUserRegister">
    <div class="modal-header">
      <div class="box-title">
        <span>Substituir TAG</span>
      </div>
      <button type="button" class="close" (click)="dismissModal()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row-box-yellow">
        <span class="box-yellow">
          <b>A</b> TAG atual será inutilizada após a substituição, não podendo ser utilizada novamente.
          A ativação da nova TAG, em alguns estabelecimentos, podem levar até 6 horas para ser efetivada.
        </span>
      </div>
    </div>
    <div class="modal-body modal-body-custom">
      <div class="row">
        <div class="col-lg-12">
        </div>
      </div>
      <div class="row row-modal-collapse mt-7rem">
        <div class="w-100 d-flex justify-content-center">
          <div class="col-lg-3">
            <img class="w-100" src="../../../assets/img/bg-place.svg" />
          </div>
          <div class="col-lg-6 tag-replace">
            <h3>TAG SUBSTITUÍDA</h3>
            <div>
              <div class="row mb-1">
                <div class="col-lg-8">
                  <strong>PLACA:</strong>
                </div>
                <div class="col-lg-4">
                  <span>{{replacementTagUserRegister.licensePlate | mask: 'AAA-AAAA' }}</span>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-lg-8">
                  <strong>TAG ativa atualmente:</strong>
                </div>
                <div class="col-lg-4">
                  {{replacementTagUserRegister.newTagSerial}}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-lg-8">
                  <strong>Cancelada por substituição:</strong>
                </div>
                <div class="col-lg-4">
                  {{replacementTagUserRegister.oldTagSerial}}
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-12 text-center">
                    {{replacementTagUserRegister?.changeDate | date: 'dd/MM/yyyy - HH:mm'}} por {{replacementTagUserRegister?.changeUserFullName}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray">

      <app-borderless-button
        [description]="'Fechar'"
        [id-html]="'btn-close'"
        [data-e2e]="'btn-close'"
        [isBtnWithBorder]="true"
        [buttonColor]="'secundary'"
        [isDisable]="onSaving"
        (emitterEvtClick)="dismissModal()">
      </app-borderless-button>

    </div>
  </div>
</div>
