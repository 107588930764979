import {animate, state, style, transition, trigger} from "@angular/animations";

export const progressBarAnimation: () => any = () => {
  return trigger("ProgressBar", [
    transition("closed => open", [animate("0.7s")]),
    transition("* => closed", [animate("0.7s")]),
    transition("* => open", [animate("0.7s")]),
    transition("open <=> closed", [animate("0.7s")]),
    transition("* => open", [animate("0.7s", style({opacity: "*"}))]),
    transition("* => *", [animate("0.7s")]),
  ]);
}

export const slideUpDownAnimation: () => any = () => {
  const isUserProfileBackOffice: boolean = localStorage.getItem('Profile') === '3';
  let _height: number = 165;

  if (isUserProfileBackOffice) {
    _height = 110;
  }

  return trigger("OpenBox", [
    state(
      "open",
      style({
        height: `${_height}px`,
        padding: "37px 45px 20px",
      })
    ),
    state(
      "closed",
      style({
        height: "0px",
        padding: "0px 45px 0px",
      })
    ),
    transition("closed => open", [animate("0.7s")]),
    transition("* => closed", [animate("0.7s")]),
    transition("* => open", [animate("0.7s")]),
    transition("open <=> closed", [animate("0.7s")]),
    transition("* => open", [animate("0.7s", style({opacity: "*"}))]),
    transition("* => *", [animate("0.7s")]),
  ])
}
