import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-pop-up',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './pop-up.component.html',
  styleUrl: './pop-up.component.scss'
})
export class PopUpComponent implements OnInit {

  hasVerificaImg: boolean = false;
  hasVerificaTitle: boolean = false;
  hasVerificaMessage: boolean = false;
  hasVerificaButton: boolean = false;

  @Output() buttonClicked = new EventEmitter<void>();


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PopUpComponent>,
  ) {
    this.verificaValoresData();
  }

  ngOnInit(): void {
  }


  fecharDialog(): void {
    this.dialogRef.close();
  }

  verificaValoresData() {
    this.hasVerificaImg = this.data.imageUrl !== "" && !!this.data.imageUrl;
    this.hasVerificaTitle = this.data.title !== "" && !!this.data.title;
    this.hasVerificaMessage = this.data.message !== "" && !!this.data.message;
    this.hasVerificaButton = this.data.button !== "" && !!this.data.button;
  }

  onButtonClick(): void {
    this.buttonClicked.emit();
  }

}
