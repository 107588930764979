import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VehiclesAvailableQueryResult } from '@models/old/vehicle/vehiclesAvailableQueryResult.model';
import { VehicleStatistics, VehicleStats } from '@models/statistics.model';
import { environment } from 'src/environments/environment';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  private _base = environment.apiTMVehicle;

  constructor(
    protected _http: HttpClient,
    protected _logger: LogService,
  ) { }

  getVehicleAvailableByContractId(): Observable<VehiclesAvailableQueryResult[]> {
    return this._http.get<VehiclesAvailableQueryResult[]>(`${this._base}/Vehicle/GetVehiclesAvailable`);
  }

  getStatistic(): Observable<VehicleStatistics> {
    return this._http.get<VehicleStatistics>(`${this._base}/Vehicle/GetVehicleStatistics`);
  }

  getVehiclesStats(): Observable<VehicleStats[]> {
    return this.getStatistic().pipe(
      map((vehicles) => {
        const newRes = Object.entries(vehicles).map(([key, value]) => ({
          key,
          value,
        }));
        const status = {
          vehiclesRegistered: { title: "Veículos cadastrados", color: "" },
          tagsToLinking: { title: "", color: "" },
          vehiclesWithActiveTag: { title: "Com tag ativa", color: "green" },
          vehiclesWithLockedTag: { title: "Com tag bloqueada", color: "red" },
          excludedVehicles: { title: "", color: "" },
          vehiclesWithoutTagRegistered: {
            title: "Sem tag vinculada",
            color: "cyan",
          },
        };

        let newVehicles: VehicleStats[] = [];

        newRes.forEach((vehicle) => {
          newVehicles.push(
            new VehicleStats(
              status[vehicle.key].title,
              vehicle.value,
              status[vehicle.key].color,
              vehicle.key
            )
          );
        });

        return newVehicles.filter(
          (stats) =>
            stats.category !== "tagsToLinking" &&
            stats.category !== "excludedVehicles"
        );
      })
    );
  }



}
