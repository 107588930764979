<div class="content-page">
  <div class="container-fluid page-myaccount hierarchyPage">
    <div class="row">
      <div class="col-12 d-flex">

          <div class="col">
            <app-page-title [title]="'/ Hierarquia'"></app-page-title>
          </div>

          <div class="col-4  d-flex justify-content-end" >
            <button
              data-e2e="new-hierarchy-btn"
              id="newHierarchyButton"
              buttonBlock
              [ContractBlock]="[3]"
              *ngIf="emptyListHierarchy == true"
              class="btn btn-repom btn-repom-user"
              data-target="#userIncludeModal"
              data-toggle="modal"
              type="button"
              [popover]="popoverAddNewHierarchy"
              [outsideClick]="true"
              placement="left"
              >
              <i class="ico ico-add "></i>nova hierarquia
              <span class="btn-animation"></span>
            </button>
            <ng-template placement="left" [outsideClick]="true" #popoverAddNewHierarchy>
              <div
              class="w-100 d-flex justify-content-between BoxAddNewHierarchy"
            >
              <div class="hierarchy-input">
                <img
                  src="../../../../../../assets/img/hierarchy_select.svg"
                />
                <input
                  data-e2e="new-hierarchy-input"
                  class="new-hierarchy-input"
                  maxlength="20"
                  #firstHierarchtName
                  type="text"
                />
                <div class="label-hierarchy" data-e2e="group-level-msg">
                  Adicionar primeiro nível
                </div>
              </div>

              <div class="content-button">
                <button
                  type="button"
                  class="btn btn-primary"
                  id="new-hierarchy-btn"
                  (click)="onSaveHierarchy(0)"
                  data-e2e="add-to-level-btn"
                >
                <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
                  <img
                    src="../../../../../../assets/img/ico-add-b.svg"
                    class="button-icon"
                  />
                  Adicionar primeiro nível
                </button>
              </div>
            </div>
            </ng-template>
          </div>

      </div>

      <div class="col-12">
        <div [ngStyle]="{'min-height': containerheight }" class="container-repom">
          <div class="box-title">
            <span data-e2e="card-title">GRUPOS DISPONÍVEIS</span>
          </div>

          <div class="container-loading" *ngIf="isLoading">
            <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
          </div>

          <div class="box-body box-empty" *ngIf="emptyListHierarchy == true && !isLoading">
            <div class="row">
              <div class="col-lg-12 text-center font-weight-bold">
                <p data-e2e="empty-hierarchy-search-msg">Ainda não foi criada uma hierarquia para esse contrato</p>
              </div>
              <div class="col-lg-12 d-flex justify-content-center">
                <img src="../../../assets/img/bg-hierarchy.svg" alt="Imagem ilustrativa da Hierarquia"
                  class="bg-hierarchy">
              </div>
            </div>
          </div>

          <div class="box-body box-hierarchy" *ngIf="emptyListHierarchy == false && !isLoading">
            <div class="row">
              <div class="col-lg-12">
                <div class="row titleHierarchy">
                  <div class="col-lg-auto">
                    <p data-e2e="choose-group-msg">Escolha um grupo para abrir na sua hierarquia</p>
                  </div>

                  <div class="col-lg-auto">
                    <form [formGroup]="formHierarchySelect" data-e2e="hierarchy-form">

                    <app-outlined-select
                      data-e2e="hierarchy-btn"
                      [values]="hierarchySelectList"
                      [title]="'Escolha um grupo'"
                      [hasLabel]="true"
                      (valueChange)="onChangeHierarchySelect($event)"
                      [disableRipple]="true"
                      customWidthSize="400px"
                      dataE2e="'hierarchy-select'">
                    </app-outlined-select>

                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <app-cdk-tree [tree]="nodeItems"></app-cdk-tree>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
