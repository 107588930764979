<div class="container-loading"
  *ngIf="isLoading"
  id="container-loader-page"
  data-e2e="container-loader-page"
>
  <app-loader
    id="loader-page"
    data-e2e="loader-page"
    [isLoading]="isLoading"
    message="{{ messageLoader }}"
  ></app-loader>
</div>

<main class="UserConsultantViewComponent" *ngIf="!isLoading">
  <h1 class="title">
    Consultores de relacionamento
  </h1>

  <!-- Actions -->
  <section class="bloc-actions">
    <form class="bloc-form" [formGroup]="userConsultantSearchForm">
      <div class="toll-input-box">
        <img class="toll-input-box__img-before" src="/assets/img/icon-search-g.svg" alt="Ícone lupa">
        <input
          class="toll-input-box__input"
          [class.is-invalid]="
            (userConsultantSearchForm.controls.searchField.invalid
              && userConsultantSearchForm.controls.searchField.touched)
            || !haveResults
          "
          id="input-search-field"
          data-e2e="input-search-field"
          formControlName="searchField"
          type="text"
          placeholder="Digite o nome do consultor que deseja buscar"
          minlength="1"
          required
          (keyup)="onSearchKeyUp($event)"
          (blur)="onBlur()"
        />
      </div>

      <div class="toll-select-box">

          <app-outlined-select
          [title]="'Quantidade de linhas'" [values]="pageSize"
          [beforeTextValue]="'Exibir '"
          [afterTextValue]="' linhas'"
          (valueChange)="onPageSizeChange($event)"
          disableRipple="true"
          [customWidthSize]="'350px'"
          >

          </app-outlined-select>

      </div>

    </form>
    <div class="download-button">
      <app-borderless-button [isDisable]="!haveResults" [description]="' Download CSV'"
      [classIconLeft]="'ico-download'" [id-html]="'export-btn'" [data-e2e]="'export-btn'"
      [isBtnWithBorder]="true"
      (emitterEvtClick)="DownloadCsv()">
    </app-borderless-button>
    </div>
  </section>

  <app-vmessage
    *ngIf="
      (userConsultantSearchForm.controls.searchField.invalid
        && userConsultantSearchForm.controls.searchField.touched)
      || !haveResults
    "
    id="msg-error-input-search-field"
    errorMessage="{{ 'Não encontramos nenhum usuário. Por favor, verifique se digitou corretamente ou tente novamente com outra informação válida.' }}"
  >
  </app-vmessage>

  <!-- Table -->
  <section class="bloc-table">
    <table class="table">
      <thead>
        <tr class="thead-text">
          <!-- User name -->
          <th [ngClass]="bindOrderByClass(tableHeaders.FULLNAME)">
            <div class="btn-order-table">
              {{ 'Consultor' }}
            </div>
            <div class="round-order"
              id="btn-order-user-name"
              data-e2e="btn-order-user-name"
              (click)="onOrderByChange(tableHeaders.FULLNAME)"
            >
              <img src="assets/svg/order-table.svg" alt="Ordenação"/>
            </div>
          </th>

          <!-- User email -->
          <th [ngClass]="bindOrderByClass(tableHeaders.EMAIL_ADDRESS)">
            <div class="btn-order-table">
              {{ 'E-mail' }}
            </div>
            <div class="round-order"
              id="btn-order-user-email"
              data-e2e="btn-order-user-email"
              (click)="onOrderByChange(tableHeaders.EMAIL_ADDRESS)"
            >
              <img src="assets/svg/order-table.svg" alt="Ordenação"/>
            </div>
          </th>

          <!-- User status -->
          <th [ngClass]="bindOrderByClass(tableHeaders.STATUS_USERID)" class="th-status">
            <div class="btn-order-table">
              {{ 'Status' }}
            </div>
            <div class="round-order"
              id="btn-order-user-status"
              data-e2e="btn-order-user-status"
              (click)="onOrderByChange(tableHeaders.STATUS_USERID)"
            >
              <img src="assets/svg/order-table.svg" alt="Ordenação"/>
            </div>
          </th>

          <!-- User registration date -->
          <th [ngClass]="bindOrderByClass(tableHeaders.REGISTRATION_DATE)">
            <div class="btn-order-table">
              {{ 'Criação do usuário' }}
            </div>
            <div class="round-order"
              id="btn-order-user-registration-date"
              data-e2e="btn-order-user-registration-date"
              (click)="onOrderByChange(tableHeaders.REGISTRATION_DATE)"
            >
              <img src="assets/svg/order-table.svg" alt="Ordenação"/>
            </div>
          </th>

           <!-- User last access date -->
           <th [ngClass]="bindOrderByClass(tableHeaders.LAST_ACCESS)">
            <div class="btn-order-table">
              {{ 'Último acesso' }}
            </div>
            <div class="round-order"
              id="btn-order-user-last-access-date"
              data-e2e="btn-order-user-last-access-date"
              (click)="onOrderByChange(tableHeaders.LAST_ACCESS)"
            >
              <img src="assets/svg/order-table.svg" alt="Ordenação"/>
            </div>
          </th>

          <!-- Actions -->
          <th class="table-header--noafter"></th>
        </tr>
      </thead>

      <tbody *ngIf="!isLoadingTableData && usersDetailsList.length > 0">
        <tr *ngFor="let user of usersDetailsList">
          <!-- Data user name -->
          <td
            id="table-data-user-name"
            data-e2e="table-data-user-name"
          >{{ user.fullName }}</td>

          <!-- Data user email -->
          <td
            id="table-data-user-email"
            data-e2e="table-data-user-email"
          >{{ user.emailAddress }}</td>

          <!-- Data user status -->
          <td
            id="table-data-user-status"
            data-e2e="table-data-user-status"
            [ngSwitch]="user.statusUserId"
          >
            <div class="status-user-type status-user-type--waiting" *ngSwitchCase="1">
              <span class="status-user-type__text">{{ 'Aguardando Ativação' }}</span>
            </div>

            <div class="status-user-type status-user-type--block" *ngSwitchCase="2">
              <span class="status-user-type__text">{{ 'Bloqueio Temporário' }}</span>
            </div>

            <div class="status-user-type status-user-type--active" *ngSwitchCase="3">
              <span class="status-user-type__text">{{ 'Ativo' }}</span>
            </div>

            <div class="status-user-type status-user-type--default" *ngSwitchDefault>
              <span class="status-user-type__text">{{ '-' }}</span>
            </div>
          </td>

          <!-- Data user registration date -->
          <td
            id="table-data-user-registration-date"
            data-e2e="table-data-user-registration-date"
          >{{ user.registrationDate | date: 'dd/MM/yyyy - HH:mm':'UTC' }}</td>

          <!-- Data user last access date -->
          <td
            id="table-data-user-last-access-date"
            data-e2e="table-data-user-last-access-date"
          >{{ user.lastAccess | date: 'dd/MM/yyyy - HH:mm' }}</td>

          <!-- Buttons actions -->
          <td class="data-actions">
            <button
              class="btn-actions"
              mat-icon-button
              id="btn-actions-edit-{{ user.userId }}"
              [attr.data-e2e]="'btn-actions-edit-'+ user.userId"
              aria-label="Botão de ações"
              (click)="navigateToUpdatePage(user.userId)"
              ngbTooltip="Editar"
            >
              <img src="assets/svg/edit.svg" alt="Ícone de lápis">
            </button>
            <button
              class="btn-actions"
              mat-icon-button
              id="btn-actions-delete-{{ user.userId }}"
              [attr.data-e2e]="'btn-actions-delete-'+ user.userId"
              aria-label="Botão de ações"
              (click)="openDeleteModal(user)"
              ngbTooltip="Excluir"
            >
              <img src="assets/img/lixeira_azul.svg" alt="Ícone de lixeira">
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="container-loading"
      *ngIf="isLoadingTableData"
      id="container-loader-page"
      data-e2e="container-loader-page"
    >
      <app-loader
        id="loader-page"
        data-e2e="loader-page"
        [isLoading]="isLoadingTableData"
        message="{{ messageLoader }}"
      ></app-loader>
    </div>

    <div
      class="pagination-custom"
      *ngIf="totalItems > userQueryArgs.pageSize && !isLoadingTableData"
    >
      <ngb-pagination
        (pageChange)="onPaginationChange($event)"
        [pageSize]="userQueryArgs.pageSize"
        [collectionSize]="totalItems"
        [(page)]="userQueryArgs.page"
        [maxSize]="5"
        [rotate]="true"
        [boundaryLinks]="true"
        [ellipses]="false"
        display="dynamic"
      >
      </ngb-pagination>
    </div>
  </section>
</main>
