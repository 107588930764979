import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserConsultantCreateUpdateComponent } from '../user-consultant-create-update.component';
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";

const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.CONSULTANT_REGISTER,
        component: UserConsultantCreateUpdateComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Cadastrar Consultor'),
          roles: '100993',
          contractRequired: false,
        },
      },
      {
        path: RoutesPaths.CONSULTANT_UPDATE + '/:id',
        component: UserConsultantCreateUpdateComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Editar Consultor'),
          roles: '100993',
          contractRequired: false,
        },
      }
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserConsultantCreateUpdateRoutingModule { }
