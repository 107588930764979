import {NgModule} from "@angular/core";
import {FooterComponent} from "@modules/commom/footer/footer.component";
import {provideNgxMask} from "ngx-mask";
import {NgClass} from "@angular/common";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";


@NgModule({
  imports: [
    NgClass,
    NgbTooltip
  ],
  declarations: [
    FooterComponent,
  ],
  providers: [provideNgxMask()],
  exports: [
    FooterComponent
  ]
})

export class FooterModule {}
