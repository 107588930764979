import { Component, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import { Tag } from "src/app/core/models/tag.model";
import { OrderStatus } from "src/app/core/models/order.model";
import { TagService } from "src/app/core/services/tag.service";
import { NotifyService } from "@services/notify.service";
import { TagReceiptModalComponent } from "src/app/shared/components/tag/tag-receipt-modal/tag-receipt-modal.component";

@Component({
  selector: "app-add-tag-modal",
  templateUrl: "./add-tag-modal.component.html",
  styleUrls: ["./add-tag-modal.component.scss"],
})
export class AddTagModalComponent {
  orderStatus = OrderStatus;
  serial: string;
  message: string;
  event: EventEmitter<any> = new EventEmitter();
  listTagAvailable: Tag;
  onSaving = false;
  error;
  orderId: number;

  constructor(
    public modalRef: BsModalRef,
    private _modalService: BsModalService,
    private _service: TagService,
    private _notifyService: NotifyService
  ) {}

  onCallOrderConfirmationModal(orderID: number): void {
    const modalRef = this._modalService.show(TagReceiptModalComponent);
    modalRef.content.tagRequestId = orderID;
  }

  saveNewTag(form: NgForm) {
    this.onSaving = true;

    this._service
      .saveTag(form.value)
      .pipe(
        finalize(() => {
          this.onSaving = false;
        })
      )
      .subscribe(
        (success) => {
          this._notifyService.showSuccess(
            "Atenção!",
            "Nova tag salva com sucesso!"
          );
          this.event.emit(success);
          this.modalRef.hide();
        },
        (e) => {
          console.error(e)
          this.error = e.error;
          this.orderId = e.error.data?.tagResquestId;
        }
      );
  }
}
