export const MenuBackofficePaths = {
  //Raiz
  BACKOFFICE: 'backoffice',

  //Menus
  ATTENDANCE: 'atendimento',
  LOGISTIC: 'logistica',
  FINANCIAL: 'financeiro',
  CONTRACT_MANAGEMENT: 'gestao-contratos',
  ACCESS_MANAGEMENT: 'gestao-acessos',
};

export const RoutesPaths = {
  /*
  ---------------
    BACKOFFICE
  ---------------
  */
  //Menu principal
  MAIN_MENU_BACKOFFICE: 'menu-inicial',

  //Atendimento
  SELECT_PROFILE: MenuBackofficePaths.ATTENDANCE + '/acessar-contrato',

  //Logistica
  REPORTS:        'logistica/estoque-logistica',
  REQUEST:        'logistica/pedidos-tag',
  REQUEST_DETAIL: 'logistica/detalhe-pedido',

  //Financeiro
  PARTNERS_TRANSFER_CONCILIATION:   'financeiro/conciliacao-repasse-parceiros',
  TRANSACTION_CONCILIATION:         'financeiro/conciliacao-transacao',
  CHARGEBACK_REPORT:                'financeiro/chargeback',
  FINANCIAL_SALES_REPORT:           'financeiro/transacoes-cartao-de-credito',
  HOLIDAYS_CALENDAR:                'financeiro/calendario-de-feriados',
  INSERT_HOLIDAYS:                  'financeiro/calendario-de-feriados/inserir-feriado',
  TRIP_EXPIRATED:                   'financeiro/viagens-expiradas',

  //Gestão de contratos
  CONTRATCTUAL_ALTERATION:                    'gestao-contratos/alteracao-contratual',
  SELECT_PROFILE_FINANCIAL_BLOCKING_POS_PAID: 'gestao-contratos/bloqueio-financeiro-pospago/acessar-contrato',
  FINANCIAL_BLOCKING_UNBLOCKING:              'gestao-contratos/bloqueio-desbloqueio-financeiro',

  //Gestão de acessos
  CONSULTANT_REGISTER:  'gestao-acessos/cadastrar-consultor',
  CONSULTANT_UPDATE:    'gestao-acessos/editar-consultor',
  CONSULTANT_VIEW:      'gestao-acessos/visualizar-consultor',

  DASHBOARD: 'pagina-inicial',

  CONTRACT: 'contrato',
};
