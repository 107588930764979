import { Component, Input } from "@angular/core";
import { Filters } from "@models/filter.model";

import { VehicleStats } from "@models/statistics.model";
import { VehicleService } from "@services/vehicle.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-fleet-stats",
  templateUrl: "./fleet-stats.component.html",
  styleUrls: ["./fleet-stats.component.scss"],
})
export class FleetStatsComponent {
  @Input() stats$: Observable<VehicleStats[]> = this._service.getVehiclesStats();
  filters: Filters = {
    myFleet: true
  }

  constructor(private _service: VehicleService) {}
}
