import {Component, inject, Input} from '@angular/core';
import {
  FinancialBlockModalComponent
} from "@modules/customer/select-contracts/components/financial-block-modal/financial-block-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-page-title',
  standalone: false,
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.scss'
})
export class PageTitleComponent {

  @Input() title!: string;
  @Input() contractBlock!: number;
  @Input() contract!: any;
  @Input('data-e2e') dataE2E: string = 'page-title';

  private _modal: NgbModal;

  constructor() {
    this._modal = inject(NgbModal);
  }

  showFinancialBlockModal(): void {
    const modalRef = this._modal.open(FinancialBlockModalComponent);
    modalRef.componentInstance.contract = this.contract;
  }
}
