export interface filterVehiclesDto {
  vehiclesRegistered: number;
  vehiclesWithoutTagRegistered: number;
  percentageVehiclesWithoutTagRegistered: number;
  vehiclesWithActiveTag: number;
  percentageVehiclesWithActiveTag: number;
  vehiclesWithLockedTag: number;
  percentageVehiclesWithLockedTag: number;
  excludedVehicles: number;
  isSelected: boolean;
  key: any;
}

export enum filtersVehicles {
  vehiclesRegistered = 1,
  vehiclesWithoutTagRegistered = 2,
  vehiclesWithActiveTag = 3,
  vehiclesWithLockedTag = 4,
  excludedVehicles = 5,
}
