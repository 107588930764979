<card-model>
  <ng-container *ngIf="expediture$ | async as expediture">
    <header class="card-model-header">
      <h1>Evolutivo de consumo de frota</h1>
      <p>Acompanhe os principais indicadores de consumo da sua frota</p>
    </header>

    <section class="card-model-container">
      <div class="action-center">

        <app-outlined-select [title]="'Período'" [values]="[3]"
                             [beforeTextValue]="'Últimos '" [afterTextValue]="' meses'" [customWidthSize]="'250px'"
                             [disableRipple]="true">
        </app-outlined-select>

        <app-borderless-button [description]="'Download CSV'"
                               [classIconRight]="'ico-download'"
                               [id-html]="'id-download-csv'"
                               [data-e2e]="'id-download-csv'"
                               [service]="'expediture'"
                               [filters]="filters"
                               [buttonColor]="'tertiary'"
                               [isBtnWithBorder]="true">
        </app-borderless-button>
      </div>

      <charts
        type="line"
        [data]="expediture?.chart"
        [options]="options"
        *ngIf="expediture?.chart.hasData"
      ></charts>

      <table class="expediture-table" *ngIf="expediture?.table as table">
        <thead>
          <tr>
            <th>{{ "Indicadores" | uppercase }}</th>
            <th *ngFor="let header of table?.labels">
              {{ header | uppercase }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of table?.datasets">
            <td>{{ row.label }}</td>
            <td *ngFor="let value of row.data">
              <span *ngIf="row.type === 'number'">{{
                value ? value : "-"
              }}</span>
              <span *ngIf="row.type === 'currency'">{{
                value
                  ? (value | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR")
                  : "-"
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </ng-container>
</card-model>
