import { HttpParams } from '@angular/common/http';

export class GetAllActiveOrdersQueryArguments {
    startDate: Date;
    endDate: Date;
    tagRequestId: string;
    tagRequestStatusTypeId: number;
 
    get httpParams() {
        let httpParams = new HttpParams();

        // if (this.tagRequestId)
        //     httpParams = httpParams.set('tagRequestId', this.tagRequestId.toString())

        if (this.startDate) {
            httpParams = httpParams.set('startDate', this.startDate.toString());
        }

        if (this.endDate) {
            httpParams = httpParams.set('endDate', this.endDate.toString());
        }


        [1,3,4,5,8,9,11,12].forEach((element) => httpParams = httpParams.append("tagRequestStatusTypeId", element.toString()));


        return httpParams;
    }
}