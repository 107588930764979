import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RequestComponent } from '../request.component';
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {DetailorderComponent} from "@modules/customer/myorder/components/detailorder/detailorder.component";

const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: 'logistica/pedidos-tag',
        component: RequestComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Pedidos de tag'),
          roles: '100993',
          contractRequired: false,
        },
      },
    ],
    false,
  ),
  RoutesService.childRoutes([
    {
      path: 'logistica/pedidos-tag/detalhe-pedido/:id',
      component: DetailorderComponent,
      canActivate: [authBackOfficeGuard],
      data: {
        // title: extract('Detalhe Pedido'),
        roles: '100994,100995,100996',
        contractRequired: true,
      },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class RequestRoutingModule {}
