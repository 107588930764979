import { Component, OnInit, EventEmitter, TemplateRef, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PostalcodeService } from 'src/app/core/services/postalcode.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  switchMap,
  filter,
  distinctUntilChanged,
} from "rxjs/operators";
import { NotifyService } from '@services/notify.service';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { PersonAddress } from '@models/old/tagrequestqueryresult/personAddress.model';
import { ZipCodeValidator } from '@services/form-validators/zip-code-validator.service';
import { AddressEvent } from '@models/old/address-event.model';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddressFormComponent implements OnInit {

  public newAddress: PersonAddress;
  @Output() event = new EventEmitter();
  submitted:boolean = false;
  @Input() AddressObject:PersonAddress;
  @Input() PreviousAddressObject:PersonAddress;
  @Input() personId:number;
  AddressForm: FormGroup;
  change: boolean = false;
  defaultObjectAddress:PersonAddress;

  constructor(
    private postalcodeservice: PostalcodeService,
    protected notifyService: NotifyService,
    private formValidatorMessageService: FormValidatorMessageService,
    private cdr: ChangeDetectorRef
  ) {
    setInterval(() => {
      this.cdr.detectChanges()
    }, 10);
  }

  getObjectAddress(ActualPersonAddress,personId){
    if(ActualPersonAddress){
     return {
        personAddressTypeId: 1,
        personId: personId,
        addressTypeId: ActualPersonAddress.addressTypeId,
        id: ActualPersonAddress.id,
        zipCode: ActualPersonAddress.zipCode,
        streetAddress: ActualPersonAddress.streetAddress,
        number: ActualPersonAddress.number,
        streetAddressLine2: ActualPersonAddress.streetAddressLine2,
        landmark: ActualPersonAddress.landmark,
        district: ActualPersonAddress.district,
        city: ActualPersonAddress.city,
        country: 'Brasil',
        mailbox: ActualPersonAddress.mailbox,
        principalFlg: ActualPersonAddress.principalFlg,
        federatedUnit: ActualPersonAddress.federatedUnit      
      };
    }
    else{
     return this.defaultObjectAddress;
    }
  }

  initForm(){
    this.newAddress = new PersonAddress()
    this.newAddress = this.getObjectAddress(this.AddressObject,this.personId)

    this.AddressForm = new FormGroup({
      id: new FormControl(this.newAddress?.id),
      personId: new FormControl(this.newAddress?.personId),
      addressTypeId: new FormControl(),
      personAddressTypeId: new FormControl(),
      zipCode: new FormControl(this.newAddress?.zipCode,[
        Validators.required,
        ZipCodeValidator.isValidZipCode()
      ]),
      streetAddress: new FormControl(this.newAddress?.streetAddress),
      number: new FormControl(this.newAddress?.number),
      district: new FormControl(this.newAddress?.district),
      city: new FormControl(this.newAddress?.city,[
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
      ]),
      federatedUnit: new FormControl(this.newAddress?.federatedUnit,[
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2)
      ]),
      country: new FormControl(this.newAddress?.country),
      streetAddressLine2: new FormControl(this.newAddress?.streetAddressLine2),
      landmark: new FormControl(this.newAddress?.landmark), 
      mailbox: new FormControl(this.newAddress?.mailbox),
      principalFlg: new FormControl(this.newAddress?.principalFlg),
    }); 

    this.fillFields(this.newAddress);
    this.getCepChanges();
    this.setValidatorsByAddressTypeId(this.newAddress.addressTypeId);
    this.verifyFormChanges();
  }
  

  verifyFormChanges(){
    this.AddressForm.valueChanges.subscribe(x => {
        let event = new AddressEvent;
        event.data = x;
        event.disableButton = this.disableButton(x);
        this.event.emit(event)
    })
  }

  disableButton(ObjectChanged){  
    let change: Boolean;
      if(
        this.PreviousAddressObject &&
        ObjectChanged.addressTypeId !== this.PreviousAddressObject?.addressTypeId ||
        ObjectChanged.zipCode !== this.PreviousAddressObject?.zipCode ||
        ObjectChanged.streetAddress !== this.PreviousAddressObject?.streetAddress ||
        ObjectChanged.number !== this.PreviousAddressObject?.number ||
        ObjectChanged.district !== this.PreviousAddressObject?.district ||
        ObjectChanged.city !== this.PreviousAddressObject?.city ||
        ObjectChanged.federatedUnit !== this.PreviousAddressObject?.federatedUnit ||
        ObjectChanged.streetAddressLine2 !== this.PreviousAddressObject?.streetAddressLine2 ||
        ObjectChanged.landmark !== this.PreviousAddressObject?.landmark ||
        ObjectChanged.mailbox !== this.PreviousAddressObject?.mailbox ||
        ObjectChanged.principalFlg !== this.PreviousAddressObject?.principalFlg
       ){
         change = true;
       }
       else{
         change = false;
       }
      
       if(this.PreviousAddressObject){
        if(this.AddressForm.invalid || !change){
          return true;
         }
         else{
          return false;
         }
       }
       else{
        if(this.AddressForm.invalid){
          return true;
         }
         else{
          return false;
         }
       }

  }

  fillFields(object){
    this.AddressForm.setValue(object);
  }

  generateErrorMessage(formControl){
    return this.formValidatorMessageService.returnMessage(formControl);
  }

  onCheckboxChange($event){
    this.AddressForm.controls.principalFlg.setValue($event.target.checked);
    
    if($event.target.checked){
        this.newAddress.principalFlg = true
      }
      else{
        this.newAddress.principalFlg = false
      } 
  }
  
  ngOnInit() {
    this.defaultObjectAddress = {
      addressTypeId: 1,
      personId: this.personId,
      id:null,
      personAddressTypeId:1,
      zipCode:null,
      streetAddress:null,
      number:null,
      streetAddressLine2:null,
      landmark:null,
      district:null,
      city:null,
      country: 'Brasil',
      mailbox: null,
      principalFlg:false,
      federatedUnit: null,
   }
    this.initForm();
    let event = new AddressEvent;
    event.data = this.newAddress;
    event.disableButton = this.disableButton(this.newAddress);
    this.event.emit(event)
  }

  getCepChanges() {
    this.AddressForm
      .get("zipCode")
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((val) => val.length === 8),
        switchMap((cep) => {
          cep = cep.replace(/[^0-9]/g, '');
          return this.postalcodeservice.get(cep);
        })
      )
      .subscribe(
        (success) => {
          if (success !== null) {
            if(this.newAddress.addressTypeId == 1){
              this.AddressForm.patchValue(success);
            }
            else{
              this.AddressForm.patchValue({
                zipCode: success.zipCode,
                city: success.city,       
                federatedUnit: success.federatedUnit,
                country: success.country,
              });
            }
          }
        },
        (error) => {
          this.AddressForm.setValue(this.newAddress);
          console.log(`Promise rejeitada: ${JSON.stringify(error)}`);
        }
      );
  }

  changeAddressTypeId(type: number) {
    this.newAddress.addressTypeId = type;
    this.AddressForm.reset();
    if(this.PreviousAddressObject && this.PreviousAddressObject.addressTypeId == type){
      this.fillFields(this.newAddress);
    }
    else{
      this.fillFields(this.defaultObjectAddress);
      this.AddressForm.controls.addressTypeId.setValue(type);
      this.AddressForm.controls.id.setValue(this.newAddress.id);
    }
    this.getCepChanges();
    this.setValidatorsByAddressTypeId(type);
  }

  setValidatorsByAddressTypeId(tipo){
    if(tipo == 1){
      this.AddressForm.get('streetAddress').setValidators([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100)
      ]);
      this.AddressForm.get('number').setValidators([
        Validators.required,
        Validators.maxLength(5)
      ]);
      this.AddressForm.get('district').setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)
      ]);
      this.AddressForm.get('streetAddressLine2').setValidators([
        Validators.maxLength(50)
      ]);
      this.AddressForm.get('landmark').setValidators([
        Validators.maxLength(100)
      ]);

      this.AddressForm.get('mailbox').clearValidators();
      this.AddressForm.get('mailbox').updateValueAndValidity();
    }
    else{
      this.AddressForm.get('mailbox').setValidators([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(10)
      ]); 
      
      this.AddressForm.get('streetAddress').clearValidators();
      this.AddressForm.get('streetAddress').updateValueAndValidity();
      this.AddressForm.get('number').clearValidators();
      this.AddressForm.get('number').updateValueAndValidity();
      this.AddressForm.get('district').clearValidators();
      this.AddressForm.get('district').updateValueAndValidity();
      this.AddressForm.get('streetAddressLine2').clearValidators();
      this.AddressForm.get('streetAddressLine2').updateValueAndValidity();
      this.AddressForm.get('landmark').clearValidators();
      this.AddressForm.get('landmark').updateValueAndValidity();
    }
  }

  onSubmit(){
    
  }

}
