import { HttpParams } from '@angular/common/http';

export class HolidaysCalendarQueryArguments {

  sortField: string;
  desc: boolean;


  constructor() {
    this.sortField = 'holidayDate';
    this.desc = true;
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.sortField);

    return httpAppend;
  }
}
