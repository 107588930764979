import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  BILL_STATUS,
  isValidBillStatusForBoleto,
  isValidBillStatusForNotaFiscalOrPDF,
  isValidBillStatusForMainButton,
  isValidBillStatusForVisualizarDetalhesOrBaixaManual,
  isValidBillStatusForCSV,
  isValidBillStatusForBankSlipByActiveBackOfficeProfile
} from "src/app/shared/constants/bill-status";
import {finalize} from "rxjs/operators";
import {Bill} from "@models/old/financial/bill/bill-list/bill.dto";
import {BillListService} from "../../../bill/components/bill-list/bill-list.service";
import {NotificationAlertService} from "@services/notification-alert/notification-alert.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BillCardService} from "../../../bill/components/bill-card/bill-card.service";
import {BillHeaderDto} from "../../../bill/dto/bill-header.dto";
import { COLOR } from 'src/app/shared/constants/color';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  BillRecompositionModalComponent
} from "@modules/customer/bill/components/bill-recomposition-modal/bill-recomposition-modal.component";
import {AbstractConfigurationBase} from "../../../../../shared/abstracts/abstract-configuration-base";
import { ContractModality } from '@models/old/contractModality.enum';
import { BILL_DESCRIPTIONS } from '../../dto/bill-consts';

@Component({
  selector: 'app-bill-card',
  templateUrl: './bill-card.component.html',
  styleUrls: ['./bill-card.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({height: 'auto'})),
      state('false', style({height: '0'})),
      transition('false <=> true', [animate(500)])
    ])
  ]
})
export class BillCardComponent extends AbstractConfigurationBase implements OnChanges {

  protected readonly BILL_STATUS = BILL_STATUS;
  protected readonly COLOR = COLOR;

  @Input() bill!: Bill;
  @Input() billHeaderDto!: BillHeaderDto;
  @Input() count!: number;
  @Input() status: any = [];
  @Input() isLoading!: boolean;
  @Input() inContractModalityTypeId: ContractModality;

  constructor(protected billListService: BillListService,
              private notificationAlertService: NotificationAlertService,
              private billCardService: BillCardService,
              private modalService: NgbModal) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bill?.currentValue != null) {
      this.billCardService.configureBillStatusAppearance(this.bill);
    }

    if (changes.billHeaderDto?.currentValue != null) {
      this.bill = this.billCardService.fromBillHeaderToBill(this.billHeaderDto)
    }
  }

  generateCSV(billId: number, csvFile: any, idStatus: number, csvDate: string | Date) {
    if (csvFile) {
      if ( idStatus === BILL_STATUS.ABERTA ) {
        this.validateTimeRangeCsv(billId, csvFile, csvDate);
      }
      else {
        this.redirectToLink(csvFile);
      }
    }
    else {
      this.requestCSV(billId);
    }
  }


  public requestCSV(billId: number): void {
    this.isLoading = true;
    this.billListService
      .GetCSVFile(billId)
      .pipe(
        finalize(() => {
          this.isLoading = false
        })
      )
      .subscribe(
        (success) => {
          this.notificationAlertService.alert(success.title, success.description);
        },
        (error) => {
          this.notificationAlertService.alert(error.title, error.description, 2);
          console.error(error);
          return;
        }
      )
  }

  generatePDF(billid: any, flgDownloadPDF: any, pdfFile: any) {
    if (pdfFile) {
      this.redirectToLink(pdfFile);
    } else {
      this.requestPDF(billid, flgDownloadPDF);
    }
  }


  public requestPDF(billid, flgDownloadPDF) {
    if (flgDownloadPDF) {
      this.isLoading = true
      this.billListService.GetBillPdfFile(billid)
        .pipe(
          finalize(() => {
            this.isLoading = false
          })
        )
        .subscribe(
          (success) => {
            this.notificationAlertService.alert(success.title, success.description);
          },
          (error) => {
            this.notificationAlertService.alert(error.title, error.description, 2);
            console.error(error);
            return;
          }
        );
    }
  }

  redirectToLink(link: any) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  isClickOpenClose(bill: Bill): void {
    bill.isOpen = !bill.isOpen;
  }

  isVisibleMainButtonVisualizarDetalhes(bill: Bill): boolean {
    if (this.inContractModalityTypeId === ContractModality.PREPAID) {
      return true;
    }

    return isValidBillStatusForMainButton(bill.idStatus);
  }

  isVisibleMainButtonBaixarBoleto(bill: Bill): boolean {
    if (this.inContractModalityTypeId === ContractModality.PREPAID) {
      return false;
    }

    return !isValidBillStatusForMainButton(bill.idStatus);
  }

  isVisibleBtnVisualizarDetalhes(bill: Bill): boolean {
    if (this.inContractModalityTypeId === ContractModality.PREPAID) {
      return false;
    }

    return isValidBillStatusForVisualizarDetalhesOrBaixaManual(bill.idStatus);
  }

  isVisibleBtnBoleto(bill: Bill): boolean {
    if (this.inContractModalityTypeId === ContractModality.PREPAID) {
      return false;
    }

    if (this.userProfileService.isCustomerOrRelationshipProfile()) {
      return bill.billetLink && isValidBillStatusForBoleto(bill.idStatus) &&
        isValidBillStatusForMainButton(bill.idStatus);
    } else {
      return isValidBillStatusForBankSlipByActiveBackOfficeProfile(bill.idStatus) &&
        isValidBillStatusForMainButton(bill.idStatus);
    }
  }

  isVisibleBtnNotaFiscal(bill: Bill): boolean {
    return bill.invoiceLink && isValidBillStatusForNotaFiscalOrPDF(bill.idStatus);
  }

  isVisibleBtnPDF(bill: Bill): boolean {
    if (this.inContractModalityTypeId === ContractModality.PREPAID) {
      return false;
    }

    return bill.invoiceLink && isValidBillStatusForNotaFiscalOrPDF(bill.idStatus)
  }

  isVisibleBtnBaixaManual(bill: Bill): boolean {
    return bill.permissionManualRecomposition && isValidBillStatusForVisualizarDetalhesOrBaixaManual(bill.idStatus);
  }

  getManualRecompositionLimit(billid) {
    const modalRef = this.modalService.open(BillRecompositionModalComponent);
    modalRef.componentInstance.billId = billid;
  }

  isVisibleBtnCSV(bill: Bill): boolean {
    return isValidBillStatusForCSV(bill.idStatus);
  }

  private isInTimeRange(date: string | Date): boolean {
    const currentDate = new Date();
    let dateCompared = new Date(date);
    dateCompared.setHours(dateCompared.getHours() - 3);

    const timeDiff = currentDate.getTime() - dateCompared.getTime();
    const minutesDiff = timeDiff / 60000;

    return minutesDiff <= 10;
  }

  private validateTimeRangeCsv(billId: number, csvFile: any, csvDate: string | Date) {
    if (this.isInTimeRange(csvDate)) {
      this.redirectToLink(csvFile);
    }
    else {
      this.requestCSV(billId);
    }
  }

  getDescriptionByBillStatusAndContractModalityType(billStatusId: number): string {
    let description = '';

    switch (billStatusId) {

      case BILL_STATUS.ABERTA:
        description =
          this.inContractModalityTypeId === ContractModality.PREPAID ?
            BILL_DESCRIPTIONS.BILL_STATUS_OPEN_PRE_PAID :
            BILL_DESCRIPTIONS.BILL_STATUS_OPEN_POS_PAID;
        break;

      case BILL_STATUS.EM_FECHAMENTO:
        description = BILL_DESCRIPTIONS.BILL_STATUS_CLOSING;
        break;

      default:
        break;
    }

    return description;
  }
}
