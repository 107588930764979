import { TagRequestReceivingResponsible } from "./tagrequestreceivingresponsible.model";

export class TagRequest {
  id: number;
  isPix: boolean;
  amount: number;
  customerId: number;
  regionDeliveryTermId: number;
  regionShippingChargeId: number;
  marketingPlanId: number;
  contractId: number;
  personAddressId: number;
  addressTypeId: number;
  tagRequestReceivingResponsible: TagRequestReceivingResponsible;
  totalTagValue: number;
  totalOrder: number;
  totalOrderDiscount: number;
  totalAmountPayable: number;
  registrationUserId: number;

  constructor() {
    this.tagRequestReceivingResponsible = new TagRequestReceivingResponsible();
  }
}

export class TagRequestPrepaid {
  id?: number;
  message?: string;
  data?: {};
  contractName?: string;
  contractId?: number;
  documentNumber?: string;
  companyName?: string;
  userId?: number;
  registrationUserName?: string;
  registrationDate?: Date;
  creditCard?: string;
  creditCardBrandTypeId?: number;
  tagRequestId?: number;
  amount?: number;
  success?: boolean;
  customerId?: number;
  mensagemCobranca?: boolean;
  tagOrderId?: number;
}
