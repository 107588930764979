<div class="search-form-inline"  [ngStyle]="handleDivStyle()">
        <input
          id="search-input"
          type="text"
          data-e2e="search-input"
          class="search-inline"
          [mask]="mask ? mask : null"
          [ngClass]="classCustom"
          [placeholder]="placeholder"
          [(ngModel)]="searchValue"
          (keyup)="onKeyUp()"
        />
        @if (hasIcon && !hasButton) {
          <i class="icn ico {{customIcon}} ico-right search {{searchClass}}"></i>
        }
  @if(hasButton){
    <app-borderless-button
    [description]="buttonDescription"
    [classIconLeft]="buttonIconClass"
    [id-html]="'search-btn'"
    [data-e2e]="'search-btn'"
    [buttonColor]="buttonColor"
    [isBtnWithBorder]="true"
    (emitterEvtClick)="onButtonClick()"
    [isInlineSearchBtn]="true">
  </app-borderless-button>
  }
</div>
