<div class="UserConsultantDeleteModal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ TITLE }}</h4>
    <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
      <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>

  <div class="container-loading"
    *ngIf="isLoading"
    id="container-loader-modal"
    data-e2e="container-loader-modal"
  >
    <app-loader
      id="loader-modal"
      data-e2e="loader-modal"
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    ></app-loader>
  </div>

  <!-- Confirmation step-->
  <div class="modal-body" *ngIf="step == eStepModal.CONFIRM && !isLoading">
    <h6 id="modal-subtitle-confirm">{{ SUBTITLE_CONFIRM }}</h6>
    <p class="modal-text--red">{{ TEXT_CONFIRM }}</p>

    <section class="modal-data-container">
      <div class="modal-text--left">
        <p>Nome:</p>
        <p>CPF:</p>
        <p>Data de nascimento:</p>
        <p>Telefone:</p>
        <p>E-mail:</p>
      </div>
      <div class="modal-text--right">
        <p id="modal-data-fullname">{{ userDetails.fullName }}</p>
        <p id="modal-data-documentnumber">{{ userDetails.documentNumber | mask: '000.000.000-00'}}</p>
        <p id="modal-data-dateofbirth">{{ userDetails.dateofBirth }}</p>
        <p id="modal-data-telephone">{{ userDetails.telephoneNumber | mask: getPhoneMask(userDetails.telephoneNumber) }}</p>
        <p id="modal-data-email">{{ userDetails.emailAddress }}</p>
      </div>
    </section>

    <form [formGroup]="userDeleteForm">
      <div class="toll-input-box">
        <label class="toll-input-box__label--above" for="input-protocol">Informe o protocolo de solicitação <span>*</span></label>
        <input
          class="toll-input-box__input"
          [class.is-invalid]="userDeleteForm.controls.protocol.invalid
            && userDeleteForm.controls.protocol.touched"
          formControlName="protocol"
          id="input-protocol"
          data-e2e="input-protocol"
          type="text"
          placeholder="Digite"
          maxlength="40"
        />
        <app-vmessage
          *ngIf="userDeleteForm.controls.protocol.invalid
            && userDeleteForm.controls.protocol.touched"
          class="modal-input-msg-error"
          id="msg-error-input-protocol"
          errorMessage="Informe um protocolo válido."
        >
        </app-vmessage>
      </div>
    </form>
  </div>

  <!-- Feedback step-->
  <div class="modal-body" *ngIf="step == eStepModal.FEEDBACK && !isLoading">
    <h6 id="modal-subtitle-feedback">{{ SUBTITLE_FEEDBACK }}</h6>

    <div class="modal-data-container">
      <img class="modal-img-feedback" src="{{ IMG_PATH }}" alt="Imagem de feedback positivo">

      <div class="modal-data-container--column">
        <p class="">{{ TEXT_FEEDBACK }}</p>

        <section class="modal-data-container">
          <div class="modal-text--left">
            <p>Protocolo:</p>
          </div>
          <div class="modal-text--right">
            <p id="modal-data-protocol">{{ feedbackDto.protocol }} </p>
          </div>
        </section>

        <p class="modal-text--gray" id="modal-data-resgistration-feedback">
          {{ feedbackDto.registrationDate | date : 'dd/MM/yyyy HH:mm':'UTC' }} por {{ feedbackDto.registrationUsername }}
        </p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="step == eStepModal.CONFIRM"
      class="btn btn-tertiary"
      id="modal-btn-cancel"
      data-e2e="modal-btn-cancel"
      (click)="dismissModal()"
    >
      CANCELAR
    </button>
    <button
      *ngIf="step == eStepModal.CONFIRM"
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      [disabled]="userDeleteForm.controls.protocol.invalid || isDisabledBtnConfirm "
      (click)="confirmDeleteUser()"
    >
      CONFIRMAR
    </button>

    <button
      *ngIf="step == eStepModal.FEEDBACK"
      class="btn btn-secondary btn-center"
      id="modal-btn-close-below"
      data-e2e="modal-btn-close-below"
      type="button"
      (click)="dismissModal()"
    >
      Fechar
    </button>
  </div>
</div>
