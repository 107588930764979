export enum TabType {
  TagActivate = 1,
  TagBlock = 2,
  TagUnblock = 3,
  TagBlockUnblockHistory = 4,
}

export const FilterPageSizes = [10, 50, 100];

export const NoResultsMessages = {
  TAG_ACTIVATE_TITLE: 'Nenhuma ativação de tags efetuada',
  TAG_ACTIVATE_SUBTITLE: 'Você ainda não realizou nenhuma ativação de tags em lote, clique em Importar novo documento para realizar.',

  TAG_BLOCK_TITLE: 'Nenhum veículo disponível para bloqueio',
  TAG_BLOCK_SUBTITLE: 'Você não possui veículos com tag ativa.',

  TAG_UNBLOCK_TITLE: 'Nenhum veículo disponível para desbloqueio',
  TAG_UNBLOCK_SUBTITLE: 'Você não possui veículos com tag bloqueada manualmente.',

  TAG_BLOCK_UNBLOCK_SEARCH_NORESULT_TITLE: 'Nenhum veículo encontrado',
  TAG_BLOCK_UNBLOCK_SEARCH_NORESULT_SUBTITLE: 'Veículos com Dual Tag não vão aparecer nesta lista, favor executar o processo na Plataforma de Abastecimento.',

  TAG_BLOCK_UNBLOCK_HISTORY_ALL_TITLE: 'Nenhum bloqueio ou desbloqueio efetuado',
  TAG_BLOCK_UNBLOCK_HISTORY_ALL_SUBTITLE: 'Você ainda não realizou nenhum bloqueio ou desbloqueio.',

  TAG_BLOCK_UNBLOCK_HISTORY_BLOCK_TITLE: 'Nenhum bloqueio efetuado',
  TAG_BLOCK_UNBLOCK_HISTORY_BLOCK_SUBTITLE: 'Você ainda não realizou nenhum bloqueio.',

  TAG_BLOCK_UNBLOCK_HISTORY_UNBLOCK_TITLE: 'Nenhum desbloqueio efetuado',
  TAG_BLOCK_UNBLOCK_HISTORY_UNBLOCK_SUBTITLE: 'Você ainda não realizou nenhum desbloqueio.',

  CONTRACT_NOT_FOUND_TITLE: 'Nenhum contrato encontrado',
  CONTRACT_NOT_FOUND_SUBTITLE: 'Não encontramos nenhum contrato com o dado informado. Por favor, verifique se digitou corretamente ou tente novamente com outra informação válida.'
}

// TAB: BatchTagActivate

export const TableHeadersBatchTagActivate = {
  DOCUMENT: 'fileName',
  REGISTRATION_DATE: 'registrationDate',
  USERNAME: 'userName',
  STATUS: 'tagFileControlStatusId',
}

export const StatusProcess = {
  ERROR: 1,
  PROCESSING: 2,
  DONE: 3,
}

export const ModalTagActivateUpload = {
  STEP_CONFIRMATION_TITLE: 'Importar novo documento',
  STEP_FEEDBACK_TITLE: 'Ativar tags',
}

// TAB: BatchTagBlockUnblock

export const TagBlockUnblockTexts = {
  BLOCK_INFO_TEXT: "Selecione os veículos que você deseja bloquear as tags.",
  UNBLOCK_INFO_TEXT: "Selecione os veículos que você deseja desbloquear as tags.",
}

export const TableHeadersBatchTagBlockUnblock = {
  LICENSE_PLATE: 'LicensePlate',
  NICKNAME: 'Nickname',
  HIERARCHY_NAME: 'Hierarchy',
  SERIAL_TAG: 'SerialTag',
}

export const ModalTagBlockUnblock = {
  BLOCK_TITLE: 'Bloquear tags',
  BLOCK_BTN_CONFIRM: 'Confirmar bloqueio',
  BLOCK_BODY_TITLE: 'Bloquear tags',
  BLOCK_BODY_SUBTITLE: 'Confirme as tags que serão bloqueadas:',
  BLOCK_BODY_INFOBOXTEXT: 'As praças de pedágios poderão levar até 6 horas para processar o bloqueio das tags.',

  UNBLOCK_TITLE: 'Desbloquear tags',
  UNBLOCK_BTN_CONFIRM: 'Confirmar desbloqueio',
  UNBLOCK_BODY_TITLE: 'Desloquear tags',
  UNBLOCK_BODY_SUBTITLE: 'Confirme as tags que serão desbloqueadas:',
  UNBLOCK_BODY_INFOBOXTEXT: 'As praças de pedágios poderão levar até 6 horas para processar o desbloqueio das tags.',
}

export const TagStatusType = {
  ACTIVE_ALLOW_BLOCK: 1,
  BLOCKED_ALLOW_UNBLOCK: 2,
}

// TAB: BatchTagBlockUnblockHistory

export const TableHeadersBatchTagBlockUnblockHistory = {
  ACTION_TYPE: 'ContractVehicleTagStatusTypeId',
  REGISTRATION_DATE: 'RegistrationDate',
  USERNAME: 'Username',
  STATUS: 'TagFileControlStatusId',
}

export const ActionType = {
  ALL: 0,
  UNBLOCK: 1, // Turn into active
  BLOCK: 2, // Turn into blocked
}

export const FilterActionType = [
  { "desc": 'todos', "value": '0' },
  { "desc": 'bloqueio', "value": '2' },
  { "desc": 'desbloqueio', "value": '1' }
];

export const ModalTagBlockUnblockInconsistencies = {
  BLOCK_TITLE: 'Erro ao bloquear tags',
  BLOCK_SUBTITLE: 'Ocorreu um erro ao bloquear tags!',
  BLOCK_TAGS_SUCCESS: 'tags foram bloqueadas com sucesso!',
  BLOCK_TAGS_INCONSISTENCIES: 'tags não puderam ser bloqueadas.',

  UNBLOCK_TITLE: 'Erro ao desbloquear tags',
  UNBLOCK_SUBTITLE: 'Ocorreu um erro ao desbloquear tags!',
  UNBLOCK_TAGS_SUCCESS: 'tags foram desbloqueadas com sucesso!',
  UNBLOCK_TAGS_INCONSISTENCIES: 'tags não puderam ser desbloqueadas.',
}
