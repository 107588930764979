<div class="toll">
  <div class="toll-header">
    <app-header></app-header>
  </div>
  <div class="toll-body">
    <router-outlet></router-outlet>
  </div>
  <div class="toll-footer">
    <app-footer></app-footer>
  </div>
</div>
