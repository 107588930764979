import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { finalize, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';

import { TicketsService } from './../../services/tickets.service';
import { GetVehiclesItemsDto } from '../../dtos/getVehiclesItems.dto';
import { GetContractVehiclesQueryResultDto } from '../../dtos/getContractVehicles.dto';
import { GetFromVehiclesQueryResult } from '../../dtos/getFromVehiclesQueryResult.dto';
import { GetTransactionsVehiclesQueryArguments } from '../../dtos/getVehiclesQueryArguments.dto';
import { GetContractVehiclesQueryArguments } from '../../dtos/searchContractVehiclesQueryArguments.dto';
import { NotifyService } from '@services/notify.service';


@Component({
  selector: 'app-tab-vehicles',
  templateUrl: './tab-vehicles.component.html',
  styleUrls: ['./tab-vehicles.component.scss']
})
export class TabVehiclesComponent implements OnInit {
  inlineDateTime: { chosenLabel: string; startDate: moment.Moment; endDate: moment.Moment };
  items: Array<GetVehiclesItemsDto>
  getContractVehiclesQueryResult: GetContractVehiclesQueryResultDto;
  public getVehiclesQueryResult: GetFromVehiclesQueryResult;
  getTransactionsVehiclesQueryArguments: GetTransactionsVehiclesQueryArguments;
  getContractVehiclesQueryArguments: GetContractVehiclesQueryArguments;
  filterDebounceTransactions: Subject<GetTransactionsVehiclesQueryArguments> = new Subject<GetTransactionsVehiclesQueryArguments>();
  filterDebounceContract: Subject<GetContractVehiclesQueryArguments> = new Subject<GetContractVehiclesQueryArguments>();
  selectedDate = new Date();
  today = new Date();
  dayCustomVehicles: Date[];
  minDate: Date;
  maxDate: Date;
  date?: any;
  perPages = [];
  isLoading: boolean;
  isShownPeriod: boolean = false;
  isShownValue: boolean = false;
  isShownHierarchy: boolean = false;
  isDatePickerOpen: boolean;
  isOpen: boolean;
  isOpenDropdown: boolean;
  open: boolean;
  click: any;
  vehicleSelected: string;
  element: HTMLElement;
  oldDate: Date;
  searchVehicles: string;
  selected: any;
  pageSizeTransactions: number;
  isTransactionsLoading: boolean;
  isSideLoading: boolean;
  datePickerDates = [7, 15, 30];

  public formPreDateVehicles = this.formBuilder.group({
    dateVehicles: "30"
  });

  public formValues = this.formBuilder.group({
    minValue: null,
    maxValue: null,
  });

  constructor(
    private ticketsService: TicketsService,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
  ) {
    this.pageSizeTransactions = 5;
    this.minDate = new Date(Date.now());
    this.maxDate = new Date(Date.now());
    this.minDate.setMonth(this.minDate.getMonth() - 13);
    //ContractVehicles
    this.oldDate = new Date();
    this.oldDate.setDate(this.oldDate.getDate() - 30);
    this.getContractVehiclesQueryArguments = new GetContractVehiclesQueryArguments();
    this.getContractVehiclesQueryArguments.search = '';
    this.getContractVehiclesQueryArguments.startDate = this.oldDate;
    this.getContractVehiclesQueryArguments.endDate = new Date();
    this.getContractVehiclesQueryArguments.pageSize = 5;
    this.getContractVehiclesQueryArguments.page = 1;
    this.filterDebounceContract.pipe(debounceTime(300)).subscribe(filter => this.getContractVehicles(filter));

    //TransactionsVehicles
    this.perPages = [5, 10, 20, 50];
    this.getTransactionsVehiclesQueryArguments = new GetTransactionsVehiclesQueryArguments();
    this.getTransactionsVehiclesQueryArguments.page = 1;
    this.getTransactionsVehiclesQueryArguments.pageSize = 5;
    this.getTransactionsVehiclesQueryArguments.desc = true;
    this.getTransactionsVehiclesQueryArguments.orderBy = "PassengerDate";
    this.getTransactionsVehiclesQueryArguments.filter = 1;
    this.getTransactionsVehiclesQueryArguments.search = "";
    this.getTransactionsVehiclesQueryArguments.startDate = this.oldDate;
    this.getTransactionsVehiclesQueryArguments.endDate = new Date();
    this.filterDebounceTransactions.pipe(debounceTime(300)).subscribe(filter => this.getVehicleQuery(this.vehicleSelected, filter));
  }

  ngOnInit() {
    this.getContractVehicles(this.getContractVehiclesQueryArguments);
    this.ticketsService.getFilterObservable().subscribe(
      value => {
        this.toggleDateAfterChangeTab(value);
      }
    );
  }

  shortCode(code){
    let ShortCode = code.slice(-7)
    return ShortCode
  }


  getContractVehicles(args: GetContractVehiclesQueryArguments) {
    this.getVehiclesQueryResult = null;
    this.isLoading = true;
    this.isSideLoading = true;
    this.ticketsService
      .getContractVehicles(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isSideLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.getContractVehiclesQueryResult = success
          if (!this.getContractVehiclesQueryResult.items.some(item => item.licensePlate == this.vehicleSelected)) {
            this.getVehiclesQueryResult = null;
            this.vehicleSelected = null;
          } else {
            this.filterDebounceTransactions.next(this.getTransactionsVehiclesQueryArguments);
          }
        },
        (error) => {
          console.error(error)
          return;
        }
      );
  }

  setContractVehicleId(licensePlate: string) {
    this.vehicleSelected = licensePlate;
    this.getTransactionsVehiclesQueryArguments.page = 1;
    this.getTransactionsVehiclesQueryArguments.pageSize = 5;
    this.getVehicleQuery(this.vehicleSelected, this.getTransactionsVehiclesQueryArguments,true);
  }

  getVehicleQuery(licensePlate: string, args: GetTransactionsVehiclesQueryArguments, fromContracts: boolean = false) {
   // this.getVehiclesQueryResult = null;
    if(fromContracts){
      this.isLoading = true;
    }
    else{
      this.isTransactionsLoading = true;
    }
    this.ticketsService
      .getVehiclesQuery(licensePlate, args)
      .pipe(
        finalize(() => {
          this.isTransactionsLoading = false;
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.getVehiclesQueryResult = success;
        },
        (error) => {
          console.error(error)
          return;

        }
      );
  }

  onPaginationChange(page: number) {
    if(this.getTransactionsVehiclesQueryArguments.page !== page){
      this.getTransactionsVehiclesQueryArguments.page = page;
      this.getVehicleQuery(this.vehicleSelected, this.getTransactionsVehiclesQueryArguments);
    }
  }

  onSidePaginationChange(page: number){
    this.getContractVehiclesQueryArguments.page = page;
    this.getContractVehicles(this.getContractVehiclesQueryArguments);
  }

  onSetVehicleFromPageSize(value: any) {
    this.onPageSizeChange(this.vehicleSelected, value);
  }

  onPageSizeChange(licensePlate: string, value: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    //this.getTransactionsVehiclesQueryArguments.page = 1;
    this.getTransactionsVehiclesQueryArguments.pageSize = value;
    this.pageSizeTransactions = value;
    this.getVehicleQuery(licensePlate, this.getTransactionsVehiclesQueryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.getTransactionsVehiclesQueryArguments.orderBy == field && !this.getTransactionsVehiclesQueryArguments.desc,
      'order-by-desc': this.getTransactionsVehiclesQueryArguments.orderBy == field && this.getTransactionsVehiclesQueryArguments.desc
    }
  }

  onOrderByChange(field: string) {
    if (this.getTransactionsVehiclesQueryArguments.orderBy === field) {
      this.getTransactionsVehiclesQueryArguments.desc = !this.getTransactionsVehiclesQueryArguments.desc;
    } else {
      this.getTransactionsVehiclesQueryArguments.orderBy = field;
      this.getTransactionsVehiclesQueryArguments.desc = true;
    }
    this.getVehicleQuery(this.vehicleSelected, this.getTransactionsVehiclesQueryArguments);
  }

  onFullNameKeyup(value: any) {
    this.getContractVehiclesQueryArguments.search = value;
    this.getContractVehiclesQueryArguments.page = 1;
    this.filterDebounceContract.next(this.getContractVehiclesQueryArguments);
  }

  onSearchClick(event: any) {
    this.getContractVehicles(this.getContractVehiclesQueryArguments);
  }

  onFilterDateVehicles(values: Date): void {
    if(values !== null){
      var daysBetween = (new Date(values[1]).getTime() - new Date(values[0]).getTime()) / 1000 / 60 / 60 / 24;
      if (daysBetween > 90) {
        this.notifyService.clear();
        this.notifyService.showError("Período de consulta", "O período de consulta não pode ser maior que 90 dias");
        //this.isShownPeriod = false;
        this.dayCustomVehicles = [
          this.getTransactionsVehiclesQueryArguments.startDate,
          this.getTransactionsVehiclesQueryArguments.endDate
        ];
        return;
      }
      this.formPreDateVehicles.get('dateVehicles').reset();
      values[0].setHours(0, 0, 0, 0);
      values[1].setHours(23, 59, 59, 0);
      this.toggleDateRangePicker();
      this.getContractVehiclesQueryArguments.startDate = this.getTransactionsVehiclesQueryArguments.startDate = values[0];
      this.getContractVehiclesQueryArguments.endDate = this.getTransactionsVehiclesQueryArguments.endDate = values[1];
      this.filterDebounceContract.next(this.getContractVehiclesQueryArguments);
    }

  }

  setDaysLast(days: number) {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - days);
    this.dayCustomVehicles = [this.selectedDate, this.today];
    return this.dayCustomVehicles;
  }


  toggleDropdown() {
    this.isOpenDropdown = !this.isOpenDropdown;
    this.isShownPeriod = false;
    this.open = false;
    this.isShownHierarchy = false;
    this.isShownValue = false;
    this.hideDate();
  }

  toggleShowPeriod() {
    this.isDatePickerOpen = !this.isDatePickerOpen;
  }

  toggleDateRangePicker() {
    this.isShownPeriod = !this.isShownPeriod;
    this.isOpen = !this.isOpen;
    this.isShownHierarchy = false;
    this.isShownValue = false;
    this.toggleShowPeriod();
  }

  toggleShowValue() {
    this.isShownValue = !this.isShownValue;
    this.isShownPeriod = false;
    this.isShownHierarchy = false;
    this.isOpen = false;
  }

  toggleShowHierarchy() {
    this.isShownHierarchy = !this.isShownHierarchy;
    this.isShownPeriod = false;
    this.isShownValue = false;
    this.isOpen = false;
  }

  resetFilters() {
    this.formPreDateVehicles = this.formBuilder.group({
      dateVehicles: "30"
    });
    this.dayCustomVehicles = null;
    this.searchVehicles = null;
    this.getContractVehiclesQueryArguments.search = "";
    this.getContractVehiclesQueryArguments.startDate = this.oldDate;
    this.getContractVehiclesQueryArguments.endDate = new Date();
    this.getTransactionsVehiclesQueryArguments.startDate = this.getContractVehiclesQueryArguments.startDate;
    this.getTransactionsVehiclesQueryArguments.endDate = this.getContractVehiclesQueryArguments.endDate;
    this.getContractVehicles(this.getContractVehiclesQueryArguments);

  }

  toggleDateAfterChangeTab(tab){
   if(tab == 2){
    if(this.isOpenDropdown){
        if(this.isDatePickerOpen){
          this.showDate()
        }
    }
    else{
      this.isDatePickerOpen = false;
      this.hideDate()
    }
  }
  else{
      if(this.isDatePickerOpen){
        this.hideDate()
      }
    }
  }

  hideDate() {
    this.element = document.getElementById('hideVehicleDate') as HTMLElement;
    this.element?.click();
  }

  showDate(){
    this.element = document.getElementById('showVehicleDate') as HTMLElement;
    this.element?.click();
  }

}

