<div class="SelectProfileComponent">
  <div class="container">
    @if (isFinancialBlockingPosPaidPath) {
      <app-page-title [title]="'/ Financial blocking and unblocking'" [data-e2e]="'page-title'"></app-page-title>
    } @else {
      <div class="title-header"> Selecione como deseja buscar pelo cliente: </div>
    }
  </div>

  <app-card-select-contracts [contractList]="contractAvailables" [totalContractsList]="totalContracts"
    (emitterValuesForm)="loadContracts(true, $event)" [haveManyContracts]="true"
    (emitterContract)="verifyContract($event)" (emitterOpenModal)="showEditContractNameModal($event)"
    (pageChangeEvent)="onPaginationChange($event)" [isLoading]="isLoading" [title]="dynamicTitle"
    (searchFormValues)="handleSearchFormValues($event)" [searchFieldParams]="searchFieldParams">
  </app-card-select-contracts>


</div>
