<div class="container-fluid page-myaccount">
    <div class="row">
        <div class="col" style="padding-bottom: 3rem">
          <app-page-title [title]="'/ Passagens'"></app-page-title>
        </div>

        <div class="col-lg-12">
            <div class="container col-md-12 mt-1">
                <app-tab-select
                  [tabs]="tabs"
                  (filterChange)="selectTabFilterValue($event)"
                ></app-tab-select>


                <!--O uso do hidder se dá devido ao fato de que ao usar o Switch,
                uma nova instancia era gerada, perdendo os valores dos filtros-->
                <app-tab-general [hidden]="selectedTab !== 1"></app-tab-general>
                <app-tab-vehicles [hidden]="selectedTab !== 2"></app-tab-vehicles>
                <app-tab-hierarchy [hidden]="selectedTab !== 3"></app-tab-hierarchy>
            </div>
        </div>
    </div>
</div>
