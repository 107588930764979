import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {FormControl, ReactiveFormsModule} from "@angular/forms";

export interface SelectValues {
  value: any;
  label: any;
}

@Component({
  selector: 'app-outlined-select',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, ReactiveFormsModule],
  templateUrl: './outlined-select.component.html',
  styleUrl: './outlined-select.component.scss'
})
export class OutlinedSelectComponent implements OnInit {

  protected selectControl: FormControl =  new FormControl();

  @Input() title : string = '';
  @Input() values : any[] = [];
  @Input() beforeTextValue: string = '';
  @Input() afterTextValue: string = '';
  @Input() disableRipple : boolean = true;
  @Input() classIconLeft : string = '';
  @Input() multiple: boolean = false;
  @Input() hasLabel: boolean = false; // if true, the valueChange will return an object with index and value
  @Input() customWidthSize: any = '200px';
  @Input() initialValue: any;


  @Output() valueChange = new EventEmitter<any>();

  onValueChange(event: any) {
    if(this.multiple){
      return this.valueChange.emit({index: this.values.indexOf(event.value), value: event.value});
    }
    this.valueChange.emit(event.value);
  }

  ngOnInit() {
    if (!this.multiple) {
      const startValue = this.values.length > 0 ? this.values[0] : '10';
      this.selectControl = new FormControl(startValue.toString());
      if (this.initialValue) {
        this.selectControl.setValue(this.initialValue.toString());
      }
    }

    this.handleStyleByInput();
  }


  protected handleStyleByInput(): any {
    let customStyle: any = '';

    if (this.customWidthSize) {
      customStyle = {'width': this.customWidthSize};
    }

    return customStyle;
  }

}
