<!-- Modal userBlockModal -->
<div class="tagLinkComponent">
<div class="modal-content ">
  <div class="step-1" id="confirmacao" [hidden]="!stepconfirmacao">
    <div class="modal-header">
      <div class="box-title">
        <span>Ativar TAG</span>
      </div>
      <button type="button" class="close" (click)="dismissModal()" id="btn-dismiss">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row-box-yellow blue">
        <span class="box-yellow blue">
          <b>F</b>ique atento, em alguns estabelecimentos, as novas Tags <br>
          podem levar até 6 horas para serem ativadas.  
        </span>
      </div>
    </div>
    <div class="modal-body">
      <div class="row ">
        <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-12">
                </div>
              </div>
              <div class="row">
                <h3>Deseja confirmar o vínculo de TAG abaixo:</h3>
              </div>
              <div class="row rowTitleLink">           
                <div class="col-lg-5 text-right">
                   TAG
                 </div>
                 <div class="col-lg-2">
                </div>
                 <div class="col-lg-5 text-left">
                   VEÍCULO
                 </div>      
             </div>
            <div class="forward-connector ico-forward-connector">
            </div>
            <div class="row rowbodyLink">        
              <div class="col-lg-5 text-right" id="serial">
                {{tagselected.serial}}
              </div>
              <div class="col-lg-2">
              </div>
              <div class="col-lg-5 text-left" id="licensePlate">
                PLACA: {{vehicleselected.licensePlate | mask: 'AAA-AAAA' }}
              </div>
            </div>
         </div>
         <div class="col-lg-6">
           
          <div class="container-repom containertypeService">
            <div class="row">
              <div class="col-lg-12">
                <label class="label-collapse" for="collapseTagsFor">Tipos de Serviço</label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 serviceOption">
                <label class="container"><div class="type">Pedágio + Estacionamento</div>
                  <input type="radio"[checked]="service1" value="1" id="inputService" name="Service" (click)="onItemChange($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 serviceOption">
                <label class="container"><div class="type">Pedágio</div>
                  <input type="radio" [checked]="service2" id="inputService2" value="2" name="Service" (click)="onItemChange($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

         </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray">
      <button type="button" class="btn btn-tertiary mr-4" id="btn-close" [disabled]="onSaving" (click)="dismissModal()">Cancelar</button>
      <button type="button" class="btn btn-secondary position-relative" (click)="saveContractVehicleTag()" id="btn-confirm"
        [disabled]="onSaving">CONFIRMAR VÍNCULO
        <img class="loading-btn" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
      </button>
    </div>

  </div>
  <div class="step-2" [hidden]="stepconfirmacao">
    <div class="modal-header">
      <div class="box-title">
        <span>Ativar TAG</span>
      </div>
      <button type="button" class="close" id="btn-close2" (click)="emitChangeAndDismissModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12">
        </div>
      </div>
      <div class="row row-modal-collapse">
        <div class="col-lg-1"></div>
        <div class="col-lg-4">
          <img src="../../../assets/img/bg-place.svg" />
        </div>
        <div class="col-lg-7" >
          <h4>TAG ATIVADA</h4>
          <div class="row-box-yellow gray">
            <span class="box-yellow gray">
              <b>F</b>ique atento, em alguns estabelecimentos, as novas Tags <br>
              podem levar até 6 horas para serem ativadas.      
            </span>
          </div>
          <div class="row Successinfos">
            <div class="col-lg-auto  text-right">PLACA:</div>
            <div class="col-lg-6 text-left" id="licensePlate2">{{vehicleselected?.licensePlate | mask: 'AAA-AAAA' }}</div>
          </div>
          <div class="row Successinfos">
            <div class="col-lg-auto  text-right">TAG ATIVA:</div>
            <div class="col-lg-6 text-left" id="serial2">{{tagselected?.serial}}</div>
          </div>
          <div class="row Successinfos">
            <div class="col-lg-auto text-right">SERVIÇO:</div>
            <div class="col-lg-6 text-left serviceType" id="ServiceDescription">{{ServiceDescription}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center" id="activationDate">
          {{contractVehicleTagUserRegister?.activationDate | date: 'dd/MM/yyyy - HH:mm'}} por {{contractVehicleTagUserRegister?.registrationUserFullName}}
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray">
      <button type="button" class="btn btn-secondary" id="emitChangeAndDismissModal" (click)="emitChangeAndDismissModal()">Fechar</button>
    </div>
  </div>
</div>
</div>
