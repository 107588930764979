import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, last } from 'rxjs';
import { ActionType, FilterActionType, FilterPageSizes, NoResultsMessages, StatusProcess, TableHeadersBatchTagBlockUnblockHistory } from '@modules/customer/batch-management/dtos/batch-management-consts';
import { TagBlockUnblockHistoryQuery } from '@modules/customer/batch-management/dtos/tag-block-unblock-history-query';
import { ITagBlockUnblockHistoryDto } from '@modules/customer/batch-management/dtos/tag-block-unblock-history.dto';
import { BatchManagementService } from '@modules/customer/batch-management/services/batch-management.service';
import { TagBlockUnblockInconsistenciesModalComponent } from './components/tag-block-unblock-inconsistencies-modal/tag-block-unblock-inconsistencies-modal.component';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';
import { SelectValues } from 'src/app/shared/select/outlined-select/outlined-select.component';

@Component({
  selector: 'app-batch-tag-block-unblock-history',
  templateUrl: './batch-tag-block-unblock-history.component.html',
  styleUrl: './batch-tag-block-unblock-history.component.scss'
})
export class BatchTagBlockUnblockHistoryComponent extends AbstractConfigurationBase implements OnInit {
  readonly tableHeadersHistory = TableHeadersBatchTagBlockUnblockHistory;
  readonly pageSizes = FilterPageSizes;
  readonly filterActionTypes = FilterActionType;
  readonly statusProcess = StatusProcess;

  isLoadingTableData: boolean;
  historyQuery: TagBlockUnblockHistoryQuery;
  historyResultList: ITagBlockUnblockHistoryDto[];
  totalItems: number;
  noResultMsgTitle: string;
  noResultMsgSubtitle: string;
  filtersToSelectAction: SelectValues[]

  constructor(
    private batchManagementService: BatchManagementService,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadDefaultSettingsComponent();

    this.filtersToSelectAction = this.filterActionTypes.map((item) => {
      return {
        value: item.value,
        label: item.desc
      };
    });
  }

  private loadDefaultSettingsComponent() {
    this.settingsInitClass();
    this.requestTagBlockUnblockHistory();
  }

  private settingsInitClass() {
    this.historyQuery = new TagBlockUnblockHistoryQuery();
    this.historyQuery.contractId = this.getContractId();
    this.totalItems = 0;
    this.historyResultList = [];
  }

  private getContractId(): number {
    return Number.parseInt(this.authService.getContractId());
  }

  private requestTagBlockUnblockHistory() {
    this.isLoadingTableData = true;

    this.batchManagementService.getTagsBlockUnblockHistory(this.historyQuery)
      .pipe(
        finalize(() => {
          this.isLoadingTableData = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.historyResultList = success.items;
          this.totalItems = success.totalItems;

          if (this.totalItems === 0) {
            this.handleNoResultsMsg();
          }
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  private handleNoResultsMsg() {
    switch (this.historyQuery.contractVehicleTagStatusTypeId) {

      case ActionType.ALL: {
        this.noResultMsgTitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_ALL_TITLE;
        this.noResultMsgSubtitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_ALL_SUBTITLE;
        break;
      }
      case ActionType.UNBLOCK: {
        this.noResultMsgTitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_UNBLOCK_TITLE;
        this.noResultMsgSubtitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_UNBLOCK_SUBTITLE;
        break;
      }
      case ActionType.BLOCK: {
        this.noResultMsgTitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_BLOCK_TITLE;
        this.noResultMsgSubtitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_BLOCK_SUBTITLE;
        break;
      }
      default: {
        this.noResultMsgTitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_ALL_TITLE;
        this.noResultMsgSubtitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_HISTORY_ALL_SUBTITLE;
        break;
      }
    }
  }

  onPageSizeChange(value: any): void {
    this.historyQuery.pageSize = value;
    this.historyQuery.page = 1;

    this.requestTagBlockUnblockHistory();
  }

  onPaginationChange(page: number) {
    this.historyQuery.page = page;
    this.requestTagBlockUnblockHistory();
  }

  onActionTypeChange(value: any): void {
    this.historyQuery.contractVehicleTagStatusTypeId =
      value.value == 0 ?
        null :
        parseInt(value.value);

    this.historyQuery.page = 1;

    this.requestTagBlockUnblockHistory();
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.historyQuery.sortField == field && !this.historyQuery.desc,
      'order-by-desc':
        this.historyQuery.sortField == field && this.historyQuery.desc,
    };
  }

  onOrderByChange(field: string) {
    if (this.historyQuery.sortField == field) {
      this.historyQuery.desc = !this.historyQuery.desc;
    }
    else {
      this.historyQuery.sortField = field;
      this.historyQuery.desc = true;
    }

    this.historyQuery.page = 1;
    this.requestTagBlockUnblockHistory();
  }

  openTagBlockUnblockInconsistenciesModal(item: ITagBlockUnblockHistoryDto) {
    const modal = this.modalService.open(TagBlockUnblockInconsistenciesModalComponent);

    modal.componentInstance.inDataInconsistencies = item;

    modal.componentInstance.outEventReloadRequest.subscribe(() => {
      this.isLoadingTableData = true;
      this.loadDefaultSettingsComponent();
      modal.close();
    });
  }
}
