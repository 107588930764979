import { Component, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback-financial-block-modal',
  templateUrl: './feedback-financial-block-modal.component.html',
  styleUrls: ['./feedback-financial-block-modal.component.scss'],
})
export class FeedbackFinancialBlockModalComponent {
  @Input() title: string;
  @Input() imgPath: string;
  @Input() subtitle: string;
  @Input() textBold: string;
  @Input() text: string;
  @Input() textGray: string;
  @Input() textBoxYellow: string;

  constructor(private _modalService: NgbModal) {}

  dismissModal() {
    this._modalService.dismissAll();
  }

  getFirstChar(text: string): string {
    return text.charAt(0);
  }

  getRestOfText(text: string): string {
    return text.slice(1);
  }
}
