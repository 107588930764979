import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetVehiclesAvailableArguments } from '../../../hierarchy/dtos/getVehiclesAvailableArguments.dto';
import { GetVehiclesAvailableQueryResult } from '../../../hierarchy/dtos/getVehiclesAvailableQueryResult.dto';
import { NotifyService } from '@services/notify.service';
import { HierarchyService } from '../../../hierarchy/services/hierarchy.service';
import { EventEmitterService } from '@services/event-emitter.service';

@Component({
  selector: 'app-add-vehicle-hierarchy',
  templateUrl: './add-vehicle-hierarchy.component.html',
  styleUrls: ['./add-vehicle-hierarchy.component.scss']
})
export class AddVehicleHierarchyComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() contractvehicleselected: any;
  public vehiclesearch: string;
  @Input() hierarchy: any;
  @Input() hierarchyId: any;
  @Input() openHierarchy: any;
  @Input() closeBtnName: any;
  getVehiclesAvailableArguments: GetVehiclesAvailableArguments;
  vehicleHierarchyForm = this.formBuilder.group({
    licensePlate: new FormArray([]),
    hierarchyId: null,
  })
  filterDebounce: Subject<GetVehiclesAvailableArguments> = new Subject<GetVehiclesAvailableArguments>();
  listVehicleAvailable: GetVehiclesAvailableQueryResult;

  isLoading: boolean;
  description_PT: any;
  breadCrumb: any;
  constructor(
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private notifyService: NotifyService,
    private hierarchyService: HierarchyService,
    private formBuilder: FormBuilder,
  ) {
     this.listVehicleAvailable = {
      total: 0,
      vehiclesAvailable: []
    }
    this.GetHierarchyList();

    this.getVehiclesAvailableArguments = new GetVehiclesAvailableArguments();
    this.getVehiclesAvailableArguments.page = 1;
    this.getVehiclesAvailableArguments.pageSize = 20;
    this.getVehiclesAvailableArguments.orderBy = "registrationDate";
    this.getVehiclesAvailableArguments.desc = true;
    this.getVehiclesAvailableArguments.search = '';
    this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getListVehicle(filter));
   }

  ngOnInit(): void {
   this.getListVehicle(this.getVehiclesAvailableArguments)
  }

  close() {
    this.bsModalRef.hide();
  }

  resetForm(order) {
    if(order == 1){
      this.getVehiclesAvailableArguments.desc = true;
      this.getVehiclesAvailableArguments.orderBy = 'registrationDate'
    }
    else if(order == 2){
      this.getVehiclesAvailableArguments.desc = false;
      this.getVehiclesAvailableArguments.orderBy = 'LicensePlate'
    }
    else{
      this.getVehiclesAvailableArguments.desc = false;
      this.getVehiclesAvailableArguments.orderBy = 'LicensePlate'
    }

    this.getListVehicle(this.getVehiclesAvailableArguments);
    this.vehicleHierarchyForm = this.formBuilder.group({
      licensePlate: new FormArray([]),
      hierarchyId: this.hierarchyId,
    })
  }

  onPaginationChange(page: number) {
    this.getVehiclesAvailableArguments.page = page;
    this.getListVehicle(this.getVehiclesAvailableArguments);
  }

  onBuscarClick(event: any) {
    this.getListVehicle(this.getVehiclesAvailableArguments);
  }

  onFullNameKeyup($event: any) {
    this.getVehiclesAvailableArguments.page = 1;
    this.getVehiclesAvailableArguments.search = $event.target.value;
    this.filterDebounce.next(this.getVehiclesAvailableArguments);
  }


  getListVehicle(args:GetVehiclesAvailableArguments) {
    this.isLoading = true;
    this.hierarchyService
      .getListVehicleAvailable(args)
      .pipe(
        finalize(() => { this.isLoading = false; })
      )
      .subscribe(
        (success: any) => {
          this.listVehicleAvailable = success;
        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }

  GetHierarchyList() {
    this.isLoading = true;
    this.hierarchyService
      .GetHierarchyBreadCrumbList()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: any) => {
          this.description_PT = success.data.filter(item => item.id == this.hierarchyId).map(item => item.description_PT).toString()
          let breadCrumb = success.data.filter(item => item.id == this.hierarchyId).map(item => item.breadCrumb).toString()
          this.breadCrumb = breadCrumb.replace(this.description_PT, '')
        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }



  saveVehicleByLicensePlate() {
    this.vehicleHierarchyForm.get('hierarchyId').setValue(this.hierarchyId);
    this.isLoading = true;
    this.hierarchyService
      .saveVehicleByLicensePlate(this.vehicleHierarchyForm.value)
      .pipe(
        finalize(() => { this.isLoading = false; })
      )
      .subscribe(
        (success) => {
          this.close();
          this.notifyService.showSuccess('Atenção!', 'Veículos adicionados com sucesso!');

          // this.listVehicleAvailable = success;
        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }




  onCheckChange(event) {
    const formArray: FormArray = this.vehicleHierarchyForm.get('licensePlate') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

}
