import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { Filters } from '@models/filter.model';
import { ReportService } from '@services/report.service';
import { FORMATED_PREVIEWS_MONTH, FORMATED_TODAY } from '@variables/dates';

export class CostsRanking {
  constructor(
    public licensePlate: string,
    public amount: number,
    public hierarchyName: string,
    public vehicleId: number
  ) {}
}

@Component({
  selector: 'app-costs-ranking',
  templateUrl: './costs-ranking.component.html',
  styleUrls: ['./costs-ranking.component.scss']
})
export class CostsRankingComponent {
  filter: Filters = {
    startDate: FORMATED_PREVIEWS_MONTH,
    endDate: FORMATED_TODAY
  };

  ranking$: Observable<CostsRanking[]> = this._service.getCostsRanking(new HttpParams({ fromObject: this.filter as any}));
  tableHeader: string[] = ['', 'Placa/Consumo', 'Apelido/Hierarquia'];

  constructor(private _service: ReportService) {}
}
