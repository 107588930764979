import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { VindiService } from "src/app/core/services/vindi.service";
import { NotifyService } from "@services/notify.service";
import { PaymentService } from "@services/payment.service";
import { CreditCardDto } from "../dtos/credit-card.dto";

@Component({
  selector: "app-update-credit-card-modal",
  templateUrl: "./update-credit-card-modal.component.html",
  styleUrls: ["./update-credit-card-modal.component.scss"],
})

export class UpdateCreditCardModalComponent implements OnInit {
  @Output() event: EventEmitter<any> = new EventEmitter();
  constructor(
    public modal: NgbActiveModal,
    private _paymentService: PaymentService,
    private notifyService: NotifyService,
    private vindiService: VindiService
  ) {}
  isLoading: boolean = false;
  submitted: boolean = false;
  CreditCard: CreditCardDto;
  CreditCardForm = new FormGroup({
    card_number: new FormControl("", [
      Validators.required,
      this.isValidCreditCard(),
    ]),
    payment_company_code: new FormControl("", [Validators.required]),
    holder_name: new FormControl("", [Validators.required]),
    card_expiration: new FormControl("", [
      Validators.required,
      this.isValidValidityNumber(),
    ]),
    card_cvv: new FormControl("", [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  creditCardFlags: Array<any> = [];

  isValidCreditCard() {
    return (control: AbstractControl): Validators => {
      let number:string = this.CreditCardForm
        ? this.CreditCardForm.controls["card_number"].value
        : '';

      return number.length !== 16 || /^([0-9])\1*$/.test(number)
        ? { invalidCreditCard: true }
        : null;
    };
  }

  isValidValidityNumber() {
    return (control: AbstractControl): Validators => {
      let validity = this.CreditCardForm
        ? this.CreditCardForm.controls["card_expiration"].value
        : "";

      return validity.length !== 6 ||
        this.isValidMonth(Number(validity.substring(0, 2)))
        ? { invalidValidityNumber: true }
        : null;
    };
  }

  isValidMonth(number) {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }
    if (months.find((x) => x == number)) {
      return false;
    } else {
      return true;
    }
  }

  generateMessage(errors) {
    if (typeof errors !== "undefined") {
      if (errors.required == true) {
        return "Campo é requirido!";
      } else if (errors.min) {
        return "Mínimo " + errors.min.min + "!";
      } else if (errors.max) {
        return "Máximo " + errors.max.max + "!";
      } else if (errors.invalidCreditCard) {
        return "Cartão de crédito inválido!";
      } else if (errors.minlength) {
        return "Mínimo " + errors.minlength.requiredLength + " caracteres!";
      } else if (errors.maxlength) {
        return "Máximo " + errors.maxlength.requiredLength + " caracteres!";
      } else if (errors.invalidValidityNumber) {
        return "inválido!";
      }
    }
  }

  generateErrorMessage(error) {
    console.log(
      error.error.errors.filter((data) => data.parameter == "card_number")
    );
    if (
      error.error.errors.filter((data) => data.parameter == "card_number")
        .length > 0
    ) {
      this.notifyService.showError("Atenção!", "Erro no cadastro do cartão. Verifique seus dados e tente novamente.");
    } else if (
      error.error.errors.filter((data) => data.parameter == "card_expiration")
        .length > 0
    ) {
      this.notifyService.showError(
        "Atenção!",
        "Data de expiração do cartão inválida!"
      );
    } else {
      this.notifyService.showError(
        "Atenção!",
        "Ocorreu um erro ao cadastrar o cartão!"
      );
    }
  }

  ngOnInit(): void {
    this.creditCardFlags = [
      {
        value: "mastercard",
        name: "Mastercard",
      },
      {
        value: "visa",
        name: "Visa",
      },
      {
        value: "elo",
        name: "Elo",
      },
    ];
  }

  createPaymentProfile() {
    this.isLoading = true;
    this.submitted = true;
    this.CreditCardForm.value.card_expiration =
      this.CreditCardForm.value.card_expiration.substr(0, 2) +
      "/" +
      this.CreditCardForm.value.card_expiration.substr(
        2,
        this.CreditCardForm.value.card_expiration.length
      );
    this.CreditCard = this.CreditCardForm.value;
    this.CreditCard.payment_method_code = "credit_card";
    if (this.CreditCardForm.invalid) {
      return;
    } else {
      this.vindiService
        .createVindiPaymentProfile(this.CreditCard)
        .subscribe(
          (success) => {
            this.UpdateCreditCardInfos(success.payment_profile.gateway_token);
          },
          (error) => {
            this.isLoading = false;
            this.generateErrorMessage(error);
            console.error(error);
          }
        );
    }
  }

  UpdateCreditCardInfos(token) {
    this._paymentService.putCreditCard(token).subscribe((success) => {
      this.notifyService.showSuccess("Sucesso", success.message);
      this.modal.close();
      this.event.emit();
    });
  }
}
