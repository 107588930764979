import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AdvanceDetailsModalComponent } from "./components/advance-details-modal/advance-details-modal.component";
import { ITabs } from "src/app/shared/components/tabs/models/tabs-models";

@Component({
  selector: "app-bill-detail-general",
  templateUrl: "./bill-detail-general.component.html",
  styleUrls: ["./bill-detail-general.component.scss"],
})
export class BillDetailGeneralComponent implements OnInit {
  @Input() billSummary: any;

  tabs: ITabs[];
  selectedTab: number = 1;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.setTabsValues();
  }

  showAdvanceDetailsModal(): void {
    this.modalService.open(
      AdvanceDetailsModalComponent
    ).componentInstance.billId = this.billSummary.id;
  }

  setTabsValues() {
    this.tabs = [
      {index: 1, name: 'Consolidado', styles:''},
      {index: 2, name: 'Serviços', styles:''},
      {index: 3, name: 'Passagens e Estornos', styles:''},
    ];
  }

  getTabFiltersByHeading(heading: string): number {
    return this.tabs.find(tab => tab.name === heading).index;
  }
}
