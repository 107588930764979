<div class="modal-content">
  <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
    <div class="modal-header">
      <div class="box-title">
        <span>Recebimento de pedido</span>
      </div>
      <button
        type="button"
        class="close"
        (click)="modalRef.hide()"
        id="btn-dismiss"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body show">
      <div class="row row-header">
        <div class="col-lg-12 desc">
          <p class="title">Confirmar recebimento de pedido:</p>
          <p class="subtitle" id="tagRequestId">
            {{ tagRequestId }} contendo {{ amount }} Tags
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="formNomeInput"
              >Digite o número de uma das tags recebidas no pacote relacionado a
              esse pedido</label
            >
            <input
              type="text"
              class="form-control ico-inserttext pl-45"
              id="formNomeInput"
              value="0126345789"
              [(ngModel)]="serial"
              required
              minlength="1"
              maxlength="100"
              #serialnumber="ngModel"
              [class.is-invalid]="f.submitted && serialnumber.invalid"
              name="serialnumber"
              id="serialnumber"
            />
            <div
              *ngIf="f.submitted && serialnumber.invalid"
              class="text-danger"
            >
              Campo número da tag é obrigatório.
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="error" *ngIf="msgResult">
            <span [innerHTML]="msgResult"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-tertiary mr-4"
        (click)="modalRef.hide()"
        id="close"
      >
        Cancelar
      </button>

      <button
        class="btn btn-secondary"
        [ngClass]="{ 'btn-load': loading }"
        [disabled]="loading"
        id="btn-confirm"
      >
        <span *ngIf="!loading">Confirmar</span>
        <img
          *ngIf="loading"
          src="../../../assets/img/loader.gif"
          alt="Loading"
        />
      </button>
    </div>
  </form>
</div>
