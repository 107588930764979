import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { ResponseDto } from '@models/responseDto.model';
import { GroupsCurrentUser } from '@models/group-user.model';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectSystemService {

  constructor(protected http: HttpClient) { }

  getGroupsByCurrentUser() {
    return this.http.get<ResponseDto<GroupsCurrentUser>>(
      `${environment.apiTMIdentityServer}/CustomerPortal/User/GetGroupsByCurrentUser`
    );
  }

  async getPromisseGroupsByCurrentUser(): Promise<ResponseDto<GroupsCurrentUser>> {
    try {
      const response = await lastValueFrom(
        this.http.get<ResponseDto<GroupsCurrentUser>>(
          `${environment.apiTMIdentityServer}/CustomerPortal/User/GetGroupsByCurrentUser`
        )
      );
      return response;
    } catch (error) {
      console.error('Erro ao obter grupos do usuário:', error);
      throw error;
    }
  }
  downloadClientBlockingSettingsCsv(): Observable<Blob> {
    return this.http.get(`${environment.apiTMContractCSV}`, { responseType: 'blob' });
  }


}
