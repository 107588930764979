import { Validators, AbstractControl } from '@angular/forms';
import { DateConfigDto } from './dtos/date-config.dto';

export class DateTimeValidator {
    constructor() {}
    
    static isValidDate(config:DateConfigDto=null) {
      return (control: AbstractControl): Validators => {
     
        let day = control.value ? control.value.getDate() : null
        let month = control.value ? control.value.getMonth() + 1 : null
        let year = control.value ? control.value.getFullYear() : null

        let today = new Date()
       
        let dateDay = day ? (day.toString().length == 1 ? day.toString().padStart(2, '0') : day.toString()) : null;
        let dateMonth = month ? (month.toString().length == 1 ?  month.toString().padStart(2, '0') : month.toString()) : null;
        let dateYear = year ? year.toString() : null;
        let date = dateDay && dateMonth && dateYear ? dateDay+dateMonth+dateYear : null;

        if(date && date.length !== 8){
            return { DateInvalid: true };
        } 
        else if(date && date.length == 8){
            if(day < 1 || day > 31){
                return { DateInvalid: true };
            }

            if(month < 1 || month > 12){
                return { DateInvalid: true };
            }

            if(config && !config.allowFutureDate && today < control.value){
                return { futureDate: true };
            }
        }

    return null;
   };
 }

 static isValidHour() {
    return (control: AbstractControl): Validators => {

        let hour = control.value ? control.value.slice(0,2) : null
        let minute = control.value ? control.value.slice(2,4) : null

        if(control.value && control.value.length !== 4){
            return { HourInvalid: true };
        } 
        else if(control.value && control.value.length == 4){
           if(Number(hour) > 23){
              return { HourInvalid: true };
           }

           if(Number(minute) > 59){
              return { HourInvalid: true };
           }
      }

  return null;
 };
}


}