import { Component } from '@angular/core';

@Component({
  selector: 'app-header-form-insert-holiday',
  templateUrl: './header-form-insert-holiday.component.html',
  styleUrl: './header-form-insert-holiday.component.scss'
})
export class HeaderFormInsertHolidayComponent {
  tableHeader = ['Nome do feriado', 'Data', 'Repetir anualmente', 'Ações']
}
