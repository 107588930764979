
<main class="CardComponent">
  <section class="table-card">
    <div class="table-header">
        <app-outlined-select
          [title]="'Ano'"
          [values]="years"
          (valueChange)="onYearChange($event)"
          [disableRipple]="true">
        </app-outlined-select>

        <app-borderless-button
          [description]="'Incluir feriado'"
          [classIconLeft]="'ico-add'"
          [id-html]="'downloadButton'"
          [data-e2e]="'export-btn'"
          [isBtnWithBorder]="true"
          [customWidthSize]="'11.5rem'"
          [buttonColor]="'primary'"
          (emitterEvtClick)="redirect()">
        </app-borderless-button>

    </div>
    <div class="table-main">
      @if(year){
        <app-table-holidays  [year]="year">
        </app-table-holidays>
      }
    </div>
  </section>
</main>
