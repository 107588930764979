import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['../../login.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private oauthService: OAuthService,    private router: Router) { }

  ngOnInit(): void {
  }

  login(){
    this.oauthService.initLoginFlow();
  }

  firstAccess(){
    this.router.navigate(['/primeiro-acesso']);
  }

}
