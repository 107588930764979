import { finalize } from 'rxjs';
import {Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserConsultantCreateUpdateService } from './services/user-consultant-create-update.service';
import { NotifyService } from '@services/notify.service';
import { TelephoneValidator } from '@services/form-validators/telephone-validator.service';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { MasksService } from '@services/masks.service';
import { ValidateUserByEmailAndGroupDto } from './dtos/validate-user-by-email-group.dto';
import { ValidateUserByDocumentAndGroupDto } from './dtos/validate-user-by-document-group.dto';
import { UserConsultantModalComponent } from './components/user-consultant-modal/user-consultant-modal.component';
import {UserModel} from "@models/backoffice/user.model";
import {cpfValidator} from "@services/form-validators/cpf-validator-directive";
import {EPageType} from "@modules/backoffice/user-consultant-create-update/enums/enum-page-type";
import {
  UserDetailsFillFormModel
} from "@modules/backoffice/user-consultant-create-update/models/user-details-fill-form.model";
import {ActivatedRoute, Router} from "@angular/router";
import {
  UserConsultantCreateUpdateConst
} from "@modules/backoffice/user-consultant-create-update/constants/const-user-consultant-create-update";
import {RoutesPaths} from "../../../core/utils/routes/routes-paths";

@Component({
  selector: 'app-user-consultant-create-update',
  templateUrl: './user-consultant-create-update.component.html',
  styleUrls: ['./user-consultant-create-update.component.scss']
})
export class UserConsultantCreateUpdateComponent implements OnInit {
  protected readonly pageType = EPageType;
  activePage: EPageType;
  pageTitle: string;
  isLoading: boolean;

  // Page
  PAGE_TITLE_CREATE: string;
  PAGE_TITLE_UPDATE: string;
  GROUP_ID: number;
  EMAIL_DOMAIN: string;
  // Modal
  TITLE_CREATE: string;
  TITLE_UPDATE: string;
  SUBTITLE_CONFIRM: string;
  SUBTITLE_FEEDBACK_CREATE: string;
  SUBTITLE_FEEDBACK_UPDATE: string;

  userConsultantForm: FormGroup;
  userInicialValuesForm: FormGroup;
  days: object[];
  months: object[];
  years: number[];
  dateOfBirthFull: string;
  emailFull: string;
  documentNumberValid: boolean;
  emailAddressValid: boolean;
  isValidatingDocument: boolean;
  isValidatingEmail: boolean;
  validateDocumentDto: ValidateUserByDocumentAndGroupDto;
  validateEmailDto: ValidateUserByEmailAndGroupDto;
  userDto: UserModel;
  userInicialDetails: UserDetailsFillFormModel;
  hasChangesFormUpdate: boolean;
  private id: any;

  constructor(
    private _userConsultantCreateUpdateService: UserConsultantCreateUpdateService,
    private _formValidatorMessageService: FormValidatorMessageService,
    private _masksService: MasksService,
    private _modalService: NgbModal,
    private _notifyService: NotifyService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
  }

  ngOnInit() {
    this.settingsInitClass();
    this.settingsByPageType()
  }

  private settingsInitClass(): void {
    this.documentNumberValid = true;
    this.emailAddressValid = true;

    this.PAGE_TITLE_CREATE = UserConsultantCreateUpdateConst.PAGE_TITLE_CREATE;
    this.PAGE_TITLE_UPDATE = UserConsultantCreateUpdateConst.PAGE_TITLE_UPDATE;
    this.GROUP_ID = UserConsultantCreateUpdateConst.GROUP_ID;
    this.EMAIL_DOMAIN = UserConsultantCreateUpdateConst.EMAIL_DOMAIN;
    this.TITLE_CREATE = UserConsultantCreateUpdateConst.TITLE_CREATE;
    this.TITLE_UPDATE = UserConsultantCreateUpdateConst.TITLE_UPDATE;
    this.SUBTITLE_CONFIRM = UserConsultantCreateUpdateConst.SUBTITLE_CONFIRM;
    this.SUBTITLE_FEEDBACK_CREATE = UserConsultantCreateUpdateConst.SUBTITLE_FEEDBACK_CREATE;
    this.SUBTITLE_FEEDBACK_UPDATE = UserConsultantCreateUpdateConst.SUBTITLE_FEEDBACK_UPDATE;

    this.userConsultantForm = new FormGroup({
      fullName: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]+( [A-Za-zÀ-ÖØ-öø-ÿ]+)*$')]),
      documentNumber: new FormControl('', [Validators.required, cpfValidator]),
      dateOfBirthDay: new FormControl('', [Validators.required]),
      dateOfBirthMonth: new FormControl('', [Validators.required]),
      dateOfBirthYear: new FormControl('', [Validators.required]),
      telephoneNumber: new FormControl('55', [Validators.required, TelephoneValidator.isValidTelephone(true)]),
      emailAddress: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+$')]),
      protocol: new FormControl('', [Validators.required, Validators.pattern('^\\S*$')]),
    });

    this.userDto = new UserModel();
    this.validateEmailDto = new ValidateUserByEmailAndGroupDto();
    this.validateDocumentDto = new ValidateUserByDocumentAndGroupDto();

    this.fillSelectOptionsDateOfBirth();
  }

  private settingsByPageType(): void {
    this._activatedRoute.params.subscribe(params => {
      this.id = params['id'];

      this.activePage = EPageType.CREATE;
      this.pageTitle = this.PAGE_TITLE_CREATE;

      if (this.id) {
        this.activePage = EPageType.UPDATE;
        this.pageTitle = this.PAGE_TITLE_UPDATE;
        this.userConsultantForm.controls.documentNumber.disable();

        this.userInicialDetails = new UserDetailsFillFormModel();
        this.getUserDetailsById();
      }
    });
  }

  fillSelectOptionsDateOfBirth(): void {
    this.days = [
      {"desc": '1',"value": '01'},
      {"desc": '2',"value": '02'},
      {"desc": '3',"value": '03'},
      {"desc": '4',"value": '04'},
      {"desc": '5',"value": '05'},
      {"desc": '6',"value": '06'},
      {"desc": '7',"value": '07'},
      {"desc": '8',"value": '08'},
      {"desc": '9',"value": '09'},
      {"desc": '10',"value": '10'},
      {"desc": '11',"value": '11'},
      {"desc": '12',"value": '12'},
      {"desc": '13',"value": '13'},
      {"desc": '14',"value": '14'},
      {"desc": '15',"value": '15'},
      {"desc": '16',"value": '16'},
      {"desc": '17',"value": '17'},
      {"desc": '18',"value": '18'},
      {"desc": '19',"value": '19'},
      {"desc": '20',"value": '20'},
      {"desc": '21',"value": '21'},
      {"desc": '22',"value": '22'},
      {"desc": '23',"value": '23'},
      {"desc": '24',"value": '24'},
      {"desc": '25',"value": '25'},
      {"desc": '26',"value": '26'},
      {"desc": '27',"value": '27'},
      {"desc": '28',"value": '28'},
      {"desc": '29',"value": '29'},
      {"desc": '30',"value": '30'},
      {"desc": '31',"value": '31'},
    ]

    this.months = [
      {"desc": 'Janeiro',"value": '01'},
      {"desc": 'Fevereiro',"value": '02'},
      {"desc": 'Março',"value": '03'},
      {"desc": 'Abril',"value": '04'},
      {"desc": 'Maio',"value": '05'},
      {"desc": 'Junho',"value": '06'},
      {"desc": 'Julho',"value": '07'},
      {"desc": 'Agosto',"value": '08'},
      {"desc": 'Setembro',"value": '09'},
      {"desc": 'Outubro',"value": '10'},
      {"desc": 'Novembro',"value": '11'},
      {"desc": 'Dezembro',"value": '12'},
    ]

    let currentYear  = new Date().getFullYear();
    // Returns an array of the current year up to the suggested length
    this.years = Array.from({length: 70}, (_, i) => currentYear - i);
  }

  generateErrorMessageByFormControlName(controlName: string): string {
    return this._formValidatorMessageService.generateErrorMessageByFormControlName(controlName);
  }

  getPhoneMask(): string {
    return this._masksService.getPhoneMask(this.userConsultantForm.controls.telephoneNumber.value);
  }

  validateUserByDocumentAndGroup(): void {
    if (this.userConsultantForm.controls.documentNumber.valid) {
      this.validateDocumentDto.groupId = this.GROUP_ID;
      this.validateDocumentDto.documentNumber = this.userConsultantForm.controls.documentNumber.value;

      this.isValidatingDocument = true;

      this._userConsultantCreateUpdateService
        .existsUserByDocumentNumberAndGroup(this.validateDocumentDto)
        .pipe(
          finalize(() => {
            this.isValidatingDocument = false;
          })
        )
        .subscribe({
          next: (success) => {
            if (success.succeeded) {
              this.documentNumberValid = !success.data.existsUser;
            }
          },
          error: (error) => {
            this.userConsultantForm.controls.documentNumber.setErrors({'incorrect': true});
            console.error(error);
            return;
          }
        });
    }
  }

  validateUserByEmailAndGroup(): void {
    if (this.userConsultantForm.controls.emailAddress.valid) {
      this.setEmailFull();

      this.validateEmailDto.groupId = this.GROUP_ID;
      this.validateEmailDto.emailAddress = this.emailFull;

      this.isValidatingEmail = true;

      this._userConsultantCreateUpdateService
        .existsUserByEmailAndGroup(this.validateEmailDto)
        .pipe(
          finalize(() => {
            this.isValidatingEmail = false;
          })
        )
        .subscribe({
          next: (success) => {
            if (success.succeeded) {
              this.emailAddressValid = !success.data.existsUser;
            }
          },
          error: (error) => {
            this.userConsultantForm.controls.emailAddress.setErrors({'incorrect': true});
            console.error(error);
            return;
          }
        });
    }
  }

  isValidAndPastDateOfBirth(): boolean {
    this.fillDateOfBirth();

    let date = new Date(this.dateOfBirthFull);
    let dateNow = new Date();

    if (isNaN(date.getTime())) {
      return false;
    }
    else
    {
      // Check if the input string matches the formatted string of the created date
      const year = date.getFullYear();
      const month = date.getMonth() + 1;  // JavaScript months are 0-based
      const day = date.getDate();
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T03:00:00.000Z`;

      if (this.dateOfBirthFull !== formattedDate || date > dateNow) {
        this._notifyService.showError(
          'Atenção!',
          'Informe uma data válida!',
        );
        return false;
      }

      return true
    }
  }

  fillDateOfBirth(): void {
    this.dateOfBirthFull =
      this.userConsultantForm.controls.dateOfBirthYear.value +'-'+
      this.userConsultantForm.controls.dateOfBirthMonth.value +'-'+
      this.userConsultantForm.controls.dateOfBirthDay.value +
      'T03:00:00.000Z'
    ;
  }

  assignRequestAttributes(): void {
    this.userDto.groupId = this.GROUP_ID;
    this.userDto.fullName = this.userConsultantForm.controls.fullName.value;
    this.userDto.documentNumber = this.userConsultantForm.controls.documentNumber.value;
    this.userDto.personDocumentTypeId = 1; // CPF
    this.userDto.dateofBirth = this.dateOfBirthFull;
    this.userDto.personPhoneCollection = [{
      telephoneNumber: this.userConsultantForm.controls.telephoneNumber.value,
      prefix: this.userConsultantForm.controls.telephoneNumber.value.slice(2,4),
      areaCode: this.userConsultantForm.controls.telephoneNumber.value.slice(2,4),
      countryCode: this.userConsultantForm.controls.telephoneNumber.value.slice(0,2),
      personPhoneTypeId: this.userConsultantForm.controls.telephoneNumber.value.length == 12 ? 1 : 2, // 1-Fixed | 2-Cell Phone
      principalFlg: true
    }];
    this.userDto.personEmailAddressCollection = [{
      emailAddress:  this.emailFull,
      principalFlg: true
    }];
    this.userDto.protocol = this.userConsultantForm.controls.protocol.value;
  }

  assignModalAttributes(): void {
    const modal = this._modalService.open(UserConsultantModalComponent);
    modal.componentInstance.activePage = this.activePage;
    modal.componentInstance.userDto = this.userDto;
    modal.componentInstance.subtitleConfirm = this.SUBTITLE_CONFIRM;

    modal.componentInstance.title = this.TITLE_CREATE;
    modal.componentInstance.subtitleFeedback = this.SUBTITLE_FEEDBACK_CREATE;

    if (this.activePage === EPageType.UPDATE) {
      modal.componentInstance.title = this.TITLE_UPDATE;
      modal.componentInstance.subtitleFeedback = this.SUBTITLE_FEEDBACK_UPDATE;
    }

    modal.componentInstance.eventHandlePage.subscribe(() => {
      this.resetFormValues();

      if (this.activePage === this.pageType.UPDATE) {
        this._router.navigate(['/backoffice/' + RoutesPaths.CONSULTANT_VIEW]);
      }
    });
  }

  resetFormValues(): void {
    this.userConsultantForm.reset({
      fullName: '',
      documentNumber: '',
      dateOfBirthDay: '',
      dateOfBirthMonth: '',
      dateOfBirthYear: '',
      telephoneNumber: '55',
      emailAddress: '',
      protocol: '',
    });
  }

  openConfirmationModal(): void {
    if (this.userConsultantForm.valid && this.isValidAndPastDateOfBirth()) {

      if (this.activePage === this.pageType.CREATE) {
        this.assignRequestAttributes();
        this.assignModalAttributes();
        return;
      }

      this.handlePageUpdateValidations();
    }
  }

  cancelForm() {
    let path = '/backoffice/' + RoutesPaths.MAIN_MENU_BACKOFFICE;

    if (this.activePage === this.pageType.UPDATE) {
      path = '/backoffice/' + RoutesPaths.CONSULTANT_VIEW;
    }

    this._router.navigate([path]);
  }

  getUserDetailsById(): void {
    this.isLoading = true;

    this._userConsultantCreateUpdateService
      .getUserDetailsById(Number.parseInt(this.id))
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          if (success.success && success.data) {
            this.userInicialDetails = success.data;
            this.fillFormUserDetails(this.userInicialDetails);
            this.handleFormValueChanges();
          }
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  private fillFormUserDetails(userDetails: UserDetailsFillFormModel): void {
    let dateFull = userDetails.dateofBirth.split('T')[0];
    let dateParts = dateFull.split('-');
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];

    let emailWithoutDomain = userDetails.emailAddress.split('@')[0];

    let telephoneFull = userDetails.countryCode + userDetails.areaCode + userDetails.telephoneNumber;
    let telephoneWithoutSpaces = telephoneFull.split(' ').join('');

    this.userConsultantForm.setValue({
      fullName: userDetails.fullName,
      documentNumber: userDetails.documentNumber,
      dateOfBirthDay: day,
      dateOfBirthMonth: month,
      dateOfBirthYear: year,
      telephoneNumber: telephoneWithoutSpaces,
      emailAddress: emailWithoutDomain,
      protocol: ''
    });
  }

  private handleFormValueChanges(): void {
    this.userInicialValuesForm = this.userConsultantForm.value;

    this.userConsultantForm.valueChanges.subscribe((value) => {
      this.hasChangesFormUpdate = this.hasFormChanges();
    });
  }

  private hasFormChanges(): boolean {
    const currentFormValues = this.userConsultantForm.value;

    const hasChanges = Object.keys(currentFormValues).some((key) => {
      if (key !== 'protocol') {
        return this.userInicialValuesForm[key] != currentFormValues[key];
      }
    });

    return hasChanges;
  }

  private setEmailFull(): void {
    this.emailFull = this.userConsultantForm.controls.emailAddress.value + this.EMAIL_DOMAIN;
  }

  private handlePageUpdateValidations() {

    if (this.hasFormChanges()) {
      this.setEmailFull()
      this.assignRequestAttributes();
      this.assignModalAttributes();
      return;
    }

    this._notifyService.showError(
      'Atenção!',
      'Os dados não foram alterados. Altere-os para seguir com a edição.',
    );
  }

}

