import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportConciliationDocumentModalComponent } from '../import-conciliation-document-modal/import-conciliation-document-modal.component';
import { finalize } from 'rxjs';
import {
  TransactionPartnersConciliationService
} from "@modules/backoffice/transaction-partners-conciliation/services/transaction-partners-conciliation.service";

1
@Component({
  selector: 'app-conciliation-feedback-modal',
  templateUrl: './conciliation-feedback-modal.component.html',
  styleUrls: ['./conciliation-feedback-modal.component.scss']
})
export class ConciliationFeedbackModalComponent{
  @Input() hasErrors: boolean = false
  @Input() totalErrors:  number
  @Input() Errors: any[] = [];
  @Input() AlreadyExistsImportedFile: any = false;
  @Input() itemId: number
  @Input() itemInfo : string

  constructor(private _modalService: NgbModal, private _transactionPartnersConciliationService : TransactionPartnersConciliationService) {}


  dismissModal() {
    this._modalService.dismissAll();
  }



  newImport(){
    this.dismissModal()
    this._modalService.open(ImportConciliationDocumentModalComponent)
  }

  DownloadItemWithErrors(itemId: any) {
    this._transactionPartnersConciliationService
      .getTransactionPartnersConciliationItemCsv(itemId)
      .pipe(
        finalize(() => {}),
      )
      .subscribe((x) => {
        this.getCsvFile(x)
      });
  }

  getCsvFile(x : any){
    const element = document.createElement('a');
    var newBlob = new Blob([x.file], { type: 'text/csv' });
    element.href = URL.createObjectURL(newBlob);
    element.download = 'Inconsistências conciliação_'+ this.itemInfo
    element.click()
  }
}
