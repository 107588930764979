import { AbstractControl } from '@angular/forms';

/** Valida um CPF */
export function cpfValidator(control: AbstractControl) {
  if (!control.value) return null;

  const strCPF: string = control.value.replace(/[^0-9]/g, '');

  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF == '00000000000') return { errorCpf: true };

  for (var i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return { errorCpf: true };

  Soma = 0;
  for (i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return { errorCpf: true };

  return null;
}
