import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";
import { HolidaysCalendarComponent } from '../holidays-calendar.component';
import { HolidayCalendarInsertComponent } from '../components/holiday-calendar-insert-page/holiday-calendar-insert-page.component';


const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.HOLIDAYS_CALENDAR,
        component: HolidaysCalendarComponent,
        canActivate: [authBackOfficeGuard],
        data: {

          roles: '100993',
          contractRequired: false,
        },
      },
      {
        path: RoutesPaths.INSERT_HOLIDAYS,
        component: HolidayCalendarInsertComponent,
        canActivate: [authBackOfficeGuard],
        data: {

          roles: '100993',
          contractRequired: false,
        },
      },
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HolidaysCalendarRoutingModule { }
