<card-model class="container-dashboard container-repom container-tag-responsive">
  <header class="dashboard-card-header">
    <div class="dashboard-card-title-box">
      <i class="ico ico-tag"></i>
      <h2 class="card-title" data-e2e="tags-card-title">Tags</h2>
    </div>

    <div>
      <div style="margin-right: -4rem;">
        <app-borderless-button [description]="'Ativar TAGs'"
                                   [classIconRight]="'ico-double-arrow'"
                                   [routerLink]="'/ativar-tags'"
                                   [id-html]="'activeTagsButton'"
                                   [data-e2e]="'activate-tag-link'"
                                   [verifyContractBlock]="true"
                                   [buttonColor]="'secundary'"
        >
        </app-borderless-button>
      </div>
    </div>
  </header>

  <section class="col-lg-4 tag-total-responsive">
    <div data-e2e="tag-total" class="tag-total">
      <p>Total de Tags</p>
      <strong id="totalQuantity-responsive" class="tags-count">{{ tag?.totalQuantity }}</strong>
    </div>
  </section>

  <main class="row tag-card-content">
    <section class="col-lg-4">
      <h3 data-e2e="available-tag" class="box-featured box-featured-1">
        <span class="bullet color-1"></span>

        <div>
          Em estoque <br />
          <strong id="availableQuantity">{{ tag?.availableQuantity }}</strong>
        </div>
      </h3>

      <h3 data-e2e="bonus-tag" class="box-featured box-featured-1">
        <span class="bullet color-1"></span>

        <div>
          Bonificadas <br />
          <strong id="bonusQuantity">{{ tag?.bonificationQuantity }}</strong>
        </div>
      </h3>
    </section>

    <section class="col-lg-4">
      <h3 data-e2e="tag-total" class="tag-total text-center">
        <p>Total de Tags</p>
        <strong id="totalQuantity" class="tags-count">{{ tag?.totalQuantity }}</strong>
      </h3>
    </section>

    <section class="col-lg-4">
      <h3 data-e2e="active-tag" class="box-featured box-featured-2">
        <span class="bullet color-2"></span>

        <div>
          Ativas <br />
          <strong id="activeQuantity">{{ tag?.activeQuantity }}</strong>
        </div>
      </h3>

      <h3 data-e2e="blocked-tag" class="box-featured box-featured-3">
        <span class="bullet color-3"></span>

        <div>
          Bloqueadas <br />
          <strong id="blockedQuantity">{{tag?.blockedQuantity}}</strong>
        </div>
      </h3>
    </section>

  </main>
</card-model>
