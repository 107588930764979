<div class="verificationCodeComponent">
    <div class="code-content">
        <div class="label">Digite seu código</div>
        <code-input [isCodeHidden]="false"
            [ngClass]="!validCode ? 'invalid' : ''"
            [codeLength]="6"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
        </code-input>
        <div class="alert-error-validator" data-e2e="verification-code-error-msg" *ngIf="!validCode">
            Código inválido.
        </div>
        <div class="resend-code">Não recebi meu código<span class="counter" *ngIf="counter > 0"> {{ counter }}s</span>: 
            <span *ngIf="!isLoading" [ngClass]="counter > 0 ? 'disabled' : ''" (click)="sendCode()">Enviar novamente</span>
            <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
        </div>
        <div class="message" *ngIf="message">Código enviado!</div>
    </div>
</div>

