import { HttpParams } from '@angular/common/http';

export class GetTicketsQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  asc: boolean;
  desc: boolean;
  search:string;
  filter:number;
  TransactionType:number;
  startDate: Date;
  endDate: Date;
  MinimumAmount:number;
  MaximumAmount:number;
  HierarchyId:number;
  isEstacionamento: boolean = null;
  estacionamentoId: number[] = [15,16];
  pedagioId: number[] = [6,9];

  get httpParans() {
    let httpParams = new HttpParams()
    .append('page', this.page.toString())
    .append('pageSize', this.pageSize.toString())
    .append('sortField', this.orderBy)
    .append('SortOrder', this.desc ? '0' : '1')
    .append('filter', this.filter?.toString())
    // .append('TransactionType', this.TransactionType?.toString())
     // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC. 
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.HierarchyId > 0){
      httpParams = httpParams.append('HierarchyId', this.HierarchyId.toString());
    }
    if(this.MinimumAmount >= 0){
      httpParams = httpParams.append('MinimumAmount', this.MinimumAmount.toString());
    }
    if(this.MaximumAmount > 0){
      httpParams = httpParams.append('MaximumAmount', this.MaximumAmount.toString());
    }
    if(this.startDate){
      httpParams = httpParams.append('startDate', this.startDate.toISOString());
    }
    if(this.search){
      httpParams = httpParams.append('search', this.search);
    }

     // As datas são selecionadas pelo usuário no timeZone do usuário, mas são enviadas para o backend no formato UTC.
    // Veja mais em: https://dev.azure.com/ERBR-Repom/TollManagement/_wiki/wikis/TollManagement.wiki/254/Conven%C3%A7%C3%A3o-de-DateTime-UTC
    if(this.endDate){
      httpParams = httpParams.append('endDate', this.endDate.toISOString());
    }
    if (this.isEstacionamento != null){
      if (this.isEstacionamento)
        this.estacionamentoId.forEach((element) => httpParams = httpParams.append('TransactionTypeId', element.toString()));
      else
        this.pedagioId.forEach((element) => httpParams = httpParams.append('TransactionTypeId', element.toString()));
    }

    return httpParams;

  };
}


