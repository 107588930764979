import { Component, OnInit, Input, Pipe, PipeTransform  } from '@angular/core';
import { BillVehiclesQueryArguments } from '../../dto/bill-vehicles-query.arguments';
import { Item,BillVehiclesResultDto,BillVehiclesDataDto } from '../../dto/bill-vehicles-result.dto';
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { BillDetailVehicles} from '../../services/bill-detail-vehicles.service';
import { ActivatedRoute } from '@angular/router'
import { BillHierarchyBreadCrumbResultDto } from '../../dto/bill-hierarchy-bread-crumb-result.dto';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-grid-vehicles',
  templateUrl: './grid-vehicles.component.html',
  styleUrls: ['./grid-vehicles.component.scss']
})
export class GridVehiclesComponent implements OnInit {

  total:number;
  billId:number;
  BillVehiclesQueryArguments: BillVehiclesQueryArguments;
  BillVehiclesResultDto: BillVehiclesResultDto;
  filterDebounce: Subject<BillVehiclesQueryArguments> = new Subject<BillVehiclesQueryArguments>();
  public InfoVehicleHeadAmonts
  BillHierarchyBreadCrumbResultDto: BillHierarchyBreadCrumbResultDto;

  isLoading: boolean;
  isLoadingPage: boolean;
  perPages = []
  public licensePlate;
  public InfoVehicleHead;
  public BillVehicleList;
  public OldPage;
  public OldPageSize;

public ChangeVehicleList(){
  this.licensePlate = ''
}

 public OpenVehicleInfos(licensePlate,hierarchyId,hierarchyDescription,nickname){
     this.nickname = nickname;
     this.licensePlate = licensePlate;
     this.BillVehiclesQueryArguments.search = licensePlate
     let hierarchy;
     this.BillVehiclesQueryArguments.page = 1;
     this.BillVehiclesQueryArguments.pageSize = 10;
     this.BillVehiclesQueryArguments.sortField = 'passengerDate';
     this.BillVehiclesQueryArguments.desc = true;
     this.OldPage = 1;
     this.OldPageSize = 10;


     if(licensePlate !== null){
      this.getBillDetailVehicles(this.BillVehiclesQueryArguments)
     }

   }

 addClass(position){
   if(position % 2 === 0) {
     return "linetype1"
   }
   else{
     return "linetype2"
   }
}

getBillDetailVehicles(args: BillVehiclesQueryArguments){

  if(this.OldPage  !== args.page || this.OldPageSize  !== args.pageSize){
    this.isLoadingPage = true;
    this.isLoading = false;
  }
  else{
    this.isLoading = true;
    this.isLoadingPage = false;
  }

  this.billDetail.getBillVehicles(args)
  .pipe(
    finalize(() => {
      this.isLoading = false;
      this.isLoadingPage = false;
    })
  )
  .subscribe(
    (success) => {

      this.gridVehicles(success)
     // this.getBillVehiclesQueryResult = success;
    },
    (error) => {
      console.error(error);
      return;
    }
  );
}

compareDay (aux1:Date,aux2:Date):boolean{
  return new Date(aux1).setHours(0,0,0,0) == new Date(aux2).setHours(0,0,0,0);
}

getHierarchyList(){
  this.isLoading = true;
  this.billDetail.getHierarchyBreadCrumbList()
  .pipe(
    finalize(() => {
      this.isLoading = false;
    })
  )
  .subscribe(
    (success) => {
      this.VehicleHierarchy(success)
    },
    (error) => {
      console.error(error);
      return;
    }
  );
}

ShortCode(code){
  let ShortCode = ''
  if(code !== null){
    ShortCode = code.slice(-7)
  }
  return ShortCode
}

VehicleHierarchy(list){
 this.BillHierarchyBreadCrumbResultDto = list
}

public nickname
public axleDescriptionPt
public serial
public vehicleTypeDescription

gridVehicles(billVehicles){

 this.licensePlate = billVehicles.data[0].licensePlate
 this.nickname = billVehicles.data[0].registerNickName
 this.axleDescriptionPt = billVehicles.data[0].axleDescriptionPt
 this.serial = billVehicles.data[0].serial
 this.vehicleTypeDescription = billVehicles.data[0].vehicleTypeDescription

this.InfoVehicleHeadAmonts = {
  amountTotal: billVehicles.data[0].amountTotal
 ,amountServices: billVehicles.data[0].amountServices
 ,amountPassenger: billVehicles.data[0].amountPassenger
 ,amountReversal: billVehicles.data[0].amountReversal
 ,amountParking: billVehicles.data[0].amountParking
 ,amountParkingReversal: billVehicles.data[0].amountParkingReversal
 ,amountVPR: billVehicles.data[0].amountVPR
}

 this.BillVehiclesResultDto = billVehicles
 this.BillVehicleList = billVehicles.data[0].items;

}

  constructor(private billDetail: BillDetailVehicles, private actRoute: ActivatedRoute, private localeService: BsLocaleService) {
    this.billId = this.actRoute.snapshot.params.id;
    this.localeService.use('pt-br');
   }



  ngOnInit() {
    this.licensePlate = '';
    this.perPages = [10, 20, 50];

    this.BillVehiclesResultDto = {
      success: true,
      message: '',
      total:0,
      data: []
    };

    this.BillVehiclesQueryArguments = new BillVehiclesQueryArguments();
    this.BillVehiclesQueryArguments.id = this.billId;
    this.BillVehiclesQueryArguments.page = 1;
    this.BillVehiclesQueryArguments.sortField = 'passengerDate';
    //this.BillVehiclesQueryArguments.search = '';
    this.BillVehiclesQueryArguments.pageSize = 10;
    this.BillVehiclesQueryArguments.desc = true;
    this.OldPage = 1;
    this.OldPageSize = 10;

    this.getHierarchyList();
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.BillVehiclesQueryArguments.sortField == field && !this.BillVehiclesQueryArguments.desc,
      'order-by-desc': this.BillVehiclesQueryArguments.sortField == field && this.BillVehiclesQueryArguments.desc
    }
  }
  onPaginationChange(page: number) {
    this.OldPage = this.BillVehiclesQueryArguments.page
    this.BillVehiclesQueryArguments.page = page;
    this.getBillDetailVehicles(this.BillVehiclesQueryArguments)
  }

  onPageSizeChange(value: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.BillVehiclesQueryArguments.page = 1;
    this.OldPageSize = this.BillVehiclesQueryArguments.pageSize
    this.BillVehiclesQueryArguments.pageSize = +value;
    this.getBillDetailVehicles(this.BillVehiclesQueryArguments)
    //this.getFromServer(this.getVehiclesQueryArguments);
  }


  onOrderByChange(field: string) {
    if (this.BillVehiclesQueryArguments.sortField === field) {
      this.BillVehiclesQueryArguments.desc = !this.BillVehiclesQueryArguments.desc;
    } else {
      this.BillVehiclesQueryArguments.sortField = field;
      this.BillVehiclesQueryArguments.desc = true;
    }
    this.getBillDetailVehicles(this.BillVehiclesQueryArguments)

  }
}


