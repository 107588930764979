export const UserConsultantDeleteConst = {
  TITLE: 'Excluir consultor',
  SUBTITLE_CONFIRM: 'Você está prestes a excluir o consultor, tem certeza?',
  SUBTITLE_FEEDBACK: 'Consultor excluído com sucesso!',
  TEXT_CONFIRM: 'Após a confirmação, o usuário terá o acesso de consultor de relacionamento desativado e a ação não poderá ser desfeita.',
  TEXT_FEEDBACK: 'Consultor excluído e o acesso foi desativado.',
  IMG_PATH: '/assets/img/feedback-success-modal.png',
}

export const TableHeadersUserConsultantConst = {
  FULLNAME: 'FullName',
  EMAIL_ADDRESS: 'EmailAddress',
  STATUS_USERID: 'StatusUserId',
  REGISTRATION_DATE: 'RegistrationDate',
  LAST_ACCESS: 'LastAccess',
}
