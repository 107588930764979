import { Component } from '@angular/core';
import { ProgressBarService } from '@services/notification-alert/progress-bar.service';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-custom-default-alert',
  templateUrl: './custom-default-alert.component.html',
  styleUrls: ['./custom-default-alert.component.scss']
})


export class CustomDefaultAlertComponent extends Toast {

  progressBarWidth: number = 0;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    public progressBarService: ProgressBarService
  ) {
    super(toastrService, toastPackage);
    this.initProgressBar();
    this.getWidth();
  }

  getWidth(){
    this.progressBarService._widthObservable(this.toastPackage.toastId)
    .subscribe(data => {       
        this.progressBarWidth = data;
    });
  }

  initProgressBar(){     
     this.progressBarService.initProgressBar(this.toastPackage.toastId,this.options.timeOut)
  }  

  close(){
    this.progressBarService.close(this.toastPackage.toastId)
  }


}


