
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedFirstAccessService {

  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.data.asObservable();
  private user;

  constructor() { }

  setData(newData) {
    this.data.next(newData);
  }

  set userToEdit(user) {
    this.user = user;
  }

  get userToEdit() {
    return this.user;
  }

}