import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractVehicleTagService } from 'src/app/core/services/contractvehicletag.service';
import { ContractVehicleTagAndUserRegister } from '@models/old/contractvehicletag/contractvehicletagandusertegister.model';
import { NotifyService } from '@services/notify.service';
import { EventEmitterService } from '@services/event-emitter.service';
import { ContractVehicleTagAlterService } from '@models/old/contractvehicletag/contractvehicletagalterservice.model';


@Component({
  selector: 'app-alter-service-modal',
  templateUrl: './alter-service-modal.component.html',
  styleUrls: ['./alter-service-modal.component.scss']
})
export class AlterServiceModalComponent implements OnInit {
  //public vehicle: GetVehicleQueryResultDto;
  public stepconfirmacao: boolean;

  @Input() vehicle;
  event: EventEmitter<any> = new EventEmitter();
  onSaving: boolean;
  isEmpty: any = '';
  ServiceDescription: string;
  buttonDisabled : boolean;
  public changeDate; // apagar
  changeUserFullName: string; //apagar
  service1:boolean;
  service2:boolean;
  serviceid: number;
  public contractVehicleTagUserRegister: ContractVehicleTagAndUserRegister;

  constructor(private modalService: NgbModal,
              private bsModalRef: BsModalRef,
              protected notifyService: NotifyService,
              private contractVehicleTagService: ContractVehicleTagService
    ) {
      this.onSaving = false;
      this.stepconfirmacao = true;
      this.buttonDisabled = true
      this.changeDate = new Date; //apagar
      this.changeUserFullName = 'Usuario teste'; //apagar

    }

  ngOnInit(){
      if(this.vehicle.contractVehicleTagServiceTypeId == 1){
        this.service1 = true;
        this.service2 = false;
      }
      else{
        this.service1 = false;
        this.service2 = true;
      }
    this.getNameService(this.vehicle.contractVehicleTagServiceTypeId)
  }


  dismissModal() {
    this.modalService.dismissAll();
  }

  emitChangeAndDismissModal(){
    EventEmitterService.get('RefreshAfterAlterService').emit();
    this.dismissModal()
  }

  alterServiceContractVehicleTag() {
    this.onSaving = true;
    const rq = new ContractVehicleTagAlterService();
    rq.contractVehicleTagServiceTypeId = +this.serviceid;
    let id = this.vehicle.contractVehicleTagId;
    this.contractVehicleTagService.saveContractVehicleTagAlterService(rq,id)
    .pipe(finalize(() => { this.onSaving = false; }))
    .subscribe(
      (success) => {
        this.contractVehicleTagUserRegister = success.contractVehicleTagAndUserRegister;
        this.stepconfirmacao = false;
        this.event.emit(true);
        this.notifyService.showSuccess('Atenção!', 'Alteração de Serviço realizada com sucesso!');
        this.event.emit(success);
      },
      (e) => {
       console.log(e)
      }
    );
  }

  getNameService(service){
    if(service == 1){
      this.ServiceDescription = 'Pedágio + Estacionamento';
     }
     else{
       this.ServiceDescription = 'Pedágio';
     }
  }

  onItemChange(event){
    this.getNameService(event.target.value);
    if(event.target.value == this.vehicle.contractVehicleTagServiceTypeId){
      this.buttonDisabled = true;
    }
    else{
      this.buttonDisabled = false;
    }
    this.serviceid = event.target.value;
  }


}
