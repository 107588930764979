import { Component, Input, OnInit,EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-attachment-error-modal',
  templateUrl: './attachment-error-modal.component.html',
  styleUrls: ['./attachment-error-modal.component.scss']
})
export class AttachmentErrorModalComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  @Input() fileError:number;
  @Output() event: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  dismissModal() {
    this.event.emit();
    this.modalService.dismissAll();
  }

}
