import { HttpParams } from '@angular/common/http';

export class GetDeliveryAddressesQueryArguments {

  search:string;

  get httpParans() {
    let httpParams = new HttpParams()
    .append('search', this.search)

    return httpParams;

  };

}


