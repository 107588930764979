<div class="UserConsultantModalComponent">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ title }}</h4>
    <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
      <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>

  <div class="container-loading"
    *ngIf="isLoading"
    id="container-loader-modal"
    data-e2e="container-loader-modal"
  >
    <app-loader
      id="loader-modal"
      data-e2e="loader-modal"
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    ></app-loader>
  </div>

  <div class="modal-body" *ngIf="!isLoading">
    <h6 *ngIf="step == eStepModal.CONFIRM" id="modal-subtitle-confirm">{{ subtitleConfirm }}</h6>
    <h6 *ngIf="step == eStepModal.FEEDBACK" id="modal-subtitle-feedback">{{ subtitleFeedback }}</h6>

    <section class="modal-data-container">
      <img *ngIf="step == eStepModal.FEEDBACK" src="{{ imgPath }}" alt="Imagem de feedback positivo">

      <div class="modal-text--left">
        <p>Nome:</p>
        <p>CPF:</p>
        <p>Data de nascimento:</p>
        <p>Telefone:</p>
        <p>E-mail:</p>
        <p>Protocolo:</p>
      </div>

      <!-- Confirmation data-->
      <div class="modal-text--right" *ngIf="step == eStepModal.CONFIRM">
        <p id="modal-data-fullname">{{ userDto.fullName }}</p>
        <p id="modal-data-documentnumber">{{ userDto.documentNumber | mask: '000.000.000-00'}}</p>
        <p id="modal-data-dateofbirth">{{ userDto.dateofBirth| date: 'dd/MM/yyyy' }}</p>
        <p id="modal-data-telephone">{{ userDto.personPhoneCollection[0].telephoneNumber | mask: getPhoneMask(userDto.personPhoneCollection[0].telephoneNumber) }}</p>
        <p id="modal-data-email">{{ userDto.personEmailAddressCollection[0].emailAddress }}</p>
        <p id="modal-data-protocol">{{ userDto.protocol }}</p>
      </div>
      <!-- Feedback data-->
      <div class="modal-text--right" *ngIf="step == eStepModal.FEEDBACK">
        <p id="modal-data-feedback-fullname">{{ feedbackDto.fullName }}</p>
        <p id="modal-data-feedback-documentnumber">{{ feedbackDto.documentNumber | mask: '000.000.000-00'}}</p>
        <p id="modal-data-feedback-dateofbirth">{{ feedbackDto.dateOfBirth | date: 'dd/MM/yyyy' }}</p>
        <p id="modal-data-feedback-telephone">{{ feedbackDto.telephoneNumber | mask: getPhoneMask(feedbackDto.telephoneNumber) }}</p>
        <p id="modal-data-feedback-email">{{ feedbackDto.email }}</p>
        <p id="modal-data-feedback-protocol">{{ feedbackDto.protocol }}</p>
      </div>
    </section>
    <p
      *ngIf="step == eStepModal.FEEDBACK"
      class="modal-text--gray"
      id="modal-data-resgistration-feedback"
    >
    {{ feedbackDto.registrationDate | date : 'dd/MM/yyyy HH:mm':'UTC-3' }} por {{ feedbackDto.registrationUsername }}
    </p>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="step == eStepModal.CONFIRM"
      class="btn btn-tertiary"
      id="modal-btn-cancel"
      data-e2e="modal-btn-cancel"
      (click)="dismissModal()"
    >
      CANCELAR
    </button>
    <button
      *ngIf="step == eStepModal.CONFIRM"
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      (click)="confirmCreateUpdateUser()"
    >
      CONFIRMAR
    </button>

    <button
      *ngIf="step == eStepModal.FEEDBACK"
      class="btn btn-secondary btn-center"
      id="modal-btn-close-below"
      data-e2e="modal-btn-close-below"
      type="button"
      (click)="dismissModal()"
    >
      Fechar
    </button>
  </div>
</div>
