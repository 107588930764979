import { HttpParams } from '@angular/common/http';

export class RequestQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  filter: number;
  tagRequestStatusType: number[];
  search?: string = '';

  get httpParans() {
    let httpParams = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortField', this.orderBy)
      .append('SortOrder', this.desc ? '1' : '0')
      .append('filter', this.filter?.toString())
      .append('search', this.search.replace(/[^0-9]/g, ''));

    this.tagRequestStatusType.forEach((element) => {
      httpParams = httpParams.append(
        'tagRequestStatusType',
        element.toString(),
      );
    });

    return httpParams;
  }
}
