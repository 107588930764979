export interface ITransactionPartnersConciliaton {
  id: number;
  sourcePartnerTypeId: number;
  reconciledDate: Date | string | null; //data da conciliacao
  partnerName: string;
  documentNumber: string;
  registrationDate: Date | string; //data da importacao
  registrationUserId: number;
  referenceDate: Date | string; //mes referencia
  vprConciliationFileStatusId: vprConciliationFileStatusId | number;
  reconciledValue: number;
  totalErrors: number;
  conciliationErrors: string[];
}

export enum vprConciliationFileStatusId {
  Error = 1,
  Processing = 2,
  Conciliated = 3
}


export interface ITollVoucherConciliationFiles {
  tollVoucherConciliationFiles : ITransactionPartnersConciliaton[];
  total: number
}

export interface ISourcePartner {
    id: number;
    ipefName: string
    validatePassageAfterRecharge: boolean;
    documentNumber: string;
    showPartner: boolean;
    partnerName: string;
}
