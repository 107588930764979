import {NgModule} from "@angular/core";
import {ButtonBlockDirective} from "@directives/button-block/button-block.directive";
import {BtnExportDirective} from "@directives/btn-export.directive";

@NgModule({
  imports: [
  ],
  declarations: [
    ButtonBlockDirective,
    BtnExportDirective
  ],
  exports: [
    ButtonBlockDirective,
    BtnExportDirective
  ],
  providers: [],
})

export class CustomDirectivesModule { }
