import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FirstAccessService } from './firstAccess.service';
import { Router } from '@angular/router';
import { SharedFirstAccessService } from '../../services/sharedFirstAccess.service';
import { finalize } from 'rxjs/operators';
import { User } from '@models/old/user.model';
import { NotifyService } from '@services/notify.service';

@Component({
  selector: 'app-first-access',
  templateUrl: './first-access.component.html',
  styleUrls: ['../../login.component.scss']
})
export class FirstAccessComponent {
  public passwordRegistered: boolean;
  formFirstAccess = this.frmBuilder.group({
    documentNumber: ['', [Validators.required, Validators.maxLength(14)]]
  });

  constructor(protected router: Router, private frmBuilder: FormBuilder, protected firstAccessService: FirstAccessService, protected notifyService: NotifyService, private SharedFirstAccessService: SharedFirstAccessService) { }

  async getUser(documentNumber : string) {
    await this.firstAccessService.getUser(documentNumber).then((result) => {
      var entity = <User>result;
      this.SharedFirstAccessService.setData({user: entity});

      this.router.navigate(["/registrar-senha", documentNumber]);
    }, (errors) =>
    {
      console.error(errors)
      console.log("Promise rejeitada: " + JSON.stringify(errors));
    });
  }

  onSubmit() {
    try{

      if(this.formFirstAccess.value.documentNumber === "")
      {
        this.notifyService.showWarning('Atenção', 'DocumentNumber deve ser informado');
      }
      else
      {
        this.firstAccess(this.formFirstAccess.value.documentNumber);
        // var entity = this.getUser(this.formFirstAccess.value.documentNumber);
      }
    }
    catch(e)
    {
      alert(e.status)
      this.notifyService.showError('erro', e.message);
      console.error(e);
    }
  }

  firstAccess(documentNumber) {
    this.firstAccessService
      .firstAccess(documentNumber)
      .pipe(
        finalize(() => {
          this.router.navigate(["/registrar-senha", documentNumber]);
        })
      )
      .subscribe(
        (success: any) => {
        },
        (error) => {
          return;
        }
      );
  }
}
