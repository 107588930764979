<div class="datepicker-input-box" >
  <input type="text" class="datepicker-input-box__input datepicker-input-box__input__date"
  [ngClass]=" invalid ? 'is-invalid' : ''"
  bsDatepicker triggers="manual" [bsConfig]="bsConfig" [value]="formattedDate" #dp="bsDatepicker" [id]="'datepicker-'+id"
     [mask]="'99/99/9999'" (bsValueChange)="onDateSelection(datepicker._bsValue)" (blur)="onBlur()"
    placeholder="DD/MM/AAAA" maxlength="10"/>
  <div class="input-group-append">
      <span (click)="datepicker.toggle()" class="calendar-icon">
        <img src="/assets/svg/calendar-blue-date-picker.svg" alt="ícone de calendário">
      </span>
  </div>
  @if(invalid) {
    <app-vmessage
      id="msg-error-input-protocol"
      errorMessage="{{ message }}"
    >
    </app-vmessage>
  }

</div>
