<div class="modal-header">
  <h4 data-e2e="modal-title" class="modal-title text-uppercase" translate>
    Report tracking code
  </h4>
  <a data-e2e="close-modal-top-btn" class="btn-close" (click)="dismissModal()">
    <img src="assets/svg/close.svg" />
  </a>
</div>

<div class="modal-body">
  <div *ngIf="step == 1">
    <div class="modal-content col-lg-8">
      <form [formGroup]="reportTrackingForm">
        <div class="form-group d-flex justify-content-center mb-4">
          <div class="col-10">
            <div class="form-group select-table">
              <label
                data-e2e="shipping-company-label"
                class="font-weight-bold"
                translate
                >Logistic Partner</label
              >
              <select
                data-e2e="shipping-company-select"
                class="form-control form-icon-track w-100 pl-5"
                formControlName="logisticPartnerId"
                [class.is-invalid]="
                  reportTrackingForm.controls.logisticPartnerId.invalid &&
                  reportTrackingForm.controls.logisticPartnerId.touched
                "
              >
                <option class="placeholder" [ngValue]="null" translate>
                  Selectt
                </option>
                <option
                  *ngFor="let partner of partners; let i = index"
                  [attr.data-e2e]="'shipping-company-option-' + i"
                  [value]="partner.id"
                >
                  {{ partner.name }}
                </option>
              </select>
            </div>

            <div>
              <label data-e2e="tracking-code-label" class="tracking-label" translate
                >Tracking code</label
              >
              <i
                class="icon-info"
                placement="right"
                data-e2e="tracking-code-info-icon"
                triggers="click"
                [autoClose]="true"
                tooltipClass="my-custom-class"
                ngbTooltip="{{ 'Info tracking' | translate }}"
              >
              <img src="/assets/img/ico-info.svg">
              </i>
              <input
                type="text"
                data-e2e="tracking-code-input"
                maxlength="30"
                class="form-control tracking-code-input"
                formControlName="trackingCode"
                [class.is-invalid]="
                  reportTrackingForm.controls.trackingCode.invalid &&
                  reportTrackingForm.controls.trackingCode.touched
                "
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="text-center">
          <app-vmessage
            data-e2e="tracking-code-error-msg"
            class="text-center"
            *ngIf="
              reportTrackingForm.controls.trackingCode.invalid &&
              reportTrackingForm.controls.trackingCode.touched
            "
            errorMessage="{{ 'Tracking code is invalid' | translate }}"
          >
          </app-vmessage>

          <app-vmessage
            data-e2e="tracking-code-error-msg"
            *ngIf="
              reportTrackingForm.controls.logisticPartnerId.invalid &&
              reportTrackingForm.controls.logisticPartnerId.touched
            "
            errorMessage="{{ 'Partner is required' | translate }}"
          >
          </app-vmessage>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="step == 2" class="d-flex justify-content-center">
    <div class="col-9 container-confirmation">
      <div class="col-5 image-content mt-4">
        <img src="./../../../../assets/img/bg-place.svg" />
      </div>
      <div class="col-10 confirmation-text mt-4">
        <div class="mt-2">
          <h3 data-e2e="step-title" translate>Tracking code included</h3>
        </div>

        <div class="mt-3 text-uppercase">
          <strong data-e2e="shipping-company"
            >PARCEIRO {{ trackingInclude?.logisticPartnerName }}
          </strong>
        </div>
        <div class="mt-2 text-uppercase">
          <strong data-e2e="shipping-code"
            >CÓDIGO {{ trackingInclude?.trackingCode }}
          </strong>
        </div>

        <div class="mt-4 user-edit">
          <span data-e2e="tracking-signature"
            >{{ trackingInclude?.changeDate | date: 'dd/MM/yyyy HH:mm:ss' }} por
            {{ trackingInclude?.userName }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="col-12" *ngIf="step == 1">
    <div class="col-12 d-flex justify-content-center">
      <div class="content-buttons col-6 d-flex justify-content-around">
        <button
          data-e2e="cancel-btn"
          class="btn btn-tertiary"
          (click)="dismissModal()"
          translate
        >
          Cancel
        </button>
        <button
          data-e2e="submit-btn"
          class="btn btn-secondary"
          (click)="onSaveTrackingCode()"
          [disabled]="isLoading"
        >
          <div *ngIf="!isLoading" translate>Confirm</div>
          <div *ngIf="isLoading" translate>
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            Loading...
          </div>
        </button>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="step == 2">
    <div class="d-flex col-12 justify-content-center align-items-center">
      <div>
        <button
          data-e2e="close-modal-btn"
          class="btn btn-secondary"
          (click)="dismissModal()"
        >
          <div translate>Close</div>
        </button>
      </div>
    </div>
  </div>
</div>
