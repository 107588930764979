export class Tag {
  id?: number;
  contractId?: number;
  available?: boolean;
  totalQuantity?: number;
  blockedQuantity?: number;
  availableQuantity?: number;
  activeQuantity?: number;
  bonificationQuantity?: number;

  constructor(public serial?: string) {}
}

export class NewTag extends Tag {
  registrationUserId?: number;
  tagRequestStatusTypeId?: number;
  tagResquestId?: number;

  constructor(serial: string) {
    super(serial);
  }
}
