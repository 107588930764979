import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from 'src/app/core/services/address.service';
import { finalize } from 'rxjs/operators';
import { PersonAddress } from '@models/old/personAddress.model';
import { NotifyService } from '@services/notify.service';
import { ResultEventAddress } from '@models/old/resultEventAddress.model';

@Component({
  selector: 'app-edit-delivery-address',
  templateUrl: './edit-delivery-address.component.html',
  styleUrls: ['./edit-delivery-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDeliveryAddressComponent implements OnInit {

  constructor(private modalService: NgbActiveModal, private cdr: ChangeDetectorRef, private addressService: AddressService, protected notifyService: NotifyService) { 
    setInterval(() => {
      this.cdr.detectChanges()
    }, 10);
  }

  @Output() event = new EventEmitter();
  @Input() fromOrder;
  @Input() AddressObject: PersonAddress;
  @Input() PreviousAddressObject: PersonAddress;
  @Input() personId:number;
  step:Number = 1;
  onSaving: Boolean;
  isDisable: Boolean;

  ngOnInit(): void {
  }

  receiveEvent($event){
    this.AddressObject = $event.data;
    this.isDisable = $event.disableButton;
  }

  confirmEdit(){
     this.onSaving = true;
     this.addressService.alterPersonAddress(this.AddressObject)
       .pipe(finalize(() => { this.onSaving = false; }))
       .subscribe(res => {
         const result = new ResultEventAddress();
         result.status = true,
         result.zipCode = this.AddressObject.zipCode;
         result.id = res.personAddressId;
         this.notifyService.showSuccess('Atenção!', 'Endereço alterado com sucesso.');
         this.event.emit(result);
         this.close();
       },
         (e) => {
           console.error(e)
         }
       );
  }

  changeStep(step){
   this.step = step;
  }

  close() {
    this.modalService.close();
  }

}
