import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";

const LOGIN_URL: string = '/login';

function navigateWithRedirect(router: Router, url: string, state: RouterStateSnapshot): boolean {
  router.navigate([url], {
    queryParams: {redirect: state.url},
    replaceUrl: true
  }).catch(error => {
    console.error("Erro ao navegar:", error);
  });
  return false;
}

export const authProfileGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  const router: Router = inject(Router);
  const oauthService: OAuthService = inject(OAuthService);

  /** Not authenticated, redirecting and adding redirect url */
  if (!oauthService.hasValidAccessToken()) {
    return navigateWithRedirect(router, LOGIN_URL, state);
  }

  return true;
}
