 <footer class="footer mt-auto">
    <div class="container">

        <div [ngClass]="disableButton ? 'img page-select-contract' : 'img'"></div>
        <div class="footer_LogoRepom float-left">
           <a
            href="https://edenred.com.br/"
            target="_blank">
            <img src="../../../../assets/img/logo-edenred.svg" class="footer_LogoRepomIMG">
          </a>
         </div>

      <div class="custom-shape-divider">
        <div class="chat-button-content row" [hidden]="disableButton">
          <a data-e2e="contact-us-button" class="contact-us-button col" ngbTooltip="FALE CONOSCO" (click)="emitOrRedirectToContactUs()"></a>
          <a data-e2e="open-chat-btn" class="chat-button col" (click)="openChat()" ngbTooltip="CHAT"></a>
        </div>
      </div>

    </div>
</footer>
