import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { map } from 'rxjs';
import { TagActivateHistoryQuery } from '../dtos/tag-activate-history-query';
import { ITagActivateHistoryQueryResult } from '../dtos/tag-activate-history.dto';
import { Dto } from '@models/dto.model';
import { TagActivateUploadResponseDto } from '../dtos/tag-activate-upload-response.dto';
import { TagActivateUploadDto } from '../dtos/tag-activate-upload.dto';
import { TagBlockUnblockQuery } from '../dtos/tag-block-unblock-query';
import { ITagBlockUnblockQueryResult } from '../dtos/tag-block-unblock.dto';
import { TagsSelectedToBlockUnblock } from '../dtos/tags-selected-to-block-unblock.dto';
import { TagsSelectedToBlockUnblockResponseDto } from '../dtos/tags-selected-to-block-unblock-response.dto';
import { TagBlockUnblockHistoryQuery } from '../dtos/tag-block-unblock-history-query';
import { ITagBlockUnblockHistoryQueryResult } from '../dtos/tag-block-unblock-history.dto';
import { ITagBlockUnblockInconsistenciesDto } from '../dtos/tag-block-unblock-inconsistencies.dto';

@Injectable({
  providedIn: 'root'
})
export class BatchManagementService {

  constructor(private http: HttpClient) { }

  getHistoryTagActivate(query: TagActivateHistoryQuery) {
    return this.http.get<ITagActivateHistoryQueryResult>(
      `${environment.apiTMTag}/TagFileControl/Get`,
      { params: query.httpParams }
    );
  }

  downloadVehiclesAndTagsAvailables(contractId: number) {
    let httpParams = new HttpParams()
      .append('contractId', contractId);

    return this.http.get(
      `${environment.apiTMTag}/Tag/GetVehiclesAndTagsAvailableFileByContractId`,
      {
        params: httpParams,
        observe: "response",
        responseType: "blob",
      }
    )
    .pipe(
      map((response) => {
        let data = {
          file: new Blob([response.body], {
            type: response.headers.get("Content-Type"),
          }),
          filename: response.headers.get("filename"),
        };
        return data;
      })
    );
  }

  postFileTagActivate(object: TagActivateUploadDto, contractId: number) {
    let httpHeaders = new HttpHeaders({'contractId': contractId});

    if (object.protocol) {
      httpHeaders = httpHeaders.append('protocolNumber', object.protocol);
    }

    return this.http.post<Dto<TagActivateUploadResponseDto>>(
      `${environment.apiTMTag}/TagFileControl/Post`,
      object.file,
      {
        headers: httpHeaders
      }
    );
  }

  putFileTagActivate(object: TagActivateUploadDto) {
    let httpHeaders = new HttpHeaders({'tagFileControlId': object.idFile});

    if (object.protocol) {
      httpHeaders = httpHeaders.append('protocolNumber', object.protocol);
    }

    return this.http.put<Dto<TagActivateUploadResponseDto>>(
      `${environment.apiTMTag}/TagFileControl/Put`,
      object.file,
      {
        headers: httpHeaders
      }
    );
  }

  downloadFileTagActivateInconsistencies(tagFileControlId: number) {
    let httpParams = new HttpParams()
      .append('tagFileControlId', tagFileControlId);

    return this.http.get(
      `${environment.apiTMTag}/TagFileControlItem/Get`,
      {
        params: httpParams,
        observe: "response",
        responseType: "blob",
      }
    )
    .pipe(
      map((response) => {
        let data = {
          file: new Blob([response.body], {
            type: response.headers.get("Content-Type"),
          }),
          filename: response.headers.get("filename"),
        };
        return data;
      })
    );
  }

  downloadTagActivateModelFile() {
    window.location.href="https://gestaodepedagio.edenred.com/Modelo_ativacao_tags_massa.xlsx";
  }

  getAllTagsToBeBlockedUnblockedContract(query: TagBlockUnblockQuery) {
    return this.http.get<ITagBlockUnblockQueryResult>(
      `${environment.apiTMTag}/Tag/GetAllTagsToBeBlockedUnblockedContract`,
      { params: query.httpParams }
    );
  }

  postBlockUnblockTags(object: TagsSelectedToBlockUnblock) {
    let httpHeaders = new HttpHeaders({'contractVehicleTagStatusTypeId': object.contractVehicleTagStatusTypeId});

    if (object.protocol) {
      httpHeaders = httpHeaders.append('protocolNumber', object.protocol);
    }

    return this.http.post<TagsSelectedToBlockUnblockResponseDto>(
      `${environment.apiTMTag}/Tag/BlockOrUnblockTags`,
      object.contractVehicleTagIdList,
      {
        headers: httpHeaders
      }
    );
  }

  getTagsBlockUnblockHistory(query: TagBlockUnblockHistoryQuery) {
    return this.http.get<ITagBlockUnblockHistoryQueryResult>(
      `${environment.apiTMTag}/TagBlockUnblockBatchControl/Get`,
      { params: query.httpParams }
    );
  }

  getTagsBlockUnblockInconsistencies(idBlockUnblockHistory: number) {
    return this.http.get<ITagBlockUnblockInconsistenciesDto[]>(
      `${environment.apiTMTag}/TagBlockUnblockBatchControlItem/Get?tagBlockUnblockBatchControlId=${idBlockUnblockHistory}`,
    );
  }

  putRetryBlockUnblockTags(object: TagsSelectedToBlockUnblock) {
    let httpHeaders = new HttpHeaders(
      {
        'contractVehicleTagStatusTypeId' : object.contractVehicleTagStatusTypeId,
        'tagBlockUnblockBatchControlId' : object.tagBlockUnblockBatchControlId
      }
    );

    return this.http.put<TagsSelectedToBlockUnblockResponseDto>(
      `${environment.apiTMTag}/Tag/RetryBlockOrUnblockTags`,
      object.contractVehicleTagIdList,
      {
        headers: httpHeaders
      }
    );
  }
}
