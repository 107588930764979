import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ConfirmationSeparateRequestComponent } from '../confirmation-separate-request/confirmation-separate-request.component';
import { NotifyService } from '@services/notify.service';
import {RequestItemsQueryResultDto} from "@modules/backoffice/request/dtos/request-items-query-result.dto";
import {RequestService} from "@modules/backoffice/request/services/request.service";

@Component({
  selector: 'app-separate-request',
  templateUrl: './separate-request.component.html',
  styleUrls: ['./separate-request.component.scss'],
})
export class SeparateRequestComponent implements OnInit {
  @Input() order: RequestItemsQueryResultDto;
  @Output() event: EventEmitter<any> = new EventEmitter();

  separateOrder: any;
  addSerialForm: FormGroup;
  isLoading: boolean;
  add: any;
  edit: any;
  tagsArray: any = [];
  msgTagSelecionada: boolean;
  errorTagMsg: string;
  orderForm: FormGroup;
  errorTag: boolean;
  availableToSave: boolean;
  tagSuccess: boolean;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private requestService: RequestService,
    private notifyService: NotifyService,
  ) {
    this.separateOrder = {
      companyName: '',
      documentNumber: '',
      tagRequestId: 0,
      tagRequestCount: 0,
      tagRequestSeparationCount: 0,
      valueFreight: 0,
      valueTag: 0,
      deliveryAddressTitle: '',
      deliveryAddress: '',
      deliveryAddressReferencePoint: '',
      tagRequestReceivingResponsibleName: '',
      tagRequestReceivingResponsiblePhone: '',
      totalOrder: 0,
      tagList: [],
    };
  }

  ngOnInit(): void {
    this.createAddSerial();
    this.createFormTag();
    this.GetTagRequestDetails(this.order.id);
  }

  createFormTag() {
    this.orderForm = this.formBuilder.group({
      tagRequestId: this.separateOrder.tagRequestId,
      tagList: this.formBuilder.array([]),
    });
  }
  hideMsg() {
    this.tagSuccess = false;
  }

  setExistingTags(tags: any[]): FormArray {
    const formArray = new FormArray([]);
    tags.forEach((c) => {
      formArray.push(this.formBuilder.control(c));
    });
    return formArray;
  }

  createAddSerial() {
    this.addSerialForm = this.formBuilder.group({
      serialNumber: ['', Validators.required],
    });
  }

  GetTagRequestDetails(id: number) {
    this.isLoading = true;
    this.requestService
      .GetTagRequestDetails(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success: any) => {
          this.separateOrder = success;
          this.orderForm.setControl(
            'tagList',
            this.setExistingTags(success.tagList),
          );

          // this.separateOrder.tagRequestSeparationCount = 2;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }

  validateSerialTagRequest(tag: number) {
    this.isLoading = true;
    this.msgTagSelecionada = false;
    this.errorTag = false;

    if (
      this.orderForm.value.tagList.find((tagNumber: number) => tagNumber == tag)
    ) {
      this.errorTag = true;
      this.errorTagMsg = 'TAG ja cadastrada';
      this.isLoading = false;
      this.msgTagSelecionada = true;
      return;
    }

    this.requestService
      .ValidateSerialTagRequest(tag)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success: any) => {
          this.availableToSave = true;
          this.tagsArray.push(tag);
          // this.separateOrder.tagList.push(tag);
          const formArray: FormArray = this.orderForm.get(
            'tagList',
          ) as FormArray;
          formArray.push(new FormControl(tag));
          this.separateOrder.tagRequestSeparationCount++;
          this.tagSuccess = true;
          this.addSerialForm.reset();
        },
        (error) => {
          this.errorTag = true;
          this.errorTagMsg = error.error;
          console.error(error.error);
          return;
        },
      );
  }

  //TODO: - Fazer a chamada do post
  finalizeSeparation() {
    this.orderForm.get('tagRequestId').setValue(this.order.id);

    this.isLoading = true;
    this.requestService
      .finalizeSeparation(this.orderForm.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.dismissModal();
          this.redirectToConfirmation(success);
          this.event.emit(success);
        },
        (e) => {
          console.error(e);
        },
      );
  }

  saveSeparationTagRequest() {
    this.orderForm.get('tagRequestId').setValue(this.order.id);
    this.isLoading = true;
    this.requestService
      .saveSeparationTagRequest(this.orderForm.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success: any) => {
          this.dismissModal();
          this.notifyService.showSuccess('Atenção!', success.message);
        },
        (e) => {
          console.error(e);
        },
      );
  }

  //TODO: - Redirecionar para o modal (ConfirmationSeparateRequestComponent) com o retorno do post
  redirectToConfirmation(obj: any) {
    const modalRef = this.modalService.open(
      ConfirmationSeparateRequestComponent,
    );
    let titles = {
      title: 'Pedido separado',
      subtitle: 'Pedido separado',
    };
    modalRef.componentInstance.titles = titles;
    modalRef.componentInstance.order = obj.tagRequestDetailsUserChangeResult;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  onCheckChange(event: any) {
    this.availableToSave = true;

    const formArray: FormArray = this.orderForm.get('tagList') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
      // this.separateOrder.tagRequestSeparationCount++;
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          this.separateOrder.tagRequestSeparationCount--;
          return;
        }

        i++;
      });
    }
  }
}
