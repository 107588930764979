import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonAddress } from '@models/old/tagrequestqueryresult/personAddress.model';

@Component({
  selector: 'app-address-confirm-data',
  templateUrl: './address-confirm-data.component.html',
  styleUrls: ['./address-confirm-data.component.scss']
})
export class AddressConfirmDataComponent implements OnInit {

  @Input() PersonAddressToConfirm:PersonAddress;

  constructor() { }

  ngOnInit(): void {
  }

}
