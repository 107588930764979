import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { authConfig } from './core/auth/auth.config';
import { Logger } from '@services/logger.service';
import {TranslateService} from "@ngx-translate/core";
import ptBR from 'src/translations/pt-BR.json';
import { Userpilot } from 'userpilot';
import { environment } from '@env';

const log = new Logger('App');

// js/libs inicia chat

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private oauthService: OAuthService,
              private router: Router,
              private translateService: TranslateService ) {
    this.addAuth();
    this.addTranslate();
    this.initializeUserPilot();
  }

  hasCreditCard:any;
  title = 'app-tollmanagement';

  addAuth(): void {
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    // this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocument();

    this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((e) => {
      log.warn('token_received', e);
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'session_terminated')).subscribe((e) => {
      log.warn('Your session has been terminated!');
      this.router.navigate(['/login']);
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'token_expires')).subscribe((e) => {
      log.warn('received token_expires event', e);
    });
  }

  private addTranslate(): void {
    this.translateService.setTranslation('pt-BR', ptBR);
    this.translateService.use('pt-BR');
  }

  private initializeUserPilot() {
    Userpilot.initialize(environment.userPilotId);
  }
}
