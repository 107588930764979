import {  Component, EventEmitter, OnInit, Output, AfterViewInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { HolidaysCalendarService } from '../../services/holidays-calendar.service';
import { IHoliday, IIncludeHolidays } from '../../models/holidays.dto';
import { finalize } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsertWarningModalComponent } from '../insert-warning-modal/insert-warning-modal.component';
import { ActionSuccessModalComponent } from '../action-success-modal/action-success-modal.component';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-form-insert-holiday',
  templateUrl: './form-insert-holiday.component.html',
  styleUrl: './form-insert-holiday.component.scss'
})
export class FormInsertHolidayComponent implements OnInit {

  public holidaysForm : FormGroup

  errorMap: Map<string, FormControl> = new Map();

  @Output() holidaysUpdated = new EventEmitter<FormArray>();

  constructor( private _holidayService: HolidaysCalendarService,
               private _fb: FormBuilder,
               private _modalService: NgbModal
  ) { }

  ngOnInit() {
    this.holidaysForm = this._fb.group({
      Rows: this._fb.array([this.initRows()])
    });
    this.formArr.valueChanges.subscribe(values => {
      values.forEach((row, index) => {
         this.onCheckChange(index, row.repeatAnnually)
      });
    });
  }

  get formArr() {
    return this.holidaysForm.get("Rows") as FormArray;
  }

  initRows(){
    return this._fb.group({
      name: ['', Validators.required],
      date: ['', Validators.required],
      repeatAnnually: [false],
    });
  }


  adicionarLinha() {
    this.formArr.push(this.initRows());
  }

  removeLine(index: number) {
    this.formArr.removeAt(index);
  }


  // track(index: number) {
  //   return index;
  // }

  validateHolidays(holidays: IIncludeHolidays[]) {
    this._holidayService.validateHolidaysList(holidays)
      .pipe()
      .subscribe({
        next: (success) => {
           (success.length > 0) ? this.handleErrors(success) : this.includeHolidays(holidays);
        },
        error: (err) => {}
      });
  }

  handleErrors(holidays: IHoliday[]) {
    let errorsAtForm: IIncludeHolidays[] = [];

    this.clearErrors();

    holidays.forEach(holiday => {
      const formattedDate = holiday.holidayDate.toString().split('T')[0];
      const matchingControl = this.formArr.controls.find(control => control.value.date === formattedDate)?.get('date');

      if (matchingControl) {
        const value = this.formArr.controls.find(control => control.value.date === formattedDate).value;

        const existingEntry = errorsAtForm.find(entry => entry.date === value.date);

        if (!existingEntry) {
          errorsAtForm.push(value);
        }
      }

      if (matchingControl instanceof FormControl) {
        this.errorMap.set(formattedDate, matchingControl);
      }
    });

    const modal = this._modalService.open(InsertWarningModalComponent);
    modal.componentInstance.holidayErrors = holidays;
    modal.componentInstance.errorsAtForm = errorsAtForm;


  }

  clearErrors() {
    this.errorMap.clear();
  }

  areAllFormsValid(): boolean {
    return !this.formArr.controls.every(holiday => holiday.valid);
  }

  // onFormLineChange(index: number, formLine: AbstractControl) {
  //   this.formArr.controls[index].setValue = formLine.value;
  // }

  addHolidaysList() {
    if (this.formArr.controls.every(holiday => holiday.valid)) {
      const holidaysData: IIncludeHolidays[] = this.formArr.controls.map(holiday => {
        const { name, date, repeatAnnually } = holiday.value;
        return {
          name: name.trim(),
          date: date,
          repeatAnnually: repeatAnnually,
        };
      });
      this.validateHolidays(holidaysData)
    }
  }

  includeHolidays(holidays : IIncludeHolidays[]) {
    return this._holidayService.addHolidayList(holidays)
    .pipe(finalize(() => {})).subscribe(
      {
        next: (success) => {
          const modal = this._modalService.open(ActionSuccessModalComponent)
          modal.componentInstance.success = success
          modal.componentInstance.many = holidays.length > 1 ? true : false
          modal.componentInstance.type = 'include'
        },
        error: () => {}
      }
    )
  }

  onDateChange(date: Date | undefined, index: number) {
    if (!date) {
      this.formArr.controls[index].get('date').setValue(null)

    } else {
      this.formArr.controls[index].get('date').setValue(date)
    }

  }

  onCheckChange(index, value: boolean): boolean {
    return value
  }

  checkValidateDate(index: number) {
      const dateControl = this.formArr.controls[index].get('date')
        return (
          (dateControl?.value == '' || dateControl?.value == null || dateControl?.value.invalid) &&
          (dateControl?.touched) ||
          this.errorMap.has(this.formArr.controls[index].value.date)
      );
  }

  checkNameInvalid(index) {
    if(this.formArr.controls[index]){
      const nameControl = this.formArr.controls[index].get('name')
    return nameControl?.invalid && nameControl?.touched
    }
  }


  hasError(index): boolean {
     if(this.formArr.controls[index].get('date')){
      const dateControl = this.formArr.controls[index].get('date')
      return dateControl?.touched && dateControl?.invalid && this.errorMap.has(this.formArr.controls[index].value.date);
     }
  }

  generateErrorMessageByFormControlName(controlName: string, index): string {
      if(this.formArr.controls[index]){
        if (this.errorMap.has(this.formArr.controls[index].value.date)) {
          return 'Já existe feriado cadastrado nessa data';
      }
       return controlName == 'date' ? 'Por favor, informe a data do feriado' : 'Por favor, informe o nome do feriado';
    }
  }

  isTouched(index){
    this.formArr.controls[index].get('date').markAsTouched()
  }

  remove(index) {
    this.formArr.controls.length > 1 ?  this.removeLine(index) : this.resetFormLine(index)
  }

  resetFormLine(index){
    this.formArr.controls[index].get('date').setValue('')
    this.formArr.controls[index].get('name').setValue('')
    this.formArr.controls[index].get('repeatAnnually').setValue(false)

  }
}
