import { HttpParams } from '@angular/common/http';

export class GetExtractQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search: string;
  filter: number;
  MovementType:number;
  TransactionType:number;
  FromDate: Date;
  ToDate: Date;

  get httpParans() {
    let fromDate  = new Date(this.FromDate.getFullYear(), this.FromDate.getMonth(), this.FromDate.getDate(),0).toJSON();
    let toDate = new Date(this.ToDate.getFullYear(), this.ToDate.getMonth(), this.ToDate.getDate(),23,59,59,999).toJSON();

    let httpParams = new HttpParams()
    .append('page', this.page.toString())
    .append('pageSize', this.pageSize.toString())
    .append('sortField', this.orderBy)
    .append('SortOrder', this.desc ? '0' : '1')
    .append('search', this.search?.toString())
    .append('filter', this.filter?.toString())
    .append('TransactionType', this.TransactionType?.toString())
    .append('FromDate', fromDate)
    .append('ToDate', toDate)
    .append('MovementType', this.MovementType?.toString())
    .append('TimeZoneId', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return httpParams;
  };
}


