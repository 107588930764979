import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})


export class ContactUsService {

    constructor(protected http: HttpClient) { }

    public getCurrentUser() {
        return this.http.get(`${environment.apiTMCustomer}/Person/PersonCurrentUser`);
    }

    public postContactUsForm(args) {
      let formData = this.getContactUsFormData(args)
      return this.http.post(`${environment.apiTMIntegrationSGA}/Contact`,formData);
    }

    getContactUsFormData(args){
      const formData = new FormData();
      
      Object.keys(args.form).forEach((key)=>{
        if(args.form[key]){
  
          if(key == 'subCategoryId')
          formData.append('ContactType',args.form[key])
  
          if(key == 'description')
          formData.append('Description',args.form[key])
  
          if(key == 'returnType')
          formData.append('ReturnType',args.form[key])
  
          if(key == 'email')
          formData.append('Email',args.form[key])
       
          if(key == 'phone')
          formData.append('PhoneNumber',args.form[key])
       
          if(key == 'licensePlate')
          formData.append('ContestationPassage.LicensePlate',args.form[key])
       
          if(key == 'passageDate')
          formData.append('ContestationPassage.Date', new Date(args.form[key]).toISOString().split('T')[0])
          
          if(key == 'passageHour')
          formData.append('ContestationPassage.Hour',args.form[key].substr(0,2)+":"+args.form[key].substr(2,4))
  
          if(key == 'parkingName')
          formData.append('ParkingRegistration.Name',args.form[key])
  
          if(key == 'zipCode')
          formData.append('ParkingRegistration.PostalCode',args.form[key])
  
          if(key == 'address')
          formData.append('ParkingRegistration.Address',args.form[key])
  
          if(key == 'number')
          formData.append('ParkingRegistration.Number',args.form[key])
  
          if(key == 'district')
          formData.append('ParkingRegistration.Neighborhood',args.form[key])
  
          if(key == 'complement')
          formData.append('ParkingRegistration.Complement',args.form[key])
  
          if(key == 'city')
          formData.append('ParkingRegistration.City',args.form[key])
  
          if(key == 'state')
          formData.append('ParkingRegistration.State',args.form[key])
  
          if(key == 'subCategory7LicensePlate')
          formData.append('TollNotOpen.LicensePlate',args.form[key])
  
          if(key == 'occurrenceDate')
          formData.append('TollNotOpen.Date', new Date(args.form[key]).toISOString().split('T')[0])
  
          if(key == 'occurrenceHour')
          formData.append('TollNotOpen.Hour',args.form[key].substr(0,2)+":"+args.form[key].substr(2,4))
  
          if(key == 'subCategory7city')
          formData.append('TollNotOpen.City',args.form[key])
  
          if(key == 'subCategory7state')
          formData.append('TollNotOpen.State',args.form[key])
  
          if(key == 'parkingAndTollName')
          formData.append('TollNotOpen.PlaceName',args.form[key])
  
          if(key == 'paymentInMoney')
          formData.append('TollNotOpen.MoneyPay',args.form[key] == 1 ? 'true' : 'false')
  
          if(key == 'parkingAndToll')
          formData.append('TollNotOpen.Toll',args.form[key] == 1 ? 'true' : 'false')

          if(key == 'billId')
          formData.append('Bill',args.form[key])

          if(key == 'userDocument')
          formData.append('DocumentNumber',args.form[key])

          if(key == 'userEmail')
          formData.append('EmailUser',args.form[key])

        }
      });
  
      if(args.files.length > 0)
      args.files.forEach((element) => formData.append('Attachments', element,element.name));
      
      return formData;
  
    }

}