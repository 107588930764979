import { BsModalRef } from "ngx-bootstrap/modal";
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { TagService } from "@services/tag.service";
import { OrderTimelineService } from "@services/order-timeline.service";
import { NotifyService } from "@services/notify.service";
import { TagConfirmReceiptCommandRequest } from "@models/old/Request/tagconfirmreceiptcommandrequest.model";

@Component({
  selector: "app-tag-receipt-modal",
  templateUrl: "./tag-receipt-modal.component.html",
  styleUrls: ["./tag-receipt-modal.component.scss"],
})
export class TagReceiptModalComponent {
  @Output("refreshPage") event: EventEmitter<any> = new EventEmitter();
  @Input() tagRequestId: number;
  @Input() amount: number;

  loading: boolean;
  serial: string;
  msgResult: string;

  constructor(
    public modalRef: BsModalRef,
    private _tagService: TagService,
    private _notify: NotifyService,
    private _timelineService: OrderTimelineService
  ) {}

  onSubmit() {
    const rq = new TagConfirmReceiptCommandRequest(
      this.serial,
      this.tagRequestId
    );
    this.loading = true;
    this._tagService.confirmReceiptTagRequest(rq).subscribe(
      (res) => {
        this._notify.showSuccess("Sucesso", `Pedido confirmado com sucesso`);
        this.event.emit(true);
        this._timelineService.refreshOrder$.next(undefined);

        this.modalRef.hide();
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        if (err.error !== null && err.error.length > 0) {
          this.msgResult = err.error[0].message;
        } else {
          alert("Problema ao processar, tente novamente");
        }
      }
    );
  }
}
