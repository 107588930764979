import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-results-msg',
  templateUrl: './no-results-msg.component.html',
  styleUrl: './no-results-msg.component.scss'
})
export class NoResultsMsgComponent {
  @Input() inTitle: string;
  @Input() inSubtitle: string;

}
