import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {OAuthService} from "angular-oauth2-oidc";
import {ResponseDto} from "@models/responseDto.model";
import {GroupUser, GroupsCurrentUser} from "@models/group-user.model";
import {UserProfileService} from "@services/user-profile.service";
import {ProfileType} from "@variables/profileType";

@Component({
  selector: 'app-select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['./select-profile.component.scss']
})
export class SelectProfileComponent implements OnInit {

  profilesGroupUser$: Observable<ResponseDto<GroupsCurrentUser>>;
  isLoading: boolean = true;

  private uniqueProfiles: number[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private _route: Router,
              private oAuthService: OAuthService,
              private manageUserProfile: UserProfileService) {
  }

  ngOnInit(): void {
    this.profilesGroupUser$ = this.activatedRoute.data.pipe(map(data => data['profilesUser']));
    this.profilesGroupUser$.subscribe(this.handleProfileGroupUserResponse.bind(this));
  }

  private handleProfileGroupUserResponse(responseDto: ResponseDto<GroupsCurrentUser>): void {
    if (this.isResponseEmpty(responseDto)) {
      this.logOut();
      return;
    }

    const allIdProfiles: number[] = this.extractProfileIds(responseDto);
    this.uniqueProfiles = this.getUniqueIds(allIdProfiles);

    this.storageCurrentUserId(responseDto.data.userID);
    this.hasMultipleProfile();
    this.manageProfiles();
    this.disablePageLoadAnimation();
  }

  private isResponseEmpty(responseDto: ResponseDto<GroupsCurrentUser>): boolean {
    return responseDto.data.listGroups.length === 0;
  }

  private extractProfileIds(responseDto: ResponseDto<GroupsCurrentUser>): number[] {
    return responseDto.data.listGroups.map((groupUser: GroupUser) => groupUser.grupoTipoID);
  }

  private getUniqueIds(allIdProfiles: number[]): number[] {
    return Array.from(new Set(allIdProfiles));
  }

  private hasMultipleProfile(): void {
    const _hasMultipleProfile: boolean = this.uniqueProfiles.length > 1 && this.uniqueProfiles.includes(ProfileType.BACKOFFICE);
    this.manageUserProfile.setHasMultipleProfile(_hasMultipleProfile);
  }

  private manageProfiles(): void {
    if (this.uniqueProfiles.length === 1 || !this.manageUserProfile.hasMultipleProfile()) {
      this.manageUserProfile.setUserProfile(this.uniqueProfiles[0]);
      this.actionByProfile();
    }
  }

  private actionByProfile(): void {
    const profileAction: any = this.manageUserProfile.getActionForProfile();
    this._route.navigateByUrl(profileAction.url);
  }

  private logOut(): void {
    alert('Usuário não possui perfil associado');
    this.oAuthService.logOut();
  }

  private disablePageLoadAnimation(): void {
    if (this.manageUserProfile.hasMultipleProfile()) {
      this.isLoading = false;
    }
  }

  toEnterCustomerPortal() {
    let profile = this.uniqueProfiles.includes(ProfileType.RELATIONSHIP) ? ProfileType.RELATIONSHIP : ProfileType.CUSTOMER;
    this.manageUserProfile.setUserProfile(profile);
    this.actionByProfile();
  }

  toEnterBackOfficePortal() {
    this.manageUserProfile.setUserProfile(ProfileType.BACKOFFICE);
    this.actionByProfile();
  }

  private storageCurrentUserId(userId: number) {
    this.manageUserProfile.setUserId(userId);
  }
}
