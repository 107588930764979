import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { EditContractNameModalComponent } from './components/edit-contract-name-modal/edit-contract-name-modal.component';
import { FinancialBlockModalComponent } from './components/financial-block-modal/financial-block-modal.component';
import { SelectProfileService } from './services/select-profile.service';
import { AuthService } from "../../../core/auth/auth.service";
import { ContractAvailablesDto } from "@models/contractAvailables.dto";
import { RoutesPaths } from "../../../core/utils/routes/routes-paths";
import { GetContractsBySearchFieldParams } from "@models/get-contracts-by-searchfield-params.model";
import { Dto } from "@models/dto.model";
import { ModalityType } from "@models/contract.model";
import { ContractService } from "@services/contract.service";
import { SelectContractsService } from '@modules/customer/select-contracts/services/select-contracts.service';

@Component({
    selector: 'app-select-profile',
    templateUrl: './select-profile-back-office.component.html',
    styleUrls: ['./select-profile-back-office.component.scss'],
})
export class SelectProfileBackOfficeComponent implements OnInit {
    dynamicTitle: string = 'CONTRATOS DISPONÍVEIS';
    contractAvailables: Array<ContractAvailablesDto>;
    isLoading: boolean;
    contractSearchForm: FormGroup;
    public SearchValidator = [Validators.required];
    clear: boolean = false;
    pastedText: string = null;
    radioSelected: number = 1;
    errorField: string;
    errorMessage: string;
    searchedValue: string;
    searchedType: number;
    isFinancialBlockingPosPaidPath: boolean = false;
    totalContracts: number;
    searchFieldParams: GetContractsBySearchFieldParams;
    searchType: any;
    searchField: any;
    protected isMessageBySearchFilter!: boolean;

    searchRadios: {
        searchField: string;
        id: number;
        placeholder?: string;
        mask?: string;
        errorText?: string;
        text?: string;
    }[] = [
            {
                searchField: 'Todos',
                id: 8,
            },
            {
                searchField: 'CNPJ',
                id: 1,
                placeholder: '00.000.000/0000-00',
                mask: '00.000.000/0000-00',
                errorText: 'o CNPJ informado',
                text: 'a placa',
            },
            {
                searchField: 'Código de Faturamento',
                id: 2,
                placeholder: 'Insira o cód. de faturamento',
                mask: '000000',
                errorText: 'o código de faturamento informado',
            },
            {
                searchField: 'Código do Contrato',
                id: 3,
                placeholder: 'Insira o cód. de contrato',
                mask: '000000',
                errorText: 'o código do contrato informado',
            },
            {
                searchField: 'Placa',
                id: 4,
                placeholder: 'AAA9999 ou AAA9A99',
                mask: 'SSS0A00',
                errorText: 'a placa informada',
            },
            {
                searchField: 'Razão Social',
                id: 5,
                placeholder: 'Insira a razão social',
                mask: '',
                errorText: 'a razão social informada',
            },
            {
                searchField: 'Tag',
                id: 7,
                placeholder: 'Insira a Tag',
                mask: '',
                errorText: 'a tag informada',
            },
        ];

    //Injections
    private contractService: ContractService;

    constructor(
        private _selectProfileService: SelectProfileService,
        private _authService: AuthService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _modalService: NgbModal,
        private _selectContractsService: SelectContractsService,
        private activeRoute: ActivatedRoute
    ) {
        this.contractService = inject(ContractService);
        this.getUrlActive();
    }

    ngOnInit(): void {
        this.clear = true;
        this.contractAvailables = [];
        this.isMessageBySearchFilter = false;
        this.createAddVehicleForm();
        this.searchFieldParams = new GetContractsBySearchFieldParams();
        this.searchFieldForm();
        this.errorField = 'CNPJ';
        this.errorMessage = 'o CNPJ informado';
        const routeTitle = this.activeRoute.snapshot.data['title'];
        if (routeTitle) {
            this.dynamicTitle = routeTitle;
        }
    }

    searchFieldForm() {
        this.contractSearchForm = this._formBuilder.group({
            documentSearch: ['', this.SearchValidator],
        });
    }

    createAddVehicleForm() {
        this.contractSearchForm = this._formBuilder.group({
            documentSearch: ['', this.SearchValidator],
        });
    }

    verifyContract(contract: any) {
        this.setContract(contract);

        if (contract.contractBlockLevel == 3 && !this.isFinancialBlockingPosPaidPath) {
            this.showFinancialBlockModal(contract);
        } else {
            this.handleRedirect();
        }
    }

    handleSearchFormValues(controls: any) {

        this.searchType = controls['searchType'].value;
        this.searchField = controls['searchField'].value;
        this.redirectLoadContracts();
    }


    redirectLoadContracts() {
        this.searchFieldParams = new GetContractsBySearchFieldParams();

        // Valores obtidos de alguma fonte (ex.: do formulário no componente pai ou filho)
        const searchType = this.searchType; // Substitua isso com o valor correto
        const searchField = this.searchField; // Substitua isso com o valor correto

        if (this.isFinancialBlockingPosPaidPath) {
            this.loadContractsPosPaid(true, searchType, searchField);
        } else {
            this.loadContracts(true, true);
        }
    }


    loadContracts(isAutoSelectContract: boolean, event: any) {
        this.isLoading = true;
        this.searchFieldParams.searchType = this.searchType;
        this.searchFieldParams.searchField = this.searchField;

        this._selectProfileService
            .getContractsAttendance(this.searchFieldParams)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe(
                (success: Dto<ContractAvailablesDto[]>) => {
                    this.handleResponseSucess(success);

                    if (this.contractAvailables.length > 0) {
                        this.clear = !this.clear;
                        this.setAttendanceDocument(this.contractAvailables[0].documentNumber);

                        if (this.contractAvailables.length == 1 && isAutoSelectContract) {
                            this.setContract(this.contractAvailables[0]);
                            this.handleRedirect();
                        }
                    }
                },
                (error) => {
                    console.error(error);
                    return;
                },
            );
    }

    loadContractsPosPaid(isAutoSelectContract: boolean, searchType: number, searchField: string) {

        this.searchFieldParams.searchType = searchType;
        this.searchFieldParams.searchField = searchField;
        this.searchFieldParams.contractModalityTypeId = ModalityType.POSTPAID;

        this.isLoading = true;

        this._selectProfileService
            .getContractsPosPaid(this.searchFieldParams)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (success: Dto<ContractAvailablesDto[]>) => {
                    this.handleResponseSucess(success);

                    if (this.contractAvailables.length > 0) {
                        this.clear = !this.clear;
                        this.setAttendanceDocument(this.contractAvailables[0].documentNumber);

                        if (this.contractAvailables.length === 1 && isAutoSelectContract) {
                            if (
                                this.contractAvailables[0].contractBlockLevel === 1 ||
                                this.contractAvailables[0].contractBlockLevel === 3
                            ) {
                                this.setContract(this.contractAvailables[0]);
                                this.handleRedirect();
                            }
                        }
                    }
                },
                (error) => {
                    console.error(error);
                    return;
                }
            );
    }

    private handleResponseSucess(success: Dto<ContractAvailablesDto[]>): void {
        if (!success.success) {
            throw new Error('Erro ao realizar a busca de contratos.');
        }

        this.contractAvailables = success.data;
        this.totalContracts = success.totalItems;
        this.searchedValue = this.contractSearchForm.value.documentSearch;
        this.clear = false;
    }

    private setAttendanceDocument(document: string) {
        this._authService.setAttendanceDocument(document);
    }

    private ContractIsDualTag(contractId) {
        this._selectContractsService.getContractDualTag(contractId).subscribe((retorno: any) => {
            this.contractService.setDualTag(retorno);
        })
    }

    private setContract(contractSelect: ContractAvailablesDto) {
        this.contractService.setContract(contractSelect);
        this.ContractIsDualTag(contractSelect.id);
    }

    private handleRedirect(): void {
        let url: string = '/pagina-inicial';

        if (this.isFinancialBlockingPosPaidPath) {
            url = '/backoffice/' + RoutesPaths.FINANCIAL_BLOCKING_UNBLOCKING;
        }

        this._router.navigate([url]);
    }

    getMask(searchType: number): string {
        let mask;
        this.searchRadios.map((data) => {
            if (searchType === data.id) {
                mask = data.mask;
            }
        });
        return mask;
    }

    getPlaceholder(searchType: number): string {
        let placeholder;
        this.searchRadios.map((data) => {
            if (searchType === data.id) {
                placeholder = data.placeholder;
            }
        });
        return placeholder;
    }

    clearMessage() {
        this.pastedText = null;
        this.clear = true;
    }

    showEditContractNameModal(contract) {
        this.contractService.setContract(contract);

        const modalRef = this._modalService.open(EditContractNameModalComponent);
        modalRef.componentInstance.contract = contract;

        modalRef.componentInstance.event.subscribe((receivedEntry: any) => {
            if (receivedEntry == 1) {
                this.ngOnInit();
            }
            this.contractService.removeContract();
        });
    }

    showFinancialBlockModal(contract) {
        const modalRef = this._modalService.open(FinancialBlockModalComponent);
        modalRef.componentInstance.contract = contract;
    }

    getContractClass(contract: any): string {
        let contractClass = '';

        if (contract.contractBlockLevel == 3) {
            contractClass = 'contract-card-financial-block';
        } else if (contract.lastBillStatusType == 6 && contract.contractBlockLevel !== 3
        ) {
            contractClass = 'contract-card-overdue-invoice';
        } else
            // Financial Block select contract
            if (this.isFinancialBlockingPosPaidPath
                && contract.contractBlockLevel !== 3
                && contract.contractBlockLevel !== 1
            ) {
                contractClass = 'contract-card-disable';
            }

        return contractClass;
    }


    getUrlActive() {
        const urlActive = this._router.routerState.snapshot.url;
        if (
            urlActive ==
            '/backoffice/' + RoutesPaths.SELECT_PROFILE_FINANCIAL_BLOCKING_POS_PAID
        ) {
            this.isFinancialBlockingPosPaidPath = true;
        }
    }

    getContractStatusDescription(statusId: number): string {
        switch (statusId) {
            case 1:
                return 'Ativo';

            case 2:
                return 'Bloqueio falta de saldo';

            case 3:
                return 'Bloqueio Financeiro';

            case 4:
                return 'Bloqueio Juridico';

            case 5:
                return 'Cancelado';

            case 6:
                return 'Primeiro acesso';

            default:
                return '-';
        }
    }

    onPaginationChange(page: number) {
        this.searchFieldParams.page = page;
        this.isMessageBySearchFilter = true;
        this.loadContracts(false, { page });  // Chama loadContracts com o número da página
    }


}
