<div class="LooseRechargeConfirmationComponent">
    <div class="modal-content">
      <header class="modal-header">
        <div class="box-title"><span>Realizar recarga avulsa</span></div>
        <button class="close" type="button" (click)="modal.dismissAll()">
          <span aria-hidden="true">×</span>
        </button>
        <div class="row-box-yellow">
          <span class="box-yellow">
            <b>A</b>pós aprovação do pagamento da recarga, o saldo será
            disponibilizado em sua conta em até 10 minutos.
          </span>
        </div>
      </header>

      <div
        class="modal-body show"
      >

      <div class="contract-infos">
        <div class="companyName">{{ response.companyName }}</div>
        <div class="companyDocument">
          {{ response.documentNumber | mask : "00.000.000/0000-00" }}
        </div>
        <div class="row row-animation"></div>
        <div class="nickname">
          <div class="box-white-small">
            <p>Contrato:</p>
            <p>
              <span id="contractId">{{ response.contractId }}</span
              ><span *ngIf="response.contractName" id="contractName">
                - {{ response.contractName }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="row row-modal-collapse mt-7rem">
        <div class="w-100 d-flex justify-content-center">
          <div class="col-lg-4 left-infos">
            <img class="w-100" src="../../../assets/img/bg-place.svg" />
            <div class="box-gray">
              você será notificado da confirmação do débido de
              <span>{{ object.paymentTotalValue | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"}}
                </span>.<br />
              Fique atento na área de notificações.
            </div>
            <div class="notification">
              <img src="/assets/img/red-arrow.svg" />
              <div class="ico-notification">
                <img src="/assets/img/notification_image.svg" />
              </div>
            </div>
          </div>
          <div class="col-sm-auto tag-replace right-infos">
            <h3>solicitação de recarga avulsa em processamento</h3>
            <div class="row RechargeConfirmedInfos">
              <div class="col">
                <div class="col-12 textTitle">Valor da recarga avulsa:</div>
                <div class="col-12 text">
                  {{
                    object.paymentValue | currency : " " : "symbol" : "0.2-2" : "pt-BR"
                  }}
                </div>
                <div class="col-12 textTitle">
                  Taxa de recarga
                  <span *ngIf="object?.paymentRechargeFee !== 0"
                    >({{ object?.paymentRechargeFee | number : "1.2-2" : "pt-BR" }}%)</span
                  >
                </div>
                <div
                  class="col-12 text"
                  *ngIf="object?.paymentRechargeFee !== 0"
                >
                  {{
                    object.paymentRechargeFeeValue | currency : " " : "symbol" : "0.2-2" : "pt-BR"
                  }}
                </div>
                <div
                  class="col-12 text"
                  *ngIf="object?.paymentRechargeFee == 0"
                >
                  Isento
                </div>
                <div class="col-12 textTitle">
                  Total debitado do cartão de crédito
                </div>
                <div class="col-12 text">
                  {{
                    object.paymentTotalValue | currency : " " : "symbol" : "0.2-2" : "pt-BR"
                  }}
                </div>
              </div>
              <div class="col card-details">
                <b data-e2e="card-label">Meio de pagamento</b>
                <app-credit-card></app-credit-card>
              </div>
            </div>
            <div class="row mt-3 user">
              <div class="col-lg-12 text-center">
                <span>{{
                  response.registrationDate | date : "dd/MM/yyyy - HH:mm"
                }}</span>
                por <span>{{ response.registerUserName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      </div>
      <footer class="modal-footer modal-footer-gray justify-content-start">
        <div class="col-12 justify-content-center d-flex">    
          <button
            (click)="modal.dismissAll()"
            class="btn btn-secondary position-relative"
            type="button"
          >
            Fechar
          </button>
        </div>
      </footer>
    </div>
  </div>
  