import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {RequestService} from "@modules/backoffice/request/services/request.service";
import {TagRequestPrepaid} from "@models/backoffice/tag.model";

@Component({
  selector: 'app-consult-reason-modal',
  templateUrl: './consult-reason-modal.component.html',
  styleUrls: ['./consult-reason-modal.component.scss'],
})
export class ConsultReasonModalComponent implements OnInit {
  @Input() orderStatus: any;
  @Input() orderDetail: any;
  tagRequestPrepaid: TagRequestPrepaid;

  constructor(
    private modalService: NgbModal,
    private requestService: RequestService,
  ) {}

  ngOnInit(): void {
    this.getTagRequestPrepaidInfos();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  getTagRequestPrepaidInfos() {
    this.requestService.getTagRequestPrepaid().subscribe(
      (success) => {
        this.tagRequestPrepaid = success;
      },
      (error) => {
        console.error('error:', error);
        return;
      },
    );
  }
}
