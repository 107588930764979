import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { LoginRoutingModule } from './routes/login-routing.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginComponent } from './login.component';
import { FirstAccessComponent } from './components/first-access/first-access.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { RegisterPasswordComponent } from './components/register-password/register-password.component';
import { RegisteredPasswordComponent } from './components/registered-password/registered-password.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {
    BorderlessButtonComponent
} from "../../../shared/components/button/button-without-border/borderless-button.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxMaskDirective,
        NgxMaskPipe,
        LoginRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        BorderlessButtonComponent
    ],
  declarations: [
     LoginComponent,
     FirstAccessComponent,
     RecoverPasswordComponent,
     RegisterPasswordComponent,
     RegisteredPasswordComponent,
     WelcomeComponent
  ],
  providers: [],
})

export class LoginModule {}
