import { Component, OnInit } from '@angular/core';
import { MenuBackoffice } from "../../../core/utils/routes/menu-nav-links";
import { NavLink } from "@models/navlink.model";
import { AuthService } from "../../../core/auth/auth.service";
import { SelectSystemService } from '@modules/commom/select-profile/services/select-system.service';

@Component({
  selector: 'app-menu-principal-backoffice',
  templateUrl: './main-menu-backoffice.component.html',
  styleUrls: ['./main-menu-backoffice.component.scss']
})
export class MainMenuBackofficeComponent implements OnInit {

  modules: NavLink[] = MenuBackoffice;
  isLoading: boolean = false;

  constructor(
    private _auth: AuthService,
    private contractService: SelectSystemService
  ) { }

  ngOnInit() {
    this._auth.removeContractId();
  }


  onDownloadReport() {
    this.isLoading = true;
    this.contractService.downloadClientBlockingSettingsCsv().subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Configurações de bloqueio clientes.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Download failed', error);
        this.isLoading = false;
      }
    });
  }



}
