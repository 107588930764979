export interface VehiclesDto {
  id: number;
  contractId: number;
  licensePlate: string;
  vehicleType: number;
  contractVehicleId: number;
  contractVehicleTagId: number;
  registrationDate: Date;
  nickName: string;
  vehicleBrandTypeId: number;
  model: string;
  vehicleCategoryId: number;
  aggregateDriver: boolean;
  documentNumber: string;
  rntrc: string;
  hierarchyId: number;
  userId: number;
  customerId: number;
  startDate?: Date;
  endDate?: Date;
  tagId: number;
  hasTag: boolean;
  descriptionAxlePt: string;
  hierarchyName: string;
  serialTag: string;
  vehicleId: number;
  contractVehicleTagStatusTypeId: number;
  contractVehicleTagServiceTypeId: number;
  tagActivationDate?: Date;
  descriptioncontractVehicleTagStatusTypeIdPt: string;
  descriptionVehicleBrandTypePt: string;
  descriptionVehicleTypePT: string;
}

export enum contractVehicleTagStatusTypeId {
  ACTIVE_TAG = 1,
  TEMP_BLOCKED_TAG = 2,
  BALANCE_BLOCKED_TAG = 3,
  FINAN_BLOCKED_TAG = 4,
  SWITCH_CANCELED = 5,
  CANCELED = 6,
}
