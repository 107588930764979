import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ContractualAlterationService } from './services/contractual-alteration.service';
import { ContractAvailablesDto } from './dtos/contractAvailables.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LimitChangeModalComponent } from './components/limit-change-modal/limit-change-modal.component';

@Component({
  selector: 'app-contractual-alteration',
  templateUrl: './contractual-alteration.component.html',
  styleUrls: ['./contractual-alteration.component.scss'],
})
export class ContractualAlterationComponent implements OnInit {
  contractAvailables: Array<ContractAvailablesDto>;
  isLoading: boolean;
  isDisabled: boolean;
  contractSearchForm: FormGroup;
  public SearchValidator;
  public selectedContract = null;
  clear: boolean;

  constructor(
    private contractualAlterationService: ContractualAlterationService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.clear = true;
    this.contractAvailables = [];
    this.SearchValidator = [Validators.required];
    this.createForm();
  }

  selectContract(contract) {
    this.selectedContract = contract;
  }

  isCPF(cpf_cnpj): boolean {
    return cpf_cnpj.length < 12 ? true : false;
  }

  getMask(): string {
    let filter = this.contractSearchForm.controls['documentSearch'].value;
    return this.isCPF(filter) ? '000.000.000-009' : '00.000.000/0000-00';
  }

  loadContractsByDocument(document: string) {
    if (document.length > 0) {
      this.isLoading = true;
      this.selectedContract = null;
      this.contractualAlterationService
        .getContractsAvailables(document)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe(
          (success) => {
            this.contractAvailables = success;
            if (this.contractAvailables.length > 0) {
              this.clear = true;
            } else {
              this.clear = false;
            }
          },
          (error) => {
            console.error(error);
            return;
          },
        );
    }
  }

  createForm() {
    this.contractSearchForm = this.formBuilder.group({
      documentSearch: ['', this.SearchValidator],
    });
  }

  clearMessage() {
    this.clear = true;
  }

  showLimitChangeModal(contract) {
    const modalRef = this.modalService.open(LimitChangeModalComponent);
    modalRef.componentInstance.contract = contract;
    modalRef.componentInstance.event.subscribe(() => {
      this.selectedContract = null;
      this.contractAvailables = null;
    });
  }
}
