import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetAllCanceledReceivedOrdersQueryArguments } from '../dtos/get-all-canceled-received-orders-query.arguments';
import { GetAllActiveOrdersQueryArguments } from '../dtos/get-all-active-orders-query.arguments';
import { AllActiveOrders } from '@models/old/allactiverders.model';
import { OrderDetails } from '@models/old/orderdetails.model';
import { LogService } from '@services/log.service';
import { LocalstorageService } from '@services/localstorage.service';
import { Observable, map } from 'rxjs';
import { TagRequestQueryResult } from '@models/old/tagrequestqueryresult/tagrequestqueryresult.model';
import { TagRequest, TagRequestPrepaid } from '@models/old/tagrequest/tagrequest.model';
import { Dto } from '@models/dto.model';

@Injectable({
  providedIn: 'root'
})
export class MyordersService {
  constructor(
    protected _http: HttpClient,
    protected _logger: LogService,
    private _localStorageService: LocalstorageService
    ) { }

    private _base = environment.apiTMTag;
    private _showErrors = environment.showErrors;
    urlCanceledReceived = `${this._base}/Tag/GetAllCanceledReceivedOrders?customerId=`;

  // Headers
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  public async getOrderDetails(idOrder: Number): Promise<OrderDetails> {
    try {
      let apiUrl = `${this._base}/Tag/GetOrderDetails?idOrder=${idOrder}`;
      var result = await this._http.get<OrderDetails>(apiUrl).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }


  getAllActiveOrders(args: GetAllActiveOrdersQueryArguments){
    return this._http.get<AllActiveOrders[]>(`${this._base}/Tag/GetAllActiveOrders`, {
      params: args.httpParams,
    });

  }

  getAllCanceledReceivedOrders(args: GetAllCanceledReceivedOrdersQueryArguments){
    return this._http.get<AllActiveOrders[]>(`${this._base}/Tag/GetAllCanceledReceivedOrders`, {
      params: args.httpParams,
    });
  }


  /**
   * Obtem paramentro necessarios para gerar pedido de tag.
   * @param {Number} customerId
   * @returns {Promise<TagRequestQueryResult>}
   */
  getContractsById(): Observable<TagRequestQueryResult> {
    return this._http
      .get<TagRequestQueryResult>(
        `${this._base}/api/TagRequest/GetByContractId`
      )
      .pipe(
        map((res) => {
          this._localStorageService.addItemGeneric<TagRequestQueryResult>(
            "tagRequestQueryResult",
            res
          );
          return res;
        })
      );
  }

  /**
   * POST HTTP request that register the Tag Order.
   * Returns an Observable of TagRequestPrepaid DTO.
   *
   * @param {string} tagRequestId.
   * @return {Observable<Dto<TagRequestPrepaid>>} an observable of type Dto<TagRequestPrepaid>.
   * */
  postTagRequest(tagRequest: TagRequest): Observable<Dto<TagRequestPrepaid>> {
    return this._http.post<Dto<TagRequestPrepaid>>(
      `${this._base}/api/TagRequest/`,
      { tagRequest }
    );
  }

  /**
   * Obtem paramentro necessarios para gerar pedido de tag.
   * @param {Number} customerId
   * @param {string} zipCode
   * @returns {Promise<TagRequestQueryResult>}
   */
  async getContractsByIdByZipCode(
    zipCode: string
  ): Promise<TagRequestQueryResult> {
    try {
      let apiUrl = `${this._base}/api/TagRequest/GetByContractIdByZipCode?zipCode=${zipCode}`;
      let result = await this._http
        .get<TagRequestQueryResult>(apiUrl)
        .toPromise();

      this._localStorageService.addItemGeneric<TagRequestQueryResult>(
        "tagRequestQueryResult",
        result
      );

      return result;
    } catch (e) {
      if (this._showErrors) {
        this._logger.showError(
          `Erro em 'TaqRequestService' ${e.status}: ${JSON.stringify(
            e.message
          )}`
        );
      }
    }
  }

  /**
   * Obtem paramentro necessarios para gerar pedido de tag.
   * @param {Number} customerId
   * @param {Number} personAddressId
   * @returns {Promise<TagRequestQueryResult>}
   */
  async getContractsByIdByPersonAddressId(
    personAddressId: Number
  ): Promise<TagRequestQueryResult> {
    try {
      let apiUrl = `${this._base}/api/TagRequest/GetByContractIdByAddressId?personAddressId=${personAddressId}`;
      let result = await this._http
        .get<TagRequestQueryResult>(apiUrl)
        .toPromise();

      this._localStorageService.addItemGeneric<TagRequestQueryResult>(
        "tagRequestQueryResult",
        result
      );

      return result;
    } catch (e) {
      if (this._showErrors) {
        this._logger.showError(
          `Erro em 'TaqRequestService' ${e.status}: ${JSON.stringify(
            e.message
          )}`
        );
      }
    }
  }
}
