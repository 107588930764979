import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent {
  @Input() title : string;
  @Input() subtitle : string;
  @Input() imgPath : string;
  @Input() message : string;
  @Output() event: EventEmitter<any> = new EventEmitter();

  constructor(public modal : NgbActiveModal) {}

  dismiss() {
    this.modal.close()
    this.event.emit();
  }
}
