import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { DashboardComponent } from './dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { DashboardRoutingModule } from './routes/dashboard-routing.module';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { TagsCardComponent } from './components/tags-card/tags-card.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {MatButtonModule} from "@angular/material/button";
import {
  BorderlessButtonComponent
} from "../../../shared/components/button/button-without-border/borderless-button.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DashboardRoutingModule,
    NgbModule,
    MatButtonModule,
    BorderlessButtonComponent,
  ],
  declarations: [
    DashboardComponent,
    InvoicesComponent,
    TagsCardComponent
  ],
  providers: [DatePipe, provideNgxMask()],
})

export class DashboardModule {}
