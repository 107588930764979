<button
  (click)="showFilterContent()"
  class="button-custom-filters"
  [class.active]="idFilter === filtersComponent.activeFilter"
>
  <span class="label">{{ labelTitle }}</span>

  <p class="button-text">{{ textValue }}</p>

  <span class="container-arrow">
    <img class="arrow" src="../../../../assets/img/ico-arrow-right-b.svg" />
  </span>
</button>

<div *ngIf="idFilter === filtersComponent.activeFilter" class="box-area-value">
  <div class="content-extend">
    <h4 class="mb-3">Selecione os valores:</h4>

    <form>
      <div class="value">
        <div class="form-group">
          <label data-e2e="min-value-filter-label" for="LabelMinimo">
            Mínimo
          </label>

          <input
            #MinValue
            data-e2e="min-value-filter"
            type="number"
            min="0"
            id="minValue"
            class="form-control"
            [value]="rangeValues[0]"
            (change)="setValues('min', $event)"
          />
        </div>

        <div class="form-group">
          <label data-e2e="max-value-filter-label" for="LabelMaximo">
            Máximo
          </label>

          <input
            #MaxValue
            data-e2e="max-value-filter"
            type="number"
            min="1"
            id="maxValue"
            class="form-control"
            [value]="rangeValues[1]"
            (change)="setValues('max', $event)"
          />
        </div>
      </div>
    </form>
  </div>
</div>
