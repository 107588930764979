<div class="box-tabs bill-detail-general">
  <div class="tabs">

    <app-tabs
      [inTabs]="tabs"
      [inTabSelected]="selectedTab"
      (outChangeTabSelected)="selectedTab = $event"
    ></app-tabs>

  </div>
</div>

<div class="box-body bill-detail-general">
  <div>
    @switch (selectedTab) {
      @case (getTabFiltersByHeading('Consolidado')) {
        <div class="container px-0 mx-0">
          <div class="row align-items-center consolid-info-responsive" style="min-height: 350px">
            <div class="col-4 d-flex flex-column align-items-end justify-content-end info-value-responsive">
              <span data-e2e="invoice-total-msg" class="text-bill-amount mb-2"
                >Valor total da fatura</span
              >
              <div
                class="box-amount d-flex align-items-center justify-content-end"
              >
                <span data-e2e="invoice-total-data" id="totalBillValue">{{
                  billSummary?.totalBillValue
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                }}</span>
              </div>
            </div>

            <div class="col-4 info-value-responsive">
              <span data-e2e="amount-to-pay-msg" class="text-title" *modalityType="2"
                >Valores a pagar</span
              >
              <span data-e2e="amount-paid-msg" class="text-title" *modalityType="1"
                >Valores pagos</span
              >
              <div class="row mt-3">
                <div class="col-6">
                  <span data-e2e="toll-title" class="services-label d-block">Pedágio</span>
                </div>

                <div class="col-6">
                  <div class="services-values-label">
                    <div data-e2e="toll-amount-data" class="services-values-label-text">
                      <span data-e2e="toll-amount-sign" class="negative-amount">{{
                        billSummary?.passesValue > 0 ? "-" : ""
                      }}</span>
                      <span data-e2e="toll-amount-number" id="passesValue" [ngClass]="billSummary?.passesValue > 0 ? 'negative-amount' : '' ">{{
                        billSummary?.passesValue
                          | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-6">
                  <span data-e2e="toll-reversal-label" class="services-label d-block"
                    >Estornos de pedágio</span
                  >
                </div>

                <div class="col-6">
                  <div class="services-values-label">
                    <div data-e2e="toll-reversal-data" class="services-values-label-text">
                      <span data-e2e="toll-reversal-sign">{{
                        billSummary?.reversalValue > 0 ? "+" : ""
                      }}</span>
                      <span data-e2e="toll-reversal-number" id="reversalValue">{{
                        billSummary?.reversalValue
                          | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-6">
                  <span data-e2e="parking-label" class="services-label d-block">Estacionamento</span>
                </div>

                <div class="col-6">
                  <div class="services-values-label">
                    <div data-e2e="parking-data" class="services-values-label-text">
                      <span data-e2e="parking-sign" class="negative-amount">{{
                        billSummary?.parkingValue > 0 ? "-" : ""
                      }}</span>
                      <span data-e2e="parking-number" id="parkingValue" [ngClass]="billSummary?.parkingValue > 0 ? 'negative-amount' : '' ">{{
                        billSummary?.parkingValue
                          | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-6">
                  <span data-e2e="parking-reversal-label" class="services-label d-block"
                    >Estornos de estacionamento</span
                  >
                </div>

                <div class="col-6">
                  <div class="services-values-label">
                    <div data-e2e="parking-reversal-data" class="services-values-label-text">
                      <span data-e2e="parking-reversal-sign">{{
                        billSummary?.parkingReversalValue > 0 ? "+" : ""
                      }}</span>
                      <span data-e2e="parking-reversal-number" id="parkingReversalValue">{{
                        billSummary?.parkingReversalValue
                          | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-6">
                  <span data-e2e="services-label" class="services-label d-block">Serviços</span>
                </div>

                <div class="col-6">
                  <div class="services-values-label">
                    <div data-e2e="services-data" class="services-values-label-text">
                      <span data-e2e="services-sign" class="negative-amount">{{
                        billSummary?.servicesValue > 0 ? "-" : ""
                      }}</span>
                      <span data-e2e="services-number" id="servicesValue" [ngClass]="billSummary?.servicesValue > 0 ? 'negative-amount' : '' ">
                        {{
                          billSummary?.servicesValue
                            | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Pós-pago-->
            <div class="col-4 align-self-baseline pt-3 info-value-responsive" *modalityType="2">
              <span data-e2e="paid-amounts-title" class="text-title">Valores pagos / Utilizados</span>

              <div class="row mt-3">
                <div class="col-6">
                  <span
                    data-e2e="used-vpr-label"
                    class="services-label d-block"
                    >Vale Pedagio <br />
                    Utilizado</span
                  >
                </div>

                <div class="col-6">
                  <div class="services-values-label">
                    <div data-e2e="used-vpr-data" class="services-values-label-text">
                      <span data-e2e="used-vpr-sign">{{
                        billSummary?.vprValue > 0 ? "+" : ""
                      }}</span>
                      <span data-e2e="used-vpr-number" id="vprValue">{{
                        billSummary?.vprValue
                          | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="billSummary?.hasVprAntecipation" class="mt-3 info-discount-bill-responsive">
                <span data-e2e="invoice-discount-title" class="text-title">Desconto em fatura</span>

                <div class="row mt-3">
                  <div class="col-6">
                    <span class="services-label d-block">
                      <img
                        ngbTooltip="Antecipação de vale-pedágio realizada no período"
                        src="./assets/img/ico-alert.svg"
                        class="mr-1 ico-alert"
                        alt=""
                        data-e2e="vpr-antecipation-label"
                      />Antecipação <br />
                      de Vale-Pedágio</span
                    >
                  </div>

                  <div class="col-6">
                    <div class="services-values-label">
                      <div data-e2e="vpr-antecipation-data" class="services-values-label-text">
                        <span data-e2e="vpr-antecipation-sign">{{
                          billSummary?.vprAntecipationValue > 0 ? "+" : ""
                        }}</span>
                        <span data-e2e="vpr-antecipation-number" id="vprValue">{{
                          billSummary?.vprAntecipationValue
                            | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                        }}</span>

                        <button
                          data-e2e="vpr-more-details-btn"
                          ngbTooltip="Veja o relatório da antecipação de vale-pedágio"
                          (click)="showAdvanceDetailsModal()"
                          class="btn btn-circle-add"
                        >
                          <span>+</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Pré-pago-->
            <div class="col-4 info-value-responsive" *modalityType="1">
              <div class="row">
                <div class="col-12 align-self-baseline mg15">
                  <span data-e2e="paid-amounts-title" class="text-title">Valores pagos / Utilizados</span>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="used-vpr-label" class="services2-label d-block"
                        >Vale Pedagio <br />
                        Utilizado</span
                      >
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="used-vpr-data" class="services-values-label-text">
                          <span data-e2e="used-vpr-sign">{{
                            billSummary?.vprValue > 0 ? "+" : ""
                          }}</span>
                          <span data-e2e="used-vpr-number" id="vprValue">{{
                            billSummary?.vprValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 align-self-baseline mg15">
                  <span data-e2e="recharge-title" class="text-title">Valores de Recarga</span>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="single-recharge-label" class="services2-label d-block"
                        >Recarga <br />
                        Avulsa</span
                      >
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="single-recharge-data" class="services-values-label-text">
                          <span data-e2e="single-recharge-sign">{{
                            billSummary?.singleRefillValue > 0 ? "+" : ""
                          }}</span>
                          <span data-e2e="single-recharge-number" id="singleRefillValue">{{
                            billSummary?.singleRefillValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="auto-recharge-label" class="services2-label d-block"
                        >Recarga <br />
                        Automática</span
                      >
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="auto-recharge-data" class="services-values-label-text">
                          <span data-e2e="auto-recharge-sign">{{
                            billSummary?.automaticRefillValue > 0 ? "+" : ""
                          }}</span>
                          <span data-e2e="auto-recharge-number" id="automaticRefillValue">{{
                            billSummary?.automaticRefillValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      @case (getTabFiltersByHeading('Serviços')) {
        <app-bill-services></app-bill-services>
      }
      @case (getTabFiltersByHeading('Passagens e Estornos')) {
        <app-bill-passes-chargeback></app-bill-passes-chargeback>
      }
    }
  </div>
</div>
