import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MyvehiclesService } from '../../../myvehicles/services/myvehicles.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-reinclude-vehicle-modal',
  templateUrl: './reinclude-vehicle-modal.component.html',
  styleUrls: ['./reinclude-vehicle-modal.component.scss']
})
export class ReincludeVehicleModalComponent implements OnInit {

  constructor(private modalService: NgbModal, private myVehiclesService: MyvehiclesService, private notifyService: NotifyService) { }

  @Input() vehicle;
  @Output() event: EventEmitter<any> = new EventEmitter();

  success: any;
  onSaving:boolean;
  step:number = 1;

  ngOnInit(): void {}

  generateVehicle(vehicle){
    return {
      hierarchyId: vehicle.hierarchyId,
      licensePlate: vehicle.licensePlate,
      model: vehicle.model,
      nickName: vehicle.nickName,
      vehicleBrandTypeId: vehicle.vehicleBrandTypeId,
      vehicleCategoryId: vehicle.vehicleCategoryId,
      vehicleType: vehicle.vehicleType,
      aggregateDriver: vehicle.aggregateDriver,
      rntrc: vehicle.rntrc,
      documentNumber: vehicle.documentNumber,
    }
  }

  reincludeVehicle(){
    let form = this.generateVehicle(this.vehicle)
    this.onSaving = true;
    this.myVehiclesService
        .onAddVehicle(form)
        .pipe(
          finalize(() => {
            this.onSaving = false;
          })
        )
        .subscribe(
          (success) => {
            this.success = success;
            this.step = 2;
          },
          (e) => {
            console.error(e);
          }
        );
  }

  emitAndClose(){
    if(this.step == 2){
      this.event.emit();
    }
    this.close();
  }

  close() {
    this.modalService.dismissAll();
  }


}
