import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from '@env';
import { ValidateUserByDocumentAndGroupDto } from "../models/validate-user-by-document-group.dto";
import { ResponseDto } from "@models/responseDto.model";
import { ValidateUserByEmailAndGroupDto } from "../models/validate-user-by-email-group.dto";
import { Dto } from "@models/dto.model";
import { FeedbackCreateUpdateUserDto } from "../models/feedback-create-update-user.dto";
import { UserDetailsFillFormModel } from "../models/user-details-fill-form.model";
import {UserModel} from "@models/backoffice/user.model";

@Injectable({
  providedIn: 'root'
})
export class UserConsultantCreateUpdateService {

  constructor(private _http: HttpClient) {}

  existsUserByDocumentNumberAndGroup(args: ValidateUserByDocumentAndGroupDto) {
    return this._http.get<ResponseDto<{existsUser: boolean}>>(
      environment.apiTMIdentityServer + `/Customer/User/ExistsUserByDocumentNumberAndGroup`,
      { params: args.httpParams }
    );
  }

  existsUserByEmailAndGroup(args: ValidateUserByEmailAndGroupDto) {
    return this._http.get<ResponseDto<{existsUser: boolean}>>(
      environment.apiTMIdentityServer + `/Customer/User/ExistsUserByEmailAndGroup`,
      { params: args.httpParams }
    );
  }

  createUser(object: UserModel) {
    return this._http.post<Dto<FeedbackCreateUpdateUserDto>>(
      environment.apiTMCustomer + `/Person/CreatePersonGuestUserWithoutContract`,
      object
    );
  }

  getUserDetailsById(userId: number) {
    return this._http.get<Dto<UserDetailsFillFormModel>>(
      environment.apiTMCustomer + `/Person/GetPersonByIdentityUserId?userId=${userId}`
    );
  }

  updateUser(object: UserModel) {
    return this._http.put<Dto<FeedbackCreateUpdateUserDto>>(
      environment.apiTMCustomer + `/Person/RelationshipConsultant`,
      object
    );
  }

}
