import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss'],
})
export class OrderTrackingComponent implements OnInit {
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder) {}
  @Input() orderDetail: any;

  viewTrackingForm: FormGroup;

  ngOnInit(): void {
     this.createFormReportTracking();
  }

  createFormReportTracking() {
    this.viewTrackingForm = this.formBuilder.group({
      trackingCode: [this.orderDetail?.trackingCode],
    });
  }

  openViewTracking() {
    var openInOtherTab = window.open(this.orderDetail.logisticPartnerLink, '_blank');
    openInOtherTab.focus();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  copyCode(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
