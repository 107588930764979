import { Component, OnInit } from "@angular/core";
import {ActivatedRoute, Params} from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale, ptBrLocale } from "ngx-bootstrap/chronos";
import { EventEmitterService } from "@services/event-emitter.service";
import { MyordersService } from "../../../myorder/services/myorder.service";
import { OrderTrackingComponent } from "../order-tracking/order-tracking.component";
import {UserProfileService} from "@services/user-profile.service";
defineLocale("pt-br", ptBrLocale);

@Component({
  selector: "app-detailorder",
  templateUrl: "./detailorder.component.html",
  styleUrls: ["./detailorder.component.scss"],
})
export class DetailorderComponent implements OnInit {
  public orderDetail: any;
  public id: number;
  public buttonDisabled: boolean;
  public received;

  constructor(
    private localeService: BsLocaleService,
    protected myorderService: MyordersService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userProfileService: UserProfileService
  ) {
    EventEmitterService.get("RefreshAfterTryAgainOrder").subscribe((data) =>
      this.getOrderDetail(this.id)
    );
  }

  ngOnInit() {
    this.received = false;
    this.orderDetail = {
      orderId: 0,
      amount: 0,
      deliverytTerm: 0,
      shippingValue: 0,
      streetAddress: "",
      zipCode: "",
      number: "",
      streetAddressLine2: "",
      district: "",
      city: "",
      federatedUnit: "",
      mailBox: "",
      landmark: "",
      personAddressTypeId: 0,
      addressTypeId: 0,
      fullName: "",
      phoneNumber: "",
      totalTagValue: 0,
      totalOrder: 0,
      totalOrderDiscount: 0,
      totalAmountPayable: 0,
      shippingDiscount: 0,
      subTotal: 0,
      statusOrder: "",
      trackingCode: "",
      descriptionAddress: "",
      getTagRequestTrackingQueryResults: [],
      totalTagValueDiscount: 0,
      totalShippingDiscount: 0,
      logisticPartnerLink: "",
      logisticPartnerImage: "",
    };
    this.localeService.use("pt-br");

    this.route.params.subscribe((params: Params) => {
      this.id = +params["id"]; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
      this.getOrderDetail(this.id);
    });
  }

  getOrderDetail(idOrder) {
    this.myorderService.getOrderDetails(idOrder).then(
      (result) => {
        this.TimelineOrder(result);
      },
      (errors) => {
        console.log("Promise rejeitada: " + JSON.stringify(errors));
      }
    );
  }

  getArrowClass(modalityType) {
    if (modalityType == 1) {
      if (this.buttonDisabled == false) {
        return "arrow-top-PrePaid";
      } else {
        return "arrow-top-PrePaid arrow-trasparent";
      }
    } else {
      if (this.buttonDisabled == false) {
        return "arrow-top-PostPaid";
      } else {
        return "arrow-top-PostPaid arrow-trasparent";
      }
    }
  }

  TimelineOrder(result) {
    this.orderDetail = result;
    if (
      this.orderDetail.getTagRequestTrackingQueryResults
        .filter((item) => item.isActive === true)
        .map((item) => item.id) == 5 &&
      this.orderDetail.trackingCode !== null &&
      this.orderDetail.trackingCode !== ""
    ) {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }

    if (
      this.orderDetail.getTagRequestTrackingQueryResults
        .filter((item) => item.isActive === true)
        .map((item) => item.id) == 7
    ) {
      this.received = true;
    } else {
      this.received = false;
    }
  }

  GetClassTimeline(i, active) {
    if (i == 0 && active == false) {
      return "visited first";
    } else if (i != 0 && active == false) {
      return "visited next";
    } else {
      return "active";
    }
  }

  GetClassTimelineImage(id) {
    return "ico ico-order-" + id;
  }

  TrackOrder() {
    const modalRef = this.modalService.open(OrderTrackingComponent);
    modalRef.componentInstance.orderDetail = this.orderDetail;
  }

  handleRouterByProfile() {
    return this.userProfileService.isCustomerOrRelationshipProfile() ?
      '/meus-pedidos' : '/backoffice/logistica/pedidos-tag';
  }
}
