<div class="contactUsComponent">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 Dashboard_Overview">
        <h1 data-e2e="page-title">/ Fale Conosco</h1>
      </div>

      <div class="col-12 mt-3 Dashboard_col_1">
        <div class="container-fale-conosco">
          <div class="box-title">
            <span data-e2e="container-title">fale conosco</span>
          </div>

          <div class="box-body">
            <div data-e2e="contact-type-msg" class="title" *ngIf="step == 1">
              Escolha o tipo de atendimento e preencha o formulário para entrar
              em contato
            </div>

            <div data-e2e="sent-msg" class="title" *ngIf="step == 2">
              Formulário Enviado, analisaremos a sua mensagem
              <span *ngIf="returnType !== 3"> e entraremos em contato </span>
            </div>

            <div class="categoryTypes row">
              <div class="category col" *ngIf="step == 1">
                <input data-e2e="request-checkbox" class="checkbox-input" [checked]="categoryList[0]" type="radio"
                  name="category" value="1" (click)="onRadioChange($event)" />
                <span class="checkmark"></span>
                <div data-e2e="request-label" class="checkbox-label">
                  Solicitação
                </div>
              </div>

              <div class="category col" *ngIf="step == 1">
                <input data-e2e="report-error-checkbox" class="checkbox-input" [checked]="categoryList[1]" type="radio"
                  name="category" value="2" (click)="onRadioChange($event)" />
                <span class="checkmark"></span>
                <div data-e2e="report-error-label" class="checkbox-label">
                  Relatar um problema
                </div>
              </div>

              <div class="category col" *ngIf="step == 1">
                <input data-e2e="suggest-checkbox" class="checkbox-input" [checked]="categoryList[2]" type="radio"
                  name="category" value="3" (click)="onRadioChange($event)" />
                <span class="checkmark"></span>
                <div data-e2e="suggest-label" class="checkbox-label">
                  Sugestão
                </div>
              </div>

              <div class="col" *ngIf="step == 2">
                <div class="protocol" data-e2e="protocol-msg">
                  <strong>Código do protocolo:</strong><span>{{ protocol }}</span>
                </div>
              </div>
            </div>

            <!--   STEP 1-->
            <div class="row" *ngIf="step == 1">
              <div class="subCategoriesList col-12" *ngIf="chosenCategory == 1">
                <mat-form-field>
                  <mat-label>Tipo de solicitação</mat-label>
                  <mat-select panelClass="mat-select-subcategory" [multiple]="false"
                    (selectionChange)="onSelectSubCategory($event.value)">
                    <mat-option [value]=""></mat-option>
                    <mat-option [value]="subcategory" *ngFor="let subcategory of subcategoryList1"
                      style="background-color: white; color: black;">
                      <img src="assets/img/ico-arrow-right-b.svg" class="button-icon" />
                      {{subcategory.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
              <!-- Step 2 -->
              <div class="subCategoriesList col-12" *ngIf="chosenCategory == 2">
                <mat-form-field>
                  <mat-label>Tipo de problema</mat-label>
                  <mat-select panelClass="mat-select-subcategory" [multiple]="false"
                    (selectionChange)="onSelectSubCategory($event.value)">
                    <mat-option [value]=""></mat-option>
                    <mat-option [value]="subcategory" *ngFor="let subcategory of subcategoryList2"
                      style="background-color: white; color: black;">
                      <img src="assets/img/ico-arrow-right-b.svg" class="button-icon" />
                      {{subcategory.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>


              <div class="form col-12" [ngClass]="sugestionForm ? 'sugestionForm' : ''">
                <app-form (formSentEvent)="changeStep($event)" [chosenSubCategoryId]="chosenSubCategoryId"
                  [userInfos]="userInfos"></app-form>
              </div>
            </div>

            <div class="row" *ngIf="step == 2">
              <div class="confirmationMessage" data-e2e="confirmation-msg">
                <strong>Prezado(a) </strong>
                <strong class="userName" data-e2e="username-data">
                  {{ userInfos?.fullName }}
                </strong>,
                <br />
                <br />
                {{
                chosenCategory == 1
                ? "sua solicitação foi registrada"
                : chosenCategory == 2
                ? "seu relato do problema foi registrado"
                : "sua sugestão foi registrada"
                }}
                no
                <strong>protocolo de número
                  <span id="confirmationProtocol" data-e2e="protocol-data">
                    {{ protocol }}
                  </span>
                </strong>.

                <br />

                <div data-e2e="reply-msg" *ngIf="returnType !== 3">
                  Retornaremos no <strong>prazo de até 7 dias</strong> no
                  <span id="returnType" data-e2e="reply-type">{{
                    returnType == 1 ? "telefone: " : "e-mail: "
                    }}</span>

                  <span data-e2e="return-type-data" id="returnTypeText" *ngIf="returnType == 1">
                    {{ returnTypeText | mask: getMask(returnTypeText) }}
                  </span>

                  <span data-e2e="return-type-data" id="returnTypeText" *ngIf="returnType == 2">
                    {{ returnTypeText }}
                  </span>.
                </div>

                <br /><br />
                Atenciosamente,<br />Central de atendimento.<br /><br />
                <strong *ngIf="returnType !== 3">Caso precise de um retorno urgente, basta informar o número
                  do protocolo acima em nosso CHAT, disponível no
                  Portal.</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>