import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmailUpdateToken, PhoneUpdateToken } from './models/update-token.model';


@Injectable({
  providedIn: 'root'
})
export class VerificationCodeService {

  private _TMCustomer = environment.apiTMCustomer

  verificationCode$ = new BehaviorSubject<string>(undefined);
  dataToVerificationCode$ = new BehaviorSubject<any>(undefined);
  isValidCode$ = new BehaviorSubject<boolean>(undefined);

  constructor(protected _http: HttpClient) { }

  postEmailUpdateToken(data){
    return this._http.post<EmailUpdateToken>
    ( `${this._TMCustomer}/PersonEmailAddress/SendEmailUpdateToken`,data)    
  }

  postPhoneUpdateToken(data){
    return this._http.post<PhoneUpdateToken>
    ( `${this._TMCustomer}/PersonPhone/SendPhoneUpdateToken`,data)    
  }
  
}
