import { Component, EventEmitter, Input, Output } from "@angular/core";

import { BsDaterangepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";

import { Filters } from "@models/filter.model";
import { Position } from '@models/types.model';
@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent {
  @Input() title: string = "Período";
  @Input("placement") position: Position = null;
  @Output() filters = new EventEmitter<Filters>();

  maxDate: Date = new Date();
  dateValue: Date = this.maxDate;
  minDate: Date = new Date("05/07/2017");

  constructor(
    public bsConfig: BsDaterangepickerConfig,
    private _localeService: BsLocaleService,
  ) {
    this._localeService.use('pt-br');
  }

  emitFilter(filters): void {
    const filterParams = {
      startDate: filters[0],
      endDate: filters[1]
    }

    this.filters.emit(filterParams);  }
}
