import { Component, Input, OnInit} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "recharge-fee-modal",
  templateUrl: "./recharge-fee-modal.component.html",
  styleUrls: ["./recharge-fee-modal.component.scss"],
})
export class RechargeFeeModalComponent implements OnInit {
  isLoading: boolean = false;
  success: any;
  @Input() rechargeFee: number;
  constructor(public modal: NgbModal) {
    
  }

  ngOnInit() {
  }  
}
