import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { finalize } from 'rxjs/operators';
import { trigger, transition, state, animate, style } from '@angular/animations';
import { LegalPerson } from '@models/old/legalperson.model';
import { LocalstorageService } from '@services/localstorage.service';
import { MyContractService } from '@services/mycontract.service';
import {PostPaidContractBalance} from "@models/old/postpaidcontractbalance.model";
import {UserProfileService} from "@services/user-profile.service";
import {Router} from "@angular/router";
import {ProfileType} from "@variables/profileType";


@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html',
  styleUrls: ['./customer-header.component.scss']
})
export class CustomerHeaderComponent implements OnInit, AfterViewInit {
  protected CLIENT_TYPE = {
    PRE: 1,
    POS: 2
  };
  public legalPerson: LegalPerson;
  companyName: any;
  haveManyContracts = null;
  BorderAnimation:boolean;
  hoverButton: boolean = false;
  hoverDropdown: boolean = false;
  isOpenAccount: boolean;
  postPaidContractBalance: PostPaidContractBalance;
  sizeScreen: number;
  isMenuOpen: boolean = false;
  settingBtnMultipleProfile: {hasMultipleProfile: boolean, description: string, url: string};
  isUserProfileBackOffice: boolean = false;

  constructor(
    private oauthService: OAuthService,
    private myContractService: MyContractService,
    private localstorageService: LocalstorageService,
    private userProfileService: UserProfileService,
    private _route: Router
    ) {
    this.isUserProfileBackOffice = this.userProfileService.isBackOffice();
      this.companyName = {
        'name': '',
        'personDocumentTypeId': 0,
        'documentNumber': 0,
        'contractId' : 0,
        'contractName': ''
      }
    }

  private initializeSettingBtnMultipleProfile(): void {
    const hasMultipleProfile: boolean = this.userProfileService.hasMultipleProfile();
    let description: string = 'Portal do cliente';
    let url: string = '/selecionar-contrato';

    if (this.userProfileService.isCustomerOrRelationshipProfile()) {
      description = 'Portal de atendimento';
      url = '/backoffice/menu-inicial';
    }

    this.settingBtnMultipleProfile = {
      hasMultipleProfile: hasMultipleProfile,
      description: description,
      url: url
    };
  }

  ngOnInit() {
    this.getName();
    this.initializeSettingBtnMultipleProfile();
  }

  ngAfterViewInit() {
    this.sizeScreen = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.sizeScreen = window.innerWidth;
  }

openDropdown(element){
  if(element == 'button')
  this.hoverButton = true;

  if(element == 'dropdownItem')
  this.hoverDropdown = true;

  this.isOpenAccount = true;
  this.BorderAnimation = true;
}

closeDropdown(element){
      if(element == 'button')
      this.hoverButton = false;

      if(element == 'dropdownItem')
      this.hoverDropdown = false;

      if(!this.hoverButton && !this.hoverDropdown)
        this.isOpenAccount = false;
}

  getLegalPerson() {
    this.myContractService.getLegalPerson()
    .subscribe(
      (success) => {
        this.legalPerson = success;
      },
      (error) => {
        console.log("Promise rejeitada: " + JSON.stringify(error));
        // this.alertService.danger(error.message);
        return;
      }
    );
  }


  getName() {
    this.myContractService
      .getName()
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (success) => {
          this.companyName = success;
        },
        (error) => {
          // TODO: Depois que for implementado alertService descomentar a linha de baixo para tratar os retornos das apis
          //this.alertService.danger(error.message);
          console.error("getFromServerError", error);
          return;
        }
      );
  }

  logout() {
    this.localstorageService.removeAllProfiles();
    this.oauthService.logOut();
  }

  get username(): string | null {
    let claims = this.oauthService.getIdentityClaims();
    return claims ? claims['given_name'] : null;
  }

  get email(): string | null {
    let claims = this.oauthService.getIdentityClaims();
    return claims ? claims['email'] : null;
  }

  getHaveManyContracts(): boolean {
    return this.localstorageService.getHaveManyContracts();
  }

  reciverPaidContractBalance(event: PostPaidContractBalance): void {
    if (event) {
      this.postPaidContractBalance = event;
    }
  }

  handleContractNameSize(companyName: any): string {
    const contractName: string = companyName.name;
    const trace: string = '- ';
    const reticences: string = '...';

    let maxLength: number = this.defineMaximumLengthForScreenSize();

    if (maxLength > 0 && contractName.length > maxLength) {
      return trace + contractName.slice(0, maxLength) + reticences;
    } else {
      return contractName.length > 0 ? trace + contractName : '';
    }
  }

  private defineMaximumLengthForScreenSize(): number {
    let maxLength: number;

    if (this.sizeScreen <= 320) {
      maxLength = 10;
    } else if (this.sizeScreen <= 360) {
      maxLength = 13;
    } else if (this.sizeScreen <= 428) {
      maxLength = 15;
    } else if (this.sizeScreen <= 1024) {
      maxLength = 30;
    } else {
      maxLength = 0;
    }

    return maxLength;
  }

  handleRouteByProfile() {
    let url: string = '/selecionar-contrato';

    if (this.isUserProfileBackOffice) {
     url = '/backoffice/atendimento/acessar-contrato';
    }

    this._route.navigateByUrl(url);
  }

  updateProfile(url: string) {
    if(this.userProfileService.hasMultipleProfile()) {
      if (this.userProfileService.isCustomerOrRelationshipProfile()) {
        this.userProfileService.updateUserProfile(ProfileType.BACKOFFICE);
      } else {
        this.userProfileService.updateUserProfile(ProfileType.CUSTOMER);
      }
    }

    this._route.navigateByUrl(url);
  }

  handleClassCustomized(): boolean {
    const exitsClass: any = document.querySelector('.Header_UserMenu_BT_Usuarios');
    return !exitsClass;
  }

}
