import { HttpParams } from '@angular/common/http';


export class VehiclesQueryArguments{
  _PageSize: number;

  constructor(
    public page?: number,
    public pageSize?: number,
    public orderBy?: string,
    public desc?: boolean,
    public search?: string,
    public filter?: number,
  ) {}

  get httpParams() {
    let httpParams = new HttpParams()

     if (this._PageSize)
      httpParams = httpParams.set('PageSize', this._PageSize.toString());

      httpParams.append('page', this.page.toString())
      httpParams.append('pageSize', this.pageSize.toString())
      httpParams.append('SortOrder', this.desc ? '1' : '0')
      httpParams.append('sortField', this.orderBy)
      httpParams.append('search', this.search)
      httpParams.append('filter', this.filter?.toString());

    return httpParams;

  }
}
