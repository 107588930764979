import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VprCreditReceivedComponent } from './vpr-credit-received.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { VprCreditReceivedRoutingModule } from './routes/vpr-credit-received-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RadioButtonModule } from 'src/app/shared/components/radiobutton/radiobutton.module';
import { ShowDocumentsModalComponent } from './components/show-documents-modal/show-documents-modal.component';
import { SearchFormInlineComponent } from 'src/app/shared/components/search-form-inline/search-form-inline.component';
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { OutlinedSelectComponent } from 'src/app/shared/select/outlined-select/outlined-select.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    NgbModule,
    RouterModule,
    BsDatepickerModule,
    BsDropdownModule,
    VprCreditReceivedRoutingModule,
    OutlinedSelectComponent,
    SearchFormInlineComponent,
    BorderlessButtonComponent,
    MatRadioModule
  ],
  declarations: [
    VprCreditReceivedComponent,
    ShowDocumentsModalComponent
  ],
  providers: [provideNgxMask()],
})

export class VprCreditReceivedModule {}
