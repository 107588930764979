import {Component, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
// import {LocalstorageService} from '@services/localstorage.service';
import {Logger} from '@services/logger.service';
import {ContractService} from "@services/contract.service";


const log = new Logger('LoginComponent');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading = false;
  action: any;
  error: string;
  message: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    // private storageService: LocalstorageService,
    private contractService: ContractService
  ) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const token = params['externalToken'];
      if (token) {
        this.externalToken(token);
      } else {
        this.loginOrFirstAccess();
      }
    });

  }

  loginOrFirstAccess() {
    this.route.queryParams.subscribe(parametros => {
      if (parametros.redirect == "/?action=firstAccess") {
        this.router.navigate(['/primeiro-acesso']);
      } else {
        this.login()
      }
    });
  }

  async login(): Promise<void> {
    this.isLoading = true;
    this.message = "Verificando sessão.";

    try {
      await this.oauthService.loadDiscoveryDocumentAndTryLogin();

      if (this.oauthService.hasValidAccessToken()) {
        this.router.navigate(['/selecionar-perfil'], {
          queryParams: {},
          replaceUrl: true
        });
      } else {
        this.redirectToWelcomePage();
      }
    } catch (error) {
      this.error = "Não foi possível inicializar sua sessão.";
      log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async externalToken(token: string) {
    this.isLoading = true;
    this.message = "Validando token externo.";

    try {

      this.oauthService.setStorage(localStorage);
      localStorage.setItem('access_token', token);

      await this.oauthService.tryLogin();

      if (this.oauthService.hasValidAccessToken()) {
        this.router.navigate(['/selecionar-perfil'], {
          queryParams: {},
          replaceUrl: true
        });
      } else {
        throw new Error("Token inválido ou expirado.");
      }
    } catch (error) {
      this.error = "Não foi possível inicializar sua sessão com o token externo.";
      log.error(error);
    } finally {
      this.isLoading = false;
    }
  }


  ngOnDestroy() {
  }

  redirectToWelcomePage() {
    this.message = "Redirecionando para tela de login."
    // this.storageService.setRoles(null);
    // this.storageService.setContractId(null);
    this.contractService.removeContract();
    this.router.navigate(['/login/bem-vindo']);
  }

  tryAgain(event: any) {
    this.oauthService.logOut();
    localStorage.clear()
    document.location.reload();
  }

}
