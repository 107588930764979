import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransactionConciliationComponent } from '../transaction-conciliation.component';
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";

const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.TRANSACTION_CONCILIATION,
        component: TransactionConciliationComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Conciliação de Transação'),
          roles: '100993',
          contractRequired: false,
        },
      },
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class TransactionConciliationRoutingModule {}
