export class FeedbackCreateUpdateUserDto {
  fullName: string;
  documentNumber: string;
  dateOfBirth: string;
  telephoneNumber: string;
  email: string;
  protocol: string;
  registrationUsername: string
  registrationDate: string
}
