import { Component, OnInit } from '@angular/core';
import { ContactUsService } from './services/contact-us.service';
import { ContactTypeId, ContactTypeText } from './dtos/contact-type.enum';
import { EventEmitterService } from '@services/event-emitter.service';
import { Router } from "@angular/router";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends AbstractProcessLauncher implements OnInit {

  chosenCategory: number;
  chosenSubCategoryText: string;
  chosenSubCategoryId: number;
  categoryList: Array<boolean> = [true, false, false];
  userInfos: any;
  sugestionForm: boolean;
  step: number;
  protocol: string;
  returnType: number;
  returnTypeText: string;

  public subcategoryList1 = [
    { id: ContactTypeId.ChangeRegistrationData, value: ContactTypeText.ChangeRegistrationData },
    { id: ContactTypeId.UpdateLicensePlateMercosur, value: ContactTypeText.UpdateLicensePlateMercosur },
    { id: ContactTypeId.RegisterNewUser, value: ContactTypeText.RegisterNewUser },
    { id: ContactTypeId.ContestationPassage, value: ContactTypeText.ContestationPassage },
    { id: ContactTypeId.ParkingRegistration, value: ContactTypeText.ParkingRegistration },
    { id: ContactTypeId.OtherRequest, value: ContactTypeText.OtherRequest },
  ];
  public subcategoryList2 = [
    { id: ContactTypeId.TollNotOpen, value: ContactTypeText.TollNotOpen },
    { id: ContactTypeId.RegistrationProblem, value: ContactTypeText.RegistrationProblem },
    { id: ContactTypeId.BillProblem, value: ContactTypeText.BillProblem },
    { id: ContactTypeId.AccessProblem, value: ContactTypeText.AccessProblem },
    { id: ContactTypeId.OtherProblem, value: ContactTypeText.OtherProblem },
  ]

  constructor(private contactUsService: ContactUsService,
    protected router: Router) {
    super(router);
  }

  ngOnInit() {
    this.emmitEventWhenStartComponent();
    this.step = 1;
    this.getUserInfos();
    this.chosenCategory = 1;
    this.resetItens();
  }

  private emmitEventWhenStartComponent(): void {
    EventEmitterService.get('alreadyInContactUsPage').subscribe(data => this.resetPage());
  }

  resetPage() {
    this.step = 1;
    this.changeCheckbox(0);
    this.chosenCategory = 1;
    this.resetItens();
  }

  changeStep($event) {
    this.protocol = $event.protocol;
    this.returnType = Number($event.returnType);
    this.returnTypeText = $event.returnTypeText;
    this.step = 2;
  }

  getUserInfos() {
    this.contactUsService.getCurrentUser()
      .subscribe(
        (success) => {
          this.userInfos = success;
          this.handlePhone(this.userInfos)
        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

  getMask(tel) {
    if (tel.length == 11) {
      return '(00) 00000-0000'
    }
    else {
      return '(00) 0000-0000'
    }
  }

  handlePhone(userInfos) {
    if (userInfos && userInfos?.prefix) {
      let prefix = userInfos.prefix.replace(' ', '');
      this.userInfos.telephoneNumber = prefix.concat(userInfos.telephoneNumber);
    }
  }

  resetItens() {
    this.chosenSubCategoryText = ''
    this.chosenSubCategoryId = null;
  }

  onSelectSubCategory(subcategory): void {
    if (!subcategory) {
      this.resetItens();
      return;
    }
    this.chosenSubCategoryId = subcategory == ContactTypeId.Suggestion ? subcategory : subcategory.id;
    this.chosenSubCategoryText = subcategory == ContactTypeId.Suggestion ? '' : subcategory.value;
  }

  changeCheckbox(index) {
    for (let i = 0; i < 3; i++) {
      if (i == index) {
        this.categoryList[i] = true;
      }
      else {
        this.categoryList[i] = false;
      }
    }
  }

  onRadioChange(event) {
    this.changeCheckbox(event.target.value - 1);
    this.resetItens();
    if (event.target.value == 3) {
      this.onSelectSubCategory(ContactTypeId.Suggestion)
      this.sugestionForm = true;
    }
    else {
      this.sugestionForm = false;
    }
    this.chosenCategory = Number(event.target.value);
  }

}
