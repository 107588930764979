import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { MyorderComponent } from '../myorder.component';
import { NeworderComponent } from '../components/neworder/neworder.component';
import { DetailorderComponent } from '../components/detailorder/detailorder.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "meus-pedidos",
      component: MyorderComponent,
      canActivate: [authGuardCustomer],
      data: {
        roles: "101171",
        backofficeRoles: '100994,100995,100996',
        contractRequired: true
      },
    },
    {
      path: "novo-pedido",
      component: NeworderComponent,
      canActivate: [authGuardCustomer],
      data: {
        roles: "101170",
        contractRequired: true,
        backOfficePermited: false
      },
    },
    {
      path: "detalhe-pedido/:id",
      component: DetailorderComponent,
      canActivate: [authGuardCustomer],
      data: {
        roles: "101172",
        backofficeRoles: '100994,100995,100996',
        contractRequired: true
      },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class MyorderRoutingModule {}
