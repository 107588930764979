import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bill } from '@models/old/financial/bill/bill-list/bill.dto';

@Injectable({
  providedIn: 'root'
})
export class BillListService {
    private baseURL = environment.apiTMFinancial;
    
    constructor(protected http: HttpClient) { }

    async GetListBillsByContractId(): Promise<Bill[]> {
    try {
      var result = await this.http.get<Bill[]>(`${this.baseURL}/api/Bill/GetListBillsByContractId`).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

    public GetCSVFile(id: any): Observable<any> {
      return this.http.get(environment.apiTMReport + `/Bill/GetBillCsv?BillId=`+id)
    }

    public GetBillPdfFile(id: any): Observable<any> {
      return this.http.get(this.baseURL + `/api/Bill/GetBillToPDF?BillId=`+id)
    }
}

