export const BILL_STATUS: any = {
  ABERTA: 1,
  A_PAGAR: 5, // aguardando pagamento
  ATRASADA: 6,
  EM_FECHAMENTO: 2,
  FECHADA: 3,
  PAGA: 4
}

export function isValidBillStatusForMainButton(status: number): boolean {
  return [BILL_STATUS.ABERTA, BILL_STATUS.EM_FECHAMENTO, BILL_STATUS.FECHADA, BILL_STATUS.PAGA].includes(status);
}

export function isValidBillStatusForVisualizarDetalhesOrBaixaManual(status: number): boolean {
  return [BILL_STATUS.A_PAGAR, BILL_STATUS.ATRASADA].includes(status);
}

export function isValidBillStatusForBoleto(status: number): boolean {
  return [BILL_STATUS.A_PAGAR, BILL_STATUS.ATRASADA, BILL_STATUS.FECHADA].includes(status);
}

export function isValidBillStatusForNotaFiscalOrPDF(status: number): boolean {
  return [BILL_STATUS.A_PAGAR, BILL_STATUS.ATRASADA, BILL_STATUS.FECHADA, BILL_STATUS.PAGA].includes(status);
}

export function isValidBillStatusForCSV(status: number): boolean {
  return [BILL_STATUS.ABERTA, BILL_STATUS.A_PAGAR, BILL_STATUS.ATRASADA, BILL_STATUS.FECHADA, BILL_STATUS.PAGA].includes(status);
}

export function isValidBillStatusForBankSlipByActiveBackOfficeProfile(status: number): boolean {
  return [BILL_STATUS.ABERTA, BILL_STATUS.A_PAGAR, BILL_STATUS.ATRASADA, BILL_STATUS.FECHADA, BILL_STATUS.PAGA].includes(status);
}
