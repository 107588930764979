import { HttpParams } from "@angular/common/http";
import { TableHeadersBatchTagBlockUnblock } from "./batch-management-consts";

export class TagBlockUnblockQuery {
  page: number;
  pageSize: number;
  sortField: string;
  desc: boolean;
  search: string;
  contractId: number;
  contractVehicleTagStatusTypeId: number;

  constructor() {
    this.page = 1;
    this.pageSize = 10;
    this.sortField = TableHeadersBatchTagBlockUnblock.LICENSE_PLATE;
    this.desc = false;
    this.search = '';
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.sortField)
      .append('search', this.search)
      .append('contractId', this.contractId.toString())
      .append('contractVehicleTagStatusTypeId', this.contractVehicleTagStatusTypeId.toString());

    return httpAppend;
  }

}
