import { Component, OnInit, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ContractVehicleTag } from "@models/old/contractvehicletag/contractvehicletag.model";
import { ContractVehicleTagAndUserRegister } from "@models/old/contractvehicletag/contractvehicletagandusertegister.model";
import { VehiclesAvailableQueryResult } from "@models/old/vehicle/vehiclesAvailableQueryResult.model";
import { Tag } from "@models/tag.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContractVehicleTagService } from "@services/contractvehicletag.service";
import { EventEmitterService } from "@services/event-emitter.service";
import { NotifyService } from "@services/notify.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-tag-link-modal",
  templateUrl: "./tag-link-modal.component.html",
  styleUrls: ["./tag-link-modal.component.css"],
})
export class TagLinkModalComponent implements OnInit {
  public tagselected: Tag;
  public vehicleselected: VehiclesAvailableQueryResult;
  public stepconfirmacao: boolean;
  event: EventEmitter<any> = new EventEmitter();
  public contractVehicleTagUserRegister: ContractVehicleTagAndUserRegister;
  service1: boolean;
  service2: boolean;
  onSaving = false;
  ServiceDescription: string;
  serviceId: number;
  @Input() fromVehiclePage: boolean;

  constructor(
    private modalService: NgbModal,
    private bsModalRef: BsModalRef,
    protected notifyService: NotifyService,
    private contractVehicleTagService: ContractVehicleTagService,
    private router: Router
  ) {
    this.service1 = true;
    this.service2 = false;
    this.serviceId = 1;
    this.getNameService(1);
    this.tagselected = new Tag();
    this.vehicleselected = new VehiclesAvailableQueryResult();
    this.stepconfirmacao = true;
  }

  ngOnInit(): void {}

  emitChangeAndDismissModal() {
    if (this.fromVehiclePage) {
      EventEmitterService.get("RefreshVehiclesAfterLinkTag").emit();
      this.router.navigate(["/meus-veiculos"]);
    } else {
      EventEmitterService.get("ReturnToOrigin").emit();
    }
    this.dismissModal();
  }

  dismissModal() {
    this.bsModalRef.hide();
    this.modalService.dismissAll();
  }

  saveContractVehicleTag() {
    const rq = new ContractVehicleTag();
    rq.contractVehicleId = this.vehicleselected.contractVehicleId;
    rq.contractVehicleTagServiceTypeId = this.serviceId;
    rq.tagId = this.tagselected.id;
    this.onSaving = true;
    this.contractVehicleTagService
      .saveContractVehicleTag(rq)
      .pipe(
        finalize(() => {
          this.onSaving = false;
        })
      )
      .subscribe(
        (success) => {
          this.contractVehicleTagUserRegister =
            success.contractVehicleTagAndUserRegister;
          this.stepconfirmacao = false;
          this.event.emit(true);
          this.notifyService.showSuccess(
            "Atenção!",
            "Vínculo realizado com sucesso!"
          );
          this.event.emit(success);
        },
        (e) => {
         console.error(e);
        }
      );
  }

  getNameService(service) {
    if (service == 1) {
      this.ServiceDescription = "Pedágio + Estacionamento";
    } else {
      this.ServiceDescription = "Pedágio";
    }
  }

  onItemChange(event) {
    this.serviceId = +event.target.value;
    this.getNameService(this.serviceId);
  }
}
