import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { ConsultReasonModalComponent } from '../consult-reason-modal/consult-reason-modal.component';
import {OrderTrackingComponent} from "@modules/customer/myorder/components/order-tracking/order-tracking.component";
import {RequestService} from "@modules/backoffice/request/services/request.service";
import {RoutesPaths} from "../../../../../core/utils/routes/routes-paths";
import {AuthService} from "../../../../../core/auth/auth.service";
import {OrderDetails} from "@modules/backoffice/request/dtos/orderdetails.model";
defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss'],
})
export class RequestDetailComponent implements OnInit, OnDestroy {
  public orderDetail: OrderDetails;
  public id: number;
  public buttonDisabled: boolean;

  public RequestTracking = [];
  public received;
  isLoading: boolean;
  tagRequestPrepaid: any;

  isBackoffice: boolean;
  requestPath = RoutesPaths.REQUEST;

  constructor(
    protected detailOrderService: RequestService,
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.received = false;
    this.orderDetail = {
      orderId: 0,
      amount: 0,
      deliverytTerm: 0,
      shippingValue: 0,
      streetAddress: '',
      zipCode: '',
      number: '',
      streetAddressLine2: '',
      district: '',
      city: '',
      federatedUnit: '',
      mailBox: '',
      landmark: '',
      personAddressTypeId: 0,
      addressTypeId: 0,
      fullName: '',
      phoneNumber: '',
      totalTagValue: 0,
      totalOrder: 0,
      totalOrderDiscount: 0,
      totalAmountPayable: 0,
      shippingDiscount: 0,
      subTotal: 0,
      statusOrder: '',
      trackingCode: '',
      descriptionAddress: '',
      getTagRequestTrackingQueryResults: [],
      totalTagValueDiscount: 0,
      totalShippingDiscount: 0,
      logisticPartnerLink: '',
      logisticPartnerImage: '',
    };
    this.localeService.use('pt-br');

    this.route.params.subscribe((params) => {
      const route = this.route.snapshot.parent.toString();

      this.id = +params['id']; // (+) converts string 'id' to a number

      if (route.includes('backoffice')) {
        this.isBackoffice = true;
      }

      // In a real app: dispatch action to load the details here.
      this.getOrderDetail(this.id);
    });
  }

  getOrderDetail(id: number) {
    this.isLoading = true;
    this.detailOrderService
      .getOrderDetails(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success: any) => {
          this.TimelineOrder(success);

          // this.separateOrder.tagRequestSeparationCount = 2;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }

  getArrowClass(type) {
    if (type == 1) {
      if (this.buttonDisabled == false) {
        return 'arrow-top-PrePaid';
      } else {
        return 'arrow-top-PrePaid arrow-trasparent';
      }
    } else {
      if (this.buttonDisabled == false) {
        return 'arrow-top-PostPaid';
      } else {
        return 'arrow-top-PostPaid arrow-trasparent';
      }
    }
  }

  TimelineOrder(result) {
    this.orderDetail = result;
    if (
      Number(
        this.orderDetail.getTagRequestTrackingQueryResults
          .filter((item) => item.isActive === true)
          .map((item) => item.id),
      ) == 5 &&
      this.orderDetail.trackingCode !== null &&
      this.orderDetail.trackingCode !== ''
    ) {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }

    if (
      Number(
        this.orderDetail.getTagRequestTrackingQueryResults
          .filter((item) => item.isActive === true)
          .map((item) => item.id),
      ) == 7
    ) {
      this.received = true;
    } else {
      this.received = false;
    }
  }

  GetClassTimeline(i, active) {
    if (i == 0 && active == false) {
      return 'visited first';
    } else if (i != 0 && active == false) {
      return 'visited next';
    } else {
      return 'active';
    }
  }

  GetClassTimelineImage(id) {
    return 'ico ico-order-' + id;
  }

  TrackOrder() {
    const modalRef = this.modalService.open(OrderTrackingComponent);
    modalRef.componentInstance.orderDetail = this.orderDetail;
  }

  ConsultReason(order) {
    const modalRef = this.modalService.open(ConsultReasonModalComponent);
    modalRef.componentInstance.orderStatus = order;
    modalRef.componentInstance.orderDetail = this.orderDetail;
  }

  ngOnDestroy(): void {
    if (this.isBackoffice) {
      this.authService.removeContractModalityTypeId();
      this.authService.removeContractId();
    }
  }
}
