<div class="dashboardComponent">
  <div class="Dashboard container-fluid">
    <div class="row">
      <div class="col-12">
        <app-page-title [title]="'Gestão de Pedágio'" [contractBlock]="contractBlock" [contract]="contract"></app-page-title>
      </div>

      <div class="col-12">
        <app-first-access-ballon [page]="1"></app-first-access-ballon>
      </div>

      <div class="container-fluid">
        <div class="row">
          @if(isVisibleAppInvoices()) {
            <div class="col app-invoice">
              <app-invoices [contractBlock]="contractBlock"></app-invoices>
            </div>
          }
          <div
            class="container-repom container-dashboard last-order-responsive"
            [ngClass]="isVisibleAppInvoices() ? 'col' : 'col consult-mode'"
          >
            <header class="dashboard-card-header">
              <div class="dashboard-card-title-box">
                <i class="ico ico-motorcycle"></i>
                <h2 data-e2e="last-order-title" class="card-title">
                  Último pedido
                </h2>
              </div>

              <div style="margin-right: -4rem;">
                <app-borderless-button [description]="'Consultar pedidos'"
                                           [classIconRight]="'ico-double-arrow'"
                                           [routerLink]="'/meus-pedidos'"
                                           [id-html]="'consultOrdersButton'"
                                           [data-e2e]="'check-order-btn'"
                                           [buttonColor]="'secundary'"
                >
                </app-borderless-button>
              </div>
            </header>

            <ng-container>
              <div class="row">
                <div class="col-lg-12">
                  <app-order-timeline></app-order-timeline>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row row-2 container-vehicle-tag-responsive">
          <div class="col-lg-6 app-vehicles">
            <div class="container-repom container-dashboard">
              <header class="dashboard-card-header">
                <div class="dashboard-card-title-box">
                  <i class="ico ico-truck"></i>
                  <h2 class="card-title" data-e2e="vehicle-title">Veículos</h2>
                </div>

                <div style="margin-right: -3rem;">
                  <app-borderless-button [description]="'Consultar veículos'"
                                             [classIconRight]="'ico-double-arrow'"
                                             [routerLink]="'/meus-veiculos'"
                                             [id-html]="'ConsultVehiclesButton'"
                                             [data-e2e]="'check-vehicle-link'"
                                             [buttonColor]="'secundary'"
                  >
                  </app-borderless-button>
                </div>
              </header>

              <main class="row card-content">
                <div class="col-lg-5">
                  <p class="info">
                    <b data-e2e="vehicles-total-data" id="totalVehicles">{{
                      vehicle == null ? 0 : vehicle.total
                    }}</b>
                    <br />
                    <span>veículos cadastrados</span>
                  </p>
                </div>

                <div class="col-lg-2"></div>

                <div class="col-lg-5 tag-information-responsive">
                  <p class="info-1">
                    <span
                      data-e2e="activated-vehicle-total-data"
                      id="totalActivesVehicles"
                      >{{ vehicle == null ? 0 : vehicle.totalActives }}</span
                    ><br />
                    <b>com TAGs ativas</b>
                  </p>

                  <p class="info-2">
                    <span
                      data-e2e="no-tag-total-data"
                      id="totalNotActiveVehicles"
                      >{{ vehicle == null ? 0 : vehicle.totalNotActive }}</span
                    ><br />
                    <!--<span>10</span><br>-->
                    <b>sem Tags</b>
                  </p>
                </div>
              </main>
            </div>
          </div>

          <div class="col-lg-6 card-tag-responsive">
            <app-tags-card [tag]="tag"></app-tags-card>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
