import { Component, OnInit, Input } from '@angular/core';
import { PaymentService } from '@services/payment.service';
import { ContractIntegrationFee } from '@models/contractintegrationfee.model';
import { IntegrationFee } from '@models/contractintegrationfee.model';
import { finalize } from "rxjs/operators";

@Component({
  selector: 'integration-fee-table',
  templateUrl: './integration-fee-table.component.html',
  styleUrls: ['./integration-fee-table.component.scss']
})
export class IntegrationFeeTableComponent implements OnInit {
  @Input() integrationFeeList: Array<IntegrationFee>;
  constructor(public _paymentService: PaymentService) { }

  ngOnInit(): void {
  }

}
