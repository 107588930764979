import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpsRequestInterceptor } from './https-request.interceptor';
import { ToastrInterceptor } from './toastr.interceptor';
import { AccessSessionInterceptor } from './access-session.interceptor';
import { OAuthService } from 'angular-oauth2-oidc';
import { LocalstorageService } from '@services/localstorage.service';
import { CacheService } from '@services/cache.service';
import { NotifyService } from '@services/notify.service';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpsRequestInterceptor,
            multi: true,
            deps: [LocalstorageService,CacheService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ToastrInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AccessSessionInterceptor,
            multi: true,
            deps: [LocalstorageService, NotifyService, OAuthService]
      },
    ],
})


export class Interceptor { }
