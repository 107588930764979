import { NavLink } from '@models/navlink.model';
import {
  MenuBackofficePaths,
  RoutesPaths,
} from './routes-paths';

export const MenuBackoffice: NavLink[] = [
  {
    title: 'Attendance',
    path: MenuBackofficePaths.ATTENDANCE,
    icon: 'happy-support',
    iconPath: 'assets/icons/happy-support-icon.svg',
    children: [
      {
        title: 'Access contract',
        path: RoutesPaths.SELECT_PROFILE,
        children: [],
      },
    ],
  },
  {
    title: 'Logistics',
    path: MenuBackofficePaths.LOGISTIC,
    icon: 'logistics-pins',
    iconPath: 'assets/icons/logistic-pins-icon.svg',
    children: [
      {
        title: 'Logistics stock',
        path: RoutesPaths.REPORTS,
        children: [],
      },
      {
        title: 'Tag orders - Customer',
        path: RoutesPaths.REQUEST,
        children: [],
      },
    ],
  },
  {
    title: 'Financial',
    path: MenuBackofficePaths.FINANCIAL,
    icon: 'money-bag',
    iconPath: 'assets/icons/money-bag-icon.svg',
    children: [
      {
        title: 'Partners Transfer Conciliation',
        path: RoutesPaths.PARTNERS_TRANSFER_CONCILIATION,
        children: []
      },
      {
        title: 'Transaction conciliation',
        path: RoutesPaths.TRANSACTION_CONCILIATION,
        children: [],
      },
      {
        title: 'Prepaid',
        children: [
          {
            title: 'Chargeback',
            path: RoutesPaths.CHARGEBACK_REPORT,
          },
          {
            title: 'Credit card transactions',
            path: RoutesPaths.FINANCIAL_SALES_REPORT,
          },
        ],
      },
      {
        title: 'Holidays calendar',
        path: RoutesPaths.HOLIDAYS_CALENDAR,
        children: [],
      },
      {
        title: 'Expired Toll Voucher Trips',
        path: RoutesPaths.TRIP_EXPIRATED,
        children: [],
      },
    ],
  },
  {
    title: 'Contract Management',
    path: MenuBackofficePaths.CONTRACT_MANAGEMENT,
    icon: 'document-sheet',
    iconPath: 'assets/icons/document-sheet-icon.svg',
    children: [
      {
        title: 'Change postpaid credit limit',
        path: RoutesPaths.CONTRATCTUAL_ALTERATION,
        children: [],
      },
      {
        title: 'Financial postpaid block',
        children: [
          {
            title: 'Financial blocking and unblocking',
            path: RoutesPaths.SELECT_PROFILE_FINANCIAL_BLOCKING_POS_PAID,
          },
          {
            title: 'Baixar relatório de configurações de bloqueio clientes',
            path: '',
            action: true
          },
        ],
      },
    ],
  },
  {
    title: 'Access Management',
    path: MenuBackofficePaths.ACCESS_MANAGEMENT,
    icon: 'person-settings',
    iconPath: 'assets/icons/person-settings-icon.svg',
    children: [
      {
        title: 'Relationship consultant',
        children: [
          {
            title: 'Consultant register',
            path: RoutesPaths.CONSULTANT_REGISTER,
          },
          {
            title: 'Consultant view',
            path: RoutesPaths.CONSULTANT_VIEW,
          },
        ]
      }
    ]
  },
];

// export const MenuCustomer: NavLink[] = [
//   {
//     title: 'Home Page',
//     path: RoutesPaths.DASHBOARD,
//     icon: 'dashboard',
//     iconPath: 'assets/img/dashboard.svg',
//     children: [],
//   },
//   {
//     title: 'Fleet',
//     path: MenuCustomerPaths.FLEET,
//     icon: 'fleet',
//     iconPath: 'assets/img/fleet.svg',
//     children: [
//       {
//         title: 'My vehicles',
//         path: RoutesPaths.MY_VEHICLES,
//         children: [],
//       },
//       {
//         title: 'Enable tags',
//         path: RoutesPaths.ENABLE_TAGS,
//         children: [],
//       },
//       {
//         title: 'Hierarchy',
//         path: RoutesPaths.HIERARCHY,
//         children: [],
//       },
//       {
//         title: 'My requests',
//         path: RoutesPaths.MY_REQUESTS,
//         children: [],
//       },
//     ],
//   },
//   {
//     title: 'Financial',
//     path: MenuCustomerPaths.FINANCIAL,
//     icon: 'financial',
//     iconPath: 'assets/img/financial.svg',
//     children: [
//       {
//         title: 'Recharge',
//         path: RoutesPaths.RECHARGE,
//         children: [],
//       },
//       {
//         title: 'Extract',
//         path: RoutesPaths.EXTRACT,
//         children: [],
//       },
//       {
//         title: 'Tickets',
//         path: RoutesPaths.TICKETS,
//         children: [],
//       },
//       {
//         title: 'Bills',
//         path: RoutesPaths.BILLS,
//         children: [],
//       },
//     ],
//   },
//   {
//     title: 'Fleet Panel',
//     path: RoutesPaths.FLEET_PANEL,
//     icon: 'report',
//     iconPath: 'assets/img/report.svg',
//     children: [],
//   },
//   {
//     title: 'Toll Voucher',
//     path: MenuCustomerPaths.TOLL_VOUCHER,
//     icon: 'toll-valley',
//     iconPath: 'assets/img/toll-valley.svg',
//     children: [
//       {
//         title: 'Vehicle balance',
//         path: RoutesPaths.VEHICLE_VPR_BALANCE,
//         children: [],
//       },
//       {
//         title: 'Credit received',
//         path: RoutesPaths.CREDIT_RECEIVED,
//         children: [],
//       },
//     ],
//   },
// ];
