import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, BehaviorSubject, Observable } from 'rxjs';
import { LogService } from './log.service';
import { LegalPerson } from '@models/old/legalperson.model';
import { Contract, ContractDto, ContractDualTagDto } from '@models/old/contract.model';
import {ComplementaryCustomerData} from "@models/old/complementarycustomerdata.model";

@Injectable({
  providedIn: 'root'
})
export class MyContractService {

  private _contractBillingCuttingCycle: string;
  private base = environment.apiTMCustomer;
  private baseIdentity = environment.apiTMIdentityServer;
  private showErrors = environment.showErrors;
  constructor(protected http: HttpClient, protected logger: LogService) { }


  /**
   * A Behavior Subject that updates the Hierarchy tree data. It emits an event to require a updated tree.
   *
   * @class boolean BehaviorSubject.
   * */
  refreshTree$ = new BehaviorSubject<boolean>(undefined);


  get contractBillingCuttingCycle(): string {
    return this._contractBillingCuttingCycle;
  }

  set contractBillingCuttingCycle(cuttingCycle: string) {
    this._contractBillingCuttingCycle = cuttingCycle;
  }

  getLegalPerson() {
    return this.http.get<LegalPerson>(`${this.base}/Customer/GetLegalPerson`);
  }

  getComplementaryCustomerData(id: Number) {
    return this.http.get<ComplementaryCustomerData>(
      `${this.base}/Customer/GetComplementaryCustomerData?id=${id}`,
    );
  }

  getName() {
    return this.http.get<any[]>(`${this.base}/Customer/GetName`);
  }

  getContractsById() {
    return this.http.get<ContractDto>(
      this.base + `/Contract/GetContractById`
    );
  }


  getContractPrePaidSummaryById() {
    return this.http.get<any>(
      this.base + `/Contract/GetContractPrePaidSummaryById`
    );
  }

  getContractDualTag(contractId: number) {
    return this.http.request<ContractDualTagDto>('GET', this.base + '/Contract/GetDualTagContractByContractId', { body: { contractId } });
  }


  public getContractUsers() {
    try {
      let apiUrl = `${this.base}/Person/GetContractPerson`;
      var result = this.http.get(apiUrl);
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }


  public getGroups() {
    try {
      let apiUrl = `${this.baseIdentity}/CustomerPortal/Group/GetAllGroupsCustomerPortal`;
      var result = this.http.get(apiUrl);
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }


  public getPerson(id) {
    try {
      let apiUrl = `${this.base}/Person/GetPerson?id=` + id;
      var result = this.http.get(apiUrl);
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  public verifyEmail(email: string, documentNumber: string): Observable<boolean> | null {
    try {
      let apiUrl: string = `${this.base}/PersonEmailAddress/PersonEmailExists?email=${email}&documentNumber=${documentNumber}`;
      return this.http.get<boolean>(apiUrl);
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  public validPassword(entity: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let apiUrl: string = `${this.baseIdentity}/Customer/User/CurrentUser/ValidatePassword`;
    const body = JSON.stringify(entity);
    return this.http.post<any>(apiUrl, body, httpOptions)
      .pipe(
        map((res) => {
          this.refreshTree$.next(undefined);
          return res;
        })
      );
  }

  public saveEditUser(entity: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const apiUrl = `${this.base}/Person/UpdatePersonAndUser`;
    const body = JSON.stringify(entity); // -> JSON para Salvar.
    return this.http.post<any>(apiUrl, body, httpOptions)
      .pipe(
        map((res) => {
          this.refreshTree$.next(undefined);
          return res;
        })
      );
  }

  /**
   * Obtem a lista de condições comerciais.
   * @param {Number} customerId
   * @returns {Promise<Contract>}
   */
  public async getCommercialConditions(): Promise<Contract> {
    try {
      let apiUrl = `${this.base}/Customer/GetCommercialConditions`;
      var result = await this.http.get<Contract>(apiUrl).toPromise();
      return result;
    } catch (e) {
      if (this.showErrors) { this.logger.showError(`Erro em 'AccountService' ${e.status}: ${JSON.stringify(e.message)}`); }
      return null;
    }
  }
}
