<section class="rep-modal">
  <header class="modal-header">
    <h4
      data-e2e="modal-title"
      class="modal-title"
      data-e2e="modal-title"
      translate
    >
      {{
        businessUser ? 'Usuário do Contrato' : 'Representantes / Procuradores'
      }}
    </h4>

    <a
      data-e2e="close-modal-top-btn"
      class="btn-close"
      (click)="modalService.dismissAll()"
    >
      <img src="assets/svg/close.svg" />
    </a>
  </header>

  <article class="modal-body">
    <ng-template class="loader" #isLoading>
      <app-loader [isLoading]="!!isLoading"></app-loader>
    </ng-template>

    <section *ngIf="complementaryCustomerData || businessUser; else isLoading">
      <header>
        <h1 data-e2e="person-name">
          {{
            complementaryCustomerData
              ? complementaryCustomerData?.fullName
              : businessUser.name
          }}
        </h1>
        <span
          data-e2e="person-profile"
          *ngIf="businessUser && businessUser.identityUserId !== null"
          >{{
            complementaryCustomerData
              ? complementaryCustomerData?.personResponsibleType
              : businessUser.profile
          }}</span
        >
        <i
          data-e2e="user-tooltip-msg"
          class="ico ico-alert col-1"
          placement="right"
          ngbTooltip="{{ businessUser.descriptionGroup }}"
          *ngIf="businessUser && businessUser.identityUserId !== null"
        ></i>
      </header>

      <article *ngIf="complementaryCustomerData" class="prosecutor-details">
        <div class="row">
          <span class="col" data-e2e="social-security-label">CPF: </span>
          <p class="col" data-e2e="social-security">
            {{ complementaryCustomerData?.documentNumber | mask: 'CPF_CNPJ' }}
          </p>
        </div>
        <div class="row">
          <span class="col" data-e2e="phone-label">Telefone: </span>
          <p class="col" data-e2e="phone">
            {{
              complementaryCustomerData?.countryCode +
                complementaryCustomerData?.areaCode +
                complementaryCustomerData?.telephoneNumber | phone
            }}
          </p>
        </div>
        <div class="row">
          <span class="col" data-e2e="email-label">Email:</span>
          <p class="col" data-e2e="email">
            {{ complementaryCustomerData?.emailAddress }}
          </p>
        </div>
      </article>

      <article *ngIf="businessUser" class="businessUser-details">
        <div class="row">
          <span class="col" data-e2e="social-security-label">CPF: </span>
          <p class="col" data-e2e="social-security">
            {{ businessUser.documentNumber | mask: 'CPF_CNPJ' }}
          </p>
        </div>

        <div class="row">
          <span class="col" data-e2e="name-label">Nome: </span>
          <p class="col" data-e2e="name">
            {{ businessUser.name }}
          </p>
        </div>
        <div class="row">
          <span class="col" data-e2e="phone-label">Telefone: </span>
          <p class="col" data-e2e="phone">
            {{ businessUser.telephone | phone }}
          </p>
        </div>
        <div class="row">
          <span class="col" data-e2e="email-label">Email:</span>
          <p class="col" data-e2e="email">
            {{ businessUser.email }}
          </p>
        </div>
      </article>
    </section>
  </article>

  <div class="modal-footer d-flex justify-content-center">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      Fechar
    </button>
  </div>
</section>
