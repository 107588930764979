import { HttpParams } from "@angular/common/http";
import { Component } from "@angular/core";

import { Observable } from "rxjs";

import { Filters } from "@models/filter.model";
import { ReportService } from "@services/report.service";
import { FORMATED_PREVIEWS_THREE_MONTHS, FORMATED_TODAY } from "@variables/dates";

@Component({
  selector: "app-total-costs",
  templateUrl: "./total-costs.component.html",
  styleUrls: ["./total-costs.component.scss"],
})
export class TotalCostsComponent {
  filter: Filters = {
    startDate: FORMATED_PREVIEWS_THREE_MONTHS,
    endDate: FORMATED_TODAY
  };
  totalCosts$: Observable<any> = this._service.getTotalFleetCosts(
    new HttpParams({ fromObject: this.filter as any})
  );

  constructor(private _service: ReportService) {}
}
