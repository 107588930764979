import { formatDate } from "@angular/common";

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_US = 'MM/dd/yyyy';
export const TODAY = new Date();
export const FORMATED_TODAY = formatDate(TODAY, DATE_FORMAT, 'en-US');
export const PREVIEWS_MONTH = new Date(new Date().setDate(TODAY.getDate() - 30));
export const FORMATED_PREVIEWS_MONTH = formatDate(PREVIEWS_MONTH, DATE_FORMAT, 'en-US');
export const PREVIEWS_THREE_MONTHS = new Date(new Date().setDate(TODAY.getDate() - 90));
export const FORMATED_PREVIEWS_THREE_MONTHS = formatDate(PREVIEWS_THREE_MONTHS, DATE_FORMAT, 'en-US');
