import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs';
import { ConciliationFeedbackModalComponent } from '../conciliation-feedback-modal/conciliation-feedback-modal.component';
import {
  ISourcePartner
} from "@modules/backoffice/transaction-partners-conciliation/dtos/transaction-partners-conciliation.dto";
import {
  TransactionPartnersConciliationService
} from "@modules/backoffice/transaction-partners-conciliation/services/transaction-partners-conciliation.service";


@Component({
  selector: 'app-import-conciliation-document-modal',
  templateUrl: './import-conciliation-document-modal.component.html',
  styleUrls: ['./import-conciliation-document-modal.component.scss']
})
export class ImportConciliationDocumentModalComponent implements OnInit{
  @Output() eventReload: EventEmitter<void> = new EventEmitter();

  SourcePartners: ISourcePartner[]; //lista de parceiros
  isLoading: boolean;
  dateArray: string[] = []; // lista de meses do select
  fromDate: Date = new Date('05-01-2024'); //data base MM/dd/yyyy
  toDate: Date = new Date(); // data atual

  files: any[] = [];
  imported: boolean;
  concluded: boolean;
  messageError: boolean;
  onSaving: boolean = false;

  selectedPartnerId : number;
  selectedReferenceDate: string | Date;

  constructor(private _modalService: NgbModal,
  private _transactionPartnersService: TransactionPartnersConciliationService,
  private _notifyService : NotifyService)
  {
    this.generateArray()
  }
  ngOnInit(): void {
    this._transactionPartnersService.getSourcePartners().subscribe((sourcePartners) => {
      this.SourcePartners = sourcePartners;
    });
    this.concluded = false;
    this.imported = false;
    this.messageError = false;
    this.selectedPartnerId = this.SourcePartners[0].id
    this.selectedReferenceDate = this.dateArray[0];
  }
  cleanMsg() {
    this.messageError = false
  }
  DownloadFileModel() {
    window.location.href =
      'https://gestaodepedagio.edenred.com/Modelo_Repasse_Parceiro_Edenred.xlsx';
  }

  dismissModal() {
      this._modalService.dismissAll();
  }


  onPartnerSelect(event: any){
    this.selectedPartnerId = event.target.value
  }

  onDateSelect(event: any){
    this.selectedReferenceDate = event.target.value
  }

  generateArray() {
    while (this.fromDate <= this.toDate) {
      this.dateArray.unshift(this.fromDate.toISOString()); // Corrigido
      this.fromDate.setMonth(this.fromDate.getMonth() + 1);
    }
  }

 //-------------------- IMPORTAR ARQUIVO
   /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    this.prepareFilesList($event);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.imported = false;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList($event) {
    const file = $event[0];
    this.messageError = false;
    if (this.files.length > 0) {
      this.deleteFile(0);
    }

    if (
      file.type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.imported = false;
      this.messageError = true;
    } else {
      this.files.push(file);
      this.imported = true;
    }
  }

   uploadFile() {
    this.onSaving = true;
    const formData = new FormData();
    formData.append('file', this.files[0]);
    this._transactionPartnersService
      .postFile(formData, this.selectedPartnerId.toString(), this.selectedReferenceDate.toString())
      .pipe(
        finalize(() => {
          this.onSaving = false;
        }),
      )
      .subscribe({
        next: (success: any) => {
         this.showSuccess()
        },
        error:(error : any) => {
          if(error.status == 400) {
           this.showError()
          }
        }
      });
  }
  showSuccess(){
    this.eventReload.emit();
            this._modalService.dismissAll()
            let feedback = this._modalService.open(ConciliationFeedbackModalComponent)
  }
  showError() {
    this._modalService.dismissAll()
    let feedbackError = this._modalService.open(ConciliationFeedbackModalComponent);
    feedbackError.componentInstance.hasErrors = true;
    feedbackError.componentInstance.AlreadyExistsImportedFile = true;
  }
  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals: number = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
