import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TagactivationComponent } from './tagactivation.component';
import { TagactivationRoutingModule } from './routes/tagactivation-routing.module';
import { AddTagModalComponent } from './components/add-tag-modal/add-tag-modal.component';
import { TagLinkModalComponent } from './components/tag-link-modal/tag-link-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {CustomDirectivesModule} from "@directives/custom-directives.module";
import { BorderlessButtonComponent } from "../../../shared/components/button/button-without-border/borderless-button.component";

@NgModule({
    imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    TagactivationRoutingModule,
    CustomDirectivesModule,
    BorderlessButtonComponent
],
  declarations: [
   TagactivationComponent,
   AddTagModalComponent,
   TagLinkModalComponent
  ],
  providers: [DatePipe, provideNgxMask()],
})

export class TagactivationModule {}
