import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TransactionPartnersConciliationQueryArguments } from '../dtos/transaction-partners-conciliation-query-arguments';
import { ISourcePartner, ITollVoucherConciliationFiles, ITransactionPartnersConciliaton } from '../dtos/transaction-partners-conciliation.dto';

@Injectable({
  providedIn: 'root',
})
export class TransactionPartnersConciliationService {
  constructor(
    private http: HttpClient,
    private _datePipe: DatePipe,
  ) {}

  public base = environment.apiTMIntegrationVPR;
  private _sourcePartners: Observable<ISourcePartner[]> | null = null;


  getTransactionPartnersConciliation(
    args: TransactionPartnersConciliationQueryArguments,
  ): Observable<ITollVoucherConciliationFiles> {
    return this.http
      .get<ITollVoucherConciliationFiles>(
        this.base + `/api/VprConciliationFile`,
        {
          params: args.httpParams,
        },
      )
      .pipe(
        map((response) => {
          return this.handleData(response);
        }),
      );
  }

  handleData(response: ITollVoucherConciliationFiles ): ITollVoucherConciliationFiles{
    let newItems: ITransactionPartnersConciliaton[] = [];
          response.tollVoucherConciliationFiles.forEach((item) => {
            let newTransactionPartnerConciliaton = {
              ...item,
              registrationDate: this._datePipe.transform(
                item.registrationDate,
                'dd/MM/yyyy HH:mm:ss','0000','pt-BR'
              ),
              reconciledDate: this._datePipe.transform(item.reconciledDate, 'dd/MM/yyyy HH:mm:ss','0000','pt-BR' ),
              referenceDate: this._datePipe.transform(item.referenceDate, 'MMMM/yyyy','+0300' )
            };
            newItems.push(newTransactionPartnerConciliaton);
          });
           response.tollVoucherConciliationFiles = newItems;
           return response;
  }


  getSourcePartners(): Observable<ISourcePartner[]> {
    if (!this._sourcePartners) {
      this._sourcePartners = this.http
        .get<ISourcePartner[]>(this.base + `/api/SourcePartner`)
        .pipe(
          map((response) => response),
          shareReplay() // Compartilhar o observable entre os componentes
        );
    }

    return this._sourcePartners;
  }

  getTransactionPartnersConciliationCsv(args: TransactionPartnersConciliationQueryArguments) {
    return this.http
      .get(this.base + `/api/VprConciliationFileCsv`, {
        observe: 'response',
        responseType: 'blob',
        params: args.httpParams,
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),

            filename: response.headers.get('filename'),
          };
          return data;
        }),
      );
  }

  getTransactionPartnersConciliationItemCsv(itemId: string) {
    return this.http
      .get(this.base + `/api/VprConciliationFileItemCsv?VprConciliationFileId=`+itemId, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {

          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),
            filename: response.headers.get('filename'),
          };

          return data;
        }),
      );
  }


  postFile(file: any, sourcePartnerTypeId: string, referenceDate: string) {
    return this.http.post(
      this.base+`/api/VprConciliationFile/Post`,
      file,
      {
        headers: {
          'sourcePartnerTypeId': sourcePartnerTypeId,
          'referenceDate': referenceDate
        }
      }
    );
  }
}
