import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import { AuthService } from 'src/app/core/auth/auth.service';
import {UserProfileService} from "@services/user-profile.service";

@Directive({
  selector: '([formControlName], [formControl])[ControlDisabledByPermission]',
})
export class DisabledControlDirective {

  constructor(
    protected authService: AuthService,
    private el: ElementRef,
    private renderer: Renderer2,
    private userProfileService: UserProfileService,) {}

  @Input() controlDisabledByCondition: boolean = false;

  @Input() set controlDisabledByPermission(permissions:Array<number>) {

    if (this.userProfileService.isBackOffice()) {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
      return;
    }

    let havePermission: boolean;
    permissions.forEach((permission) => {
      if(!havePermission){
        havePermission = this.authService.checkRoles(permission.toString())
      }
    });

    let disable = !havePermission ? true : this.controlDisabledByCondition;
    this.renderer.setProperty(this.el.nativeElement, 'disabled', disable);

  }

}

