<!-- Modal userBlockModal -->
<div
  class="modal-content postPaidContent"
  *modalityType="modalityType.POSTPAID"
>
  <div class="step-2">
    <header class="modal-header">
      <div class="box-title">
        <span>Novo pedido</span>
      </div>

      <button
        type="button"
        class="close"
        (click)="modal.dismissAll()"
        id="btn-dismiss"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </header>

    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12"></div>
      </div>
      <div class="row row-modal-collapse">
        <div class="col-lg-1"></div>
        <div class="col-lg-4">
          <img src="../../../assets/img/bg-place.svg" />
        </div>
        <div class="col-lg-7 box-infos">
          <h4>Pedido realizado</h4>
          <div class="row">
            <div class="col-lg-12 font-weight-bold p-0" id="tagOrderId">
              Número:
              {{ response.data.tagOrderId }}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 p-0" id="registrationDate">
              {{ response.data.registrationDate | date : "dd/MM/yyyy - HH:mm" }}
            </div>
            <div class="col-lg-12 p-0" id="registrationUserFullName">
              {{ response.data.registrationUserName }}
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-12 p-0"
              *ngIf="response.data.mensagemCobranca == true"
            >
              O valor do pedido será cobrado em sua próxima fatura.
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="modal-footer modal-footer-gray">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.dismissAll()"
        id="btn-close"
      >
        Fechar
      </button>
    </footer>
  </div>
</div>

<div class="modal-content PrePaidContent" *modalityType="modalityType.PREPAID">
  <header class="modal-header">
    <div class="box-title">
      <span>Pedido solicitado</span>
    </div>

    <button class="close" type="button" (click)="modal.dismissAll()">
      <span aria-hidden="true">×</span>
    </button>

    <div class="row-box-yellow">
      <span class="box-yellow">
        <b>P</b>edido de {{ amount }} tags em processamento
      </span>
    </div>
  </header>

  <div class="modal-body">
    <div class="contract-infos">
      <div class="companyName">
        {{ response.data.companyName }}
      </div>
      <div class="companyDocument">
        {{ response.data.documentNumber | mask : "00.000.000/0000-00" }}
      </div>
      <div class="row row-animation"></div>
      <div class="nickname">
        <div class="box-white-small">
          <p>Contrato:</p>
          <p>
            <span id="contractId">{{ response.data.contractId }}</span
            ><span *ngIf="response.data.contractName" id="contractName">
              - {{ response.data.contractName }}</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="row row-modal-collapse mt-7rem">
      <div class="w-100 d-flex justify-content-center">
        <div class="col-lg-4 left-infos">
          <img class="w-100" src="../../../assets/img/bg-place.svg" />
          <div class="box-gray">
            você será notificado da confirmação do débido de
            <span>{{
              totalAmountPayable
                | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
            }}</span
            >.<br />
            Fique atento na área de notificações.
          </div>
          <div class="notification">
            <img src="/assets/img/red-arrow.svg" />
            <div class="ico-notification">
              <img src="/assets/img/notification_image.svg" />
            </div>
          </div>
        </div>
        <div class="col-sm-auto tag-replace right-infos">
          <h3>Pedido Solicitado</h3>
          <strong
            >Número:
            <span id="tagOrderId">{{
              response.data.tagRequestId
            }}</span></strong
          >
          <div class="row RechargeConfirmedInfos">
            <div class="col">
              <div class="col-12 textTitle">
                Total debitado do cartão de crédito
              </div>
              <div class="col-12 text">
                {{
                  totalAmountPayable
                    | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                }}
              </div>
            </div>
            <div class="col credit-card-details">
              <b data-e2e="card-label">Meio de pagamento</b>
              <app-credit-card></app-credit-card>
            </div>
          </div>
          <div class="row mt-3 user">
            <div class="col-lg-12 text-center">
              <span id="registrationDate">{{
                response.data.registrationDate | date : "dd/MM/yyyy - HH:mm"
              }}</span>
              por
              <span id="registrationUserFullName">{{
                response.data.registrationUserName
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="modal-footer modal-footer-gray">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismissAll()"
      id="btn-close"
    >
      Fechar
    </button>
  </footer>
</div>
