<div class="container-repom tag-disp container-min-height">
  <div class="box-title" [hidden]="hideElement">
    <span data-e2e="page-title">Tags disponíveis</span>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <b data-e2e="page-subtitle" class="subtitle"
        >Escolha uma tag cadastrada para vincular.</b
      >
    </div>
  </div>

<div class="row">
  <div class="col-lg-12">
    <label
      data-e2e="available-tag-title"
      class="box-collapse-label"
      for="collapseTagsFor"
      >Tags disponíveis</label
    >
  </div>

  <div class="col-lg-12">
    <div class="box-collapse">
      <!-- Collapse buttons -->
      <div>
        <button
          id="collapseTagsFor"
          class="btn btn-collapse"
          data-e2e="available-tag-dropdown-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTags"
          aria-expanded="false"
          aria-controls="collapseTags"
        >
          <b *ngIf="newtag == tagSelected?.serial && tagSelected != null"
            >Tag criada e selecionada:
            <span data-e2e="selected-serial" id="selectedTag">{{
              tagSelected.serial
            }}</span>
          </b>

          <b *ngIf="newtag != tagSelected?.serial && tagSelected != null"
            >Tag selecionada:
            <span data-e2e="selected-serial" id="selectedTag">{{
              tagSelected.serial
            }}</span>
          </b>

          <span
            data-e2e="selected-serial"
            id="selectedTag"
            *ngIf="tagSelected == null"
            >Escolher</span
          >
        </button>
      </div>
      <!-- / Collapse buttons -->

      <!-- Collapsible element -->
      <ng-template class="collapse" id="collapseTags" #EMPTY>
        <b data-e2e="empty-search-msg" class="subtitle"
          >Não há tags disponíveis.</b
        >
      </ng-template>

      <div
        class="collapse"
        id="collapseTags"
        *ngIf="listTagAvailable?.length; else EMPTY"
      >
        <div class="mt-3">
          <mat-tab-group animationDuration="0ms" >
            <mat-tab>
              <ng-template data-e2e="all-serial-tab" mat-tab-label>
               <div class="activate-tag-option">
                 Todos
               </div>
              </ng-template>

              <mat-radio-group
                aria-label="Select an option"
                [(ngModel)]="tagSelected"
                *ngIf="listTagAvailable"
                (change)="onchangeTag()"
              >
                <div class="tags-container">
                  <mat-radio-button
                    *ngFor="let item of listTagAvailable; let i = index"
                    [attr.data-e2e]="'serial-option-' + i"
                    [value]="item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTags"
                    aria-expanded="false"
                    aria-controls="collapseTags"
                  >
                    {{ item.serial }}
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </mat-tab>

            <mat-tab>
              <ng-template data-e2e="serial-search-tab" mat-tab-label>
                <div class="activate-tag-option">
                  Pesquisar
                </div>
              </ng-template>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-header-search form-header-search-dropdown">
                    <input
                      data-e2e="serial-search-input"
                      id="searchTag"
                      type="text"
                      value="90207 - 80173"
                      placeholder="número da TAG"
                      class="form-control btn-search-order"
                      [(ngModel)]="tagsearch"
                    />

                    <button
                      data-e2e="serial-search-btn"
                      id="searchTagButton"
                      type="button"
                      class="btn btn-primary"
                    >
                      <i class="ico ico-search-white"></i>
                      buscar
                    </button>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="serach-result">
                    <mat-radio-group
                      aria-label="Select an option"
                      [(ngModel)]="tagSelected"
                      *ngIf="listTagAvailable"
                      (change)="onchangeTag()"
                    >
                      <div
                        class="col-lg-12"
                        *ngFor="
                          let item of listTagAvailable | tagfilter : tagsearch;
                          let i = index
                        "
                      >
                        <mat-radio-button
                          [attr.data-e2e]="'serial-option-' + i"
                          id="serialTag{{ item.serial }}"
                          [value]="item"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTags"
                          aria-expanded="false"
                          aria-controls="collapseTags"
                        >
                          {{ item.serial }}
                        </mat-radio-button>
                      </div>
                    </mat-radio-group>
                    <p></p>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <!-- / Collapsible element -->
    </div>
  </div>

  <span class="hr">
    <p>Ou</p>
    <hr />
  </span>
</div>
<div class="row">
  <div class="col-lg-7">
    <p data-e2e="insert-another-serial-msg" class="font-weight-bold custom-p">
      Inserir uma tag adquirida que não esteja na relação acima
    </p>
  </div>

  <div class="col-lg-5 custom-button">
    <app-borderless-button
      [description]="'Incluir nova TAG'"
      [classIconLeft]="'ico-add'"
      [id-html]="'insertNewTagButto'"
      [data-e2e]="'add-new-serial-btn'"
      [isBtnWithBorder]="true"
      [buttonColor]="'secundary'"
      (emitterEvtClick)="showAddTagModal()"
      [verifyContractBlock]="true"
      [listVerifyContractBlock]="listVerifyContractStatus"
    >
    </app-borderless-button>
  </div>
</div>
</div>
