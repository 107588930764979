<div class="container header-page tags-osa">
  <div class="col">
    <app-page-title [title]="'/Tags received from OSA'"></app-page-title>
  </div>
  <div class="actions-header-page tags-osa">
    <app-search-form-inline
      [placeholder]="'Tag number or lot' | translate"
      (buttonClickEvent)="onBuscarClick($event)"
      (keyUpEvent)="onFullNameKeyup($event)">
    </app-search-form-inline >
  </div>
</div>


<div class="container col-md-11 mt-5">
  <div class="card mb-5 card-loading">
    <div class="card-body pt-0">
      <div class="title d-flex  justify-content-between mt-4 mb-5">
        <div class="d-flex justify-content-around">
          <app-filters [hasTransctionDateFilter]="true" (filtersParams)="handleFiltersParams($event)"
            (resetParams)="resetFilters()">
          </app-filters>
          <form novalidate>
            <app-outlined-select
            [title]="'Quantidade de linhas'"
            [values]="perPages"
            (ValueChange)="onPageSizeChange($event)"
            [afterTextValue]="' linhas'"
            >
            </app-outlined-select>
          </form>
        </div>
      </div>

      <div class="px-4">
        <table class="table tags-osa">
          <thead class="thead">
            <tr>
              <th>
                <button data-e2e="sort-manufacturing-btn" class="btn-order-table" translate>
                  Manufacturing date and time
                </button>
              </th>
              <th>
                <button data-e2e="sort-tag-btn" class="btn-order-table" translate>
                  Tag number
                </button>
              </th>
              <th>
                <button data-e2e="sort-obuid-btn" class="btn-order-table" translate>
                  OBUID
                </button>
              </th>
              <th>
                <button data-e2e="sort-batch-btn" class="btn-order-table" translate>
                  Lot number
                </button>
              </th>
            </tr>
          </thead>

          <div class="container-loading" *ngIf="isLoading">
            <app-loader [isLoading]="isLoading" message="{{ 'Loading...' | translate }}"></app-loader>
          </div>

          <tbody>
            <tr *ngIf="getTagsQuery.total == 0">
              <td data-e2e="empty-search-msg" colspan="12" class="text-center w-100 py-4" translate>
                No result for this search
              </td>
            </tr>
            <tr *ngFor="let tag of getTagsQuery.tags; let i = index">
              <td [attr.data-e2e]="'table-manufacturing-' + i">
                {{ tag?.manufacturingDate | date: 'dd/MM/yyyy - HH:mm' }}
              </td>
              <td [attr.data-e2e]="'table-tag-' + i">{{ tag?.tagNumber }}</td>
              <td [attr.data-e2e]="'table-obuid-' + i">{{ tag?.obuid }}</td>
              <td [attr.data-e2e]="'table-batch-' + i">
                {{ tag?.productionBatch }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-custom" *ngIf="getTagsQuery.total != 0">
        <ngb-pagination (pageChange)="onPaginationChange($event)" [pageSize]="tagsQueryArguments.pageSize" [maxSize]="5"
          [collectionSize]="getTagsQuery.total" [(page)]="tagsQueryArguments.page" [rotate]="true"
          [boundaryLinks]="true" [ellipses]="false" display="dynamic">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
