import { Component, OnInit, EventEmitter, Output, Input, Renderer2, AfterViewChecked } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { VehicleService } from '@services/vehicle.service';
import { VehiclesAvailableQueryResult } from '@models/old/vehicle/vehiclesAvailableQueryResult.model';
import { Logger } from '@services/logger.service';
import { AddVehicleComponent } from '@modules/customer/myvehicles/components/add-vehicle-modal/add-vehicle.component';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';
import { ContractService } from '@services/contract.service';
import { TOOLTIPS } from '@modules/customer/myvehicles/tooltips-vehicles/tooltips.constants';

const log = new Logger('MyvehiclesComponent');

@Component({
  selector: 'app-veiculos-disponiveis',
  templateUrl: './veiculos-disponiveis.component.html',
  styleUrls: ['./veiculos-disponiveis.component.scss']
})
export class VeiculosDisponiveisComponent extends AbstractConfigurationBase implements OnInit {
  @Output() changeVehicle = new EventEmitter;
  @Input() contractvehicleselected: VehiclesAvailableQueryResult;
  public verificationPermissionByContractType: boolean = false;
  public listVehicleAvailable: VehiclesAvailableQueryResult[];
  tooltips = TOOLTIPS;
  public vehiclesearch: string;
  isLoading = false;
  vehicleId: number;
  vehicleCreated: any;
  static refreshList = new EventEmitter<string>();

  constructor(
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private modalService2: NgbModal // mdoal2
  ) {
    super();
  }

  ngOnInit(): void {
    this.getVehicleAvailableByContractId();
    VeiculosDisponiveisComponent.refreshList.subscribe((success) => {
      this.getVehicleAvailableByContractId()
    })
    this.verificationPermissionByContractType = this.contractService.isDualTag();

  }



  selectVehicle() {
    this.vehicleId = this.route.snapshot.params.id;
    if (this.vehicleId) {
      this.contractvehicleselected = this.listVehicleAvailable.find(el => el.id == this.vehicleId);
      this.changeVehicle.emit(this.contractvehicleselected);
    }
    if (this.vehicleCreated) {
      this.contractvehicleselected = this.listVehicleAvailable.find(el => el.id == this.vehicleCreated.id);
      this.changeVehicle.emit(this.contractvehicleselected);
    }
  }

  showAddVehicleModal() {
    const modalRef = this.modalService2.open(AddVehicleComponent);
    modalRef.componentInstance.event.subscribe((receivedEntry) => {
      Object.assign(receivedEntry);
      this.vehicleCreated = receivedEntry;
      this.getVehicleAvailableByContractId();
    })
  }
  onchangeVehicle() {
    this.changeVehicle.emit(this.contractvehicleselected);
  }
  getVehicleAvailableByContractId() {
    this.vehicleService.getVehicleAvailableByContractId().subscribe((result) => {
      this.listVehicleAvailable = result;
      this.selectVehicle();
    }, (errors) => {
      console.log(`Promise rejeitada: ${JSON.stringify(errors)}`);
    });
  }

}
