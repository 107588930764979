<div class="FilterComponent">
  <button
  type="button"
  (click)="toggleFilters(); closeOtherFilters(null)"
  data-e2e="filter-toggle-btn"
  class="btn btn-filter"
  [class.active]="showFilters"
  translate
>
  <img src="assets/img/filter.svg" class="button-icon" translate />
  Filtros
</button>

<div class="filters" *ngIf="showFilters">
  <app-date-filter
    *ngIf="hasTransctionDateFilter"
    [labelTitle]="'Período de transação'"
    [textValue]="textValues.transactionDateText"
    [idFilter]="eFilterId.transactionDate"
    (getFilter)="handleDataFilters('transactionDateFilter', $event)"
  ></app-date-filter>

  <app-date-filter
    *ngIf="hasExpectedClearingDateFilter"
    [labelTitle]="'Período de pagamento'"
    [textValue]="textValues.expectedClearingDateText"
    [idFilter]="eFilterId.expectedClearingDate"
    (getFilter)="handleDataFilters('expectedClearingDateFilter', $event)"
  ></app-date-filter>

  <app-date-filter
    *ngIf="hasCreditDateFilter"
    [labelTitle]="'Data do Crédito'"
    [textValue]="textValues.creditDateText"
    [idFilter]="eFilterId.creditDate"
    (getFilter)="handleDataFilters('creditDateFilter', $event)"
  ></app-date-filter>

  <app-date-filter
    *ngIf="hasExpirationDateFilter"
    [labelTitle]="'Data da Expiração'"
    [textValue]="textValues.expirationDateText"
    [idFilter]="eFilterId.expirationDate"
    (getFilter)="handleDataFilters('expirationDateFilter', $event)"
  ></app-date-filter>

  <app-checkbox-filter
    *ngIf="hasTransctionTypeFilter"
    [labelTitle]="'Tipo de transação'"
    [textValue]="textValues.transactionTypeText"
    [idFilter]="eFilterId.transactionType"
    [typesData]="transactionTypes"
    [transactionTypeIcon]="true"
    (getFilter)="handleDataFilters('transactionTypeFilter', $event)"
  ></app-checkbox-filter>

  <app-checkbox-filter
    *ngIf="hasStatusTypeFilter"
    [labelTitle]="'Status da transação'"
    [idFilter]="eFilterId.statusType"
    [typesData]="statusTypes"
    [statusIcon]="true"
    (getFilter)="handleDataFilters('statusTypeFilter', $event)"
  ></app-checkbox-filter>

  <app-range-amount-filter
    *ngIf="hasRangeAmountFilter"
    [labelTitle]="'Faixa de valores'"
    [textValue]="textValues.rangeAmountText"
    [idFilter]="eFilterId.rangeAmount"
    (getFilter)="handleDataFilters('rangeAmountFilter', $event)"
  ></app-range-amount-filter>

  <app-radio-filter
    *ngIf="hasRadioFilter"
    [labelTitle]="'Passo de conciliação'"
    [idFilter]="eFilterId.radioStep"
    [stepsData]="conciliationSteps"
    (getFilter)="handleDataFilters('radioStepFilter', $event)"
    (selectedStep)="handleStep($event)"
  ></app-radio-filter>

  <app-checkbox-filter
    *ngIf="
      hasRefusalReasonFilter ||
      (hasFlowStepAndRefusalFilter && showRefusalReason)
    "
    [labelTitle]="'Motivos de recusa'"
    [textValue]="textValues.refusalReasonText"
    [idFilter]="eFilterId.refusalReason"
    [typesData]="refusalTypes"
    [statusIcon]="true"
    (getFilter)="handleDataFilters('refusalReasonFilter', $event)"
  ></app-checkbox-filter>

  <div class="content-button">
    <app-borderless-button
    [description]="'Aplicar filtros'"
    [buttonColor]="'primary'"
    isBtnWithBorder="true"
    (emitterEvtClick)="applyFilters()"
    [data-e2e]="'apply-filter-btn'"
    [id-html]="'apply-filter-btn'">
    </app-borderless-button>


    <app-borderless-button
    [description]="'Limpar filtros'"
    [buttonColor]="'secundary'"
    isBtnWithBorder="true"
    (emitterEvtClick)="resetFilters()"
    data-e2e="reset-filter-btn"
    [id-html]="'reset-filter-btn'">
    </app-borderless-button>

  </div>
</div>
</div>
