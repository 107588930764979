import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PostalCode } from '@models/old/postalcode.model';
import { LogService } from './log.service';


@Injectable({
  providedIn: 'root'
})
export class PostalcodeService {
  private base = environment.apiPostalCode;
  private showErrors = environment.showErrors;

  constructor(protected http: HttpClient, protected logger: LogService) { }

  /** 
   * Get Cep by zipcode.
   * @param {Number} zipcode
   * @returns {Promise<PostalCode>}
   */
  public async get(zipcode: string): Promise<PostalCode> {
    try {
      const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept'
          })
      };
      const apiUrl = `${this.base}/PostalCode/${zipcode}`;
      const result = await this.http.get<PostalCode>(apiUrl, httpOptions).toPromise();
      return result;
    } catch (e) {
      if (this.showErrors) {
        this.logger.showError(`Erro em 'PostalCode' ${e.status}: ${JSON.stringify(e.message)}`);
      }
    }
  }

}
