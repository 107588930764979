import { Injectable } from '@angular/core';
import {Menu} from "@modules/commom/header/componentes/customer-header/models/menu.model";


@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menuSidebar: Menu[] = [
    {
      link_name: "Página Inicial",
      link: "/pagina-inicial",
      linkActive: "is-active",
      icon: "assets/img/ico-nav-1-active.svg",
      sub_menu: []
    },
    {
      link_name: "Frota",
      link: null,
      icon: "assets/img/ico-nav-2-active.svg",
      sub_menu: [
        {
          link_name: "Meus Veículos",
          link: "/meus-veiculos",
          accessControl: 101143,
        }, {
          link_name: "Ativar Tags",
          link: "/ativar-tags",
          accessControl: 101157,
        }, {
          link_name: "Hierarquia",
          link: "/hierarquia",
          accessControl: 101162,
        }, {
          link_name: "Pedidos",
          link: "/meus-pedidos",
          accessControl: 101171,
        }
      ]
    },
    {
      link_name: "Financeiro",
      link: null,
      icon: "assets/img/ico-nav-3-active.svg",
      sub_menu: [
        {
          link_name: "Recargas",
          link: "/recargas",
          accessControl: 101174,
          modalityType: 1
        },
        {
          link_name: "Extrato",
          link: "/extrato",
          accessControl: 101180,
        }, {
          link_name: "Passagens",
          link: "/passagens",
          accessControl: 101187,
        }, {
          link_name: "Faturas",
          link: "/faturas",
          accessControl: 101193,
        }
      ]
    },
    {
      link_name: "Painel de Gestão",
      link: "/indicadores-frota",
      icon: "assets/img/ico-nav-4-active.svg",
      sub_menu: []
    },
    {
      link_name: "Vale Pedágio",
      link: null,
      icon: "assets/img/ico-nav-5-active.svg",
      sub_menu: [
        {
          link_name: "Saldo dos Veículos",
          link: "/saldovpr-veiculos",
          accessControl: 101212,
        }, {
          link_name: "Créditos Recebidos",
          link: "/creditos-recebidos",
          accessControl: 101214,
        }
      ]
    },
    {
      link_name: "Conta",
      accessControl: 101134,
      link: "/minha-conta",
      icon: "assets/svg/icon_bt_minhaconta.svg",
      sub_menu: []
    },
    {
      link_name: "Contrato",
      accessControl: 101136,
      link: "/contrato",
      icon: "assets/svg/icon-contract.svg",
      sub_menu: []
    },
    {
      link_name: "Trocar Contrato",
      link: "/selecionar-contrato",
      icon: "assets/svg/turquoise/ico-employe-turquoise.svg",
      sub_menu: []
    },
    {
      link_name: "Sair",
      link: "",
      icon: "assets/svg/Exit.svg",
      sub_menu: []
    }
  ];

  constructor() { }
}
