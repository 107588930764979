import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { CreditAlertService } from "./services/credit-alert.service";
import { CreditAlertDto } from "./dto/credit-alert.dto";
import {
  boxPositionAnimation,
  tutorialBground,
} from "./alert-credit.animation";
import { CreditAlert } from "@models/old/credit-alert";
import { NotifyService } from "@services/notify.service";

enum TutorialMode {
  INTRO = "intro",
  STEP_1 = "step1",
  STEP_2 = "step2",
}

@Component({
  selector: "app-alert-credit-modal",
  templateUrl: "./alert-credit-modal.component.html",
  styleUrls: ["./alert-credit-modal.component.scss"],
  animations: [boxPositionAnimation, tutorialBground],
})
export class AlertCreditModalComponent implements OnInit {
  alerts: CreditAlert[] = [];

  tutorialMode: TutorialMode = null;
  isTutorialFirstAccess: boolean;
  sendEmailNotification: boolean;
  showTutorial: boolean;
  alertForm: FormGroup;

  isSuccessful: boolean = false;
  isLoading: boolean = false;

  constructor(
    private creditAlertService: CreditAlertService,
    private modalService: NgbModal,
    private notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.creditAlertService.getCreditAlerts().subscribe((alerts) => {
      this.isTutorialFirstAccess = alerts.firstAccess
      if (alerts.firstAccess) { this.startTutorial() };
      this.alerts = alerts.limits;

      if (alerts.limits.length > 0) {
        this.setInitialPosition(alerts.limits);
      }
      if (alerts.limits.length === 0) {
        this.alerts.push(
          new CreditAlert(0, 90, false, true, 0),
          new CreditAlert(0, 0, false, false, 1),
          new CreditAlert(0, 0, false, false, 2)
        );
      }
      if (this.alerts.length < 3) {
        for (let index = 3 - alerts.limits.length; index > 0; index--) {
          this.alerts.push(new CreditAlert(0, 0, false, false, index));
        }
      }
      this.isLoading = false;
    });

    this.startTutorial();
  }

  private setInitialPosition(alerts: CreditAlert[]) {
    const checkDuplicated = alerts.filter((box) => box.percentageLimit == 0);

    if (checkDuplicated.length == 1) {
      alerts.push(alerts.shift());
    } else {
      alerts.sort(function compare(a, b) {
        if (checkDuplicated.length == 2) {
          if (a.percentageLimit < b.percentageLimit) return 1;

          if (a.percentageLimit > b.percentageLimit) return -1;

          return 0;
        } else {
          if (a.percentageLimit < b.percentageLimit) return -1;

          if (a.percentageLimit > b.percentageLimit) return 1;

          return 0;
        }
      });
    }

    alerts.forEach((element, index) => {
      element.order = index;

      if (element.percentageLimit > 0) {
        element.enabled = true;
      }
      if (element.percentageLimit === 0) {
        element.enabled = false;
      }
    });

    this.alerts = alerts;
  }

  onChangePositions(alert: CreditAlert, index?: number) {
    if (alert.order == 0) {
      const order1 = Number(
        this.alerts
          .filter((data) => data.order == 1)
          .map((data) => data.percentageLimit)
      );
      const order2 = Number(
        this.alerts
          .filter((data) => data.order == 2)
          .map((data) => data.percentageLimit)
      );

      if (alert.percentageLimit > 0) {
        if (order1 > 0) {
          if (alert.percentageLimit > order1) {
            alert.order = 1;
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 0;
            if (order2 > 0) {
              if (alert.percentageLimit > order2) {
                alert.order = 2;
                if (order1 > 0 && order2 > 0) {
                  if (order1 > order2) {
                    this.alerts
                      .filter((data) => data.percentageLimit == order1)
                      .shift().order = 1;
                    this.alerts
                      .filter((data) => data.percentageLimit == order2)
                      .shift().order = 0;
                    return;
                  } else {
                    this.alerts
                      .filter((data) => data.percentageLimit == order2)
                      .shift().order = 1;
                    return;
                  }
                }
              }
            }
          } else {
            if (order2 > 0) {
              if (order2 < order1) {
                this.alerts
                  .filter((data) => data.percentageLimit == order1)
                  .shift().order = 2;
                if (order2 > alert.percentageLimit) {
                  alert.order = 0;
                  this.alerts
                    .filter((data) => data.percentageLimit == order2)
                    .shift().order = 1;
                  return;
                } else {
                  alert.order = 1;
                  this.alerts
                    .filter((data) => data.percentageLimit == order2)
                    .shift().order = 0;
                  return;
                }
              }
            }
          }
        } else {
          if (order2 > 0) {
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 2;
            this.alerts
              .filter((data) => data.percentageLimit == order2)
              .shift().order = 1;
            if (alert.percentageLimit > order2) {
              alert.order = 1;
              this.alerts
                .filter((data) => data.percentageLimit == order2)
                .shift().order = 0;
            }
          }
        }

        if (order1 > 0 && order2 > 0) {
          if (order1 > order2) {
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 2;
            this.alerts
              .filter((data) => data.percentageLimit == order2)
              .shift().order = 1;
          }
        }
      } else {
        if (order2 > 0) {
          alert.order = 2;
          this.alerts
            .filter((data) => data.percentageLimit == order2)
            .shift().order = 0;
          if (order1 > 0) {
            if (order2 > order1) {
              this.alerts
                .filter((data) => data.percentageLimit == order1)
                .shift().order = 0;
              this.alerts
                .filter((data) => data.percentageLimit == order2)
                .shift().order = 1;
            }
          }
        } else {
          if (order1 > 0) {
            alert.order = 1;
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 0;
          }
        }
      }
    }

    if (alert.order == 1) {
      const order0 = Number(
        this.alerts
          .filter((data) => data.order == 0)
          .map((data) => data.percentageLimit)
      );
      const order2 = Number(
        this.alerts
          .filter((data) => data.order == 2)
          .map((data) => data.percentageLimit)
      );
      if (alert.percentageLimit > 0) {
        if (order0 > 0) {
          if (alert.percentageLimit < order0) {
            alert.order = 0;
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 1;
            if (order2 > 0) {
              if (order0 > order2) {
                this.alerts
                  .filter((data) => data.percentageLimit == order0)
                  .shift().order = 2;
                if (alert.percentageLimit > order2) {
                  alert.order = 1;
                  this.alerts
                    .filter((data) => data.percentageLimit == order2)
                    .shift().order = 0;
                  return;
                } else {
                  alert.order = 0;
                  this.alerts
                    .filter((data) => data.percentageLimit == order2)
                    .shift().order = 1;
                  return;
                }
              }
            }
          } else {
            if (order2 > 0) {
              if (alert.percentageLimit > order2) {
                alert.order = 2;
                this.alerts
                  .filter((data) => data.percentageLimit == order2)
                  .shift().order = 1;
                if (order0 > order2) {
                  this.alerts
                    .filter((data) => data.percentageLimit == order0)
                    .shift().order = 1;
                  this.alerts
                    .filter((data) => data.percentageLimit == order2)
                    .shift().order = 0;
                  return;
                } else {
                  this.alerts
                    .filter((data) => data.percentageLimit == order0)
                    .shift().order = 0;
                  this.alerts
                    .filter((data) => data.percentageLimit == order2)
                    .shift().order = 1;
                  return;
                }
              }
            }
          }
        } else {
          if (order2 > 0) {
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 2;
            this.alerts
              .filter((data) => data.percentageLimit == order2)
              .shift().order = 0;
            if (order2 > alert.percentageLimit) {
              alert.order = 0;
              this.alerts
                .filter((data) => data.percentageLimit == order2)
                .shift().order = 1;
            }
          } else {
            alert.order = 0;
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 1;
          }
        }

        if (order0 > 0 && order2 > 0) {
          if (order0 > order2) {
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 2;
            this.alerts
              .filter((data) => data.percentageLimit == order2)
              .shift().order = 0;
          }
        }
      } else {
        //alert.enabled = false
        if (order2 > 0) {
          alert.order = 2;
          this.alerts
            .filter((data) => data.percentageLimit == order2)
            .shift().order = 1;
          if (order0 > 0) {
            if (order0 > order2) {
              this.alerts
                .filter((data) => data.percentageLimit == order0)
                .shift().order = 1;
              this.alerts
                .filter((data) => data.percentageLimit == order2)
                .shift().order = 0;
            }
          } else {
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 1;
            this.alerts
              .filter((data) => data.percentageLimit == order2)
              .shift().order = 0;
          }
        }
      }
    }

    if (alert.order == 2) {
      const order0 = Number(
        this.alerts
          .filter((data) => data.order == 0)
          .map((data) => data.percentageLimit)
      );
      const order1 = Number(
        this.alerts
          .filter((data) => data.order == 1)
          .map((data) => data.percentageLimit)
      );
      if (alert.percentageLimit > 0) {
        if (order1 > 0) {
          if (alert.percentageLimit < order1) {
            alert.order = 1;
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 2;
            if (order0 > 0) {
              if (alert.percentageLimit < order0) {
                alert.order = 0;
                this.alerts
                  .filter((data) => data.percentageLimit == order0)
                  .shift().order = 1;
                if (order0 > order1) {
                  this.alerts
                    .filter((data) => data.percentageLimit == order0)
                    .shift().order = 2;
                  this.alerts
                    .filter((data) => data.percentageLimit == order1)
                    .shift().order = 1;
                  return;
                } else {
                  this.alerts
                    .filter((data) => data.percentageLimit == order0)
                    .shift().order = 1;
                  this.alerts
                    .filter((data) => data.percentageLimit == order1)
                    .shift().order = 2;
                  return;
                }
              }
            } else {
              this.alerts
                .filter((data) => data.percentageLimit == order0)
                .shift().order = 1;
              alert.order = 0;

              this.alerts
                .filter((data) => data.percentageLimit == order0)
                .shift().order = 2;
              this.alerts
                .filter((data) => data.percentageLimit == order1)
                .shift().order = 1;
            }
          } else {
            if (order0 > 0) {
              if (alert.percentageLimit < order0) {
                alert.order = 1;
                this.alerts
                  .filter((data) => data.percentageLimit == order0)
                  .shift().order = 2;
                this.alerts
                  .filter((data) => data.percentageLimit == order1)
                  .shift().order = 0;
                return;
              }
            } else {
              this.alerts
                .filter((data) => data.percentageLimit == order0)
                .shift().order = 2;
              alert.order = 0;

              this.alerts
                .filter((data) => data.percentageLimit == order1)
                .shift().order = 0;
              alert.order = 1;
            }
          }
        } else {
          if (order0 > 0) {
            alert.order = 1;
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 2;
            if (alert.percentageLimit < order0) {
              alert.order = 0;
              this.alerts
                .filter((data) => data.percentageLimit == order0)
                .shift().order = 1;
            }
          } else {
            alert.order = 0;
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 2;
            return;
          }
        }

        if (order0 > 0 && order1 > 0) {
          if (order0 > order1) {
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 1;
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 0;
          }
        }
      } else {
        if (order1 > 0 && order0 == 0) {
          this.alerts
            .filter((data) => data.percentageLimit == order0)
            .shift().order = 1;
          this.alerts
            .filter((data) => data.percentageLimit == order1)
            .shift().order = 0;
        } else if (order0 > 0 && order1 > 0) {
          if (order0 > order1) {
            this.alerts
              .filter((data) => data.percentageLimit == order0)
              .shift().order = 1;
            this.alerts
              .filter((data) => data.percentageLimit == order1)
              .shift().order = 0;
          }
        }
      }
    }
  }

  startTutorial(event?: Event) {
    if (event) {
      if (!this.isTutorialFirstAccess) {
        this.onChangeTutorialSteps("STEP_1");
      } else {
        this.onChangeTutorialSteps("INTRO");
      }
    } else {
      if (this.isTutorialFirstAccess) {
        this.creditAlertService.postAlertTutorialFirstAccess().subscribe();
        this.onChangeTutorialSteps("INTRO");
      }
      if (!this.isTutorialFirstAccess && this.showTutorial) {
        this.onChangeTutorialSteps("STEP_1");
      }
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  onEnableAlert(index: number) {
    const alert = this.alerts[index];

    if (alert.enabled === true) {
      this.alerts[index].percentageLimit = 0;
    }

    alert.enabled = !alert.enabled;
    this.onChangePositions(alert, index);
  }

  onSubmit() {
    let hasDuplicate;
    let Array = [];

    this.setInitialPosition(this.alerts);

    this.alerts.forEach(function (item) {
      if (item.percentageLimit !== 0) {
        Array.push(item.percentageLimit);
      }
    });

    if (Array.length !== new Set(Array).size) {
      hasDuplicate = true;
    } else {
      hasDuplicate = false;
    }

    if (!hasDuplicate) {
      this.isLoading = true;
      let alerts = [...this.alerts];
      let creditAlertDto = new CreditAlertDto(alerts);

      creditAlertDto.formatCreditAlerts(this.sendEmailNotification);

      this.creditAlertService.putCreditAlerts(creditAlertDto).subscribe(
        (success) => {
          this.isLoading = false;
          this.isSuccessful = true;
        },
        (error) => {
          console.log("Something went wrong with the request");
          this.isSuccessful = false;
          this.isLoading = false;
        }
      );
    } else {
      this.notifyService.showWarning(
        "Atenção",
        "Não é permitido cadastrar a mesma porcentagem de alerta mais de uma vez."
      );
    }
  }

  onChangeTutorialSteps(step: string) {
    this.tutorialMode = TutorialMode[step];
  }

  onDismissTutorial() {
    this.creditAlertService.postDisableCreditAlertTutorial().subscribe();
  }

  checkInputLimit(alert: CreditAlert) {
    const maxPercentage = 100;

    if (alert.percentageLimit > maxPercentage) alert.percentageLimit = 99;
    if (alert.percentageLimit < 0) alert.percentageLimit = 0;

    alert.percentageLimit = Number(alert.percentageLimit.toFixed(2));

    this.onChangePositions(alert);
  }
}
