<div class="myorderComponent">
  <div class="Dashboard container-fluid page-myaccount">
    <div class="row">
      <div class="col-12 d-flex title-header">

        <div class="col">
          <app-page-title [title]="'/ Meus Pedidos'"></app-page-title>
        </div>

        <div class="col-3 d-flex justify-content-end deliveryAddresses" style="padding-right: 2rem;">
          <app-borderless-button [description]="'Endereços para entrega'" [verifyContractBlock]="true"
            [classIconLeft]="'ico ico-address'" [id-html]="'AddressButton'" [data-e2e]="'ico ico-address'"
            [buttonColor]="'tertiary'" [isBtnWithBorder]="true" (emitterEvtClick)="showDeliveryAddressesModal()">
          </app-borderless-button>

        </div>

        <div class="col-2 d-flex justify-content-end newOrder" style="padding-right: 2rem;" *controlAccessCustomer>
          <app-borderless-button [description]="'novo pedido'" [verifyContractBlock]="true"
            [classIconLeft]="'ico-upload'" [id-html]="'newOrderButton'" [data-e2e]="'ico ico-shopping-car'"
            [routerLink]="'/novo-pedido'" [buttonColor]="'primary'">
          </app-borderless-button>

        </div>

      </div>
      <div class="col-12">
        <app-first-access-ballon [page]="3"></app-first-access-ballon>
      </div>
      <div class="container">
        <div class="row d-flex justify-content-end">
          <div class="col-lg-12 col-header-filte">
            <div class="row d-flex justify-content-end align-items-baseline">
              <div class="col-lg-2">
                <p><i data-e2e="filter-label" class="ico title ico-filter-transparent">Filtros</i></p>
              </div>

              <div class="col-lg-3 col-position simulate-select ">
                <div class="form-group form-icon">
                  <label data-e2e="period-filter-label" for="datePickerInput">Período</label>
                  <input id="datePickerInput" data-e2e="period-filter-input" type="text" placeholder="Selecione"
                    class="simulate-form-control ico-date-picker" #dp="bsDaterangepicker" bsDaterangepicker
                    [bsConfig]="bsConfig" placement="right" [maxDate]="maxDate" (bsValueChange)="onFilterDate($event)"
                    [bsValue]="dayCustom" [minDate]="minDate" [outsideClick]="false" (click)="toggleDateCard()">

                  <div data-e2e="card-toggle-btn" class="simulate-button" [class.active]="dateOpen"
                    (click)="toggleDateCard();dp.toggle();">
                    <img src="assets/img/arrow-select.svg" />
                  </div>

                  <div class="card date-picker-card" [ngClass]="{'show-date-picker-card' : dateOpen}">
                    <div class="clean-filter align-items-end d-flex justify-content-center">
                      <button data-e2e="clear-filter-btn" id="resetFiltersButton"
                        class="d-flex justify-content-center btn btn-primary" (click)="resetFilters();dp.hide()">
                        <img class="button-icon" src="assets/img/ico-delete.svg" />
                        Limpar filtro
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-position ">
                <app-search-form-inline
                id="formNumberOrderInput"
                data-e2e="order-filter-input"
                customWidth="180px"
                [placeholder]="'preencha'"
                (keyUpEvent)="changeFilter($event)">
                </app-search-form-inline>
              </div>

              <div class="col-lg-4 col-position ml-1 ">
                <app-outlined-select *modalityType="1"
                [classIconLeft]="'ico-status-select'"
                [title]="'Status'"
                [values]="modalityOptions1"
                (valueChange)="changeStatus($event)"
                [customWidthSize]="'320px'"
                [hasLabel]="true">
                </app-outlined-select>


                <app-outlined-select *modalityType="2"
                [classIconLeft]="'ico-status-select'"
                [title]="'Status'"
                [values]="modalityOptions2"
                (valueChange)="changeStatus($event)"
                [customWidthSize]="'300px'"
                [hasLabel]="true">
                </app-outlined-select>

              </div>
            </div>
          </div>

          <div class="col-lg-12 col-box-repom ">
            <div class="row " id="rodoviario ">
              <div class="container-loading " *ngIf="isActiveOrdersLoading ">
                <app-loader [isLoading]="isActiveOrdersLoading " message="{{ 'Carregando...'}} "></app-loader>
              </div>

              <div class="col-lg-4 " *ngFor="let item of allActiveOrdersFiltered; let i = index">
                <div class="box-repom ">
                  <div class="row row-top ">
                    <div class="col-lg-6 ">
                      <div class="blue ">
                        <p [attr.data-e2e]="'order-filter-title-' + i" class="fw-600">PEDIDO</p>
                        <b [attr.data-e2e]="'order-filter-id-' + i" id="orderId{{i}}">{{ item.id }}</b>
                      </div>
                      <div *controlAccessCustomer>
                        <div *accessControl="101173">
                          <button [attr.data-e2e]="'order-filter-confirm-receipt-btn' + i" class="orange"
                            (click)="confirmReceiptTagRequest(item) " *ngIf="item.tagRequestStatusTypeId==5 ">
                            confirmar recebimento
                          </button>
                        </div>
                      </div>
                      <div *controlAccessBackOffice>
                        <button [attr.data-e2e]="'order-filter-confirm-receipt-btn' + i" class="orange"
                          *ngIf="item.tagRequestStatusTypeId==5">
                          cliente deve confirmar recebimento das tags
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-6 ">
                      <p [attr.data-e2e]="'order-filter-registration-' + i" class="date" id="registrationDate{{i}}">
                        {{ item.registrationDate | date: 'dd/MM/yyyy' }}
                      </p>

                      <p class="tags">
                        <b [attr.data-e2e]="'order-filter-amount-' + i" id="amount{{i}}">{{ item.amount }}</b> tags
                      </p>

                      <p class="value">
                        <b [attr.data-e2e]="'order-filter-total-' + i" id="totalAmountPayable{{i}}">{{
                          item.totalAmountPayable | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}</b>
                      </p>
                    </div>
                  </div>

                  <div class="row row-bottom ">
                    <div class="col-lg-5 ">
                      <p>
                        <!-- <img src="../../../assets/svg/done.svg " />{{item.tagRequestStatusType}} -->
                        <!-- <img *ngIf="order?.tagRequestStatusTypeId==3 " src="assets/img/separate-order.svg " />
                          <img *ngIf="order?.tagRequestStatusTypeId==4 " src="assets/img/shipping-company.svg " />
                          <img *ngIf="order?.tagRequestStatusTypeId==5 " src="assets/img/track.svg " />-->
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-transport "
                          *ngIf="item.tagRequestStatusTypeId==5" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-carrying "
                          *ngIf="item.tagRequestStatusTypeId==4" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-separate-order "
                          *ngIf="item.tagRequestStatusTypeId==3" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-done "
                          *ngIf="item.tagRequestStatusTypeId==1" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-8 "
                          *ngIf="item.tagRequestStatusTypeId==8" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-9 "
                          *ngIf="item.tagRequestStatusTypeId==9" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-11 "
                          *ngIf="item.tagRequestStatusTypeId==11" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                        <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-12 "
                          *ngIf="item.tagRequestStatusTypeId==12" id="tagRequestStatusType{{i}}">{{
                          item.tagRequestStatusType }}</i>
                      </p>
                    </div>

                    <div class="col-lg-7 d-flex justify-content-end">
                      <a class="a-button-link" [attr.data-e2e]="'order-filter-view-link-' + i" [routerLink]="['/detalhe-pedido', item.id]">
                        <!-- <a [routerLink]="[ '/detalhe-pedido', item.id] "> -->
                        <!-- <button *accessControl="101172" id="viewOrderButton{{i}}" class="btn btn-repom btn-repom-user "
                          type="button ">
                          <i class="ico ico-search "></i>
                          ver pedido <span class="btn-animation "></span>
                        </button> -->
                        <app-borderless-button *accessControl="101172" [description]="'ver pedido'" [buttonColor]="'primary'" [isBtnWithBorder]="true"
                          [classIconLeft]="'ico-search-zoom'" [id-html]="'viewOrderButton'" [data-e2e]="'btn-orders'"
                          [buttonColor]="'secondary'">
                        </app-borderless-button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div data-e2e="empty-order-result-msg" class="col-lg-12 p-0 row d-flex justify-content-center"
        *ngIf="!isActiveOrdersLoading && allActiveOrdersFiltered?.length== 0 ">
        Nenhum pedido em andamento encontrado.
      </div>

      <div class="container mt-5 ">
        <div class="row card ">
          <div class="container-loading " *ngIf="isCanceledReceivedOrdersLoading ">
            <app-loader [isLoading]="isCanceledReceivedOrdersLoading " message="{{ 'Carregando...'}} "></app-loader>
          </div>

          <div class="col-lg-12 py-15px">
            <table class="table">
              <thead class="thead ">
                <tr>
                  <th scope="col ">
                    <p data-e2e="canceled-order-date-label">data</p>
                  </th>
                  <th scope="col ">
                    <p data-e2e="canceled-order-id-label">número de pedido</p>
                  </th>
                  <th scope="col ">
                    <p data-e2e="canceled-order-tag-amount-label">QUANTIDADE DE TAGS</p>
                  </th>
                  <th scope="col ">
                    <p data-e2e="canceled-order-status-label">STATUS</p>
                  </th>
                  <th scope="col ">
                    <p data-e2e="canceled-order-amount-label">VALOR</p>
                  </th>
                  <th scope="col " class="no-border ">&nbsp;</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of allCanceledReceivedOrders; let count = index ">
                  <td [attr.data-e2e]="'canceled-order-date-' + count" id="registrationDate{{count}}">{{
                    item.registrationDate | date: 'dd/MM/yyyy' }}</td>
                  <td [attr.data-e2e]="'canceled-order-id-' + count" id="id{{count}}">{{ item.id }}</td>
                  <td><span [attr.data-e2e]="'canceled-order-tag-amount-' + count" id="amount{{count}}">{{ item.amount
                      }}</span> TAGS</td>
                  <td class="py-2 ">
                    <i *ngIf="item.tagRequestStatusTypeId==7" class="ico ico-order-checked "></i>
                    <i *ngIf="item.tagRequestStatusTypeId==2" class="ico ico-order-canceled "></i>
                    <span [attr.data-e2e]="'canceled-order-status-' + count" id="tagRequestStatusType{{count}}">{{
                      item.tagRequestStatusType }}</span>
                  </td>
                  <td [attr.data-e2e]="'canceled-order-amount-' + count" id="totalAmountPayable{{count}}">{{
                    item.totalAmountPayable| currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}</td>
                  <td>
                    <a [attr.data-e2e]="'canceled-order-details-link-' + count" href="/detalhe-pedido/{{item.id}} ">
                      <app-borderless-button
                        *accessControl="101172"
                        [description]="'ver pedido'"
                        [buttonColor]="'primary'"
                        [isBtnWithBorder]="true"
                        [classIconLeft]="'ico-search-zoom'"
                        [id-html]="'allCanceledReceivedOrdersViewOrderButton'"
                        [data-e2e]="'btn-orders'"
                        [buttonColor]="'secondary'"/>

                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="!isCanceledReceivedOrdersLoading && allCanceledReceivedOrders?.length== 0 ">
              <div data-e2e="empty-order-result-msg" colspan="12 " class="text-center w-100 pt-2" translate>Nenhum
                pedido cancelado ou recebido encontrado.</div>
            </div>
          </div>

        </div>
      </div>
      <!-- /container  -->
    </div>
  </div>
</div>
