<main class="transactionConciliationComponent">
  <div class="container header-page">
    <app-page-title [title]="'/ Conciliação de Transação'"></app-page-title>

  </div>

  <section class="table-card">
    <div class="table-header">
      <div class="table-header-left">
        <app-filters
          [hasTransctionDateFilter]="true"
          [hasRadioFilter]="true"
          [hasFlowStepAndRefusalFilter]="true"
          [conciliationSteps]="conciliationStepsFilters"
          [refusalTypes]="refusalTypesFilters"
          (filtersParams)="handleFiltersParams($event)"
          (resetParams)="resetFilters()"
        >
        </app-filters>

        <app-outlined-select
          [title]="'Exibição'"
          name="perPages"
          data-e2e="perPages"
          [values]="perPages"
          [afterTextValue]=" ' linhas'"
          [disableRipple]="true"
          [id]="'select-input'"
          (valueChange)="onPageSizeChange($event)"
          >
        </app-outlined-select>
      </div>

      <div class="download-button">
        <app-borderless-button [isDisable]="noFiles" [description]="'Download CSV'" [verifyContractBlock]="true"
        [classIconLeft]="'ico-download'" [id-html]="'export-btn'" [data-e2e]="'export-btn'"
        (emitterEvtClick)="Download()">
      </app-borderless-button>

      </div>
    </div>
    <div class="table-main">
      <table class="table">
        <thead class="thead">
          <tr>
            <th
              *ngFor="let column of tableHeader"
              [ngClass]="bindOrderByClass(column)"
            >
              <div
                *ngIf="column !== ''"
                class="btn-order-table"
                [attr.data-e2e]="'sort-' + column + '-date-btn'"
                translate
              >
                {{ column }}
              </div>

              <div class="round-order" (click)="onOrderByChange(column)">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="!isLoading && DtoTransactions?.totalItems == 0">
          <p class="empty-search-msg" translate>No Transactions Found</p>
        </tbody>

        <tbody *ngIf="!isLoading && DtoTransactions.totalItems !== 0">
          <tr *ngFor="let transaction of DtoTransactions.data; let i = index">
            <td [attr.data-e2e]="'table-sales-' + i">
              {{ transaction?.registrationDate }}
            </td>

            <td [attr.data-e2e]="'table-payment-' + i">
              {{ transaction?.guidTransactionId }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.stepTransaction }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.errorDescriptionPT }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ message | translate }}"
        ></app-loader>
      </div>
    </div>

    <div class="pagination-custom"  *ngIf="
    DtoTransactions?.totalItems > queryArguments.pageSize && !isLoading
    ">
      <ngb-pagination
        (pageChange)="onPaginationChange($event)"
        [pageSize]="queryArguments.pageSize"
        [maxSize]="5"
        [collectionSize]="DtoTransactions?.totalItems"
        [(page)]="queryArguments.page"
        [rotate]="true"
        [boundaryLinks]="true"
        [ellipses]="false"
        display="dynamic"
      >
      </ngb-pagination>
    </div>
  </section>
</main>
