<ngb-pagination
  id="pagination"
  data-e2e="pagination"
  [collectionSize]="totalItems"
  [pageSize]="itemsPerPage"
  [maxSize]="maxPages"
  [page]="currentPage"
  [rotate]="true"
  [boundaryLinks]="true"
  [ellipses]="false"
  (pageChange)="onPageChange($event)"
>
</ngb-pagination>
