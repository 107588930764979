import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VindiService {

  private base = environment.apiVindi;

  constructor(protected http: HttpClient) { }

  createVindiPaymentProfile(form:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    const apiUrl = `${this.base}/v1/public/payment_profiles`;
    const body = JSON.stringify(form); // -> JSON para Salvar.
    const result = this.http.post<any>(apiUrl, body, httpOptions);
    return result;
}

}
