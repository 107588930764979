import { TableHeadersUserConsultantConst } from './../constants/const-user-consultant-delete';
import { HttpParams } from '@angular/common/http';

export class UserConsultantQueryArgs {
  page: number;
  pageSize: number;
  sortField: string;
  desc: boolean;
  search: string;

  constructor() {
    this.page = 1;
    this.pageSize = 5;
    this.sortField = TableHeadersUserConsultantConst.REGISTRATION_DATE;
    this.desc = true;
    this.search = '';
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.sortField);

    if (this.search != null) {
      httpAppend = httpAppend.append('search', this.search);
    }

    return httpAppend;
  }

}
