<div class="modal-content">
    <div class="modal-header">
        <div class="box-title"><span>Erro ao anexar arquivo</span></div>
        <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body show">
        <div class="title" *ngIf="fileError == 1">tamanho de arquivo excedido</div>
        <div class="title" *ngIf="fileError == 2">Esse formato de arquivo não é aceito</div>
        <div class="info" *ngIf="fileError == 1">Por favor, realize o upload de arquivos com <span class="red">até 10
                MB</span>.</div>
        <div class="info" *ngIf="fileError == 2">Por favor, realize o upload de arquivos somente nos formatos <span
                class="red">.csv, .xls ou .xlsx</span>.</div>
    </div>
    <div class="modal-footer modal-footer-gray">
        <button type="button" class="btn btn-secondary" id="btn-close" (click)="dismissModal()">Fechar</button>
    </div>
</div>