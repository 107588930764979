<section class="showModalDocumentsComponent">
  <header class="modal-header">
    <div class="box-title">
      <span>Documentos da Viagem</span>
    </div>
    <button type="button" class="close" (click)="modalRef.close()">
      <span aria-hidden="true">×</span>
    </button>
  </header>

  <main class="modal-body show">
    <div class="tableDocument">
      <h3>VIAGEM: {{tripCode}}</h3>
    <div class="textDocument" *ngFor="let item of tripDocuments; let i = index">
      <span>Documento {{i + 1}} </span>
      <span>{{item.number}}-{{item.serial}}</span>
    </div>
    </div>
  </main>

  <footer class="modal-footer">
    <button
      type="button"
      id="CancelButton"
      class="btn btn-tertiary mr-4"
      (click)="modalRef.close()"
    >
      Fechar
    </button>
  </footer>

</section>
