<div class="PixPaymentModalComponent">
    <div class="modal-content">
        <header class="modal-header">
            <div class="box-title" ><span data-e2e="modal-title">Realize o pagamento</span></div>
            <button class="close" type="button" (click)="modal.dismissAll()">
            <span aria-hidden="true">×</span>
            </button>
            <div class="row-box-yellow">
            <span class="box-yellow">
                <b>A</b>tenção! O QR Code expira em {{ expirationMinutes }} minutos após a geração. <br>Em caso de não pagamento, a transação será automaticamente cancelada. Não perca tempo!
            </span>
            </div>
        </header>

        <div class="modal-body show modal-loose-recharge" *ngIf="isLoading">
            <div class="container-loading" >
            <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
            </div>
        </div>

        <div class="modal-body show pix-payment-container" *ngIf="!isLoading">
            <div class="box-gray-left">
                <div class="title">Aguardando pagamento</div>
                <div class="timer">
                  <img src="../../../assets/img/icon-timer.svg"/>
                   <div class="time">Pague em até {{ timeToExpiry | date:'mm:ss' }}</div>
                </div>
            </div>
            <div class="payment-container">
              <div class="box-title"><span data-e2e="payment-container-title">Pagamento</span></div>
              <span class="box-message">Acesse seu Internet Banking ou app de pagamentos e faça a leitura do QR Code ou copie o código abaixo para efetuar o pagamento de forma rápida e segura. Após o pagamento não será possível solicitar estorno.</span>
              <div class="qr-code-img">
                <qrcode *ngIf="paymentPix" [qrdata]="paymentPix?.codePix" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
              <div class="qr-code">
                 <div class="title">Código</div>
                 <div class="reduced-code">{{  paymentPix?.codePix.length <= 35 ? paymentPix?.codePix : (paymentPix?.codePix | slice:0:35)+'...' }}</div>
                 <div class="copy-btn">
                    <button
                        data-e2e="copy-code-btn"
                        class="btn btn-repom btn-repom-user m-0 d-flex"
                        type="button"
                        [copy-clipboard]="paymentPix?.codePix"
                        (copied)="showMessageAfterCopy()"
                      >
                    <img src="../../../assets/img/content-copy.svg" alt="" />
                    Copiar código para pagamento
                    <span class="btn-animation"></span>
                  </button>
                  <div class="copied" *ngIf="copied">Código copiado!</div>
               </div>
               <div class="payment-values">
                <div data-e2e="value-label" class="label valueLabel">Valor {{ paymentOriginId == paymentOrigin.LooseRecharge ? 'da recarga avulsa' : (paymentOriginId == paymentOrigin.TagOrder ? 'do pedido' : '') }}</div>
                <div data-e2e="value" class="value">{{ paymentPix?.amount | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                <div data-e2e="fee-value-label" class="label feeValueLabel" *ngIf="paymentOriginId == paymentOrigin.LooseRecharge">Taxa de recarga <span *ngIf="paymentPix?.feePercent !== 0">({{ paymentPix?.feePercent | number : "1.2-2" : "pt-BR" }}%)</span></div>
                <div data-e2e="fee-value" class="value feeValue" *ngIf="paymentOriginId == paymentOrigin.LooseRecharge">
                    <span *ngIf="paymentPix?.feePercent !== 0">{{ paymentPix?.feeAmount | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</span>
                    <span *ngIf="paymentPix?.feePercent == 0">Isento</span>
                </div>
                <div data-e2e="discount-value-label" class="label discountValueLabel" *ngIf="paymentOriginId !== paymentOrigin.LooseRecharge">Descontos</div>
                <div data-e2e="discount-value" class="value discountValue" *ngIf="paymentOriginId !== paymentOrigin.LooseRecharge">
                    <span *ngIf="paymentPix?.totalDiscount !== 0">{{ paymentPix?.totalDiscount | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</span>
                    <span *ngIf="paymentPix?.totalDiscount == 0">-</span>
                </div>
            </div>
            <div class="payment-total">
                <div data-e2e="total-label" class="label totalLabel">Você irá pagar</div>
                <div data-e2e="total" class="value total">{{ paymentPix?.totalAmount | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</div>
            </div>
              </div>
              </div>
              <div class="payment-destiny-container">
                <div class="box-title"><span data-e2e="payment-destiny-container-title">Destino do pagamento</span></div>
                 <div class="company">
                     <div class="company-name company-info">{{ paymentPix?.organization }}</div>
                     <div class="company-document company-info"><span>CNPJ: </span><span>{{ paymentPix?.documentNumber | mask: "00.000.000/0000-00" }}</span></div>
                     <div class="company-bank company-info"><span>Banco: </span><span>{{ paymentPix?.bankName }}</span></div>
                 </div>
              </div>
          </div>
            <footer class="modal-footer modal-footer-gray justify-content-center">
                <button
                class="btn btn-secondary position-relative"
                data-e2e="btn-close"
                type="button"
                (click)="modal.dismissAll()"
                >
                Fechar
                </button>
            </footer>


    </div>
</div>
