import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { TransferVprComponent } from './modals/transfer-vpr/transfer-vpr.component';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { NotifyService } from '@services/notify.service';
import {VehiclesDto} from "@models/backoffice/vehicles-backoffice.dto";
import {MyvehiclesService} from "@modules/customer/myvehicles/services/myvehicles.service";

@Component({
  selector: 'app-cancel-tag',
  templateUrl: './cancel-tag-backoffice.component.html',
  styleUrls: ['./cancel-tag-backoffice.component.scss'],
})
export class CancelTagBackOfficeComponent implements OnInit {
  @Input() vehicle: VehiclesDto;
  success: any;
  errorMsg: string;
  isLoading = false;
  focus: boolean = false;
  step = 0;
  balance: number;
  optionSelected: boolean = false;

  cancelTagForm = new FormGroup({
    tagId: new FormControl(),
    protocol: new FormControl('', [Validators.required]),
    contractVehicleId: new FormControl(),
    motivoCancelamento: new FormControl('', [Validators.required]),
    cancelTag: new FormControl('', [Validators.required]),
    motivoDescricao: new FormControl(),
  });

  constructor(
    private modalService: NgbModal,
    private vehiclesService: MyvehiclesService,
    private notifyService: NotifyService,
    public activeModal: NgbActiveModal,
  ) {
    this.getRequiredFields();
  }

  getRequiredFields() {
    this.cancelTagForm.get('cancelTag').valueChanges.subscribe((value) => {
      this.cancelTagForm
        .get('motivoCancelamento')
        .setValidators(Validators.required);

      this.cancelTagForm
        .get('motivoCancelamento')
        .valueChanges.subscribe((value) => {
          if (value == '6') {
            this.cancelTagForm
              .get('motivoDescricao')
              .setValidators([Validators.required]);
            this.cancelTagForm.get('motivoDescricao').clearValidators();
          } else {
            if (value) {
              this.cancelTagForm.get('motivoDescricao').clearValidators();
              this.cancelTagForm.get('motivoDescricao').reset('');
            }
          }
          this.cancelTagForm.get('motivoDescricao').updateValueAndValidity();
        });
    });
  }

  ngOnInit() {
    this.loadInfos();
  }

  loadInfos() {
    this.vehiclesService
      .GetVehicleTagDetails(this.vehicle.contractVehicleId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.balance = success && (success.accountBalance) ? success.accountBalance : 0;
        },
        (e) => {
          console.error('warning: ', e);
        },
      );
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  next() {
    this.errorMsg = '';
    if (!this.cancelTagForm.valid) {
      this.notifyService.showInfo(
        'Atenção',
        'Preencha todos os campos, por favor!',
      );
    } else {
      if (this.balance > 0) {
        let modal = this.modalService.open(TransferVprComponent);
        modal.componentInstance.vehicle = this.vehicle;
        modal.componentInstance.balance = this.balance;
        modal.componentInstance.cancelTagForm = this.cancelTagForm;
      } else {
        let modal = this.modalService.open(ConfirmationComponent);
        modal.componentInstance.vehicle = this.vehicle;
        modal.componentInstance.balance = this.balance;
        modal.componentInstance.cancelTagForm = this.cancelTagForm;
      }
      this.activeModal.dismiss();
    }
  }
}
