import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FinancialBlockingUnblockingRoutingModule } from './routes/financial-blocking-unblocking-routing.module';
import { FinancialBlockingUnblockingComponent } from './financial-blocking-unblocking.component';
import { ConfirmationBlockTypeModalComponent } from './components/confirmation-block-type-modal/confirmation-block-type-modal.component';
import { FeedbackFinancialBlockModalComponent } from './components/feedback-financial-block-modal/feedback-financial-block-modal.component';
import { ConfirmationBlockUnblockModalComponent } from './components/confirmation-block-unblock-modal/confirmation-block-unblock-modal.component';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import {MatCardModule} from "@angular/material/card";
import {NgxMaskDirective} from "ngx-mask";
import {MatRadioModule} from "@angular/material/radio";
import { LateFeeEditModalComponent } from './components/late-fee-edit-modal/late-fee-edit-modal.component';
import { DefaultInterestEditModalComponent } from './components/default-interest-edit-modal/default-interest-edit-modal.component';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    FinancialBlockingUnblockingRoutingModule,
    MatCardModule,
    MatRadioModule,
    NgxMaskDirective
  ],
  declarations: [
    FinancialBlockingUnblockingComponent,
    ConfirmationBlockTypeModalComponent,
    FeedbackFinancialBlockModalComponent,
    ConfirmationBlockUnblockModalComponent,
    LateFeeEditModalComponent,
    DefaultInterestEditModalComponent
  ],
})
export class FinancialBlockingUnblockingModule {}
