import { ContractFee } from "@models/contractFee.model";

export class ContractInfosFinancialBlockingDto {
  companyName: string;
  id: number;
  billingCode: string;
  accountBalance: string;
  creditLimit: string;
  tagsBlockedQuantity: number;
  tagsActiveQuantity: number;
  daysToBlock: number;
  contractStatusTypeId: number;
  contractBlockTypeId: number;
  contractFee: ContractFee;
}
