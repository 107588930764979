import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { VindiService } from "src/app/core/services/vindi.service";
import { finalize } from "rxjs/operators";
import { CreditCardDto } from "../dtos/credit-card.dto";
import { PaymentService } from "@services/payment.service";
import { NotifyService } from "@services/notify.service";
import { PrePaidConditionsDto } from "@models/prepaid-conditions.dto";

@Component({
  selector: "app-register-credit-card-modal",
  templateUrl: "./register-credit-card-modal.component.html",
  styleUrls: ["./register-credit-card-modal.component.scss"],
})
export class RegisterCreditCardModalComponent implements OnInit {
  constructor(
    private modalService: NgbActiveModal,
    private service: PaymentService,
    private vindiService: VindiService,
    private notifyService: NotifyService,
  ) {}

  prePaidConditions: PrePaidConditionsDto;
  isLoading: boolean = false;
  submitted: boolean = false;
  step: number = 1;
  success: any;
  CreditCard: CreditCardDto;
  @Output() event: EventEmitter<any> = new EventEmitter();
  @Input() isPaymentTransaction: boolean;



  CreditCardForm = new FormGroup({
    card_number: new FormControl("", [
      Validators.required,
      this.isValidCreditCard(),
    ]),
    payment_company_code: new FormControl("", [Validators.required]),
    holder_name: new FormControl("", [Validators.required]),
    card_expiration: new FormControl("", [
      Validators.required,
      this.isValidValidityNumber(),
    ]),
    card_cvv: new FormControl("", [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  creditCardFlags: Array<any> = [];

  isValidCreditCard() {
    return (control: AbstractControl): Validators => {
      let number = this.CreditCardForm
        ? this.CreditCardForm.controls["card_number"].value
        : '0';

      return number.length !== 16 || /^([0-9])\1*$/.test(number)
        ? { invalidCreditCard: true }
        : null;
    };
  }

  getPrePaidConditions() {
    this.isLoading = true;
    this.service
      .getPrePaidConditions()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: PrePaidConditionsDto) => {
          this.prePaidConditions = success;
        },
        (error) => {
          console.error("error: ", error);
        }
      );
  }

  isValidValidityNumber() {
    return (control: AbstractControl): Validators => {
      let validity = this.CreditCardForm
        ? this.CreditCardForm.controls["card_expiration"].value
        : "";

      return validity.length !== 6 ||
        this.isValidMonth(Number(validity.substring(0, 2)))
        ? { invalidValidityNumber: true }
        : null;
    };
  }

  isValidMonth(number) {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }
    if (months.find((x) => x == number)) {
      return false;
    } else {
      return true;
    }
  }

  generateMessage(errors) {
    if (typeof errors !== "undefined") {
      if (errors.required == true) {
        return "Campo é requirido!";
      } else if (errors.min) {
        return "Mínimo " + errors.min.min + "!";
      } else if (errors.max) {
        return "Máximo " + errors.max.max + "!";
      } else if (errors.invalidCreditCard) {
        return "Cartão de crédito inválido!";
      } else if (errors.minlength) {
        return "Mínimo " + errors.minlength.requiredLength + " caracteres!";
      } else if (errors.maxlength) {
        return "Máximo " + errors.maxlength.requiredLength + " caracteres!";
      } else if (errors.invalidValidityNumber) {
        return "inválido!";
      }
    }
  }

  ngOnInit(): void {
    this.creditCardFlags = [
      {
        value: "mastercard",
        name: "Mastercard",
      },
      /*  {
        id:2,
        name: "Hipercard"
      }, */
      {
        value: "visa",
        name: "Visa",
      },
      /*   {
        id:4,
        name: "American Express"
      }, */
      {
        value: "elo",
        name: "Elo",
      },
      /* {
        id:6,
        name: "Diners Club"
      }, */
    ];
    this.getPrePaidConditions();
  }

  dismissModal(){
    this.modalService.close();
    if(this.step == 2) {
      this.event.emit();
    }
  }

  createPaymentProfile() {
    this.isLoading = true;
    this.submitted = true;
    this.CreditCardForm.value.card_expiration =
      this.CreditCardForm.value.card_expiration.substr(0, 2) +
      "/" +
      this.CreditCardForm.value.card_expiration.substr(
        2,
        this.CreditCardForm.value.card_expiration.length
      );
    this.CreditCard = this.CreditCardForm.value;
    this.CreditCard.payment_method_code = "credit_card";
    if (this.CreditCardForm.invalid) {
      return;
    } else {
      this.vindiService.createVindiPaymentProfile(this.CreditCard).subscribe(
        (success) => {
          this.registerCreditCardInfos(success.payment_profile.gateway_token);
        },
        (error) => {
          this.isLoading = false;
          this.generateErrorMessage(error);
          console.error(error);
        }
      );
    }
  }

  generateErrorMessage(error) {
    console.log(
      error.error.errors.filter((data) => data.parameter == "card_number")
    );
    if (
      error.error.errors.filter((data) => data.parameter == "card_number")
        .length > 0
    ) {
      this.notifyService.showError("Atenção!", "Erro no cadastro do cartão. Verifique seus dados e tente novamente.");
    } else if (
      error.error.errors.filter((data) => data.parameter == "card_expiration")
        .length > 0
    ) {
      this.notifyService.showError(
        "Atenção!",
        "Data de expiração do cartão inválida!"
      );
    } else {
      this.notifyService.showError(
        "Atenção!",
        "Ocorreu um erro ao cadastrar o cartão!"
      );
    }
  }

  registerCreditCardInfos(token) {
    this.service
      .registerCreditCard(token)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.success = success;
          if(this.isPaymentTransaction){
            this.notifyService.showSuccess("Sucesso", "Cartão cadastrado com sucesso!");
            this.modalService.close();
          }
          else{
            this.step = 2;
          }
        },
        (error) => {
          console.error(error);
          this.notifyService.showError(
            "Error",
            "Ocorreu um erro ao cadastrar o cartão!"
          );
        }
      );

  }


}
