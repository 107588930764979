 import { HttpParams } from '@angular/common/http';

 export class GetVprCreditReceivedQueryArguments {

  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search: string;
  FromDate: Date;
  ToDate: Date;

  transactionTypeId: number;

  get httpParans() {
    let fromDate  = this.FromDate ? new Date(this.FromDate.getFullYear(), this.FromDate.getMonth(), this.FromDate.getDate(),0).toJSON() : null;
    let toDate = this.ToDate ? new Date(this.ToDate.getFullYear(), this.ToDate.getMonth(), this.ToDate.getDate(),23,59,59,999).toJSON() : null;

    let httpParams = new HttpParams()
    .append('Page', this.page.toString())
    .append('PageSize', this.pageSize.toString())
    .append('TimeZoneId', Intl.DateTimeFormat().resolvedOptions().timeZone);


    if(this.orderBy){
      httpParams = httpParams.append('SortField', this.orderBy)
    }

    if(this.desc){
      httpParams = httpParams.append('SortOrder', this.desc ? '0' : '1')
    }

    if(this.search){
      httpParams = httpParams.append('Search', this.search?.toString())
    }

    if(this.transactionTypeId){
      httpParams = httpParams.append('TransactionTypeId', this.transactionTypeId?.toString())
    }

    if(this.FromDate){
      httpParams = httpParams.append('FromDate', fromDate)
    }

    if(this.ToDate){
      httpParams = httpParams.append('ToDate', toDate)
    }


  return httpParams;
  };
}





