import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CredentialsModalComponent } from '../credentials-modal/credentials-modal.component';
import { PaymentService } from '@services/payment.service';
import { ContractIntegrationFee } from '@models/contractintegrationfee.model';
import { finalize } from 'rxjs';
import { MyContractService } from '@services/mycontract.service';
import { UserProfileService } from '@services/user-profile.service';

@Component({
  selector: 'app-contract-details-modal',
  templateUrl: './contract-details-modal.component.html',
  styleUrls: ['./contract-details-modal.component.scss']

})

export class ContractDetailsModalComponent implements OnInit {
  @Input() commercialCondition: any;
  @Input() contractBillingCuttingCycle: string;
  @Input() principalPaymentMethodType: number;

  contractIntegrationFee: ContractIntegrationFee;
  haveIntegrationRole: boolean;
  public dualTagAquisicaoNovasTags: any;
  public dualTagMensalidadeTags: any;
  public codigoContrato: any;
  public retornoDualTag: any;

  constructor(
    private paymentService: PaymentService,
    private userProfileService: UserProfileService,
    private modalService: NgbModal, private mycontractService: MyContractService) { }


  ngOnInit(): void {
    this.codigoContrato = localStorage.getItem('contractId');
    this.getDualTag();
    this.getContractIntegrationFee();
  }

  getDualTag() {
    this.mycontractService.getContractDualTag(this.codigoContrato).subscribe((success: any) => {
      this.retornoDualTag = success;
      this.dualTagMensalidadeTags = success[0];
      this.dualTagAquisicaoNovasTags = success[1];
    }, (error) => {
      console.error("Error: " + JSON.stringify(error));
      return;
    })
  }

  close() {
    this.modalService.dismissAll();
  }


  openCredentials() {
    this.close();
    const modalRef = this.modalService.open(CredentialsModalComponent);
    modalRef.componentInstance.commercialCondition = this.commercialCondition;
    modalRef.componentInstance.principalPaymentMethodType = this.principalPaymentMethodType;
  }

  getContractIntegrationFee() {
    this.haveIntegrationRole = this.userProfileService.haveRole("101230");

    if (this.userProfileService.isBackOffice()) {
      this.haveIntegrationRole = true;
    }

    if (!this.haveIntegrationRole) {
      return;
    }

    this.paymentService
      .getContractIntegrationFee()
      .pipe(
        finalize(() => {
          //this.isLoading = false;
        })
      )
      .subscribe(
        (success: ContractIntegrationFee) => {
          this.contractIntegrationFee = success;
        },
        (error) => {
          console.error("error: ", error);
        }
      );
  }


  isPeriodoIsencao(): boolean {
    return this.dualTagMensalidadeTags?.exemptionStartDate !== null &&
      this.dualTagMensalidadeTags?.exemptionEndDate !== null &&
      this.dualTagMensalidadeTags?.endDate == null;
  }

  getDescricaoMarketingPlan(): string {
    switch (this.commercialCondition.marketingPlan.marketingPlanTypeId) {
      case 1:
        return 'Taxa';
      case 2:
        return 'Mensalidade + Taxa';
      case 3:
        return 'Mensalidade';
      case 4:
        return 'Mensalidade Reduzida + Taxa de Recarga';
      case 5:
        return 'Mensalidade Fixa';
      case 6:
        return 'Taxa de Recarga';
      default:
        return '';
    }
  }

}
