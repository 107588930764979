import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BillVehiclesQueryArguments } from '../dto/bill-vehicles-query.arguments';
import { BillVehiclesResultDto } from '../dto/bill-vehicles-result.dto';
import { BillHierarchyBreadCrumbResultDto } from '../dto/bill-hierarchy-bread-crumb-result.dto';



@Injectable({
  providedIn: 'root'
})
export class BillDetailVehicles {
 
  constructor(protected http: HttpClient) { }

getBillVehicles(args: BillVehiclesQueryArguments){
    return this.http.get<BillVehiclesResultDto>(environment.apiTMReport +`/Bill/GetBillVehicle`, {
      params: args.httpParams,
    });
}

getHierarchyBreadCrumbList(){
  return this.http.get<BillHierarchyBreadCrumbResultDto>(environment.apiTMVehicle +`/GroupHierarchy/GetHierarchyBreadCrumbList`);
  
}


}
