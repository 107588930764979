import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectContractsComponent } from '../select-contracts.component';
import { authGuardCustomer } from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  {
    path: "selecionar-contrato",
    component: SelectContractsComponent,
    canActivate: [authGuardCustomer],
    data: { roles: "", contractRequired: false },

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SelectContractsRoutingModule { }
