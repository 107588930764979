import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionPartnersConciliationComponent } from '../transaction-partners-conciliation.component';
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";

const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.PARTNERS_TRANSFER_CONCILIATION,
        component: TransactionPartnersConciliationComponent,
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Conciliação de repasse de parceiros'),
          roles: '100993',
          contractRequired: false,
        },
      },
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionPartnersConciliationRoutingModule { }
