import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ContractModality } from '@models/old/contractModality.enum';

@Component({
  selector: 'app-financial-block-modal',
  templateUrl: './financial-block-modal.component.html',
  styleUrls: ['./financial-block-modal.component.scss']
})
export class FinancialBlockModalComponent implements OnInit {

  constructor(private modalService: NgbModal,private router: Router) { }

  contractModality = ContractModality;
  @Input() contract;

  ngOnInit(): void {}

  dismissModal() {
    this.modalService.dismissAll();
  }

  RedirectToPage(modality){
    this.dismissModal();
    if(modality == 1){
      this.router.navigate(['/recargas']);
    }
    else{
      this.router.navigate(['/faturas']);
    }
  }

}
