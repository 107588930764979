import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-edit-modal-confirm',
  standalone: false,
  templateUrl: './user-edit-modal-confirm.component.html',
  styleUrl: './user-edit-modal-confirm.component.scss'
})
export class UserEditModalConfirmComponent {
  @Input() subTitleModal: string;
  @Input() userEdit: any;
  @Input() resultEdit: any;
  @Input() isBackoffice: boolean

}
