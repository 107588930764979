import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {VehiclesDto} from "@models/backoffice/vehicles-backoffice.dto";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  @Input() vehicle: VehiclesDto;
  @Input() balance: number;
  @Input() vehicleToReceiveVPR: VehiclesDto;
  @Input() isExclude: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  dismissModal() {
    this.activeModal.close();
  }
}
