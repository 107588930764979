import { Component, OnInit, Input } from '@angular/core';
import { itemsByDateDto } from '../../dtos/getExtractQueryResult.dto';

@Component({
  selector: 'app-final-limit',
  templateUrl: './final-limit.component.html',
  styleUrls: ['./final-limit.component.scss']
})
export class FinalLimitComponent implements OnInit {
  @Input() itemByDate: itemsByDateDto;
  @Input() first: boolean;


  constructor() {
    this.itemByDate = {
      balanceAfterTransaction: 0,
      date: null,
      items: []
    }
  }

  getHeight(){
    let height = this.first ? this.itemByDate.items.length*81.52+172.56 : this.itemByDate.items.length*81.85+35.81
    return height
  }

  ngOnInit(): void {

  }

}
