import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TripExpiratedRoutingModule } from './routes/trip-expirated-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TripExpiratedComponent } from './trip-expirated.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { SearchFormInlineComponent } from "../../../shared/components/search-form-inline/search-form-inline.component";
import { OutlinedSelectComponent } from '../../../shared/select/outlined-select/outlined-select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    NgbModule,
    TripExpiratedRoutingModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BorderlessButtonComponent,
    SearchFormInlineComponent,
    OutlinedSelectComponent,
],
  declarations: [TripExpiratedComponent],
  providers: [provideNgxMask()],
})
export class TripExpiratedModule {}
