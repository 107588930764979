import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  constructor() { }

  finishedLoading: boolean = false;
  changeOpacityLoading:boolean = false;

  ngOnInit() {
    this.finishedLoad()
  }


  finishedLoad(){
    setTimeout(() => { 
      this.changeOpacityLoading = true;
      setTimeout(() => {
        this.finishedLoading = true
       }, 900);
     }, 200);
  }
}
