import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../login.component';
import { WelcomeComponent } from '../components/welcome/welcome.component';
import { FirstAccessComponent } from '../components/first-access/first-access.component';
import { RegisterPasswordComponent } from '../components/register-password/register-password.component';
import { RecoverPasswordComponent } from '../components/recover-password/recover-password.component';
import { RegisteredPasswordComponent } from '../components/registered-password/registered-password.component';


const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "login/bem-vindo", component: WelcomeComponent },
  { path: "primeiro-acesso", component: FirstAccessComponent },
  { path: "registrar-senha/:documentNumber", component: RegisterPasswordComponent },
  { path: "recuperar-senha", component: RecoverPasswordComponent },
  { path: "senha-cadastrada", component: RegisteredPasswordComponent },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LoginRoutingModule {}
