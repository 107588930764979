<div class="tabHierarchyComponent">
<div class="col-12 d-flex hierarchy-section">
  <div class="col-sm-auto card card-tab-hierarchy mt-6rem overflow-initial">
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12 px-0 mr-2 no-right-border">
          <div class="group-row">
            <div class="groups">
              <div class="d-flex justify-content-center">
                <p data-e2e="group-title" class="p-group mt-4">GRUPOS</p>
              </div>
            </div>
          </div>

          <div
            class="btn-group mt-3 mb-4"
            dropdown
            [isAnimated]="true"
            [autoClose]="false"
          >
            <button
              dropdownToggle
              data-e2e="filter-toggle-btn"
              type="button"
              id="button-animated"
              aria-controls="dropdown-animated"
              class="btn btn-filter dropdown-toggle"
              [class.without-border-bottom-radius]="isOpenDropdown"
              [class.active]="isOpenDropdown"
              (click)="toggleDropdown()"
            >
              <img src="assets/img/filter.svg" class="button-icon" />
              Filtros
            </button>

            <div class="filters">
              <ul
                id="dropdown-animated"
                *dropdownMenu
                class="dropdown-menu dropdown-filter"
                role="menu"
                aria-labelledby="button-animated"
              >
                <li role="menuitem" (click)="toggleDateRangePicker()">
                  <div class="simulate-select">
                    <img src="assets/img/ico-calendar.svg" />

                    <span data-e2e="period-filter-label" class="simulate-label">Período</span>

                    <input
                      id="filterDateHierarchy"
                      data-e2e="period-filter-input"
                      type="text"
                      placeholder="Selecione"
                      class="simulate-form-control"
                      #dp="bsDaterangepicker"
                      bsDaterangepicker
                      placement="right"
                      [bsValue]="dayCustomHierarchies"
                      [maxDate]="maxDate"
                      (bsValueChange)="onFilterDateHierarchies($event)"
                      [minDate]="minDate"
                      [outsideClick]="false"
                    />

                    <div class="simulate-button" [class.active]="isShownPeriod">
                      <img src="assets/img/arrow-select.svg" />
                    </div>
                  </div>
                </li>

                <li>
                  <form class="input-search">
                    <app-search-form-inline
                      [customWidth]="'298px'"
                      [placeholder]="'busca de hierarquia'"
                      (keyUpEvent)="onFullNameKeyup($event)"/>
                  </form>
                </li>

                <li role="menuitem">
                  <div class="content-button">

                    <app-borderless-button
                    [description]="'Limpar filtros'"
                    [classIconLeft]="'ico-delete'"
                    [id-html]="'resetFiltersButton'"
                    [data-e2e]="'reset-filter-btn'"
                    [buttonColor]="'secundary'"
                    (emitterEvtClick)="resetFilters()"
                    [isBtnWithBorder]="true"></app-borderless-button>
                    <button
                      id="hideHierarchyDate"
                      data-e2e="hide-hierarchy-date"
                      #myDiv
                      hidden
                      class="btn btn-success"
                      (click)="dp.hide()"
                      type="button"
                    ></button>

                    <button
                      id="showHierarchyDate"
                      data-e2e="show-hierarchyy-date"
                      #myDiv2
                      hidden
                      class="btn btn-success"
                      (click)="dp.show()"
                      type="button"
                    ></button>
                  </div>
                </li>
              </ul>

              <div
                class="box-area-datepicker"
                [hidden]="dateHierarchies != null && !isOpen"
                [ngClass]="{ show: isShownPeriod, hide: !isShownPeriod }"
              >
                <div class="content-extend">
                  <form [formGroup]="formPreDateHierarchy">
                    <div class="datepicker">
                      <h4 data-e2e="period-filter-label" class="text-center mb-2 mt-0">Selecione o período</h4>

                      <div
                        class="d-flex justify-content-around align-items-center"
                      >
                      <mat-radio-group class="custom-control custom-radio">
                        @for(date of datepickerDates; track date;) {
                         <mat-radio-button  class="custom-control-input" id="defaultGroupExample4"
                         formControlName="dateVehicles" [value]="date" (click)="setDaysLast(date)"
                         name="dateVehicles" />
                       <label class="custom-control-label" for="defaultGroupExample4">últimos {{date}} dias</label>
                        }
                       </mat-radio-group>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="container-loading mt-5" *ngIf="isSideLoading">
            <app-loader
              [isLoading]="isSideLoading"
              message="{{ 'Carregando...' }}"
            ></app-loader>
          </div>

          <div
            *ngIf="
              getContractHierarchiesQueryResult?.total == 0 && !isSideLoading
            "
          >
            <p data-e2e="empty-hierarchy-msg" class="text-center font-weight-bold mt-5">
              Não existe nenhum grupo.
            </p>
          </div>

          <ul
            class="box-vehicles"
            *ngIf="
              getContractHierarchiesQueryResult?.total > 0 && !isSideLoading
            "
          >
            <li
              class="item-hierarchy"
              *ngFor="
                let item of getContractHierarchiesQueryResult?.items;
                let i = index
              "
              [ngClass]="{ active: selected == item }"
              (click)="setHierarchyId(item.hierarchyId, item.hierarchyDescription); selected = item"
            >
              <div class="info-hierarchies">
                <span [attr.data-e2e]="'hierarchy-description-filter-' + i" id="hierarchyDescription{{ i }}">
                  {{ item?.hierarchyDescription }} </span
                ><br />

                <span [attr.data-e2e]="'hierarchy-breadcrumb-' + i" class="completeDescription" id="breadCrumb{{ i }}">
                  {{ item?.breadCrumb }} </span
                ><br />

                <span [attr.data-e2e]="'hierarchy-amount-' + i" id="amount{{ i }}">
                  {{ item?.amount | currency: "BRL":"symbol":"0.2-2":"pt-BR" }}
                </span>
              </div>

              <button [attr.data-e2e]="'hierarchy-action-btn' + i" class="btn btn-round-icon">
                <img src="assets/svg/ico-search-dark.svg" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-auto pl-0 mt-negative info-section">
    <div class="card card-query">
      <div class="container-loading mt-5" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ 'Carregando...' }} "
        ></app-loader>
      </div>

      <div
        *ngIf="
          (getContractHierarchiesQueryResult?.total == 0 ||
          cleanList == true) &&
          !isLoading
        "
      >
        <p
          *ngIf="getContractHierarchiesQueryResult?.total > 0 && cleanList == true"
          data-e2e="hierarchy-group-filter-msg"
          class="text-center mt-5 font-weight-bold"
        >
          Selecione um grupo ao lado ou faça uma busca
        </p>

        <p
          *ngIf="getContractHierarchiesQueryResult?.total == 0"
          data-e2e="empty-group-msg"
          class="text-center mt-5 font-weight-bold"
        >
          Não existe nenhum grupo.
        </p>
      </div>

      <div
        *ngIf="
          getContractHierarchiesQueryResult?.total > 0 &&
          getHierarchiesQueryResult &&
          cleanList == false &&
          !isLoading
        "
      >
        <div class="row first-level">
          <div class="col-4 hierarchyDetails">
            <div class="row">
              <div class="col-sm-auto">
                <img class="pr-2" src="assets/img/hierarchy_select.svg " />
              </div>

              <div class="col-sm-auto">
                <div data-e2e="hierarchy-description" class="hierarchyDescription">
                  {{ getHierarchiesQueryResult?.hierarchyDescription }}
                </div>

                <div data-e2e="hierarchy-complete-description" class="completeHierarchicalDescription">
                  {{ getHierarchiesQueryResult?.completeHierarchicalDescription }}
                </div>

              </div>
            </div>
          </div>

          <div data-e2e="totals-msg" class="col-6 hierarchy-name">
            <span data-e2e="total-vehicle" id="vehiclesTotal">
              {{ getHierarchiesQueryResult?.vehiclesTotal }}
            </span>

            veículos associados <br />
            <span data-e2e="active-tags-total" id="activeTagsTotal">
              {{ getHierarchiesQueryResult?.activeTagsTotal }}
            </span>

            tags ativas
          </div>

          <div data-e2e="total-values -msg" class="col-2 d-flex justify-content-end total">
            Valor total gasto
            <span data-e2e="total-hierarchy-value" id="totalHierarchyValue" class="red">
              {{ getHierarchiesQueryResult?.totalHierarchyValue | currency: "BRL":"symbol":"0.2-2":"pt-BR" }}
            </span>
            <!-- {{ getHierarchiesQueryResult?.totalMonthlyFees | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }} -->
          </div>
        </div>

        <div class="d-flex justify-content-start align-items-centert-3 pb-2 m-4">
          <form novalidate>
            <div class="form-group select-table">
              <app-outlined-select
                [title]="'Quantidade de linhas'"
                [afterTextValue]="' linhas'"
                [beforeTextValue]="'exibir '"
                [values]="perPages"
                [initialValue]="pageSizeTransactions"
                (selectedValueChange)="onSetVehicleFromPageSize($event)"
              ></app-outlined-select>
            </div>
          </form>
        </div>

        <div class="px-4">
          <div class="row">
            <div class="col-custom percent-16 pr-1">
              <div data-e2e="table-top-description" class="header-table">Detalhes</div>
            </div>

            <div class="col-custom percent-84 pl-1">
              <div data-e2e="table-top-value" class="header-table">Valores (R$)</div>
            </div>
          </div>

          <table class="table tickets-hierarchy">
            <thead class="thead">
              <tr>
                <th [ngClass]="bindOrderByClass('licensePlateNickName')">
                  <button
                    class="btn-order-table"
                    data-e2e="sort-plate-btn"
                    (click)="onOrderByChange('licensePlateNickName')"
                  >
                    Placa/Apelido
                  </button>
                  <div
                    class="round-order"
                    (click)="onOrderByChange('licensePlateNickName')"
                  >
                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                  </div>
                </th>

                <th [ngClass]="bindOrderByClass('totalTollPassingAmount')">
                  <button
                    class="btn-order-table"
                    data-e2e="sort-toll-value-btn"
                    (click)="onOrderByChange('totalTollPassingAmount')"
                  >
                    Pedágio
                  </button>
                  <div
                    class="round-order"
                    (click)="onOrderByChange('totalTollPassingAmount')"
                  >
                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                  </div>
                </th>

                <th
                  [ngClass]="bindOrderByClass('totalTollPassingReversalAmount')"
                >
                  <button
                    class="btn-order-table"
                    data-e2e="sort-reversal-value-btn"
                    (click)="onOrderByChange('totalTollPassingReversalAmount')"
                  >
                    Estorno de Pedágio
                  </button>
                  <div
                    class="round-order"
                    (click)="onOrderByChange('totalTollPassingReversalAmount')"
                  >
                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                  </div>
                </th>

                <th [ngClass]="bindOrderByClass('totalParkingPassingAmount')">
                  <button
                    class="btn-order-table"
                    data-e2e="sort-parking-value-btn"
                    (click)="onOrderByChange('totalParkingPassingAmount')"
                  >
                    Estacionamento
                  </button>
                  <div
                    class="round-order"
                    (click)="onOrderByChange('totalParkingPassingAmount')"
                  >
                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClass('totalParkingPassingReversalAmount')
                  "
                >
                  <button
                    class="btn-order-table"
                    data-e2e="sort-parking-reverse-value-btn"
                    (click)="
                      onOrderByChange('totalParkingPassingReversalAmount')
                    "
                  >
                    Estorno de Estacionamento
                  </button>
                  <div
                    class="round-order"
                    (click)="
                      onOrderByChange('totalParkingPassingReversalAmount')
                    "
                  >
                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                  </div>
                </th>

                <th [ngClass]="bindOrderByClass('totalTollValleyAmount')">
                  <button
                    class="btn-order-table"
                    data-e2e="sort-vpr-total-btn"
                    (click)="onOrderByChange('totalTollValleyAmount')"
                  >
                    gasto em vale pedágio
                  </button>
                  <div
                    class="round-order"
                    (click)="onOrderByChange('totalTollValleyAmount')"
                  >
                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                  </div>
                </th>
                <!--     <th [ngClass]="bindOrderByClass( 'totalMonthlyFeeAmount') ">
                                    <button class="btn-order-table " (click)="onOrderByChange( 'totalMonthlyFeeAmount') ">Total de <br/> mensalidades</button>
                                    <div class="round-order " (click)="onOrderByChange( 'totalMonthlyFeeAmount') ">
                                        <img src="../../../assets/svg/order-table.svg " alt=" " />
                                    </div>
                                </th>
                                <th [ngClass]="bindOrderByClass( 'totalValue') ">
                                    <button class="btn-order-table " (click)="onOrderByChange( 'totalValue') ">Valor total</button>
                                    <div class="round-order " (click)="onOrderByChange( 'totalValue') ">
                                        <img src="../../../assets/svg/order-table.svg " alt=" " />
                                    </div>
                                </th> -->
              </tr>
            </thead>

            <div class="container-loading" *ngIf="isLoadingVehicles">
              <app-loader
                [isLoading]="isLoadingVehicles"
                message="{{ 'Carregando...' }}"
              ></app-loader>
            </div>

            <tbody *ngIf="!isLoadingVehicles">
              <tr
                *ngFor="
                  let itemTransaction of getHierarchiesQueryResult?.transactions
                    .transactionItems;
                  let i = index
                "
                data-e2e="open-vehicle-modal-btn"
                (click)="
                  showVehicleModal(
                    itemTransaction?.licensePlate,
                    getHierarchiesQueryResult?.hierarchyDescription
                  )
                "
              >
                <td [attr.data-e2e]="'transaction-plate-' + i" id="licensePlateNickName{{ i }}">
                  {{ itemTransaction?.licensePlateNickName }}
                </td>

                <td [attr.data-e2e]="'transaction-toll-value-' + i" class="color-primary red" id="totalTollPassingAmount{{ i }}">
                  {{
                    itemTransaction?.totalTollPassingAmount
                      | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                  }}
                </td>

                <td
                  class="color-primary"
                  [attr.data-e2e]="'transaction-toll-reversal-value-' + i"
                  id="totalTollPassingReversalAmount{{ i }}"
                >
                  {{
                    itemTransaction?.totalTollPassingReversalAmount
                      | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                  }}
                </td>

                <td [attr.data-e2e]="'transaction-parking-value-' + i" class="color-primary red" id="totalParkingPassingAmount{{ i }}">
                  {{
                    itemTransaction?.totalParkingPassingAmount
                      | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                  }}
                </td>

                <td
                  class="color-primary"
                  [attr.data-e2e]="'transaction-parking-reversal-value-' + i"
                  id="totalParkingPassingReversalAmount{{ i }}"
                >
                  {{
                    itemTransaction?.totalParkingPassingReversalAmount
                      | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                  }}
                </td>

                <td [attr.data-e2e]="'transaction-total-value' + i" class="color-primary" id="totalTollValleyAmount{{ i }}">
                  {{
                    itemTransaction?.totalTollValleyAmount
                      | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <div
            *ngIf="
              getHierarchiesQueryResult?.transactions.total == 0 &&
              !isLoadingVehicles
            "
          >
            <div data-e2e="empty-vehicle-msg" colspan="12 " class="text-center w-100 py-4" translate>
              Nenhum resultado encontrado.
            </div>
          </div>
        </div>

        <div
          class="tickets-hierarchies pagination-custom"
          *ngIf="
            getHierarchiesQueryResult?.transactions.total >
              getTransactionsHierarchiesQueryArguments.pageSize &&
            !isLoadingVehicles
          "
        >
          <app-pagination
            [totalItems]="getHierarchiesQueryResult?.transactions.total"
            [itemsPerPage]="getTransactionsHierarchiesQueryArguments.pageSize"
            [maxPages]="5"
            [currentPage]="getTransactionsHierarchiesQueryArguments.page"
            (pageChangeEvent)="onPaginationChange($event)"
          ></app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
