import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { PaymentService } from "src/app/core/services/payment.service";
import { PrePaidConditionsDto } from "../../../recharge/dtos/prepaid-conditions.dto";
import { RechargeService } from "../../../recharge/services/recharge.service";
import { NotifyService } from "@services/notify.service";

@Component({
  selector: "app-change-auto-recharge-modal",
  templateUrl: "./change-auto-recharge-modal.component.html",
  styleUrls: ["./change-auto-recharge-modal.component.scss"],
})
export class ChangeAutoRechargeModalComponent implements OnInit {
  isLoading: boolean = false;
  @Input() prePaidConditions: PrePaidConditionsDto;
  @Input() rangeList: any;
  @Input() cardIconPath: string;
  submitted: boolean = false;
  step: number = 1;
  @ViewChild("myCarousel", { static: false }) myCarousel;
  newAutoRechargeForm: any;
  success: any;
  @Output() event: EventEmitter<any> = new EventEmitter();
  responsiveOptions: any;
  formValue: any;
  page: number = 1;

  constructor(
    public modal: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private rechargeService: RechargeService,
    private notifyService: NotifyService,
    public paymentService: PaymentService,
  ) {
    this.responsiveOptions = [{
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 1
  }];
  }

  generateForm() {
    this.newAutoRechargeForm = new FormGroup({
      automaticRechargeValue: new FormControl(""),
    });
  }

  selectValue(page){
    this.formValue = this.rangeList.range[page]
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.positionInitCarousel(this.prePaidConditions.automaticRechargeValue);
  }

  ngOnInit() {
    this.generateForm();
  }

  positionInitCarousel(value) {
    let index = this.rangeList.range.findIndex((x) => x === value);
    this.page = index;
    this.formValue = value;
  }

  ConfirmChangeAutoRecharge() {
    this.isLoading = true;
    this.rechargeService
      .putUpdateAutomaticRechargeValue(this.newAutoRechargeForm.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.success = success;
          this.event.emit(success);
          this.step = 2;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  justifyStyle(){
    return this.step == 1 ? `space-between`: `center`
  }
}
