import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { EventEmitterService } from '@services/event-emitter.service';
import { NotificationService } from '@services/notification.service';
import { CustomDefaultAlertComponent } from 'src/app/shared/components/alerts/custom-default-alert/custom-default-alert.component';

@Injectable({
  providedIn: 'root'
})

export class NotificationAlertService {

  options: GlobalConfig;

  constructor(private toastr: ToastrService, private notificationService:NotificationService) {
    this.options = this.toastr.toastrConfig;
   }

  componentAlertList = [
    // Default alert
    {
      componentId: 1,
      toastComponent: CustomDefaultAlertComponent,
      progressBar: true,
      timeOut: 10000,
      extendedTimeOut : 10000,
      closeButton: true,  
      toastClass:'toast-info',
      tapToDismiss: false
    },
    // Error alert
    {
      componentId: 2,
      toastComponent: CustomDefaultAlertComponent,
      progressBar: true,
      timeOut: 10000,
      extendedTimeOut : 10000,
      closeButton: true,  
      toastClass:'toast-error',
      tapToDismiss: false
    },
  ]

  // Contract alerts (asynchronous requests)
  contractAlert(){
    this.notificationService.getContractAlertFeedback(true)
    .subscribe(
      (success) => {
        if(success.length > 0){
          if(success.filter(data => data.notificationTypeId == 7).length > 0){
            EventEmitterService.get('updateAfterContractAlertFeedback').emit();
          }

          for(let i=0;i<success.length;i++){
            if(success[i].notificationTypeId == 7){
              // Info/Success alert
              this.alert(success[i].title,success[i].description)
            }
            else if(success[i].notificationTypeId == 8){
              // Error alert
              this.alert(success[i].title,success[i].description,2)
            }
          }
        }
      },
      (error) => {
        console.log("error: ", error);
        return;
      }
    );
  }
  
  alert(title: string, message:string, componentId:number = 1) {
    var opt = cloneDeep(this.options);
    opt = this.componentAlertList.filter(data => data.componentId == componentId)[0]
    if(typeof opt !== 'undefined'){ 
      const inserted = this.toastr.show(message, title, opt);
      return inserted;
    }

    return  
  }

  close(id) {
    this.toastr.clear(id);
  }

}
