import { CreditAlert } from "@models/old/credit-alert";

export class CreditAlertDto {
  showTutorial?: boolean;
  firstAccess?: boolean;

  constructor(public limits?: CreditAlert[]) {}

  formatCreditAlerts(sendEmailNotification: boolean) {
    if (this.limits) {
      const credits = [];

      this.limits.forEach((element, index) => {
        const { enabled, order, ...rest } = element;

        rest.sendEmailNotification = sendEmailNotification;

        credits.push(rest);
      });

      this.limits = credits;
    }
  }
}
