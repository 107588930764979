import { HttpParams } from '@angular/common/http';

export class ChargebackReportQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search: string;
  fromDateChargeback: Date;
  toDateChargeback: Date;
  transactionTypeDescriptionId: number[];
  minimumAmount: number;
  maximumAmount: number;

  constructor() {
    this.page = 1;
    this.pageSize = 10;
    this.orderBy = 'chargebackDate';
    this.desc = true;
    this.search = '';
    this.fromDateChargeback = null;
    this.toDateChargeback = null;
    this.transactionTypeDescriptionId = [];
    this.minimumAmount = null;
    this.maximumAmount = null;
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.orderBy);

    if (this.search != null) {
      httpAppend = httpAppend.append('search', this.search);
    }

    if (this.fromDateChargeback != null) {
      /*
        The fromDate arrives like: Fri Jun 29 2023 00:00:00
        toISOString() - add 3 hours: 2023-06-29T03:00:00.000Z
        .split('T')[0] - split the Date, storing only the Date: 2023-06-29
      */
      const fromDateISOString = this.fromDateChargeback
        .toISOString()
        .split('T')[0];

      httpAppend = httpAppend.append('fromDateChargeback', fromDateISOString);
    }

    if (this.toDateChargeback != null) {
      /*
        The toDate arrives like: Fri Jun 29 2023 23:59:59
        toISOString() - add 3 hours: 2023-06-30T02:59:59.000Z
        .split('T')[0] - split the Date, storing only the Date: 2023-06-30
      */
      const toDateISOString = this.toDateChargeback.toISOString().split('T')[0];

      httpAppend = httpAppend.append('toDateChargeback', toDateISOString);
    }

    if (
      this.transactionTypeDescriptionId != null ||
      this.transactionTypeDescriptionId.length !== 0
    ) {
      this.transactionTypeDescriptionId.forEach(
        (element) =>
          (httpAppend = httpAppend.append(
            'transactionTypeDescriptionId',
            element.toString(),
          )),
      );
    }

    if (this.minimumAmount !== null && this.minimumAmount >= 0) {
      httpAppend = httpAppend.append(
        'minimumAmount',
        this.minimumAmount.toString(),
      );
    }

    if (this.maximumAmount !== null && this.maximumAmount > 0) {
      httpAppend = httpAppend.append(
        'maximumAmount',
        this.maximumAmount.toString(),
      );
    }

    return httpAppend;
  }
}
