export class UserDetailsFillFormModel {
  fullName: string;
  documentNumber: string;
  dateofBirth: string;
  emailAddress: string;
  telephoneNumber: string;
  prefix: string;
  areaCode: string;
  countryCode: string;
}
