<mat-accordion class="vehiclesByFilterComponent" *ngIf="vehicles; else empty">
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        data-e2e="available-vehicle-selected-msg"
        *ngIf="vehicleSelected == null"
      >
        Escolher
      </mat-panel-title>

      <mat-panel-title
        data-e2e="available-vehicle-selected-msg"
        *ngIf="vehicleSelected != null"
      >
        Veículo selecionado:
        {{ vehicleSelected.licensePlate | mask: 'AAA-AAAA' }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- panel body-->
    <div class="vehiclesByFilterComponent__buttons-options">
      <button
        class="vehiclesByFilterComponent__buttons-options-choose"
        (click)="buttonType(1)"
        [class.active]="buttonSelected == 1"
      >
        Todos
      </button>
      <button
        class="vehiclesByFilterComponent__buttons-options-search"
        (click)="buttonType(2)"
        [class.active]="buttonSelected == 2"
      >
        Pesquisar
      </button>
    </div>
    <div
      *ngIf="buttonSelected == 2"
      class="vehiclesByFilterComponent__search-field"
    >
      <input
        type="text"
        [(ngModel)]="vehicleSearch"
        placeholder="Placa do veículo"
        mask="SSS-0A00"
      />
      <button (click)="searchVehicles(vehicleSearch)">Buscar</button>
    </div>
    <mat-radio-group
      aria-label="Select an option"
      class="radio-group__label-black"
      [style.grid-template-columns]="emptySearchStyle()"
      [style.height]="buttonSelected == 2 ? '190px' : '250px'"
      *ngIf="
        (vehicles | vehiclefilter: vehicleSearch) == false
          ? emptySearch
          : !emptySearch
      "
      (change)="onchangeVehicle($event.value)"
    >
      <mat-radio-button
        *ngFor="
          let item of vehicles
            | vehiclefilter: vehicleSearch as filteredVehicles;
          let i = index
        "
        [value]="item"
        class="custom-radio"
        [attr.data-e2e]="'available-vehicle-option-' + i"
      >
        {{ item.licensePlate | mask: 'SSS-0A00' }}
      </mat-radio-button>
    </mat-radio-group>

    <div
      class="empty-search"
      *ngIf="(vehicles | vehiclefilter: vehicleSearch) == false"
    >
      Por favor, informe um veículo com tag ativa que esteja vinculado a este
      contrato.
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #empty>
  <div class="vehiclesByFilterComponent__empty-container" *ngIf="!isLoading">
    Este contrato não possui nenhum veículo com uma tag ativa. Por favor,
    informe ao cliente que é necessário ativar uma tag para concluir a
    transferência de saldo.
  </div>
  <app-loader
    class="empty-search"
    [isLoading]="isLoading"
    message="{{ 'Carregando Veículos' }}"
  ></app-loader>
</ng-template>
