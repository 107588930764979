<main class="TransactionPartnerConciliation">
  <div class="container header-page">
    <app-page-title [title]="'/ Histórico de conciliações'"></app-page-title>

    <app-borderless-button [description]="'Importar novo documento'" [verifyContractBlock]="true"
      [classIconLeft]="'ico-upload'" [id-html]="'export-btn'" [data-e2e]="'export-btn'"
      (emitterEvtClick)="openImportDocumentsModal()">
    </app-borderless-button>
  </div>

  <section class="table-card">
    <div class="table-header">
      <div class="table-header-left">

        <app-outlined-select
          [title]="'Quantidade de linhas'"
          name="perPages"
          data-e2e="perPages"
          [values]="perPages"
          [beforeTextValue]="'Exibir '"
          [afterTextValue]=" ' linhas'"
          [disableRipple]="true"
          [id]="'select-input'"
          (valueChange)="onPageSizeChange($event)"
          >
        </app-outlined-select>

        <app-outlined-select
        [title]="'Filtre por parceiro'"
        name="perPages"
        data-e2e="perPages"
        [values]="sourcePartnersForSelect"
        [disableRipple]="true"
        [multiple]="true"
        [id]="'dropdownManual'"
        (valueChange)="onSelectPartners($event)"
        >
        </app-outlined-select>

      </div>

      <div class="download-button">
        <app-borderless-button [isDisable]="noFiles" [description]="'   Download CSV'" [verifyContractBlock]="true"
          [classIconLeft]="'ico-download'" [id-html]="'export-btn'" [data-e2e]="'export-btn'"
          (emitterEvtClick)="Download()">
        </app-borderless-button>

      </div>
    </div>
    <div class="table-main">
      <table class="table">
        <thead class="thead">
          <tr>
            <th *ngFor="let column of tableHeader" [ngClass]="bindOrderByClass(column[0])">
              <div class="btn-order-table" [attr.data-e2e]="'sort-' + column[0] + '-date-btn'" translate>
                {{ column[1] }}
              </div>

              <div class="round-order" (click)="onOrderByChange(column[0])">
                <img src="assets/svg/order-table.svg" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="!isLoading && noFiles">
          <p class="empty-search-msg" translate>No Transactions Found</p>
        </tbody>

        <tbody *ngIf="!isLoading">
          <tr *ngFor="let transaction of TollVoucherConciliationFiles?.tollVoucherConciliationFiles ; let i = index">
            <td [attr.data-e2e]="'table-referenceDate-' + i" class="reference-date">
              {{ transaction?.referenceDate}}
            </td>

            <td [attr.data-e2e]="'table-partnerName-' + i">
              {{ transaction?.partnerName }}
            </td>
            <td [attr.data-e2e]="'table-registrationDate' + i">
              {{ transaction?.registrationDate}}
            </td>
            <td [attr.data-e2e]="'table-reconcilValue-'+ i">
              {{ transaction?.reconciledValue | currency:'BRL':'symbol':'1.2-2' }}
            </td>
            <td [attr.data-e2e]="'table-status-' + i" class="table-status-error">
              <ng-container *ngIf="transaction?.vprConciliationFileStatusId === Enum.Conciliated">
                <i><img src="assets/svg/icon-check-green-circle.svg" alt=""></i> Conciliado
              </ng-container>
              <ng-container *ngIf="transaction?.vprConciliationFileStatusId === Enum.Error">
                <span><img src="assets/svg/icon-error-circle-red.svg" alt=""> Erro</span> <button
                  class="custom-button btn btn-primary"
                  (click)="openErrorsModal(transaction.conciliationErrors, transaction.totalErrors,  transaction)">Ver
                  detalhes</button>
              </ng-container>
              <ng-container *ngIf="transaction?.vprConciliationFileStatusId === Enum.Processing">
                <i><img src="assets/svg/icon-warning-yellow.svg" alt=""></i> Em Processamento
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader [isLoading]="isLoading" message="{{ message | translate }}"></app-loader>
      </div>
    </div>

    <div class="pagination-custom" *ngIf="
    TollVoucherConciliationFiles?.total > queryArguments.pageSize && !isLoading
  ">
      <ngb-pagination (pageChange)="onPaginationChange($event)" [pageSize]="queryArguments.pageSize" [maxSize]="5"
        [collectionSize]="TollVoucherConciliationFiles?.total" [(page)]="queryArguments.page" [rotate]="true"
        [boundaryLinks]="true" [ellipses]="false" display="dynamic">
      </ngb-pagination>
    </div>
  </section>

</main>
