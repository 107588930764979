import { Customer } from './customer.model';
import { ContractPaymentMethod } from './contractPaymentMethod.model';
import { MarketingPlan } from './marketingPlan.model';
import { Person } from './person.model';
import { ContractFee } from '@models/contractFee.model';

export class Contract {
    id: number;
    contractSystemSourceTypeId: number;
    crmCustomerCode: string;
    customer: Customer;
    amountVehicle: number;
    validityStartDate: Date;
    validityEndDate: Date;
    automaticRenovation: boolean;
    contractIndexReadjustmentTypeId: number;
    contractModalityTypeId: number;
    inativeTagCharge: boolean;
    inativeTagUnitValue: number;
    inativeTagExemptionDays: number;
    inativeTagExemptionPercentage: number;
    tagMonthlyFee: boolean;
    tagMonthlyFeeUnitValue: number;
    tagMonthlyFeeStartDate: Date;
    tagMonthlyFeeEndDate: Date;
    tagMonthlyFeeExemptionStartDate: Date;
    tagMonthlyFeeExemptionEndDate: Date;
    contractPaymentMethod: ContractPaymentMethod;
    marketingPlan: MarketingPlan;
    shippingDiscount: number;
    shippingExemptionDays: number;
    creditLimit: number;
    minimumBalanceForAutomaticRecharge: number;
    contractBillingCuttingTypeId: number;
    billingCuttingDays: number;
    billingCuttingMonthDay: number;
    automaticRechargeValue: number;
    paymentTerm: number;
    automaticRechargeService: number;
    tollVoucherAnticipation: boolean;
    tollVoucherAntecipationService: number;
    responsibleAnnouncer: Person[];
    automaticRechargeLimit: number;
    contractBillingCuttingWeekDayTypeId: number;
    contractFee: ContractFee;
}

export interface ContractDualTagDto {
  id: number,
  contractId: number,
  externalId: number,
  dualTagIntegrationTypeId: number,
  dualTagStatus: boolean,
  dualTagMonthlyFee: number,
  dualTagAcquisition: number,
  registrationDate: Date,
  registrationUserId: number,
  changeDate: Date,
  changeUserId: number,
  dualTagMonthlyExemptionStartDate: Date,
  dualTagMonthlyExemptionEndDate: Date
}

export interface ContractDto {
  contract: Contract;
  contractBillingCuttingCycle: any;
  principalPaymentMethodType: number;
  result: any;
}

export enum ContractBillingCuttingType {
  NONE,
  WEEKLY,
  BIWEEKLY,
  MONTLHY,
  INDIVIDUAL,
  CUSTOM,
}
