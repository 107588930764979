<div class="FinancialBlockingUnblockingComponent">
  <header class="container header-page">
    <h1 translate>Financial blocking and unblocking</h1>

    <div class="actions-header-page">
      <button
        class="btn btn-primary"
        id="btn-search-other-contract"
        data-e2e="btn-search-other-contract"
        [routerLink]="'/' + selectProfileFinancialBlockingPosPaidPath"
        translate
      >
        Buscar outro contrato
      </button>
    </div>
  </header>

  <div class="container-loading" *ngIf="isLoading">
    <app-loader
      id="loader-page"
      data-e2e="loader-page"
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    ></app-loader>
  </div>

  <main class="container" *ngIf="!isLoading">
    <section class="contract-infos">
      <article class="contract-infos__name">
        <h6>{{ 'Razão social' }}</h6>
        <p id="info-companyName" data-e2e="info-companyName">{{ contractInfos.companyName }}</p>
      </article>

      <table class="contract-infos__table">
        <tr>
          <th>{{ 'Cód. do Contrato' }}</th>
          <th>{{ 'Cód. de Faturamento' }}</th>
          <th>{{ 'Juros moratórios' }}
            <img
              id="tooltip-juros"
              data-e2e="tooltip-juros"
              src="assets/svg/info.svg"
              alt="Informação"
              placement="top"
              ngbTooltip="
                Em caso de atraso no pagamento, juros mensais serão adicionados ao valor devido,
                aplicados desde a data da mora até o dia em que o pagamento for realizado.
              "
            />
          </th>
          <th>{{ 'Multa moratória' }}
            <img
              id="tooltip-multa"
              data-e2e="tooltip-multa"
              src="assets/svg/info.svg"
              alt="Informação"
              placement="top"
              ngbTooltip="
              Em caso de atraso no pagamento, uma multa mensal será aplicada ao valor devido,
              contada a partir da data da mora até o dia em que o pagamento for realizado.
              "
          />
          </th>
          <th>{{ 'Tags ativas' }}</th>
          <th>{{ 'Tags bloqueadas' }}</th>
          <th>
            {{ 'Prazo de bloqueio' }}
            <img
              id="tooltip-days-toblock"
              data-e2e="tooltip-days-toblock"
              src="assets/svg/info.svg"
              alt="Informação"
              placement="top"
              [ngbTooltip]="
                'Com o bloqueio automático ativo, o contrato bloqueia após ' +
                contractInfos.daysToBlock +
                ' dias sem pagamento pós-vencimento, desbloqueando após quitação das faturas pendentes.'
              "
            />
          </th>
          <th>{{ 'Status' }}</th>
        </tr>

        <tr>
          <td id="info-id" data-e2e="info-id">{{ contractInfos.id }}</td>
          <td id="info-billingcode" data-e2e="info-billingcode">{{ contractInfos.billingCode }}</td>

          <td id="info-juros" data-e2e="info-juros">{{ contractInfos.contractFee.DefaultInterest.value| currency: ' ':'symbol':'0.2-2':'pt-BR'}}% ao mês
            <button
            id="table-data-btn-edit-default-interest"
            [attr.data-e2e]="'table-data-btn-edit-default-interest'"
            class="btn-icon-not-bg"
            type="button"
            (click)="openDefaultInterestEditModal(contractInfos.contractFee.DefaultInterest.value)"
          >
            <img class="btn-icon-not-bg__icon icon-edit" alt="Ícone de editar">
          </button>
          </td>

          <td id="info-multa" data-e2e="info-multa">{{ contractInfos.contractFee.LateFee.value  | currency: ' ':'symbol':'0.2-2':'pt-BR'}}% ao mês
            <button
            id="table-data-btn-edit-late-fee"
            [attr.data-e2e]="'table-data-btn-edit-late-fee'"
            class="btn-icon-not-bg"
            type="button"
            (click)="openLateFeeEditModal(contractInfos.contractFee.LateFee.value)"
            >
              <img class="btn-icon-not-bg__icon icon-edit" alt="Ícone de editar">
            </button>
          </td>


          <td id="info-tags-active" data-e2e="info-tags-active">{{ contractInfos.tagsActiveQuantity }}</td>
          <td id="info-tags-blocked" data-e2e="info-tags-blocked">{{ contractInfos.tagsBlockedQuantity }}</td>
          <td id="info-days-toblock" data-e2e="info-days-toblock">{{ contractInfos.daysToBlock + ' dias após vencimento' }}</td>

          <td [ngSwitch]="contractInfos.contractStatusTypeId" id="info-status" data-e2e="info-status">
            <div class="status-type status-type--active" *ngSwitchCase="1">
              <span class="status-type__items">{{ 'Ativo' }}</span>
            </div>

            <div class="status-type status-type--block" *ngSwitchCase="3">
              <span class="status-type__items">{{ 'Bloqueado' }}</span>
            </div>

            <div class="status-type status-type--default" *ngSwitchDefault>
              <span class="status-type__items">{{ '-' }}</span>
            </div>
          </td>
        </tr>
      </table>
    </section>

    <section class="cards">
      <!-- Configuração de bloqueio financeiro -->
      <mat-card class="card-container">
          <mat-card-header>
              <mat-card-title class="card-container__title">{{ 'Configuração de bloqueio financeiro' }}</mat-card-title>
              <img
                id="tooltip-block-type"
                src="assets/svg/info.svg"
                alt="Informação"
                placement="top"
                ngbTooltip="Com o bloqueio financeiro automático ativo, o contrato será bloqueado e desbloqueado automaticamente. Ao desativar, a ação poderá ser realizada manualmente."
              />
          </mat-card-header>

        <mat-card-content>
          <form [formGroup]="financialBlockConfigForm">
            <mat-card-subtitle class="card-container__subtitle">{{
              'Selecione uma das opções:'
            }}</mat-card-subtitle>

              <mat-radio-group
                class="radio-group__label-black--small-bold"
                formControlName="blockType"
              >
                <mat-radio-button
                  id="radio-block-automatic"
                  data-e2e="radio-block-automatic"
                  value="1"
                  [checked]="this.financialBlockConfigForm.controls.blockType.value === '1'"
                  (change)="onSelectionRadio(1)"
                >
                  {{ 'Bloqueio financeiro automático' }}
                </mat-radio-button>
                <mat-radio-button
                  id="radio-block-manual"
                  data-e2e="radio-block-manual"
                  value="2"
                  [checked]="this.financialBlockConfigForm.controls.blockType.value === '2'"
                  (change)="onSelectionRadio(2)"
                >
                  {{ 'Bloqueio financeiro manual' }}
                </mat-radio-button>
              </mat-radio-group>

            <mat-card-subtitle
              [ngClass]="!hasChangedBlockType ? 'disable' : ''"
              class="card-container__subtitle card-container__subtitle--bold-after"
              >{{ 'Informe o protocolo de solicitação: '
              }}<span>*</span></mat-card-subtitle
            >

              <div class="card-container__required-box">
                <input
                  [ngClass]="!hasChangedBlockType ? 'disable' : ''"
                  class="card-container__input-protocol"
                  [class.is-invalid]="
                    financialBlockConfigForm.controls.protocol.invalid &&
                    financialBlockConfigForm.controls.protocol.touched
                  "
                  id="input-protocol"
                  data-e2e="input-protocol"
                  formControlName="protocol"
                  type="text"
                  placeholder="Informe o protocolo da solicitação"
                  required
                  [mask]="getInputMask()"
                  maxlength="50"
                  minlength="1"
                />

                <button
                  class="btn btn-primary"
                  id="btn-confirm-block-type-change"
                  data-e2e="btn-confirm-block-type-change"
                  [disabled]="!hasChangedBlockType
                    || (hasChangedBlockType && financialBlockConfigForm.controls.protocol.invalid)"
                  (click)="confirmBlockTypeChange()"
                >
                  {{ 'Confirmar' }}
                </button>
              </div>
              <div>
                <app-vmessage
                  *ngIf="
                    financialBlockConfigForm.controls.protocol.invalid &&
                    financialBlockConfigForm.controls.protocol.touched
                  "
                  id="msg-error-input-protocol"
                  data-e2e="msg-error-input-protocol"
                  errorMessage="{{ 'Informe um número de protocolo válido' | translate }}"
                >
                </app-vmessage>
              </div>
            </form>
          </mat-card-content>
      </mat-card>

      <!-- Bloqueio e desbloqueio financeiro manual -->
      <mat-card class="card-container">
        <mat-card-header>
          <mat-card-title
            [ngClass]="isBlockAutomatic ? 'disable' : ''"
            class="card-container__title"
            >{{ 'Bloqueio e desbloqueio financeiro manual' }}</mat-card-title
          >
        </mat-card-header>

          <mat-card-actions class="card-container__btn-actions">
            <button
              class="btn btn-primary"
              id="btn-block-manual"
              data-e2e="btn-block-manual"
              [disabled]="isBlockAutomatic || (!isBlockAutomatic && isBlock)"
              (click)="confirmBlockUnblockContract()"
            >
              {{ 'Bloquear' }}
            </button>

            <button
              class="btn btn-primary"
              id="btn-unblock-manual"
              data-e2e="btn-unblock-manual"
              [disabled]="isBlockAutomatic || (!isBlockAutomatic && !isBlock)"
              (click)="confirmBlockUnblockContract()"
            >
              {{ 'Desbloquear' }}
            </button>
          </mat-card-actions>
      </mat-card>

      <!-- Histórico de bloqueios -->
      <mat-card class="card-container card-container--scroll">
        <mat-card-header>
          <mat-card-title class="card-container__title">{{
            'Histórico de bloqueios'
          }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <table class="table">
            <thead>
              <tr>
                <th
                  [ngClass]="
                    bindOrderByClassBlockLog(tableHeadersBlockLog.protocol)
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Protocolo' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-log-protocol"
                    data-e2e="btn-order-log-protocol"
                    (click)="onOrderByChangeBlockLog(tableHeadersBlockLog.protocol)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClassBlockLog(
                      tableHeadersBlockLog.registrationUserName
                    )
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Usuário' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-log-registration-username"
                    data-e2e="btn-order-log-registration-username"
                    (click)="onOrderByChangeBlockLog(tableHeadersBlockLog.registrationUserName)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClassBlockLog(
                      tableHeadersBlockLog.registrationDate
                    )
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Data' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-log-registration-date"
                    data-e2e="btn-order-log-registration-date"
                    (click)="onOrderByChangeBlockLog(tableHeadersBlockLog.registrationDate)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClassBlockLog(
                      tableHeadersBlockLog.contractBlockType
                    )
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Execução' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-log-block-type"
                    data-e2e="btn-order-log-block-type"
                    (click)="onOrderByChangeBlockLog(tableHeadersBlockLog.contractBlockType)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClassBlockLog(
                      tableHeadersBlockLog.contractStatusTypeId
                    )
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Status' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-log-status-type"
                    data-e2e="btn-order-log-status-type"
                    (click)="onOrderByChangeBlockLog(tableHeadersBlockLog.contractStatusTypeId)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClassBlockLog(tableHeadersBlockLog.reason)
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Motivo' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-log-reason"
                    data-e2e="btn-order-log-reason"
                    (click)="onOrderByChangeBlockLog(tableHeadersBlockLog.reason)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody *ngIf="!isLoading && contractBlockLogDto.length > 0">
              <tr *ngFor="let log of contractBlockLogDto">
                <td class="card-container__table-data"
                  id="table-data-log-protocol"
                  data-e2e="table-data-log-protocol"
                  [innerHTML]="formatSliceString(log.protocol)"
                ></td>

                <td class="card-container__table-data"
                  id="table-data-log-registration-username"
                  data-e2e="table-data-log-registration-username"
                >{{ log.registrationUserName }}</td>

                <td class="card-container__table-data"
                  id="table-data-log-registration-date"
                  data-e2e="table-data-log-registration-date"
                >{{ log.registrationDate }}</td>

                <td class="card-container__table-data"
                  id="table-data-log-block-type"
                  data-e2e="table-data-log-block-type"
                >{{ log.contractBlockType }}</td>

                <td class="card-container__table-data"
                  id="table-data-log-status-type"
                  data-e2e="table-data-log-status-type"
                  [ngSwitch]="log.contractStatusTypeId"
                >
                  <div class="status-type status-type--active" *ngSwitchCase="1">
                    <span class="status-type__items">{{ 'Ativo' }}</span>
                  </div>

                  <div class="status-type status-type--block" *ngSwitchCase="3">
                    <span class="status-type__items">{{ 'Bloqueado' }}</span>
                  </div>

                  <div
                    class="status-type status-type--default"
                    *ngSwitchDefault
                  >
                    <span class="status-type__items">{{ '-' }}</span>
                  </div>
                </td>

                <td class="card-container__table-data"
                  id="table-data-log-reason"
                  data-e2e="table-data-log-reason"
                >{{ log.reason }}</td>
              </tr>
            </tbody>
          </table>

          <div class="card-container__empty-search" *ngIf="!isLoading && contractBlockLogDto.length == 0">
            <p id="msg-log-no-records-found" data-e2e="msg-log-no-records-found">{{ 'No records found' | translate }}</p>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Faturas em atraso -->
      <mat-card class="card-container card-container--scroll">
        <mat-card-header>
          <mat-card-title class="card-container__title">{{
            'Faturas em atraso'
          }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <mat-card-subtitle class="card-container__subtitle">
            Total de faturas em atraso: <b id="total-overdue-bills" data-e2e="total-overdue-bills">{{ contractOverdueBillsDto.length }}</b>
          </mat-card-subtitle>

          <table class="table">
            <thead>
              <tr>
                <th
                  [ngClass]="
                    bindOrderByClassOverdueBills(
                      tableHeadersOverdueBills.billId
                    )
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Fatura' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-bill-id"
                    data-e2e="btn-order-bill-id"
                    (click)="onOrderByChangeOverdueBills(tableHeadersOverdueBills.billId)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClassOverdueBills(
                      tableHeadersOverdueBills.dueDate
                    )
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Vencimento' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-bill-duedate"
                    data-e2e="btn-order-bill-duedate"
                    (click)="onOrderByChangeOverdueBills(tableHeadersOverdueBills.dueDate)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>

                <th
                  [ngClass]="
                    bindOrderByClassOverdueBills(
                      tableHeadersOverdueBills.amount
                    )
                  "
                >
                  <div class="btn-order-table">
                    {{ 'Valor' }}
                  </div>
                  <div class="round-order"
                    id="btn-order-bill-amount"
                    data-e2e="btn-order-bill-amount"
                    (click)="onOrderByChangeOverdueBills(tableHeadersOverdueBills.amount)"
                  >
                    <img src="assets/svg/order-table.svg" alt="Ordenação"/>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody *ngIf="!isLoading && contractOverdueBillsDto.length > 0">
              <tr *ngFor="let bill of contractOverdueBillsDto">
                <td class="card-container__table-data"
                  id="table-data-bill-id"
                  data-e2e="table-data-bill-id"
                >{{ bill.id }}</td>

                <td class="card-container__table-data"
                  id="table-data-bill-duedate"
                  data-e2e="table-data-bill-duedate"
                >{{ bill.dueDate }}</td>

                <td class="card-container__table-data"
                  id="table-data-bill-amount"
                  data-e2e="table-data-bill-amount"
                >{{ bill.amount }}</td>
              </tr>
            </tbody>
          </table>

          <div class="card-container__empty-search" *ngIf="!isLoading && contractOverdueBillsDto.length == 0">
            <p id="msg-bill-no-records-found" data-e2e="msg-bill-no-records-found">{{ 'No records found' | translate }}</p>
          </div>
        </mat-card-content>
      </mat-card>
    </section>
  </main>
</div>
