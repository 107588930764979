export const SEARCH_TYPES: {
  description: string;
  id: number;
  placeholder?: string;
  mask?: string;
  errorText?: string;
  text?: string;
}[] = [
    {
      description: 'Todos',
      id: 8,
      placeholder: 'Digite para buscar',
      mask: '',
      errorText: ''
    },
    {
      description: 'CNPJ',
      id: 1,
      placeholder: 'Digite o CNPJ do contrato',
      mask: '00.000.000/0000-00',
      errorText: 'o CNPJ informado',
      text: 'a placa',
    },
    {
      description: 'Código de Faturamento',
      id: 2,
      placeholder: 'Digite o código de faturamento do contrato',
      mask: '9999999999',
      errorText: 'o código de faturamento informado',
    },
    {
      description: 'Código do Contrato',
      id: 3,
      placeholder: 'Digite o código do contrato',
      mask: '9999999999',
      errorText: 'o código do contrato informado',
    },
    {
      description: 'Placa',
      id: 4,
      placeholder: 'Digite a placa (AAA9999 ou AAA9A99)',
      mask: 'SSS0A00',
      errorText: 'a placa informada',
    },
    {
      description: 'Razão Social',
      id: 5,
      placeholder: 'Digite a razão social do contrato',
      mask: '',
      errorText: 'a razão social informada',
    },
    {
      description: 'Apelido do contrato',
      id: 6,
      placeholder: 'Digite o apelido do contrato',
      mask: '',
      errorText: 'o apelido do contrato informado',
    },
    {
      description: 'Tag',
      id: 7,
      placeholder: 'Digite o serial da tag',
      mask: '9999999999999999',
      errorText: 'a tag informada',
    },

  ];
