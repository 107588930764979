import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {Router, RouterLink} from "@angular/router";
import {NgClass, NgIf, NgStyle} from "@angular/common";
import {Filters} from "@models/filter.model";
import {CustomDirectivesModule} from "@directives/custom-directives.module";
import {CONTRACT_STATUS_TYPE} from "../../../constants/contract-status-type";

type Service = 'myFleet' | 'vehicles' | "canceledTags" | "costsRanking" | "totalCosts" | 'vprCredit' | 'vehicleBalance' | 'expediture' | 'vehicle';

@Component({
  selector: 'app-borderless-button',
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLink,
    NgIf,
    NgClass,
    NgStyle,
    CustomDirectivesModule
  ],
  templateUrl: './borderless-button.component.html',
  styleUrl: './borderless-button.component.scss'
})
export class BorderlessButtonComponent implements OnInit {
  private contractStatusDefault = CONTRACT_STATUS_TYPE.BLOCKED_FINANCIAL;

  @Input() customWidthSize!: any;

  @Input() buttonColor: string = '';
  @Input('id-html') idHtml: string = '';
  @Input('data-e2e') dataE2E: string = '';
  @Input() description: string = '';
  @Input() routerLink: string = '';
  @Input() classIconLeft: string = '';
  @Input() classIconRight: string = '';
  @Input() service: Service = null;
  @Input() filters: Filters = null;

  @Input() isInlineSearchBtn: boolean = false;
  @Input() isBtnWithBorder: boolean = false;
  @Input() isDisable: boolean = false;
  @Input() verifyContractBlock: boolean = false;
  @Input() verifyDualTag: boolean = false;
  @Input() listVerifyContractBlock: CONTRACT_STATUS_TYPE[] = [this.contractStatusDefault];

  @Output() emitterEvtClick: EventEmitter<any> = new EventEmitter();

  protected classCustom: string = '';

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.handleClassByInput();
    this.handleStyleByInput();
  }

  protected handleEventClick(): void {
    if (this.routerLink.length > 0) {
      this.router.navigate([this.routerLink]);
    }
    this.emitterEvtClick.emit(true);
  }

  protected handleClassByInput(): void {

    let classCustom = this.isBtnWithBorder ? 'button-with-border' : 'button-without-border';

    switch (this.buttonColor) {
      case 'primary':
        classCustom = classCustom + ' button-primary';
        break;
      case 'secundary':
        classCustom = classCustom + ' button-secundary';
        break;
      case 'tertiary':
        classCustom = classCustom + ' button-tertiary';
        break;
      case 'fourth':
        classCustom = classCustom + ' button-fourth';
        break;
      default:
        // Primary
        classCustom = classCustom + ' button-primary';
        break;
    }

    if(this.isInlineSearchBtn) {
      classCustom = classCustom + ' inline-search-btn';
    }

    this.classCustom = classCustom;
  }

  protected handleStyleByInput(): any {
    let customStyle: any = '';

    if (this.customWidthSize) {
      customStyle = {'width': this.customWidthSize};
    }

    return customStyle;
  }
}
