import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatCurrency, formatDate } from '@angular/common';

import { map } from 'rxjs/operators';

import { environment } from '@env';
import { ContractBlockLogArguments } from '../dtos/contract-block-log.arguments';
import { ContractBlockLogDto } from '../dtos/contract-block-log.dto';
import { ContractBillDto } from '../dtos/contract-bill.dto';
import { UpdateBlockTypeDto } from '../dtos/update-block-type.dto';
import { BlockUnblockContractDto } from '../dtos/block-unblock-contract.dto';
import { Dto } from '@models/dto.model';
import { FiancialBlockResponseDto } from '../dtos/financial-block-response.dto';
import { ContractBillArguments } from '../dtos/contract-bill.arguments';
import {PostPaidContractBalance} from "@models/old/postpaidcontractbalance.model";
import {TagsDashboardDto} from "@modules/backoffice/financial-blocking-unblocking/dtos/tagsDashboard.dto";
import {ContractDto} from "@models/old/contract.model";

@Injectable({
  providedIn: 'root',
})
export class FinancialBlockingUnblockingService {
  constructor(private _http: HttpClient) {}

  getContractsById() {
    return this._http.get<ContractDto>(
      environment.apiTMCustomer + `/Contract/GetContractById`,
    );
  }

  getTagsDashboard() {
    return this._http.get<TagsDashboardDto>(
      environment.apiTMTag + `/Tag/GetDashboardTag`,
    );
  }

  getPostPaidContractBalance() {
    return this._http.get<PostPaidContractBalance>(
      environment.apiTMSystemAccount + `/api/Contract/GetBalanceByContract`,
    );
  }

  getContractBlockLog(args: ContractBlockLogArguments) {
    return this._http
      .get<ContractBlockLogDto[]>(
        environment.apiTMCustomer + `/Contract/GetContractBlockLog`,
        { params: args.httpParams },
      )
      .pipe(
        map((response) => {
          let newItems: ContractBlockLogDto[] = [];

          response.forEach((item) => {
            let handleItems = {
              ...item,

              registrationDate: formatDate(
                item.registrationDate,
                'dd/MM/yyyy HH:mm:ss',
                'en-US',
              ),
              protocol: item.contractBlockTypeId == 1 ? '-' : item.protocol,
              registrationUserName:
                item.contractBlockTypeId == 1 ? '-' : item.registrationUserName,
              reason: item.contractBlockTypeId == 1 ? '-' : item.reason,
            };

            newItems.push(handleItems);
          });

          return newItems;
        }),
      );
  }

  getListOverdueBillsByContractId(args: ContractBillArguments) {
    return this._http
      .get<ContractBillDto[]>(
        environment.apiTMFinancial + `/api/Bill/GetListBill`,
        { params: args.httpParams },
      )
      .pipe(
        map((response) => {
          let overdueBills: ContractBillDto[] = [];

          response.forEach((bill) => {
            // Seleciona as faturas em atraso
            if (bill.idStatus == 6) {
              bill.dueDate = bill.dueDate.split('T')[0];

              let handleBill = {
                ...bill,

                dueDate: formatDate(bill.dueDate, 'dd/MM/yyyy', 'en-US'),

                amount: formatCurrency(Number(bill.amount), 'pt-BR', 'R$ '),
              };
              overdueBills.push(handleBill);
            }
          });
          return overdueBills;
        }),
      );
  }

  updateContractBlockType(object: UpdateBlockTypeDto) {
    return this._http.post<Dto<FiancialBlockResponseDto>>(
      environment.apiTMCustomer + `/Contract/UpdateContractBlockType`,
      object,
    );
  }

  blockUnblockManualContract(object: BlockUnblockContractDto) {
    return this._http.post<Dto<FiancialBlockResponseDto>>(
      environment.apiTMCustomer + `/Contract/BlockUnblockManualContract`,
      object,
    );
  }

  updateContractFeeByType(ContractId: number, ContractFeeTypeId: number, newValue: number, protocol: string) {
    return this._http.put<Dto<any>>(
      environment.apiTMCustomer + `/api/ContractFee`,
      {
        contractId: ContractId,
        contractFeeTypeId: ContractFeeTypeId,
        newValue: newValue,
        protocol: protocol,
      },
    );
  }
}
