import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, map } from "rxjs";
import { environment } from "@env";

import { TagStockStats } from "@models/statistics.model";
import { NewTag, Tag } from "@models/tag.model";
import { Dto } from "@models/dto.model";
import { LogService } from "./log.service";
import { TagConfirmReceiptCommandRequest } from "@models/old/Request/tagconfirmreceiptcommandrequest.model";
import {
  TagAvailableQueryResult
} from "@modules/customer/myvehicles/components/transfer-vehicle/dtos/tagAvailableQueryResult.dto";

@Injectable({
  providedIn: "root",
})
export class TagService {
  private _baseUrl = environment.apiTMTag;
  private _showErrors = environment.showErrors;

  constructor(private _http: HttpClient, protected _logger: LogService) {}

  /**
   * Adicionar uma nova tag.
   * @param {NewTag} NewTag
   */
  saveTag(entity: NewTag): Observable<Dto<Tag>> {
    return this._http.post<Dto<Tag>>(`${this._baseUrl}/Tag`, entity);
  }

  getTagAvailableByContractId(): Observable<Tag[]> {
    return this._http
      .get<Tag[]>(`${this._baseUrl}/Tag/GetTagAvailable/`);
  }

  /* TAGs disponíveis */
  getTagAvailableBackOfficeByContractId(): Observable<TagAvailableQueryResult[]> {
    return this._http.get<TagAvailableQueryResult[]>(`${this._baseUrl}/Tag/GetTagAvailable/`);
  }

  getCanceledTagsByDate(params?: HttpParams): Observable<TagStockStats> {
    return this._http.get<TagStockStats>(
      `${this._baseUrl}/Tag/GetTotalCanceledTagsByContractId`,
      { params }
    );
  }

  /**
   * Confirmar recebimento de pedido de tag.
   * @param {TagConfirmReceiptCommandRequest} tagrequest
   */
  confirmReceiptTagRequest(
    entity: TagConfirmReceiptCommandRequest
  ): Observable<any> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      };
      const apiUrl = `${this._baseUrl}/api/TagRequest/ConfirmReceiptTagRequest`;
      const body = JSON.stringify(entity);
      const result = this._http.post<any>(apiUrl, body, httpOptions);
      return result;
    } catch (e) {
      if (this._showErrors) {
        this._logger.showError(
          `Erro em 'TaqRequestService - confirmReceiptTagRequest' ${
            e.status
          }: ${JSON.stringify(e.message)}`
        );
      }
    }
  }

  getDashboardTag(params?: HttpParams): Observable<Tag> {
    return this._http.get<Tag>(`${this._baseUrl}/Tag/GetDashboardTag`, { params });
  }

  getTagStock(params?: HttpParams): Observable<TagStockStats[]> {
    return this.getDashboardTag(params).pipe(
      map((tags) => {
        const output = Object.entries(tags).map(([key, value]) => ({
          key,
          value,
        }));
        let newRes: TagStockStats[] = [];

        output.forEach((tag) => {
          const status = {
            availableQuantity: "Tags disponíveis em estoque",
            bonificationQuantity: "Tags bonificadas",
          };

          newRes.push({
            title: status[tag.key],
            amount: tag.value,
            category: tag.key,
          });
        });

        return newRes.filter((tags) => {
          return (
            tags.category !== "activeQuantity" &&
            tags.category !== "blockedQuantity" &&
            tags.category !== "totalQuantity"
          );
        });
      })
    );
  }
}
