<div class="modal-content ">

  <div class="modal-header">
    <div class="box-title">
      <span>Desbloquear TAG</span>
    </div>
    <button type="button" class="close" (click)="close()" id="btn-close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row row-animation">
      <h3 class="font-weight-bold">Tem certeza de deseja desbloquear a tag abaixo:</h3>
      <span class="col-12 row-information">
        O desbloqueio da TAG tem um prazo de até 6 horas para ser<br> efetivado em todos os estabelecimentos do Brasil.
      </span>
    </div>
    <div class="row row-modal-collapse">
      <div class="col-lg-12 col-vehicle-tag">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-lg-4">
            <p>Veículo</p>
            <span></span>
            <b id="licensePlate">{{ vehicle.licensePlate | mask: 'AAA-AAAA' }}</b>
          </div>
          <div class="col-lg-4">
            <p>TAG</p>
            <span></span>
            <b id="serialTag">{{ vehicle.serialTag }} </b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-gray">
    <app-borderless-button [description]="'Cancelar'"
                           [id-html]="'btn-cancel'"
                           [data-e2e]="'btn-cancel'"
                           [buttonColor]="'secundary'"
                           [isDisable]="onSaving"
                           [isBtnWithBorder]="true"
                           (emitterEvtClick)="close()">
    </app-borderless-button>
    <app-borderless-button [description]="'Confirmar desbloqueio'"
                           [id-html]="'btn-confirm'"
                           [data-e2e]="'btn-confirm'"
                           [buttonColor]="'primary'"
                           [isDisable]="onSaving"
                           [isBtnWithBorder]="true"
                           [classIconLeft]="'ico-padlock-opened'"
                           (emitterEvtClick)="unlockTag()">
    </app-borderless-button>
  </div>
