import { HttpParams } from '@angular/common/http';

export class GetVehicleListQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  search:string;
  HierarchyId:number;
  filter:number;

  get getHttpParams() {
    let httpParams = new HttpParams()
    .append('page', this.page.toString())
    .append('HierarchyId', this.HierarchyId.toString())
    .append('pageSize', this.pageSize.toString())
    .append('sortField', this.orderBy)
    .append('SortOrder', this.desc ? '1' : '0')
    .append('search', this.search)
    .append('filter', this.filter?.toString());

    return httpParams;

  };
}


