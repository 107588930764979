import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { NotificationAlertService } from './notification-alert.service';

@Injectable({
  providedIn: 'root'
})

export class ProgressBarService {

    subscription: Array<Subscription> = [];
    public _width$: Array<BehaviorSubject<number>> = [];

    constructor(private notificationAlertService:NotificationAlertService) {}
 
    _widthObservable(id): Observable<any> { 
     if(!this._width$[id]){
       this._width$[id] = new BehaviorSubject<number>(undefined);
       this._width$[id].next(0);
     }
     return this._width$[id].asObservable() 
    }
 
    initProgressBar(id,timeOut){
       let progressInterval = timeOut / 100;
       this.subscription[id] = interval(progressInterval)
        .subscribe(() => {            
         if(!this._width$[id]){
           this._width$[id] = new BehaviorSubject<number>(undefined);
           this._width$[id].next(0);
         }
         else{
           this._width$[id].next(this._width$[id].getValue()+1)
         }
      
         if(this._width$[id].getValue() >= 100){
           this.close(id);
         }   
       });
    }

    close(id){
      this.subscription[id].unsubscribe(); 
      this.notificationAlertService.close(id);
    }

}