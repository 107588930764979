import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './recover-password.component.html',
  styleUrls: ['../../login.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
