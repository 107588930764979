import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BillHierarchyQueryArguments } from '../dto/bill-hierarchy-query.arguments';
import { BillHierarchyResultDto } from '../dto/bill-hierarchy-result.dto';
import { BillHierarchyBreadCrumbResultDto } from '../dto/bill-hierarchy-bread-crumb-result.dto';
import { BillVehiclesQueryArguments } from '../dto/bill-vehicles-query.arguments';
import { BillVehiclesResultDto } from '../dto/bill-vehicles-result.dto';
import { BillVehiclesTotalQueryArguments } from '../dto/bill-vehicles-total-query.arguments';
import { BillVehiclesTotalResultDto } from '../dto/bill-vehicles-total-result.dto';
import { VehiclesResultDto } from '../dto/vehicles-result.dto';
import { VehiclesQueryArguments } from '../dto/vehicles-query.arguments';





@Injectable({
  providedIn: 'root'
})
export class BillDetailHierarchy {
 
  constructor(protected http: HttpClient) { }

getBillHierarchy(args: BillHierarchyQueryArguments){
    return this.http.get<BillHierarchyResultDto>(environment.apiTMReport +`/Bill/GetBillHierarchy`, {
      params: args.httpParams,
    });
}

getBillVehicles(args: BillVehiclesQueryArguments){
  return this.http.get<BillVehiclesResultDto>(environment.apiTMReport +`/Bill/GetBillVehicle`, {
    params: args.httpParams,
  });
}

getTotalVehicles(args: BillVehiclesTotalQueryArguments){
  return this.http.get<BillVehiclesTotalResultDto>(environment.apiTMReport +`/Bill/GetBillHierarchyVehicleTotal`, {
    params: args.httpParams,
  });
}

getVehicles(args: VehiclesQueryArguments){
  return this.http.get<VehiclesResultDto>(environment.apiTMVehicle +`/Vehicle/GetVehicles`, {
    params: args.httpParams,
  });
}

getHierarchyBreadCrumbList(){
  return this.http.get<BillHierarchyBreadCrumbResultDto>(environment.apiTMVehicle +`/GroupHierarchy/GetHierarchyBreadCrumbList`);
}

}
