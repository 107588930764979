<div class="extractComponent">
  <div class="Dashboard container-fluid page-myaccount">
    <div class="row">
      <div class="">
        <div class="col">
          <app-page-title [title]="'/ Extrato'"></app-page-title>
        </div>
        <div class="page-subtitle">
          <div *modalityType="1">
            Abaixo são exibidas as transações que sensibilizam o saldo do contrato e o saldo de vale pedágio.
          </div>
          <div *modalityType="2">
            Abaixo são exibidas as transações que sensibilizam o limite do contrato e o saldo de vale-pedágio.
          </div>
        </div>
      </div>
    </div>

    <!--row-->
    <div class="row">
      <div class="col-lg-12">
        <div class="tab-content container-repom extractComponentContent" id="myTabContent">
          <div class="tab-pane fade show active" id="nav1" role="tabpanel" aria-labelledby="nav1-tab">
            <div class="row">

              <div class="d-flex row col">
                <div class="btn-group mt-3 mb-5 btn-filters" dropdown [isAnimated]="true" [autoClose]="false">
                <button
                  dropdownToggle
                  data-e2e="filter-dropdown-btn"
                  type="button"
                  id="button-animated"
                  aria-controls="dropdown-animated"
                  class="btn btn-filter dropdown-toggle"
                  [class.no-shadow]="isDatePickerOpen"
                  [class.without-border-bottom-radius]="isOpenDropdown"
                  [class.active]="isOpenDropdown"
                  (click)="toogleDropdown(); hideDate()">
                  <img src="assets/img/filter.svg" class="button-icon" />
                  Filtros
                </button>

                <div class="filters">
                  <ul
                    id="dropdown-animated"
                    *dropdownMenu
                    class="dropdown-menu dropdown-filter"
                    [class.no-shadow]="isDatePickerOpen"
                    role="menu"
                    aria-labelledby="button-animated">
                    <li data-e2e="date-range-picker-btn" role="menuitem" (click)="toggleDateRangePicker()">
                      <div class="simulate-select">
                        <img src="assets/img/ico-calendar.svg" />
                        <span class="simulate-label">Período</span>
                        <input
                          data-e2e="date-range-picker-input"
                          type="text"
                          id="filterDate"
                          placeholder="Selecione"
                          class="simulate-form-control"
                          #dp="bsDaterangepicker"
                          bsDaterangepicker
                          placement="right"
                          [bsValue]="dayCustom"
                          [maxDate]="maxDate"
                          (bsValueChange)="onFilterDate($event)"
                          [minDate]="minDate"
                          [outsideClick]="false">

                        <div class="simulate-button" [class.active]="isShownPeriod">
                          <img src="assets/img/arrow-select.svg" />
                        </div>
                      </div>
                    </li>

                    <li data-e2e="show-type-toggle-btn" role="menuitem" (click)="toggleShowType(); dp.hide()">
                      <div class="simulate-select">
                        <img src="assets/img/hierarchy_select.svg" />
                        <span data-e2e="type-label" class="simulate-label">Tipo</span>

                        <p
                          data-e2e="selected-type-data"
                          class="simulate-input tipo"
                          id="tiposelecionado">
                          {{ tiposelecionado }}
                        </p>

                        <div class="simulate-button" [class.active]="isShowType">
                          <img src="assets/img/arrow-select.svg" />
                        </div>
                      </div>
                    </li>

                    <li data-e2e="value-show-toggle-btn" role="menuitem" (click)="toggleShowValue(); dp.hide()" id="">
                      <div class="simulate-select">
                        <img src="assets/img/hierarchy_select.svg" />

                        <span data-e2e="sign-title" class="simulate-label">Sinal</span>
                        <p data-e2e="selected-sign-data" class="simulate-input tipo" id="sinalselecionado">{{ sinalselecionado }}</p>

                        <div class="simulate-button" [class.active]="isShownValue">
                          <img src="assets/img/arrow-select.svg" />
                        </div>
                      </div>
                    </li>

                    <li role="menuitem">
                      <div class="content-button">

                        <app-borderless-button
                          [description]="'Limpar filtros'"
                          [id-html]="'reset-filter-btn'"
                          [data-e2e]="'reset-filter-btn'"
                          [isBtnWithBorder]="true"
                          [buttonColor]="'secundary'"
                          [classIconLeft]="'ico-delete'"
                          (emitterEvtClick)="resetFilters(); toggleDateRangePicker();"
                        >
                        </app-borderless-button>

                        <button
                          data-e2e="hide-date-btn"
                          id="hideDate"
                          #myDiv
                          hidden
                          class="btn btn-success"
                          (click)="dp.hide()"
                          type="button">
                        </button>
                      </div>
                    </li>
                  </ul>

                  <div class="box-area-datepicker" [hidden]="date != null && !isOpen"
                    [ngClass]="{ show: isShownPeriod, hide: !isShownPeriod }">
                    <div class="content-extend">
                      <form [formGroup]="formPreDate">
                        <div class="datepicker">
                          <h4 data-e2e="period-title" class="text-center mb-2 mt-0">Selecione o período</h4>
                          <div class="d-flex justify-content-around align-items-center">
                            <div class="custom-control custom-radio">
                              <input data-e2e="last-week-radio" type="radio" class="custom-control-input form-check-input" id="defaultGroupExample1"
                                formControlName="date" (click)="setSevenDaysLast()"  value="7">
                              <label data-e2e="last-week-label" class="custom-control-label form-check-label" for="defaultGroupExample1">últimos 7 dias</label>
                            </div>

                            <div class="custom-control custom-radio">
                              <input data-e2e="last-biweek-radio" type="radio" checked class="custom-control-input form-check-input" id="defaultGroupExample2"
                                formControlName="date" (click)="setFifteenDaysLast()"
                                value="15">
                              <label data-e2e="last-biweek-label" class="custom-control-label form-check-label" for="defaultGroupExample2">últimos 15 dias</label>
                            </div>

                            <div class="custom-control custom-radio">
                              <input data-e2e="last-month-radio" type="radio" class="custom-control-input form-check-input" id="defaultGroupExample3"
                                formControlName="date" (click)="setThirtyDaysLast()"
                                value="30">
                              <label data-e2e="last-month-label" class="custom-control-label form-check-label" for="defaultGroupExample3">últimos 30 dias</label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <form [formGroup]="formExtract">
                    <div class="box-area-select-type" [ngClass]="{ show: isShowType, hide: !isShowType }">
                      <div class="content-extend">
                        <div class="hierarchy">
                          <h4 data-e2e="select-type-title" class="text-center mb-2">Selecione o tipo</h4>

                          <div class="col-lg-12" *modalityType="contractModality.POSTPAID">
                            <ngx-radiobutton-group [(ngModel)]="radioType" [disabled]="disabled"
                              formControlName="radioType">
                              <ngx-radiobutton
                                [attr.data-e2e]="'type-option-' + i"
                                id="typeRadioButton{{ value.id }}"
                                (click)="onFilterTypeClick(value)"
                                *ngFor="let value of PostPaidTypes; let i = index"
                                [value]="value.id">
                                <b [attr.data-e2e]="'type-name-data' + i" id="typeName{{ value.id }}">{{ value.name }}</b>
                              </ngx-radiobutton>
                            </ngx-radiobutton-group>
                          </div>

                          <div class="col-lg-12" *modalityType="contractModality.PREPAID">
                            <ngx-radiobutton-group [(ngModel)]="radioType" [disabled]="disabled"
                              formControlName="radioType">
                              <ngx-radiobutton
                                [attr.data-e2e]="'type-option-' + i"
                                id="typeRadioButton{{ value.id }}"
                                (click)="onFilterTypeClick(value)"
                                *ngFor="let value of PrePaidTypes; let i = index"
                                [value]="value.id">
                                <b [attr.data-e2e]="'type-name-data' + i" id="typeName{{ value.id }}">{{ value.name }}</b>
                              </ngx-radiobutton>
                            </ngx-radiobutton-group>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="box-area-value" [ngClass]="{ show: isShownValue, hide: !isShownValue }">
                      <div class="content-extend">
                        <h4 data-e2e="transaction-sign-title" class="text-center mb-2">Sinal de transação</h4>

                        <div class="col-lg-12">
                          <ngx-radiobutton-group [(ngModel)]="radioSignal" [disabled]="disabled" formControlName="signal">
                            <ngx-radiobutton
                              [attr.data-e2e]="'signal-option-' + i"
                              id="sinalRadioButton{{ value.id }}"
                              (click)="onFilterSinalClick(value)"
                              *ngFor="let value of signal; let i = index"
                              [value]="value.id">
                              <b [attr.data-e2e]="'sign-name-data-' + i" id="sinalName{{ value.id }}">{{ value.name }}</b>
                            </ngx-radiobutton>
                          </ngx-radiobutton-group>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
                <div class="col-3">
                  <div class="dataTables_length" id="DataTables_Table_0_length">
                    <form [formGroup]="formPerPages">
                      <app-outlined-select
                        [beforeTextValue]="'exibir '"
                        [afterTextValue]="' linhas'"
                        [customWidthSize]="'300px'"
                        [disableRipple]="true"
                        formControlName="perPages"
                        [id]="'select-page-size'"
                        [title]="'Quantidade de linhas'"
                        [values]="perPages"
                        (valueChange)="onPageSizeChange($event)">
                      </app-outlined-select>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col d-flex row justify-content-end">

                <div class="col-6">
                  <div class="form-header-search">
                    <app-search-form-inline
                    id="formNumberOrderInput"
                    [placeholder]="'Insira placa'"
                    (keyUpEvent)="onFullNameKeyup($event)"
                    [searchValue]="getExtractQueryArguments.search"
                  ></app-search-form-inline>
                  </div>
                </div>

                <div class="col-3 btn-export">

                  <app-borderless-button
                    [description]="'Exportar'"
                    [id-html]="'downloadButton'"
                    [data-e2e]="'export-btn'"
                    [classIconLeft]="'ico-export'"
                    [buttonColor]="'primary'"
                    [isBtnWithBorder]="true"
                    (emitterEvtClick)="Download()"
                  >
                  </app-borderless-button>
                </div>
              </div>
            </div>

            <div class="container-loading" *ngIf="isDownloading">
              <app-loader [isLoading]="isDownloading" message="{{ 'Download em andamento...'}}"></app-loader>
            </div>

            <div *ngIf="!isDownloading">

            <div class="row" *ngIf="getExtractQueryResult && getExtractQueryResult.itemsByDate.length">
              <app-final-limit [itemByDate]="getExtractQueryResult.itemsByDate[0]" [first]="true"></app-final-limit>
            </div>

            <div class="row row-1">
              <div class="col-lg-12">
                <div data-e2e="description-title" class="titles title-1">
                  DESCRIÇÕES
                </div>

                <div data-e2e="amount-title" class="titles title-2">
                  VALORES (R$)
                </div>
              </div>
            </div>

            <!-- // TODO: Verificar a possibilidade de separar essa table em um componente para ficar mais organizada -->
            <table class="table table-extract">
              <thead class="thead">
                <tr>
                  <th>
                    <button data-e2e="processing-order-btn" class="btn-order-table" translate>DATA DO <br>
                      processamento</button>
                  </th>
                  <th>
                    <button data-e2e="transaction-type-order-btn" class="btn-order-table" translate>tipo de <br />
                      transação</button>
                  </th>
                  <th>
                    <button data-e2e="transaction-date-order-btn" class="btn-order-table" translate>Data da <br>
                      transação</button>
                  </th>
                  <th width="200px">
                    <button data-e2e="plate-nickname-order-btn" class="btn-order-table" translate>Placa <br> apelido</button>
                  </th>
                  <th>
                    <button data-e2e="category-order-btn" class="btn-order-table" translate>CATEGORIA cobrada</button>
                  </th>
                  <th>
                    <button data-e2e="description-order-btn" class="btn-order-table" translate>Descrição</button>
                  </th>
                  <th>
                    <button data-e2e="transaction-amount-order-btn" class="btn-order-table" translate>valor da <br>
                      transação</button>
                  </th>
                  <th width="208px">
                    <button data-e2e="used-order-btn" class="btn-order-table" translate>utilizado de <br> vale-pedágio</button>
                  </th>
                  <th width="10%">
                    <button data-e2e="final-amount-order-btn" class="btn-order-table" translate>valor final</button>
                  </th>
                  <th>
                    <button *modalityType="1" data-e2e="updated-amount-order-btn" class="btn-order-table" translate>saldo atualizado<br> após transação</button>
                    <button *modalityType="2" data-e2e="updated-amount-order-btn" class="btn-order-table" translate>gasto atualizado<br> após transação</button>
                  </th>
                </tr>
              </thead>

              <div class="container-loading" *ngIf="isLoading">
                <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
              </div>

              <tbody>
                <ng-container *ngFor="let itemByDate of getExtractQueryResult.itemsByDate; let i = index">
                  <tr [attr.data-e2e]="'final-limit-' + i" *ngIf="i > 0">
                    <td class="app-final-limit" colspan="10">
                      <app-final-limit [itemByDate]="itemByDate" [first]="false"></app-final-limit>
                    </td>
                  </tr>

                  <tr [attr.data-e2e]="'transaction-item-by-date-' + i" *ngFor="let item of itemByDate.items; let in = index">
                    <td [attr.data-e2e]="'transaction-processing-date-' + i" id="processingDate{{in}}">{{ item?.processingDate | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td [attr.data-e2e]="'transaction-type-' + i" id="transactionType{{in}}">{{ item?.transactionType }}</td>
                    <td [attr.data-e2e]="'transaction-date-' + i" id="transactionDate{{in}}">
                      {{ item?.transactionTypeId !== 11 &&
                        item?.transactionTypeId !== 12 &&
                        item?.transactionTypeId !== 18 &&
                        item?.transactionTypeId !== 19 &&
                        item?.transactionTypeId !== 20 &&
                        item?.transactionTypeId !== 21 &&
                        item?.transactionTypeId !== 23
                        ? (item?.transactionDate | date:'dd/MM/yyyy HH:mm:ss')
                        : ''
                      }}</td>
                    <td [attr.data-e2e]="'transaction-plate-' + i" id="licensePlate{{in}}">{{ (item?.licensePlate ? (item?.licensePlate | mask: 'AAA-AAAA') : '')  }}
                      <div [attr.data-e2e]="'transaction-nickname-' + i" id="nickname{{in}}">
                        {{ (item?.nickname ? item?.nickname : '') }}
                      </div>
                    </td>
                    <td [attr.data-e2e]="'transaction-axel-' + i">
                        {{
                          item?.transactionTypeId == 6 || item?.transactionTypeId == 9 && item?.axleWheelType ?
                          item?.axleWheelType : ''
                        }}
                    </td>
                    <td [attr.data-e2e]="'transaction-description' + i" [ngClass]="item?.transactionTypeId == 15 || item?.transactionTypeId == 16 ? 'breakline' : ''">{{ generateDescription(item) }}</td>
                    <td [attr.data-e2e]="'transaction-amount-' + i" class="p1" id="transactionAmount{{in}}">{{ item?.transactionAmount | currency:'BRL' : 'symbol' : '0.2-2' : 'pt-BR' }}</td>
                    <td [attr.data-e2e]="'transaction-toll-voucher-' + i" id="tollVoucher{{in}}" class="p1">
                        {{
                           item?.transactionTypeId == 6 || item?.transactionTypeId == 9 || item?.tollVoucher > 0 && item?.tollVoucher ?
                           (item?.tollVoucher | currency:'BRL' : 'symbol':'0.2-2':'pt-BR') :
                           ''
                        }}
                    </td>
                    <td [attr.data-e2e]="'transaction-value-' + i" class="p1" id="value{{ in }}" >{{ item?.value | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}</td>
                    <td [attr.data-e2e]="'transaction-balance-after-transaction-' + i" class="p2" id="balanceAfterTransaction{{in}}">
                      {{ item?.balanceAfterTransaction | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}
                    </td>
                  </tr>
                </ng-container>
                <!--
                 <tr>
                  <td class="app-final-limit" colspan="10">
                    <app-final-limit></app-final-limit>
                  </td>
                </tr>-->
              </tbody>
            </table>

            <div *ngIf="getExtractQueryResult.total == 0">
              <div data-e2e="no-record-msg" colspan="12" class="text-center w-100 py-4" translate> Nenhum resultado encontrado do dia
                <span data-e2e="record-starting-date" id="FromDate">{{ getExtractQueryArguments.FromDate | date:'dd/MM/yyyy' }}</span> até
                <span data-e2e="record-ending-date" id="ToDate">{{ getExtractQueryArguments.ToDate | date:'dd/MM/yyyy' }}</span></div>
            </div>

            <div class="pagination-custom" *ngIf="getExtractQueryResult.total != 0">
              <app-pagination
                [totalItems]="getExtractQueryResult.total"
                [itemsPerPage]="getExtractQueryArguments.pageSize"
                [maxPages]="5"
                [currentPage]="getExtractQueryArguments.page"
                (pageChangeEvent)="onPaginationChange($event)"
              ></app-pagination>
            </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav2" role="tabpanel" aria-labelledby="nav2-tab"></div>
          <div class="tab-pane fade" id="nav3" role="tabpanel" aria-labelledby="nav3-tab"></div>
        </div>
      </div>
    </div>
  </div>
</div>
