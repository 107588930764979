<div class="arrow-up"></div>

<div class="popover-details">
  <div class="details-sidebar">
    <div class="notification-box">
      <div>
        <span [attr.data-e2e]="'popover-order-label-' + id">Pedido</span><br />
        <span [attr.data-e2e]="'popover-order-' + id" class="id">
          {{ order.id }}
        </span>
      </div>

      <div>
        <span [attr.data-e2e]="'popover-contract-label-' + id">Contrato</span
        ><br />
        <span [attr.data-e2e]="'popover-contract-' + id" class="id">
          {{ order.contractId }}
        </span>
      </div>
    </div>

    <button
      [attr.data-e2e]="'order-details-btn-' + id"
      (click)="redirectToRequestDetail(order)"
      class="btn btn-primary btn-request"
    >
      Ver Pedido
    </button>
  </div>

  <div class="details-body">
    <div>
      <h6 [attr.data-e2e]="'popover-recipient-label-' + id">
        Responsável pelo recebimento:
      </h6>
      <p [attr.data-e2e]="'popover-recipient-' + id">
        {{ order.receivingResponsibleFullName }}
      </p>
    </div>

    <div>
      <h6 [attr.data-e2e]="'popover-phone-label-' + id">
        Telefone para contato:
      </h6>
      <p [attr.data-e2e]="'popover-phone-' + id">
        ({{ order.receivingResponsibleAreaCode }})
        {{ order.receivingResponsibleTelephoneNumber | mask: '00000-0000' }}
      </p>
    </div>

    <div>
      <h6 [attr.data-e2e]="'popover-email-label-' + id">E-mail:</h6>
      <p
        [attr.data-e2e]="'popover-email-' + id"
        *ngFor="let email of order.emailsAddress"
      >
        {{ email }}
      </p>
    </div>

    <div>
      <h6 [attr.data-e2e]="'popover-address-label-' + id">Endereço:</h6>
      <p [attr.data-e2e]="'popover-address-' + id">
        {{ order.addressStreetAddress }}, {{ order.addressNumber }},
        {{ order.addressStreetAddressLine2 }}, {{ order.addressDistrict }},
        {{ order.addressCity }} - {{ order.addressFederatedUnit }} - CEP:
        {{ order.addressZipCode | mask: '00.000-000' }}
      </p>
    </div>

    <div>
      <h6 [attr.data-e2e]="'popover-login-label-' + id">Login solicitante:</h6>
      <p [attr.data-e2e]="'popover-login-' + id">
        {{
          order.responsibilityRequestEmail != null
            ? '- ' + order.responsibilityRequestEmail
            : ''
        }}
      </p>
    </div>

    <div>
      <h6 [attr.data-e2e]="'popover-tax-id-label-' + id">CNPJ:</h6>
      <p [attr.data-e2e]="'popover-tax-id-' + id">
        {{ order.documentNumber | mask: 'CPF_CNPJ' }}
      </p>
    </div>
  </div>
</div>
