import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseDto } from '@models/responseDto.model';
import { GroupsCurrentUser } from '@models/group-user.model';
import { ContractInfosDto } from '@models/contract-infos.dto';
import { GetContractsBySearchFieldParams } from '@models/get-contracts-by-searchfield-params.model';
import { Dto } from '@models/dto.model';
import { Observable } from 'rxjs/internal/Observable';
import { CurrentUser } from '@models/old/currentuser.model';

@Injectable({
  providedIn: 'root'
})
export class SelectContractsService {

  constructor(protected http: HttpClient) { }

  getContracts() {
    return this.http.get<ContractInfosDto[]>(
      `${environment.apiTMCustomer}/Contract/GetContractsByCurrentUser`
    );
  }

  putContractName(form) {
    return this.http.put(
      `${environment.apiTMCustomer}/Contract/NameContract`,
      form
    );
  }

  getGroupsByCurrentUser() {
    return this.http.get<ResponseDto<GroupsCurrentUser>>(
      `${environment.apiTMIdentityServer}/CustomerPortal/User/GetGroupsByCurrentUser`
    );
  }

  getLastAccessContracts(groupId: number, numberOfResults: number) {
    var params = new HttpParams()
      .append('GroupId', groupId)
      .append('NumberOfResults', numberOfResults);

    return this.http.get<ContractInfosDto[]>(
      `${environment.apiTMCustomer}/Contract/GetLastAccessContractsByCurrentUser`,
      { params }
    );
  }

  getContractsBySearchField(params: GetContractsBySearchFieldParams) {
    return this.http.get<Dto<ContractInfosDto[]>>(
      `${environment.apiTMCustomer}/Contract/GetContractsBySearchField`,
      { params: params.httpParams }
    );
  }

  userContractAccessAndBond(contractId: number) {
    return this.http.post(
      `${environment.apiTMIdentityServer}/CustomerPortal/User/UserContractAccessAndBond`,
      { contractId }
    );
  }

  getUserContractsBySearchField(params: GetContractsBySearchFieldParams) {
    return this.http.get<Dto<ContractInfosDto[]>>(
      `${environment.apiTMCustomer}/Contract/GetUserContractsBySearchField`,
      { params: params.httpParams }
    );
  }

  // Get Contract Dual Tag Ativo
  getContractDualTag(contractId) {
    return this.http.post<any>(environment.apiTMContract + `/GetDualTagStatus?Token=${environment.tokenDualTag}`, { contractId });
  }

  getCurrentUser(): Observable<CurrentUser>  {
    return this.http
    .get<CurrentUser>(
      `${environment.apiTMCustomer}/Person/PersonCurrentUser`
    )
  }
}
