<div class="tabVehiclesComponent">
  <div class="col-12 d-flex vehicle-section">
    <div class="col-sm-auto card card-tab-vehicles mt-6rem overflow-initial">
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-12 px-0 mr-2 no-right-border">
            <div class="btn-group mt-3 mb-4" dropdown [isAnimated]="true" [autoClose]="false">
              <button dropdownToggle type="button" id="button-animated" aria-controls="dropdown-animated"
                class="btn btn-filter dropdown-toggle" [class.without-border-bottom-radius]="isOpenDropdown"
                [class.active]="isOpenDropdown" (click)="toggleDropdown()">
                <img src="assets/img/filter.svg" class="button-icon" />
                Filtros
              </button>
              <div class="filters">
                <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-filter" role="menu"
                  aria-labelledby="button-animated">
                  <li role="menuitem" (click)="toggleDateRangePicker()">
                    <div class="simulate-select">
                      <img src="assets/img/ico-calendar.svg" />
                      <span class="simulate-label">Período</span>
                      <input id="filterDateVehicles" type="text" placeholder="Selecione" class="simulate-form-control"
                        #dpVehicles="bsDaterangepicker" bsDaterangepicker placement="right"
                        [(bsValue)]="dayCustomVehicles" [maxDate]="maxDate"
                        (bsValueChange)="onFilterDateVehicles($event)" [minDate]="minDate" [outsideClick]="false" />
                      <div class="simulate-button" [class.active]="isShownPeriod">
                        <img src="assets/img/arrow-select.svg" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <form class="input-search">
                      <app-search-form-inline
                        [placeholder]="'Placa, apelido...'"
                        [customWidth]="'294px'"
                        [searchValue]="searchVehicles"
                        (keyUpEvent)="onFullNameKeyup($event)"/>
                    </form>
                  </li>
                  <li role="menuitem">
                    <div class="content-button">

                      <app-borderless-button
                      [buttonColor]="'secundary'"
                      [id-html]="'reset-filter-btn'"
                      [data-e2e]="'reset-filter-btn'"
                      [isBtnWithBorder]="true"
                      [description]="'Limpar filtros'"
                      [classIconLeft]="'ico-delete'"
                      (emitterEvtClick)="resetFilters()">
                      >

                      </app-borderless-button>
                      <button id="hideVehicleDate" #myDiv hidden class="btn btn-success" (click)="dpVehicles.hide()"
                        type="button"></button>
                      <button id="showVehicleDate" #myDiv2 hidden class="btn btn-success" (click)="dpVehicles.show()"
                        type="button"></button>
                    </div>
                  </li>
                </ul>
                <div class="box-area-datepicker" [hidden]="date != null && !isOpen"
                  [ngClass]="{ show: isShownPeriod, hide: !isShownPeriod }">
                  <div class="content-extend">
                    <form [formGroup]="formPreDateVehicles">
                      <div class="datepicker">
                        <h4 class="text-center mb-2 mt-0">Selecione o período</h4>
                        <div class="d-flex justify-content-around align-items-start">
                          <mat-radio-group class="custom-control custom-radio">
                           @for(date of datePickerDates; track date;) {
                            <mat-radio-button  class="custom-control-input" id="defaultGroupExample4"
                            formControlName="dateVehicles" [value]="date" (click)="setDaysLast(date)"
                            name="dateVehicles" />
                          <label class="custom-control-label" for="defaultGroupExample4">últimos {{date}} dias</label>
                           }
                          </mat-radio-group>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="getContractVehiclesQueryResult?.total == 0 && !isSideLoading">
              <p class="text-center font-weight-bold mt-5">
                Não foi encontrado nenhum veículo <br />
                com os filtros selecionados.
              </p>
            </div>

            <ul class="box-vehicles" *ngIf="getContractVehiclesQueryResult?.total > 0 && !isSideLoading">
              <li class="item-vehicle" *ngFor="
                let item of getContractVehiclesQueryResult?.items;
                let i = index
              " [ngClass]="{ active: selected == item }" (click)="
                setContractVehicleId(item.licensePlate); selected = item
              ">
                <div class="info-vehicles">
                  <span id="licensePlate{{ i }}">
                    {{ item?.licensePlate | mask: "AAA-AAAA" }} </span><br />
                  <p id="nickName{{ i }}" *ngIf="item?.nickName !== '' && item?.nickName !== null">
                    {{ item?.nickName }}
                  </p>
                  <p id="hierarchyDescription{{ i }}" *ngIf="
                    item?.hierarchyDescription !== '' &&
                    item?.hierarchyDescription !== null
                  ">
                    /{{ item?.hierarchyDescription }}
                  </p>
                  <span class="red" id="amount{{ i }}">
                    {{ item?.amount | currency: "BRL":"symbol":"0.2-2":"pt-BR" }}
                  </span>
                </div>
                <button class="btn btn-round-icon">
                  <img src="assets/svg/ico-search-dark.svg" />
                </button>
              </li>
            </ul>
            <div class="container-loading mt-5 SideLoading" *ngIf="isSideLoading">
              <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
            </div>
            <div class="tickets-vehicles pagination-custom pagination-custom--side"
              *ngIf="getContractVehiclesQueryResult?.total > 5">
              <app-pagination [totalItems]="getContractVehiclesQueryResult?.total"
                [itemsPerPage]="getContractVehiclesQueryArguments.pageSize" [maxPages]="3"
                [currentPage]="getContractVehiclesQueryArguments.page"
                (pageChangeEvent)="onSidePaginationChange($event)"></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-auto pl-0 mt-negative info-section">
      <div class="card card-query">
        <div class="container-loading mt-5" *ngIf="isLoading">
          <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
        </div>
        <div *ngIf="
          (!getVehiclesQueryResult ||
            getContractVehiclesQueryResult?.total == 0) &&
          !isLoading
        ">
          <p *ngIf="
            !getVehiclesQueryResult && getContractVehiclesQueryResult?.total > 0
          " class="text-center mt-5 font-weight-bold">
            Selecione um veículo ao lado e faça uma busca
          </p>
          <p *ngIf="getContractVehiclesQueryResult?.total == 0" class="text-center mt-5 font-weight-bold">
            Não foi encontrado nenhum veículo com os filtros selecionados.
          </p>
        </div>
        <div *ngIf="
          getVehiclesQueryResult &&
          getContractVehiclesQueryResult?.total !== 0 &&
          !isLoading
        ">
          <div class="row first-level">
            <div class="col-3">
              <span id="DescriptionLicensePlate">{{
                getVehiclesQueryResult?.licensePlate | mask: "AAA-AAAA"
                }}</span><br />
              Apelido:
              <span id="DescriptionNickName">{{
                getVehiclesQueryResult?.nickName
                }}</span><br />
              Tipo:
              <span id="DescriptionType">{{ getVehiclesQueryResult?.type }}</span>
            </div>
            <div class="col-3">
              Tag:
              <span id="DescriptionTag">{{ getVehiclesQueryResult?.tag }}</span><br />
              N. de eixos:
              <span id="DescriptionAxleWheelTypeDescription">{{
                getVehiclesQueryResult?.axleWheelTypeDescription
                }}</span>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-end">
              <img class="pr-2" src="assets/img/hierarchy_select.svg" />
              <p class="HierarchyComplete" id="completeHierarchicalDescription">
                {{ getVehiclesQueryResult?.completeHierarchicalDescription }}
              </p>
              <p class="HierarchyDesc"></p>
            </div>
          </div>
          <div class="row second-level">
            <div class="tickets col-sm-auto">
              <div class="row">
                <div class="col-20-percent col">
                  <a><img src="assets/img/icon_pedagio.svg" /></a>
                  <p>Pedágio</p>
                  <p>(R$)</p>
                  <span id="totalTollPassing" >{{
                    getVehiclesQueryResult?.totalTollPassing
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}</span>
                </div>
                <div class="col-20-percent col">
                  <a><img src="assets/svg/icon_estacionamento.svg" /></a>
                  <p>Estacionamento</p>
                  <p>(R$)</p>
                  <span id="totalParkingPassing">{{
                    getVehiclesQueryResult?.totalParkingPassing
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}</span>
                </div>
              </div>
            </div>
            <div class="reversal col-sm-auto">
              <div class="row">
                <div class="col-20-percent reversalLabel col">
                  <div class="row">
                    <p class="col-sm-auto">ESTORNOS</p>
                    <a class="col-sm-auto"><img src="assets/img/Forward_arrow.svg" /></a>
                  </div>
                </div>
                <div class="col-20-percent col">
                  <a><img src="assets/img/icon_estornopedagio.svg" /></a>
                  <p>Pedágio</p>
                  <p>(R$)</p>
                  <span id="totalTollPassingReversal" >{{
                    getVehiclesQueryResult?.totalTollPassingReversal
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}</span>
                </div>
                <div class="col-20-percent col-sm-auto">
                  <a><img src="assets/img/icon_estornoestacionamento.svg" /></a>
                  <p>Estacionamento</p>
                  <p>(R$)</p>
                  <span id="totalParkingPassingReversal">{{
                    getVehiclesQueryResult?.totalParkingPassingReversal
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}</span>
                </div>
              </div>
            </div>
            <div class="col-20-percent vp col">
              <img src="assets/img/icon_valepedagio.svg" />
              <p>Vale Pedágio</p>
              <p>(R$)</p>
              <span id="totalTollValley">{{
                getVehiclesQueryResult?.totalTollValley
                | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                }}</span>
            </div>
            <div class="col-20-percent total col-sm-auto">
              <p>Valor total do veículo</p>
              <span id="totalVehicleValue" >{{
                getVehiclesQueryResult?.totalVehicleValue
                | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center pt-3 pb-2 m-4">
            <form novalidate>
              <app-outlined-select [title]="'Quantidade de linhas'" [values]="perPages"
                [beforeTextValue]="'exibir '" [afterTextValue]="' linhas'" [customWidthSize]="'250px'"
                [disableRipple]="true" (valueChange)="onSetVehicleFromPageSize($event)">
              </app-outlined-select>
            </form>
          </div>

          <div class="px-4">
            <div class="row">
              <div class="col-custom percent-65 pr-1">
                <div class="header-table">Detalhes</div>
              </div>
              <div class="col-custom percent-35 pl-1">
                <div class="header-table">Valores (R$)</div>
              </div>
            </div>
            <table class="table tickets-vehicles">
              <thead class="thead">
                <tr>
                  <th [ngClass]="bindOrderByClass('Code')">
                    <button class="btn-order-table" (click)="onOrderByChange('Code')">
                      COD
                    </button>
                    <div class="round-order" (click)="onOrderByChange('Code')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('Description')">
                    <button class="btn-order-table" (click)="onOrderByChange('Description')">
                      Tipo de transação
                    </button>
                    <div class="round-order" (click)="onOrderByChange('Description')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('PassengerDate')">
                    <button class="btn-order-table" (click)="onOrderByChange('PassengerDate')">
                      Data da transação
                    </button>
                    <div class="round-order" (click)="onOrderByChange('PassengerDate')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('ProcessingDate')">
                    <button class="btn-order-table" (click)="onOrderByChange('ProcessingDate')">
                      Data do processamento
                    </button>
                    <div class="round-order" (click)="onOrderByChange('ProcessingDate')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('merchantName')">
                    <button class="btn-order-table" (click)="onOrderByChange('merchantName')">
                      Descrição
                    </button>
                    <div class="round-order" (click)="onOrderByChange('merchantName')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('amount')">
                    <button class="btn-order-table" (click)="onOrderByChange('amount')">
                      Transação
                    </button>
                    <div class="round-order" (click)="onOrderByChange('amount')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('VprUser')">
                    <button class="btn-order-table" (click)="onOrderByChange('VprUser')">
                      Utilizado de vale pedágio
                    </button>
                    <div class="round-order" (click)="onOrderByChange('VprUser')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                  <th [ngClass]="bindOrderByClass('Passenger')">
                    <button class="btn-order-table" (click)="onOrderByChange('Passenger')">
                      Cobrado
                    </button>
                    <div class="round-order" (click)="onOrderByChange('Passenger')">
                      <img src="../../../assets/svg/order-table.svg" alt="" />
                    </div>
                  </th>
                </tr>
              </thead>
              <div class="container-loading" *ngIf="isTransactionsLoading">
                <app-loader [isLoading]="isTransactionsLoading" message="{{ 'Carregando...' }}"></app-loader>
              </div>
              <tbody *ngIf="!isTransactionsLoading">
                <tr *ngFor="
                  let itemTransaction of getVehiclesQueryResult?.transactions
                    .transactionItems;
                  let i = index
                ">
                  <td id="transactionChainId{{ i }}">
                    {{ shortCode(itemTransaction?.transactionChainId) }}
                  </td>
                  <td id="transactionType{{ i }}">
                    {{ itemTransaction?.transactionType }}
                  </td>
                  <td id="transactionDate{{ i }}">
                    {{
                    itemTransaction?.transactionDate
                    | date: "dd/MM/yyyy HH:mm:ss"
                    }}
                  </td>
                  <td id="transactionProcessDate{{ i }}">
                    {{
                    itemTransaction?.transactionProcessDate
                    | date: "dd/MM/yyyy HH:mm:ss"
                    }}
                  </td>
                  <td id="merchantName{{ i }}">
                    {{ itemTransaction?.merchantName }}
                  </td>
                  <td class="text-center red" id="amount{{ i }}">
                    {{
                    itemTransaction?.amount
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}
                  </td>
                  <td class="text-center" id="vehicleAmount{{ i }}">
                    {{
                    itemTransaction?.vehicleAmount
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}
                  </td>
                  <td class="text-center red" id="contractAmount{{ i }}">
                    {{
                    itemTransaction?.contractAmount
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="
              (getVehiclesQueryResult?.transactions.total == 0 ||
                getContractVehiclesQueryResult?.total == 0) &&
              !isTransactionsLoading
            ">
              <div colspan="12" class="text-center w-100 py-4" translate>
                Nenhum resultado encontrado do dia
                <span id="startDate">{{
                  getTransactionsVehiclesQueryArguments.startDate
                  | date: "dd/MM/yyyy"
                  }}</span>
                até
                <span id="endDate">{{
                  getTransactionsVehiclesQueryArguments.endDate
                  | date: "dd/MM/yyyy"
                  }}</span>.
              </div>
            </div>
          </div>
          <div class="pagination-custom" *ngIf="
            getVehiclesQueryResult?.transactions.total >
              getTransactionsVehiclesQueryArguments.pageSize &&
            !isTransactionsLoading
          ">
            <app-pagination [totalItems]="getVehiclesQueryResult?.transactions.total"
              [itemsPerPage]="getTransactionsVehiclesQueryArguments.pageSize" [maxPages]="5"
              [currentPage]="getTransactionsVehiclesQueryArguments.page"
              (pageChangeEvent)="onPaginationChange($event)"></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
