import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MasksService {

  constructor() { }

  private isCellPhone(phone): boolean {
    return !!phone && !(phone.length < 13);
  }

  getPhoneMask(phone: string): string {
    return this.isCellPhone(phone)
      ? '+00 (00) 0 0000-0000'
      : '+00 (00) 0000-00009'
  }
}
