import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ChargebackReportModel} from "@models/backoffice/chargeback-report.model.dto";

@Component({
  selector: 'app-chargeback-approved',
  templateUrl: './chargeback-approved.component.html',
  styleUrls: ['./chargeback-approved.component.scss'],
})
export class ChargebackApprovedComponent {
  @Input() transaction: ChargebackReportModel;
  @Input() option: boolean;
  @Input() reason: string;
  @Output() test = new EventEmitter<boolean>();
  isLoading: boolean;
  errorMsg: string;

  constructor(private _modalService: NgbModal) {}

  dismissModal() {
    this.test.emit(true);
    this._modalService.dismissAll();
  }
}
