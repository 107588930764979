import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { TagActivateUploadModalComponent } from '../../../tag-activate-upload-modal/tag-activate-upload-modal.component';
import { BatchManagementService } from '@modules/customer/batch-management/services/batch-management.service';
import { ITagActivateHistoryDto } from '@modules/customer/batch-management/dtos/tag-activate-history.dto';

@Component({
  selector: 'app-batch-tag-file-inconsistencies-modal',
  templateUrl: './batch-tag-file-inconsistencies-modal.component.html',
  styleUrl: './batch-tag-file-inconsistencies-modal.component.scss'
})
export class BatchTagFileInconsistenciesModalComponent {
  @Input() inFileData: ITagActivateHistoryDto;
  @Input() inHasTagsAndVehiclesAvaliable: boolean;
  @Output() outEventReloadRequest: EventEmitter<number> = new EventEmitter();

  isLoading: boolean;

  constructor(
    private _modalService: NgbModal,
    private _batchManagementService: BatchManagementService,
  ) { }

  dismissModal() {
    this._modalService.dismissAll();
  }

  downloadFileInconsistencies() {
    this.isLoading = true;

    this._batchManagementService
      .downloadFileTagActivateInconsistencies(this.inFileData.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });

        element.href = URL.createObjectURL(newBlob);
        element.download = `Arquivo_inconsistencias.csv`;
        element.click();
      });
  }

  redirectUploadModal() {
    this._modalService.dismissAll();

    const modal = this._modalService.open(TagActivateUploadModalComponent);
    modal.componentInstance.inFileId = this.inFileData.id;

    modal.componentInstance.outEventReloadRequest.subscribe(() => {
      //Send Event To Reload Request Tag Activate
      this.outEventReloadRequest.emit(1);
    });
  }
}
