import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { NotAuthorizedComponent } from "./shared/components/auxiliary-page/not-authorized/not-authorized.component";
import { UnderConstructionComponent } from "./shared/components/auxiliary-page/under-construction/under-construction.component";
import {PageService} from "@modules/customer/page/services/page.service";
import {authGuardCustomer} from "./core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "nao-autorizado",
      component: NotAuthorizedComponent,
      canActivate: [authGuardCustomer],
      data: { contractRequired: true },
    },
    { path: "construcao", component: UnderConstructionComponent }
  ]),
  // { path: "**", component: NotfoundComponent }, // esta linha tem que ficar por ultimo.
];


@NgModule({
  imports: [RouterModule.forRoot(routes,  { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
