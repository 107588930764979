<div class="financialBlockModalComponent">
  <div class="modal-header">
    <h4 class="modal-title" translate>Financial block</h4>
    <a class="btn-close" (click)="close()">
      <img src="assets/svg/close.svg" />
    </a>
  </div>

  <div class="blueContainer"></div>
  <div class="line"></div>
  <div class="contractInfo">
    <p data-e2e="contract-description-title" class="name">
      {{ contract.description }}
    </p>
    <p class="contract">
      <span data-e2e="contract-tax-id-title" class="document">{{
        contract?.documentNumber | mask: '00.000.000/0000-00'
      }}</span>
      | Contrato:
      <span data-e2e="contract-id-title" class="id">{{ contract?.id }}</span>
    </p>
  </div>

  <div class="modal-body show row" *modalityType="modalityType.PREPAID">
    <div class="col"><img src="assets/img/contract_block.svg" /></div>
    <div class="col">
      <div data-e2e="warning-title" class="title">
        Seu contrato está bloqueado por falta de saldo.
      </div>

      <div data-e2e="warning-msg" class="message">
        Realize uma nova recarga para continuar utilizando nossos serviços ou
        entre em contato com a Central de Atendimento através do chat ou Fale
        Conosco disponíveis na plataforma.
      </div>
    </div>
  </div>

  <div class="modal-body show row" *modalityType="modalityType.POSTPAID">
    <div class="col"><img src="assets/img/contract_block.svg" /></div>
    <div class="col">
      <div data-e2e="warning-title" class="title">
        Seu contrato está bloqueado por falta de pagamento.
      </div>

      <div data-e2e="warning-msg" class="message">
        Regularize a situação para continuar usando nossos serviços ou entre em
        contato pela nossa central de atendimento através do Chat,
      </div>

      <div data-e2e="warning-email-label" class="message">E-mail:</div>
      <div data-e2e="warning-email" class="email">
        {{ 'suportepedagio@edenred.com.br' }}
      </div>
      <div data-e2e="warning-phone" class="message">
        Ou pela central de atendimento: 4020-8205.
      </div>
    </div>
  </div>

  <div class="modal-footer modal-footer-gray">
    <div class="col-12">
      <button
        type="button"
        data-e2e="close-modal-btn"
        class="btn btn-tertiary mr-4"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        Fechar
      </button>

      <button
        *modalityType="modalityType.PREPAID"
        type="button"
        data-e2e="recharge-page-link"
        (click)="RedirectToPage()"
        class="btn btn-secondary position-relative"
      >
        Regularizar contrato
      </button>

      <button
        *modalityType="modalityType.POSTPAID"
        type="button"
        data-e2e="bill-page-link"
        (click)="RedirectToPage()"
        class="btn btn-secondary position-relative"
      >
        Regularizar fatura
      </button>
    </div>
  </div>
</div>
