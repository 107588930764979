<div class="modal-content">
    <div class="modal-header">
        <div class="box-title"><span>Excluir Veículo</span></div>
        <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body show modal-edit-vehicle">
        <div class="d-flex justify-content-center">
            <b>Tem certeza que deseja excluir o veículo abaixo?</b>
        </div>
        <div class="row row-animation"></div>
        <div class="content-delete">
            <div class="tag-right" *ngIf="vehicle?.tagId">
                <div class="row">
                    <div class="col-lg-12">
                        <p>TAG Vinculada</p>
                        <span class="px12" id="serialTag">{{vehicle?.serialTag}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-content-delete">
            <div class="row">
                <div>
                    <b>
                        APELIDO
                    </b>
                    <p id="nickName">
                        {{ vehicle?.nickName }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-auto">
                    <b>
                        PLACA
                    </b>
                    <p id="licensePlate">
                        {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}
                    </p>
                </div>
                <div class="col-sm-auto">
                    <b>
                        TIPO
                    </b>
                    <p id="descriptionVehicleType">
                        {{ vehicle?.descriptionVehicleTypePT }}
                    </p>
                </div>
                <div class="col-sm-auto">
                    <b>
                        MARCA
                    </b>
                    <p id="descriptionVehicleBrandType">
                        {{ vehicle?.descriptionVehicleBrandTypePt }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div>
                    <b>
                        MODELO
                    </b>
                    <p id="model">
                        {{vehicle?.model}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div>
                    <b>
                        EIXOS
                    </b>
                    <p id="descriptionAxle">
                        {{vehicle?.descriptionAxlePt}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-auto">
                    <b>
                        HIERARQUIA
                    </b>
                    <p id="hierarchyName">
                        {{vehicle?.hierarchyName}}
                    </p>
                </div>
                <div class="col-sm-auto">
                    <b>
                        RNTRC DO AGREGADO
                    </b>
                    <p id="rntrc">
                        {{vehicle?.rntrc}}
                    </p>
                </div>
                <div class="col-sm-auto">
                    <b>
                        CPF/CNPJ DO AGREGADO
                    </b>
                    <p>
                        <span *ngIf="vehicle?.documentNumber?.length == 11" id="documentNumber">
                            {{ vehicle?.documentNumber | mask: '000.000.000-00' }}
                        </span>
                        <span *ngIf="vehicle?.documentNumber?.length == 14" id="documentNumber">
                            {{ vehicle?.documentNumber | mask: '00.000.000/0000-00' }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="exl modal-footer modal-footer-gray">

      <app-borderless-button
        [description]="'Cancelar'"
        [id-html]="'btn-close'"
        [data-e2e]="'btn-close'"
        [isBtnWithBorder]="true"
        [buttonColor]="'secundary'"
        [isDisable]="onSaving"
        (emitterEvtClick)="dismissModal()">
      </app-borderless-button>

      <app-borderless-button
        [description]="'Confirmar exclusão'"
        [id-html]="'confirmBtn'"
        [data-e2e]="'confirmBtn'"
        [isBtnWithBorder]="true"
        [buttonColor]="'primary'"
        [classIconLeft]="'ico-delete'"
        [isDisable]="onSaving"
        (emitterEvtClick)="onDeleteVehicle()"
      >
    </app-borderless-button>

    </div>
</div>
