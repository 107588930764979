import {
  Component,
  OnInit,
  ViewChildren,
  QueryList
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import { HierarchyService } from "./services/hierarchy.service";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { NotifyService } from "@services/notify.service";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";
import { Router } from "@angular/router";
import { SelectValues } from "src/app/shared/select/outlined-select/outlined-select.component";

@Component({
  selector: "app-hierarchy",
  templateUrl: "./hierarchy.component.html",
  styleUrls: ["./hierarchy.component.scss"],
})
export class HierarchyComponent extends AbstractProcessLauncher implements OnInit {

  @ViewChildren('firstHierarchtName') firstHierarchtName: QueryList<any>;
  bsModalRef: BsModalRef;
  isLoading: boolean;
  hierarchySelectList: SelectValues[];
  formHierarchySelect = this.formBuilder.group({
    hierarchySelect: 0,
  });
  isOpenDropdown: boolean;
  array: any[];
  public hierarchySelect = null;
  public Choosehierarchy;
  public containerheight;
  public emptyListHierarchy: boolean;
  public nodeItems;
  public sub$: Subscription;

  constructor(
    private hierarchyService: HierarchyService,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
    protected router: Router
  ) {
    super(router);
  }

  private startRefresh() {
    this.sub$ = this.hierarchyService.refreshTree$.subscribe(() => {
      this.updateTree();
    })
  }

  updateTree() {
    this.getHierarchyTree(this.hierarchySelect);
    this.getHierarchySelect();
  }

  ngOnInit(): void {
    this.startRefresh();
    this.Choosehierarchy = "Escolha um grupo";
    this.array = [];
    this.isOpenDropdown = false
    this.getHierarchyTree();
    this.getHierarchySelect();
  }

  toggleDropdown() {
    this.isOpenDropdown = !this.isOpenDropdown;
  }

  getHierarchySelect() {
    this.isLoading = true;
    this.hierarchyService
      .GetHierarchyList()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: any) => {
          this.getSelect(success);
        },
        (error) => {
          // TODO: Depois que for implementado alertService descomentar a linha de baixo para tratar os retornos das apis
          //this.alertService.danger(error.message);
          console.error("getFromServerError", error);
          return;
        }
      );
  }

  getSelect(hierarchySelectList) {
    if (hierarchySelectList !== null) {
      this.emptyListHierarchy = false;
      let length = hierarchySelectList.length;
      this.hierarchySelectList = hierarchySelectList;
      this.containerheight = length * 50 + 141 + "px";
    } else {
      this.hierarchySelectList = [];
      this.emptyListHierarchy = true;
    }

    this.hierarchySelectList = hierarchySelectList.map((item) => {
      return {
        value: item.hierarchicalLevel,
        label: item.description_PT,
      };
    });
  }

  onChangeHierarchySelect($event : SelectValues) {
    console.log($event)
    this.Choosehierarchy = $event.label;
    this.nodeItems = [];
    this.array = [];
    this.isOpenDropdown = false;
    this.getHierarchyTree($event.value);
  }

  getHierarchyTree(hierarchySelect: number = null) {
    // this.isLoading = true;
    this.hierarchySelect = hierarchySelect;
    this.hierarchyService
      .getGroupHierarchy(hierarchySelect)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      ).subscribe(
        (success: any) => {
          this.array = [];
          this.array.push(success.data.tree);
          this.nodeItems = this.array;
          this.Choosehierarchy = hierarchySelect ? this.nodeItems[0].description_PT : this.Choosehierarchy
        });
  }

  onSaveHierarchy(hierarchicalLevel) {
    this.isLoading = true;
    let ArrayInputs = this.firstHierarchtName.toArray()
    let Input = ArrayInputs.filter(item => item.nativeElement.value !== '')
    let array

    if (typeof Input[0] == 'undefined') {
      this.notifyService.showInfo('Atenção!', 'Digite o nome da primeira hieraraquia!');
    }
    else {
      array = {
        "description_PT": Input[0].nativeElement.value,
        "description_EN": Input[0].nativeElement.value,
        "higherHierarchicalLevel": hierarchicalLevel
      }
      this.hierarchyService
        .addHiearchy(array)
        .pipe(
          finalize(() => {
            this.isLoading = false
          })
        )
        .subscribe(
          (success: any) => {
            this.notifyService.showSuccess('Atenção!', 'Hierarquia adicionada com sucesso!');
          },
          (error) => {
            console.error("getFromServerError", error);
            return;
          }
        );
      Input[0].nativeElement.value = ''
    }
  }


}
