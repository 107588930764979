import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { PaymentService } from "@services/payment.service";
@Injectable({
  providedIn: "root",
})
export class RechargeService {
  private _base = environment.apiTMCustomer;

  constructor(
    protected _http: HttpClient,
    private _paymentService: PaymentService
  ) {}

  putUpdateAutomaticRechargeValue(form: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    const apiUrl = `${this._base}/Contract/UpdateAutomaticRechargeValue`;
    const body = JSON.stringify(form); // -> JSON para Salvar.
    return this._http.put<any>(apiUrl, body, httpOptions).pipe(
      map((res) => {
        this._paymentService.refreshPrepaidConditions$.next(undefined);
        return res;
      })
    );
  }
}
