<div class="RechargeFeeTableComponent">
   <div class="container-loading" *ngIf="isLoading">
      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
    </div>   
   <div class="content-fee" *ngIf="!isLoading">
      <p class="title-fee">Taxa da Recarga via Pix</p>
      <br/>
      <table class="table-fee-pix">
         <thead>
            <tr>
               <th>De</th>
               <th>Até</th>
               <th>Taxa cobrada</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let pixFee of contractPixFee;">
               <td>{{ pixFee.amountMin | currency: "BRL":"symbol":"0.2-2":"pt-BR" }}</td>
               <td>
                  {{ pixFee.amountMax > 0 ? "" : "R$ -"}}
                  {{ pixFee.amountMax | currency: "BRL":"symbol":"0.2-2":"pt-BR" }}
               </td>
               <td>{{ pixFee.percentage | currency: ' ':'symbol':'0.2-2':'pt-BR' }}%</td>
            </tr>           
         </tbody>
      </table>
      <br/>
      <p class="title-fee">Taxa da Recarga via Cartão de Crédito</p>
      <br/>
      <table class="table-recharge-card">
         <tr>
            <td>Taxa fixa para qualquer valor</td>
            <td>{{ this.rechargeFeeCard | currency: ' ':'symbol':'0.2-2':'pt-BR' }}%</td>
         </tr>
      </table>
   </div>
</div>