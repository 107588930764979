export enum ContactTypeId {
    ChangeRegistrationData = 1,
    ContestationPassage = 2,
    ParkingRegistration = 3,
    OtherRequest = 4,
    TollNotOpen = 5,
    RegistrationProblem = 6,
    BillProblem = 7,
    AccessProblem = 8,
    OtherProblem = 9,
    Suggestion = 10,
    UpdateLicensePlateMercosur = 11,
    RegisterNewUser = 12
}

export enum ContactTypeText {
    ChangeRegistrationData = "Alterar dados cadastrais",
    ContestationPassage = "Contestação de passagem",
    ParkingRegistration = "Credenciar estacionamento",
    OtherRequest = "Outros",
    TollNotOpen = "Cancela não abre",
    RegistrationProblem = "Problema de cadastro",
    BillProblem = "Nota Fiscal, boleto ou fatura",
    AccessProblem = "Problema de acesso",
    OtherProblem = "Outros",
    UpdateLicensePlateMercosur = "Atualizar placa mercosul",
    RegisterNewUser = "Cadastrar novo usuário"
}

