import { HttpParams } from '@angular/common/http';

export class TransactionConciliationQueryArguments {
  page: number;
  pageSize: number;
  orderBy: string;
  desc: boolean;
  fromDate: Date;
  toDate: Date;
  stepTransaction?: number;
  refusalReason?: number[];

  constructor() {
    this.page = 1;
    this.pageSize = 10;
    this.orderBy = 'RegistrationDate';
    this.desc = false;
    this.fromDate = null;
    this.toDate = null;
    this.stepTransaction = null;
    this.refusalReason = [];
  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.orderBy);

    if (this.fromDate != null) {
      /*
        The fromDate arrives like: Fri Jun 29 2023 00:00:00
        toISOString() - add 3 hours: 2023-06-29T03:00:00.000Z
        .split('T')[0] - split the Date, storing only the Date: 2023-06-29
      */

      const fromDateISOString = this.fromDate.toISOString().split('T')[0];
      httpAppend = httpAppend.append('fromDate', fromDateISOString);
    }

    if (this.toDate != null) {
      /*
        The toDate arrives like: Fri Jun 29 2023 23:59:59
        toISOString() - add 3 hours: 2023-06-30T02:59:59.000Z
        .split('T')[0] - split the Date, storing only the Date: 2023-06-30
      */
      const toDateISOString = this.toDate.toISOString().split('T')[0];
      httpAppend = httpAppend.append('toDate', toDateISOString);
    }

    if (this.stepTransaction != null && this.stepTransaction >= 0) {
      httpAppend = httpAppend.append(
        'stepTransaction',
        this.stepTransaction.toString(),
      );
    }

    if (this.refusalReason != null || this.refusalReason != undefined) {
      this.refusalReason.forEach(
        (element) =>
          (httpAppend = httpAppend.append('refusalReason', element.toString())),
      );
    }

    return httpAppend;
  }
}
