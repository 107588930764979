<div class="TagReplaceComponent">
  <div class="modal-header shadow-sm">
    <h4 class="modal-title text-uppercase" translate>Transfer vehicle</h4>
    <a class="btn-close" (click)="dismissModal()">
      <img src="assets/svg/close.svg" />
    </a>
  </div>
  <div class="sub-header">
    <div class="box-infos">
      <div class="title-infos">
        <div>Veiculo</div>
        <div>Tag</div>
        <div>Saldo VPR</div>
      </div>
      <div class="font-weight-bold">
        {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}
      </div>
      <div>
        {{ vehicle?.serial }}
      </div>
      <div class="semibold">
        {{
          vehicle?.accountBalance
            | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
        }}
      </div>
    </div>
<!--    <div class="line bollets-line"></div>-->
  </div>
  <div class="position-relative" *ngIf="step == 2">
    <div class="shadow-line"></div>
  </div>
  <div class="modal-body">
    <div class="modal-content col-12 d-flex">
      <div class="step-1" id="confirmacao">
        <div class="modal-body modal-body-secondary">
          <div class="row mb-5">
            <div class="col-lg-4 position-relative">
              <div class="box-infocontract shadow">
                <div>
                  <div>
                    {{ vehicle?.companyName }}
                  </div>
                  <div>
                    CNPJ:
                    {{ vehicle?.documentNumber | mask: '00.000.000/0000-00' }}
                  </div>
                  <div>Contrato: {{ vehicle?.contractId }}</div>
                </div>
              </div>
              <div class="line-arrow-right a bullets"></div>
              <!-- <div class="arrow-right"></div> -->
              <div class="bg-arrow-right"></div>
            </div>
            <div class="col-lg-8 box-right">
              <div class="row box-search-cnpj mt-1">
                <div class="title">CNPJ PARA TRANSFERÊNCIA</div>
                <div class="py-2" *ngIf="step == 1">
                  <form
                    [formGroup]="contractSearchForm"
                    class="d-flex flex-column input-search position-relative my-2"
                    novalidate
                  >
                    <div class="inputfield">
                      <app-search-form-inline
                      [placeholder]="'Digite o CNPJ...'"
                      [mask]="'00.000.000/0000-00'"
                      (keyUpEvent)="loadContractsByDocument($event)"
                      />
                    </div>
                    <div>
                      <app-vmessage
                        *ngIf="
                          contractSearchForm.controls.documentSearch.invalid &&
                          contractSearchForm.controls.documentSearch.touched &&
                          !contractSearchForm
                            ?.get('documentSearch')
                            .hasError('digit')
                        "
                        errorMessage="{{ 'Document is required' | translate }}"
                      >
                      </app-vmessage>
                      <app-vmessage
                        *ngIf="contractAvailables?.length == 0"
                        errorMessage="Nenhuma contrato encontrado"
                      >
                      </app-vmessage>
                      <app-vmessage
                        *ngIf="
                          contractSearchForm
                            ?.get('documentSearch')
                            .hasError('digit')
                        "
                        errorMessage="CNPJ inválido"
                      >
                      </app-vmessage>
                    </div>
                  </form>
                </div>
              </div>
              <div
                class="row col-12 box-result-cnpj pb-4"
                *ngIf="searchContractSuccess"
              >
                <div class="col-6 pl-0">
                  <div>
                    {{ contractSelectedForm?.value.contract?.description }}
                  </div>
                  <div *ngIf="contractSelectedForm?.value.contract?.id">
                    CNPJ:
                    {{
                      contractSelectedForm?.value.contract?.documentNumber
                        | mask: '00.000.000/0000-00'
                    }}
                  </div>
                </div>
                <div class="col-12 pl-0 mt-2 mb-2 text-dark" *ngIf="step == 2">
                  <b
                    >Contrato: {{ contractSelectedForm?.value.contract?.id }}</b
                  >
                </div>
                <div class="col-6" *ngIf="step == 1">
                  <form [formGroup]="contractSelectedForm" class="mt-0 mb-0">
                    <div class="form-group">
                      <select
                        class="form-control form-select"
                        formControlName="contract"
                      >
                        <option value="null">Selecione</option>
                        <option
                          [ngValue]="contract"
                          *ngFor="let contract of contractAvailables"
                        >
                          Contrato {{ contract.id }}
                        </option>
                      </select>
                    </div>
                  </form>
                  <app-vmessage
                    *ngIf="
                      contractSelectedForm?.value.contract?.id ==
                      vehicle?.contractId
                    "
                    errorMessage="Contrato de origem igual ao do destino"
                  >
                  </app-vmessage>
                </div>
              </div>
              <div
                class="row col-12 pl-0 mt-2 mb-2 text-dark manter-tag"
                *ngIf="step == 2"
              >
                <div class="title w-100">
                  <b *ngIf="tagActionForm.value.tagAction == 2">TROCAR TAG</b>
                  <b *ngIf="tagActionForm.value.tagAction == 3">MANTER TAG</b>
                </div>
                <div *ngIf="tagActionForm.value.tagAction == 2">
                  Substituir a tag anterior
                </div>
                <div *ngIf="tagActionForm.value.tagAction == 3">
                  Transferir veículo mantendo a TAG atual
                </div>
                <div class="w-100">
                  <div
                    class="color-primary"
                    *ngIf="tagActionForm.value.tagAction == 2"
                  >
                    TAG: {{ tagForm.value.tagSelect.serial }}
                  </div>
                  <div
                    class="color-primary"
                    *ngIf="tagActionForm.value.tagAction == 3"
                  >
                    TAG: {{ vehicle?.serial }}
                  </div>
                  <div class="color-primary">
                    Saldo VPR:
                    {{
                      vehicle?.accountBalance
                        | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
                    }}
                  </div>
                </div>
              </div>
              <div
                class="row col-12 box-action-tag"
                *ngIf="step == 1 && searchContractSuccess"
              >
                <form
                  [formGroup]="tagActionForm"
                  class="mt-0"
                  *ngIf="contractSelectedForm?.value.contract?.id"
                >
                  <h2>O que deseja fazer com a tag vinculada</h2>
                  <div class="row mt-2">
                    <mat-radio-group
                      aria-label="Select an option"
                      formControlName="tagAction"
                    >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-lg-12 d-flex">
                              <mat-radio-button
                                data-toggle="collapse"
                                data-target="#collapseTags2"
                                aria-expanded="false"
                                aria-controls="collapseTags2"
                                placement="right"
                                value="1"
                              >
                                <div class="title">Sem tag</div>
                                <div class="description">
                                  Cancelar a tag anterior
                                </div>
                              </mat-radio-button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-lg-12 d-flex">
                              <mat-radio-button type="button" value="2">
                                <div class="title">Trocar tag</div>
                                <div class="description">
                                  Substituir a tag anterior
                                </div>
                              </mat-radio-button>
                            </div>
                            <div
                              class="box-tag"
                              *ngIf="tagActionForm.value.tagAction == 2"
                            >
                              <div translate class="title">Available Tags</div>
                              <form [formGroup]="tagForm">
                                <mat-accordion
                                  class="vehiclesByFilterComponent"
                                  *ngIf="listTagAvailable"
                                >
                                  <mat-expansion-panel
                                    (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false"
                                  >
                                    <mat-expansion-panel-header>
                                      <mat-panel-title
                                        data-e2e="available-vehicle-selected-msg"
                                        *ngIf="tagselected == null"
                                      >
                                        Escolher Tag
                                      </mat-panel-title>

                                      <mat-panel-title
                                        data-e2e="available-vehicle-selected-msg"
                                        *ngIf="tagselected != null"
                                      >
                                        Tag selecionada :
                                        <strong>{{
                                          ' ' + tagselected.serial
                                        }}</strong>
                                      </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <!-- panel body-->
                                    <div
                                      class="vehiclesByFilterComponent__buttons-options"
                                    >
                                      <button
                                        class="vehiclesByFilterComponent__buttons-options-choose"
                                        (click)="buttonType(1)"
                                        [class.active]="buttonSelected == 1"
                                      >
                                        Todos
                                      </button>
                                      <button
                                        class="vehiclesByFilterComponent__buttons-options-search"
                                        (click)="buttonType(2)"
                                        [class.active]="buttonSelected == 2"
                                      >
                                        Pesquisar
                                      </button>
                                    </div>
                                    <div
                                      *ngIf="buttonSelected == 2"
                                      class="vehiclesByFilterComponent__search-field"
                                    >

                                      <app-search-form-inline
                                        [placeholder]="'Digite a TAG...'"
                                        [mask]="'9999999999999999999'"
                                        formControlName="searchValue"
                                        [searchValue]="tagSearch"
                                        (keyUpEvent)="onKeyUp($event)"
                                        />
                                    </div>
                                    <mat-radio-group
                                      aria-label="Select an option"
                                      class="radio-group__label-black"
                                      [style.grid-template-columns]="
                                        emptySearchStyle()
                                      "
                                      formControlName="tagSelect"
                                      *ngIf="
                                        (listTagAvailable
                                          | tagfilter: tagSearch) == false
                                          ? emptySearch
                                          : !emptySearch
                                      "
                                      (change)="selectTag($event.value)"
                                    >
                                      <mat-radio-button
                                        *ngFor="
                                          let item of listTagAvailable
                                            | tagfilter
                                              : tagSearch as filteredTags;
                                          let i = index
                                        "
                                        [value]="item"
                                        class="custom-radio"
                                        [attr.data-e2e]="
                                          'available-vehicle-option-' + i
                                        "
                                      >
                                        {{ item.serial }}
                                      </mat-radio-button>
                                    </mat-radio-group>

                                    <div
                                      class="empty-search"
                                      *ngIf="emptySearch"
                                    >
                                      Por favor, informe uma tag ativa que
                                      esteja vinculado a este contrato.
                                    </div>
                                    <div
                                      *ngIf="listTagAvailable.length == 0"
                                      class="empty-search"
                                    >
                                      Este contrato não possui tags ativas
                                      disponíveis
                                    </div>
                                  </mat-expansion-panel>
                                </mat-accordion>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-lg-12 d-flex">
                              <mat-radio-button
                                data-toggle="collapse"
                                data-target="#collapseTags2"
                                aria-expanded="false"
                                aria-controls="collapseTags2"
                                value="3"
                              >
                                <div class="title">Manter tag</div>
                                <div class="description">
                                  Transferir veículo mantendo a TAG atual
                                </div>
                              </mat-radio-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-radio-group>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div *ngIf="step == 2">
            <div class="row box-typeof-services">
              <div class="header">
                <h4 class="text-uppercase" translate>Type of service</h4>
              </div>
              <div class="desc-header col-12">
                Todas as tags vinculadas tem um prazo de até 12h para <br />
                serem ativadas em todas as praças de pedágio do Brasil.
              </div>
            </div>
            <div class="row col-12 mt-4 pr-0 min-width-760">
              <div class="col-7 pl-0">
                <div class="col-12 pl-0 box-transfer-vehicle">
                  <h5 class="font-weight-bold">
                    Deseja alterar o tipo de serviço atual?
                  </h5>
                  <div class="d-flex mt-3">
                    <div class="col-6 pl-0 pr-5">
                      <div class="text-right">TAG</div>
                      <div
                        class="text-right mt-2"
                        *ngIf="tagActionForm.value.tagAction == 2"
                      >
                        {{ tagForm.value.tagSelect.serial }}
                      </div>
                      <div
                        class="text-right mt-2"
                        *ngIf="tagActionForm.value.tagAction == 3"
                      >
                        {{ vehicle?.serial }}
                      </div>
                    </div>
                    <div class="col-6 pr-0 pl-5">
                      <div class="text-center">VEÍCULO</div>
                      <div class="mt-2 text-center">
                        PLACA: {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}
                      </div>
                    </div>
                  </div>
                  <div class="line-arrow-right bullets b"></div>
                </div>
              </div>
              <div class="col-5 box-types-services pr-0">
                <div class="title">TIPOS DE SERVIÇO</div>
                <div class="col-12 options-types">
                  <form [formGroup]="typeServiceForm">
                    <mat-radio-group
                      aria-label="Select an option"
                      formControlName="contractVehicleTagServiceTypeId"
                    >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-lg-12 d-flex bg-option">
                              <mat-radio-button
                                value="item3"
                                data-toggle="collapse"
                                data-target="#collapseTags2"
                                aria-expanded="false"
                                aria-controls="collapseTags2"
                                value="1"
                              >
                                <div class="description">
                                  PEDÁGIO + ESTACIONAMENTO
                                </div>
                              </mat-radio-button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-lg-12 d-flex bg-option mt-2">
                              <mat-radio-button
                                value="item4"
                                data-toggle="collapse"
                                data-target="#collapseTags2"
                                aria-expanded="false"
                                aria-controls="collapseTags2"
                                value="2"
                              >
                                <div class="description">PEDÁGIO</div>
                              </mat-radio-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-radio-group>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-gray d-flex justify-content-between">
    <button
      type="button"
      class="btn btn-tertiary mr-4"
      (click)="dismissModal()"
      translate
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="next()"
      translate
      *ngIf="step == 1"
      [disabled]="
        (tagActionForm.value.tagAction == 2 &&
          tagForm.controls.tagSelect.invalid) ||
        tagActionForm.controls.tagAction.invalid ||
        contractSelectedForm?.value.contract?.id == vehicle?.contractId ||
        isLoading
      "
    >
      <span>
        <i
          *ngIf="onSaving || isLoading"
          class="fa fa-spinner fa-pulse fa-3x fa-fw"
        ></i>
        <span translate *ngIf="tagActionForm.value.tagAction != 1">
          Continue
        </span>
        <span translate *ngIf="tagActionForm.value.tagAction == 1">
          CONFIRMAR TRANSFERÊNCIA DE VEÍCULO
        </span>
      </span>
    </button>

    <div *ngIf="step == 2">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="transferVehicle(tagActionForm.value.tagAction)"
        [disabled]="
          typeServiceForm.controls.contractVehicleTagServiceTypeId.invalid
        "
        *ngIf="!isLoading"
      >
        CONFIRMAR TRANSFERÊNCIA DE VEÍCULO
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        disabled
        *ngIf="isLoading"
      >
        <div translate>
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          Loading...
        </div>
      </button>
    </div>
  </div>
</div>
