import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { TransferSuccessComponent } from './transfer-success/transfer-success.component';
import { NotifyService } from '@services/notify.service';
import {SelectProfileService} from "@modules/backoffice/select-profile/services/select-profile.service";
import {MyvehiclesService} from "@modules/customer/myvehicles/services/myvehicles.service";
import {
  TagAvailableQueryResult
} from "@modules/customer/myvehicles/components/transfer-vehicle/dtos/tagAvailableQueryResult.dto";
import {CpfCnpjValidator} from "@services/form-validators/cpf-cnpj-validator.service";
import {TagService} from "@services/tag.service";

@Component({
  selector: 'app-transfer-vehicle',
  templateUrl: './transfer-vehicle.component.html',
  styleUrls: ['./transfer-vehicle.component.scss'],
})
export class TransferVehicleComponent implements OnInit, OnDestroy {
  @Input() vehicle: any;
  @Output() event: EventEmitter<any> = new EventEmitter();
  public listTagAvailable: TagAvailableQueryResult[];
  public tagsearch: string;

  tagForm: FormGroup;
  contractSearchForm: FormGroup;
  contractSelectedForm: FormGroup;
  tagActionForm: FormGroup;
  typeServiceForm: FormGroup;
  replaceTagActionForm: FormGroup;
  contractAvailables: any;

  onSaving = false;
  isLoading = false;

  searchContractSuccess: boolean;
  contractSelected: boolean;
  loadingInfos: boolean;
  newtag: any;
  tagselected: TagAvailableQueryResult;

  panelOpenState = false;
  buttonSelected: number = 1;
  tagSearch: string;
  emptySearch: boolean;
  constructor(
    private modalService: NgbModal,
    private notifyService: NotifyService,
    private vehiclesService: MyvehiclesService,
    private selectProfileService: SelectProfileService,
    private tagActivationService: TagService,
    private formBuilder: FormBuilder,
  ) {
    this.contractSearchForm = this.formBuilder.group({
      documentSearch: ['', [Validators.required, CpfCnpjValidator.isValidDocument()]],
    });

    this.contractSelectedForm = this.formBuilder.group({
      contract: null,
    });

    this.tagForm = this.formBuilder.group({
      tagSelect: [null, Validators.required],
      searchValue: '',
    });
    this.tagActionForm = this.formBuilder.group({
      tagAction: [null, Validators.required],

    });
    this.typeServiceForm = this.formBuilder.group({
      contractVehicleTagServiceTypeId: [null, Validators.required],
    });
  }
  step = 1;
  ngOnInit(): void {
    this.loadInfos();
    this.getTagAvailableByContractId();
  }

  ngOnDestroy() {
    this.panelOpenState = false;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
  next() {
    if (this.tagActionForm.get('tagAction').value == 1) {
      this.transferVehicleNoTag();
      return;
    }
    this.step++;
  }

  selectTag(item: TagAvailableQueryResult) {
    this.tagselected = item;
    if (this.newtag) {
      this.tagselected = this.listTagAvailable.find(
        (el) => el.serial == this.newtag,
      );
    }
  }

  getTagAvailableByContractId() {
    this.tagActivationService
      .getTagAvailableBackOfficeByContractId()
      .pipe(finalize(() => {}))
      .subscribe(
        (success) => {
          this.listTagAvailable = success;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  loadInfos() {
    this.loadingInfos = true;
    this.vehiclesService
      .GetVehicleTagDetails(this.vehicle.contractVehicleId)
      .pipe(
        finalize(() => {
          this.loadingInfos = true;
        }),
      )
      .subscribe(
        (success) => {
          this.vehicle = success;
          let tagService = success.contractVehicleTagServiceTypeId.toString();
          this.typeServiceForm
            .get('contractVehicleTagServiceTypeId')
            .setValue(tagService);
        },
        (e) => {
          console.error(e);
        },
      );
  }

  searchContract($event) {
    this.contractSelected = false;

    var rq = $event.target.value;

    this.vehiclesService
      .saveContractVehicleTagReplacement(rq)
      .pipe(
        finalize(() => {
          this.onSaving = false;
        }),
      )
      .subscribe(
        (success) => {},
        (e) => {
          console.error(e);
        },
      );
  }

  loadContractsByDocument(document: string) {
    this.searchContractSuccess = false;
    this.isLoading = true;
    this.selectProfileService
      .getContractsAvailables(document)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.contractAvailables = success;
          // this.contractAvailables.splice(this.contractAvailables.indexOf(this.vehicle.contractId), 1);
          this.searchContractSuccess = true;
          if (this.contractAvailables.length == 0) {
            this.notifyService.showInfo(
              'Atenção!',
              'Nenhum contrato encontrado',
            );
          }
          if (this.contractAvailables.length == 1) {
            this.contractSelectedForm.get('contract').setValue(success[0]);
          }
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }
  transferVehicleReplacementNoTag() {
    let contractVehicleTagServiceTypeId: number = +this.typeServiceForm.get(
      'contractVehicleTagServiceTypeId',
    ).value;
    let contract = this.contractSelectedForm.get('contract').value;
    let newTag = this.tagForm.get('tagSelect').value;
    var post = {
      currentContractId: this.vehicle.contractId,
      newContractId: contract.id,
      contractVehicleId: this.vehicle.contractVehicleId,
      currentTagId: this.vehicle.tagId,
      replacementTagId: newTag.id,
      vehicleId: this.vehicle.vehicleId,
      contractVehicleTagServiceTypeId: contractVehicleTagServiceTypeId,
    };
    this.isLoading = true;

    this.vehiclesService
      .onTransferWithTagReplacementAsync(post)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.modalService.dismissAll();
          const modalRef = this.modalService.open(TransferSuccessComponent);
          modalRef.componentInstance.success = success;
          this.event.emit(success);
        },
        (e) => {
          console.error('warning: ', e);
        },
      );
  }

  transferVehicleNoTag() {
    let contract = this.contractSelectedForm.get('contract').value;
    var post = {
      currentContractId: this.vehicle.contractId,
      newContractId: contract.id,
      contractVehicleId: this.vehicle.contractVehicleId,
      vehicleId: this.vehicle.vehicleId,
      tagId: this.vehicle.tagId,
    };
    this.isLoading = true;

    this.vehiclesService
      .onTransferVehicleWithoutTag(post)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.modalService.dismissAll();
          const modalRef = this.modalService.open(TransferSuccessComponent);
          modalRef.componentInstance.success = success;
          this.event.emit(success);
        },
        (e) => {
          console.error(e);
        },
      );
  }
  transferKeepTagVehicle() {
    let contract = this.contractSelectedForm.get('contract').value;
    let contractVehicleTagServiceTypeId: number = +this.typeServiceForm.get(
      'contractVehicleTagServiceTypeId',
    ).value;
    var post = {
      currentContractId: this.vehicle.contractId,
      newContractId: contract.id,
      contractVehicleId: this.vehicle.contractVehicleId,
      vehicleId: this.vehicle.vehicleId,
      tagId: this.vehicle.tagId,
      contractVehicleTagServiceTypeId: contractVehicleTagServiceTypeId,
    };
    this.isLoading = true;
    this.vehiclesService
      .onTransferVehicle(post)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.modalService.dismissAll();
          const modalRef = this.modalService.open(TransferSuccessComponent);
          modalRef.componentInstance.success = success;
          this.event.emit(success);
        },
        (e) => {
          console.error(e);
        },
      );
  }
  transferVehicle(id) {
    if (id == 2) {
      this.transferVehicleReplacementNoTag();
      return;
    }
    this.transferKeepTagVehicle();
  }

  buttonType(button: number) {
    this.buttonSelected = button;
  }

  onKeyUp(event: any) {
    this.tagSearch = event;
    this.searchTag(this.tagSearch);
  }

  searchTag(value: string) {
    let searchResult = this.listTagAvailable.filter((value) => {
      return searchResult;
    });
  }

  emptySearchStyle() {
    return this.emptySearch ? `1fr` : `1fr 1fr 1fr`;
  }
}
