<div class="FeedbackModalComponent">
  <div class="modal-content">
    <div class="modal-header">
      <div class="box-title"><span>{{title}}</span></div>
      <button
        class="close"
        type="button"
        (click)="dismiss()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body">
      <img [src]="imgPath" alt="">
      <p class="subtitle">{{subtitle}}</p>
      <p class="message">{{message}}</p>
    </div>

    <div class="modal-footer modal-footer-gray">
        <button
          (click)="dismiss()"
          class="btn btn-secondary"
          type="button"
        >
          Fechar
        </button>
    </div>
  </div>
</div>
