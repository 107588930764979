import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordModalValidator } from './services/change-password-modal-validator.service';
import { ChangePasswordModalHttpService } from './services/change-password-modal-http.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  @ViewChild('currentPassword') currentPassword: ElementRef;
  @ViewChild('newPassword') newPassword: ElementRef;

  isLoading: boolean;
  step: number = 1;
  returnedValidation = {
    incorrectPassword: {
      status: false,
      message:'A senha está incorreta. Tente novamente.'
    },
    repeatedPassword:{
      status: false,
      message:'A nova senha deve ser diferente das suas últimas 10 senhas cadastradas.'
    }
  }
  showPassword: Array<boolean> = [false,false,false];
  submitted: boolean;
  passwordForm = new FormGroup({
    currentPassword: new FormControl('',[
      Validators.required,
    ]),
    newPassword: new FormControl('',[
      Validators.required,
      ChangePasswordModalValidator.passwordCharacterValidation()
    ]),
    newPasswordConfirmation: new FormControl('',[
      Validators.required,
      ChangePasswordModalValidator.passwordConfirmation()
    ]),
  });

  constructor(private modalService: NgbModal, private changePasswordModalHttpService: ChangePasswordModalHttpService) { }

  ngOnInit(){
    this.controlChange();
  }


controlChange(){
  this.passwordForm.get('newPassword').valueChanges.subscribe((data) => {
    ChangePasswordModalValidator.newPassword$.next(data);
    this.passwordForm.get('newPasswordConfirmation').updateValueAndValidity();
  });
}

  generateErrorMessage(formControl,returnedValidation = null){
    return returnedValidation && returnedValidation.status ? returnedValidation.message : ChangePasswordModalValidator.getMessage(formControl);
  }

  close() {
    this.modalService.dismissAll();
   } 

   confirmPasswordChange(){
     this.submitted = true;
     if(this.passwordForm.invalid){
       return;
     }
     else{
          this.isLoading = true; 
          this.changePasswordModalHttpService
          .putAlterPassword(this.passwordForm.value)
          .pipe(finalize(() => { 
            this.isLoading = false; 
            }))
          .subscribe(
          (success) => {
            this.step = 2;
          },
          (error) => {
           if(error.error.errors.SenhaIncorreta){
             this.returnedValidation.incorrectPassword.status = true;
             this.currentPassword.nativeElement.focus();
           }

           if(error.error.errors.SenhaRepetida){
            this.returnedValidation.repeatedPassword.status = true;
            if(!this.returnedValidation.incorrectPassword.status){
              this.newPassword.nativeElement.focus();
            }

          }

          }
        );
     }
   } 

}
