import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormsModule } from '@angular/forms';
import { SaldovprVeiculosRoutingModule } from './routes/saldovpr-veiculos-routing.module';
import { SaldovprVeiculosComponent } from './saldovpr-veiculos.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedModule } from 'src/app/shared/shared.module';
import {CustomDirectivesModule} from "@directives/custom-directives.module";
import { BorderlessButtonComponent } from 'src/app/shared/components/button/button-without-border/borderless-button.component';
import { SearchFormInlineComponent } from 'src/app/shared/components/search-form-inline/search-form-inline.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OutlinedSelectComponent } from 'src/app/shared/select/outlined-select/outlined-select.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgbModule,
        FormsModule,
        RouterModule,
        SaldovprVeiculosRoutingModule,
        PopoverModule.forRoot(),
        CustomDirectivesModule,
        BorderlessButtonComponent,
        SearchFormInlineComponent,
        OutlinedSelectComponent,
        MatCheckboxModule
    ],
  declarations: [
    SaldovprVeiculosComponent
  ],
  providers: [provideNgxMask()],
})

export class SaldovprVeiculosModule {}
