import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestQueryArguments } from '../dtos/request-query-arguments.dto';
import { RequestQueryResultDto } from '../dtos/request-query-result.dto';
import { saveTrackingCodeDto } from '../dtos/tracking-code.dto';
import { GetAllActiveOrdersQueryArguments } from '../dtos/get-all-active-orders-query.arguments';
import { GetAllCanceledReceivedOrdersQueryArguments } from '../dtos/get-all-canceled-received-orders-query.arguments';
import { AllActiveOrders } from '../dtos/allactiverders.model';
import { map } from 'rxjs/operators';
import { environment } from '@env';
import {TagRequestPrepaid} from "@models/backoffice/tag.model";

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private http: HttpClient) {}

  getOrders(args: RequestQueryArguments) {
    return this.http.get<RequestQueryResultDto>(
      environment.apiTMTag + `/api/TagRequest`,
      {
        params: args.httpParans,
      },
    );
  }

  finalizeSeparation(obj: any) {
    return this.http.post(
      environment.apiTMTag + `/api/TagRequest/FinalizeSeparation`,
      obj,
    );
  }

  saveSeparationTagRequest(obj: any) {
    return this.http.post(
      environment.apiTMTag + `/api/TagRequest/SaveSeparationTagRequest`,
      obj,
    );
  }
  generateTagRequestFiles(id: any) {
    return this.http.post(
      environment.apiTMTag + `/Tag/GenerateTagRequestFiles`,
      id,
    );
  }

  TagRequestUpdateStatus(obj: any) {
    return this.http.post(
      environment.apiTMTag + `/api/TagRequest/TagRequestUpdateStatusSync`,
      obj,
    );
  }

  getPartners() {
    return this.http.get<any[]>(
      environment.apiTMTag + `/api/TagRequest/GetLogisticPartner`,
    );
  }

  getOrderDetails(id: number) {
    return this.http.get<number>(
      environment.apiTMTag + `/Tag/GetOrderDetails?idOrder=` + id,
    );
  }
  GetTagRequestDetails(TagRequestId: number) {
    return this.http.get<number>(
      environment.apiTMTag +
        `/api/TagRequest/GetTagRequestDetails?TagRequestId=` +
        TagRequestId,
    );
  }
  ValidateSerialTagRequest(tagNumber: number) {
    return this.http.get<number>(
      environment.apiTMTag +
        `/api/TagRequest/ValidateSerialTagRequest?SerialTag=` +
        tagNumber,
    );
  }

  saveTrackingCode(saveTrackingCode: saveTrackingCodeDto) {
    return this.http.post(
      environment.apiTMTag + `/api/TagRequest/UpdateTrackingCode`,
      saveTrackingCode,
    );
  }

  getAllActiveOrders(args: GetAllActiveOrdersQueryArguments) {
    return this.http.get<AllActiveOrders[]>(
      environment.apiTMTag + `/Tag/GetAllActiveOrders`,
      {
        params: args.httpParams,
      },
    );
  }

  getAllCanceledReceivedOrders(
    args: GetAllCanceledReceivedOrdersQueryArguments,
  ) {
    return this.http.get<AllActiveOrders[]>(
      environment.apiTMTag + `/Tag/GetAllCanceledReceivedOrders`,
      {
        params: args.httpParams,
      },
    );
  }

  getTagRequestPrepaid() {
    return this.http
      .get<TagRequestPrepaid>(`${environment.apiTMTag}/api/TagRequestPrepaid`)
      .pipe(
        map((res) => {
          const cardFlagIconPath = {
            '1': '../../../assets/img/mastercard.svg',
            '2': '../../../assets/img/hipercard.svg',
            '3': './../../assets/img/visa.svg',
            '4': '../../../assets/img/amex.svg',
            '5': '../../../assets/img/elo.svg',
            '6': '../../../assets/img/dinersclub.svg',
          };

          return {
            ...res,
            cardFlagIconPath:
              cardFlagIconPath[res.creditCardBrandTypeId.toString()],
          };
        }),
      );
  }

  public GetCSVFile(args: RequestQueryArguments) {
    let uri = environment.apiTMTag + `/api/TagRequest/Csv`;
    return this.http.get(uri, {observe: 'response', responseType: 'blob', params: args.httpParans})
    .pipe(map((response) => {
                  let data = {
                       file: new Blob([response.body], {type: response.headers.get('Content-Type')}),
                       filename: response.headers.get('filename'), // pegar nome do arquivo caso content-disposition esteja acessível.
                    }
        return data ;
    }
    ));
  }

}
