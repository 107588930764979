import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentMethodEnum, PaymentOrigin } from '@models/payment.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '@services/payment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-purchase-confirmation-modal',
  templateUrl: './purchase-confirmation-modal.component.html',
  styleUrls: ['./purchase-confirmation-modal.component.scss']
})
export class PurchaseConfirmationModalComponent implements OnInit {

  constructor(public modal: NgbActiveModal, public _paymentService: PaymentService) { 
    this._getProcessingVariable();
  }

  @Input() object: any;
  @Output() event: EventEmitter<any> = new EventEmitter();
  processing: Boolean = false;
  paymentMethodEnum = PaymentMethodEnum;
  paymentOrigin = PaymentOrigin;
  sub$: Subscription;

  ngOnInit(): void {
  }

  _getProcessingVariable(){
    this.sub$ = this._paymentService.processingPaymentTransaction$
    .subscribe((processing) => {
      this.processing = processing;
      if(!processing){
        this.modal.close();
      }
    });
  }

  confirmPurchase(){
    this._paymentService.processingPaymentTransaction$.next(true)
    this.event.emit(this.object.paymentMethodSelected)
  }

}
