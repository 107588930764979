<form [formGroup]="cancelTagForm" class="cancelTagComponent" novalidate>
  <div class="modal-content">
    <div class="modal-header">
      <div class="box-title">
        <span>CANCELAMENTO DE TAG</span>
      </div>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="container-loading" *ngIf="isLoading">
        <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
      </div>
      <div class="modal-body" *ngIf="step != 3 && !isLoading">
        <div class="row row-animation justify-content-center">
          <div class="box-infos">
            <div class="title-infos">
              <div>
                Veiculo
              </div>
              <div>
                Tag
              </div>
              <div>
                Saldo VPR
              </div>
            </div>
            <div class="font-weight-bold licensePlate">
              {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}
            </div>
            <div class="serialTag">
              {{ vehicle?.serialTag }}
            </div>
            <div class="semibold vprBalance">
              {{ balance | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}
            </div>
          </div>

        </div>
        <div class="content-modal" *ngIf="step == 1">
          <div>
            <h1>Deseja excluir o veículo?</h1>
          </div>
          <div class="">
            <mat-radio-group aria-label="Select an option"  formControlName="excluirVeiculoFrota">
              <div class="col-lg-12 d-flex box-options">
                <mat-radio-button value="1">
                  <span class="desc-radio">
                    Sim, excluir o veículo da frota
                  </span>
                </mat-radio-button>
                <mat-radio-button value="2" class="ml-0 mt-2">
                  <span class="desc-radio">
                    Não, manter o veículo para futuras vinculações
                  </span>
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </div>
        <div class="content-modal" *ngIf="step == 2">
          <div>
            <h1>Selecione um motivo</h1>
          </div>
            <div class="">
              <mat-radio-group aria-label="Select an option" formControlName="motivoCancelamento">
                <div class="col-lg-12 d-flex box-options">
                  <mat-radio-button value="1">
                    <span class="desc-radio">
                      Venda do veículo
                    </span>
                  </mat-radio-button>
                  <mat-radio-button value="2" class="ml-0 mt-2">
                    <span class="desc-radio">
                      Veículo parado no pátio
                    </span>
                  </mat-radio-button>
                  <mat-radio-button value="3" class="ml-0 mt-2">
                    <span class="desc-radio">
                      Sinistro
                    </span>
                  </mat-radio-button>
                  <mat-radio-button value="4" class="ml-0 mt-2">
                    <span class="desc-radio">
                      Devolução do veículo a locadora
                    </span>
                  </mat-radio-button>
                  <mat-radio-button value="6" class="ml-0 mt-2">
                    <span class="desc-radio">
                      Insatisfação com a solução
                    </span>
                  </mat-radio-button>
                  <mat-radio-button value="7" class="ml-0 mt-2">
                    <span class="desc-radio">
                      Embarcador trabalha com outra marca de tag
                    </span>
                  </mat-radio-button>
                  <mat-radio-button value="5" class="ml-0 mt-2">
                    <span class="desc-radio">
                      Outro
                    </span>
                  </mat-radio-button>
                  <div class="motivo col-12" *ngIf="cancelTagForm.controls.motivoCancelamento.value == '5'">
                    <p class="col-12">DIGITE O MOTIVO</p>
                      <input type="text"  formControlName="motivoDescricao" class="col-12 desc-text" />
                  </div>
                  <div class="motivo col-12" *ngIf="cancelTagForm.controls.motivoCancelamento.value == '7'">
                    <p class="col-12">DIGITE O EMBARCADOR</p>
                      <input type="text"  formControlName="shipper" class="col-12 desc-text" />
                  </div>
                </div>
              </mat-radio-group>
            </div>
        </div>
      </div>
      <div class="modal-footer-step1 modal-footer modal-footer-gray col-12 justify-content-between" *ngIf="step == 1">

        <app-borderless-button
          [description]="'Cancelar'"
          [id-html]="'btn-cancel'"
          [data-e2e]="'btn-cancel'"
          [isBtnWithBorder]="true"
          [buttonColor]="'secundary'"
          [isDisable]="isLoading"
          (emitterEvtClick)="close()">
        </app-borderless-button>

        <app-borderless-button
          [description]="'Próxima etapa'"
          [id-html]="'btn-next'"
          [data-e2e]="'btn-next'"
          [isBtnWithBorder]="true"
          [buttonColor]="'primary'"
          [isDisable]="isLoading || cancelTagForm.controls.excluirVeiculoFrota.invalid"
          (emitterEvtClick)="next()"
        >
        </app-borderless-button>

      </div>
      <div class="modal-footer-step2 modal-footer modal-footer-gray col-12 justify-content-between" *ngIf="step == 2">

        <app-borderless-button
          [description]="'Cancelar'"
          [id-html]="'btn-cancel'"
          [data-e2e]="'btn-cancel'"
          [isBtnWithBorder]="true"
          [buttonColor]="'secundary'"
          [isDisable]="isLoading"
          (emitterEvtClick)="close()">
        </app-borderless-button>

        <app-borderless-button
          [description]="'Confirmar'"
          [id-html]="'btn-confirm'"
          [data-e2e]="'btn-confirm'"
          [isBtnWithBorder]="true"
          [buttonColor]="'primary'"
          [isDisable]="isLoading || cancelTagForm.invalid"
          (emitterEvtClick)="confirmCancellation()"
          type="submit">
        </app-borderless-button>

      </div>

    <div *ngIf="step == 3 && !isLoading">
      <div class="modal-body">
        <div class="content-modal">
          <div class="col-12 d-flex mt-3">
            <div class="col-4 image-content">
              <img src="/assets/img/bg-place.svg" />
            </div>
            <div class="col-7 mt-4">
              <div class="title">TAG CANCELADA</div>
              <div class="sub-title">
                TAG cancelada {{
                  this.cancelTagForm.value.excluirVeiculoFrota == 1
                  || this.cancelTagForm.value.excluirVeiculoFrota == true ? 'e veículo excluído da frota' :''
                }} com sucesso. Alguns estabelecimentos poderão levar até 6 horas para processar o cancelamento da TAG. Durante esse período ainda poderão existir transações processadas.
              </div>
              <div class="d-flex justify-content-center mt-4 box-infos-success">
                <div class="box-left text-right mr-3">
                  <div>
                    PLACA:
                  </div>
                  <div>
                    TAG CANCELADA:
                  </div>
                </div>
                <div class="box-right">
                  <div>
                    <span id="step3licensePlate">{{ success?.licensePlate | mask: 'AAA-AAAA' }}</span>
                  </div>
                  <div>
                    <span id="step3tag">{{ success?.tagSerial }}</span>
                  </div>
                </div>
              </div>
              <div class="text-center mt-7 box-infos-success">
                <span id ="date"
                >{{ success?.changeDate | date: 'dd/MM/yyyy' }} -
                {{ success?.changeDate | date: 'HH:mm' }}</span> por
                <span id="user">{{ success?.userFullName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-gray col-12 justify-content-center">

        <app-borderless-button
          [description]="'Fechar'"
          [id-html]="'btn-close'"
          [data-e2e]="'btn-close'"
          [isBtnWithBorder]="true"
          [buttonColor]="'secundary'"
          [isDisable]="isLoading"
          (emitterEvtClick)="close()">
        </app-borderless-button>
      </div>
    </div>
  </div>
</form>

