import { Component } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { Order } from "src/app/core/models/order.model";
import { OrderTimelineService } from "@services/order-timeline.service";
import { TagReceiptModalComponent } from "../tag/tag-receipt-modal/tag-receipt-modal.component";
import { OrderTrackingComponent } from "@modules/customer/myorder/components/order-tracking/order-tracking.component";

@Component({
  selector: "app-order-timeline",
  templateUrl: "./order-timeline.component.html",
  styleUrls: ["./order-timeline.component.scss"],
})
export class OrderTimelineComponent {
  order$: Observable<Order>;

  constructor(
    private readonly _service: OrderTimelineService,
    private readonly _modalService: BsModalService,
    private readonly _modal: NgbModal
  ) {
    this.order$ = _service.lastOrder$;
  }

  tryAgainModal(order: Order) {
   /*  const modalRef = this._modal.open(TryAgainModalComponent);
    modalRef.componentInstance.orderStatus = order;
    modalRef.componentInstance.orderDetail = order; */
  }

  trackOrderModal(order: Order) {
    const modalRef = this._modal.open(OrderTrackingComponent);
    modalRef.componentInstance.orderDetail = order;
  }

  confirmTagReceivalModal(order: Order) {
    const bsModalRef = this._modalService.show(TagReceiptModalComponent);
    bsModalRef.content.tagRequestId = order.orderId;
  }
}
