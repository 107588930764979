import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectContractsRoutingModule } from './routes/select-contracts-routing.module';
import { SelectContractsComponent } from './select-contracts.component';
import { EditContractNameModal } from './components/edit-contract-name-modal/edit-contract-name-modal.component';
import { FinancialBlockModalComponent } from './components/financial-block-modal/financial-block-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterModule } from "@modules/commom/footer/footer.module";
import { CardSelectContractsComponent } from '@modules/commom/card-select-contracts/card-select-contracts.component';
import {
    BorderlessButtonComponent
} from "../../../shared/components/button/button-without-border/borderless-button.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxMaskDirective,
        NgxMaskPipe,
        SelectContractsRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        FooterModule,
        CardSelectContractsComponent,
        BorderlessButtonComponent,

    ],
  declarations: [
    SelectContractsComponent,
    EditContractNameModal,
    FinancialBlockModalComponent,
  ],
  providers: [provideNgxMask()]
})

export class SelectContractsModule { }
