import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesPaths } from 'src/app/core/utils/routes/routes-paths';

@Component({
  selector: 'app-card-insert-holidays',
  templateUrl: './card-insert-holidays.component.html',
  styleUrl: './card-insert-holidays.component.scss'
})
export class CardInsertHolidaysComponent {
  @Output() changePage = new EventEmitter<boolean>()

  constructor(private _router: Router){

  }

  backToCalendar() {
    this._router.navigate(['/backoffice/' + RoutesPaths.HOLIDAYS_CALENDAR]);
  }
}
