import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainMenuBackofficeComponent } from '../main-menu-backoffice.component';
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {RoutesPaths} from "../../../../core/utils/routes/routes-paths";
import {authBackOfficeGuard} from "../../../../core/auth/auth-backoffice.guard";
import {
  mainMenuBackofficeResolver
} from "@modules/backoffice/main-menu-backoffice/resolvers/main-menu-backoffice.resolver";

const routes: Routes = [
  RoutesService.childRoutes(
    [
      {
        path: RoutesPaths.MAIN_MENU_BACKOFFICE,
        component: MainMenuBackofficeComponent,
        resolve: {rolesBackOffice: mainMenuBackofficeResolver},
        canActivate: [authBackOfficeGuard],
        data: {
          // title: extract('Menu Inicial'),
          contractRequired: false,
        },
      },
    ],
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainMenuBackofficeRoutingModule { }
