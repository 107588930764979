<main class="chargebackReportComponent">
  <div class="container header-page">
    <app-page-title [title]="'/ Chargeback'"></app-page-title>

    <div class="actions-header-page">
      <form class="input-search">
        <app-search-form-inline
        [placeholder]="'Document Number ou Athorization Code' | translate"
        [searchValue]="queryArguments.search"
        (keyUpEvent)="onFullNameKeyup($event)"
        [customWidth]="'300px'">
      </app-search-form-inline >
      </form>
    </div>
  </div>

  <section class="table-card">
    <div class="table-header">
      <div class="table-header-left">
        <app-filters [hasTransctionDateFilter]="true" [hasTransctionTypeFilter]="true" [hasRangeAmountFilter]="true"
          [transactionTypes]="transactionTypesFilters" (filtersParams)="handleFiltersParams($event)"
          (resetParams)="resetFilters()">
        </app-filters>

        <app-outlined-select
          [title]="'Exibição'"
          name="perPages"
          data-e2e="perPages"
          [values]="perPages"
          [beforeTextValue]="'Exibir '"
          [afterTextValue]=" ' linhas'"
          [disableRipple]="true"
          [id]="'select-input'"
          (valueChange)="onPageSizeChange($event)"
          >
        </app-outlined-select>
      </div>

      <div class="download-button">

        <app-borderless-button [isDisable]="noFiles" [description]="'Download CSV'" [verifyContractBlock]="true"
          [classIconLeft]="'ico-download'" [id-html]="'export-btn'" [data-e2e]="'export-btn'"
          (emitterEvtClick)="Download()">
        </app-borderless-button>
      </div>
    </div>
    <div class="table-main">
      <table class="table">
        <thead class="thead">
          <tr>
            <th *ngFor="let column of tableHeader" [ngClass]="bindOrderByClass(column[0])">
              <div *ngIf="column[0] !== ''" class="btn-order-table" [attr.data-e2e]="'sort-' + column[0] + '-date-btn'"
                translate>
                {{ column[0] }}

                <img *ngIf="column[1] !== ''" src="../assets/svg/info.svg" placement="top" [ngbTooltip]="column[1]"
                  alt="INFORMAÇÃO" />

                <img *ngIf="column[0] == 'Action'" src="../assets/svg/info.svg" placement="top-right"
                  [ngbTooltip]="tipContent" alt="INFORMAÇÃO" tooltipClass="custom-tooltip" />

                <ng-template #tipContent>
                  <b>Aprovar:</b> Ao aprovar o chargeback, o contrato será
                  desbloqueado automaticamente.<br />
                  <br />
                  <b>Reprovar:</b> Ao recusar o chargeback, o contrato irá
                  permanecer bloqueado. <br />
                  <br />
                  <b>Aprovação automática:</b> Caso não haja interação após o
                  prazo para validação, o chargeback será aprovado
                  automaticamente.
                </ng-template>
              </div>

              <div class="round-order" *ngIf="column[0] !== '' && column[0] != 'Action'"
                (click)="onOrderByChange(column[0])">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="!isLoading && DtoTransactions?.totalItems == 0">
          <p class="empty-search-msg" translate>No Transactions Found</p>
        </tbody>

        <tbody *ngIf="!isLoading && DtoTransactions.totalItems !== 0">
          <tr *ngFor="let transaction of DtoTransactions.data; let i = index">
            <td [attr.data-e2e]="'table-sales-' + i">
              <ng-template #popContentActions>
                <div class="container">
                  <div class="row my-1">
                    <div [attr.data-e2e]="'popover-name-label-' + i" class="head-info" translate>
                      {{ popoverHeader[0] }}
                    </div>

                    <div [attr.data-e2e]="'popover-name-' + i" class="content-info">
                      {{ transaction?.companyName }}
                    </div>
                  </div>

                  <div class="row my-1">
                    <div [attr.data-e2e]="'popover-tax-id-label-' + i" class="head-info" translate>
                      {{ popoverHeader[1] }}
                    </div>

                    <div [attr.data-e2e]="'popover-tax-id-' + i" class="content-info">
                      {{ transaction?.contractId }} -
                      {{ transaction?.contractName }}
                    </div>
                  </div>

                  <div class="row my-1">
                    <div [attr.data-e2e]="'popover-invoice-label-' + i" class="head-info" translate>
                      {{ popoverHeader[2] }}
                    </div>

                    <div [attr.data-e2e]="'popover-invoice-' + i" class="content-info">
                      [{{ transaction?.creditCardBrandType }}]******{{
                      transaction?.creditCard
                      }}
                    </div>
                  </div>

                  <div class="row my-1">
                    <div [attr.data-e2e]="'popover-contract-label-' + i" class="head-info" translate>
                      {{ popoverHeader[3] }}
                    </div>

                    <div [attr.data-e2e]="'popover-contract-' + i" class="content-info">
                      {{ transaction?.cardHolder }}
                    </div>
                  </div>

                  <div class="row my-1">
                    <div [attr.data-e2e]="'popover-acquierer-label-' + i" class="head-info" translate>
                      {{ popoverHeader[4] }}
                    </div>

                    <div [attr.data-e2e]="'popover-acquierer-' + i" class="content-info">
                      {{ transaction?.accountBalance }}
                    </div>
                  </div>
                </div>
              </ng-template>

              <button type="button" class="btn-popover" [attr.data-e2e]="'popover-btn-' + i"
                [ngbPopover]="popContentActions" placement="right" popoverClass="custom-popover2"
                [autoClose]="'outside'">
                <img src="../assets/svg/icon-popover.svg" />
              </button>
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i">
              {{ transaction?.documentNumber | mask: '00.000.000/0000-00' }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i">
              {{ transaction?.authorization }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i" translate>
              {{ transaction?.transactionTypeDescription }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i">
              {{ transaction?.transactionDate }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i">
              {{ transaction?.chargebackDate }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i">
              {{ transaction?.validationDeadlineDate }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i">
              {{ transaction?.totalAmount }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i">
              {{ transaction?.chargebackStatus }}
            </td>

            <td [attr.data-e2e]="'table-chargeback-' + i" class="actions">
              <button [disabled]="transaction.chargebackStatus != 'Sem interação'" class="btn btn-primary btn-approval"
                (click)="approveChargeback(transaction, true)">
                Aprovar
              </button>
              <button [disabled]="transaction.chargebackStatus != 'Sem interação'" class="btn btn-primary btn-rejection"
                (click)="approveChargeback(transaction, false)">
                Reprovar
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader [isLoading]="isLoading" message="{{ message | translate }}"></app-loader>
      </div>
    </div>

    <div class="pagination-custom" *ngIf="
    DtoTransactions?.totalItems > queryArguments.pageSize && !isLoading
    ">
      <ngb-pagination (pageChange)="onPaginationChange($event)" [pageSize]="queryArguments.pageSize" [maxSize]="5"
        [collectionSize]="DtoTransactions?.totalItems" [(page)]="queryArguments.page" [rotate]="true"
        [boundaryLinks]="true" [ellipses]="false" display="dynamic">
      </ngb-pagination>
    </div>
  </section>
</main>
