import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetExtractQueryArguments } from '../dtos/getExtractQueryArguments.dto';
import { GetExtractQueryResultDto } from '../dtos/getExtractQueryResult.dto';
import { GetExtractDownloadQueryArguments } from '../dtos/getExtractDownloadQueryArguments.dto';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root',
})
export class ExtractService {
  constructor(private http: HttpClient) {}

  getExtractQuery(args: GetExtractQueryArguments) {
    return this.http.get<GetExtractQueryResultDto>(environment.apiTMSystemAccount + `/api/ContractAccount/Extract`, {
      params: args.httpParans,
    });
  }

  public GetCSVFile(args: GetExtractDownloadQueryArguments) {
    let uri = environment.apiTMSystemAccount + `/api/ContractAccount/ExtractCsv`;
    return this.http.get(uri, {observe: 'response', responseType: 'blob', params: args.httpParans})
    .pipe(map((response) => {
                  let data = {
                       file: new Blob([response.body], {type: response.headers.get('Content-Type')}),                     
                       filename: response.headers.get('filename'), // pegar nome do arquivo caso content-disposition esteja acessível. 
                    } 
        return data ;
    }
    ));
  }	


}
