<div class="UpdateCreditCardModalComponent">
  <div class="modal-content">
    <div class="modal-header">
      <div class="box-title"><span>Atualizar Cartão de crédito</span></div>
      <button class="close" type="button" (click)="modal.close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="container-loading" *ngIf="isLoading">
      <app-loader
        [isLoading]="isLoading"
        message="{{ 'Carregando...' }}"
      ></app-loader>
    </div>

    <div class="modal-body show modal-update-credit-card" *ngIf="!isLoading">
      <div class="title">
        Insira os dados do seu cartão de crédito para recargas
      </div>
      <div class="CreditCardform">
        <div class="safeEnvironmentTag">
          <img src="assets/img/lock_black.svg" /><span>Ambiente seguro</span>
        </div>

        <form class="form row" [formGroup]="CreditCardForm">
          <div class="formfield col-7">
            <label for="card_number">Número do cartão</label>
            <div class="formInput">
              <input
                id="creditCardNumber"
                [mask]="'0000 0000 0000 0000'"
                type="text"
                formControlName="card_number"
              />

              <div
                class="alertErrorValidator"
                *ngIf="
                  (CreditCardForm.controls['card_number']?.invalid &&
                    CreditCardForm.controls['card_number']?.touched) ||
                  (submitted && CreditCardForm.controls['card_number']?.invalid)
                "
              >
                {{
                  generateMessage(CreditCardForm.controls["card_number"].errors)
                }}
              </div>
            </div>
          </div>

          <div class="formfield col-5 form-group">
            <label for="payment_company_code">Bandeira</label>
            <div class="formInput">
              <select
                class="form-control form-select"
                id="creditCardFlag"
                formControlName="payment_company_code"
              >
                <option
                  *ngFor="let creditCardflag of creditCardFlags"
                  value="{{ creditCardflag.value }}"
                >
                  {{ creditCardflag.name }}
                </option>
              </select>
              <div
                class="alertErrorValidator"
                *ngIf="
                  (CreditCardForm.controls['payment_company_code']?.invalid &&
                    CreditCardForm.controls['payment_company_code']?.touched) ||
                  (submitted &&
                    CreditCardForm.controls['payment_company_code']?.invalid)
                "
              >
                {{
                  generateMessage(
                    CreditCardForm.controls["payment_company_code"].errors
                  )
                }}
              </div>
            </div>
          </div>

          <div class="formfield col-12">
            <label for="holder_name">Nome impresso do cartão</label>
            <div class="formInput">
              <input
                id="creditCardName"
                type="text"
                formControlName="holder_name"
                maxlength="50"
              />
              <div
                class="alertErrorValidator"
                *ngIf="
                  (CreditCardForm.controls['holder_name']?.invalid &&
                    CreditCardForm.controls['holder_name']?.touched) ||
                  (submitted && CreditCardForm.controls['holder_name']?.invalid)
                "
              >
                {{
                  generateMessage(CreditCardForm.controls["holder_name"].errors)
                }}
              </div>
            </div>
          </div>

          <div class="formfield col-7">
            <label for="card_expiration">Validade</label>
            <div class="formInput">
              <input
                id="creditCardValidity"
                [mask]="'00/0000'"
                type="text"
                formControlName="card_expiration"
              />
              <div
                class="alertErrorValidator"
                *ngIf="
                  (CreditCardForm.controls['card_expiration']?.invalid &&
                    CreditCardForm.controls['card_expiration']?.touched) ||
                  (submitted &&
                    CreditCardForm.controls['card_expiration']?.invalid)
                "
              >
                {{
                  generateMessage(
                    CreditCardForm.controls["card_expiration"].errors
                  )
                }}
              </div>
            </div>
          </div>

          <div class="formfield col-5">
            <label for="card_cvv">CVV</label>
            <div class="formInput">
              <input
                id="creditCardCvv"
                type="text"
                [mask]="'000'"
                formControlName="card_cvv"
              />
              <div
                class="alertErrorValidator"
                *ngIf="
                  (CreditCardForm.controls['card_cvv']?.invalid &&
                    CreditCardForm.controls['card_cvv']?.touched) ||
                  (submitted && CreditCardForm.controls['card_cvv']?.invalid)
                "
              >
                {{
                  generateMessage(CreditCardForm.controls["card_cvv"].errors)
                }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray justify-content-start">
      <div class="col-12 justify-content-center d-flex">
        <button
          [disabled]="isLoading"
          type="button"
          class="btn btn-tertiary mr-4"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modal.close()"
        >
          Cancelar
        </button>
        <button
          [disabled]="CreditCardForm.invalid || isLoading"
          (click)="createPaymentProfile()"
          class="btn btn-secondary position-relative"
          type="button"
        >
          Atualizar cartão
        </button>
      </div>
    </div>
  </div>
</div>
