export const UserConsultantCreateUpdateConst = {
  // Page
  PAGE_TITLE_CREATE: 'Cadastro de consultor de relacionamento',
  PAGE_TITLE_UPDATE: 'Editar consultor de relacionamento',
  GROUP_ID: 10200, // Consultor de relacionamento
  EMAIL_DOMAIN: '@edenred.com',
  // Modal
  TITLE_CREATE: 'Cadastrar consultor',
  TITLE_UPDATE: 'Editar consultor',
  SUBTITLE_CONFIRM: 'Confira se todos os dados estão corretos e confirme',
  SUBTITLE_FEEDBACK_CREATE: 'Consultor cadastrado com sucesso!',
  SUBTITLE_FEEDBACK_UPDATE: 'Consultor editado com sucesso!',
  IMG_PATH: '/assets/img/feedback-success-modal.png',
}
