<main class="select-module">
  <h1 class="select-module__title" translate>
    Select which menu you want to access:
  </h1>
  <div class="select-module__container">
    <mat-card class="toll-card select-module__card" *ngFor="let module of modules; let i = index">
      <header class="select-module__card__header">
        <img [src]="module.iconPath">
        <h2 translate>{{ module.title }}</h2>
      </header>
      <section class="select-module__options">
        <mat-accordion *ngFor="let item of module.children" displayMode="flat">
          <mat-expansion-panel [hideToggle]="item.children!.length <= 0"
            [routerLink]="item.children!.length > 0 ? null : '../' + item.path">
            <mat-expansion-panel-header class="select-module__options__title">
              {{ item.title | translate }}
            </mat-expansion-panel-header>

            <mat-action-row *ngFor="let subitem of item.children">
              <button class="select-module__options__subitem" mat-button
                [routerLink]="!subitem.action ? '../' + subitem.path : null"
                (click)="subitem.action ? onDownloadReport() : null">
                {{ subitem.title | translate }}
              </button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
      </section>
    </mat-card>
  </div>
</main>
<div class="container-loading" *ngIf="isLoading">
  <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
</div>
