<ng-template #actionPopover placement="right"
 [outsideClick]="true"
>
  <ng-container>
    <section class="vehicle-details">
      <strong class="heading-info"> Placa: </strong>
      <strong class="content-info" data-e2e="popover-plate" translate>
        {{ vehicle?.licensePlate | mask : "AAA-AAAA" }}
      </strong>

      <strong class="heading-info"> Apelido: </strong>
      <p class="content-info" data-e2e="popover-nickname" translate>
        {{ vehicle?.nickname }}
      </p>

      <strong class="heading-info"> Tag: </strong>
      <p class="content-info" data-e2e="popover-tag" translate>
        {{ vehicle?.tag }}
      </p>

      <strong class="heading-info"> Tipo: </strong>
      <p class="content-info" data-e2e="popover-type" translate>
        {{ vehicle?.type }}
      </p>

      <strong class="heading-info"> Marca: </strong>
      <p class="content-info" data-e2e="popover-brand" translate>
        {{ vehicle?.brand }}
      </p>

      <strong class="heading-info"> Modelo: </strong>
      <p class="content-info" data-e2e="popoverModel-label" translate>
        {{ vehicle?.model }}
      </p>

      <strong class="heading-info"> Nº de eixos: </strong>
      <p class="content-info" data-e2e="popover-axel-label" translate>
        {{ vehicle?.axle }}
      </p>
    </section>

    <!-- <ng-container *ngIf="hasActions">
      <section class="action-center" [ngSwitch]="vehicle.status">
        <section
          class="reinclude-vehicle-button"
          *ngSwitchCase="vehicleStatus.DELETED_VEHICLE"
        >
          <div class="card-button-content mb-3 popoverButtonLarge">
            <button
              data-e2e="popover-reinclude-action-label"
              (click)="redirectToReincludeVehicleModal(vehicle); actionPopover.hide()"
              class="btn btn-repom btn-repom-user col-10 btn-functionality"
              translate
            >
              <img
                class="button-icon mr-2"
                src="../../../assets/img/reinclude_vehicle_icon.svg"
                alt=""
              />
              Reincluir na frota
              <span class="btn-animation"></span>
            </button>
          </div>
        </section>

        <section *ngSwitchDefault>
          <div class="content-buttons-popover mt-4">
            <div class="item-action-list" *accessControl="101146">
              <button
                type="button"
                class="btn-popover"
                data-e2e="'popover-edit-action-label"
                (click)="showEditVehicleModal(vehicle); actionPopover.hide()"
              >
                <img
                  class="btn-popover-icon"
                  src="../../../assets/svg/edit.svg"
                  alt=""
                />
              </button>
              <span class="flex-start" translate>Editar</span>
            </div>

            <div class="item-action-list" *accessControl="101156">
              <button
                data-e2e="'popover-changeServ-action-label"
                buttonBlock
                [ConditionToBlock]="
                  vehicle?.contractVehicleTagStatusTypeId !== 1 &&
                  vehicle?.contractVehicleTagStatusTypeId !== 2
                "
                type="button"
                class="btn-popover btn-functionality"
                (click)="showAlterServiceModal(vehicle); actionPopover.hide()"
              >
                <img
                  class="btn-popover-icon btn-switch"
                  src="../../../assets/svg/toggle_on-24px.svg"
                  alt=""
                />
              </button>
              <span class="flex-start" translate>Alterar serviço</span>
            </div>

            <div
              class="item-action-list"
              *accessControl="101154"
              ngbTooltip="Cancelamento de tag é possível somente para tag bloqueada temporariamente e sem saldo de Vale Pedágio"
            >
              <button
                [ContractBlock]="[2, 3]"
                data-e2e="'popover-cancel-action-label"
                [ConditionToBlock]="
                  vehicle?.contractVehicleTagStatusTypeId !== 2 ||
                  vehicle?.balanceValePedagio
                "
                (click)="redirectToCancelTag(vehicle); actionPopover.hide()"
                class="btn-popover btn-functionality"
                type="button"
                buttonBlock
              >
                <img
                  class="btn-popover-icon btn-backspace"
                  src="../../../assets/svg/backspace-24px.svg"
                  alt=""
                />
              </button>
              <span class="flex-start" translate>Cancelar Tag</span>
            </div>

            <div class="item-action-list" *accessControl="101147">
              <button
                data-e2e="'popover-delete-action-label"
                [disabled]="vehicle.serialTag"
                type="button"
                class="btn-popover btn-functionality"
                (click)="showDeleteVehicleModal(vehicle); actionPopover.hide()"
              >
                <img
                  class="btn-popover-icon btn-trash"
                  src="../../../assets/svg/delete.svg"
                  alt=""
                />
              </button>
              <span class="flex-start" translate>Excluir</span>
            </div>
          </div>

          <div class="d-flex">
            <div *accessControl="101152">
              <div
                class="card-button-content mb-3 popoverButtonLarge"
                *ngIf="vehicle?.contractVehicleTagStatusTypeId == 1"
              >
                <button
                  data-e2e="'popover-block-action-label"
                  buttonBlock
                  [ContractBlock]="[2, 3]"
                  [ConditionToBlock]="vehicle?.contractVehicleTagStatusTypeId !== 1"
                  (click)="redirectToBlockTag(vehicle); actionPopover.hide()"
                  class="btn btn-repom btn-repom-user col-12 btn-functionality"
                  translate
                >
                  <img
                    class="button-icon mr-2"
                    src="../../../assets/svg/block.svg"
                    alt=""
                  />
                  Bloquear TAG
                  <span class="btn-animation"></span>
                </button>
              </div>
            </div>

            <div
              class="card-button-content mb-3 ml-3 popoverButtonLarge"
              *accessControl="101155"
            >
              <button
                [ContractBlock]="[2, 3]"
                [ConditionToBlock]="
                  vehicle?.contractVehicleTagStatusTypeId !== 1 &&
                  vehicle?.contractVehicleTagStatusTypeId !== 2
                "
                (click)="showReplaceTagVehicleModal(vehicle); actionPopover.hide()"
                class="btn btn-repom btn-repom-user col-10 btn-functionality"
                data-e2e="'popover-change-action-label"
                buttonBlock
                translate
              >
                <img
                  class="button-icon mr-2"
                  src="../../../assets/svg/replace-tag.svg"
                  alt=""
                />
                Substituir tag
                <span class="btn-animation"></span>
              </button>
            </div>
          </div>

          <div *accessControl="101153">
            <div
              class="card-button-content mb-3 popoverButtonLarge"
              *ngIf="vehicle?.contractVehicleTagStatusTypeId == 2"
            >
              <button
                data-e2e="'popover-unblock-action-label"
                buttonBlock
                [ContractBlock]="[2, 3]"
                [ConditionToBlock]="vehicle?.contractVehicleTagStatusTypeId !== 2"
                (click)="redirectToUnlockTag(vehicle); actionPopover.hide()"
                class="btn btn-repom btn-repom-user col-10 btn-functionality"
                translate
              >
                <img
                  class="button-icon mr-2"
                  src="../../../assets/svg/block.svg"
                  alt=""
                />
                Desbloquear TAG
                <span class="btn-animation"></span>
              </button>
            </div>
          </div>
        </section>
      </section>
    </ng-container> -->
  </ng-container>
</ng-template>

<button
  type="button"
  class="btn-popover btn-functionality"
  [popover]="actionPopover"
  #pop="bs-popover" 
  [outsideClick]="true" 
  placement="right"
></button>
