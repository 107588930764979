import { Component, Input, OnInit } from '@angular/core';
import { VerificationCodeService } from './verification-code.service';
import { Subscription, interval } from 'rxjs';
import { UpdateType } from './models/update-token.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent implements OnInit {

  @Input() type: number;
  sub$: Array<Subscription> = [];
  data: any;
  counter: number = 60;
  message: boolean = false;
  updateType = UpdateType;
  isLoading: Boolean = false;
  validCode: Boolean; 

  constructor(private service: VerificationCodeService) { 
    this.getData();
    this.getCodeValidation()
  }

  initCounter(){
    this.sub$[0] = interval(1000)
    .subscribe(() => {            
       this.counter = this.counter - 1;
       if( this.counter == 0){
         this.finishCounter();
       }
    });
  }

  finishCounter(){
    this.sub$[0].unsubscribe();
  }

  ngOnInit(): void {
    this.service.isValidCode$.next(true);
    this.service.verificationCode$.next(undefined);
    this.initCounter();
  }

  onCodeChanged(code: string){
    if(code.length < 6){
      this.service.verificationCode$.next(undefined);
    }
  }

  onCodeCompleted(code: string) {
    this.service.isValidCode$.next(true);
    this.service.verificationCode$.next(code);
  }

  getData(){
    this.sub$[1] = this.service.dataToVerificationCode$
    .subscribe((data) => {
      this.data = data;
    });
  }

  getCodeValidation(){
    this.sub$[2] = this.service.isValidCode$
    .subscribe((data) => {
      this.validCode = data;
    });
  }

  sendCode(){
    if(this.counter <= 0){
      if(this.type == this.updateType.email){
        this.sendEmailUpdateToken();
      }
      else{
        this.sendPhoneUpdateToken();
      }
    }
 }

 showMessageAfterSend(){
  this.message = true;
  setTimeout(()=>{
    this.message = false;
  }, 10000);
}

 sendEmailUpdateToken(){
  this.isLoading = true
  this.service.postEmailUpdateToken(this.data)
  .pipe(finalize(() =>{
    this.isLoading = false
  }))
  .subscribe(
    (success) => {
      this.counter = 60;
      this.showMessageAfterSend();
      this.initCounter();
    },
    (error) => {
      console.error(error)
    });
 }

 sendPhoneUpdateToken(){
  this.isLoading = true
  this.service.postPhoneUpdateToken(this.data)
  .pipe(finalize(() =>{
    this.isLoading = false
  }))
  .subscribe(
    (success) => {
      this.counter = 60;
      this.showMessageAfterSend();
      this.initCounter();
    },
    (error) => {
      console.error(error)
    });
 }

  ngOnDestroy() {
    this.sub$.forEach(element => {
      element.unsubscribe();
    });
  }

}
