<div class="ChangeAutoRechargeModalComponent">
  <div class="modal-content">
    <div class="modal-header">
      <div class="box-title"><span>Alterar Recarga automática</span></div>
      <button class="close" type="button" (click)="modal.dismissAll()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="container-loading" *ngIf="isLoading">
      <app-loader
        [isLoading]="isLoading"
        message="{{ 'Carregando...' }}"
      ></app-loader>
    </div>
    <div
      class="modal-body show modal-change-auto-recharge"
      *ngIf="!isLoading && step == 1"
    >
      <div class="title">Escolha o valor que deseja</div>
      <div class="carrousel">
        <p-carousel [value]="rangeList.carrousel" styleClass="custom-carousel" [numVisible]="5" [numScroll]="1"
        [circular]="true" [autoplayInterval]="0" [responsiveOptions]="responsiveOptions" [page]="page" (onPage)="selectValue($event.page)">
            <ng-template let-rangeList pTemplate="item">
              <div class="item card">
                <div class="card-body">
                  {{ rangeList | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR" }}
                </div>
              </div>
            </ng-template>
        </p-carousel>
       <!--  <carousel
          #myCarousel
          [cellsToShow]="5"
          [loop]="true"
          [height]="40"
          [arrowsOutside]="true"
          [width]="810"
        >
          <div class="carousel-cell carousel-cell-disabled carousel-cell-first">
            <div class="carousel-content">
              <div class="value">
                {{
                  rangeList[rangeList.length - 2]
                    | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                }}
              </div>
            </div>
          </div>
          <div
            [ngClass]="
              myCarousel.slide.counter == 1 ? 'withRoundedBorderLeft' : ''
            "
            class="carousel-cell carousel-cell-disabled carousel-cell-second"
          >
            <div class="carousel-content">
              <div class="value">
                {{
                  rangeList[rangeList.length - 1]
                    | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                }}
              </div>
            </div>
          </div>
          <div
            class="carousel-cell"
            *ngFor="let value of rangeList; let i = index"
          >
            <div class="carousel-content">
              <div *ngIf="i != myCarousel.slide.counter" class="value">
                {{ value | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR" }}
              </div>
              <form [formGroup]="newAutoRechargeForm">
                <input
                  readonly
                  formControlName="automaticRechargeValue"
                  *ngIf="i == myCarousel.slide.counter"
                  [ngModel]="value"
                  currencyMask
                  [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                />
              </form>
            </div>
          </div>
          <div
            [ngClass]="
              myCarousel.slide.counter == rangeList.length - 2
                ? 'withRoundedBorderRight'
                : ''
            "
            class="carousel-cell carousel-cell-disabled carousel-cell-penultimate"
          >
            <div class="carousel-content">
              <div class="value">
                {{
                  rangeList[0] | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                }}
              </div>
            </div>
          </div>
          <div class="carousel-cell carousel-cell-disabled carousel-cell-last">
            <div class="carousel-content">
              <div class="value">
                {{
                  rangeList[1] | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                }}
              </div>
            </div>
          </div>
        </carousel> -->
      </div>
      <div class="box">
        <div class="title">Recarga automática</div>
        <div class="text-center">
          <form [formGroup]="newAutoRechargeForm">
            <input
              readonly
              formControlName="automaticRechargeValue"
              [ngModel]="formValue"
              currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' }"
            />
          </form>
          <div class="box-recarga">
            <b class="mr-2">R$</b>
          </div>
          <div class="box-porcentagem">
            <div class="percent_connect">
              <div class="porcentagem">
                {{ prePaidConditions.minimumPercentageForAutomaticRecharge }}%
              </div>
            </div>
          </div>

          <div class="box-border-left shadow-lg">
            <b>{{
              (prePaidConditions?.minimumPercentageForAutomaticRecharge / 100) *
                newAutoRechargeForm.controls["automaticRechargeValue"].value
                | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
            }}</b>
            Saldo mínimo
          </div>
        </div>
      </div>
      <div class="text">
        Toda vez que o saldo de sua conta atingir
        <b class="red minimumPercentageForAutomaticRecharge"
          >{{ prePaidConditions?.minimumPercentageForAutomaticRecharge }}%</b
        >
        do valor da Recarga Automática, no caso
        <b class="black"
          >{{
            (prePaidConditions?.minimumPercentageForAutomaticRecharge / 100) *
              newAutoRechargeForm.controls["automaticRechargeValue"].value
              | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
          }}
          (saldo mínimo),</b
        >
        será débitado de seu cartão de crédito uma nova recarga automática no
        valor escolhido de
        <b class="color-primary">
          {{
            newAutoRechargeForm.controls["automaticRechargeValue"].value
              | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
          }}.
        </b>
      </div>
    </div>
    <div
      class="modal-body show modal-change-auto-recharge-confirmed"
      *ngIf="!isLoading && step == 2"
    >
      <div class="row row-modal-collapse mt-7rem">
        <div class="w-100 d-flex justify-content-center">
          <div class="col-lg-4">
            <img class="w-100" src="../../../assets/img/bg-place.svg" />
          </div>
          <div class="col-sm-auto tag-replace">
            <h3>Valor da recarga automática alterado</h3>
            <div class="row RechargeConfirmedInfos">
              <div class="col">
                <div class="col-12 textTitle">
                  Novo valor da recarga automática
                </div>
                <div class="col-12 text">
                  {{
                    newAutoRechargeForm?.controls["automaticRechargeValue"]
                      .value | currency : " " : "symbol" : "0.2-2" : "pt-BR"
                  }}
                </div>
              </div>
              <div class="col card-details">
                <b data-e2e="card-label">Meio de pagamento</b>
                <app-credit-card></app-credit-card>
              </div>
            </div>
            <div class="textConfirmed">
              Toda vez que o saldo de sua conta atingir
              <b class="red minimumPercentageForAutomaticRecharge"
                >{{
                  prePaidConditions?.minimumPercentageForAutomaticRecharge
                }}%</b
              >
              do valor da Recarga Automática, no caso
              <b class="black"
                >{{
                  (prePaidConditions?.minimumPercentageForAutomaticRecharge /
                    100) *
                    newAutoRechargeForm.controls["automaticRechargeValue"].value
                    | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
                }}
                (saldo mínimo),</b
              >
              será débitado de seu cartão de crédito uma nova recarga automática
              no valor escolhido de
              <b class="color-primary">
                {{
                  newAutoRechargeForm.controls["automaticRechargeValue"].value
                    | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
                }}.
              </b>
            </div>
            <div class="row mt-3 user">
              <div class="col-lg-12 text-center">
                <span>{{
                  success.registrationDate | date : "dd/MM/yyyy - HH:mm"
                }}</span>
                por <span>{{ success.registerUserName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray" [style.justify-content]="justifyStyle()">
      <button
      *ngIf="step == 1"
      [disabled]="isLoading"
      type="button"
      class="btn btn-tertiary mr-4"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismissAll()"
    >
      Cancelar
    </button>
    <button
      *ngIf="step == 1"
      [disabled]="
        newAutoRechargeForm?.controls['automaticRechargeValue'].value ==
          prePaidConditions.automaticRechargeValue || isLoading
      "
      (click)="ConfirmChangeAutoRecharge()"
      class="btn btn-secondary"
      type="button"
    >
      Confirmar Alteração
    </button>
    <button
      *ngIf="step == 2"
      (click)="modal.dismissAll()"
      class="btn btn-secondary closeModal"
      type="button"
    >
      Fechar
    </button>
    </div>
  </div>
</div>
