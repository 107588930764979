import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs';
import { IHoliday, IIncludeHolidays } from '../../models/holidays.dto';
import { HolidaysCalendarService } from '../../services/holidays-calendar.service';
import { ActionSuccessModalComponent } from '../action-success-modal/action-success-modal.component';

@Component({
  selector: 'app-insert-warning-modal',
  templateUrl: './insert-warning-modal.component.html',
  styleUrl: './insert-warning-modal.component.scss'
})
export class InsertWarningModalComponent {

  index: number
  @Input() holidayErrors : IHoliday[]
  @Input() errorsAtForm : IIncludeHolidays[]
  excludeForm: FormGroup;

  constructor(private _holidayService : HolidaysCalendarService,
              private _modalService: NgbModal)
  { }
  ngOnInit(): void {

  }

  dismissModal() {

    this._modalService.dismissAll();
  }


}
