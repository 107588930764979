<div class="modal-header">
  <h4 data-e2e="modal-title" class="modal-title" translate>Separate order</h4>
  <a data-e2e="close-modal-top-btn" class="btn-close" (click)="dismissModal()">
    <img src="assets/svg/close.svg" />
  </a>
</div>

<div class="modal-body">
  <div class="modal-content col-lg-12">
    <div class="row header-info">
      <div class="col-6 company-info">
        <strong data-e2e="company-name-title">
          {{ separateOrder?.companyName }}
        </strong>
        <p data-e2e="company-tax-id-title">
          {{ separateOrder?.documentNumber | mask: '00.000.000/0000-99' }}
        </p>
      </div>

      <div class="col-6 order-number">
        <p>
          Pedido:
          <strong data-e2e="order-id">
            {{ separateOrder?.tagRequestId }}
          </strong>
        </p>
      </div>
    </div>
    <hr />

    <div class="content-info">
      <div class="header-count">
        <h3 data-e2e="tag-amount-title" class="text-center" translate>
          Number of tags
        </h3>
        <div class="col-12 d-flex p-0 position-relative">
          <div class="floating-box">
            <img src="assets/svg/arrows-reverse.svg" />
          </div>

          <div class="col-6 requested-client">
            <p data-e2e="requested-tags-amount-label" translate>
              Requested by client
            </p>
            <span data-e2e="requested-tags-amount">
              {{ separateOrder?.tagRequestCount }} tags
            </span>
          </div>

          <div class="col-6 order-added">
            <ng-template #popoverAddSerial>
              <div class="popover-add">
                <button
                  data-e2e="popover-close-btn"
                  class="btn-menu btn-popover"
                  (click)="add.close()"
                >
                  <img src="assets/img/ico-filter-nav-active.svg" />
                </button>

                <div class="d-flex flex-column justify-content-center h-100">
                  <p data-e2e="order-add-tag-label" translate>Added to order</p>
                  <span data-e2e="order-add-tag">
                    {{ separateOrder?.tagRequestSeparationCount }} tags
                  </span>
                </div>
              </div>

              <form [formGroup]="addSerialForm" novalidate>
                <div class="form-group d-flex flex-column">
                  <label data-e2e="tag-serial-label" class="label-serial" translate>Serial</label>
                  <div class="d-flex position-relative">
                    <input
                      type="text"
                      data-e2e="tag-serial-input"
                      (focus)="hideMsg()"
                      class="form-control input-serial"
                      formControlName="serialNumber"
                      ma="10"
                      [class.is-invalid]="
                        addSerialForm.controls.serialNumber.invalid &&
                        addSerialForm.controls.serialNumber.touched
                      "
                    />

                    <button
                      [disabled]="
                        isLoading ||
                        separateOrder.tagRequestCount ==
                          separateOrder.tagRequestSeparationCount
                      "
                      class="btn btn-secondary"
                      translate
                      data-e2e="tag-serial-add-btn"
                      (click)="
                        validateSerialTagRequest(
                          addSerialForm.controls.serialNumber.value
                        )
                      "
                    >
                      Include
                    </button>
                  </div>

                  <div data-e2e="success-msg" *ngIf="tagSuccess" class="mt-2">
                    TAG adicionada com sucesso!
                  </div>

                  <app-vmessage
                    data-e2e="required-error-msg"
                    *ngIf="
                      addSerialForm.controls.serialNumber.invalid &&
                      addSerialForm.controls.serialNumber.touched
                    "
                    errorMessage="{{ 'Serial number is required' | translate }}"
                  >
                  </app-vmessage>

                  <app-vmessage
                    data-e2e="limit-error-msg"
                    *ngIf="
                      separateOrder.tagRequestCount ==
                      separateOrder.tagRequestSeparationCount
                    "
                    errorMessage="{{ 'Limite de tags selecionadas' }}"
                  >
                  </app-vmessage>

                  <app-vmessage
                    data-e2e="dynamic-error-msg"
                    *ngIf="errorTag"
                    errorMessage="{{ errorTagMsg }}"
                  >
                  </app-vmessage>
                  <div></div>
                </div>
              </form>

              <div class="d-flex justify-content-center align-items-center">
                <button
                  data-e2e="close-btn"
                  class="btn btn-primary mt-2 mb-4"
                  translate
                  (click)="add.close()"
                >
                  <img class="button-icon" src="assets/img/ticked.svg" />
                  Complete
                </button>
              </div>
            </ng-template>
            <button
              class="btn-header-popover enabled btn-popover"
              [ngbPopover]="popoverAddSerial"
              [autoClose]="false"
              #add="ngbPopover"
              data-e2e="popover-add-serial-btn"
              placement="right"
              popoverClass="custom-order-popover add"
              [hidden]="
                separateOrder.tagRequestCount <=
                separateOrder.tagRequestSeparationCount
              "
            >
              <img src="assets/svg/ico-plus.svg" />
            </button>

            <button
              [hidden]="
                separateOrder.tagRequestCount >
                separateOrder.tagRequestSeparationCount
              "
              class="btn-header-popover disabled btn-popover"
            >
              <img src="assets/svg/ico-plus.svg" />
            </button>

            <ng-template #popoverEditSerial>
              <div class="popover-add">
                <button
                  data-e2e="close-popover-btn"
                  class="btn-menu btn-popover"
                  (click)="edit.close()"
                >
                  <img src="assets/img/ico-filter-nav-active.svg" />
                </button>

                <div class="d-flex flex-column justify-content-center h-100">
                  <p data-e2e="edit-popover-title" translate>Edit inclusions</p>
                  <span data-e2e="edit-popover-counter">
                    {{ separateOrder?.tagRequestSeparationCount }} tags
                  </span>
                </div>
              </div>

              <form novalidate>
                <div class="form-group d-flex flex-column edit-tags">
                  <label
                    class="checkbox-custom"
                    [attr.data-e2e]="'popover-order-tag-count-label-' + i"
                    *ngFor="
                      let number of orderForm.value.tagList;
                      let i = index
                    "
                    for="{{ number }}"
                  >
                    {{ number }}
                    <input
                      type="checkbox"
                      id="{{ number }}"
                      [attr.data-e2e]="'popover-order-tag-input-' + i"
                      name="{{ number }}"
                      [value]="number"
                      (change)="onCheckChange($event)"
                      checked
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <!-- <div class="custom-control custom-checkbox" *ngFor="let item of listVehicleAvailable; let i=index">
                  <div class="d-flex justify-content-around align-items-center">
                    <input type="checkbox" class="custom-control-input" id="{{item.contractVehicleId}}"
                      name="{{item.licensePlate}}" [value]="item.licensePlate" (change)="onCheckChange($event)">
                    <label class="custom-control-label font-weight-bold"
                      for="{{item.contractVehicleId}}">{{item.licensePlate | mask: 'AAA-AAAA' }}</label>
                  </div>
                </div> -->
              </form>
              <div class="d-flex justify-content-center align-items-center">
                <button
                  data-e2e="close-popover-btn"
                  class="btn btn-primary mt-2 mb-4"
                  translate
                  (click)="edit.close()"
                >
                  <img class="button-icon" src="assets/img/ticked.svg" />
                  Complete
                </button>
              </div>
            </ng-template>

            <button
              [hidden]="separateOrder?.tagRequestSeparationCount == 0"
              class="btn-header-popover edit btn-popover ml-0"
              [ngbPopover]="popoverEditSerial"
              [autoClose]="false"
              #edit="ngbPopover"
              data-e2e="open-edit-popover-btn"
              placement="right"
              popoverClass="custom-order-popover edit"
            >
              <img src="assets/svg/edit.svg" />
            </button>

            <button
              (click)="saveSeparationTagRequest()"
              data-e2e="submit-btn"
              class="btn-header-popover btn-popover btn-save"
              [hidden]="!availableToSave"
            >
              <img src="assets/img/save.svg" />
            </button>

            <div class="d-flex flex-column justify-content-center h-100">
              <p data-e2e="order-tags-added-title" translate>Added to order</p>
              <span data-e2e="order-tags-added">
                {{ separateOrder?.tagRequestSeparationCount }} tags
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-70 d-flex flex-column">
        <div class="col-12 d-flex justify-content-between mt-2">
          <div class="col-4">
            <h3 data-e2e="freight-label" translate>Freight</h3>
            <div class="d-flex justify-content-between">
              <p>R$</p>
              <span data-e2e="freight">
                {{ separateOrder?.shippingValue | number: '.2' : 'pt-BR' }}
              </span>
            </div>
          </div>
          <div class="col-4">
            <h3 data-e2e="order-tag-total-amount-label" translate>Tag value</h3>
            <div class="d-flex justify-content-between">
              <p>R$</p>
              <span data-e2e="order-tag-total-amount">
                {{ separateOrder?.totalTagValues | number: '.2' : 'pt-BR' }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-12 address">
          <h3 data-e2e="address-title" class="pl-3" translate>
            Delivery address
          </h3>
          <p data-e2e="order-address" class="pl-3">
            <strong>Título endereço</strong><br />
            {{ separateOrder?.personAddress?.streetAddress }},
            {{ separateOrder?.personAddress?.number }}
            {{ separateOrder?.personAddress?.streetAddressLine2 }} -
            {{ separateOrder?.personAddress?.city }} -
            {{ separateOrder?.personAddress?.federatedUnit }} - CEP:
            {{ separateOrder?.personAddress?.zipCode }}
            <br />
            {{ separateOrder?.personAddress?.landmark }}
          </p>
        </div>

        <div class="col-12">
          <h3 data-e2e="order-recipient-label" class="text-center" translate>
            Responsible for receiving the order
          </h3>
        </div>

        <div class="col-12 received-order">
          <div class="col-6">
            <img src="assets/img/user.svg" />
            <span data-e2e="order-recipient">
              {{ separateOrder?.tagRequestReceivingResponsibleName }}
            </span>
          </div>
          <div class="col-6">
            <img src="assets/svg/phone.svg" />
            <span data-e2e="order-recipient-phone">
              {{
                separateOrder?.tagRequestReceivingResponsiblePhone
                  | mask: '(00) 00000-0000'
              }}
            </span>
          </div>
        </div>

        <div class="col-12 total-value">
          <div class="col-6">
            <p data-e2e="order-total-amount" translate>Total order amount</p>
          </div>
          <div class="col-6">
            <p>R$</p>
            <span data-e2e="order-total-amount">{{
              separateOrder?.totalOrder | number: '.2' : 'pt-BR'
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="content-buttons">
    <button
      data-e2e="close-modal-btn"
      class="btn btn-tertiary"
      translate
      (click)="dismissModal()"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      data-e2e="submit-btn"
      [disabled]="
        isLoading ||
        separateOrder.tagRequestCount != separateOrder.tagRequestSeparationCount
      "
      (click)="finalizeSeparation()"
    >
      <!-- TODO: Colocar código abaixo no botão de finalizar separação -->
      <!-- (click)="finalizeSeparate()" -->
      <div *ngIf="!isLoading" translate>
        <img class="button-icon" src="assets/img/separate-order.svg" />
        Finalize separation
      </div>
      <div *ngIf="isLoading" translate>
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        Loading...
      </div>
    </button>
  </div>
</div>

<!-- <pre> {{ orderForm.value | json }}</pre>
<pre> {{ separateOrder | json }}</pre> -->
