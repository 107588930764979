import {Component, OnInit} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OAuthService } from "angular-oauth2-oidc";
import { OrderPipe } from "ngx-order-pipe";
import { Notification } from "src/app/core/models/notification.model";
import { NotificationService } from "@services/notification.service";
import { NotificationAlertService } from "@services/notification-alert/notification-alert.service";
import { TabsSubscriptionService } from "src/app/shared/services/tabs-subscription.service";
import { NOTIFICATION_TYPE } from "./constants/notification-type";
import { TabType } from "@modules/customer/batch-management/dtos/batch-management-consts";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  numberNotification: number;
  notificationQueryResult: Array<Notification>;
  length: number = 0;
  hoverButton: boolean = false;
  hoverDropdown: boolean = false;
  isOpenNotifications: boolean;
  customClass: string = '';

  constructor(
    private _oauthService: OAuthService,
    private _modalService: NgbModal,
    private _notificationService: NotificationService,
    private _router: Router,
    private _orderPipe: OrderPipe,
    private _notificationAlertService: NotificationAlertService,
    private _tabsService: TabsSubscriptionService,
  ) {}

  ngOnInit() {
    this.notificationQueryResult = null;
    this.getLastNotifications();
    this._notificationAlertService.contractAlert();
    setInterval(() => {
      if (this._oauthService.hasValidAccessToken()) {
        this._notificationAlertService.contractAlert();
        this.getLastNotifications();
      }
    }, 180000);
  }

  openDropdown(element){
    if(element == 'button')
    this.hoverButton = true;

    if(element == 'dropdownItem')
    this.hoverDropdown = true;

    this.isOpenNotifications = true;

    this.handleCustomClass();
  }

  closeDropdown(element){
        if(element == 'button')
        this.hoverButton = false;

        if(element == 'dropdownItem')
        this.hoverDropdown = false;

        if(!this.hoverButton && !this.hoverDropdown)
          this.isOpenNotifications = false;

    this.handleCustomClass();
  }

  getLastNotifications(notification: any = null, cacheble: boolean = true) {
    this._notificationService.getNotificationsByContractId(cacheble).subscribe(
      (success: any) => {
        this.orderNotificationsByStatus(success);
        if (notification) {
          this.openModalOrRedirect(notification);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  orderNotificationsByStatus(notification) {
    this.notificationQueryResult = this._orderPipe.transform(
      notification,
      "notificationStatusTypeId"
    );
    this.length = this.notificationQueryResult.length;
    this.numberNotification = notification.filter(
      (data) => data.notificationStatusTypeId == 1
    ).length;
  }

  markNotificationAsRead(notification) {
    if (notification.notificationStatusTypeId == 1) {
      this._notificationService.readNotification(notification.id).subscribe(
        (success: any) => {
          this.getLastNotifications(notification, false);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.openModalOrRedirect(notification);
    }
  }

  openModalOrRedirect(notification) {
    if (notification.modalDestination) {
      const modalRef = this._modalService.open(notification.modalComponent);
      modalRef.componentInstance.idDestination = notification.idDestination;
      if(notification.paymentOriginId)
      modalRef.componentInstance.paymentOriginId = notification.paymentOriginId;

    }
     else {
      if (notification.urlDestination) {
        if (notification.isUrlExternal) {
          window.open(notification.urlDestination, "_blank");
        } else {
          this.handleUrlDestination(notification);
        }
      }
    }
  }

  getClassNotificationRow(notification) {
    if (notification.notificationStatusTypeId == 1) {
      return "pendingNotification";
    } else {
      if (
        (notification.urlDestination || notification.modalDestination) &&
        notification.notificationStatusTypeId == 2
      ) {
        return "redirectNotification solvedNotification";
      } else {
        return "solvedNotification";
      }
    }
  }

  handleNumberNotification(): string {
    return this.numberNotification > 9 ? '9+' : this.numberNotification.toString();
  }

  private handleUrlDestination(notification) {
    if (notification.notificationTypeId === NOTIFICATION_TYPE.BULK_BLOCKUNBLOCK) {
      this._tabsService.setTabActive(TabType.TagBlockUnblockHistory);
    }

    this._router.navigate([notification.urlDestination]);
  }

  private handleCustomClass() {
    if (this.isOpenNotifications) {
      this.customClass = ' ico-border ';
    } else {
      this.customClass = this.customClass.replace('ico-border', '');
    }
  }
}
