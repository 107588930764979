import { ITripExpirated } from './../dtos/trip-expirated.dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TripExpiratedQueryArguments } from '../dtos/trip-expirated-query-arguments';
import { Dto } from '@models/dto.model';

@Injectable({
  providedIn: 'root',
})
export class TripExpiratedService {
  constructor(
    private http: HttpClient,
    private _datePipe: DatePipe,
  ) {}

  public baseTag = environment.apiTMTagTransaction;
  public base = environment.apiTMIntegrationVPR;

  getTripExpiration(
    args: TripExpiratedQueryArguments,
  ): Observable<Dto<ITripExpirated[]>> {
    return this.http
      .get<Dto<ITripExpirated[]>>(
        this.base + `/api/Trip`,
        {
          params: args.httpParams,
        },
      )
      .pipe(
        map((response) => {
          let newItems: ITripExpirated[] = [];

          response.data.forEach((item) => {
            let newTransactionConciliaton = {
              ...item,

              tripDate: this._datePipe.transform(
                item.tripDate,
                'dd/MM/yyyy HH:mm:ss',
              ),
              creditDate: this._datePipe.transform(
                item.creditDate,
                'dd/MM/yyyy HH:mm:ss',
              ),
              tripExpirationDate: this._datePipe.transform(
                item.tripExpirationDate,
                'dd/MM/yyyy HH:mm:ss',
              ),
            };
            newItems.push(newTransactionConciliaton);
          });

          response.data = newItems;
          return response;
        }),
      );
  }

  getTripExpirationCsv(args: TripExpiratedQueryArguments) {
    return this.http
      .get(this.base + `/api/Trip/Csv`, {
        observe: 'response',
        responseType: 'blob',
        params: args.httpParams,
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),

            filename: response.headers.get('filename'),
          };
          return data;
        }),
      );
  }
}
