import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private success = [ 'background: #4b8b3b', 'color: white', 'display: block', 'text-align: center'].join(';');
  private failure = [ 'background: #ff0000', 'color: white', 'display: block', 'text-align: center'].join(';');
  private warning = [ 'background: #dcad24', 'color: white', 'display: block', 'text-align: center'].join(';');
  private informa = [ 'background: #1338be', 'color: white', 'display: block', 'text-align: center'].join(';');

  constructor() { }

  /** 
  * Exibi uma mensagem de erro no inspector-console do browser.
  * @param {string} message mensagem a ser exibida.
  */
  showError(message: string) {
     console.log(`%c[APPLICATION ERROR]:%c ${message}`, this.failure, '');
  }

  /** 
  * Exibi uma mensagem de sucesso no inspector-console do browser.
  * @param {string} message mensagem a ser exibida.
  */
  showSuccess(message: string) {
    console.log(`%c[APPLICATION SUCCESS]:%c ${message}`, this.success, '');
  }

  /** 
  * Exibi uma mensagem de atenção no inspector-console do browser.
  * @param {string} message mensagem a ser exibida.
  */
  showWarning(message: string) {
    console.log(`%c[APPLICATION WARNING]:%c ${message}`, this.warning, '');
  }

  /** 
  * Exibi uma mensagem comum de informativo no inspector-console do browser.
  * @param {string} message mensagem a ser exibida.
  */
  showInformaton(message: string) {
    console.log(`%c[APPLICATION INFO]:%c ${message}`, this.informa, '');
  }

}
