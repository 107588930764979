import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainMenuBackofficeRoutingModule } from './routes/main-menu-backoffice-routing.module';
import { MainMenuBackofficeComponent } from './main-menu-backoffice.component';
import { CoreModule } from "../../../core/core.module";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [MainMenuBackofficeComponent],
  imports: [
    CommonModule,
    // TranslateModule,
    RouterModule,
    MainMenuBackofficeRoutingModule,
    CoreModule,
    SharedModule,
    MatCardModule,
    MatExpansionModule,
    MatButtonModule,
    TranslateModule,
  ]
})
export class MainMenuBackofficeModule { }
