import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CurrentUser } from '@models/old/currentuser.model';
import { UpdateContractRegistration } from 'src/app/shared/components/verification-code/models/update-token.model';
import {environment} from "@env";

@Injectable({
  providedIn: 'root'
})

export class MyAccountService {

  private base = environment.apiTMCustomer;

  constructor(
    protected http: HttpClient
  ) {}

/**
   * A Behavior Subject that updates the current user data. It emits an event to require current user new infos.
   *
   * @class boolean BehaviorSubject.
   * */
  refreshCurrentUser$ = new BehaviorSubject<boolean>(undefined);

 /**
   * An Observable which listen any current user update.
   * @returns {Observable<CurrentUser>} an Observable containing the new current user data.
   */
  updateCurrentUser$ = this.refreshCurrentUser$.pipe(
    switchMap(() => this.getCurrentUser())
  );

  /**
  * Obtem da API dados da pessoa.
  * @param {Number} customerId
  * @returns {Promise<User>}
  */


  public getCurrentUser(): Observable<CurrentUser>  {
    return this.http
    .get<CurrentUser>(
      `${this.base}/Person/PersonCurrentUser`
    )
  }

  getContracts() {
    return this.http.get<any[]>(`${this.base}/Contract/GetContractsByCurrentUser`);
  }

  putUpdatePersonEmailAddress(data){
    return this.http.put<UpdateContractRegistration>
    (`${this.base}/PersonEmailAddress/UpdatePersonEmailAddress`,data)
  }

  putUpdatePersonPhone(data){
    return this.http.put<UpdateContractRegistration>
    (`${this.base}/PersonPhone/UpdatePersonPhone`,data)
  }



}

