import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { ActionType, ModalTagBlockUnblockInconsistencies } from '@modules/customer/batch-management/dtos/batch-management-consts';
import { ITagBlockUnblockHistoryDto } from '@modules/customer/batch-management/dtos/tag-block-unblock-history.dto';
import { ITagBlockUnblockInconsistenciesDto } from '@modules/customer/batch-management/dtos/tag-block-unblock-inconsistencies.dto';
import { TagsSelectedToBlockUnblock } from '@modules/customer/batch-management/dtos/tags-selected-to-block-unblock.dto';
import { BatchManagementService } from '@modules/customer/batch-management/services/batch-management.service';

@Component({
  selector: 'app-tag-block-unblock-inconsistencies-modal',
  templateUrl: './tag-block-unblock-inconsistencies-modal.component.html',
  styleUrl: './tag-block-unblock-inconsistencies-modal.component.scss'
})
export class TagBlockUnblockInconsistenciesModalComponent implements OnInit {
  @Input() inDataInconsistencies: ITagBlockUnblockHistoryDto;
  @Output() outEventReloadRequest: EventEmitter<number> = new EventEmitter();

  isLoading: boolean;
  title: string;
  subtitle: string;
  tagsSuccessText: string;
  tagsInconsistenciesText: string;
  tagsInconsistenciesResultList: ITagBlockUnblockInconsistenciesDto[];
  tagsSelectedToBlockUnblockRetry: TagsSelectedToBlockUnblock;

  constructor(
    private modalService: NgbModal,
    private batchManagementService: BatchManagementService,
  ) {}

  ngOnInit(): void {
    this.settingInitClass();
    this.requestTagsBlockUnblockInconsistencies();
  }

  private settingInitClass() {
    this.tagsInconsistenciesResultList = [];
    this.tagsSelectedToBlockUnblockRetry = new TagsSelectedToBlockUnblock();

    if (this.getTagsActionTypeId() === ActionType.BLOCK) {
      this.title = ModalTagBlockUnblockInconsistencies.BLOCK_TITLE;
      this.subtitle = ModalTagBlockUnblockInconsistencies.BLOCK_SUBTITLE;
      this.tagsSuccessText = ModalTagBlockUnblockInconsistencies.BLOCK_TAGS_SUCCESS;
      this.tagsInconsistenciesText = ModalTagBlockUnblockInconsistencies.BLOCK_TAGS_INCONSISTENCIES;
      return;
    }

    this.title = ModalTagBlockUnblockInconsistencies.UNBLOCK_TITLE;
    this.subtitle = ModalTagBlockUnblockInconsistencies.UNBLOCK_SUBTITLE;
    this.tagsSuccessText = ModalTagBlockUnblockInconsistencies.UNBLOCK_TAGS_SUCCESS;
    this.tagsInconsistenciesText = ModalTagBlockUnblockInconsistencies.UNBLOCK_TAGS_INCONSISTENCIES;
  }

  private getTagsActionTypeId(): number {
    return this.inDataInconsistencies.blockOrUnblockDescription === 'Bloqueio' ?
      ActionType.BLOCK :
      ActionType.UNBLOCK;
  }

  private requestTagsBlockUnblockInconsistencies() {
    this.isLoading = true;

    this.batchManagementService.getTagsBlockUnblockInconsistencies(this.inDataInconsistencies.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.tagsInconsistenciesResultList = success;
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  confirmTryAgain() {
    this.isLoading = true;
    this.setTagsSelectedRetryToRequestObject();
    this.requestRetryBlockUnblockTags();
  }

  private setTagsSelectedRetryToRequestObject() {
    this.tagsSelectedToBlockUnblockRetry.tagBlockUnblockBatchControlId = this.inDataInconsistencies.id;
    this.tagsSelectedToBlockUnblockRetry.contractVehicleTagIdList = this.getContractVehicleTagIdList();
    this.tagsSelectedToBlockUnblockRetry.contractVehicleTagStatusTypeId = this.getTagsActionTypeId();
  }

  private getContractVehicleTagIdList(): number[] {
    let idsList: number[] = [];

    this.tagsInconsistenciesResultList.forEach((item) => {
      idsList.push(item.contractVehicleTagId);
    });

    return idsList;
  }

  private requestRetryBlockUnblockTags() {
    this.batchManagementService.putRetryBlockUnblockTags(this.tagsSelectedToBlockUnblockRetry)
      .subscribe({
        next: () => {
          setTimeout(
            ()=>{
              // Send Event To Reload Request Tag Block Unblock History
              this.outEventReloadRequest.emit(1);
            },
            2000
          );
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }
}
