export class VehicleStatistics {
  vehiclesRegistered: number;
  vehiclesWithoutTagRegistered: number;
  vehiclesWithActiveTag: number;
  vehiclesWithLockedTag: number;
  excludedVehicles: number;
  tagsToLinking: number;
}

export class VehicleStats {
  constructor(
    public title: string,
    public stats: number,
    public color: string,
    public category: string
  ) {}
}

export class CostsRanking {
  constructor(
    public licensePlate: string,
    public amount: number,
    public hierarchyName: string,
    public vehicleId: number,
  ) {}
}

export class TagStockStats {
  title?: string;
  amount?: number;
  category?: string;
  tags?: number;
  startDate?: Date | string;
  endDate?: Date | string;
}

export class TotalSpending {
  type: string;
  amount: number;
  percentage: number;
}
