   <div class="register-credit-card-confirmation">
    <div class="row-box-yellow">
        <span class="box-yellow">
          <b>A</b>pós débito da primeira recarga, o saldo será disponibilizado em sua conta em até 10 minutos.
        </span>
      </div>
      <div class="contract-infos">
            <div class="companyName">{{ success.companyName }}</div>
            <div class="companyDocument">{{ success.documentNumber | mask: '00.000.000/0000-00'}}</div>
            <div class="row row-animation"></div>
            <div class="nickname">
                <div class="box-white-small">
                    <p>Contrato:</p>   
                    <p><span id="contractId">{{ success.contractId }}</span><span *ngIf="success.nameContract" id="contractName"> - {{ success.nameContract }}</span></p>  
                 </div> 
            </div>
      </div>
        <div class="row row-modal-collapse mt-7rem">
            <div class="w-100 d-flex justify-content-center">
            <div class="col-lg-4 left-infos">
                <img class="w-100" src="../../../assets/img/bg-place.svg" />
                <div class="box-gray">
                    Você será notificado sobre a solicitação da Primeira Recarga.
                    Fique atento às notificações!
                </div>
                <div class="notification">
                    <img src="/assets/img/red-arrow.svg" />
                    <div class="ico-notification">
                        <img  src="/assets/img/notification_image.svg"/>       
                    </div>
                </div>
               
            </div>
            <div class="col-sm-auto tag-replace right-infos">
                <h3>Cadastro de cartão de crédito realizado com sucesso <br>
                    Primeira Recarga em processamento</h3>
            <div class="row RechargeConfirmedInfos">
                <div class="col">
                    <div class="col-12 textTitle">Valor da recarga automática:</div>
                    <div class="col-12 text">{{ prePaidConditions.automaticRechargeValue | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</div>
                    <div class="col-12 textTitle">Taxa de recarga <span *ngIf="prePaidConditions.rechargeFee !==0">({{ prePaidConditions.rechargeFee }}%)</span></div>
                    <div class="col-12 text" *ngIf="prePaidConditions.rechargeFee !== 0">{{ prePaidConditions.rechargeFee / 100 * prePaidConditions.automaticRechargeValue | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</div>
                    <div class="col-12 text" *ngIf="prePaidConditions.rechargeFee == 0">Isento</div>                   
                    <div class="col-12 textTitle">Total debitado do cartão de crédito</div>
                    <div class="col-12 text">{{prePaidConditions.automaticRechargeValue + (prePaidConditions.rechargeFee / 100 * prePaidConditions.automaticRechargeValue) | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</div>
                </div>
                <div class="col">
                <div class="infos-payment-confirmed">
                    <div class="pt-3">
                    <b>Cartão de crédito</b>
                    </div>
                    <div class="content-payment">
                    <div class="infos-card-payment">
                        <img *ngIf="success.creditCardBrandTypeId == 1" src="../../../assets/img/mastercard.svg" />
                        <img *ngIf="success.creditCardBrandTypeId == 2" src="../../../assets/img/hipercard.svg" />
                        <img *ngIf="success.creditCardBrandTypeId == 3" src="../../../assets/img/visa.svg" />
                        <img *ngIf="success.creditCardBrandTypeId == 4" src="../../../assets/img/amex.svg" />
                        <img *ngIf="success.creditCardBrandTypeId == 5" src="../../../assets/img/elo.svg" />
                        <img *ngIf="success.creditCardBrandTypeId == 6" src="../../../assets/img/dinersclub.svg" />
                        ●●●● ●●●● ●●●● {{success.cardNumber}}
                    </div>
                    </div>
                </div>
                </div>
            </div>
                <div class="row mt-3 user">
                    <div class="col-lg-12 text-center">
                      <span id="registerDateTime">{{ success.registerDateTime | date: 'dd/MM/yyyy - HH:mm' }}</span> por <span id="registerUserName">{{ success.registerUserName }}</span>
                    </div>
                </div>       
            </div>
        </div>
        </div>
 </div>