<div class="LimitChangeModalComponent">
  <div class="modal-header">
    <h4 data-e2e="modal-title" class="modal-title" translate>
      Contracted limit change
    </h4>
    <a data-e2e="close-modal-top-btn" class="btn-close" (click)="close()">
      <img src="assets/svg/close.svg" />
    </a>
  </div>

  <!-- passo 1 - formulario -->
  <div class="modal-body step1" *ngIf="step == 1">
    <div class="modal-content col-lg-12 col-md-12">
      <div class="limitInfos row">
        <div class="col limit">
          <div data-e2e="current-limit-label" class="title">Limite atual</div>
          <div data-e2e="current-limit" class="value">
            {{
              contract.creditLimit
                | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
            }}
          </div>
        </div>

        <div data-e2e="contract-description" class="col Company">
          {{ contract.description }}
        </div>
        <div class="col Contract">
          <div
            data-e2e="contract-tax-id"
            class="document"
            *ngIf="contract.documentNumber.length == 14"
          >
            {{ contract.documentNumber | mask: '00.000.000/0000-00' }}
          </div>

          <div
            data-e2e="contract-social-security"
            class="document"
            *ngIf="contract.documentNumber.length == 11"
          >
            {{ contract.documentNumber | mask: '00.000.000-00' }}
          </div>

          <div data-e2e="contract-id" class="contractId">
            contrato: <span>{{ contract.id }}</span>
          </div>
        </div>
      </div>

      <div class="formLimitChange">
        <form class="form row" [formGroup]="ChangeLimitForm">
          <div class="formfield col">
            <label data-e2e="new-limit-label" for="newLimit">Novo limite</label>
            <div class="formInput">
              <span class="monetary">R$</span>
              <input
                id="newLimit"
                currencyMask
                data-e2e="new-limit"
                [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                formControlName="newLimit"
              />
              <div
                class="alertErrorValidator"
                data-e2e="new-limit-error"
                *ngIf="
                  (ChangeLimitForm.controls['newLimit']?.invalid &&
                    ChangeLimitForm.controls['newLimit']?.touched) ||
                  (submitted && ChangeLimitForm.controls['newLimit']?.invalid)
                "
              >
                {{
                  generateMessage(ChangeLimitForm.controls['newLimit'].errors)
                }}
              </div>
            </div>
          </div>

          <div class="formfield col">
            <label data-e2e="case-label" for="approvedCaseNumber"
            >Nº do caso aprovado</label
            >
            <div class="formInput">
              <input
                data-e2e="case-input"
                id="approvedCaseNumber"
                (keypress)="numberOnly($event)"
                type="text"
                formControlName="approvedCaseNumber"
              />
              <div
                class="alertErrorValidator"
                data-e2e="case-error"
                *ngIf="
                  (ChangeLimitForm.controls['approvedCaseNumber']?.invalid &&
                    ChangeLimitForm.controls['approvedCaseNumber']?.touched) ||
                  (submitted &&
                    ChangeLimitForm.controls['approvedCaseNumber']?.invalid)
                "
              >
                {{
                  generateMessage(
                    ChangeLimitForm.controls['approvedCaseNumber'].errors
                  )
                }}
              </div>
            </div>
          </div>

          <div class="formfield col">
            <label data-e2e="approver-label" for="approver">Aprovador</label>
            <div class="formInput">
              <input
                data-e2e="approver-input"
                id="approver"
                type="text"
                formControlName="approver"
              />
              <div
                class="alertErrorValidator"
                data-e2e="apporver-error"
                *ngIf="
                  (ChangeLimitForm.controls['approver']?.invalid &&
                    ChangeLimitForm.controls['approver']?.touched) ||
                  (submitted && ChangeLimitForm.controls['approver']?.invalid)
                "
              >
                {{
                  generateMessage(ChangeLimitForm.controls['approver'].errors)
                }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- passo 2 - confirmação -->
  <div class="modal-body step2" *ngIf="step == 2">
    <div data-e2e="check-title" class="title">
      {{
        ChangeLimitForm.controls['newLimit'].value > contract.creditLimit
          ? 'Aumentar'
          : 'Diminuir'
      }}
      valor?
    </div>

    <div data-e2e="check-text" class="message">
      Confirma
      {{
        ChangeLimitForm.controls['newLimit'].value > contract.creditLimit
          ? 'o aumento'
          : 'a redução'
      }}
      do limite contratado pelo cliente de
      {{
        contract.creditLimit | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
      }}
      para
      <span
      >{{
          ChangeLimitForm.controls['newLimit'].value
            | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
        }}?</span
      >
    </div>
  </div>

  <!-- passo 3 - retorno -->
  <div class="modal-body step3" *ngIf="step == 3">
    <div class="row confirmation">
      <div class="col-5 image-content">
        <img src="/assets/img/bg-place.svg" />
      </div>
      <div class="col-7 confirmation-text">
        <h3 data-e2e="confirmation-title" translate>
          Limite
          {{
            ChangeLimitForm.controls['newLimit'].value > contract.creditLimit
              ? 'aumentado'
              : 'reduzido'
          }}
        </h3>

        <div class="row-box-yellow yellow">
          <span data-e2e="warning-box" class="box-yellow yellow">
            <b>A</b>lteração de limite contratado realizada com sucesso!<br />
            Caso o cliente possua tags bloqueadas por falta de saldo,<br />
            o desbloqueio poderá levar até uma hora para ser processado.
          </span>
        </div>

        <div class="row mt-4">
          <div class="col-12 row">
            <span data-e2e="previews-label" class="col">Valor anterior: </span>
            <span data-e2e="previews" class="previousValue col">
              {{
                contract.creditLimit
                  | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
              }}
            </span>
          </div>

          <div class="col-12 row">
            <span data-e2e="current-label" class="col">Valor atual: </span>
            <span data-e2e="current" class="currentValue col">
              {{
                ChangeLimitForm.controls['newLimit'].value
                  | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
              }}
            </span>
          </div>

          <div class="author col-12">
            <p data-e2e="request-date">
              {{
                changeLimitResponseDto.data.requestDate
                  | date: 'dd/MM/yyyy HH:mm'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer modal-footer-gray">
    <button
      type="button"
      class="btn btn-secondary"
      data-e2e="close-modal-btn"
      *ngIf="step == 3"
      (click)="close()"
    >
      Fechar
    </button>

    <button
      type="button"
      data-e2e="close-modal-btn"
      *ngIf="step == 1 || step == 2"
      class="btn btn-tertiary mr-4"
      (click)="close()"
    >
      Cancelar
    </button>

    <button
      *ngIf="step == 1"
      class="btn btn-secondary"
      data-e2e="submit-btn"
      [disabled]="ChangeLimitForm.invalid"
      (click)="changeLimit(1)"
    >
      Alterar limite
    </button>

    <button
      *ngIf="step == 2"
      class="btn btn-secondary"
      [disabled]="isLoading"
      data-e2e="next-step-btn"
      (click)="changeLimit(2)"
    >
      Confirmar
      <span *ngIf="isLoading">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </span>
    </button>
  </div>
</div>
