import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TagService } from "src/app/core/services/tag.service";
import { Tag } from "@models/tag.model";
import { AddTagModalComponent } from "@modules/customer/tagactivation/components/add-tag-modal/add-tag-modal.component";
import { CONTRACT_STATUS_TYPE } from "src/app/shared/constants/contract-status-type";

@Component({
  selector: "app-tags-disponiveis",
  templateUrl: "./tags-disponiveis.component.html",
  styleUrls: ["./tags-disponiveis.component.scss"],
})
export class TagsDisponiveisComponent implements OnInit {
  listVerifyContractStatus = [
    CONTRACT_STATUS_TYPE.BLOCKED_LACK_OF_BALANCE,
    CONTRACT_STATUS_TYPE.BLOCKED_FINANCIAL,
    CONTRACT_STATUS_TYPE.FIRST_ACCESS
  ];

  @Output() changeTag = new EventEmitter();

  @Input() tagSelected: any;
  @Input() hasCreditCard;
  @Input() tagselected: any;
  @Input() elementEmpty = '';

  listTagAvailable: Tag[] = [];
  tagsearch: string;
  newtag: string;
  newtagadded: Tag[] = [];
  bsModalRef: BsModalRef;
  hideElement = false;

  static refreshList = new EventEmitter<string>();

  constructor(
    private _service: TagService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.getTagAvailableByContractId();
    TagsDisponiveisComponent.refreshList.subscribe((success) => {
      this.getTagAvailableByContractId();
    });
    this.hideElements();
  }

  hideElements() {
    if (!this.elementEmpty) {
      this.hideElement = true;
    }
  }

  onchangeTag() {
    this.changeTag.emit(this.tagSelected);
  }

  selectTag() {
    if (this.newtag) {
      this.tagSelected = this.listTagAvailable.find(
        (el) => el.serial == this.newtag
      );
      this.changeTag.emit(this.tagSelected);
    }
  }

  getTagAvailableByContractId() {
    this._service.getTagAvailableByContractId().subscribe(
      (result) => {
        this.listTagAvailable = result;
        this.selectTag();
      },
      (errors) => {
        console.log(`Promise rejeitada: ${JSON.stringify(errors)}`);
      }
    );
  }

  showAddTagModal() {
    this.bsModalRef = this.modalService.show(AddTagModalComponent);
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.event.subscribe((result) => {
      if (result != null) {
        setTimeout(() => {
          if (result) {
            this.newtag = this.bsModalRef.content.serial;
            this.getTagAvailableByContractId();
          }
        }, 100);
      }
    });
  }
}
