import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { BillServicesQueryArguments } from '@modules/customer/bill/dto/bill-services-query.arguments';
import { OtherServices } from '@models/old/financial/bill/bill-detail/bill-detail-general/bill-services/otherServices.model';
import { BillHierarchyBreadcrumbResultDto } from '@modules/customer/bill/dto/bill-hierarchy-breadcrumb-result.dto';
import { BillDetailService } from '@modules/customer/bill/components/bill-detail/services/bill-detail.service';
import moment from 'moment';
import {BillServiceDto} from "@modules/customer/bill/dto/bill-services-dto";
import { BILL_ITEM_TYPE } from 'src/app/shared/constants/bill-item-type';
import {ContractService} from "@services/contract.service";

@Component({
  selector: 'app-bill-services',
  templateUrl: './bill-services.component.html',
  styleUrls: ['./bill-services.component.scss']
})
export class BillServicesComponent implements OnInit {
  public otherServices: OtherServices[] = [];
  public servicesControl: any;
  billId: number;
  servicesQuery: BillServicesQueryArguments;
  billServiceResult: any; //BillServicesResultDto;
  getBreadcrumbResult: BillHierarchyBreadcrumbResultDto;
  isLoading: boolean;
  messageLoader: string = 'Carregando...';
  filterDebounce: Subject<BillServicesQueryArguments> = new Subject<BillServicesQueryArguments>();
  perPages = [];
  description: string;
  isShownPeriod: boolean = false;
  isShownValue: boolean = false;
  isShownHierarchy: boolean = false;
  isShowServiceType:boolean = false;
  open: boolean;
  isOpenDropdown: boolean;
  hierarchySelected: any = 'Selecione o grupo';
  ServiceTypeSelected: any = 'Escolha um tipo';
  contractModalityTypeId:any;
  pageMonthlyPaymentId: number[] = [];
  prePaidPageOtherServicePaymentId: number[] = [8,9,14,17,20];
  postPaidPageOtherServicePaymentId: number[] = [8,9,6,15,4,21];
  isPageMonthlyPayment: boolean;
  readonly BILL_ITEM_TYPE = BILL_ITEM_TYPE;

  constructor(private billDetail: BillDetailService,
              private actRoute: ActivatedRoute,
              private contractService: ContractService) {
    //Modalidade do contrato
    this.contractModalityTypeId = this.contractService.getContractModalityTypeId();
  }

  ngOnInit(): void {
    this.billId = this.actRoute.snapshot.params.id;
    this.perPages = [10, 20, 50];
    //Mensalidade de tag ativa, o id muda dependendo da modalidade do contrato (PrePago = 16, PosPago = 3)
    this.pageMonthlyPaymentId = [this.contractModalityTypeId == 1 ? 16 : 3];

    this.billServiceResult = {
      total: 0,
      services: [],
      totalMonthlyPayment: 0,
      totalOtherServices: 0
    };
    this.perPages = [10, 20, 50];
    this.servicesQuery = new BillServicesQueryArguments();
    this.servicesQuery.id = this.billId;
    this.servicesQuery.page = 1;
    this.servicesQuery.sortField = 'licensePlate';
    this.servicesQuery.pageSize = 10;
    this.servicesQuery.desc = false;
    this.servicesQuery.search = '';
    this.isPageMonthlyPayment = true;
    this.servicesQuery.BillItemTypeIdArray = this.pageMonthlyPaymentId;
    this.filterDebounce.pipe(debounceTime(300)).subscribe((filter) => this.getBillServices(filter));
    this.getBillServices(this.servicesQuery);
    this.getHierarchyBreadcrumb();

  }

  onRadioChange($event) {
    this.isPageMonthlyPayment = $event.value === '0';
    this.servicesQuery.page = 1;
    if (this.isPageMonthlyPayment) {
      this.servicesQuery.sortField = 'licensePlate';
      this.servicesQuery.desc = false;
      this.servicesQuery.BillItemTypeIdArray = this.pageMonthlyPaymentId;
    }
    else {
      this.servicesQuery.sortField = 'DateProcessing';
      this.servicesQuery.desc = true;
      this.servicesQuery.BillItemTypeIdArray =
      this.contractModalityTypeId == 1 ? this.prePaidPageOtherServicePaymentId : this.postPaidPageOtherServicePaymentId;
    }
    this.servicesQuery.search = null;
    this.getBillServices(this.servicesQuery);
  }

  PrePaidBillItemTypes: Array<any> = [
    { id: 8, description: 'Aquisição de Tag' },
    { id: 9, description: 'Frete' },
    { id: 14, description: 'Taxa de Recarga' },
    { id: 17, description: 'Mensalidade de tag em estoque' },
    { id: 20, description: 'Estorno Taxa de Recarga' },
  ];

  PostPaidBillItemTypes: Array<any> = [
    { id: 8, description: 'Aquisição de Tag' },
    { id: 9, description: 'Frete' },
    { id: 6, description: 'Serviço de antecipação de vale-pedágio'},
    { id: 15, description: 'Taxa sobre volume'},
    { id: 4, description: 'Mensalidade de tag em estoque' },
    { id: 21, description: 'Serviço de Integração de Dados' }
  ];

  isChecked(id){
    if(this.servicesQuery.BillItemTypeIdArray == this.prePaidPageOtherServicePaymentId ||
      this.servicesQuery.BillItemTypeIdArray == this.postPaidPageOtherServicePaymentId
      ){
        this.servicesQuery.BillItemTypeIdArray = [];
       }
   let isChecked = this.servicesQuery.BillItemTypeIdArray.indexOf(id) !== -1 ? true : false;
   return isChecked;
  }

  onCheckboxChange($event){
    let index = this.servicesQuery.BillItemTypeIdArray.indexOf(Number($event.source.value))

    if(this.servicesQuery.BillItemTypeIdArray == this.prePaidPageOtherServicePaymentId ||
      this.servicesQuery.BillItemTypeIdArray == this.postPaidPageOtherServicePaymentId
      ){
        this.servicesQuery.BillItemTypeIdArray = [];
      }

     if($event.checked){
      this.servicesQuery.BillItemTypeIdArray.push(Number($event.source.value))
     }
     else{
      this.servicesQuery.BillItemTypeIdArray.splice(index,1)
     }

     if(this.servicesQuery.BillItemTypeIdArray.length == 0){
      this.servicesQuery.BillItemTypeIdArray =
      this.contractModalityTypeId == 1 ? this.prePaidPageOtherServicePaymentId : this.postPaidPageOtherServicePaymentId;
     }

     this.getBillServices(this.servicesQuery);
  }

  getBillServices(args: BillServicesQueryArguments){
    this.isLoading = true;
    this.billDetail.getBillServices(args)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success) => {
        this.billServiceResult = success;
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.servicesQuery.sortField == field && !this.servicesQuery.desc,
      'order-by-desc': this.servicesQuery.sortField == field && this.servicesQuery.desc
    }
  }

  onOrderByChangeSelect(event: any) {
    this.onOrderByChange(event.target.value);
  }
  onOrderByChange(field: string) {
    if (this.servicesQuery.sortField === field) {
      this.servicesQuery.desc = !this.servicesQuery.desc;
    } else {
      this.servicesQuery.sortField = field;
      this.servicesQuery.desc = true;
    }
    this.servicesQuery.page = 1;
    this.getBillServices(this.servicesQuery);

  }
  onPaginationChange(page: number) {
    if(this.servicesQuery.page !== page){
      this.servicesQuery.page = page;
      this.getBillServices(this.servicesQuery);
    }
  }

  onPageSizeChange(value: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.servicesQuery.page = 1;
    this.servicesQuery.pageSize = value;
    this.getBillServices(this.servicesQuery);
  }
  onSearchClick(event: any) {
    this.servicesQuery.page = 1;
    this.getBillServices(this.servicesQuery);
  }
  onFullNameKeyup(value: any) {
    this.servicesQuery.page = 1;
    this.servicesQuery.search = value.replace("-","");
    this.filterDebounce.next(this.servicesQuery);
    this.getBillServices(this.servicesQuery);

  }

  toggleShowServiceType(){
    this.isShowServiceType = !this.isShowServiceType;
  }

  toggleShowHierarchy() {
    this.isShownHierarchy = !this.isShownHierarchy;
  }
  toggleDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
    this.isShownPeriod = false;
    this.open = false;
    this.isShownHierarchy = false;
    this.isShowServiceType = false;
    this.isShownValue = false;
  }
  getHierarchyBreadcrumb() {
    this.billDetail
      .getHierarchyBreadcrumb()
      .pipe(
        finalize(() => {

        })
      )
      .subscribe(
        (success) => {
          this.getBreadcrumbResult = success;
        },
        (error) => {
          return;
        }
      );
  }

  onApplyHierarchyFilter(hierarchy){
    this.description = hierarchy.description_PT;
    this.servicesQuery.hierarchyId = hierarchy.id;
    this.getBillServices(this.servicesQuery);
    this.isShownHierarchy = false;
    this.hierarchySelected = this.description;
  }

  resetFilters(){
    this.hierarchySelected = null;
    this.servicesQuery.hierarchyId = null;
    this.servicesQuery.page = 1;
    this.servicesQuery.search = null;
    this.servicesQuery.BillItemTypeIdArray =
    this.contractModalityTypeId == 1 ? this.prePaidPageOtherServicePaymentId : this.postPaidPageOtherServicePaymentId;
    this.getBillServices(this.servicesQuery);
  }

  getTooltipText(billItemTypeId: number): string {
    const isDisabled = this.isBtnDetailsDisabled(billItemTypeId);

    return isDisabled ?
      'Sem detalhes disponíveis' :
      'Ver detalhes';
  }

  private downloadDetailsIntegrationService(billItem: BillServiceDto) {
    this.messageLoader = 'Download...';
    this.isLoading = true;
    let now = new Date;
    let date = moment(now).format('DDMMYYYY');

    this.billDetail
      .getDataIntegrationServiceCsv(billItem.billId, billItem.contractId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.messageLoader = 'Carregando...';
        })
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = `Requisições_fatura${billItem.billId}_${date}.csv`;
        element.click();
      });
  }

  handleBtnDetailsActionByBillItemType(billItem: BillServiceDto) {
    switch (billItem.billItemTypeId) {

      case BILL_ITEM_TYPE.SERVICO_INTEGRACAO_DADOS:
        this.downloadDetailsIntegrationService(billItem);
        break;

      case BILL_ITEM_TYPE.MENSALIDADE_TAG_ESTOQUE:
      case BILL_ITEM_TYPE.MENSALIDADE_TAG_ESTOQUE_PRE_PAGO:
        this.downloadDetailsTagInStockMonthly(billItem);
        break;

      default:
        break;
    }
  }

  isBtnDetailsDisabled(billItemTypeId: number): boolean {
    return ![
      BILL_ITEM_TYPE.SERVICO_INTEGRACAO_DADOS,
      BILL_ITEM_TYPE.MENSALIDADE_TAG_ESTOQUE,
      BILL_ITEM_TYPE.MENSALIDADE_TAG_ESTOQUE_PRE_PAGO
    ].includes(billItemTypeId);
  }

  private downloadDetailsTagInStockMonthly(billItem: BillServiceDto) {
    this.messageLoader = 'Download...';
    this.isLoading = true;

    this.billDetail
      .getTagInStockMonthlyCsv(billItem.billId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.messageLoader = 'Carregando...';
        })
      )
      .subscribe((data) => {
        this.handleFileTagInStockMonthly(data, billItem);
      });
  }

  private handleFileTagInStockMonthly(data: any, billItem: BillServiceDto) {
    const now = new Date;
    const date = moment(now).format('DDMMYYYY');
    const element = document.createElement('a');
    var newBlob = new Blob([data.file], { type: 'text/csv' });

    element.href = URL.createObjectURL(newBlob);
    element.download = `Tags_estoque_${billItem.billId}_${date}.csv`;
    element.click();
  }
}
