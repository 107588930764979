<div class="ActionSuccesExcludeComponent">
  <div class="modal-header">

      @if(type == 'exclude'){
        <h4 class="modal-title" id="modal-title">EXCLUIR FERIADO</h4>
      } @else {
        <h4 class="modal-title" id="modal-title">INCLUIR FERIADO</h4>
      }

      <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
        <img src="assets/svg/close.svg" alt="Ícone de X"/>
      </a>

  </div>
  <div class="modal-body">
    <img src="/assets/svg/success-circle-blue.svg" alt="">
    @if(type == 'exclude'){
      <p class="primary-color-bold"><span>{{holiday.name}}</span> em <span>{{holiday.holidayDate | date: "dd/MM/yyyy"}}</span> excluído com sucesso!</p>
    } @else {
      @if(many){
        <p class="primary-color-bold">Feriados cadastrados com sucesso!</p>
      } @else {
        <p class="primary-color-bold">Feriado cadastrado com sucesso!</p>
      }
    }
    @if(type == 'include'){
      <span>{{success.registrationDate | date: 'dd/MM/yyyy - HH:mm'}} por {{success.fullName}}</span>
    }

    @if(type == 'exclude'){
      <span>{{success.changeDate | date: 'dd/MM/yyyy - HH:mm'}} por {{success.fullName}}</span>
      <span class="protocol"><b>Protocolo</b> {{success.protocol}}</span>
    }
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-cancel"
      data-e2e="modal-btn-cancel"
      (click)="dismissModal()"
    >
      Fechar
    </button>
  </div>
</div>
