import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormValidatorMessageService {

 returnMessage(formControl){
     if(formControl.errors){
               //requerido
                if(formControl.errors.required){
                    return 'Campo obrigatório'
                }
                else if(formControl.errors.pattern){
                    //E-mail
                    if(formControl.errors.pattern.requiredPattern == "^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$"){
                         return 'O e-mail informado não é válido. Por favor informe um e-mail válido.'
                     }
                     else if(formControl.errors.pattern.requiredPattern == '/^[0-9]\\d*$/'){
                        return 'Código da fatura Inválido.'
                     }
                     else{
                        return 'Inválido.'
                     }
                }
                //confirmação de e-mail
                else if(formControl.errors.emailConfirmationInvalid){
                     return 'O novo e-mail e a confirmação do novo e-mail devem ser iguais.'
                }
                   // telefone (Fixo ou celular)
                   else if(formControl.errors.telephoneInvalid){
                    return 'Número de telefone inválido.'
                }
                   // telefone (Fixo ou celular) ja cadastrado
                   else if(formControl.errors.sameTelephone){
                    return 'Número de telefone já cadastrado.'
                }
                   // mínimo caracteres
                   else if(formControl.errors.minlength){
                    return 'mínimo '+formControl.errors.minlength.requiredLength+' caracter(es).'
                }
                //máximo caracteres
                else if(formControl.errors.maxlength){
                    return 'máximo '+formControl.errors.maxlength.requiredLength+' caracter(es).'
                }
                // Placa
                else if(formControl.errors.licensePlateInvalid){
                    return  'Placa inválida.'
                }
                // Data
                else if(formControl.errors.DateInvalid || (formControl.errors.bsDate && formControl.errors.bsDate.invalid == 'Invalid Date')){
                    return  'Data inválida.'
                }
                // Data futura
                else if(formControl.errors.futureDate){
                    return 'Data não pode ser futura.'
                }
                // Hora
                else if(formControl.errors.HourInvalid){
                    return  'Hora inválida.'
                }
                // CEP
                else if(formControl.errors.zipcodeInvalid){
                    return  'CEP inválido.'
                }
                //CPF
                else if(formControl.errors.cpfInvalid){
                    return  'CPF inválido.'
                }
                //Documento (CPF ou CNPJ)
                else if(formControl.errors.documentInvalid){
                    return  'Documento inválido.'
                }
                  //Documento (CPF ou CNPJ)
                  else if(formControl.errors.documentInvalid){
                    return  'Documento inválido.'
                }
                else{
                    return  'Inválido.'
                }
        }
       else{
           return;
       }
}

  generateErrorMessageByFormControlName(controlName: string): string {
    switch (controlName) {
      case 'fullName':
        return 'Informe um nome válido.';
        break;

      case 'documentNumber':
        return 'Informe um documento válido.';
        break;

      case 'dateOfBirthDay':
        return 'Informe um dia válido.';
        break;

      case 'dateOfBirthMonth':
        return 'Informe um mês válido.';
        break;

      case 'dateOfBirthYear':
        return 'Informe um ano válido.';
        break;

      case 'telephoneNumber':
        return 'Número de telefone inválido. Por favor, informe um telefone válido.';
        break;

      case 'emailAddress':
        return 'O e-mail informado é inválido. Por favor, informe um e-mail válido.';
        break;

      case 'protocol':
        return 'Informe um número de protocolo válido.';
        break;

      default:
        return 'Informe um valor válido.';
        break;
    }
  }

}
