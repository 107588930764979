import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FiltersService } from '../../filters.service';
import { FiltersComponent } from '../../filters.component';
import { NotifyService } from '@services/notify.service';
import { EFiltersIds } from "@models/filters-backoffice.model";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['../../filters.component.scss', './date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input() labelTitle: string = 'Período';
  @Input() idFilter: EFiltersIds;
  @Output() getFilter = new EventEmitter<Date[]>();
  @Input() textValue: string;

  radioSelected: number;
  sub$: Subscription;
  periodRange: number[] = [7, 15, 30];
  selectedPeriod: Date[];
  // selectedDate: Date;
  notificationEmitted: boolean;
  isTransactionDate: boolean;
  isExpectedClearingDate: boolean;

  constructor(
    private _filtersService: FiltersService,
    public filtersComponent: FiltersComponent,
    private _notifyService: NotifyService,
    private localeService: BsLocaleService
  ) { }

  ngOnInit(): void {
    // Check which filter is being used
    this.isTransactionDate = this.idFilter === EFiltersIds.transactionDate;
    this.isExpectedClearingDate =
      this.idFilter === EFiltersIds.expectedClearingDate;

    this.getFromObservable(); // Get values from service
    this.localeService.use('pt-br'); // Define a localidade para português do Brasil
  }

  getFromObservable() {
    // first filter value from observable
    let textTransactionService =
      this._filtersService.transactionDate$.getValue();
    let datesTransaction: Date[] = this._filtersService.formatTextToDate(
      textTransactionService,
    );

    // second filter value
    let textExpectedService =
      this._filtersService.expectedClearingDate$.getValue();

    let datesExpected: Date[] =
      this._filtersService.formatTextToDate(textExpectedService);

    if (this.isTransactionDate && this.textValue === 'Selecione') {
      // get previous value to appear checked
      this.onPeriodSelection(
        this._filtersService.periodRadioTransaction$.getValue(),
      );
    } else if (
      this.isTransactionDate &&
      this._filtersService.transactionDate$.value !== 'Selecione'
    ) {
      this.selectedPeriod = [datesTransaction[0], datesTransaction[1]];
      this.textValue = this._filtersService.formatDateToText(
        datesTransaction[0],
        datesTransaction[1],
      );
    }

    if (
      this.isExpectedClearingDate &&
      this._filtersService.expectedClearingDate$.value !== 'Selecione'
    ) {
      this.selectedPeriod = [datesExpected[0], datesExpected[1]];
      this.textValue = this._filtersService.formatDateToText(
        datesExpected[0],
        datesExpected[1],
      );
    }
  }

  showFilterContent() {
    this._filtersService.activeFilter = this.idFilter;
    this.filtersComponent.closeOtherFilters(this.idFilter);
  }

  onDateSelection(date: Date[]) {
    let fromDate = date[0];
    fromDate.setHours(0, 0, 0, 0);

    let toDate = date[1];
    toDate.setHours(23, 59, 59, 0);

    if (this.getDiffBetweenDates(fromDate, toDate) > 90) {
      if (!this.notificationEmitted) {
        this._notifyService.showError(
          'Período Inválido',
          'Consulta não pode ser maior que 90 dias',
        );
        this.notificationEmitted = true;
      }
    } else {
      this.getFilter.emit([fromDate, toDate]);

      this.selectedPeriod = [fromDate, toDate];
      this.textValue = this._filtersService.formatDateToText(fromDate, toDate);

      const isDateInRange = this.periodRange.some((period) => {
        const fromDateRange = new Date();
        fromDateRange.setHours(0, 0, 0, 0);
        fromDateRange.setDate(fromDateRange.getDate() - period);
        const toDateRange = new Date();
        this.radioSelected = period;
        return (
          date[0].getDate() == fromDateRange.getDate() &&
          date[1].getDate() == toDateRange.getDate()
        );
      });

      // If date is out of range, uncheck the radio
      if (!isDateInRange) {
        this.radioSelected = null;
      }
      this.notificationEmitted = false;
    }
  }

  onPeriodSelection(period: number) {
    this.radioSelected = period;
    if (this.isTransactionDate) {
      this._filtersService.periodRadioTransaction$.next(period);
    }
    if (this.isExpectedClearingDate) {
      this._filtersService.periodRadioExpected$.next(period);
    }

    let fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);
    let toDate = new Date();
    toDate.setHours(23, 59, 59, 0);

    fromDate.setDate(fromDate.getDate() - period);
    this.getFilter.emit([fromDate, toDate]);

    this.selectedPeriod = [fromDate, toDate];
    this.textValue = this._filtersService.formatDateToText(fromDate, toDate);
  }

  getDiffBetweenDates(fromDate: Date, toDate: Date): number {
    let diffMilis = toDate.getTime() - fromDate.getTime();
    let diffDays = diffMilis / (1000 * 60 * 60 * 24);
    return diffDays;
  }
}
