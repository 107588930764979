export class EmailUpdateToken{
  personEmailAddressId?: number
  newEmail: string
  confirmationEmail: string
}

export class PhoneUpdateToken{
  userId?: number
  personPhoneTypeId?: number
  personEmailAddressId?: number
  telephoneNumber: number
  countryCode: number
  areaCode: number
}

export class UpdateContractRegistration{
  id: number
  code:string
  email?: string
  telephoneNumber?: string
  countryCode?: string
  areaCode?: string
  personPhoneTypeId?: number
}

export enum UpdateType{
   email=1,
   phone=2
}


