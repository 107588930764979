import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { TagRequestPrepaid } from "@models/old/tagrequest/tagrequest.model";
import { PaymentService } from "@services/payment.service";
import { EventEmitterService } from "@services/event-emitter.service";


@Component({
  selector: "app-try-again-modal",
  templateUrl: "./try-again-modal.component.html",
  styleUrls: ["./try-again-modal.component.scss"],
})
export class TryAgainModalComponent implements OnInit, OnDestroy {
  @Input() orderStatus: any;
  @Input() orderDetail: any;

  step: number = 1;
  tagRequestPrepaid$: Observable<TagRequestPrepaid>;
  success: TagRequestPrepaid;
  onSaving: boolean = false;
  subs: Subscription;
  updateStatus;

  constructor(public modal: NgbModal, public _service: PaymentService) {
    this.tagRequestPrepaid$ = this._service.updateOrder$;
  }

  ngOnInit(): void {
    this.subs = this._service.updateStatus$.subscribe(
      (data) => (this.updateStatus = data)
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  confirmOrder() {
    this.onSaving = true;
    this._service
      .postReprocessTagOrder(this.orderDetail.orderId)
      .pipe(
        finalize(() => {
          this.onSaving = false;
        })
      )
      .subscribe((res) => {
        EventEmitterService.get("RefreshAfterTryAgainOrder").emit();
        this.step = 2;
        this.success = res;
      });
  }
}
