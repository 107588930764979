import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '@services/payment.service';
import { CreditCardDto } from '../dtos/credit-card.dto';
import { LocalstorageService } from '@services/localstorage.service';
import { finalize } from 'rxjs';
import { FeedbackModalComponent } from '../../modals/feedback-modal/feedback-modal.component';
import { PrepaidConditions } from '@models/prepaid.model';

@Component({
  selector: 'app-exclude-credit-card-modal',
  templateUrl: './exclude-credit-card-modal.component.html',
  styleUrls: ['./exclude-credit-card-modal.component.scss']
})
export class ExcludeCreditCardModalComponent {
  @Output() event: EventEmitter<any> = new EventEmitter();
  @Input() PrepaidConditions: PrepaidConditions;
  constructor(
    public modal: NgbActiveModal,
    private _paymentService: PaymentService,
    private _localStorageService : LocalstorageService,
    private _modalService : NgbModal
  ) {}

  isLoading: boolean = false;
  submitted: boolean = false;
  CreditCard: CreditCardDto;
  prepaidConditions: PrepaidConditions;
  contractID = this._localStorageService.getContractId();
  paymentMethodID : number = this._paymentService.paymentMethodSelected;



  excludeCreditCard(){
    this.isLoading = true;

    this._paymentService.excludeCreditCard(Number(this.contractID))
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success) => {
        this.modal.close();

        const modal = this._modalService.open(FeedbackModalComponent);
        modal.componentInstance.title = "Cartão excluído";
        modal.componentInstance.subtitle = "Seu cartão foi excluído com sucesso!";
        modal.componentInstance.imgPath = "assets/svg/feedback-positive.svg";
        modal.componentInstance.event.subscribe(() => {
          this.event.emit();
        })
      },
      (error) => {
        if (
              error.error.success == false
              && error.error.message == "Não foi possível excluir o cartão"
              && error.error.data == "Transações em andamento"
            ) {
          this.modal.close();

          const modal = this._modalService.open(FeedbackModalComponent);
          modal.componentInstance.title = "Não foi possível excluir seu cartão";
          modal.componentInstance.subtitle = "Existem transações em andamento.";
          modal.componentInstance.message = "Não foi possível excluir o cartão de crédito no momento, pois há transações em andamento. Aguarde até que a transação seja concluída e tente novamente.";
        }
        console.error("error", error)
      }
    )
  }
}
