import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { GetTicketsQueryArguments } from '../dtos/getTicketsQueryArguments.dto';
import { GetTicketsQueryResultDto } from '../dtos/getTicketsQueryResult.dto';
import { GetContractVehiclesQueryResultDto } from '../dtos/getContractVehicles.dto';
import { GetFromVehiclesQueryResult } from '../dtos/getFromVehiclesQueryResult.dto';
import { GetContractHierarchiesQueryResultDto } from '../dtos/getContractHierarchies.dto';
import { GetContractHierarchiesQueryArguments } from '../dtos/getHierarchiesQueryArguments.dto';
import { GetFromHierarchiesQueryResult } from '../dtos/getFromHierarchiesQueryResult.dto';
import { GetHierarchyBreadcrumbDto } from '../dtos/getHierarchyBreadcrumb.dto';
import { GetContractVehiclesQueryArguments } from '../dtos/searchContractVehiclesQueryArguments.dto';
import { GetTransactionsVehiclesQueryArguments } from '../dtos/getVehiclesQueryArguments.dto';

import { map } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(private http: HttpClient) { }

  private tabFilterValue = new BehaviorSubject<number>(0);


  // General - Query
  getGeneralQuery(args: GetTicketsQueryArguments) {
    return this.http.get<GetTicketsQueryResultDto>(environment.apiTMReport + `/ReportBillPassenger/GetTransactionProcessingGeneral`, {
      params: args.httpParans,
    });
  }

  // General - Filter hierarchy
  getHierarchyBreadcrumb() {
    return this.http.get<GetHierarchyBreadcrumbDto>(environment.apiTMVehicle + `/GroupHierarchy/GetHierarchyBreadCrumbList`);
  }

  // Vehicles - ContractVehicles
  getContractVehicles(args: GetContractVehiclesQueryArguments) {
    return this.http.get<GetContractVehiclesQueryResultDto>(environment.apiTMReport + `/ReportBillPassenger/GetContractVehicles`, {
      params: args.httpParans,
    });
  }

  // Vehicles - TransactionsVehicles
  getVehiclesQuery(licensePlate: string, args: GetTransactionsVehiclesQueryArguments) {
    return this.http.get<GetFromVehiclesQueryResult>(environment.apiTMReport + `/ReportBillPassenger/GetTransactionsByVehicle` + '?licensePlate=' + licensePlate, {
      params: args.httpParans,
    });
  }

  // Hierarchy - ContractHierarchies
  getContractHierarchies(args: GetContractHierarchiesQueryArguments) {
    return this.http.get<GetContractHierarchiesQueryResultDto>(environment.apiTMReport + `/ReportBillPassenger/GetContractHierarchies`, {
      params: args.httpParans,
    });
  }

  // Hierarchy - TransactionsHierarcies
  getHierarchiesQuery(hierarchyId: number, args: GetContractHierarchiesQueryArguments) {
    return this.http.get<GetFromHierarchiesQueryResult>(environment.apiTMReport + `/ReportBillPassenger/GetTransactionsByHierarchyId` + '?HierarchyId=' + hierarchyId, {
      params: args.httpParans,
    });
  }

  //Download CSV
  public GetCSVFile(args: GetTicketsQueryArguments) {
    let uri = environment.apiTMReport + `/ReportBillPassenger/DownloadCSVTransactionGeneral`;
    return this.http.get(uri, { observe: 'response', responseType: 'blob', params: args.httpParans })
      .pipe(map((response) => {
        let data = {
          file: new Blob([response.body], { type: response.headers.get('Content-Type') }),
          filename: response.headers.get('filename'), // pegar nome do arquivo caso content-disposition esteja acessível.
        }
        return data;
      }
      ));
  }

  getFilterObservable() {
    return this.tabFilterValue.asObservable();
  }

  setFilterObservable(value : number){
    this.tabFilterValue.next(value);
  }
}
