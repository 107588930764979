import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

import { VehicleBalanceVPRService } from './services/saldovpr-veiculos.component.service';
import { GetVehiclesBalanceVPRQueryResultDto } from './dtos/get-vehiclesbalancevpr-query-result.dto';
import { GetVehiclesBalanceVPRQueryArguments } from './dtos/get-vehiclesbalancevpr-query-arguments.dto';

@Component({
  selector: 'app-saldovpr-veiculos',
  templateUrl: './saldovpr-veiculos.component.html',
  styleUrls: ['./saldovpr-veiculos.component.scss']
})
export class SaldovprVeiculosComponent implements OnInit {
  isLoading: boolean;
  fromSearchBar: boolean = false;
  getVehiclesBalanceVPRQueryArguments: GetVehiclesBalanceVPRQueryArguments;
  filterDebounce: Subject<GetVehiclesBalanceVPRQueryArguments> = new Subject<GetVehiclesBalanceVPRQueryArguments>();
  getVehiclesBalanceVPRQueryResult: GetVehiclesBalanceVPRQueryResultDto;

  vehicles: Array<any>;
  perPages = [];

  get csvFilters() {
    const { pageSize, ...filters } = this.getVehiclesBalanceVPRQueryArguments;

    return filters;
  }

  constructor(private vehicleBalanceVPRService: VehicleBalanceVPRService) {
    this.perPages = [10, 20, 50];
    this.getVehiclesBalanceVPRQueryResult = {
      hasGreatherThanZero: true,
      total: null,
      items: []
    }
    this.isLoading = true;
    this.getVehiclesBalanceVPRQueryArguments = new GetVehiclesBalanceVPRQueryArguments();
    this.getVehiclesBalanceVPRQueryArguments.page = 1;
    this.getVehiclesBalanceVPRQueryArguments.pageSize = 10; // TODO: Pegar do componnete verificar
    this.getVehiclesBalanceVPRQueryArguments.orderBy = "licensePlate";
    this.getVehiclesBalanceVPRQueryArguments.desc = false;
    this.getVehiclesBalanceVPRQueryArguments.IsOnlyGreaterThanZero = false;
    this.getVehiclesBalanceVPRQueryArguments.search = "";

    this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getFromServer(filter));
  }

  ngOnInit(): void {
    this.getFromServer(this.getVehiclesBalanceVPRQueryArguments);
  }

  getFromServer(args: any) {
    this.isLoading = true;
    this.vehicleBalanceVPRService
      .getVehiclesBalanceVPRQuery(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success:any) => {
          this.getVehiclesBalanceVPRQueryResult = success;
        },
        (error) => {
          // TODO: Depois que for implementado alertService descomentar a linha de baixo para tratar os retornos das apis
          //this.alertService.danger(error.message);
          console.error("getFromServerError", error);
          return;
        }
      );
  }

  onBuscarSaldoMaiorZero(value: any) {
    this.getVehiclesBalanceVPRQueryArguments.IsOnlyGreaterThanZero = value;
    this.getFromServer(this.getVehiclesBalanceVPRQueryArguments);
  }
  onSearch(event: any) {
    this.fromSearchBar = true;
    this.getFromServer(this.getVehiclesBalanceVPRQueryArguments);
  }
  onPaginationChange(page: number) {
    this.getVehiclesBalanceVPRQueryArguments.page = page;
    this.getFromServer(this.getVehiclesBalanceVPRQueryArguments);
  }

  onFullNameKeyup(value: any) {
    this.fromSearchBar = true;
    this.getVehiclesBalanceVPRQueryArguments.page = 1;
    this.getVehiclesBalanceVPRQueryArguments.search = value;
    this.filterDebounce.next(this.getVehiclesBalanceVPRQueryArguments);
  }

  onOrderByChange(field: string) {
    if (this.getVehiclesBalanceVPRQueryArguments.orderBy === field) {
      this.getVehiclesBalanceVPRQueryArguments.desc = !this.getVehiclesBalanceVPRQueryArguments.desc;
    } else {
      this.getVehiclesBalanceVPRQueryArguments.orderBy = field;
      this.getVehiclesBalanceVPRQueryArguments.desc = true;
    }

    this.getFromServer(this.getVehiclesBalanceVPRQueryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.getVehiclesBalanceVPRQueryArguments.orderBy == field && !this.getVehiclesBalanceVPRQueryArguments.desc,
      'order-by-desc': this.getVehiclesBalanceVPRQueryArguments.orderBy == field && this.getVehiclesBalanceVPRQueryArguments.desc
    }
  }

  onPageSizeChange(value: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.getVehiclesBalanceVPRQueryArguments.page = 1;
    this.getVehiclesBalanceVPRQueryArguments.pageSize = value;
    this.getFromServer(this.getVehiclesBalanceVPRQueryArguments);
  }

}
