import { Component } from "@angular/core";

import { Observable } from "rxjs";

import { ChartData, LineChartOptions } from "@models/charts.model";
import { ReportService } from "@services/report.service";
import { Filters } from "@models/filter.model";

export class ExpeditureData {
  constructor(
    public chart?: ChartData,
    public table?
  ) {}
}

@Component({
  selector: "app-fleet-expediture-evolution",
  templateUrl: "./fleet-expediture-evolution.component.html",
  styleUrls: ["./fleet-expediture-evolution.component.scss"],
})
export class FleetExpeditureEvolutionComponent {
  expediture$: Observable<ExpeditureData> = this._service.getExpetureEvolution();
  options = new LineChartOptions();
  filters: Filters;

  constructor(private _service: ReportService) {}

}
