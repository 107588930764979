import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SelectProfileComponent} from "@modules/commom/select-profile/select-profile.component";
import {selectProfileResolver} from "@modules/commom/select-profile/resolvers/select-profile.resolver";
import {authProfileGuard} from "../../../../core/auth/auth-profile.guard";
import {selectProfileCanDeactivateGuard} from "@modules/commom/select-profile/guards/select-profile.guard";

const routes: Routes = [
  {
    path: "selecionar-perfil",
    component: SelectProfileComponent,
    resolve: {profilesUser: selectProfileResolver},
    canActivate: [authProfileGuard],
    canDeactivate: [selectProfileCanDeactivateGuard],
    data: { roles: "", contractRequired: false },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SelectProfileRoutingModule {}
