import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr);
import {CommonModule, DatePipe} from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyvehiclesComponent } from './myvehicles.component';
import { MyvehiclesRoutingModule } from './routes/myvehicles-routing.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { AddVehicleComponent } from './components/add-vehicle-modal/add-vehicle.component';
import { ImportVehiclesModalComponent } from './components/import-vehicles-modal/import-vehicles-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AlterServiceModalComponent } from './components/alter-service-modal/alter-service-modal.component';
import { DeleteVehicleModalComponent } from './components/delete-vehicle-modal/delete-vehicle-modal.component';
import { TagBlockModalComponent } from './components/tag-block-modal/tag-block-modal.component';
import { CancelTagComponent } from './components/tag-cancel-modal/cancel-tag.component';
import { TagReplaceModalComponent } from './components/tag-replace-modal/tag-replace-modal.component';
import { TagUnlockModalComponent } from './components/tag-unlock-modal/tag-unlock-modal.component';
import { ConfirmationTagUnlockModalComponent } from './components/confirmation-tag-unlock-modal/confirmation-tag-unlock-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { ReincludeVehicleModalComponent } from './components/reinclude-vehicle-modal/reinclude-vehicle-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  TransferVehicleComponent
} from "@modules/customer/myvehicles/components/transfer-vehicle/transfer-vehicle.component";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {
  TransferSuccessComponent
} from "@modules/customer/myvehicles/components/transfer-vehicle/transfer-success/transfer-success.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {TranslateModule} from "@ngx-translate/core";
import {
  CancelTagBackOfficeComponent
} from "@modules/customer/myvehicles/components/cancel-tag-backoffice/cancel-tag-backoffice.component";
import {
  ConfirmationComponent
} from "@modules/customer/myvehicles/components/cancel-tag-backoffice/modals/confirmation/confirmation.component";
import {
  FeedbackComponent
} from "@modules/customer/myvehicles/components/cancel-tag-backoffice/modals/feedback/feedback.component";
import {
  TransferVprComponent
} from "@modules/customer/myvehicles/components/cancel-tag-backoffice/modals/transfer-vpr/transfer-vpr.component";
import {
  VehiclesByFilterComponent
} from "@modules/customer/myvehicles/components/vehicles-by-filter/vehicles-by-filter.component";
import {
    BorderlessButtonComponent
} from "../../../shared/components/button/button-without-border/borderless-button.component";
import {CustomDirectivesModule} from "@directives/custom-directives.module";
import { SearchFormInlineComponent } from 'src/app/shared/components/search-form-inline/search-form-inline.component';
import { OutlinedSelectComponent } from "../../../shared/select/outlined-select/outlined-select.component";
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    MatTabsModule,
    MyvehiclesRoutingModule,
    MatFormFieldModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatRadioModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    MatExpansionModule,
    TranslateModule,
    BorderlessButtonComponent,
    CustomDirectivesModule,
    SearchFormInlineComponent,
    OutlinedSelectComponent,
    MatCheckboxModule,
],
  declarations: [
    MyvehiclesComponent,
    AddVehicleComponent,
    ImportVehiclesModalComponent,
    AlterServiceModalComponent,
    DeleteVehicleModalComponent,
    TagBlockModalComponent,
    CancelTagComponent,
    TagReplaceModalComponent,
    TagUnlockModalComponent,
    ConfirmationTagUnlockModalComponent,
    ReincludeVehicleModalComponent,
    TransferVehicleComponent,
    TransferSuccessComponent,
    CancelTagBackOfficeComponent,
    FeedbackComponent,
    TransferVprComponent,
    ConfirmationComponent,
    VehiclesByFilterComponent
  ],
  providers: [provideNgxMask(), DatePipe],
})

export class MyvehiclesModule {}
