import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-tab-select',
  templateUrl: './tab-select.component.html',
  standalone: true,
  imports: [NgClass],
  styleUrl: './tab-select.component.scss'
})
export class TabSelectComponent {
  @Input() tabs: {
    heading: string, // texto da tab
    filters?: any | any[], // caso precise passar valores
    badges?: {left?: boolean, right?: boolean, value?: any, subtitle? : any} }[] = [];

  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();
  activeTabIndex: number = 0;

  selectTab(index: number) {
    this.activeTabIndex = index;
    if (this.tabs[index].filters) {
      this.applyFilter(this.tabs[index].filters);
    }
  }

  applyFilter(filters: any | any[]) {
    this.filterChange.emit(filters);
  }
}
