<div class="AddressConfirmDataComponent">
        <div class="row my-1">
            <div class="head-info" data-e2e="address-zipCode-label" translate>CEP:</div>
            <div class="content-info" data-e2e="address-zipCode-data" id="zipCode">{{ PersonAddressToConfirm?.zipCode | mask: 'AAAAA-AAA'}}</div>
        </div>
        <div class="row my-1" *ngIf="PersonAddressToConfirm?.addressTypeId == 1">
            <div class="head-info" data-e2e="address-streetAddress-label" translate>Endereço:</div>
            <div class="content-info" data-e2e="address-streetAddress-data" id="streetAddress">{{ PersonAddressToConfirm?.streetAddress }}</div>
        </div>
        <div class="row my-1" *ngIf="PersonAddressToConfirm?.addressTypeId == 1">
            <div class="head-info" data-e2e="address-number-label" translate>Número:</div>
            <div class="content-info" data-e2e="address-number-data" id="number">{{ PersonAddressToConfirm?.number }}</div>
        </div>
        <div class="row my-1" *ngIf="PersonAddressToConfirm?.addressTypeId == 1">
            <div class="head-info" data-e2e="address-district-label" translate>Bairro:</div>
            <div class="content-info" data-e2e="address-district-data" id="district">{{ PersonAddressToConfirm?.district }}</div>
        </div>
        <div class="row my-1">
            <div class="head-info" data-e2e="address-city-label" translate>Cidade:</div>
            <div class="content-info" data-e2e="address-city-data" id="city">{{ PersonAddressToConfirm?.city }}</div>
        </div>
        <div class="row my-1">
            <div class="head-info" data-e2e="address-federatedUnit-label" translate>Estado:</div>
            <div class="content-info" data-e2e="address-federatedUnit-data" id="federatedUnit">{{ PersonAddressToConfirm?.federatedUnit }}</div>
        </div>
        <div class="row my-1" *ngIf="PersonAddressToConfirm?.addressTypeId == 1  && PersonAddressToConfirm?.streetAddressLine2">
            <div class="head-info" data-e2e="address-streetAddressLine2-label" translate>Complemento:</div>
            <div class="content-info" data-e2e="address-streetAddressLine2-data" id="streetAddressLine2">{{ PersonAddressToConfirm?.streetAddressLine2 }}</div>
        </div>
        <div class="row my-1" *ngIf="PersonAddressToConfirm?.addressTypeId == 1 && PersonAddressToConfirm?.landmark">
            <div class="head-info" data-e2e="address-landmark-label" translate>Referência:</div>
            <div class="content-info" data-e2e="address-landmark-data" id="landmark">{{ PersonAddressToConfirm?.landmark }}</div>
        </div>
        <div class="row my-1" *ngIf="PersonAddressToConfirm?.addressTypeId == 2">
            <div class="head-info" data-e2e="address-mailbox-label" translate>Código de caixa postal:</div>
            <div class="content-info" data-e2e="address-mailbox-data" id="mailbox">{{ PersonAddressToConfirm?.mailbox }}</div>
        </div>
        <div class="row my-1">
            <div class="head-info" data-e2e="address-principalFlg-label" translate>
                <img src="assets/img/pin_location2.svg" />
                  Endereço principal:
            </div>
            <div class="content-info" data-e2e="address-principalFlg-data" id="principalFlg">{{ PersonAddressToConfirm?.principalFlg ? 'Sim' : 'Não' }}</div>
        </div>    
</div>
