import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {BillTooltipFile} from "@modules/customer/bill/dto/bill-tooltip-file.enum";
import {Bill} from "@models/old/financial/bill/bill-list/bill.dto";
import {BillSummary} from "@models/old/invoices/bill-summary.model";
import {BillHeaderDto} from "@modules/customer/bill/dto/bill-header.dto";
import {BillReversalPassengersResultDto} from "@modules/customer/bill/dto/bill-reversal-passengers-result.dto";
import {
  BillReversalPassengersQueryArguments
} from "@modules/customer/bill/dto/bill-reversal-passengers-query.arguments";
import {BillServicesQueryArguments} from "@modules/customer/bill/dto/bill-services-query.arguments";
import {BillServicesResultDto} from "@modules/customer/bill/dto/bill-services-result.dto";
import {BillHierarchyBreadcrumbResultDto} from "@modules/customer/bill/dto/bill-hierarchy-breadcrumb-result.dto";
import {
  BillHierarchyResultDto
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-hierarchy/dto/bill-hierarchy-result.dto";
import {
  BillHierarchyQueryArguments
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-hierarchy/dto/bill-hierarchy-query.arguments";
import {
  BillVehiclesQueryArguments
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-vehicles/dto/bill-vehicles-query.arguments";
import {
  BillVehiclesResultDto
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-vehicles/dto/bill-vehicles-result.dto";
import {
  BillVehiclesTotalQueryArguments
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-hierarchy/dto/bill-vehicles-total-query.arguments";
import {
  BillVehiclesTotalResultDto
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-hierarchy/dto/bill-vehicles-total-result.dto";
import {
  VehiclesQueryArguments
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-hierarchy/dto/vehicles-query.arguments";
import {
  VehiclesResultDto
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-hierarchy/dto/vehicles-result.dto";
import {
  BillAdvanceDetailsDto,
  BillAdvanceDetailsQueryArguments
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-general/components/advance-details-modal/dto/advance-details-dto";
import {BillRecompositionManualDto} from "@modules/customer/bill/dto/bill-recomposition-manual-dto";


@Injectable({
  providedIn: 'root',
})
export class BillService {
  private baseURL = environment.apiTMFinancial;
  billTooltipFile = BillTooltipFile;

  constructor(protected http: HttpClient) {}

  generateFileTooltip(file, type) {
    if (file) {
      if (type == 'pdf') {
        return this.billTooltipFile.pdfAvailable;
      }

      if (type == 'csv') {
        return this.billTooltipFile.csvAvailable;
      }
    } else {
      if (type == 'pdf') {
        return this.billTooltipFile.generatePdf;
      }

      if (type == 'csv') {
        return this.billTooltipFile.generateCsv;
      }
    }
  }

  GetListBillsByContractId() {
    return this.http
      .get<Bill[]>(`${this.baseURL}/Bill/GetListBillsByContractId`)
      .pipe(
        map((res) => {
          let objectResponse = [];
          for (let i = 0; i < res.length; i++) {
            objectResponse.push({
              ...res[i],
              csvFileTooltip: this.generateFileTooltip(res[i].csvFile, 'csv'),
              pdfFileTooltip: this.generateFileTooltip(res[i].pdfFile, 'pdf'),
            });
          }
          return objectResponse;
        }),
      );
  }

  getBillSummary(id: number) {
    return this.http.get<BillSummary>(
      environment.apiTMFinancial + `/api/Bill/GetBillSummary/` + id,
    );
  }
  getBillHeader(id: number) {
    return this.http
      .get<BillHeaderDto>(environment.apiTMReport + `/Bill/GetBillHeader/` + id)
      .pipe(
        map((res) => {
          const csvFileTooltip = this.generateFileTooltip(res.csvFile, 'csv');
          const pdfFileTooltip = this.generateFileTooltip(res.pdfFile, 'pdf');
          return { ...res, csvFileTooltip, pdfFileTooltip };
        }),
      );
  }

  getBillReversalAndPassengers(args: BillReversalPassengersQueryArguments) {
    return this.http.get<BillReversalPassengersResultDto>(
      environment.apiTMReport + `/Bill/GetBillReversalAndPassengers`,
      {
        params: args.httpParams,
      },
    );
  }

  getBillServices(args: BillServicesQueryArguments) {
    return this.http.get<BillServicesResultDto>(
      environment.apiTMReport + `/Bill/GetBillServices`,
      {
        params: args.httpParams,
      },
    );
  }

  getHierarchyBreadcrumb() {
    return this.http.get<BillHierarchyBreadcrumbResultDto>(
      environment.apiTMVehicle + `/GroupHierarchy/GetHierarchyBreadCrumbList`,
    );
  }

  public GetBillCSVFile(id: any): Observable<any> {
    return this.http.get(
      environment.apiTMReport + `/Bill/GetBillCsv?BillId=` + id,
    );
  }

  public GetBillPdfFile(id: any): Observable<any> {
    return this.http.get(this.baseURL + `/Bill/GetBillToPDF?BillId=` + id);
  }

  getBillHierarchy(args: BillHierarchyQueryArguments) {
    return this.http.get<BillHierarchyResultDto>(
      environment.apiTMReport + `/Bill/GetBillHierarchy`,
      {
        params: args.httpParams,
      },
    );
  }

  getBillVehicles(args: BillVehiclesQueryArguments) {
    return this.http.get<BillVehiclesResultDto>(
      environment.apiTMReport + `/Bill/GetBillVehicle`,
      {
        params: args.httpParams,
      },
    );
  }

  getHierarchyBreadCrumbList() {
    return this.http.get<BillHierarchyBreadcrumbResultDto>(
      environment.apiTMVehicle + `/GroupHierarchy/GetHierarchyBreadCrumbList`,
    );
  }

  getTotalVehicles(args: BillVehiclesTotalQueryArguments) {
    return this.http.get<BillVehiclesTotalResultDto>(
      environment.apiTMReport + `/Bill/GetBillHierarchyVehicleTotal`,
      {
        params: args.httpParams,
      },
    );
  }

  getVehicles(args: VehiclesQueryArguments) {
    return this.http.get<VehiclesResultDto>(
      environment.apiTMVehicle + `/Vehicle/GetVehicles`,
      {
        params: args.httpParams,
      },
    );
  }

  getAdvanceDetails(args: BillAdvanceDetailsQueryArguments) {
    return this.http.get<BillAdvanceDetailsDto>(
      environment.apiTMReport + `/VprAntecipation/GetVprAntecipation`,
      { params: args.httpParams },
    );
  }

  getCvsAdvanceDetails(args: BillAdvanceDetailsQueryArguments) {
    return this.http
      .get(environment.apiTMReport + `/VprAntecipation/GetVprAntecipationCsv`, {
        params: args.httpParams,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get('Content-Type'),
            }),
            filename: response.headers.get('filename'),
          };
          return data;
        }),
      );
  }

  finalizeRecompositionBill(entity: BillRecompositionManualDto) {
    return this.http.post(
      environment.apiTMFinancial + `/api/Bill/RecompositionLimit`,
      entity,
    );
  }

  getDataIntegrationServiceCsv(billId: number, contractId: number) {
    let httpParams = new HttpParams()
      .append('billId', billId)
      .append('contractId', contractId)

    return this.http
      .get(
        environment.apiTMReport + `/DataIntegrationService/GetDataIntegrationServiceCsv`,
        {
          params: httpParams,
          observe: "response",
          responseType: "blob",
        })
      .pipe(
        map((response) => {
          let data = {
            file: new Blob([response.body], {
              type: response.headers.get("Content-Type"),
            }),
            filename: response.headers.get("filename"),
          };
          return data;
        })
      );
  }
}
