<table class="table">
  <thead class="thead">
    <tr>
      @for(column of tableHeader; track $index){
        <th
        [ngClass]="bindOrderByClass(column[0])"
      >
          <div
          class="btn-order-table"
          [attr.data-e2e]="'sort-' + column[0] + '-table'"
          translate
        >
          {{ column[1] }}
        </div>
        @if(column[0] != ''){
        <div class="round-order" (click)="onOrderByChange(column[0])">
          <img src="assets/svg/order-table.svg" />
        </div>
        }
      </th>
      }
    </tr>
  </thead>


  @if(noFiles){

  <div class="noFiles">
    <app-no-results-msg [inTitle]="'Nenhum feriado cadastrado'"
    [inSubtitle]="'Você ainda não cadastrou nenhum feriado, clique em Incluir feriado para cadastrar.'" >
    </app-no-results-msg>
  </div>
  } @else if(isLoading) {
    <div class="container-loading"
    id="container-loader-page"
    data-e2e="container-loader-page"
  >
    <app-loader
      id="loader-page"
      data-e2e="loader-page"
      [isLoading]="isLoading"
      message="{{ messageLoader }}"
    ></app-loader>
  </div>
  }
  @if(!noFiles && !isLoading) {
    <tbody>
    @for (item of holidays; track item) {
      <tr>
      <td [attr.data-e2e]="'table-name-' + item.id" class="name">
        {{item.name}}
      </td>

      <td [attr.data-e2e]="'table-date-' + item.id">
        {{item.holidayDate | date: "dd/MM/yyyy"}}
      </td>
      <td [attr.data-e2e]="'table-created' + item.id">
        {{item.fullName}}
      </td>
      <td [attr.data-e2e]="'table-repeat-'+ item.id">
        <div class="status" [ngClass]="item.repeatAnnually  ? 'status-true' : 'status-false'">
          <span class="status__text">{{ item.repeatAnnually ? 'Sim' : 'Não'}}</span>
        </div>
      </td>
      <td [attr.data-e2e]="'table-actions-'+ item.id" class="actions">
        @if(canBeExcluded(item.holidayDate)){
          <img src="assets/svg/icon-trash-blue.svg" [id]="'exclude'+item.id" ngbTooltip="Excluir feriado" class="exclude-true" alt="" (click)="openExclude(item)"/>
        } @else {
          <img src="assets/svg/icon-trash-grey.svg" ngbTooltip="Excluir feriado" alt="excluir feriado" />
        }
      </td>
    </tr>
    }
  </tbody>}
</table>
