import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transfer-success',
  templateUrl: './transfer-success.component.html',
  styleUrls: ['./transfer-success.component.scss'],
})
export class TransferSuccessComponent {
  @Input() success: any;

  constructor(private modalService: NgbModal) {}

  dismissModal() {
    this.modalService.dismissAll();
  }
}
