import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { ActionType, ModalTagBlockUnblock, TabType } from '@modules/customer/batch-management/dtos/batch-management-consts';
import { ITagBlockUnblockDto } from '@modules/customer/batch-management/dtos/tag-block-unblock.dto';
import { BatchManagementService } from '@modules/customer/batch-management/services/batch-management.service';
import { STEP_TYPES } from 'src/app/shared/constants/step-types';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';
import { TagsSelectedToBlockUnblock } from '@modules/customer/batch-management/dtos/tags-selected-to-block-unblock.dto';
import { TagsSelectedToBlockUnblockResponseDto } from '@modules/customer/batch-management/dtos/tags-selected-to-block-unblock-response.dto';

@Component({
  selector: 'app-tag-block-unblock-modal',
  templateUrl: './tag-block-unblock-modal.component.html',
  styleUrl: './tag-block-unblock-modal.component.scss'
})
export class TagBlockUnblockModalComponent extends AbstractConfigurationBase implements OnInit {
  @Input() inTabTypeActive: TabType;
  @Input() inTagsSelected: ITagBlockUnblockDto[];
  @Output() outEventChangeTab: EventEmitter<number> = new EventEmitter();

  stepTypes = STEP_TYPES;
  step: STEP_TYPES;
  isLoading: boolean;
  title: string;
  infoBoxText: string;
  subtitle: string;
  tagBlockUnblockForm: FormGroup;
  btnConfirm: string;
  tagsSelectedToBlockUnblock: TagsSelectedToBlockUnblock;
  feedbackSubtitle: string;
  feedbackDate: Date;
  feedbackUsername: string;
  feedbackProtocol: string;

  constructor(
    private modalService: NgbModal,
    private batchManagementService: BatchManagementService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.settingsInitClass();
  }

  private settingsInitClass(): void {
    this.step = this.stepTypes.CONFIRMATION;
    this.tagsSelectedToBlockUnblock = new TagsSelectedToBlockUnblock();

    this.tagBlockUnblockForm = new FormGroup({
      protocol: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]*$")])
    });

    if (this.inTabTypeActive === TabType.TagBlock) {
      this.title = ModalTagBlockUnblock.BLOCK_TITLE;
      this.subtitle = ModalTagBlockUnblock.BLOCK_BODY_SUBTITLE;
      this.infoBoxText = ModalTagBlockUnblock.BLOCK_BODY_INFOBOXTEXT;
      this.btnConfirm = ModalTagBlockUnblock.BLOCK_BTN_CONFIRM;
      return;
    }

    this.title = ModalTagBlockUnblock.UNBLOCK_TITLE;
    this.subtitle = ModalTagBlockUnblock.UNBLOCK_BODY_SUBTITLE;
    this.infoBoxText = ModalTagBlockUnblock.UNBLOCK_BODY_INFOBOXTEXT;
    this.btnConfirm = ModalTagBlockUnblock.UNBLOCK_BTN_CONFIRM;
  }

  isUserProfileBackOffice() {
    return this.userProfileService.isBackOffice();
  }

  dismissModal() {
    this.modalService.dismissAll();

    if (this.step === STEP_TYPES.FEEDBACK) {
      this.router.navigate(['/meus-veiculos']);
    }
  }

  confirmBlockUnblock() {
    this.isLoading = true;

    this.setTagsSelectedToRequestObject();
    this.requestTagsToBlockUnblock();
  }

  private setTagsSelectedToRequestObject() {
    this.tagsSelectedToBlockUnblock.contractVehicleTagIdList = this.getContractVehicleTagIdList();

    this.tagsSelectedToBlockUnblock.contractVehicleTagStatusTypeId =
      this.inTabTypeActive === TabType.TagBlock ?
        ActionType.BLOCK :
        ActionType.UNBLOCK;

    if (this.isUserProfileBackOffice()) {
      this.tagsSelectedToBlockUnblock.protocol = this.tagBlockUnblockForm.controls.protocol.value;
    }
  }

  private getContractVehicleTagIdList(): number[] {
    let idsList: number[] = [];

    this.inTagsSelected.forEach((item) => {
      idsList.push(item.contractVehicleTagId);
    });

    return idsList;
  }

  getBtnIconClass(): string {
    return this.inTabTypeActive === TabType.TagBlock ?
    'ico-padlock-closed ' :
    'ico-padlock-opened'
  }

  private handleResponseSettings(response: TagsSelectedToBlockUnblockResponseDto) {
    this.step = this.stepTypes.FEEDBACK;
    this.feedbackSubtitle = this.inTabTypeActive === TabType.TagBlock ? 'Bloqueio' : 'Desbloqueio';
    this.feedbackDate = response.registrationDate;
    this.feedbackUsername = response.registrationUserName;
    this.feedbackProtocol = response.protocolNumber;
  }

  isInvalidToConfirm(): boolean {
    let isInvalid = false;

    if (this.isUserProfileBackOffice()) {
      isInvalid = this.tagBlockUnblockForm.controls.protocol.invalid
        && this.tagBlockUnblockForm.controls.protocol.touched
        || this.tagBlockUnblockForm.controls.protocol.value == '';
    }

    return isInvalid;
  }

  private requestTagsToBlockUnblock() {
    this.batchManagementService.postBlockUnblockTags(this.tagsSelectedToBlockUnblock)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (success) => {
          setTimeout(
            ()=>{ this.sendEventToChangeTab(); },
            2000
          );

          this.handleResponseSettings(success);
        },
        error: (error) => {
          console.error(error);
          return;
        },
      });
  }

  private sendEventToChangeTab() {
    this.outEventChangeTab.emit(TabType.TagBlockUnblockHistory);
  }

}
