import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, interval } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PaymentService } from 'src/app/core/services/payment.service';
import { PaymentOrigin, PixPayment } from '@models/payment.model';

@Component({
  selector: 'app-pix-payment-modal',
  templateUrl: './pix-payment-modal.component.html',
  styleUrls: ['./pix-payment-modal.component.scss']
})
export class PixPaymentModalComponent implements OnInit {

  @Input() idDestination: number;
  @Input() paymentOriginId: number = null;

  paymentPix: PixPayment;
  isLoading: Boolean = false;
  step: number = 1;
  copied: boolean = false;
  timeToExpiry: Date;
  private subscription: Subscription;
  paymentOrigin = PaymentOrigin;
  expirationMinutes: number;

  constructor(public modal: NgbModal, private _service: PaymentService){
    this.paymentOrigin.TagOrder
  }

  ngOnInit() {
      this.getPaymentPixInfos(this.idDestination);
  }

  initTimer(expirationDate){
   this.subscription = interval(1000)
    .subscribe(() => {
      this.timeToExpiry = this.getTimeToExpiry(expirationDate);
      if(new Date().getTime() >= new Date(expirationDate).getTime()){
        this.modal.dismissAll();
      }
     });
  }

  getTimeToExpiry(expirationDate): Date {
    let date = new Date()
    let timeDifference = new Date(expirationDate).getTime() - date.getTime();
    let seconds= Math.floor((timeDifference) / (1000) % 60);
    let minutes = Math.floor((timeDifference) / (1000 * 60) % 60);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, minutes, seconds);
  }

  getPaymentPixInfos(id) {
    this.isLoading = true;
    this._service
      .getPixPayment(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.setExpirationMinutes(success.expirationDate);
          this.timeToExpiry = this.getTimeToExpiry(success.expirationDate)
          this.initTimer(success.expirationDate)
          this.paymentPix = success;
        },
        (error) => {
          console.error("error: ", error);
        }
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showMessageAfterCopy() {
    this.copied = true;
    setTimeout(()=>{
      this.copied = false;
    }, 5000);
  }

  setExpirationMinutes(expirationDate) {
    let date = new Date()
    let timeDifference = new Date(expirationDate).getTime() - date.getTime();

    this.expirationMinutes = Math.floor((timeDifference) / (1000 * 60) % 60) + 1;
  }
}
