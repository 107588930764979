import { Validators, AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export class ChangePasswordModalValidator {
    constructor() {}

   static uppercaseValidation$ = new BehaviorSubject<boolean>(undefined);
   static lowercaseValidation$ = new BehaviorSubject<boolean>(undefined);
   static numberValidation$ = new BehaviorSubject<boolean>(undefined);
   static especialCaracterValidation$ = new BehaviorSubject<boolean>(undefined);
   static minLengthValidation$ = new BehaviorSubject<boolean>(undefined);
   static newPassword$ = new BehaviorSubject<string>(undefined);

/* Verifica se a senha e a confirmação de senha digitadas são iguais */
   static passwordConfirmation(){
    return (control: AbstractControl): Validators => {
        
      let newPassword = this.newPassword$.getValue();
      let confirmationPassword = control.value;

      let InvalidCondition = newPassword !== confirmationPassword ? true : false;

      return InvalidCondition
        ? { passwordConfirmationInvalid: true }
        : null;
    };

}

  /* Verifica se a senha digitada atende aos requisitos de caracteres */
    static passwordCharacterValidation(){
         return (control: AbstractControl): Validators => {
      
         if(control.value.length > 0){
          this.uppercaseValidation$.next(false);
          this.lowercaseValidation$.next(false);
          this.numberValidation$.next(false);
          this.especialCaracterValidation$.next(false);
          this.minLengthValidation$.next(false);

            for (let i = 0; i < control.value.length; i++) {
                
                if(control.value[i].match(/^[A-Z]*$/)) // letra maiúscula
                this.uppercaseValidation$.next(true);
                
                if(control.value[i].match(/^[a-z]*$/)) //letra minúscula
                this.lowercaseValidation$.next(true);
                
                if(control.value[i].match(/^[0-9]*$/)) //número
                this.numberValidation$.next(true);
                
                if(control.value[i].match(/^\W$/)) //carácter especial
                this.especialCaracterValidation$.next(true); 
                
            } 

            if(control.value.length >= 8){ //mínimo 8 digitos
                this.minLengthValidation$.next(true);
            }
            else{
                this.minLengthValidation$.next(false);
            }   
      
        }
        else{
          this.uppercaseValidation$.next(undefined);
          this.lowercaseValidation$.next(undefined);
          this.numberValidation$.next(undefined);
          this.especialCaracterValidation$.next(undefined);
          this.minLengthValidation$.next(undefined);
        }

        return this.uppercaseValidation$.getValue() &&
        this.lowercaseValidation$.getValue() &&
        this.numberValidation$.getValue() &&
        this.especialCaracterValidation$.getValue() &&
        this.minLengthValidation$.getValue()
        ? null
        : { passwordCharactersInvalid: true };

      }
        
    }

   static getMessage(formControl){
         //requerido
         if(formControl.errors.required){
            return 'Campo obrigatório'
         }
         //confirmação de senha    
         else if(formControl.errors.passwordConfirmationInvalid){
            return 'A nova senha e a confirmação de nova senha devem ser iguais'
         }              
    }

}