
export class RegionShippingCharge {
    id: number;
    federatedUnitTypeId: number;
    zipCodeClassificationTypeId: number;
    shippingValue: number;
    shippingValueDiscount: number;
    shippingTagRangeId: number;
    startAmount: number;
    endAmount: number;

    constructor() {
        this.id = 0;
        this.federatedUnitTypeId = 0;
        this.zipCodeClassificationTypeId = 0;
        this.shippingValue = 0;
        this.shippingValueDiscount = 0;
        this.shippingTagRangeId = 0;
        this.startAmount = 0;
        this.endAmount = 0;
    }
}