import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
} from '@angular/core';
import {Router} from '@angular/router';

import {OAuthService} from 'angular-oauth2-oidc';

import {NavLink} from '@models/navlink.model';
import {RoutesService} from "@modules/backoffice/page-backoffice/services/routes.service";
import {MenuBackoffice} from "../../../../../core/utils/routes/menu-nav-links";
import {MenuBackofficePaths, RoutesPaths} from "../../../../../core/utils/routes/routes-paths";
import {UserProfileService} from "@services/user-profile.service";
import {ProfileType} from "@variables/profileType";

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.scss'],
})
export class MenuNavComponent implements OnInit {
  menuBackoffice: NavLink[];
  menuCustomer: NavLink[];

  isMainMenuBackoffice: boolean = false;
  isBackofficeMenu: boolean = false;
  isCustomerMenu: boolean = false;

  mainMenuBackofficePath: string;
  accessContractPath: string;
  contractPath: string;

  urlActive: string;
  menuActive: NavLink;
  menuSubitemsActive: string;

  showMenuOptions: boolean = false;

  companyName: any;

  constructor(
    private _router: Router,
    private _oauthService: OAuthService,
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private _routesService: RoutesService,
    private _userProfileService: UserProfileService
  ) {
    this.menuBackoffice = MenuBackoffice;

    this.mainMenuBackofficePath = '/' + MenuBackofficePaths.BACKOFFICE + '/' + RoutesPaths.MAIN_MENU_BACKOFFICE;
    this.accessContractPath = RoutesPaths.SELECT_PROFILE;
    this.contractPath = RoutesPaths.CONTRACT;

    this.urlActive = this._router.routerState.snapshot.url;
    _routesService.setMenuPathActive(this.urlActive);

    this.setMenuNavActiveFromUrl(this.urlActive);

    this.companyName = [];
  }

  protected hasMultipleProfile(): boolean {
    return this._userProfileService.hasMultipleProfile();
  }

  ngOnInit(): void {
    // this.getName();
    // Executes everytime url change
    this._routesService.urlChange$.subscribe((url) => {
      let paths = url.split('/');
      let rootPath = paths[1];

      if (
        rootPath == RoutesPaths.MAIN_MENU_BACKOFFICE ||
        rootPath == MenuBackofficePaths.BACKOFFICE
      ) {
        this._routesService.setMenuPathActive(url);
        this.setMenuNavActiveFromUrl(url);
      }
    });
  }

  setMenuNavActiveFromUrl(url: string) {
    let paths = url.split('/');
    let menuPath: string;

    if (paths[2] == RoutesPaths.MAIN_MENU_BACKOFFICE) {
      this.isMainMenuBackoffice = true;
      menuPath = paths[3];
    } else if (paths[1] == MenuBackofficePaths.BACKOFFICE) {
      this.isBackofficeMenu = true;
      menuPath = paths[2];
      this.menuBackoffice.forEach((menu) => {
        if (menu.path == menuPath) this.menuActive = menu;
      });
    }
  }

  isMenuActivePath(
    itemPath: string,
    isBackofficeMenu: boolean,
    item?: NavLink,
  ): boolean {
    if (this._routesService.getMenuPathActive().includes(itemPath)) {
      return true;
    } else if (isBackofficeMenu && item.path == undefined) {
      let isPath = false;

      // Makes menu active when its submenu matches the active url path
      item.children.forEach((child) => {
        if (this._routesService.getMenuPathActive().includes(child.path)) {
          isPath = true;
        }
      });

      // Makes a specific menu active when a specific page matches the active url path
      if (
        item.title == 'Financial postpaid block' &&
        this._routesService
          .getMenuPathActive()
          .includes(RoutesPaths.FINANCIAL_BLOCKING_UNBLOCKING)
      ) {
        isPath = true;
      }

      return isPath;
    } else {
      return false;
    }
  }

  logout() {
    this._oauthService.logOut();
  }

  openMenuSubitems(menu: string) {
    this.menuSubitemsActive = menu;
    this.showMenuOptions = false;
  }

  generateClassName(item: NavLink): string {
    return item.title.toLowerCase().replace(' ', '').substring(0, 4).toString();
  }

  // Manipulates DOM elements to open the submenu below the corresponding menu button
  handleClassByMenusIds(item: NavLink) {
    const menuDiv = this.getDivMenuById(item);

    if (menuDiv) {
      const submenuDiv = this.getDivSubmenuByClass(item);

      if (submenuDiv) {
        this.rendererClassSubmenu(menuDiv, submenuDiv);
      }
    }
    return undefined;
  }

  private getDivMenuById(item: NavLink): any {
    const idMenu =
      '#menu-links-' + item.title.toLowerCase().trim().substring(0, 4);
    return this._elRef.nativeElement.querySelector(idMenu);
  }

  private getDivSubmenuByClass(item: NavLink): Element {
    const classSubmenu =
      '.submenu-' + item.title.toLowerCase().trim().substring(0, 4);
    return document.querySelector(classSubmenu);
  }

  private rendererClassSubmenu(menu: any, submenu: Element) {
    this._renderer.setStyle(
      submenu,
      'top',
      `88px`,
      RendererStyleFlags2.Important,
    );
    this._renderer.setStyle(
      submenu,
      'left',
      `${menu.offsetLeft}px`,
      RendererStyleFlags2.Important,
    );
  }

  changeProfile() {
    const url: string = '/selecionar-contrato';
    this._userProfileService.updateUserProfile(ProfileType.CUSTOMER);
    this._router.navigateByUrl(url);
  }
}
