import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FILE_EXTENSION_TYPES } from '../../constants/file-extension-types';

@Component({
  selector: 'app-dragdrop-file',
  templateUrl: './dragdrop-file.component.html',
  styleUrl: './dragdrop-file.component.scss'
})
export class DragdropFileComponent {
  @Input() inAllowedFileType: string;
  @Input() inTextInfoAllowedExtensions: string;

  @Output() onFileValid = new EventEmitter<object>();

  files: any[] = [];
  uploaded: boolean;
  messageError: boolean;

  fileBrowseHandler($event) {
    this.prepareFilesList($event);
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList($event) {
    const file = $event[0]
    this.messageError = false;

    if(this.files.length > 0){
      this.removeFile(0)
    }

    if(file.type !== this.inAllowedFileType) {
      this.uploaded = false;
      this.messageError = true;

    }
    else{
      this.files.push(file);
      this.uploaded = true;

      this.onFileValid.emit(file);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  removeFile(index: number) {
    this.files.splice(index, 1);
    this.uploaded = false;

    this.onFileValid.emit(undefined);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals:number = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getUploadedFileIconClass(): string {
    if (this.inAllowedFileType == FILE_EXTENSION_TYPES.XLS) {
      return 'icon-document-xls';
    }

  }
}
