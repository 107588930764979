import {Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef} from "@angular/core";
import {UserProfileService} from "@services/user-profile.service";

@Directive({
  selector: "[controlAccessProfile]",
   standalone: true
})
export class ControlAccessProfileDirective implements OnChanges {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private manageUserProfile: UserProfileService
              ) {
  }

  @Input()
  controlAccessProfile!: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.controlAccessProfile && (changes.controlAccessProfile.currentValue > 0)) {
      const profilePermited = changes.controlAccessProfile.currentValue;
      const autorizedProfile: boolean = profilePermited === this.manageUserProfile.getUserProfile();

      autorizedProfile ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
    }
  }


}
