<div class="IntegrationFeeTableComponent">
  <div class="content-fee">
    <p class="title-fee">Tarifas de Integração de Dados</p>
    <br/>
    <table class="table-fee-integration">
      <thead>
        <tr>
          <th>Faixa de requisições</th>
          <th>Valor cobrado</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fee of integrationFeeList; let i = index; let first = first; let last = last;">
          <td>

            <span *ngIf="first">
              Até {{ fee.limit | number : ".0" : "pt-br"}}
            </span>

            <span *ngIf="!first && !last">
              De {{ integrationFeeList[i-1].limit + 1 | number : ".0" : "pt-br" }}
              a  {{ fee.limit | number : ".0" : "pt-br" }}
            </span>

            <span *ngIf="last">
              Acima de {{ integrationFeeList[i-1].limit | number : ".0" : "pt-br" }}
            </span>

          </td>

          <td>
            {{ fee.value | currency: "BRL":"symbol":"0.2-2":"pt-BR" }}
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>



