import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ChangeLimitDto } from '../../dtos/changeLimit.dto';
import { ChangeLimitResponseDto } from '../../dtos/changeLimitResponse.dto';
import { ContractualAlterationService } from '../../services/contractual-alteration.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-limit-change-modal',
  templateUrl: './limit-change-modal.component.html',
  styleUrls: ['./limit-change-modal.component.scss'],
})
export class LimitChangeModalComponent {
  @Input() contract;
  @Output() event: EventEmitter<any> = new EventEmitter();
  step: number = 1;
  submitted: boolean = false;
  changeLimitValue: ChangeLimitDto;
  changeLimitResponseDto: ChangeLimitResponseDto;
  isLoading: boolean;

  constructor(
    private modalService: NgbModal,
    private contractualAlterationService: ContractualAlterationService,
  ) {
    this.changeLimitValue = {
      documentNumber: '',
      contractCode: '',
      caseNumber: 0,
      contractedLimit: 0,
      approver: '',
    };
  }

  ChangeLimitForm = new FormGroup({
    newLimit: new FormControl<number>(0, [
      Validators.required,
      Validators.min(1),
      this.notEqualActualLimit(),
    ]),
    approvedCaseNumber: new FormControl<any>('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    approver: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
  });

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  notEqualActualLimit() {
    return (control: AbstractControl): Validators => {
      let actualLimit = this.contract ? this.contract.creditLimit : 0;
      let NewLimit = this.ChangeLimitForm
        ? this.ChangeLimitForm.controls['newLimit'].value
        : 0;
      return actualLimit == NewLimit ? { equalLimit: true } : null;
    };
  }

  generateMessage(errors) {
    if (typeof errors !== 'undefined') {
      if (errors.required == true) {
        return 'Campo é requirido!';
      } else if (errors.min) {
        if (errors.min.min == 1) {
          return 'o valor tem que ser maior que zero!';
        } else {
          return 'Mínimo ' + errors.min.min + '!';
        }
      } else if (errors.minlength) {
        return 'Mínimo ' + errors.minlength.requiredLength + ' caracteres!';
      } else if (errors.maxlength) {
        return 'Máximo ' + errors.maxlength.requiredLength + ' caracteres!';
      } else if (errors.equalLimit) {
        return 'Novo limite deve ser diferente do atual!';
      }
    }
  }

  changeLimit(step) {
    this.submitted = true;
    if (step == 1) {
      if (this.ChangeLimitForm.invalid) {
        return;
      } else {
        this.step = 2;
      }
    } else {
      this.isLoading = true;
      this.changeLimitValue.documentNumber = this.contract.documentNumber;
      this.changeLimitValue.contractCode = this.contract.id.toString();
      this.changeLimitValue.caseNumber =
        this.ChangeLimitForm.value.approvedCaseNumber;
      this.changeLimitValue.contractedLimit =
        this.ChangeLimitForm.value.newLimit;
      this.changeLimitValue.approver = this.ChangeLimitForm.value.approver;
      this.contractualAlterationService
        .postChangeLimit(this.changeLimitValue)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe(
          (success: any) => {
            this.changeLimitResponseDto = success;
            this.step = 3;
          },
          (error) => {
            console.error(error);
            return;
          },
        );
    }
  }

  close() {
    if (this.step == 3) {
      this.event.emit();
    }

    this.modalService.dismissAll();
  }
}
