import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { OAuthService } from "angular-oauth2-oidc";
import { LocalstorageService } from "@services/localstorage.service";
import { NotifyService } from "@services/notify.service";

@Injectable()
export class AccessSessionInterceptor implements HttpInterceptor {
  notifyOneMinute: boolean = false;
  notifyFiveMinutes: boolean = false;

  constructor(
    protected localstorageService: LocalstorageService,
    private _notifyService: NotifyService,
    private _oauthService: OAuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const expiresSession = this.localstorageService.getExpiresSession();
    const nowMillis = new Date().getTime();
    const diffMillis = expiresSession - nowMillis;
    const oneMinuteMillis = 60 * 1000;
    const fiveMinutesMillis = 5 * 60 * 1000;

    if (nowMillis > expiresSession) {
      this._oauthService.logOut();

    } else if ((diffMillis <= oneMinuteMillis) && !this.notifyOneMinute) {
      this._notifyService.showError(
        "Sessão",
        "Sua sessão irá expirar em 1 minuto. Realize o login novamente para continuar usando a plataforma."
      );
      this.notifyOneMinute = true;

    } else if ((diffMillis <= fiveMinutesMillis) && !this.notifyFiveMinutes) {
      this._notifyService.showError(
        "Sessão",
        "Sua sessão irá expirar em 5 minutos. Fique atento!"
      );
      this.notifyFiveMinutes = true
    }
    return next.handle(req);
  }
}
