<div class="chart-container" [ngClass]="labelPosition">
  <div class="chart-holder">
    <canvas baseChart [type]="type" [data]="data" [options]="options"></canvas>
  </div>

  <ng-container [ngSwitch]="type">
    <section class="labels">
      <ng-container *ngSwitchCase="'line'">
        <p *ngFor="let item of data?.datasets" class="labels-line">
          {{ item.label }}
        </p>
      </ng-container>

      <div *ngSwitchCase="'doughnut'">
        <div
          *ngFor="let item of data?.customLabels; let i = index"
          class="labels-doughnut"
        >
          <p>{{ item.percentage + "% " + item.type }}</p>

          <p>
            {{ item.amount | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR" }}
          </p>
        </div>
      </div>
    </section>
  </ng-container>
</div>
