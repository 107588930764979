import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { ContractInfosDto } from "@models/contract-infos.dto";
import { GetContractsBySearchFieldParams } from "@models/get-contracts-by-searchfield-params.model";
import { SEARCH_TYPES } from "../../../shared/constants/search-types";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { CommonModule } from "@angular/common";
import { ContractAvailablesDto } from "@models/contractAvailables.dto";
import { NoResultsMessages } from "@modules/customer/batch-management/dtos/batch-management-consts";
import { Router } from "@angular/router";
import {
  BorderlessButtonComponent
} from "../../../shared/components/button/button-without-border/borderless-button.component";


@Component({
    selector: 'app-card-select-contracts',
    standalone: true,
  imports: [SharedModule, FormsModule, ReactiveFormsModule, NgxMaskDirective, NgxMaskPipe, CommonModule, BorderlessButtonComponent],
    templateUrl: './card-select-contracts.component.html',
    styleUrl: './card-select-contracts.component.scss'
})
export class CardSelectContractsComponent implements OnInit, OnChanges {
    protected readonly noResultsMessages = NoResultsMessages;

    protected noResultMsgTitle = NoResultsMessages.CONTRACT_NOT_FOUND_TITLE;
    protected noResultMsgSubtitle = NoResultsMessages.CONTRACT_NOT_FOUND_SUBTITLE;

    messageLoader = '';
    verifyContract: boolean = false;

    selectedSearchType: number;
    isTypingInput: boolean = false; // Remover variável, pois seu uso está errado

    searchTypes: any = SEARCH_TYPES;
    searchTypeForm: FormGroup = new FormGroup({
        searchType: new FormControl('1', [Validators.required]),
        searchField: new FormControl('', [Validators.required]),
    });

    @Input() searchFieldParams: GetContractsBySearchFieldParams;
    @Input() title: string = 'CONTRATOS DISPONÍVEIS'; // Título padrão
    @Input() contractList!: ContractInfosDto[] | ContractAvailablesDto[];
    @Input() totalContractsList!: number;
    @Input() haveManyContracts: boolean = false;
    @Input() isLoading: boolean;  // Adicionado
    @Input() isLoadingContracts: boolean;
    @Input() isMessageBySearchFilter: boolean;
    @Input() isAtendimentoRoute: boolean;


    @Output() emitterOpenModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitterValuesForm: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitterContract: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitterPageChange: EventEmitter<number> = new EventEmitter<number>(); // Novo output para emitir o evento de página
    @Output() pageChangeEvent = new EventEmitter<number>();
    @Output() emitterClear: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() searchClicked = new EventEmitter<void>();
    @Output() searchFormValues = new EventEmitter<any>();
    @Input() isRelationshipConsultantUser: boolean = false;
    verifyRota: boolean = false;
    //Mensagem Input
    noResultsIconPath: string = '';
    noResultsClass: string | string[] | Set<string> | { [p: string]: any } | null | undefined;
    messageNoResults: string;
    messageResults: string;
    messageLastAccess: string;
    verifyRelationConsultantUse: boolean = false;
    constructor(private _router: Router
    ) { }

    ngOnInit(): void {
        this.startMessageInfo();
        this._router.events.subscribe((event: any) => {
            if (event?.routerEvent?.url) {
                this.isAtendimentoRoute = event.routerEvent.url.includes('/backoffice/');
                this.verifyRota = event.routerEvent.url.includes('/selecionar-contrato');
                if (this.verifyRota) {
                    this.searchTypeForm = new FormGroup({
                        searchType: new FormControl('8', [Validators.required]),
                        searchField: new FormControl('', [Validators.required]),
                    });
                }
            }
        });
    }

    isButtonEnabled(): boolean {
        const searchTypeValue = this.searchTypeForm.get('searchType')?.value;
        const searchFieldValid = !this.searchTypeForm.get('searchField')?.invalid;

        // Habilitar o botão somente se searchType for 'Todos' ou searchField for válido
        return searchTypeValue === '8' || (searchFieldValid && !this.isLoading);
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes?.contractList) {
            if (!this.isMessageBySearchFilter) { // Sempre na inicialização e no clear da pesquisa, mostrar esse bloco
                if (this.totalContractsList == 0 && !this.isAtendimentoRoute) {
                    this.messageNoResults = 'Você ainda não acessou nenhum contrato. Faça uma busca para acessar';
                    this.noResultsClass = 'no-results-text--gray';
                    this.noResultsIconPath = 'assets/svg/icon-search-no-results-access-g.svg';
                } else {
                    this.messageResults = this.totalContractsList + ' contrato(s) encontrado(s)';
                }
            } else { // Sempre na pesquisa de filtro para ambos os perfis na pesquisa de filtro
                if (this.totalContractsList == 0 && !this.isAtendimentoRoute) {
                    this.messageNoResults = this.noResultMsgSubtitle;
                    this.noResultsClass = 'no-results-text--red';
                    this.noResultsIconPath = 'assets/svg/icon-search-no-results-r.svg';
                } else {
                    this.messageResults = this.totalContractsList + ' contrato(s) encontrado(s)';
                }
            }
        }


        //tirar a opção todos do perfil consultor de relacionamento
        if (this.isRelationshipConsultantUser) {
            this.verifyRelationConsultantUse = true;
            this.searchTypes = SEARCH_TYPES.filter(type => !(type.description === 'Todos'));
            if (!this.verifyContract) {
                this.messageLastAccess = 'Últimos contratos acessados';
            } else {
                this.messageLastAccess = '';
            }
        }
    }

    private startMessageInfo() {
        this.messageLoader = 'Carregando...';
    }

    openEditContractModal(contract: any) {
        this.emitterOpenModal.emit(contract);
    }

    getContractClass(contract) {
        if (contract.contractBlockLevel == 3 || contract.lastBillStatusType == 6) {
            return "contract-card-status";
        }
        else {
            return "";
        }
    }

    getMask(): string {
        let mask: string;
        this.selectedSearchType = parseInt(this.searchTypeForm.controls.searchType.value);

        this.searchTypes.filter((type) => {
            if (type.id == this.selectedSearchType) {
                mask = type.mask;
            }
        });
        return mask;
    }

    getPlaceholder(): string {
        let placeholder: string;
        this.selectedSearchType = parseInt(this.searchTypeForm.controls.searchType.value);

        this.searchTypes.filter((type) => {
            if (type.id == this.selectedSearchType) {
                placeholder = type.placeholder;
            }
        });
        return placeholder;
    }

    resetFormValues() {
        this.searchTypeForm.reset({
            searchField: ''
        });
    }

    onChangeSearchType() {
        const selectedType = this.searchTypeForm.controls['searchType'].value;

        if (selectedType === '8') {
            this.onSearch();
        } else {
            this.searchTypeForm.controls['searchField'].enable();
        }
    }

    onInputChange() {
        const selectedType = this.searchTypeForm.controls['searchType'].value;
        const searchField = this.searchTypeForm.controls['searchField'].value;

        if (selectedType === '8' && searchField == '') {
            this.onSearch();
        }
    }

    clearSearch() {
        this.resetFormValues();
    }


    onSearch() {
        this.emitterValuesForm.emit({
            searchType: this.searchTypeForm.controls['searchType'].value,
            searchField: this.searchTypeForm.controls['searchField'].value
        });

    }

    onSearchBackOffice() {
        this.searchClicked.emit();
        this.searchFormValues.emit(this.searchTypeForm.controls);
    }

    selectContract(contract: ContractInfosDto) {
        this.emitterContract.emit(contract);
    }


    onPageChange(page: number) {
        this.pageChangeEvent.emit(page);
    }

    handleClear() {
        const inpt = this.searchTypeForm.controls.searchField.value;
        return inpt && (inpt.length > 0);
    }
}

