import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ContractAvailablesDto } from '../dtos/contractAvailables.dto';
import { ChangeLimitDto } from '../dtos/changeLimit.dto';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ContractualAlterationService {
  constructor(private http: HttpClient) {}

  getContractsAvailables(document: string) {
    return this.http.get<ContractAvailablesDto[]>(
      environment.apiTMCustomer + `/Contract/GetContractsByCpfCnpj/` + document,
    );
  }

  postChangeLimit(entity: ChangeLimitDto) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const apiUrl = `${environment.apiTMSystemAccount}/api/Account/ChangeLimit`;
    const body = entity;
    const result = this.http.post<ChangeLimitDto>(apiUrl, body, httpOptions);
    return result;
  }
}
