import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class CacheService {

    cache = new Map();

    get(url:string,expiry:number,contractId:number){
        let result = this.cache.get(url);
        if(result){
            let response = result.response;
            let setDate = result.date;
            let cachedContractId = result.contractId;
            let expired = Date.now()-setDate.getTime() > expiry ? true : false;
            let changedContract = contractId !== cachedContractId ? true : false;
            return (expired || changedContract) ? this.delete(url) : response;
        }
        else{ 
            return null;
        }
    }

    set(url:string,response,date,contractId){
        this.cache.set(url,{response:response,date:date,contractId:contractId});
    }

    delete(url:string){
        this.cache.delete(url);
        return null;
    }
}