<div class="requestDatailComponent">
  <div class="RequestAttendanceComponent">
    <div class="container header-page">
      <h1 data-e2e="page-title" translate>
        Request:
        <span data-e2e="order-id-title">
          {{ orderDetail.orderId }}
        </span>
      </h1>
      <div class="actions-header-page justify-content-end mr-4">
        <!-- coment -->
        <button
          class="btn btn-primary mr-5"
          translate
          data-e2e="return-top-btn"
          [routerLink]="'/' + requestPath"
        >
          <img src="assets/img/ico-arrow-left.svg" class="button-icon" />
          back to orders
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="col-12 Dashboard_col_1 timelineBlock">
      <div class="container-repom timelineStatus" style="padding-bottom: 0">
        <div data-e2e="timeline-title" class="box-title">
          <span>Status</span>
        </div>

        <div class="box-body">
          <!--Pre-pago-->
          <div class="box-body" *modalityType="1">
            <div class="row">
              <div class="arrow-top" [ngClass]="getArrowClass(1)">
                <a><img src="/assets/img/bg-arrow-down.svg" /></a>
              </div>

              <div class="col-sm-auto timelinecolumn PrePaidTimeline">
                <div class="checkout-wrap">
                  <ul
                    class="checkout-bar"
                    [ngClass]="received == true ? 'received' : ''"
                  >
                    <li
                      *ngFor="
                        let order of orderDetail.getTagRequestTrackingQueryResults;
                        let count = index
                      "
                      [ngClass]="GetClassTimeline(count, order.isActive)"
                    >
                      <div class="bg-arrow-down"></div>
                      <button
                        id="checkReason"
                        data-e2e="timeline-reason-btn"
                        (click)="ConsultReason(order)"
                        *ngIf="order.id === 12"
                        class="btn btn-repom btn-repom-user btn-primary"
                        type="button"
                      >
                        Consultar motivo<span class="btn-animation"></span>
                      </button>
                      <i
                        *ngIf="order.id !== 5"
                        [ngClass]="GetClassTimelineImage(order.id)"
                      ></i>

                      <i
                        *ngIf="order.id === 5 && isBackoffice"
                        class="ico ico-Tracking-progress"
                      ></i>

                      <button
                        id="trackButton"
                        *ngIf="order.id === 5 && !isBackoffice"
                        (click)="TrackOrder()"
                        data-e2e="timeline-track-btn"
                        [disabled]="buttonDisabled"
                        class="btn btn-repom btn-repom-user btn-primary"
                        data-target="#userIncludeModal"
                        data-toggle="modal"
                        type="button"
                      >
                        <i class="ico ico-Tracking-progress"></i
                        ><span class="trackOrderText">rastrear</span>
                        <span class="btn-animation"></span>
                      </button>
                      <b data-e2e="timeline-description">{{
                        order.description
                      }}</b>

                      <p *ngIf="order.registrationDate === null">
                        <br />
                      </p>

                      <p
                        data-e2e="timeline-registration-date"
                        *ngIf="order.registrationDate !== null"
                      >
                        {{ order.registrationDate | date: 'dd/MM/yyyy HH:mm' }}
                      </p>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--Pos-pago-->
          <div class="box-body" *modalityType="2">
            <div class="row">
              <div class="arrow-top" [ngClass]="getArrowClass(2)">
                <a><img src="/assets/img/bg-arrow-down.svg" /></a>
              </div>
              <div class="col-sm-auto timelinecolumn">
                <div class="checkout-wrap">
                  <ul
                    class="checkout-bar"
                    [ngClass]="received == true ? 'received' : ''"
                  >
                    <li
                      *ngFor="
                        let order of orderDetail.getTagRequestTrackingQueryResults;
                        let count = index
                      "
                      [ngClass]="GetClassTimeline(count, order.isActive)"
                    >
                      <div class="bg-arrow-down"></div>
                      <i
                        *ngIf="order.id !== 5"
                        [ngClass]="GetClassTimelineImage(order.id)"
                      ></i>

                      <i
                        *ngIf="order.id === 5 && isBackoffice"
                        class="ico ico-Tracking-progress"
                      ></i>

                      <button
                        id="trackButton"
                        *ngIf="order.id === 5 && !isBackoffice"
                        (click)="TrackOrder()"
                        data-e2e="timeline-track-btn"
                        [disabled]="buttonDisabled"
                        class="btn btn-repom btn-repom-user btn-primary"
                        data-target="#userIncludeModal"
                        data-toggle="modal"
                        type="button"
                      >
                        <i class="ico ico-Tracking-progress"></i
                        ><span class="trackOrderText">rastrear</span>
                        <span class="btn-animation"></span>
                      </button>

                      <b data-e2e="timeline-description">{{
                        order.description
                      }}</b>

                      <p *ngIf="order.registrationDate === null">
                        <br />
                      </p>

                      <p
                        data-e2e="timeline-registration-date"
                        *ngIf="order.registrationDate !== null"
                      >
                        {{ order.registrationDate | date: 'dd/MM/yyyy HH:mm' }}
                      </p>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 Dashboard_col_1">
        <div class="container-repom">
          <div class="box-title">
            <span data-e2e="card-title">Resumo</span>
          </div>

          <h1 data-e2e="order-tags-total">{{ orderDetail.amount }} Tags</h1>
          <div class="box-body">
            <!-- <button
              class="btn btn-primary btn-repom btn-repom-user btn-print"
              data-target="#userIncludeModal"
              data-toggle="modal"
              type="button"
            >
              <img src="assets/img/ico-print-out.svg" class="button-icon" />
              imprimir
            </button> -->
            <div class="col-12 d-flex">
              <div class="col-lg-2">
                <div
                  class="form-group form-br"
                  style="margin-top: 0 !important"
                >
                  <label
                    data-e2e="order-shipping-total-label"
                    for="formTagInput"
                    >Frete</label
                  >
                  <p data-e2e="order-shipping-total">
                    R$
                    <b class="vprice">
                      {{ orderDetail.shippingValue | number: '.2' : 'pt-br' }}
                    </b>
                  </p>
                </div>

                <div class="form-group form-br">
                  <label data-e2e="order-subtotal-label" for="formTagInput"
                    >Sub Total</label
                  >
                  <p data-e2e="order-subtotal">
                    R$
                    <b class="vprice">
                      {{ orderDetail.subTotal | number: '.2' : 'pt-br' }}
                    </b>
                  </p>
                </div>
              </div>

              <div class="col-lg-1"></div>

              <div class="col-lg-4">
                <div class="form-group" style="margin: 0">
                  <label data-e2e="delivery-address" class="big"
                    >Endereço de entrega</label
                  >
                </div>
                <b
                  data-e2e="address-type"
                  *ngIf="orderDetail.addressTypeId == 1"
                >
                  Endereço comum
                </b>
                <b
                  data-e2e="address-type"
                  *ngIf="orderDetail.addressTypeId == 2"
                >
                  Caixa Postal
                </b>
                <p
                  data-e2e="address"
                  class="pbox-address mt-2"
                  *ngIf="orderDetail.addressTypeId == 1"
                >
                  {{ orderDetail.streetAddress }}, {{ orderDetail.number }},
                  {{ orderDetail.streetAddressLine2 }}
                  {{
                    orderDetail.streetAddressLine2 === null ||
                    orderDetail.streetAddressLine2 === ''
                      ? ''
                      : ','
                  }}
                  {{ orderDetail.district }} - {{ orderDetail.city }} -
                  {{ orderDetail.federatedUnit }} - CEP:
                  {{ orderDetail.zipCode }}
                  <br />
                  {{ orderDetail.landmark }}
                </p>
                <p
                  data-e2e="address-zipcode"
                  class="pbox-address"
                  *ngIf="orderDetail.addressTypeId == 2"
                >
                  Código da caixa postal: {{ orderDetail.mailBox }} <br />
                  {{ orderDetail.city }} - {{ orderDetail.federatedUnit }} -
                  CEP:
                  {{ orderDetail.zipCode }}
                </p>
              </div>

              <div class="col-lg-1"></div>

              <div class="col-lg-4">
                <div class="form-group" style="margin: 0">
                  <label data-e2e="delivery-recipient" class="big"
                    >RESPONSÁVEL PELO RECEBIMENTO</label
                  >
                </div>

                <div class="form-group form-icon no-margin">
                  <input
                    type="text"
                    data-e2e="delivery-recipient-input"
                    class="form-control"
                    id="formNameInput"
                    placeholder="{{ orderDetail.fullName }}"
                  />
                </div>
                <div
                  class="form-group form-icon"
                  style="margin-top: 0px"
                  *ngIf="orderDetail.addressTypeId == 1"
                >
                  <input
                    type="text"
                    data-e2e="delivery-recipient-phone"
                    class="form-control"
                    id="formPhoneInput"
                    placeholder="{{ orderDetail.phoneNumber }}"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 d-flex ico-arrow-right">
              <div class="col-sm-auto mr-6">
                <div class="row line-gray blue-light">
                  <div data-e2e="tags-total-amount-label" class="col-lg-9">
                    VALOR TOTAL DAS TAGS
                  </div>
                  <div class="col-lg-1">R$</div>
                  <div data-e2e="tags-total-amount" class="col-lg-2 price">
                    {{ orderDetail.totalTagValue | number: '.2' : 'pt-br' }}
                  </div>
                </div>
              </div>

              <div class="col-sm-auto ml-6">
                <div class="row line-gray blue-light">
                  <div data-e2e="order-total-amount-label" class="col-lg-9">
                    VALOR TOTAL DO PEDIDO
                  </div>
                  <div class="col-lg-1">R$</div>
                  <div data-e2e="order-total-amount" class="col-lg-2 price">
                    {{ orderDetail.totalOrder | number: '.2' : 'pt-br' }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 d-flex"
              *ngIf="orderDetail.totalOrderDiscount > 0"
            >
              <div class="col-lg-12">
                <h6 data-e2e="ordet-discounts-title">DESCONTOS</h6>
              </div>
            </div>

            <div
              class="col-12 d-flex ico-arrow-right"
              *ngIf="orderDetail.totalOrderDiscount > 0"
            >
              <div class="col-sm-auto mr-6">
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      class="row line-gray blue-light"
                      *ngIf="orderDetail.totalTagValueDiscount > 0"
                    >
                      <div data-e2e="tag-discount-label" class="col-lg-9">
                        DESCONTO DE TAG
                      </div>
                      <div class="col-lg-1">R$</div>
                      <div data-e2e="tag-discount" class="col-lg-2 price">
                        {{
                          orderDetail.totalTagValueDiscount
                            | number: '.2' : 'pt-br'
                        }}
                      </div>
                    </div>

                    <div
                      class="row line-gray blue-light"
                      *ngIf="orderDetail.totalShippingDiscount > 0"
                    >
                      <div data-e2e="shipping-discont-label" class="col-lg-9">
                        DESCONTO DE FRETE
                      </div>
                      <div class="col-lg-1">R$</div>
                      <div data-e2e="shipping-discount" class="col-lg-2 price">
                        {{
                          orderDetail.totalShippingDiscount
                            | number: '.2' : 'pt-br'
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-auto ml-6">
                <div
                  class="row line-gray blue-light"
                  [ngClass]="
                    orderDetail.totalTagValueDiscount > 0 &&
                    orderDetail.totalShippingDiscount > 0
                      ? 'line-bg'
                      : ''
                  "
                  *ngIf="orderDetail.totalOrderDiscount > 0"
                >
                  <div data-e2e="order-total-discount-label" class="col-lg-9">
                    DESCONTO TOTAL DO PEDIDO
                  </div>
                  <div class="col-lg-1">R$</div>
                  <div data-e2e="order-total-discount" class="col-lg-2 price">
                    {{
                      orderDetail.totalOrderDiscount | number: '.2' : 'pt-br'
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 d-flex">
              <div class="col-lg-12">
                <h6 data-e2e="order-total-title">TOTAL FINAL</h6>
                <div class="row line-gray dark-blue">
                  <div data-e2e="order-due-amount-label" class="col-lg-10">
                    VALOR TOTAL A PAGAR
                  </div>
                  <div class="col-lg-1">R$</div>
                  <div data-e2e="order-due-amount" class="col-lg-1 price">
                    {{
                      orderDetail.totalAmountPayable | number: '.2' : 'pt-br'
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
