import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChargebackApprovedComponent } from '../chargeback-approved/chargeback-approved.component';
import { finalize } from 'rxjs/operators';
import {ChargebackReportModel} from "@models/backoffice/chargeback-report.model.dto";
import {ChargebackReportService} from "@modules/backoffice/chargeback-report/services/chargeback-report.service";
import {ChargebackApproveReport} from "@modules/backoffice/chargeback-report/dto/chargeback-approve.dto";

@Component({
  selector: 'app-chargeback-approve',
  templateUrl: './chargeback-approve.component.html',
  styleUrls: ['./chargeback-approve.component.scss'],
})
export class ChargebackApproveComponent {
  @Input() transaction: ChargebackReportModel;
  @Input() option: boolean;
  @Output() approved = new EventEmitter<boolean>();
  isLoading: boolean;
  errorMsg: string;
  reason: string;
  chargebackData: ChargebackApproveReport;

  constructor(
    private _modalService: NgbModal,
    private _chargebackService: ChargebackReportService,
  ) {
    this.chargebackData = new ChargebackApproveReport();
  }

  dismissModal() {
    this._modalService.dismissAll();
  }

  approveOrDisapprove() {
    let reason = this.reason;
    this.chargebackData.authorization = this.transaction.authorization;
    this.chargebackData.nsu = this.transaction.nsu;
    this.chargebackData.chargebackStatus = 1;
    this.chargebackData.reason = reason;
    this.chargebackData.isApproved = this.option;

    this.isLoading = true;
    this._chargebackService
      .approveDisapproveChargeback(this.chargebackData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.dismissModal();

          let modal = this._modalService.open(ChargebackApprovedComponent);
          modal.componentInstance.transaction = this.transaction;
          modal.componentInstance.option = this.option;
          modal.componentInstance.reason = this.reason;

          modal.componentInstance.test.subscribe((event) => {
            if (event) {
              this.approved.emit(true);
            }
          });

          return;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }
}
