@if (idHtml.length > 0 && dataE2E.length > 0 && description.length > 0) {
  @if (verifyContractBlock) {
    <button mat-button class="button-config" [ngClass]="classCustom" [disabled]="isDisable" id="{{idHtml}}"
            buttonBlock [ContractBlock]="listVerifyContractBlock"
            [attr.data-e2e]="dataE2E"
            (click)="handleEventClick()">
      <span>
        @if (classIconLeft.length > 0) {
          <i class="icn ico {{classIconLeft}} ico-left"></i>
        }

        {{ description }}

        @if (classIconRight.length > 0) {
          <i class="icn ico {{classIconRight}} ico-right"></i>
        }
      </span>
    </button>
  }
  @else if (verifyContractBlock && verifyDualTag) {
    <button mat-button class="button-config" [ngClass]="classCustom" [disabled]="isDisable" id="{{idHtml}}"
            buttonBlock [ContractBlock]="listVerifyContractBlock"
            [attr.data-e2e]="dataE2E"
            [ContractDualTagBlock]="verifyDualTag"
            (click)="handleEventClick()">
      <span >
        @if (classIconLeft.length > 0) {
          <i class="icn ico {{classIconLeft}} ico-left"></i>
        }

        {{ description }}

        @if (classIconRight.length > 0) {
          <i class="icn ico {{classIconRight}} ico-right"></i>
        }
      </span>
    </button>
  }
  @else if (service && filters) {
    <button mat-button class="button-config" [ngClass]="classCustom" [disabled]="isDisable"
            id="{{idHtml}}" [attr.data-e2e]="dataE2E"
            btn-export [service]="service" [filters]="filters">
      <span >
        @if (classIconLeft.length > 0) {
          <i class="icn ico {{classIconLeft}} ico-left"></i>
        }

        {{ description }}

        @if (classIconRight.length > 0) {
          <i class="icn ico {{classIconRight}} ico-right"></i>
        }
      </span>
    </button>
  }
  @else {
    <button mat-button class="button-config" [ngClass]="classCustom" [disabled]="isDisable" [ngStyle]="handleStyleByInput()"
            id="{{idHtml}}"
            [attr.data-e2e]="dataE2E"
            (click)="handleEventClick()">
      <span>
        @if (classIconLeft.length > 0) {
          <i class="icn ico {{classIconLeft}} ico-left"></i>
        }

        {{ description }}

        @if (classIconRight.length > 0) {
          <i class="icn ico {{classIconRight}} ico-right"></i>
        }
      </span>
    </button>
  }
}
