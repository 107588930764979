<div class="toll-balance hidden-xs app-toll-balance">
  <div class="row infos1">
    <div class="col-12 box-white-small">
      <p>Contrato:</p>
      <p>
        <span id="contractId">{{ contractId }}</span
        ><span
          *ngIf="
            VPRBalance?.contractName && VPRBalance?.contractName.length > 0
          "
          id="contractName"
        >
          - {{ VPRBalance?.contractName }}</span
        >
      </p>
    </div>
    <div class="col-12 box-head-gray">
      <p>
        Saldo de<br />
        Vale Pedágio
      </p>
      <p class="vprValue">
        <span id="headerTotalVPRBalance" *ngIf="VPRBalance?.totalVPRBalance">{{
          VPRBalance?.totalVPRBalance | currency: "BRL":"symbol":"0.2-2":"pt-BR"
        }}</span>
        <span *ngIf="!VPRBalance?.totalVPRBalance" id="headerTotalVPRBalance_zero"
          >0,00</span
        >
      </p>
      <button
        [routerLink]="['/saldovpr-veiculos']"
        type="button"
        class="header-vpr-route"
      >
        <i class="ico ico-add"></i>
      </button>
    </div>
  </div>
  <div class="box-head-white infos2" id="balanceprepaid" *modalityType="1">
    <div class="InfoBox">
      <div class="title">
        <b> Saldo disponível </b>
      </div>
      <hr />
      <div class="value">
        <span class="left">R$</span>
        <span class="right" [ngClass]="postPaidContractBalance?.accountBalance >= 0 ? 'numberPositive' : 'numberNegative'">{{
          postPaidContractBalance?.accountBalance | number: ".2":"pt-br"
        }}</span>
      </div>
    </div>
  </div>
  <div class="box-head-white infos2" id="balancepostpaid" *modalityType="2">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">Gastos atuais</div>
        <div class="col-lg-6">Limite restante</div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="header-nav data-progress" data-start="0">
            <div class="blue"></div>
            <div
              class="red"
              [style.width]="isOpenProgressBar ? widthProgressBar : '0px'"
              [@ProgressBar]="isOpenProgressBar ? 'open' : 'closed'"
            ></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <span *ngIf="postPaidContractBalance?.accountBalance"
            >R$
            <span id="headerAccountBalance">{{
              postPaidContractBalance?.accountBalance | number: ".2":"pt-br"
            }}</span></span
          >
          <span *ngIf="!postPaidContractBalance?.accountBalance">R$ 0,00</span>
        </div>
        <div class="col-lg-6 value-2">
          <span *ngIf="postPaidContractBalance?.remainingLimit"
            >R$
            <span id="headerRemainingLimit">{{
              postPaidContractBalance?.remainingLimit | number: ".2":"pt-br"
            }}</span></span
          >
          <span
            id="headerRemainingLimit_zero"
            *ngIf="!postPaidContractBalance?.remainingLimit"
            >R$ 0,00</span
          >
        </div>
      </div>
      <div class="row">
        <i class="ico ico-white ico-unfold-more"
          [ngClass]="isOpenBox ? 'active' : ''"
          (click)="openBoxInfo()"
        ></i>
        <div class="box-orange" [@OpenBox]="isOpenBox ? 'open' : 'closed'">
          <span> Limite financeiro </span>
          <hr />
          <div>
            <b class="left">R$</b>
            <b
              class="right"
              *ngIf="postPaidContractBalance?.accountLimit"
              id="headerAccountLimit"
              >{{
                postPaidContractBalance?.accountLimit | number: ".2":"pt-br"
              }}</b
            >
            <b
              class="right"
              id="headerAccountLimit_zero"
              *ngIf="!postPaidContractBalance?.accountLimit"
              >0,00</b
            >
          </div>

          <div *controlAccessCustomer>
            <button
             *accessControl="101121"
              class="btn btn-primary mt-2 mx-auto creditAlerts"
              (click)="showCreditAlertModal()"
            >
              <svg class="mr-2" viewBox="0 0 24 24" width="18">
                <use
                  href="../../../../assets/svg/pie-report-icon.svg#pieReportAlert"
                ></use>
              </svg>

              Alertas de limite
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
