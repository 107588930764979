<div class="login">
  <div class="BGLogin">
    <img src="assets/svg/BG-Login.png" />
  </div>

  <div class="Login_BoxForm">
    <div class="BoxForm_Content">
      <div class="BoxForm_Title">
        <img src="assets/svg/icon_login.svg">
        <p data-e2e="first-acess-title">Primeiro acesso</p>
      </div>
      <div class="BoxForm_Form">
        <!-- <form class="form-login" action="dashboard.html"> -->
        <form class="form-login" [formGroup]="formFirstAccess" (ngSubmit)="onSubmit()">
          <label data-e2e="fill-cpf-label" class="login-fa-cpf-resp">Preencha o CPF de <a href="#" target="_blank">cadastro</a>.</label>
          <div class="input-group mb-3">
            <p class="Form_TitleInput">CPF</p>
            <div class="input-group-prepend Form_Prepend">
              <span class="input-group-text"><img src="assets/svg/icon_login_user.svg" class="IconInput"></span>
            </div>
            <input data-e2e="cpf-input" formControlName="documentNumber" type="text" class="form-control FormInput_Usuario" placeholder="" aria-label="Username"
              aria-describedby="basic-addon1">
          </div>
          <app-borderless-button
            [description]="'PROSSEGUIR'"
            [isBtnWithBorder]="true"
            (emitterEvtClick)="onSubmit()"
            [data-e2e]="'cpf-submit-btn'"
            [id-html]="'cpf-submit-btn'"
            [customWidthSize]="'20rem'">
          </app-borderless-button>
        </form>

        <div class="FormLogin_Divider">
          <p>OU</p>
        </div>

        <div class="Login_ForgotPassword">
          <a data-e2e="back-to-login-btn" href="login" class="Login_btn_ForgotPassword">
            Voltar
          </a>
        </div>

        <div class="BoxForm_LogoRepomResponsive">
          <img src="assets/svg/footer_logo_repom.svg">
        </div>
      </div>
      <!--BoxForm_Form-->
    </div>
    <!--BoxForm_Content-->
  </div>
  <!--Login_BoxForm-->
  <!-- **************** Login | End **************** -->
</div>

