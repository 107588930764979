import { Component, Input, OnInit } from '@angular/core';
import { ContractModality } from '@models/old/contractModality.enum';
import { PaymentObject } from '@models/payment.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ContractService} from "@services/contract.service";

@Component({
  selector: 'app-tag-order-confirmation',
  templateUrl: './tag-order-confirmation.component.html',
  styleUrls: ['./tag-order-confirmation.component.scss']
})
export class TagOrderConfirmationComponent implements OnInit {

  @Input() object: PaymentObject;
  @Input() response: any;
  modalityTypeId: number;
  contractModality = ContractModality;

  constructor(
    public modal: NgbModal,
    private contractService: ContractService
  ) {
    this.modalityTypeId = this.contractService.getContractModalityTypeId();
  }

  ngOnInit() {
  }


}
