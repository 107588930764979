<section class="tabs-container">
  <ul class="tabs">
    @for (tab of inTabs; track tab.index) {
      <li
        id="tab-{{tab.index}}"
        [attr.data-e2e]="'tab-' + tab.index"
        class="tab"
        [ngClass]="(inTabSelected == tab.index ? 'active' : '')"
        (click)="onSelectTab(tab.index)"
        [style]="onStyle(tab.styles)"
      >
        <span
          id="tab-text-{{tab.index}}"
          class="tab__text"
        >
          {{ tab.name }}
        </span>
        <span class="tab__line"></span>
      </li>
    }
  </ul>
</section>
