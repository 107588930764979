import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectProfileService } from './../../services/select-profile.service';
import { EventEmitterService } from '@services/event-emitter.service';

@Component({
  selector: 'app-edit-contract-name-modal',
  templateUrl: './edit-contract-name-modal.component.html',
  styleUrls: ['./edit-contract-name-modal.component.scss'],
})
export class EditContractNameModalComponent implements OnInit {
  constructor(
    private selectProfileService: SelectProfileService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {}

  @Output() event: EventEmitter<any> = new EventEmitter();
  @Input() contract;
  editContractNameForm: FormGroup;
  onSaving: boolean = false;
  contractName: string;
  step: number = 1;
  nameUser: string;
  dateRegister: Date;

  ngOnInit() {
    this.contractName = this.contract.nameContract;
    this.createForm();
  }

  createForm() {
    this.editContractNameForm = this.formBuilder.group({
      nameContract: [''],
    });
  }

  submitContractName(form) {
    this.onSaving = true;
    this.selectProfileService
      .putContractName(form)
      .pipe(
        finalize(() => {
          this.onSaving = false;
        }),
      )
      .subscribe(
        (success: any) => {
          this.event.emit(1);
          EventEmitterService.get('clearCacheAfterEditContractName').emit();
          this.nameUser = success.nameUser;
          this.dateRegister = success.dateRegister;
          this.step = 2;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }

  close() {
    this.event.emit(2);
    this.modalService.dismissAll();
  }
}
