import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prepago',
  templateUrl: './prepago.component.html',
  styleUrls: ['./prepago.component.css']
})
export class PrepagoComponent {

  @Input() commercialCondition: any;

  constructor() { }

}
