import { Validators, AbstractControl } from '@angular/forms';

export class TelephoneValidator {
    constructor() {}
 
    static LandlineLength = 10;
    static CellPhoneLength = 11;
  
   

    /**
     * Verifica se é um Telefone fixo válido
    */
    static isValidLandline(withDDI:boolean=false) {
      return (control: AbstractControl): Validators => {
        const landlineLength = withDDI ? 12 : 10
        const tel =  control.value ? control.value.replace(/\D/g, '') : '';
        // Verifica se todos os dígitos são iguais.
        if (/^([0-9])\1*$/.test(tel)) {
            return { landlineInvalid: true };
          }
        // Verifica a quantidade de dígitos
        if (tel.length !== landlineLength) {
            return { landlineInvalid: true };
          }

    return null;
   };
 }

 /**
     * Verifica se é um Telefone celular válido
    */

 static isValidCellPhone(withDDI:boolean=false) {
    return (control: AbstractControl): Validators => {
        const cellPhoneLength = withDDI ? 13 : 11
        const tel =  control.value ? control.value.replace(/\D/g, '') : '';
        // Verifica se todos os dígitos são iguais.
        if (/^([0-9])\1*$/.test(tel)) {
            return { cellphoneInvalid: true };
          }
        // Verifica a quantidade de dígitos
        if (tel.length !== cellPhoneLength) {
            return { cellphoneInvalid: true };
          }
    
        return null;
 };
}
    /**
     * Verifica se é um telefone válido (fixo ou celular)
    */
 static isValidTelephone(withDDI:boolean=false){
    return (control: AbstractControl): Validators => {
      const landlineLength = withDDI ? 12 : 10
      const cellPhoneLength = withDDI ? 13 : 11
      const tel =  control.value ? control.value.replace(/\D/g, '') : '';
        // Verifica se todos os dígitos são iguais.
        if (/^([0-9])\1*$/.test(tel)) {
            return { telephoneInvalid: true };
          }
        // Verifica a quantidade de dígitos
        if ([cellPhoneLength, landlineLength].indexOf(tel.length) < 0) {
            return { telephoneInvalid: true };
          }

    return null;
  };
}





}