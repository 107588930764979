import { HttpParams } from '@angular/common/http';

export class TransactionPartnersConciliationQueryArguments {
  page: number;
  pageSize: number;
  sortField: string;
  desc: boolean;
  sourcePartnerTypeId: number[];
  VprConciliationFileStatusId: number;


  constructor() {
    this.page = 1;
    this.pageSize = 10;
    this.sortField = 'registrationDate';
    this.desc = true;
    this.sourcePartnerTypeId = [];

  }

  get httpParams() {
    let httpAppend = new HttpParams()
      .append('page', this.page.toString())
      .append('pageSize', this.pageSize.toString())
      .append('sortOrder', this.desc ? '1' : '0')
      .append('sortField', this.sortField);
      for (let id of this.sourcePartnerTypeId) {
        httpAppend = httpAppend.append('SourcePartnerTypeId', id.toString());
      }

    return httpAppend;
  }
}
