import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { BillListComponent } from '../components/bill-list/bill-list.component';
import { BillDetailComponent } from '../components/bill-detail/bill-detail.component';
import {authGuardCustomer} from "../../../../core/auth/authGuardCustomer";

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "faturas",
      component: BillListComponent,
      canActivate: [authGuardCustomer],
      data: {
        roles: "101193",
        backofficeRoles: '100994,100995,100996',
        contractRequired: true
      },
    },
    {
      path: "faturas/detalhe-fatura/:id",
      component: BillDetailComponent,
      canActivate: [authGuardCustomer],
      data: {
        roles: "101193",
        backofficeRoles: '100994,100995,100996',
        contractRequired: true
      },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class BillRoutingModule {}
