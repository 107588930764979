import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsSubscriptionService {

  tabActive$ = new BehaviorSubject<number>(1);

  setTabActive(tabNumber: number) {
    this.tabActive$.next(tabNumber);
  }
}
