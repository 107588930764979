import { HttpParams } from "@angular/common/http";

export class ValidateUserByEmailAndGroupDto {
  groupId: number;
  emailAddress: string;

  get httpParams() {
    let params = new HttpParams()
      .append('GroupId', this.groupId.toString())
      .append('Email', this.emailAddress);

    return params;
  }
}
