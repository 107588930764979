import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PrePaidContractDto } from '../models/prePaidContract.model';
import { PostPaidContractBalance } from '@models/old/postpaidcontractbalance.model';
import { VPRBalance } from '@models/old/VPRBalance.model';
import { CacheService } from '@services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class TollBalanceService {

  private base = environment.apiTMSystemAccount;
  constructor(protected http: HttpClient, protected cache:CacheService) { }

  public async getPostPaidContractBalance(): Promise<PostPaidContractBalance> {
    let cacheExpiry = 180000;
    let headers = new HttpHeaders({
         'cache-response': 'true'
        ,'cache-expiry': cacheExpiry.toString() 
    });
    
    try {
      let apiUrl = `${this.base}/api/Contract/GetBalanceByContract`;
      var result = await this.http.get<PostPaidContractBalance>(apiUrl,{ headers }).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  // public async getPrePaidContractBalance(): Promise<PrePaidContractDto> {
  //   try {
  //     let apiUrl = `${this.base}/api/Contract/GetBalanceByContract`;
  //     var result = await this.http.get<PrePaidContractDto>(apiUrl).toPromise();
  //     return result;
  //   } catch (e) {
  //     console.error(e.message);
  //     return null;
  //   }
  // }

  getPrePaidContractBalance() {
    return this.http.get<PrePaidContractDto>(`${this.base}/api/Contract/GetBalanceByContract`);
  }

  public async getVPRBalance(): Promise<VPRBalance> {
    let cacheExpiry = 180000;
    let headers = new HttpHeaders({
         'cache-response': 'true'
        ,'cache-expiry': cacheExpiry.toString() 
    });
    
    try {
      let apiUrl = `${this.base}/api/Contract/GetVPRBalanceByContract`;
      var result = await this.http.get<VPRBalance>(apiUrl,{ headers }).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  deleteVPRCacheAndRefresh(){
    let apiUrl = `${this.base}/api/Contract/GetVPRBalanceByContract`;
    this.cache.delete(apiUrl);
  }
}