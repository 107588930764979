import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalstorageService } from './localstorage.service';
import {SelectContractsService} from "@modules/customer/select-contracts/services/select-contracts.service";
import {ContractService} from "@services/contract.service";

@Injectable({
    providedIn: 'root'
  })

export class StoragedContractInfosService {

  constructor(private _localstorageService: LocalstorageService,
              private _selectContractsService: SelectContractsService,
              private _contractService: ContractService)
  {
   this.storagedContractInfos$.subscribe(() => this.updateStoragedContractInfos())
  }

  /**
   * A Behavior Subject that updates the storaged hasCreditCard variable
   *
   * @class boolean BehaviorSubject.
   */
  hasCreditCard$ = new BehaviorSubject<boolean>(undefined);

  /**
   * A Behavior Subject that updates the storaged contractBlockLevel variable
   *
   * @class boolean BehaviorSubject.
   */
  contractBlockLevel$ = new BehaviorSubject<number>(undefined);

  /**
   * A Behavior Subject that updates the storaged contract infos
   *
   * @class boolean BehaviorSubject.
   */
   storagedContractInfos$ = new BehaviorSubject<boolean>(undefined);


updateStoragedContractInfos():any {
  const contractId = Number(this._localstorageService.getContractId());
  if (contractId && contractId != 0) {
    this._selectContractsService.getContracts().subscribe(
      (success: any) => {
        let hasCreditCard =
          success.length == 1
            ? success[0].hasCreditCard
            : success
              .filter((data) => data.id == contractId)
              .map((data) => data.hasCreditCard)[0]

        let contractBlockLevel =
          success.length == 1
            ? success[0].contractBlockLevel
            : success
              .filter((data) => data.id == contractId)
              .map((data) => data.contractBlockLevel)[0]

        this.updateHasCreditCardVariable(hasCreditCard);
        this.updateContractBlockLevelVariable(contractBlockLevel);
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }
}

updateHasCreditCardVariable(hasCreditCard){
  this._contractService.setCrediCardCheck(hasCreditCard);
  this.hasCreditCard$.next(hasCreditCard)
}

updateContractBlockLevelVariable(contractBlockLevel?: any){
  this._contractService.setContractBlockLevel(contractBlockLevel);
  this.contractBlockLevel$.next(this._contractService.getContractBlockLevel())
}

}
