import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageService } from '../../page/services/page.service';
import { MyvehiclesComponent } from '../myvehicles.component';
import { authGuardCustomer } from "../../../../core/auth/authGuardCustomer";
import { myVehiclesResponseResolver } from '../resolvers/myvehicles-response.resolver';

const routes: Routes = [
  PageService.childRoutes([
    {
      path: "meus-veiculos",
      component: MyvehiclesComponent,
      canActivate: [authGuardCustomer],
      resolve: { profilesUser: myVehiclesResponseResolver },
      data: {
        roles: "101143",
        backofficeRoles: '100994,100995,100996',
        contractRequired: true
      },
    }
  ]),
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class MyvehiclesRoutingModule { }
