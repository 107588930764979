import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbInputDatepicker, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { PostalcodeService } from 'src/app/core/services/postalcode.service';
import { ContactTypeId } from '../../dtos/contact-type.enum';
import { ContactUsService } from '../../services/contact-us.service';
import { finalize } from 'rxjs/operators';
import { GetContactUsQueryArguments } from '../../dtos/get-contact-us-query-arguments.dto';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { NotifyService } from '@services/notify.service';
import { DateConfigDto } from '@services/form-validators/dtos/date-config.dto';
import { PostalCode } from '@models/old/postalcode.model';
import { TelephoneValidator } from '@services/form-validators/telephone-validator.service';
import { LicensePlateValidator } from '@services/form-validators/license-plate-validator.service';
import { DateTimeValidator } from '@services/form-validators/date-time-validator.service';
import { ZipCodeValidator } from '@services/form-validators/zip-code-validator.service';
import { CpfCnpjValidator } from '@services/form-validators/cpf-cnpj-validator.service';
import { AttachmentErrorModalComponent } from '../attachment-error-modal/attachment-error-modal.component';


defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent implements OnInit, OnChanges {

  @Input() chosenSubCategoryId;
  @Input() userInfos;

  @Output() formSentEvent = new EventEmitter<any>();

  constructor(
    private formValidatorMessageService: FormValidatorMessageService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private localeService: BsLocaleService,
    private postalcodeservice: PostalcodeService,
    private contactUsService: ContactUsService,
    private notifyService: NotifyService
  ) {
    setInterval(() => {
      this.cdr.detectChanges()
    }, 10);
    this.minDate = new Date(Date.now());
    this.maxDate = new Date(Date.now());
    this.minDate.setMonth(this.minDate.getMonth() - 13);
    this.localeService.use('pt-br');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chosenSubCategoryId) {
      this.ContactUsForm.controls.subCategoryId.setValue(changes.chosenSubCategoryId.currentValue);
      this.setValidatorsBySubCategory(changes.chosenSubCategoryId.currentValue)
    }

    if (changes.chosenSubCategoryId &&
      typeof changes.chosenSubCategoryId.previousValue !== 'undefined') {
      this.resetForm(changes.chosenSubCategoryId.currentValue);
    }

    if (changes.userInfos
      && typeof changes.userInfos.previousValue == 'undefined'
      && typeof changes.userInfos.currentValue !== 'undefined') {
      this.defaultTel = changes.userInfos.currentValue.telephoneNumber
      this.defaultEmail = changes.userInfos.currentValue.emailAddress
      this.currentTel = changes.userInfos.currentValue.telephoneNumber
      this.currentEmail = changes.userInfos.currentValue.emailAddress
    }

  }

  files: any[];
  submitted: boolean = false;
  error: Array<boolean>;
  percent: Array<number>;
  interval: any;
  defaultTel: string;
  defaultEmail: string;
  currentTel: string;
  currentEmail: string;
  maxDate: Date = new Date();
  minDate: Date = new Date();
  isLoading: Boolean;
  DateConfig: DateConfigDto = {
    allowFutureDate: false,
    maxDate: null,
    minDate: null
  }
  postalCode: PostalCode;
  isShowDatePicker: Array<boolean>;
  validExtensions = [
    { extension: 'image/png' },
    { extension: 'text/csv' },
    { extension: 'application/pdf' },
    { extension: 'image/jpeg' },
    { extension: 'application/vnd.ms-excel' },
  ];

  UFs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
    'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO', 'DF']

  public contactTypeIdEnum = ContactTypeId;
  getContactUsQueryArguments: GetContactUsQueryArguments;

  private emailValidators = [
    Validators.required,
    //validação e-mail
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$")
  ];
  private phoneValidators = [
    Validators.required,
    TelephoneValidator.isValidTelephone()
  ];
  private licensePlateValidators = [
    Validators.required,
    LicensePlateValidator.isValidLicensePlate()
  ];
  private passageHourValidators = [
    Validators.required,
    DateTimeValidator.isValidHour()
  ];
  private passageDateValidators = [
    Validators.required,
    DateTimeValidator.isValidDate(this.DateConfig)
  ]

  private parkingNameValidators = [
    Validators.required
  ]

  private zipCodeValidators = [
    ZipCodeValidator.isValidZipCode()
  ]

  private addressValidators = [
    Validators.required
  ]

  private cityValidators = [
    Validators.required
  ]

  private stateValidators = [
    Validators.required
  ]

  private parkingAndTollValidators = [
    Validators.required
  ]


  private subCategory7LicensePlateValidators = [
    Validators.required,
    LicensePlateValidator.isValidLicensePlate()
  ]

  private parkingAndTollNameValidators = [
    Validators.required,
  ]

  private subCategory7CityValidators = [
    Validators.required,
  ]

  private subCategory7StateValidators = [
    Validators.required,
  ]

  private occurrenceDateValidators = [
    Validators.required,
    DateTimeValidator.isValidDate(this.DateConfig)
  ]

  private occurrenceHourValidators = [
    Validators.required,
    DateTimeValidator.isValidHour()
  ];

  private paymentInMoneyValidators = [
    Validators.required,
  ]

  private billIdValidators = [
    Validators.required,
    Validators.pattern(/^[0-9]\d*$/)
  ]

  private userDocumentValidators = [
    Validators.required,
    CpfCnpjValidator.isValidCpf()
  ]

  private userEmailValidators = [
    Validators.required,
    //validação e-mail
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$")
  ];

  ContactUsForm = new FormGroup({
    subCategoryId: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    returnType: new FormControl<any>('', [Validators.required]),
    email: new FormControl('', []),
    phone: new FormControl('', []),
    licensePlate: new FormControl('', []),
    passageDate: new FormControl('', []),
    passageHour: new FormControl('', []),
    parkingName: new FormControl('', []),
    zipCode: new FormControl('', []),
    address: new FormControl('', []),
    number: new FormControl('', []),
    district: new FormControl('', []),
    complement: new FormControl('', []),
    city: new FormControl('', []),
    state: new FormControl('', []),
    parkingAndToll: new FormControl<any>('', []),
    subCategory7LicensePlate: new FormControl('', []),
    parkingAndTollName: new FormControl('', []),
    subCategory7city: new FormControl('', []),
    subCategory7state: new FormControl('', []),
    occurrenceDate: new FormControl('', []),
    occurrenceHour: new FormControl('', []),
    paymentInMoney: new FormControl('', []),
    billId: new FormControl('', []),
    userDocument: new FormControl('', []),
    userEmail: new FormControl('', []),
  });

  getAddressInfos() {
    let zipcode = this.ContactUsForm.controls['zipCode'].value;
    this.postalcodeservice.get(zipcode).then((result) => {
      this.postalCode = result;
    });
  }


  ngOnInit() {
    this.submitted = false;
    this.files = [];
    this.percent = [];
    this.error = [];
    this.isShowDatePicker = [];
    this.ContactUsForm.controls.returnType.setValue('2');
    this.ContactUsForm.controls.parkingAndToll.setValue('1');
    this.ContactUsForm.controls.paymentInMoney.setValue('1');
    this.setRequiredReturnType(2)
  }

  isCellPhone(phone): boolean {
    return phone.length < 11 ? false : true;
  }


  getPhoneMask() {
    let filter = this.ContactUsForm.controls['phone'].value;
    return this.isCellPhone(filter)
      ? '(00) 00000-0000'
      : '(00) 0000-00009'
  }

  resetForm(chosenSubCategoryId) {
    this.submitted = false;
    this.ContactUsForm.reset();
    this.postalCode = null;
    this.files = [];
    this.percent = [];
    this.error = [];
    this.currentTel = this.defaultTel;
    this.currentEmail = this.defaultEmail;
    this.ContactUsForm.controls.returnType.setValue('2');
    this.ContactUsForm.controls.parkingAndToll.setValue('1');
    this.ContactUsForm.controls.paymentInMoney.setValue('1');
    this.ContactUsForm.controls.subCategoryId.setValue(chosenSubCategoryId);
    this.ContactUsForm.controls.email.setValue(this.defaultEmail);
    this.ContactUsForm.controls.phone.setValue(this.defaultTel);
    this.setRequiredReturnType(2)
  }

  generateErrorMessage(formControl) {
    return this.formValidatorMessageService.returnMessage(formControl);
  }

  setValidatorsBySubCategory(chosenSubCategoryId) {
    if (chosenSubCategoryId == this.contactTypeIdEnum.ContestationPassage) {
      //Colocar validação - Placa
      this.ContactUsForm.get('licensePlate').setValidators(this.licensePlateValidators)
      //Colocar validação - Hora da passagem
      this.ContactUsForm.get('passageHour').setValidators(this.passageHourValidators)
      //Colocar validação - Data da passagem
      this.ContactUsForm.get('passageDate').setValidators(this.passageDateValidators)
      //Tirar validação - nome do estabelecimento
      this.ContactUsForm.get('parkingName').clearValidators();
      this.ContactUsForm.get('parkingName').updateValueAndValidity();
      //Tirar validação - endereço
      this.ContactUsForm.get('address').clearValidators();
      this.ContactUsForm.get('address').updateValueAndValidity();
      //Tirar validação - CEP
      this.ContactUsForm.get('zipCode').clearValidators();
      this.ContactUsForm.get('zipCode').updateValueAndValidity();
      //Tirar validação - Cidade
      this.ContactUsForm.get('city').clearValidators();
      this.ContactUsForm.get('city').updateValueAndValidity();
      //Tirar validação - Estado
      this.ContactUsForm.get('state').clearValidators();
      this.ContactUsForm.get('state').updateValueAndValidity();
      //Tirar validação - radio Pedágio / Estacionamento
      this.ContactUsForm.get('parkingAndToll').clearValidators();
      this.ContactUsForm.get('parkingAndToll').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Placa
      this.ContactUsForm.get('subCategory7LicensePlate').clearValidators();
      this.ContactUsForm.get('subCategory7LicensePlate').updateValueAndValidity();
      //Tirar validação - Qual a praça de pedágio \ Qual estacionamento
      this.ContactUsForm.get('parkingAndTollName').clearValidators();
      this.ContactUsForm.get('parkingAndTollName').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Cidade
      this.ContactUsForm.get('subCategory7city').clearValidators();
      this.ContactUsForm.get('subCategory7city').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Estado
      this.ContactUsForm.get('subCategory7state').clearValidators();
      this.ContactUsForm.get('subCategory7state').updateValueAndValidity();
      //Tirar validação - Data da ocorrência
      this.ContactUsForm.get('occurrenceDate').clearValidators();
      this.ContactUsForm.get('occurrenceDate').updateValueAndValidity();
      //Tirar validação - Horário aproximado da ocorrência
      this.ContactUsForm.get('occurrenceHour').clearValidators();
      this.ContactUsForm.get('occurrenceHour').updateValueAndValidity();
      //Tirar validação - O pagamento ocorreu em dinheiro?
      this.ContactUsForm.get('paymentInMoney').clearValidators();
      this.ContactUsForm.get('paymentInMoney').updateValueAndValidity();
      //Tirar validação - Código da fatura
      this.ContactUsForm.get('billId').clearValidators();
      this.ContactUsForm.get('billId').updateValueAndValidity();
      //Tirar validação - CPF do usuário
      this.ContactUsForm.get('userDocument').clearValidators();
      this.ContactUsForm.get('userDocument').updateValueAndValidity();
      //Tirar validação - E-mail do usuário
      this.ContactUsForm.get('userEmail').clearValidators();
      this.ContactUsForm.get('userEmail').updateValueAndValidity();
    }
    else if (chosenSubCategoryId == this.contactTypeIdEnum.ParkingRegistration) {
      //Colocar validação - nome do estabelecimento
      this.ContactUsForm.get('parkingName').setValidators(this.parkingNameValidators)
      //Colocar validação - endereço
      this.ContactUsForm.get('address').setValidators(this.addressValidators)
      //Colocar validação - CEP
      this.ContactUsForm.get('zipCode').setValidators(this.zipCodeValidators)
      //Colocar validação - Cidade
      this.ContactUsForm.get('city').setValidators(this.cityValidators)
      //Colocar validação - Estado
      this.ContactUsForm.get('state').setValidators(this.stateValidators)
      //Tirar validação - Placa
      this.ContactUsForm.get('licensePlate').clearValidators();
      this.ContactUsForm.get('licensePlate').updateValueAndValidity();
      //Tirar validação - Hora da passagem
      this.ContactUsForm.get('passageHour').clearValidators();
      this.ContactUsForm.get('passageHour').updateValueAndValidity();
      //Tirar validação - Data da passagem
      this.ContactUsForm.get('passageDate').clearValidators();
      this.ContactUsForm.get('passageDate').updateValueAndValidity();
      //Tirar validação - radio Pedágio / Estacionamento
      this.ContactUsForm.get('parkingAndToll').clearValidators();
      this.ContactUsForm.get('parkingAndToll').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Placa
      this.ContactUsForm.get('subCategory7LicensePlate').clearValidators();
      this.ContactUsForm.get('subCategory7LicensePlate').updateValueAndValidity();
      //Tirar validação - Qual a praça de pedágio \ Qual estacionamento
      this.ContactUsForm.get('parkingAndTollName').clearValidators();
      this.ContactUsForm.get('parkingAndTollName').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Cidade
      this.ContactUsForm.get('subCategory7city').clearValidators();
      this.ContactUsForm.get('subCategory7city').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Estado
      this.ContactUsForm.get('subCategory7state').clearValidators();
      this.ContactUsForm.get('subCategory7state').updateValueAndValidity();
      //Tirar validação - Data da ocorrência
      this.ContactUsForm.get('occurrenceDate').clearValidators();
      this.ContactUsForm.get('occurrenceDate').updateValueAndValidity();
      //Tirar validação - Horário aproximado da ocorrência
      this.ContactUsForm.get('occurrenceHour').clearValidators();
      this.ContactUsForm.get('occurrenceHour').updateValueAndValidity();
      //Tirar validação - O pagamento ocorreu em dinheiro?
      this.ContactUsForm.get('paymentInMoney').clearValidators();
      this.ContactUsForm.get('paymentInMoney').updateValueAndValidity();
      //Tirar validação - Código da fatura
      this.ContactUsForm.get('billId').clearValidators();
      this.ContactUsForm.get('billId').updateValueAndValidity();
      //Tirar validação - CPF do usuário
      this.ContactUsForm.get('userDocument').clearValidators();
      this.ContactUsForm.get('userDocument').updateValueAndValidity();
      //Tirar validação - E-mail do usuário
      this.ContactUsForm.get('userEmail').clearValidators();
      this.ContactUsForm.get('userEmail').updateValueAndValidity();
    }
    else if (chosenSubCategoryId == this.contactTypeIdEnum.TollNotOpen) {
      //Colocar validação - radio Pedágio / Estacionamento
      this.ContactUsForm.get('parkingAndToll').setValidators(this.parkingAndTollValidators)
      //Colocar validação - Cancela não abre - Placa
      this.ContactUsForm.get('subCategory7LicensePlate').setValidators(this.subCategory7LicensePlateValidators)
      //Colocar validação - Qual a praça de pedágio \ Qual estacionamento
      this.ContactUsForm.get('parkingAndTollName').setValidators(this.parkingAndTollNameValidators)
      //Colocar validação - Cancela não abre - Cidade
      this.ContactUsForm.get('subCategory7city').setValidators(this.subCategory7CityValidators)
      //Colocar validação - Cancela não abre - Estado
      this.ContactUsForm.get('subCategory7state').setValidators(this.subCategory7StateValidators)
      //Colocar validação - Data da ocorrência
      this.ContactUsForm.get('occurrenceDate').setValidators(this.occurrenceDateValidators)
      //Colocar validação - Horário aproximado da ocorrência
      this.ContactUsForm.get('occurrenceHour').setValidators(this.occurrenceHourValidators)
      //Colocar validação - O pagamento ocorreu em dinheiro?
      this.ContactUsForm.get('paymentInMoney').setValidators(this.paymentInMoneyValidators)
      //Tirar validação - nome do estabelecimento
      this.ContactUsForm.get('parkingName').clearValidators();
      this.ContactUsForm.get('parkingName').updateValueAndValidity();
      //Tirar validação - endereço
      this.ContactUsForm.get('address').clearValidators();
      this.ContactUsForm.get('address').updateValueAndValidity();
      //Tirar validação - CEP
      this.ContactUsForm.get('zipCode').clearValidators();
      this.ContactUsForm.get('zipCode').updateValueAndValidity();
      //Tirar validação - Cidade
      this.ContactUsForm.get('city').clearValidators();
      this.ContactUsForm.get('city').updateValueAndValidity();
      //Tirar validação - Estado
      this.ContactUsForm.get('state').clearValidators();
      this.ContactUsForm.get('state').updateValueAndValidity();
      //Tirar validação - Placa
      this.ContactUsForm.get('licensePlate').clearValidators();
      this.ContactUsForm.get('licensePlate').updateValueAndValidity();
      //Tirar validação - Hora da passagem
      this.ContactUsForm.get('passageHour').clearValidators();
      this.ContactUsForm.get('passageHour').updateValueAndValidity();
      //Tirar validação - Data da passagem
      this.ContactUsForm.get('passageDate').clearValidators();
      this.ContactUsForm.get('passageDate').updateValueAndValidity();
      //Tirar validação - Código da fatura
      this.ContactUsForm.get('billId').clearValidators();
      this.ContactUsForm.get('billId').updateValueAndValidity();
      //Tirar validação - CPF do usuário
      this.ContactUsForm.get('userDocument').clearValidators();
      this.ContactUsForm.get('userDocument').updateValueAndValidity();
      //Tirar validação - E-mail do usuário
      this.ContactUsForm.get('userEmail').clearValidators();
      this.ContactUsForm.get('userEmail').updateValueAndValidity();
    }
    else if (chosenSubCategoryId == this.contactTypeIdEnum.BillProblem) {
      //Colocar validação - Código da fatura
      this.ContactUsForm.get('billId').setValidators(this.billIdValidators)
      //Tirar validação - Placa
      this.ContactUsForm.get('licensePlate').clearValidators();
      this.ContactUsForm.get('licensePlate').updateValueAndValidity();
      //Tirar validação - Hora da passagem
      this.ContactUsForm.get('passageHour').clearValidators();
      this.ContactUsForm.get('passageHour').updateValueAndValidity();
      //Tirar validação - Data da passagem
      this.ContactUsForm.get('passageDate').clearValidators();
      this.ContactUsForm.get('passageDate').updateValueAndValidity();
      //Tirar validação - nome do estabelecimento
      this.ContactUsForm.get('parkingName').clearValidators();
      this.ContactUsForm.get('parkingName').updateValueAndValidity();
      //Tirar validação - endereço
      this.ContactUsForm.get('address').clearValidators();
      this.ContactUsForm.get('address').updateValueAndValidity();
      //Tirar validação - CEP
      this.ContactUsForm.get('zipCode').clearValidators();
      this.ContactUsForm.get('zipCode').updateValueAndValidity();
      //Tirar validação - Cidade
      this.ContactUsForm.get('city').clearValidators();
      this.ContactUsForm.get('city').updateValueAndValidity();
      //Tirar validação - Estado
      this.ContactUsForm.get('state').clearValidators();
      this.ContactUsForm.get('state').updateValueAndValidity();
      //Tirar validação - radio Pedágio / Estacionamento
      this.ContactUsForm.get('parkingAndToll').clearValidators();
      this.ContactUsForm.get('parkingAndToll').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Placa
      this.ContactUsForm.get('subCategory7LicensePlate').clearValidators();
      this.ContactUsForm.get('subCategory7LicensePlate').updateValueAndValidity();
      //Tirar validação - Qual a praça de pedágio \ Qual estacionamento
      this.ContactUsForm.get('parkingAndTollName').clearValidators();
      this.ContactUsForm.get('parkingAndTollName').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Cidade
      this.ContactUsForm.get('subCategory7city').clearValidators();
      this.ContactUsForm.get('subCategory7city').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Estado
      this.ContactUsForm.get('subCategory7state').clearValidators();
      this.ContactUsForm.get('subCategory7state').updateValueAndValidity();
      //Tirar validação - Data da ocorrência
      this.ContactUsForm.get('occurrenceDate').clearValidators();
      this.ContactUsForm.get('occurrenceDate').updateValueAndValidity();
      //Tirar validação - Horário aproximado da ocorrência
      this.ContactUsForm.get('occurrenceHour').clearValidators();
      this.ContactUsForm.get('occurrenceHour').updateValueAndValidity();
      //Tirar validação - O pagamento ocorreu em dinheiro?
      this.ContactUsForm.get('paymentInMoney').clearValidators();
      this.ContactUsForm.get('paymentInMoney').updateValueAndValidity();
      //Tirar validação - CPF do usuário
      this.ContactUsForm.get('userDocument').clearValidators();
      this.ContactUsForm.get('userDocument').updateValueAndValidity();
      //Tirar validação - E-mail do usuário
      this.ContactUsForm.get('userEmail').clearValidators();
      this.ContactUsForm.get('userEmail').updateValueAndValidity();
    }
    else if (chosenSubCategoryId == this.contactTypeIdEnum.AccessProblem) {
      //Colocar validação - CPF do usuário
      this.ContactUsForm.get('userDocument').setValidators(this.userDocumentValidators)
      //Colocar validação - E-mail do usuário
      this.ContactUsForm.get('userEmail').setValidators(this.userEmailValidators)
      //Tirar validação - Placa
      this.ContactUsForm.get('licensePlate').clearValidators();
      this.ContactUsForm.get('licensePlate').updateValueAndValidity();
      //Tirar validação - Hora da passagem
      this.ContactUsForm.get('passageHour').clearValidators();
      this.ContactUsForm.get('passageHour').updateValueAndValidity();
      //Tirar validação - Data da passagem
      this.ContactUsForm.get('passageDate').clearValidators();
      this.ContactUsForm.get('passageDate').updateValueAndValidity();
      //Tirar validação - nome do estabelecimento
      this.ContactUsForm.get('parkingName').clearValidators();
      this.ContactUsForm.get('parkingName').updateValueAndValidity();
      //Tirar validação - endereço
      this.ContactUsForm.get('address').clearValidators();
      this.ContactUsForm.get('address').updateValueAndValidity();
      //Tirar validação - CEP
      this.ContactUsForm.get('zipCode').clearValidators();
      this.ContactUsForm.get('zipCode').updateValueAndValidity();
      //Tirar validação - Cidade
      this.ContactUsForm.get('city').clearValidators();
      this.ContactUsForm.get('city').updateValueAndValidity();
      //Tirar validação - Estado
      this.ContactUsForm.get('state').clearValidators();
      this.ContactUsForm.get('state').updateValueAndValidity();
      //Tirar validação - radio Pedágio / Estacionamento
      this.ContactUsForm.get('parkingAndToll').clearValidators();
      this.ContactUsForm.get('parkingAndToll').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Placa
      this.ContactUsForm.get('subCategory7LicensePlate').clearValidators();
      this.ContactUsForm.get('subCategory7LicensePlate').updateValueAndValidity();
      //Tirar validação - Qual a praça de pedágio \ Qual estacionamento
      this.ContactUsForm.get('parkingAndTollName').clearValidators();
      this.ContactUsForm.get('parkingAndTollName').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Cidade
      this.ContactUsForm.get('subCategory7city').clearValidators();
      this.ContactUsForm.get('subCategory7city').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Estado
      this.ContactUsForm.get('subCategory7state').clearValidators();
      this.ContactUsForm.get('subCategory7state').updateValueAndValidity();
      //Tirar validação - Data da ocorrência
      this.ContactUsForm.get('occurrenceDate').clearValidators();
      this.ContactUsForm.get('occurrenceDate').updateValueAndValidity();
      //Tirar validação - Horário aproximado da ocorrência
      this.ContactUsForm.get('occurrenceHour').clearValidators();
      this.ContactUsForm.get('occurrenceHour').updateValueAndValidity();
      //Tirar validação - O pagamento ocorreu em dinheiro?
      this.ContactUsForm.get('paymentInMoney').clearValidators();
      this.ContactUsForm.get('paymentInMoney').updateValueAndValidity();
      //Tirar validação - Código da fatura
      this.ContactUsForm.get('billId').clearValidators();
      this.ContactUsForm.get('billId').updateValueAndValidity();
    }
    else {
      //Tirar validação - Placa
      this.ContactUsForm.get('licensePlate').clearValidators();
      this.ContactUsForm.get('licensePlate').updateValueAndValidity();
      //Tirar validação - Hora da passagem
      this.ContactUsForm.get('passageHour').clearValidators();
      this.ContactUsForm.get('passageHour').updateValueAndValidity();
      //Tirar validação - Data da passagem
      this.ContactUsForm.get('passageDate').clearValidators();
      this.ContactUsForm.get('passageDate').updateValueAndValidity();
      //Tirar validação - nome do estabelecimento
      this.ContactUsForm.get('parkingName').clearValidators();
      this.ContactUsForm.get('parkingName').updateValueAndValidity();
      //Tirar validação - endereço
      this.ContactUsForm.get('address').clearValidators();
      this.ContactUsForm.get('address').updateValueAndValidity();
      //Tirar validação - CEP
      this.ContactUsForm.get('zipCode').clearValidators();
      this.ContactUsForm.get('zipCode').updateValueAndValidity();
      //Tirar validação - Cidade
      this.ContactUsForm.get('city').clearValidators();
      this.ContactUsForm.get('city').updateValueAndValidity();
      //Tirar validação - Estado
      this.ContactUsForm.get('state').clearValidators();
      this.ContactUsForm.get('state').updateValueAndValidity();
      //Tirar validação - radio Pedágio / Estacionamento
      this.ContactUsForm.get('parkingAndToll').clearValidators();
      this.ContactUsForm.get('parkingAndToll').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Placa
      this.ContactUsForm.get('subCategory7LicensePlate').clearValidators();
      this.ContactUsForm.get('subCategory7LicensePlate').updateValueAndValidity();
      //Tirar validação - Qual a praça de pedágio \ Qual estacionamento
      this.ContactUsForm.get('parkingAndTollName').clearValidators();
      this.ContactUsForm.get('parkingAndTollName').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Cidade
      this.ContactUsForm.get('subCategory7city').clearValidators();
      this.ContactUsForm.get('subCategory7city').updateValueAndValidity();
      //Tirar validação - Cancela não abre - Estado
      this.ContactUsForm.get('subCategory7state').clearValidators();
      this.ContactUsForm.get('subCategory7state').updateValueAndValidity();
      //Tirar validação - Data da ocorrência
      this.ContactUsForm.get('occurrenceDate').clearValidators();
      this.ContactUsForm.get('occurrenceDate').updateValueAndValidity();
      //Tirar validação - Horário aproximado da ocorrência
      this.ContactUsForm.get('occurrenceHour').clearValidators();
      this.ContactUsForm.get('occurrenceHour').updateValueAndValidity();
      //Tirar validação - O pagamento ocorreu em dinheiro?
      this.ContactUsForm.get('paymentInMoney').clearValidators();
      this.ContactUsForm.get('paymentInMoney').updateValueAndValidity();
      //Tirar validação - Código da fatura
      this.ContactUsForm.get('billId').clearValidators();
      this.ContactUsForm.get('billId').updateValueAndValidity();
      //Tirar validação - CPF do usuário
      this.ContactUsForm.get('userDocument').clearValidators();
      this.ContactUsForm.get('userDocument').updateValueAndValidity();
      //Tirar validação - E-mail do usuário
      this.ContactUsForm.get('userEmail').clearValidators();
      this.ContactUsForm.get('userEmail').updateValueAndValidity();
    }
  }

  setRequiredReturnType(type) {
    if (type == 1) {
      this.ContactUsForm.get('phone').setValidators(this.phoneValidators)
      this.ContactUsForm.get('email').clearValidators();
      this.ContactUsForm.get('email').updateValueAndValidity();
    }
    else if (type == 2) {
      this.ContactUsForm.get('email').setValidators(this.emailValidators)
      this.ContactUsForm.get('phone').clearValidators();
      this.ContactUsForm.get('phone').updateValueAndValidity();
    }
    else {
      this.ContactUsForm.get('email').clearValidators();
      this.ContactUsForm.get('email').updateValueAndValidity();
      this.ContactUsForm.get('phone').clearValidators();
      this.ContactUsForm.get('phone').updateValueAndValidity();
    }

  }

  onRadioChange(event) {
    if (event.target.value == 1) {
      this.setRequiredReturnType(1);
    }
    else if (event.target.value == 2) {
      this.setRequiredReturnType(2);
    }
    else {
      this.setRequiredReturnType(3);
    }
  }

  onRadioParkingAndTollChange(event) {

  }

  onRadiPaymentInMoneyChange(event) {

  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.percent.splice(index, 1);
    this.error.splice(index, 1);
  }

  fileBrowseHandler($event: any) {
    this.prepareFilesList($event);
  }

  /**
     * handle file from browsing
     */
  prepareFilesList($event) {
    const file = $event[0];
    const allowedExtensions = ['csv', 'xlsx', 'xls'];
    const FILE_ERROR_INVALID_FORMAT = 2
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        this.error.push(false);
        this.files.push(file);
        this.percent.push(0);
        let position = this.files.length - 1;
        this.simulateProgressBarLoading(position);
      } else {
        let fileError = FILE_ERROR_INVALID_FORMAT;
        this.error.push(true);
        this.redirectToAttachmentErrorModal(fileError);
      }
    }

  }
  simulateProgressBarLoading(position) {
    let self = this;
    this.interval = setInterval(() => {
      if (self.percent[position] < 80) {
        self.percent[position] = self.percent[position] + 20
      }
      else {
        self.validateFile(position);
        clearInterval(this.interval);
      }
    }, 500);
  }

  validateFile(position) {
    const FILE_ERROR_SIZE_EXCEEDED = 1;
    const maxFileSizeMB = 1024 * 1024 * 10;
    let validExtension = this.validExtensions.filter(data => data.extension == this.files[position].type)
    if (validExtension.length == 0 || this.files[position].size > maxFileSizeMB) {
      let fileError
      if (this.files[position].size > maxFileSizeMB) {
        fileError = FILE_ERROR_SIZE_EXCEEDED;
        this.error[position] = true;
        this.redirectToAttachmentErrorModal(fileError)
      }
    }
    else {
      this.error[position] = false;
    }
  }

  redirectToAttachmentErrorModal(fileError) {
    const config: NgbModalOptions = {
      backdrop: 'static'
    }
    const modalRef = this.modalService.open(AttachmentErrorModalComponent, config);
    modalRef.componentInstance.fileError = fileError;
    modalRef.componentInstance.event.subscribe(() => {
      this.deleteFile(this.files.length - 1)
    })
  }

  onFilterDateGeneral(i) {
    this.isShowDatePicker[i] = false;
  }

  toggleDatePicker(element, i) {
    element.toggle();
    this.isShowDatePicker[i] = !this.isShowDatePicker[i]
  }

  onSubmit() {
    this.getContactUsQueryArguments = new GetContactUsQueryArguments();
    this.getContactUsQueryArguments.files = this.files;
    this.getContactUsQueryArguments.form = this.ContactUsForm.value;
    this.isLoading = true;
    this.submitted = true;
    this.contactUsService.postContactUsForm(this.getContactUsQueryArguments)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: any) => {
          let toEmit = {
            protocol: success.data.protocol,
            returnType: this.ContactUsForm.controls['returnType'].value,
            returnTypeText: this.ContactUsForm.controls['returnType'].value == 1 ?
              this.ContactUsForm.controls['phone'].value : this.ContactUsForm.controls['email'].value
          }
          this.formSentEvent.emit(toEmit);
        },
        (error) => {
          console.error(error)
          return;
        });
  }

}

