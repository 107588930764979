import { Component, OnInit} from '@angular/core';
import { MyvehiclesService } from '../../../myvehicles/services/myvehicles.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-import-vehicles-modal',
  templateUrl: './import-vehicles-modal.component.html',
  styleUrls: ['./import-vehicles-modal.component.scss']
})

export class ImportVehiclesModalComponent implements OnInit {

constructor(private modalService: NgbModal
  ,private vehiclesService:MyvehiclesService
  ,protected notifyService: NotifyService
  ){}

files: any[] = [];
imported: boolean;
concluded: boolean;
messageError: boolean;
onSaving: boolean = false;

ngOnInit(){
    this.concluded = false;
    this.imported = false;
    this.messageError = false;
}

dismissModal() {
    this.modalService.dismissAll();
  }

  DownloadFileModel(){
     window.location.href="https://gestaodepedagio.edenred.com/modelo_padrao.xlsx";
  }

    /**
   * on file drop handler
   */
     onFileDropped($event) {
        this.prepareFilesList($event);
      }

      /**
       * handle file from browsing
       */
      fileBrowseHandler($event) {
        this.prepareFilesList($event);
      }

      /**
       * Delete file from files list
       * @param index (File index)
       */
      deleteFile(index: number) {
        this.files.splice(index, 1);
        this.imported = false;
      }

      /**
       * Convert Files list to normal array list
       * @param files (Files List)
       */
      prepareFilesList($event) {
        const file = $event[0]
        this.messageError = false;
        if(this.files.length > 0){
             this.deleteFile(0)
        }

        if(file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
            this.imported = false;
            this.messageError = true;
        }
        else{
            this.files.push(file);
            this.imported = true;
        }
      }

      uploadFile(){
        this.onSaving = true;
        const formData = new FormData();
        formData.append('file', this.files[0]);
        this.vehiclesService.saveVehiclesByUploadFile(formData)
        .pipe(
          finalize(() => {
            this.onSaving = false;
          })
        )
        .subscribe(
          (success: any) => {
            this.concluded = true;
          },
          (error) => {
            console.error("getFromServerError", error);
            return;
          }
        )
      }

      /**
       * format bytes
       * @param bytes (File size in bytes)
       * @param decimals (Decimals point)
       */
      formatBytes(bytes, decimals:number = 0) {
        if (bytes === 0) {
          return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }

}
