import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MyAccountService } from '../../../myaccount/services/myaccount.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormValidatorMessageService } from '@services/form-validators/form-validator-message.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UpdateContractRegistration, UpdateType } from 'src/app/shared/components/verification-code/models/update-token.model';
import { VerificationCodeService } from 'src/app/shared/components/verification-code/verification-code.service';

@Component({
  selector: 'app-edit-contract-email-modal',
  templateUrl: './edit-contract-email-modal.component.html',
  styleUrls: ['./edit-contract-email-modal.component.scss']
})
export class EditContractEmailModalComponent implements OnInit{

  @Input() currentEmailAddress:string;
  @Input() personEmailAddressId:number;
  submitted: boolean;
  isLoading: boolean;
  step: number = 1;
  code: string;
  sub$: Subscription;
  updateType = UpdateType

  emailForm = new FormGroup({
    newEmail: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$"),
    ]),
    confirmationEmail: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$"),
      this.emailConfirmation()
    ]),
    personEmailAddressId: new FormControl()
  });

  constructor(
     private modalService: NgbModal,
     private formValidatorMessageService: FormValidatorMessageService,
     private verificationCodeService: VerificationCodeService,
     private myAccountService: MyAccountService
    ) {
     this.getCode();
    }

getCode(){
  this.sub$ = this.verificationCodeService.verificationCode$
  .subscribe((code) => {
    this.code = code;
  });
}

ngOnDestroy() {
  this.sub$.unsubscribe();
}

  ngOnInit(): void {
    this.emailForm.get('personEmailAddressId').setValue(this.personEmailAddressId)
    this.controlChange();
  }

  controlChange(){
    this.emailForm.get('newEmail').valueChanges.subscribe(() => {
      this.emailForm.get('confirmationEmail').updateValueAndValidity();
    });
  }


  emailConfirmation() {
    return (control: AbstractControl): Validators => {
      let newEmail = this.emailForm
        ? this.emailForm.controls["newEmail"].value
        : '';

      let confirmationEmail = this.emailForm
      ? this.emailForm.controls["confirmationEmail"].value
      : '';

      let InvalidCondition = newEmail !== confirmationEmail ? true : false;

      return InvalidCondition
        ? { emailConfirmationInvalid: true }
        : null;
    };
  }

  generateErrorMessage(formControl){
    return this.formValidatorMessageService.returnMessage(formControl);
  }

  close() {
    this.modalService.dismissAll();
   }

   sendCode(){
      this.submitted = true;
      this.isLoading = true;
      this.verificationCodeService.postEmailUpdateToken(this.emailForm.value)
      .pipe(finalize(() => { this.isLoading = false; }))
      .subscribe(
        (success) => {
          this.verificationCodeService.dataToVerificationCode$.next(this.emailForm.value)
          this.step = 2;
        },
        (error) => {
          console.error(error)
        });
   }

   confirm(){
      if(!this.code || this.code.length < 6){
          this.verificationCodeService.isValidCode$.next(false)
          return;
       }
       else{
        let updatedData: UpdateContractRegistration = {
          code:this.code,
          email:this.emailForm.controls["newEmail"].value,
          id: this.personEmailAddressId
        };
        this.isLoading = true;
        this.myAccountService.putUpdatePersonEmailAddress(updatedData)
        .pipe(finalize(() => { this.isLoading = false; }))
        .subscribe(
          (success) => {
            this.myAccountService.refreshCurrentUser$.next(undefined);
            this.step = 3;
          },
          (error) => {
            error.error.forEach(element => {
              if(element.message == "Código inválido"){
                this.verificationCodeService.isValidCode$.next(false)
               }
            });

            console.error(error)
          });
       }
   }

}
