import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ComplementaryCustomerData} from "@models/old/complementarycustomerdata.model";
import {BusinessUser} from "@models/old/legalperson.model";

@Component({
  selector: 'app-representatives-attorneys-modal',
  templateUrl: './representatives-attorneys-modal.component.html',
  styleUrls: ['./representatives-attorneys-modal.component.scss'],
})
export class RepresentativesAttorneysModalComponent {
  complementaryCustomerData: ComplementaryCustomerData;
  businessUser: BusinessUser;

  constructor(public modalService: NgbModal) {}
}
