import { Component } from '@angular/core';
import { finalize, debounceTime } from 'rxjs/operators';

import { TagsDto } from './dtos/tags.dto';
import { GetTagsQueryDto } from './dtos/getTags.dto';
import { TagsQueryArguments } from './dtos/tags-query-arguments.dto';
import { Subject } from 'rxjs';
import {
  NgbCalendar,
  NgbDate,
  NgbDropdownConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import {ReportsService} from "@services/reports.service";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [NgbDropdownConfig],
})
export class ReportsComponent {
  getTagsQuery: GetTagsQueryDto;
  vehicles: Array<TagsDto>;
  tagsQueryArguments: TagsQueryArguments;
  filterDebounce: Subject<TagsQueryArguments> =
    new Subject<TagsQueryArguments>();
  perPages: number[] = [];
  total: number;
  tagsReceived: Array<TagsDto> = [];
  isLoading: boolean;
  isOpenDropdown: boolean;
  open: boolean;
  isShowStatus: boolean;
  isOpen: boolean;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  statusCustom: any = 'Selecione';
  todayNow: any;
  public formPreDate;

  constructor(
    private reportsService: ReportsService,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    config: NgbDropdownConfig,
  ) {
    this.formPreDate = this.formBuilder.group({
      date: '30',
    });
    this.getTagsQuery = {
      total: 0,
      tags: [],
    };
    config.autoClose = false;
    this.todayNow = calendar.getToday();
    this.toDate = calendar.getToday();
    this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 30);

    this.perPages = [10, 20, 50];
    this.tagsQueryArguments = new TagsQueryArguments();
    this.tagsQueryArguments.page = 1;
    this.tagsQueryArguments.pageSize = 10;
    this.tagsQueryArguments.orderBy = 'manufacturingDate';
    this.tagsQueryArguments.desc = true;
    this.tagsQueryArguments.filter = 1;
    this.tagsQueryArguments.search = '';
    this.tagsQueryArguments.FromDate = new Date(
      this.fromDate.year,
      this.fromDate.month - 1,
      this.fromDate.day,
    ).toJSON();
    this.tagsQueryArguments.ToDate = new Date(
      this.toDate.year,
      this.toDate.month - 1,
      this.toDate.day,
    ).toJSON();
    this.filterDebounce
      .pipe(debounceTime(300))
      .subscribe((filter) => this.loadTags(filter));
    this.loadTags(this.tagsQueryArguments);
  }

  loadTags(args: TagsQueryArguments) {
    this.isLoading = true;
    this.reportsService
      .getTagsOSA(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.getTagsQuery = success;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }

  onPaginationChange(event: any) {
    this.tagsQueryArguments.page = event;
    this.loadTags(this.tagsQueryArguments);
  }

  onPageSizeChange(event: any) {
    this.tagsQueryArguments.page = 1;
    this.tagsQueryArguments.pageSize = event.target.value;
    this.loadTags(this.tagsQueryArguments);
  }

  onFullNameKeyup($event: any) {
    this.tagsQueryArguments.page = 1;
    this.tagsQueryArguments.search = $event;
    this.filterDebounce.next(this.tagsQueryArguments);
  }
  onBuscarClick(event: any) {
    this.loadTags(this.tagsQueryArguments);
  }

  toggleDropdown() {
    this.isOpenDropdown = !this.isOpenDropdown;
    this.open = false;
    this.isShowStatus = false;
  }

  toggleStatus() {
    this.isOpen = !this.isOpen;
    this.isShowStatus = !this.isShowStatus;
  }

  resetFilters() {
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
    this.formPreDate.get('date').setValue('30');
    this.statusCustom = 'Selecione';
    this.tagsQueryArguments.page = 1;
    this.tagsQueryArguments.pageSize = 10;
    this.tagsQueryArguments.orderBy = 'manufacturingDate';
    this.tagsQueryArguments.desc = false;
    this.tagsQueryArguments.filter = 1;
    this.tagsQueryArguments.search = '';
    this.tagsQueryArguments.FromDate = new Date(
      this.fromDate.year,
      this.fromDate.month - 1,
      this.fromDate.day,
    ).toJSON();
    this.tagsQueryArguments.ToDate = new Date(
      this.toDate.year,
      this.toDate.month - 1,
      this.toDate.day,
    ).toJSON();
    this.loadTags(this.tagsQueryArguments);
  }

  onFilterTypeClick() {}
  handleFiltersParams(event: any) {
    this.tagsQueryArguments.FromDate = event.transactionDateFilter[0];
    this.tagsQueryArguments.ToDate = event.transactionDateFilter[1];
    this.tagsQueryArguments.search = '';
    this.loadTags(this.tagsQueryArguments);
    this.tagsQueryArguments.page = 1;
  }
}
