<div class="ImportDocumentsModalComponent">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Importar novo documento</h4>
    <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
      <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>

  <div class="container-loading"
    *ngIf="isLoading"
    id="container-loader-modal"
    data-e2e="container-loader-modal"
  >
    <app-loader
      id="loader-modal"
      data-e2e="loader-modal"
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    ></app-loader>
  </div>

  <div class="modal-body" *ngIf="!isLoading">

    <section class="modal-data-container">
      <div class="selects">
        <div class="label-select">
          <label class="out-label" for="select-input">Selecione o parceiro</label>
          <select
            id="select-partner-modal"
            name="select-partner-modal"
            data-e2e="select-partner-modal"
            class="select-input"
            (change)="onPartnerSelect($event)"
          >
            <option *ngFor="let partner of SourcePartners" [value]="partner.id">
              {{ partner.ipefName }}
            </option>
          </select>
          <img src="../../assets/svg/form-select-icon.svg" alt="arrow-select" />
        </div>
        <div class="label-select">
          <label class="out-label" for="select-input">Período de referência</label>
          <select
            id="select-date-modal"
            name="select-date-modal"
            data-e2e="select-date-modal"
            class="select-input"
            (change)="onDateSelect($event)"
          >
            <option *ngFor="let date of dateArray" [value]="date">
              {{ date | date: 'MMMM/yyyy'  }}
            </option>
          </select>
          <img src="../../assets/svg/form-select-icon.svg" alt="arrow-select" />
        </div>

      </div>
      <div class="dragdrop" appDnd (fileDropped)="onFileDropped($event)" (click)="cleanMsg()" *ngIf="!imported">
        <input
          type="file"
          id="inputFile"
          #fileDropRef
          id="fileDropRef"
          multiple
          (change)="fileBrowseHandler($event.target.files)"
        />
        <a><img src="assets/svg/common-file-text-add.svg"/></a>

        <span *ngIf="!imported" class="text">
        Arraste e solte ou clique aqui escolher o arquivo
        <br/>
        (Somente arquivos com extensão .xlsx)
        </span>
      </div>
      <span *ngIf="messageError" class="error">
        Formato de arquivo inválido
      </span>
      <div class="uploaded-file" *ngIf="imported">
        <div class="file-details">
          <img src="assets/svg/icon-xlsx.svg" alt="xlsx">
          <div class="file-name-size">
          <p>{{ files[0].name }}</p>
          <span>{{ formatBytes(files[0].size) }}</span>
          </div>
        </div>
        <span class="delete-icon" (click)="deleteFile(0)"   id="modal-btn-delete-file"
        data-e2e="modal-btn-delete-file"><img  src="assets/svg/icon-trash-red.svg"/></span>
      </div>

      <div class="model-download">
        <p>Baixe aqui o modelo da planilha de conciliação de repasse para preenchimento</p>
        <button class="btn btn-secondary" (click)="DownloadFileModel()"
          id="modal-btn-download-model"
          data-e2e="modal-btn-download-model" >
          <img src="assets/svg/icon-download.svg" alt="">
          Baixar arquivo
        </button>
      </div>
    </section>


    <div class="modal-footer">
    <button
      class="btn btn-tertiary"
      id="modal-btn-cancel"
      data-e2e="modal-btn-cancel"
      (click)="dismissModal()"
    >
      CANCELAR
    </button>
    <button
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      [disabled]="!imported"
      (click)="uploadFile()"
    >
      ENVIAR
    </button>
  </div>
</div>
