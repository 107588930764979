<div class="container-fluid page-myaccount billListComponent">
  <div class="row">
    <header class="col">
      <app-page-title [title]="'/ Faturas'"></app-page-title>
    </header>
    <section class="seletor-prepaid-responsive" *modalityType="contractModality.PREPAID"></section>

    <section class="col-12 d-flex justify-content-center seletor-responsive" *modalityType="contractModality.POSTPAID">
      <app-tab-select [tabs]="tabs" (filterChange)="status = $event">
      </app-tab-select>
    </section>

    <main class="col-12 Dashboard_col_1" [ngClass]="handleClassByContractModality(contractModality.POSTPAID)">
      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ message }}"
        ></app-loader>
      </div>
      <div *ngIf="!isLoading">
        <div style="padding-bottom: 1rem" *ngFor="let bill of bills | billStatusFilter: status; let count = index">
          <app-bill-card [bill]="bill" [count]="count" [status]="status" [isLoading]="isLoading" [inContractModalityTypeId]="contractModalityTypeId"></app-bill-card>
        </div>
      </div>
    </main>
  </div>
</div>
