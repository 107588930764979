import {Component, OnInit} from '@angular/core';
import {LegalPerson} from "@models/old/legalperson.model";
import {MyContractService} from "@services/mycontract.service";
import {finalize} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  RepresentativesAttorneysModalComponent
} from "@modules/customer/mycontract/components/representatives-attorneys-modal/representatives-attorneys-modal.component";

@Component({
  selector: 'app-contract-representative-attorney',
  standalone: false,
  templateUrl: './contract-representative-attorney.component.html',
  styleUrl: './contract-representative-attorney.component.scss'
})
export class ContractRepresentativeAttorneyComponent implements OnInit {

  legalPerson: LegalPerson;

  protected isLoading: boolean = true;
  protected requestModalType: any = { request: 'request', contractUser: 'contractUser' };

  constructor(private myContractService: MyContractService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.loadLegalPersons();
  }

  private loadLegalPersons() {
    this.myContractService
      .getLegalPerson()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((result) => {
        this.legalPerson = result;
      });
  }

  onOpenProsecutorsModal(id: number, request: string): void {
    const bsModalRef = this.modalService.open(RepresentativesAttorneysModalComponent);

    if (request === this.requestModalType.request) {
      this.myContractService
        .getComplementaryCustomerData(id)
        .subscribe((result) => {
          bsModalRef.componentInstance.complementaryCustomerData = result;
        });
    }
    if (request === this.requestModalType.contractUser) {
      bsModalRef.componentInstance.businessUser =
        this.legalPerson.usersCollection[id];
    }
  }
}
