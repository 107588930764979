<div class="modal-content ">
    <div class="step-2">
      <div class="modal-header">
        <div class="box-title">
          <span>Rastrear pedido</span>
        </div>
        <button type="button" class="close" (click)="dismissModal()" id="btn-dimiss">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <form formGroup="viewTrackingForm">
        <div class="form-group d-flex justify-content-center mb-4">
            <div class="text-center">
              <div class="col-12 d-flex justify-content-center mb-4 image_track_partner">
                <img [src]="orderDetail.logisticPartnerImage" alt="" class="w-50" />
              </div>
              <label translate>Digite o código de rastreio do seu pedido</label>
              <div class="search-tracking-box">
                <input value="{{orderDetail.trackingCode}}" id="inputTrackingCode" type="text" formControlName="trackingCode" class="form-control ico-insert-text text-uppercase" />
                <button class="btn btn-search" translate (click)="openViewTracking()" id="openViewTracking">
                  <img src="assets/img/icon-search.svg" class="button-icon" />
                  <span translate>Buscar</span>
                </button>
              </div>
            </div>
        </div>
      </form> 
  </div>
  <div class="modal-footer">
    <div class="d-flex col-12 justify-content-between align-items-center">
      <div>
        <button class="btn btn-secondary" (click)="dismissModal()" id="btn-close">
          <div translate>Fechar</div>
        </button>
      </div>
      <div class="box-copyTrackingCode shadow">
        <button class="btn btn-primary shadow-sm mr-4" id="copyCode" translate (click)="copyCode(orderDetail.trackingCode)">
          <img src="assets/img/copy.svg" class="button-icon" />
            Copiar código de rastreio
        </button>
        <span id="trackingCode">
        {{orderDetail.trackingCode}}
        </span>
      </div>
    </div>
  </div>
</div>