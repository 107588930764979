export class UserModel {
  fullName: string;
  documentNumber: string;
  personDocumentTypeId: number;
  dateofBirth: string;
  personPhoneCollection: PersonPhoneCollection[];
  personEmailAddressCollection: PersonEmailAddressCollection[];
  protocol: string;
  groupId: number;
  contractId?: number;
}

export class PersonEmailAddressCollection {
  emailAddress: string;
  principalFlg: boolean;
}

export class PersonPhoneCollection {
  telephoneNumber: string;
  prefix: string;
  areaCode: string;
  countryCode: string;
  personPhoneTypeId: number;
  principalFlg: boolean;
}
