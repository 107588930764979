<div class="batch-management container-fluid">

  <div class="row">
    <div class="col-12">
      <app-page-title [title]="'/ Gestão em Lote'"></app-page-title>
    </div>

    <div class="batch-management__link-page">
      <app-borderless-button
        [description]="'Meus veículos'"
        [id-html]="'link-page-myvehicles'"
        [data-e2e]="'link-page-myvehicles'"
        [classIconLeft]="'ico-traffic'"
        [buttonColor]="'tertiary'"
        [routerLink]="'/meus-veiculos'"
        [isBtnWithBorder]="true"
      >
      </app-borderless-button>
    </div>

    <app-batch-management-card></app-batch-management-card>

  </div>
</div>
