<div class="PurchaseConfirmationModalComponent">
    <div class="modal-content" *ngIf="!processing">
            <header class="modal-header">
                <div class="box-title" ><span data-e2e="modal-title">Confirme sua compra</span></div>
                <button class="close" type="button" (click)="modal.close()">
                <span aria-hidden="true">×</span>
                </button>
            </header>
            <div class="modal-body show">
                <div class="box-gray-left">
                    <div class="title">Detalhes do pedido</div>
                </div>
                <div class="payment-values">
                    <div data-e2e="payment-value-label" class="label payment-value-label">Valor <span *ngIf="object?.paymentOriginId == paymentOrigin.LooseRecharge">da recarga</span></div>
                    <div data-e2e="payment-value" class="value payment-value">{{ object.paymentValue | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                    <div data-e2e="fee-value-label" *ngIf="object?.paymentOriginId == paymentOrigin.LooseRecharge" class="label fee-value-label">Taxa de recarga <span *ngIf="object.paymentRechargeFee !== 0">({{ object?.paymentRechargeFee | number : "1.2-2" : "pt-BR" }}%)</span></div>
                    <div data-e2e="discount-value-label" *ngIf="object?.paymentOriginId == paymentOrigin.TagOrder" class="label discount-value-label">Descontos</div>

                    <div data-e2e="fee-value" class="value fee-value" *ngIf="object?.paymentOriginId == paymentOrigin.LooseRecharge">
                        <span *ngIf="object.paymentRechargeFee !== 0">{{ object.paymentRechargeFeeValue | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</span>
                        <span *ngIf="object.paymentRechargeFee == 0">Isento</span>
                    </div>
                    <div data-e2e="discount-value" class="value discount-value" *ngIf="object?.paymentOriginId == paymentOrigin.TagOrder">
                        <span *ngIf="object.paymentDiscountValue !== 0">{{ object?.paymentDiscountValue | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</span>
                        <span *ngIf="object.paymentDiscountValue == 0">-</span>
                    </div>
                </div>
                <div class="payment-total" [ngClass]="object?.paymentMethodSelected == paymentMethodEnum.NoPaymentMethod ? 'with-margin-bottom' : ''">
                    <div data-e2e="payment-total-label" class="label total-label">Você irá pagar</div>
                    <div data-e2e="payment-total" class="value total">{{ object.paymentTotalValue | currency : "R$" : "symbol" : "0.2-2" : "pt-BR" }}</div>
                </div>
                <div class="payment-method" *ngIf="object?.paymentMethodSelected !== paymentMethodEnum.NoPaymentMethod">
                    <div class="title">Detalhes do pagamento</div>
                    <div class="method" [ngClass]="object?.paymentMethodSelected == paymentMethodEnum.CreditCard ? 'method-credit-card' : ''">
                        <div class="method-img" *ngIf="object?.paymentMethodSelected == paymentMethodEnum.Pix">
                            <img src="/assets/img/icon-pix.svg" />
                        </div>
                        <app-credit-card *ngIf="object?.paymentMethodSelected == paymentMethodEnum.CreditCard"></app-credit-card>
                        <div class="method-change">
                            <span (click)="modal.close()">Alterar</span>
                        </div>
                    </div>
                    <div class="message">
                        Em caso de não pagamento, a transação será automaticamente cancelada. Não perca tempo!
                    </div>
                </div>
           </div>
           <footer class="modal-footer modal-footer-gray justify-content-end">
            <button
              type="button"
              data-e2e="btn-dismiss-modal"
              class="btn btn-tertiary mr-4"
              data-dismiss="modal"
              aria-label="Close"
              (click)="modal.close()"
            >
              Cancelar
            </button>
    
            <button
              class="btn btn-secondary position-relative confirm"
              data-e2e="btn-confirm-recharge"
              type="button"
              (click)="confirmPurchase()"
            >
              Confirmar
            </button>
        </footer>
    </div>
    <div class="modal-content" *ngIf="processing" [ngClass]="processing ? 'modal-content-loading' : ''">
         <div class="container-loading">
            <app-loader [isLoading]="processing" message="Processando..."></app-loader>
          </div>
    </div>
</div>