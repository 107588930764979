import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@services/notify.service';
import { finalize } from 'rxjs/operators';
import {RequestService} from "@modules/backoffice/request/services/request.service";
import {FormErrorHandleService} from "@services/form-validators/form-error-handler.service";
import {FormSuccessHandleService} from "@services/form-validators/form-success-handler.service";

@Component({
  selector: 'app-report-tracking',
  templateUrl: './report-tracking.component.html',
  styleUrls: ['./report-tracking.component.scss'],
})
export class ReportTrackingComponent implements OnInit {
  @Input() order: any;
  @Output() event: EventEmitter<any> = new EventEmitter();

  step = 1;
  reportTrackingForm: FormGroup;
  isLoading: boolean;
  trackingInclude: any;
  partners: { name: string; id: number }[];

  constructor(
    private modalService: NgbModal,
    private requestService: RequestService,
    private errorHandler: FormErrorHandleService,
    private successHandler: FormSuccessHandleService,
    private notifyService: NotifyService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.createFormReportTracking();
    this.getPartners();
  }

  getPartners() {
    this.isLoading = true;
    this.requestService
      .getPartners()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.partners = success;
        },
        (error) => {
          console.error(error);
          return;
        },
      );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  createFormReportTracking() {
    this.reportTrackingForm = this.formBuilder.group({
      tagRequestId: [this.order?.id],
      logisticPartnerId: [null, Validators.required],
      trackingCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'),
        ],
      ],
    });
  }

  onSaveTrackingCode() {
    var number = Number(this.reportTrackingForm.get('logisticPartnerId').value);
    this.reportTrackingForm.get('logisticPartnerId').setValue(number);
    // this.step = 2;

    if (!this.reportTrackingForm.valid) {
      this.errorHandler.validateAllFormFields(this.reportTrackingForm);
      this.errorHandler.smoothError();
      this.notifyService.showWarning(
        'Atenção!',
        'Preencha todos os campos, por favor!',
      );
    } else {
      this.isLoading = true;
      this.requestService
        .saveTrackingCode(this.reportTrackingForm.value)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe(
          (success) => {
            this.event.emit(success);
            this.successHandler.smoothSuccess();
            // this.alertService.success('Código cadastrado com sucesso!');
            this.step = 2;
            this.trackingInclude = success;
          },
          (error) => {
            console.error(error);
          },
        );
    }
  }
}
