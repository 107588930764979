import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { CreditCard, PaymentMethodEnum, PaymentObject, PaymentOrigin } from "./../models/payment.model";
import { environment } from "src/environments/environment";
import { CreditCardFlagUrl } from "../models/payment.model";
import { Dto } from "../models/dto.model";
import { PrepaidConditions } from "../models/prepaid.model";
import { PaymentType } from "../models/payment.model";
import { PixPayment } from "../models/payment.model";
import { StoragedContractInfosService } from "./storaged-contract-infos.service";
import { OrderTimelineService } from "./order-timeline.service";
import { TagRequestPrepaid } from "@models/old/tagrequest/tagrequest.model";
import { ContractPixFee } from "@models/contractpixfee.model";
import { ContractIntegration, ContractIntegrationFee } from '@models/contractintegrationfee.model';

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private _TMCustomerUrl = environment.apiTMCustomer;
  private _TMTagUrl = environment.apiTMTag;
  private _TMSystemAccount = environment.apiTMSystemAccount;
  private _TMFinancial = environment.apiTMFinancial;
  private _cardIcons = CreditCardFlagUrl;


  /** Returns the id of payment method selected in loose recharge
   * @returns {number} the icon path.
   **/
  paymentMethodSelected: number;

  /** Returns the card flag icon path.
   * @returns {string} the icon path.
   **/
  flagCardIcon: string;

  cardNumber: string;

  /**
   * Enum of Credit Card update status.
   * @enum
   * @readonly
   */
  readonly statusInfo = {
    UNTOUCHED: {
      iconPath: "assets/img/ico-payment-refused2.svg",
      title: "Pagamento recusado",
      status: "pristine",
    },
    UPDATED: {
      iconPath: "assets/img/ico-payment-accept.svg",
      title: "Meio de pagamento atualizado",
      status: "updated",
    },
  };

  /**
   * A Behavior Subject that updates the order data. It emits an event to require a new order.
   *
   * @class boolean BehaviorSubject.
   */
  refreshOrder$ = new BehaviorSubject<boolean>(undefined);

  /**
   * A Behavior Subject that updates the Prepaid Conditions data. It emits an event to require a new order.
   *
   * @class boolean BehaviorSubject.
   * */
  refreshPrepaidConditions$ = new BehaviorSubject<boolean>(undefined);

  /**
   * A Behavior Subject that updates the payment status. It emits the status data.
   *
   * @class statusInfo BehaviorSubject.
   */
  updateStatus$ = new BehaviorSubject(this.statusInfo.UNTOUCHED);



  /**
   * A Behavior Subject that contains a boolean that controls payment transaction processing (loose recharge and tag order)
   *
   * @class boolean BehaviorSubject.
   */
  processingPaymentTransaction$ = new BehaviorSubject<boolean>(undefined);

  /**
   * An Observable which listen any ORDER update.
   * @returns {Observable<TagRequestPrepaid>} an Observable containing the new Order data.
   */
  updateOrder$ = this.refreshOrder$.pipe(
    switchMap(() => this.getTagRequestPrepaid())
  );

  /**
   * An Observable which listen any PREPAID CONDITIONS update.
   * @returns {Observable<PrepaidConditions>} an Observable containing the new Prepaid Conditions data.
   */
  updatePrepaidConditions$ = this.refreshPrepaidConditions$.pipe(
    switchMap(() => this.getPrePaidConditions())
  );

  constructor(
    protected _http: HttpClient,
    private _timelineService: OrderTimelineService,
    private _storagedContractInfosService: StoragedContractInfosService,
  ) {}

   /**
   * Updates the payment method selected in loose recharge
   */
    selectPaymentMethod(paymentMethodId){
        this.paymentMethodSelected = paymentMethodId;
    }

     /**
   * Generate object to payment
   */
     generatePaymentObject(paymentOriginId,object,amount = null, contractPixFee = null){
      const paymentObject = new PaymentObject();
      paymentObject.paymentOriginId = paymentOriginId;
      paymentObject.paymentMethodSelected = this.paymentMethodSelected ? this.paymentMethodSelected : PaymentMethodEnum.NoPaymentMethod;

        if(paymentOriginId == PaymentOrigin.LooseRecharge){
            paymentObject.paymentValue = amount;
            paymentObject.paymentDiscountValue = null;
            var rechargeFee = 0;
            if (this.paymentMethodSelected == PaymentMethodEnum.Pix)
            {
              var pixFee = contractPixFee.find((obj) =>{
                return obj.amountMin <= amount && (obj.amountMax >= amount || obj.amountMax === null);
              });
              rechargeFee = pixFee.percentage;
            }
            else
            {
              rechargeFee = object.rechargeFee;
            }
            paymentObject.paymentRechargeFee = rechargeFee;
            paymentObject.paymentRechargeFeeValue = (rechargeFee / 100) * amount;
            paymentObject.paymentTotalValue = ((rechargeFee / 100) * amount) + amount;
            paymentObject.paymentAmountOfTags = null;
        }
        else if(paymentOriginId == PaymentOrigin.TagOrder){
          paymentObject.paymentValue = object.totalOrder;
          paymentObject.paymentDiscountValue = object.totalOrderDiscount;
          paymentObject.paymentRechargeFee = null
          paymentObject.paymentRechargeFeeValue = null
          paymentObject.paymentTotalValue = object.totalAmountPayable;
          paymentObject.paymentAmountOfTags = object.amount;
        }

        return paymentObject;
     }

      /**
   * GET HTTP request that returns an Observable of Payment Types.
   *
   * @returns {Observable<TagRequestPrepaid>} an observable of type PaymentType Array.
   */
  getPaymentTypes(): Observable<Array<PaymentType>> {
    return this._http.get<Array<PaymentType>>(`${this._TMCustomerUrl}/Payment/GetPaymentType`)
  }

   /**
   * GET HTTP request that returns an Observable of PIX payment infos.
   *
   * @returns {Observable<PixPayment>} an observable of type PixPayment.
   */
   getPixPayment(id): Observable<PixPayment>{
    return this._http.get<PixPayment>(`${this._TMFinancial}/api/Pix?id=`+id)
  }

  /**
   * Updates the registered Credit Card attached to the order.
   * PUT HTTP request that returns an Observable of a Credit Card.
   *
   * @param token string
   * @return {Observable<CreditCard>} an observable of type Credit Card.
   * */
  putCreditCard(token: string): Observable<CreditCard> {
    return this._http
      .put<CreditCard>(`${this._TMCustomerUrl}/CreditCard`, { token })
      .pipe(
        map((res) => {
          this.flagCardIcon = this._cardIcons[res.creditCardBrandTypeId];
          this.cardNumber = res.cardNumber;

          this.refreshOrder$.next(undefined);
          this.refreshPrepaidConditions$.next(undefined);
          return res;
        })
      );
  }

  /**
   * Exclude the registered Credit Card.
   * DELETE HTTP request.
   *
   * @param contractId number
   * */
  excludeCreditCard(contractId: Number) {
    return this._http
   .delete(`${this._TMCustomerUrl}/CreditCard`, {body : {contractId}})
      .pipe(
        map((res) => {
          this.flagCardIcon = '';
          this.cardNumber = '';

          this.refreshOrder$.next(undefined);
          this.refreshPrepaidConditions$.next(undefined);
          return res;
        })
      );
  }

  /**
   * Register credit Card.
   * POST HTTP request that returns an Observable of a Credit Card.
   *
   * @param token string
   * @return {Observable<CreditCard>} an observable of type Credit Card.
   * */
  registerCreditCard(token: string): Observable<CreditCard> {
    return this._http
      .post<CreditCard>(`${this._TMCustomerUrl}/CreditCard`, { token })
      .pipe(
        map((res) => {
          this.flagCardIcon = this._cardIcons[res.creditCardBrandTypeId];
          this.cardNumber = res.cardNumber;

          this.refreshOrder$.next(undefined);
          this.refreshPrepaidConditions$.next(undefined);
          this._storagedContractInfosService.storagedContractInfos$.next(undefined)
          return res;
        })
      );
  }

  /**
   * POST HTTP request that reproceses the Tag Order.
   * Returns an Observable of TagRequestPrepaid DTO.
   *
   * @param {string} tagRequestId.
   * @return {Observable<Dto<TagRequestPrepaid>>} an observable of type Dto<TagRequestPrepaid>.
   * */
  postReprocessTagOrder(
    tagRequestId: string
  ): Observable<Dto<TagRequestPrepaid>> {
    return this._http
      .post<Dto<TagRequestPrepaid>>(
        `${this._TMTagUrl}/api/TagRequestPrepaid/reprocesstagrequest`,
        { tagRequestId }
      )
      .pipe(
        map((res) => {
          this.flagCardIcon = this._cardIcons[res.data.creditCardBrandTypeId];
          this.cardNumber = res.data.creditCard;

          this._timelineService.refreshOrder$.next(undefined);
          this.refreshOrder$.next(undefined);

          return res;
        })
      );
  }

  /**
   * GET HTTP request that returns an Observable of TagRequestPrepaid object.
   *
   * @returns {Observable<TagRequestPrepaid>} an observable of type TagRequestPrepaid.
   */
  getTagRequestPrepaid(): Observable<TagRequestPrepaid> {
    return this._http
      .get<TagRequestPrepaid>(`${this._TMTagUrl}/api/TagRequestPrepaid`)
      .pipe(
        map((res) => {
          this.flagCardIcon = this._cardIcons[res.creditCardBrandTypeId];
          this.cardNumber = res.creditCard;
          return res;
        })
      );
  }

  /**
   * GET HTTP request that returns an Observable of PrepaidConditions.
   *
   * @returns {Observable<PrepaidConditions>} an observable of type PrepaidConditions.
   */
  getPrePaidConditions(): Observable<PrepaidConditions> {
    return this._http
      .get<PrepaidConditions>(
         `${this._TMCustomerUrl}/Contract/GetPrePaidConditions`
      )
      .pipe(
        map((res) => {
          const contractMinimunBalance =
            res.automaticRechargeValue *
            (res.minimumPercentageForAutomaticRecharge / 100);
          this.flagCardIcon = this._cardIcons[res.creditCardBrandTypeId];
          this.cardNumber = res.creditCard;

          return { ...res, contractMinimunBalance };
        })
      );
  }

  postSingleRecharge(form: any): Observable<any> {
    return this._http
      .post<any>(
         `${this._TMSystemAccount}/api/PrePaidTransaction/SingleRecharge`,
         form
      )
      .pipe(
        map((res) => {
          if(!form.isPix){
            res.cardFlagIcon = this._cardIcons[res.creditCardBrandTypeId];
            this.flagCardIcon = this._cardIcons[res.creditCardBrandTypeId];
          }
          return res;
        })
      );
  }

  /**
   * GET HTTP request that returns an Observable with a PrepaidConditions object.
   *
   * @returns {Observable<number[]>} PrepaidConditions Observable of number[].
   */
  getAutomaticRechargeLimitValues(): Observable<any> {
    return this._http.get<number[]>(
      `${this._TMCustomerUrl}/Contract/GetAutomaticRechargeLimitValues`
    )
    .pipe(
      map((res) => {
      let firstElement = res[0];
      let secondElement = res[1];
      let penultimateElement = res[res.length-2];
      let lastElement = res[res.length-1];
      let carrousel = Object.assign([], res);
      // adiciona os dois primeiros valores no final do array para o carrousel funcionar
      carrousel.push(firstElement,secondElement)
      // adiciona os dois ultimos valores no começo do array para o carrousel funcionar
      carrousel.unshift(penultimateElement, lastElement);
        return {
          range: res,
          carrousel: carrousel
        };
      })
    );
  }

  getContractPixFee(): Observable<Array<ContractPixFee>> {
    return this._http.get<Array<ContractPixFee>>(`${this._TMCustomerUrl}/Contract/GetContractPixFee`)
  }

  getContractIntegrationFee(): Observable<ContractIntegrationFee>{
    return this._http.get<ContractIntegrationFee>(`${this._TMCustomerUrl}/Contract/IntegrationFee`)
  }

  getContractIntegration(endpoint: string): Observable<ContractIntegration>{
    return this._http.get<ContractIntegration>(`${this._TMCustomerUrl}/Contract/${endpoint}`)
  }

}
