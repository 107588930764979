<div class="confirmationBlockUnblockModalComponent">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" data-e2e="modal-title">
      {{ isUnblockingContract ? 'DESBLOQUEIO' : 'BLOQUEIO' }} FINANCEIRO MANUAL
    </h4>
    <a class="btn-close" id="modal-btn-close-above" data-e2e="modal-btn-close-above" (click)="dismissModal()">
     <img src="assets/svg/close.svg" alt="Ícone de X"/>
    </a>
  </div>

  <div class="container-loading" *ngIf="isLoading">
    <app-loader
      id="loader-modal"
      data-e2e="loader-modal"
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    ></app-loader>
  </div>

  <div class="modal-body" *ngIf="!isLoading">
    <h6 id="modal-body-subtitle" data-e2e="modal-body-subtitle">TEM CERTEZA DE QUE DESEJA {{ isUnblockingContract ? 'DESBLOQUEAR' : 'BLOQUEAR' }} O CONTRATO?</h6>

    <p id="modal-body-text-block" data-e2e="modal-body-text-block" *ngIf="!isUnblockingContract">
      Ao bloquear o contrato, todas as tags vinculadas a ele serão bloqueadas e as ações na plataforma ficarão suspensas até que o contrato seja desbloqueado.
    </p>

    <p id="modal-body-text-unblock" data-e2e="modal-body-text-unblock" *ngIf="isUnblockingContract">
      Ao desbloquear o contrato, as tags vinculadas a ele serão desbloqueadas e as ações na plataforma voltarão ao comportamento normal.
    </p>
    <p class="modal-text--gray" id="modal-body-text-block-plus" data-e2e="modal-body-text-block-plus" *ngIf="isUnblockingContract">
      <span>Atenção:</span> Caso o bloqueio financeiro automático seja ativado e o contrato possua pendências financeiras, o contrato será bloqueado automaticamente.
    </p>

    <form [formGroup]="blockUnblockForm">
      <div class="input-box">
        <label
          >{{ 'Informe o protocolo de solicitação: ' }}<span>*</span></label
        >
        <input
          id="modal-input-protocol"
          data-e2e="modal-input-protocol"
          formControlName="protocol"
          required
          type="text"
          placeholder="Informe o protocolo da solicitação"
          [mask]="getInputMask()"
          maxlength="50"
          minlength="1"
        />
        <div>
          <app-vmessage
            *ngIf="
              blockUnblockForm.controls.protocol.invalid &&
              blockUnblockForm.controls.protocol.touched
            "
            id="modal-msg-error-input-protocol"
            data-e2e="modal-msg-error-input-protocol"
            errorMessage="{{ 'Informe um número de protocolo válido' | translate }}"
          >
          </app-vmessage>
        </div>

        <label class="input-label--margin">{{ 'Motivo: ' }}</label>
        <input
          id="modal-input-reason"
          data-e2e="modal-input-reason"
          formControlName="reason"
          type="text"
          placeholder="Digite"
          maxlength="70"
        />
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-tertiary"
      id="modal-btn-cancel"
      data-e2e="modal-btn-cancel"
      (click)="dismissModal()"
    >
      CANCELAR
    </button>
    <button
      class="btn btn-secondary"
      type="submit"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      [disabled]="blockUnblockForm.controls.protocol.invalid"
      (click)="confirmBlockUnblockContract()"
    >
      CONFIRMAR
    </button>
  </div>
</div>
