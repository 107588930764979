<ng-container
  [ngSwitch]="step"
  *ngIf="tagRequestPrepaid$ | async as request; else loading"
>
  <div class="modal-content tryAgainModalComponent" *ngSwitchCase="1">
    <header class="modal-header">
      <div class="box-title">
        <span>Tentar Novamente</span>
      </div>

      <button class="close" type="button" (click)="modal.dismissAll()">
        <span aria-hidden="true">×</span>
      </button>

      <div class="row-box-yellow">
        <p class="box-yellow">
          <b>P</b>ara finalizar o pedido, deverá ser incluído um meio de
          pagamento válido
        </p>
      </div>
    </header>

    <main class="modal-body">
      <header class="contract-infos">
        <h2 class="companyName">{{ request.companyName }}</h2>

        <div class="companyDocument">
          {{ request.documentNumber | mask : "00.000.000/0000-00" }}
        </div>

        <div class="row row-animation"></div>

        <div class="nickname">
          <div class="box-white-small">
            <p>Contrato:</p>
            <p>
              <span id="contractId">{{ request.contractId }}</span
              ><span id="contractName" *ngIf="request.contractName">
                - {{ request.contractName }}</span
              >
            </p>
          </div>
        </div>
      </header>

      <div class="content-box">
        <section class="left-info">
          <img [src]="updateStatus.iconPath" />

          <p class="title" [class.success]="updateStatus.status == 'updated'">
            {{ updateStatus.title }}
          </p>
          <p *ngIf="updateStatus.status == 'pristine'" class="text">
            {{ orderStatus.registrationDate | date : "dd/MM/yyyy - HH:mm" }}
          </p>
        </section>

        <section class="main-info">
          <app-payment-method
          [reducedList]="true"
          [selectorMode]="false"
      ></app-payment-method>
        </section>

        <section *ngIf="updateStatus.status == 'pristine'" class="right-info">
          <p class="text">
            Atualize o meio de pagamento cadastrado ou entre em contato com o
            emissor do seu cartão de crédito
          </p>
        </section>
      </div>
    </main>

    <footer class="modal-footer modal-footer-gray">
      <button
        [disabled]="onSaving || !tagRequestPrepaid$"
        type="button"
        class="btn btn-tertiary"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismissAll()"
      >
        fechar
      </button>

      <button
        [disabled]="onSaving || !tagRequestPrepaid$"
        (click)="confirmOrder()"
        class="btn btn-secondary position-relative"
        type="button"
      >
        <img
          class="button-icon"
          *ngIf="onSaving"
          src="../../../assets/img/loader.gif"
          alt="Loading"
        />

        <span *ngIf="!onSaving">Confirmar pedido</span>
      </button>
    </footer>
  </div>

  <div *ngSwitchCase="2">
    <app-order-success
      [amount]="orderDetail.amount"
      [totalAmountPayable]="orderDetail.totalAmountPayable"
      [response]="success"
    ></app-order-success>
  </div>
</ng-container>

<ng-template #loading>
  <main class="container-loading">
    <app-loader
      [isLoading]="loading"
      message="{{ 'Carregando...' }}"
    ></app-loader>
  </main>
</ng-template>
