<card-model>
  <ng-container *ngIf="totalCosts$ | async as totalCosts">
    <header class="card-model-header">
      <h1>Gasto Total da Frota</h1>
    </header>

    <section class="card-model-container">
      <div class="action-center">
        <app-outlined-select [title]="'Período'" [values]="[3]"
                             [beforeTextValue]="'Últimos '" [afterTextValue]="' meses'" [customWidthSize]="'250px'"
                             [disableRipple]="true">
        </app-outlined-select>

        <app-borderless-button [description]="'Download CSV'"
                               [classIconRight]="'ico-download'"
                               [id-html]="'id-download-csv'"
                               [data-e2e]="'id-download-csv'"
                               [service]="'totalCosts'"
                               [filters]="filter"
                               [buttonColor]="'tertiary'"
                               [isBtnWithBorder]="true">
        </app-borderless-button>
      </div>

      <ng-container *ngIf="totalCosts.labels.length != 0; else empty">
        <charts
          type="doughnut"
          labelPosition="right"
          [data]="totalCosts"
        ></charts>
      </ng-container>
    </section>
  </ng-container>
</card-model>

<ng-template #empty>
  <p class="empty-msg">
    Nenhuma informação encontrada para o período selecionado.
  </p>
</ng-template>
