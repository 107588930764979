import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import {RequestService} from "@modules/backoffice/request/services/request.service";

@Component({
  selector: 'app-confirmation-separate-request',
  templateUrl: './confirmation-separate-request.component.html',
  styleUrls: ['./confirmation-separate-request.component.scss'],
})
export class ConfirmationSeparateRequestComponent implements OnInit {
  @Input() order: any;
  @Input() titles: any;
  isLoading: boolean;
  generatingFiles: boolean;
  errorMsg: boolean;

  constructor(
    private modalService: NgbModal,
    private requestService: RequestService,
  ) {}

  ngOnInit(): void {
    this.generateTagRequestFiles();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  goToPrint(url: string) {
    window.open(url, '_blank');
  }

  generateTagRequestFiles() {
    this.generatingFiles = true;
    if (this.order.urlProtocolFile) {
      this.generatingFiles = false;
      return;
    }
    this.isLoading = true;

    let objt = { tagRequestId: this.order.id };
    this.requestService
      .generateTagRequestFiles(objt)
      .pipe(
        finalize(() => {
          this.generatingFiles = false;
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success: any) => {
          this.order.urlProtocolFile = success.urlProtocolFile;
          this.order.urlDeclarationFile = success.urlDeclarationFile;
        },
        (e) => {
          console.error(e);
          this.errorMsg = true;
        },
      );
  }
}
