import { Component, OnInit, Input} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileInconsistenciesModalService } from './services/file-inconsistencies-modal.service';
import { finalize } from 'rxjs/operators';
import { ImportVehiclesModalComponent } from '@modules/customer/myvehicles/components/import-vehicles-modal/import-vehicles-modal.component';
import { GetInconsistenciesQueryResultDto } from '../../dtos/getInconsistenciesQueryResult.dto';

@Component({
  selector: 'app-file-inconsistencies-modal',
  templateUrl: './file-inconsistencies-modal.component.html',
  styleUrls: ['./file-inconsistencies-modal.component.css']
})

export class FileInconsistenciesModalComponent implements OnInit {

constructor(private modalService: NgbModal, private fileInconsistenciesService: FileInconsistenciesModalService){}
fileName: string;
inconsistenciesNumber: number;
InconsistenciesURL: string;
getInconsistenciesQueryResultDto: GetInconsistenciesQueryResultDto;
isLoading: boolean;
@Input() idDestination;



ngOnInit(){
  this.getInconsistenciesQueryResultDto = null;
  this.getFileInconsistencies(this.idDestination);
}


getFileInconsistencies(idDestination: number){
this.isLoading = true;
this.fileInconsistenciesService.getBatchVehicle(idDestination)
.pipe(
  finalize(() => {
    this.isLoading = false;
  })
)
.subscribe(
  (success:any) => {
    this.getInconsistenciesQueryResultDto = success
    this.fileName = this.getInconsistenciesQueryResultDto.fileName;
    this.inconsistenciesNumber = this.getInconsistenciesQueryResultDto.invalidItemsQuantity;
    this.InconsistenciesURL = this.getInconsistenciesQueryResultDto.urlErrorFile
  },
  (error) => {
    console.error(error);
  });
}

DownloadFileInconsistencies(){
  if(this.InconsistenciesURL){
    window.location.href = this.InconsistenciesURL;
  }
}


dismissModal() {
    this.modalService.dismissAll();
  }

  showImportVehiclesModal(){
    this.modalService.open(ImportVehiclesModalComponent);
  }

}
