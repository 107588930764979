import { inject } from "@angular/core";
import { GroupsCurrentUser } from "@models/group-user.model";
import { ResponseDto } from "@models/responseDto.model";
import { SelectSystemService } from "@modules/commom/select-profile/services/select-system.service";
import { LocalstorageService } from "@services/localstorage.service";

export const selectProfileResolver: () => Promise<ResponseDto<GroupsCurrentUser>> = () => {
  inject(LocalstorageService).removeAllProfiles();
  return inject(SelectSystemService).getPromisseGroupsByCurrentUser();
}
