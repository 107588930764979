import { Component } from '@angular/core';

@Component({
  selector: 'app-holidays-calendar',
  templateUrl: './holidays-calendar.component.html',
  styleUrl: './holidays-calendar.component.scss'
})
export class HolidaysCalendarComponent {
  isInclude : boolean = false

  changeView(isInclude: boolean){
    console.log(isInclude)
    this.isInclude = isInclude
  }


}
