import { inject } from "@angular/core";
import { LocalstorageService } from "@services/localstorage.service";
import { MyvehiclesService } from "../services/myvehicles.service";
import { catchError, firstValueFrom, map, of } from "rxjs";
import { GetVehiclesQueryArguments } from "../dtos/get-vehicles-query-arguments.dto";
import { HttpParams } from "@angular/common/http";
import { GetVehiclesQueryResultDto } from "../dtos/get-vehicles-query-result.dto";

export const myVehiclesResponseResolver = async (): Promise<GetVehiclesQueryResultDto> => {

  const myVehiclesService = inject(MyvehiclesService);

  const args = new GetVehiclesQueryArguments(
    'licensePlate',
    '',
    1,
    false,
    1,
    1000,
    false
  );
  try {
    const response = await firstValueFrom(myVehiclesService.getVehicles(args).pipe(
      map(response => {
        const vehicles = response.vehicles;
        const dualTagVehicles = vehicles.filter(vehicle => { if (vehicle.dualtag) { return vehicle.id } });
        localStorage.setItem('vehiclesDualTag', JSON.stringify(dualTagVehicles.map(vehicle => vehicle.id)));
        return response;
      }),
      catchError(error => {
        console.error('Erro ao buscar veículos', error);
        return of({ vehicles: [] } as GetVehiclesQueryResultDto);
      })
    ));

    return response;
  } catch (error) {
    console.error('Erro ao converter para Promise', error);
    throw error;
  }
};
